import React from 'react';
import GetStarted from '../user/signup/GetStarted';
import SplitPanels from '../core/panels/SplitPanels';
import { LoadingRing } from '../core/Loading';
import { Link } from '../core/router';
import usePlanBySlug from './hooks/usePlanBySlug';
import PlanFeatureList from './PlanFeatureList';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../../asset-config';

interface Props {
  planSlug: string;
  coupon?: string;
}

interface ViewProps {
  plan?: PlanEntity;
  coupon?: string;
}

const View = ({ plan, coupon }: ViewProps) => {
  if (plan == null) {
    return <GetStarted />;
  }

  const link = ['/plans', `${plan.slug}`];

  if (plan.isPaid) {
    link.push(plan.recurlyPlans[0].id);

    if (coupon) {
      link.push(`${coupon}`);
    }
  }

  return (
    <div>
      <div className="content text-center text-white">
        <div className="text-center mb-10">
          <h2>{plan?.name}</h2>
          <p className="text-large">
            You are signing up for the {plan?.name} plan which will give you:
          </p>
        </div>

        <PlanFeatureList plan={plan} />
      </div>
      <Link
        className="btn btn--primary btn--rev btn--sm w-full mt-8"
        to={link.join('/')}
      >
        Get Started
      </Link>
    </div>
  );
};

export default ({ planSlug, coupon }: Props) => {
  const { plan, isFetching } = usePlanBySlug(planSlug);

  if (isFetching) {
    return (
      <div className="flex flex-col w-full h-full justify-center items-center">
        <LoadingRing isActive />
      </div>
    );
  }

  return (
    <SplitPanels
      headerImg={DEFAULT_ONBOARDING_COVER_PHOTO_URL}
      backgroundClass="bg-red-900"
      logoImg={DEFAULT_ONBOARDING_LOGO_URL}
    >
      <div>
        <View plan={plan} coupon={coupon} />
      </div>
    </SplitPanels>
  );
};
