import React from 'react';

interface Props {
  src: string;
  alt: string;
  currentIndex: number;
  imageIndex: number;
}

const FeatureImage = ({ src, alt, currentIndex, imageIndex }: Props) => {
  const classes = ['feature-img'];

  if (currentIndex === imageIndex) {
    classes.push('active');
  }

  if (currentIndex > imageIndex) {
    classes.push('past');
  }

  return <img alt={alt} src={src} className={classes.join(' ')} />;
};
export default FeatureImage;
