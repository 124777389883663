import profile from './profile';
import role from './role';
import location from './location';
import about from './about';
import skills from './skills';
import industries from './industries';
import social from './social';
import portfolio from './portfolio';
import cover from './cover';
import title from './title';

const url = 'edit-profile';

export default [
  profile(url),
  role(url),
  location(url),
  about(url),
  skills(url),
  industries(url),
  title(url),
  social(url),
  portfolio(url),
  cover(url),
];
