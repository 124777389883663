import React, { useCallback, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Cover from './formElements/Cover';
import Title from './formElements/Title';
import Description from './formElements/Description';
import FormImageBrowser from '../core/form/FormImageBrowser';
import { FormActions } from '../core/form';
import { SubmitButton } from '../core/Button';
import reorder from '../../utilities/Array';
import { HashLink } from '../core/router';
import SortableImage from '../core/sortable/SortableImage';
import Video from './formElements/Video';

type Props = {
  form: any;
  imagesChanged: Function;
  coverChanged?: Function;
  onSubmit: any;
  defaultValues?: PortfolioEntity;
  backRoute: string;
};

type PortfolioImage = {
  id: number;
  status: 'uploading' | 'complete';
  url: string | null;
};

const Form = ({
  form,
  imagesChanged,
  coverChanged = () => {},
  onSubmit,
  defaultValues,
  backRoute,
}: Props) => {
  const { handleSubmit, errors, register } = form;
  const [portfolioImages, setPortfolioImages] = useState<PortfolioImage[]>(
    () => {
      if (defaultValues?.imageURLs?.length) {
        return defaultValues.imageURLs.map((url: string, index: number) => {
          return {
            id: index,
            status: 'complete',
            url,
          };
        });
      }

      return [];
    }
  );

  const handleUploadStart = () => {
    const newImage: PortfolioImage = {
      id: Math.floor(Math.random() * 10) + 12,
      status: 'uploading',
      url: null,
    };

    setPortfolioImages(state => [...state, newImage]);
  };

  const handleAddImage = useCallback(
    (url: string) => {
      setPortfolioImages(state => {
        // Find a placeholder image
        const index = state.findIndex(portfolioImage => {
          return portfolioImage.url === null;
        });

        if (index < 0) {
          // If we dont find anything, return early
          return state;
        }

        // Replace the found placeholder with the new url
        const newArray = Array.from(state);

        newArray.splice(index, 1, {
          id: Math.floor(Math.random() * 10) + 12,
          url,
          status: 'complete',
        });

        const imageArray = newArray.reduce<string[]>((acc, item) => {
          if (item.status === 'complete' && item.url !== null) {
            acc.push(item.url);
          }
          return acc;
        }, []);

        imagesChanged(imageArray);

        return newArray;
      });
    },
    [imagesChanged]
  );

  const handleRemoveImage = (index: number) => {
    const newArray = Array.from(portfolioImages);
    newArray.splice(index, 1);

    setPortfolioImages(newArray);

    // Strip out any placeholders
    const imageArray = newArray.reduce<string[]>((acc, item) => {
      if (item.status === 'complete' && item.url !== null) {
        acc.push(item.url);
      }
      return acc;
    }, []);

    imagesChanged(imageArray);
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    // Did the user drop outside?
    if (!destination) {
      return;
    }

    // The user dropped the item back where it came from
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Create the new reordered array
    const newOrder = reorder(portfolioImages, source.index, destination.index);

    setPortfolioImages(newOrder as PortfolioImage[]);

    // Strip out any placeholders
    const imageArray = (newOrder as PortfolioImage[]).reduce<string[]>(
      (acc, item) => {
        if (item.status === 'complete' && item.url !== null) {
          acc.push(item.url);
        }
        return acc;
      },
      []
    );

    imagesChanged(imageArray);
  };

  return (
    <>
      <HashLink
        to={backRoute}
        className="hidden md:block font-medium mb-4 text-sm"
      >
        <i className="i-arrow-left text-xs mr-1" />
        Back
      </HashLink>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Cover
          errors={errors}
          form={form}
          ref={register({ required: 'Cover photo is required' })}
          defaultValue={defaultValues?.headerPhotoURL}
          onChange={coverChanged}
        />
        <Title errors={errors} register={register} />
        <Description errors={errors} register={register} />
        <Video form={form} />

        <p className="text-black font-semibold mb-1">Images</p>

        {portfolioImages.length > 1 && (
          <p className="text-caption">Drag & Drop to reorder your images</p>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="items"
            renderClone={(provided, snapshot, rubric) => (
              <div>
                <div
                  // eslint-disable-next-line
                  {...provided.draggableProps}
                  // eslint-disable-next-line
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <div className="relative aspect-21/9 bg-grey-500 rounded-lg+ overflow-hidden my-4">
                    <img
                      className="absolute bottom-0 right-0 w-full h-full object-cover"
                      // @ts-ignore
                      src={portfolioImages[rubric.source.index].url}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
          >
            {provided => (
              <div
                ref={provided.innerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
              >
                {portfolioImages.map(({ url, id }, index) => (
                  <SortableImage
                    key={id}
                    id={id.toString()}
                    index={index}
                    url={url}
                    removeImage={handleRemoveImage}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <FormImageBrowser
          name="imageURLs"
          aspectRatio={null}
          onChange={handleAddImage}
          errors={errors}
          ref={register}
          setLoading={handleUploadStart}
        />

        <FormActions>
          <div className="md:ml-auto">
            <SubmitButton text="Save project" />
          </div>
        </FormActions>
      </form>
    </>
  );
};
export default Form;
