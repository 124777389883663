import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Password from '../../../user/formElements/Password';
import Messaging from '../../../../services/Messaging';
import UserContext from '../../../user/Context';
import useStep from '../../../stepper/hooks/useStep';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

export default () => {
  const { user, update: updateUser } = useContext(UserContext);
  const {
    setCanAdvance,
    goToNextStep,
    setOnNext,
    isLoading,
    setLoading,
  } = useStep();
  const isPasswordSet = !user?.isPasswordEmpty;

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { handleSubmit, register, errors, reset, formState } = form;
  const { isValid } = formState;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - Password Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) {
        return;
      }

      setLoading(true);

      const { password } = values;

      const data = {
        password,
        isPasswordEmpty: false,
      };
      updateUser(data)
        .then(() => {
          reset({
            password,
          });

          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, isLoading, reset, setLoading, updateUser]
  );

  useEffect(() => {
    setCanAdvance(isPasswordSet || isValid);
  }, [isPasswordSet, isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isPasswordSet) {
        return true;
      }

      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, isPasswordSet, onSubmit, setOnNext]);

  return isPasswordSet ? (
    <div className="text-center">
      <h5 className="mb-4">Your password is set.</h5>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Password errors={errors} register={register} label="Create a password" />
    </form>
  );
};
