import { useCallback, useContext, useEffect, useState } from 'react';
import { Api } from '../Api';
import UserContext from '../components/user/Context';

type Props = {
  data: PageView | null;
  loading: boolean;
};

function usePageViews(pageType: string, pageId: string): Props {
  const { isPublic } = useContext(UserContext);

  const [item, setItem] = useState({
    data: null,
    loading: true,
  });

  const updateState = useCallback((key, value) => {
    setItem(curState => ({
      ...curState,
      [key]: value,
    }));
  }, []);

  const refresh = useCallback(() => {
    updateState('loading', true);
    (isPublic
      ? Api.get(`page-views/${pageType}/${pageId}/public`)
      : Api.get(`page-views/${pageType}/${pageId}`)
    )
      .then((response: PageView) => {
        updateState('data', response);
      })
      .catch(() => {
        updateState('data', null);
      })
      .finally(() => {
        updateState('loading', false);
      });
  }, [pageId, pageType, updateState, isPublic]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return item;
}

export default usePageViews;
