import React, { MemoExoticComponent } from 'react';
import cn from 'classnames';

import ModalBackButton from './ModalBackButton';
import ModalCloseButton from './ModalCloseButton';

type Props = {
  showHeader: boolean;
  showBackButton: boolean;
  backButton?:
    | MemoExoticComponent<() => JSX.Element>
    | JSX.Element
    | JSX.Element[]
    | null;
  showClose: boolean;
  customHeader?: any;
  title?: string | null;
};
const ModalHeader = ({
  showHeader,
  showBackButton,
  backButton = <ModalBackButton />,
  showClose,
  customHeader,
  title,
}: Props) => {
  if (!showHeader) {
    return null;
  }

  return (
    <header className={cn('bg-white z-10 sticky top-0')}>
      <div className="h-full relative flex items-center">
        {showBackButton ? <div className="w-12">{backButton}</div> : null}

        <div className="flex-1 items-center justify-center pt-2">
          {customHeader ?? (
            <div className="h-12 text-lg text-center truncate flex-1 flex items-center justify-center font-bold ">
              {title || null}
            </div>
          )}
        </div>

        <div className="w-12 pt-1">
          {showClose ? <ModalCloseButton /> : null}
        </div>
      </div>
    </header>
  );
};
export default ModalHeader;
