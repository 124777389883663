import React, { useContext } from 'react';
// eslint-disable-next-line no-unused-vars
import { NavContext, NavContextState, RouterDirection } from '@ionic/react';

type NavigateFunction = (
  path: string,
  direction?: RouterDirection | 'none',
  ionRouteAction?: 'push' | 'replace' | 'pop'
) => void;

const NavigateContext = React.createContext<NavigateFunction>(() => {});

export const NavigateContextProvider = ({ children }: { children: any }) => {
  const { navigate }: NavContextState = useContext(NavContext);

  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
};

export const NavigateContextConsumer = NavigateContext.Consumer;

export default NavigateContext;
