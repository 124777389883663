import React, { useContext } from 'react';
import { Link } from '../../core/router';
import UserContext from '../Context';
import SignupLayout from '../signup/SignupLayout';
import { DEFAULT_NOT_FOUND_COVER_PHOTO_URL } from '../../../asset-config';

const Unknown = () => {
  const { authDetails } = useContext(UserContext);

  return (
    <SignupLayout headerImg={DEFAULT_NOT_FOUND_COVER_PHOTO_URL}>
      <div>
        <div className="text-center mb-8">
          <h3 className="mb-2">We can&apos;t find you.</h3>
          {authDetails.email && (
            <div className="text-lg">
              <span className="font-medium">{authDetails.email}</span>{' '}
              isn&apos;t in our system.
            </div>
          )}
          <div className="text-lg mt-2">
            You can try a different email, or perhaps you are a new member?
          </div>
        </div>

        <div className="mt-6 md:mt-4 xl:mt-16">
          <Link to="/login" className="btn btn--sm btn--primary btn--full mb-4">
            Try another email
          </Link>
          <Link
            to="/plans"
            className="btn btn--sm btn--primary btn--outline btn--full mb-4"
          >
            Join Communo
          </Link>
        </div>
      </div>
    </SignupLayout>
  );
};

export default Unknown;
