import React from 'react';
import { ForceMobileContextProvider } from '../../context/ForceMobileContext';
import useUser from '../../hooks/useUser';
import CandidatesAgencyProfile from './CandidatesAgencyProfile';
import CandidatesFreelancerProfile from './CandidatesFreelancerProfile';

type Props = {
  application: ApplicationEntity;
  membership: MembershipEntity | undefined;
  planType: PlanType | undefined;
  role: string | undefined;
  work: WorkEntity;
};

const CandidatesProfileTab = ({
  application,
  membership,
  planType,
  role,
  work,
}: Props) => {
  const { user: fetchedUser } = useUser(application.user.id);

  return (
    <ForceMobileContextProvider>
      <div className="flex flex-col gap-2 pb-28">
        {planType === 'agency' && membership ? (
          <CandidatesAgencyProfile
            membership={membership}
            application={application}
            work={work}
            role={role}
          />
        ) : (
          <CandidatesFreelancerProfile
            user={fetchedUser}
            application={application}
            showRelations={false}
            work={work}
            role={role}
          />
        )}
      </div>
    </ForceMobileContextProvider>
  );
};

export default CandidatesProfileTab;
