import React from 'react';
import { Link } from '../../core/router';
import { OverlayStyle } from '../index';

interface Props {
  link: string;
  avatar?: JSX.Element[] | JSX.Element;
  firstLine: string;
  secondLine?: string;
  thirdLine?: string;
  fourthLine?: string;
  backgroundUrl?: string;
  aspect?: [number, number];
  overlayStyle?: OverlayStyle;
  className?: string;
}

const OverlayBase = ({
  avatar,
  link,
  firstLine,
  secondLine,
  thirdLine,
  fourthLine,
  backgroundUrl,
  aspect = [4, 3],
  overlayStyle = OverlayStyle.Solid,
  className,
}: Props) => {
  const paddingBottom = () => {
    const ratio = aspect[1] / aspect[0];
    const percentage = ratio * 100;

    return `${percentage}%`;
  };

  return (
    <Link to={link} className={className}>
      <div
        className="relative w-full"
        style={{
          paddingBottom: paddingBottom(),
        }}
      >
        <img
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          alt={firstLine}
          src={backgroundUrl}
        />
        <div
          className={`swiper-slide__inset swiper-slide__inset--${overlayStyle}`}
        >
          <div className="flex">
            {avatar ? <div className="pr-4">{avatar}</div> : null}
            <div className="flex-1 min-w-0">
              {firstLine && (
                <div className="h5 swiper-slide__inset-title font-semibold">
                  {firstLine}
                </div>
              )}
              {secondLine && (
                <div className="swiper-slide__inset-title swiper-slide__inset-title--second text-small">
                  {secondLine}
                </div>
              )}
              {thirdLine && (
                <div className="swiper-slide__inset-title text-small">
                  {thirdLine}
                </div>
              )}
              {fourthLine && (
                <div className="swiper-slide__inset-title swiper-slide__inset-title--fourth text-small">
                  {fourthLine}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default OverlayBase;
