import React from 'react';
import { FormTextarea } from '../../core/form';

export default ({
  errors,
  register,
  form,
  defaultValue,
  isExternal = false,
  required = true,
}: any) => (
  <FormTextarea
    required={required}
    name="description"
    label="Details"
    placeholder="Enter a short description of the work"
    errors={errors}
    ref={register({
      required: required ? 'Work description is required' : false,
    })}
    richText={!isExternal}
    defaultValue={defaultValue}
    form={form}
  />
);
