import React, { useMemo } from 'react';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../../../asset-config';
import useClientBrandingStore from '../../../hooks/stores/useClientBrandingStore';
import SplitPanels from '../../core/panels/SplitPanels';

type Theme = 'light' | 'dark';

interface Props {
  headerImg?: string | null;
  logoImg?: string | null;
  children: JSX.Element | JSX.Element[];
  theme?: Theme;
}

const SignupLayout = ({ children, headerImg, logoImg }: Props) => {
  const brandCoverPhotoURL = useClientBrandingStore(
    state => state.clientBranding?.brandCoverPhotoURL
  );
  const brandLogoURL = useClientBrandingStore(
    state => state.clientBranding?.brandLogoURL
  );

  const headerImgUrl = useMemo(() => {
    if (headerImg) {
      return headerImg;
    }

    return brandCoverPhotoURL ?? DEFAULT_ONBOARDING_COVER_PHOTO_URL;
  }, [brandCoverPhotoURL, headerImg]);

  const logoImgUrl = useMemo(() => {
    if (logoImg) {
      return logoImg;
    }

    return brandLogoURL ?? DEFAULT_ONBOARDING_LOGO_URL;
  }, [brandLogoURL, logoImg]);

  return (
    <SplitPanels headerImg={headerImgUrl} logoImg={logoImgUrl}>
      {children}
    </SplitPanels>
  );
};
export default SignupLayout;
