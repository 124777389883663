import { SearchOptions } from '@algolia/client-search';
import { useContext } from 'react';
import useAsyncEffect from 'use-async-effect';
import SearchContext from '../components/search/Context';
import useSearch from '../components/search/useSearch';
import { BEATS_MEMBERSHIP_ID } from '../config';
import useCurrentUser from './useCurrentUser';

const now = Math.round(new Date().getTime() / 1000);

function getUsersCollectionFilters(user: UserEntity) {
  const collections = user?.collections ?? [];

  const collectionIdsQuery = collections
    .map(collection => `collectionIds:${collection.collectionId}`)
    .join(' OR ');

  const collectionFilters = [`(NOT audience:collection)`];

  if (collections.length > 0) {
    collectionFilters.push(`(${collectionIdsQuery})`);
  }

  return collectionFilters.join(' OR ');
}

const useBeatsWork = (searchParams: Partial<SearchOptions> = {}) => {
  const { user } = useCurrentUser();
  const { getWork } = useContext(SearchContext);

  const [results, handleSearch] = useSearch(getWork);

  useAsyncEffect(async () => {
    if (!user) {
      return;
    }

    const filters = [
      `expiresAtTimestamp > ${now}`,
      'type:student',
      `membership.id:${BEATS_MEMBERSHIP_ID}`,
      getUsersCollectionFilters(user),
    ].join(' AND ');

    handleSearch({
      work: {
        ...searchParams,
        query: '',
        filters,
      },
    });
  }, [user]);

  return results?.work ?? null;
};
export default useBeatsWork;
