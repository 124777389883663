/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useRef } from 'react';
import Page from '../components/core/Page';
import ImageUploader from '../components/image/ImageUploader';

const ImageUpload = () => {
  const profileImageRef = useRef();
  const coverImageRef = useRef();

  const [profileImage, setProfileImage]: [any, any] = useState(
    'https://placeimg.com/300/300/people'
  );
  const [coverImage, setCoverImage]: [any, any] = useState(
    'https://placeimg.com/1200/400/nature'
  );

  function browseFiles(pondRef: any) {
    pondRef.current.removeFile();
    pondRef.current.browse();
  }

  return (
    <Page>
      <h2>ImageUploader Examples</h2>

      <p className="my-4">Typical profile image flow.</p>
      <ImageUploader
        ref={profileImageRef}
        onChange={(imageData: string) => setProfileImage(imageData)}
      >
        <>
          {profileImage && (
            <img
              src={profileImage}
              alt="base64"
              className="avatar avatar--2xl inline-block border-4 border-white cursor-pointer"
              onClick={() => browseFiles(profileImageRef)}
            />
          )}
          <button
            type="button"
            onClick={() => browseFiles(profileImageRef)}
            className="btn btn--outline inline-block ml-4"
          >
            Change
          </button>
        </>
      </ImageUploader>

      <p className="my-4">Typical cover image flow.</p>
      <ImageUploader
        ref={coverImageRef}
        aspectRatio={1 / 2}
        dokaConfig={{
          cropMask: null,
        }}
        onChange={(imageData: string) => setCoverImage(imageData)}
      >
        <>
          {coverImage && (
            <img
              src={coverImage}
              alt="base64"
              className="w-full h-40 cursor-pointer"
              onClick={() => browseFiles(coverImageRef)}
            />
          )}
          <button
            type="button"
            onClick={() => browseFiles(coverImageRef)}
            className="btn btn--outline mt-4"
          >
            Change Cover Photo
          </button>
        </>
      </ImageUploader>
    </Page>
  );
};
export default ImageUpload;
