import React from 'react';
import moment from 'moment';
import { LoadingRing } from '../../core/Loading';
import usePreviewUpgradePlan from '../hooks/usePreviewUpgradePlan';
import { currency } from '../../../utilities/Number';
import { getCouponDiscount } from '../util';
import LineItem from './LineItem';

type Props = {
  planName?: string;
  recurlyPlan: RecurlyPlanEntity;
  coupon?: RecurlyCoupon;
};

const UpgradePricingPreview = ({ planName, recurlyPlan, coupon }: Props) => {
  const { isFetching, data } = usePreviewUpgradePlan(
    recurlyPlan.recurlyCode,
    coupon?.code
  );

  if (isFetching) {
    return (
      <div className="py-8 text-center">
        <LoadingRing isActive color="primary" size="lg" />
      </div>
    );
  }

  const chargeInvoice = data?.invoiceCollection?.chargeInvoice ?? null;

  const unitAmount = data?.unitAmount ?? recurlyPlan.unitAmount;

  const lineItem = chargeInvoice?.lineItems?.data[0];
  const endDate = lineItem?.endDate
    ? moment(lineItem.endDate).format('MMM DD, YYYY')
    : null;
  const subTotal = chargeInvoice?.subtotal ?? null;
  const paidAmount = chargeInvoice?.paid ?? null;
  const discount =
    chargeInvoice?.discount ?? getCouponDiscount(recurlyPlan, coupon);
  const balance = chargeInvoice?.balance ?? recurlyPlan.unitAmount - discount;

  return (
    <div className="mt-4 pb-2 space-y-2">
      <div className="font-semibold">
        {planName ? (
          <LineItem
            label={planName}
            value={`${currency(unitAmount)}/${recurlyPlan.intervalUnit}`}
          />
        ) : null}
      </div>

      {subTotal && endDate ? (
        <LineItem
          label={`Pro-rated until ${endDate}:`}
          value={currency(subTotal)}
          className="pl-4"
        />
      ) : null}
      {paidAmount ? (
        <LineItem
          label="Previous payment"
          value={currency(-paidAmount)}
          className="pl-4"
        />
      ) : null}
      {coupon ? (
        <LineItem
          label={`${coupon.name} coupon`}
          value={currency(-discount)}
          className="pl-4 text-red-900 font-semibold"
        />
      ) : null}

      <div className="pt-2 border-t-4 border-grey-200">
        <LineItem
          label="Due now"
          value={<span className="font-semibold">{currency(balance)}</span>}
        />
      </div>
    </div>
  );
};
export default UpgradePricingPreview;
