import React from 'react';
import Avatar from '../../avatar/Avatar';
import useChatUser from '../hooks/useChatUser';
import { TwilioUserAttributes } from '../../../@types/chat.d';

const IsTypingMessage = ({ identity }: { identity: string }) => {
  const { user } = useChatUser(identity);

  if (!user) return null;

  const { friendlyName, attributes } = user;
  const { avatarURL }: TwilioUserAttributes = attributes;

  return (
    <div className="chat-message chat-message--them mt-6">
      <div className="chat-message__avatar">
        <Avatar avatarName={friendlyName} avatarUrl={avatarURL} />
      </div>
      <div className="chat-bubble">
        <div className="chat-bubble__author">{friendlyName}</div>
        <div className="chat-bubble__body">
          <div className="typing-indicator">
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsTypingMessage;
