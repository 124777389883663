import React from 'react';
import UserCollectionIcon from '../collections/components/UserCollectionIcon';
import Avatar from '../avatar/Avatar';

type Props = {
  userId: string;
  url: string | null;
  altText: string;
  badge?: JSX.Element | null;
};

const UserProfilePhoto = ({ userId, url, altText, badge = null }: Props) => {
  const nameParts = altText.toUpperCase().split(' ');
  const initials =
    (nameParts[0] || '').charAt(0) + (nameParts[1] || '').charAt(0);

  return (
    <div
      className={`profile-columns__avatar relative overflow-hidden ${
        url ? 'aspect-3/4' : 'aspect-square'
      }`}
    >
      <div className="flex flex-col justify-between h-full w-full">
        {url ? (
          <Avatar
            avatarUrl={url}
            avatarName={altText}
            size="full"
            className="h-full w-full rounded-t-md rounded-b-none overflow-hidden"
            circle={false}
          />
        ) : (
          <div className="h-full bg-grey-500 text-white flex flex-col justify-center items-center text-2xl">
            {initials}
          </div>
        )}

        {badge ?? null}
      </div>

      <UserCollectionIcon
        userId={userId}
        className="absolute top-0 right-0 mt-2 mr-2"
        size="large"
      />
    </div>
  );
};
export default UserProfilePhoto;
