import React, { useContext } from 'react';
import Page from '../components/core/Page';
import Onboarding from '../components/onboarding/Onboarding';
import UserContext from '../components/user/Context';
import useNavigate from '../hooks/useNavigate';

const PageOnboarding = () => {
  const pageTitle = 'Onboarding';
  const { showOnboarding } = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname } = window.location;

  if (pathname === '/onboarding' && showOnboarding) {
    // when reloading the page, showOnboarding will be set to true,
    // so we should navigate away from the onboardin page, otherwise
    // two onboarding components will be active.
    navigate('/feed');
  }

  return (
    <Page
      authGuard
      layout="onboarding"
      className="home-page"
      pageTitle={pageTitle}
      hideHeader
    >
      <div className="flex grow flex-col h-full">
        {/* In the instance of the onboarding overlay being active, make sure this component is removed */}
        {!showOnboarding && <Onboarding />}
      </div>
    </Page>
  );
};

export default PageOnboarding;
