import React from 'react';
import { Redirect } from 'react-router';
import { Route } from './AppRoutes';

type RedirectRoute = {
  from: string;
  to: string;
};

const routes: RedirectRoute[] = [
  { from: '/beats-product', to: '/work/1cc69027e5134b56a838958d7261481e' },
  { from: '/beats-sports', to: '/work/7710932ef34e451bb10e2a3f20282bef' },
  { from: '/beats-filmmaker', to: '/work/cc6026fa095845a0a198bd30b00f94ac' },
  { from: '/beats-content', to: '/work/c4940c1b41da419caf473786fd748d75' },
];

const redirectRoutes = routes.map(
  (route: RedirectRoute): Route => {
    return {
      path: route.from,
      component: () => <Redirect to={route.to} />,
      exact: true,
    };
  }
);
export default redirectRoutes;
