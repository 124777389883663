import React from 'react';

import { Error404 } from '../error';
import FreelancerEntityColumn from './FreelancerEntityColumn';
import FreelancerSidebarColumn from './FreelancerSidebarColumn';
import ProfileCoverPhoto from './ProfileCoverPhoto';
import ProfileDetailsColumn from './ProfileDetailsColumn';
import ProfileLayout from './ProfileLayout';
import ProfilePortfolio from './portfolio/ProfilePortfolio';
import ProfileWork from './ProfileWork';

type Props = {
  user: UserEntity;
};

const FreelancerProfile = ({ user }: Props) => {
  const membership = user?.primaryMembershipUser?.membership;
  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  if (!membership || !user) {
    return (
      <Error404 title="Member 404" message="We couldn't find that member..." />
    );
  }

  return (
    <div className="profile profile-individual">
      <ProfileCoverPhoto url={membership.coverPhotoURL} altText={userName} />

      <ProfileLayout
        entityColumn={<FreelancerEntityColumn user={user} />}
        sidebarColumn={<FreelancerSidebarColumn user={user} />}
        detailsColumn={
          <>
            <ProfileDetailsColumn
              name={userName}
              summary={user.summary}
              about={user.about}
              roles={user.roles.map(assoc => assoc.role.name)}
              skills={user.skills.map(assoc => assoc.skill.name)}
              industries={user.industries.map(assoc => assoc.industry.name)}
              videoUrl={user.videoUrl}
            />

            <ProfileWork membership={membership} />
          </>
        }
      />
      <ProfilePortfolio membership={membership} user={user} />
    </div>
  );
};
export default FreelancerProfile;
