/* eslint-disable no-console */
import { Recommendation } from '@communo-corp/shared-types';
import React from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';

type SubmitRecommendationContext = {
  recommendation: Recommendation;
  form: UseFormMethods<any>;
  submissionToken: string;
};

export const SubmitRecommendationContext = React.createContext<
  SubmitRecommendationContext
>({} as any);

export const SubmitRecommendationContextProvider = ({
  recommendation,
  submissionToken,
  children,
}: any) => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      firstName: recommendation.firstName,
      lastName: recommendation.lastName,
      email: recommendation.email,
      title: null,
      department: null,
      organization: null,
      body: null,
    },
  });

  return (
    <SubmitRecommendationContext.Provider
      value={{
        recommendation,
        form,
        submissionToken,
      }}
    >
      {children}
    </SubmitRecommendationContext.Provider>
  );
};
