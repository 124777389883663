import React from 'react';
import Page from '../components/core/Page';
import View from '../components/discover/View';

const PageDiscover = () => {
  return (
    <Page authGuard layout="none" className="bg-grey-100 discover">
      <View />
    </Page>
  );
};

export default PageDiscover;
