import { CollectionInvite } from '@communo-corp/shared-types';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useCollectionInviteLink(
  collectionId: string
): {
  isLoading: boolean;
  invite: CollectionInvite;
} {
  const { data, isLoading } = useQuery(
    ['collection-invite', collectionId],
    () => Api.getCollectionInviteSummary(collectionId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    invite: data,
  };
}
