import React from 'react';
import Page from '../components/core/Page';
import PlanSuccess from '../components/plan/Success';

export default ({ match: { params } }: any) => {
  const { type } = params;

  return (
    <Page
      layout="none"
      className="page-bg--white page--no-padding"
      pageTitle="Success"
      hideHeader
    >
      <PlanSuccess type={type} />
    </Page>
  );
};
