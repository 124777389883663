import React, { useMemo } from 'react';
import ProfileRelations from './ProfileRelations';
import ProfileVideo from './ProfileVideo';
import ShowMore from '../showMoreText/ShowMore';
import RichText from '../core/RichText';
import markdown from '../../utilities/Markdown';
import ProfileTagline from './ProfileTagline';

type Props = {
  name: string;
  summary: string | null;
  about: string | null;
  roles?: string[];
  showRoles?: boolean;
  skills?: string[];
  showSkills?: boolean;
  industries?: string[];
  videoUrl?: string | null;
  tagline?: string | null;
  graphicTagline?: string | null;
};

const ProfileDetailsColumn = ({
  name,
  summary,
  about,
  roles = [],
  showRoles = true,
  skills = [],
  showSkills = true,
  industries = [],
  videoUrl = null,
  tagline = null,
  graphicTagline = null,
}: Props) => {
  const richTextValue = useMemo(() => markdown(about), [about]);

  return (
    <>
      <ProfileTagline tagline={tagline} graphicTagline={graphicTagline} />
      {summary ? (
        <h2 className="leading-snug mt-4 h4">{summary}</h2>
      ) : (
        <h2 className="leading-snug mt-4 h4">
          {`${name} hasn't written a summary yet!`}
        </h2>
      )}

      {about ? (
        <ShowMore collapsedHeight={300}>
          <RichText html={richTextValue} classVariant="ql-editor" />
        </ShowMore>
      ) : (
        <div className="mt-4">
          {`${name} hasn't written an about section yet!`}
        </div>
      )}

      <div className="flex flex-col gap-8">
        <ProfileRelations
          showing={showRoles}
          title="Roles"
          entities={roles}
          name={name}
        />
        <ProfileRelations
          showing={showSkills}
          title="Skills"
          entities={skills}
          name={name}
        />
        <ProfileRelations
          showing
          title="Industries"
          entities={industries}
          name={name}
        />
      </div>
      <ProfileVideo videoUrl={videoUrl} />
    </>
  );
};
export default ProfileDetailsColumn;
