import React from 'react';

// eslint-disable-next-line no-unused-vars
import Avatar, { AvatarProps } from './Avatar';

type Props = AvatarProps & {
  user: UserEntity;
};
const UserAvatar = (props: Props) => {
  const { user } = props;
  const membership = user.primaryMembershipUser?.membership;

  return (
    <Avatar
      avatarName={`${user.firstName} ${user.lastName}`}
      avatarUrl={user.avatarURL}
      logoName={membership?.name}
      logoUrl={membership?.logoURL}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
export default UserAvatar;
