import { SearchResponse } from '@algolia/client-search';
import orderBy from 'lodash/orderBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';
import { thousands } from '../../../utilities/Number';
import { groupProjectOptions } from '../../projectOptions/Util';

export default function getProjectOptionOptions(
  results: {
    [key: string]: SearchResponse;
  },
  projectOptions: ProjectOptionEntity[]
) {
  const projectOptionsFacetOptions = getFacetValuesFromResponse(
    results?.projectType || results?.search,
    'projectOption.name'
  );

  const filteredProjectOptions = projectOptions.filter(projectOption => {
    return !!projectOptionsFacetOptions[projectOption.name];
  });

  const projectOptionsOptions = groupProjectOptions(
    filteredProjectOptions,
    projectOption => {
      const count = projectOptionsFacetOptions[projectOption.name] ?? 0;

      return {
        value: projectOption.name,
        activeLabel: projectOption.name,
        label: `${projectOption.name} (${thousands(count)})`,
        count,
      };
    }
  );

  return orderBy(projectOptionsOptions, ['count'], ['desc']);
}
