export default (url: string) => {
  return {
    id: 'portfolio',
    label: 'Show off your portfolio',
    description:
      'A picture’s worth a thousand words and all that. Portfolios let your best work shine.',
    icon: 'image',
    action: `#/${url}/portfolio`,
    actionLabel: 'Add Portfolio',
    complete: false,
  };
};
