import React, { useState, useCallback } from 'react';
import { RadioFilterComponent } from '../../filter/options/RadioFilterOption';
import {
  statusFromApi,
  statusToApi,
  statusLabel,
  stateTransitionTable,
  applicationStatuses,
  AllowedStatusTransitions,
  isStatusFrozen,
} from '../../../utilities/ApplicationStatus';

// This function filters the allowed status list
// according to where status are to be displayed.
const applicationStatusOptions = (
  isPublic: boolean,
  nextStatusesOptions: AllowedStatusTransitions
) => {
  return nextStatusesOptions.map((nextStatus: ApplicationStatus) => {
    const statusParams = applicationStatuses[nextStatus];
    const canDisplayStatus = isPublic
      ? statusParams.displayExternally
      : statusParams.displayInternally;
    return canDisplayStatus
      ? { label: statusLabel(nextStatus, isPublic), value: nextStatus }
      : { label: '', value: '' };
  });
};

type Props = {
  application: ApplicationEntity;
  isPublic: boolean;
  patchApplications: Function;
  onComplete?: Function;
};

const CandidateStatusForm = ({
  application,
  isPublic,
  patchApplications,
  onComplete,
}: Props) => {
  const applicationStatus = statusFromApi(application.status);
  const [status, setStatus] = useState(applicationStatus);

  const nextPossibleStatuses =
    stateTransitionTable[applicationStatus][isPublic ? 'external' : 'internal'];

  const nextStatusOptions = applicationStatusOptions(
    isPublic,
    nextPossibleStatuses
  );

  const applicantHasBeenInvited = applicationStatus.toLowerCase() === 'invited';
  const applicantFirstName = application.user.firstName;

  const applicantHasBeenAwardedTheJob =
    applicationStatus.toLowerCase() === 'awarded';

  let changeStatusButtonText;
  if (applicantHasBeenInvited) {
    changeStatusButtonText = `${applicantFirstName} has been invited to apply`;
  } else if (applicantHasBeenAwardedTheJob) {
    changeStatusButtonText = `${applicantFirstName} has been awarded the job`;
  } else {
    changeStatusButtonText = 'Apply';
  }

  const changeStatusButtonDisabled =
    applicantHasBeenInvited || applicantHasBeenAwardedTheJob;

  const disabledStatusChange = isStatusFrozen(application.status, isPublic);

  const onStatusChange = useCallback(
    (name: string, value?: any) => {
      if (value) {
        setStatus(value);
      }
    },
    [setStatus]
  );

  const onApply = useCallback(() => {
    const data = {
      [application.id]: {
        status: statusToApi(status),
        position: null, // reset the position
      },
    };
    patchApplications(data);
    if (onComplete) onComplete();
  }, [application.id, onComplete, patchApplications, status]);

  return (
    <div className="relative flex flex-col justify-between p-8">
      <RadioFilterComponent
        multiple={false}
        allowClear={false}
        options={nextStatusOptions}
        onChange={onStatusChange}
        sectionTitle="Move candidate to:"
        name="status"
        value={applicationStatus}
        disabledStatusChange={disabledStatusChange}
      />

      <div className="filter-apply">
        <button
          type="submit"
          onClick={onApply}
          className="btn btn--primary btn--full"
          disabled={changeStatusButtonDisabled}
        >
          {changeStatusButtonText}
        </button>
      </div>
    </div>
  );
};

export default CandidateStatusForm;
