import React, { useMemo } from 'react';
import { thousands } from '../../utilities/Number';

type Props = {
  showing: number;
  total: number;
  onLoadMore?: Function;
  displayShowingCount?: Boolean;
  max?: number | null;
  maxText?: string;
};

const Summary = ({
  showing,
  total,
  displayShowingCount,
  max = null,
  maxText,
}: any) =>
  showing > 0 ? (
    <div className="text-center mt-8">
      {displayShowingCount && (
        <div className="text-xs text-subdued mb-2">
          Showing {showing} of {max && total > max ? maxText : thousands(total)}
        </div>
      )}
      {displayShowingCount && total > 0 && (
        <div className="mx-auto w-32 mb-4">
          <div
            className="rounded-lg bg-grey-200 relative"
            style={{ height: 2 }}
          >
            <div
              className="rounded-lg bg-black absolute left-0 top-0 bottom-0"
              style={{
                width: `${(showing / total) * 100}%`,
              }}
            />
          </div>
        </div>
      )}
    </div>
  ) : null;

const Button = ({ handleLoadMore }: any) => {
  return (
    <div className="text-center">
      <div className="btn btn--primary" role="none" onClick={handleLoadMore}>
        Load more
      </div>
    </div>
  );
};

const Paginate = React.memo(
  ({
    showing,
    total,
    onLoadMore,
    displayShowingCount = true,
    max = null,
    maxText = 'thousands',
  }: Props) => {
    const isLastPage = useMemo(() => showing === total, [showing, total]);

    const handleLoadMore = () => {
      if (onLoadMore) onLoadMore();
    };

    return (
      <>
        <Summary
          showing={showing}
          total={total}
          displayShowingCount={displayShowingCount}
          max={max}
          maxText={maxText}
        />
        {!isLastPage && <Button handleLoadMore={handleLoadMore} />}
      </>
    );
  }
);

export default Paginate;
