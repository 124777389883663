import React from 'react';
import { FormTextarea } from '../../core/form';

export default ({ errors, register, form, defaultValue }: any) => (
  <FormTextarea
    name="about"
    label="About"
    placeholder=""
    errors={errors}
    ref={register({
      // required: 'About is required',
    })}
    defaultValue={defaultValue}
    richText
    form={form}
  />
);
