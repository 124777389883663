import React, { useCallback, useState, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { RefresherEventDetail } from '@ionic/core';

const PullToRefreshContext = React.createContext<any>(null);

export const PullToRefreshContextProvider = ({
  children,
}: {
  children: any;
}) => {
  const refreshHandlerRef = useRef<Function | null>(null);
  const refreshCompleteRef = useRef<Function | null>(null);
  const [pullToRefreshToken, setPullToRefreshToken] = useState<number | null>(
    null
  );

  const resetPullToRefreshToken = useCallback(() => {
    setPullToRefreshToken(Math.random());
  }, []);

  const setRefreshHandler = useCallback(func => {
    refreshHandlerRef.current = func;
  }, []);

  const onRefreshStart = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      refreshCompleteRef.current = event.detail.complete;

      setTimeout(() => {
        if (refreshHandlerRef?.current) {
          refreshHandlerRef.current();
        } else {
          // eslint-disable-next-line
          console.error(
            'Pull to refresh called without a handler! Nothing was actually refreshed.'
          );
          if (refreshCompleteRef?.current) refreshCompleteRef.current();
        }
      }, 500);
    },
    []
  );

  const onRefreshFinish = useCallback(() => {
    if (refreshCompleteRef.current) {
      refreshCompleteRef.current();
    }
  }, []);

  return (
    <PullToRefreshContext.Provider
      value={{
        onRefreshStart, // Called by the ion-refresher component
        onRefreshFinish, // Called by consumer when component is finished re-loading
        setRefreshHandler, // Called by the consumer to set what should happen when pull to refresh starts
        pullToRefreshToken,
        resetPullToRefreshToken,
      }}
    >
      {children}
    </PullToRefreshContext.Provider>
  );
};

export const PullToRefreshContextConsumer = PullToRefreshContext.Consumer;

export default PullToRefreshContext;
