import axios from 'axios';
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import pluralize from 'pluralize';
import { useCookie } from 'react-use';
import MembershipApi from '../membership/Api';
import { FormGroup, ErrorMessage } from '../core/form';
import { currency, percent } from '../../utilities/Number';
import ErrorBoundary from '../core/ErrorBoundary';
import environment from '../../environment';
import {
  FirstName,
  LastName,
  Region,
  Country,
  PostalCode,
  Terms,
} from './formElements';
import UserContext from '../user/Context';
import useAnalytics from '../../hooks/useAnalytics';
import { addResponseError } from '../../services/Messaging';
import BackButton from '../core/BackButton';
import { SubmitButton } from '../core/Button';
import ContentHeader from '../core/ContentHeader';
import Breadcrumbs from '../core/Breadcrumb';
import { Link } from '../core/router';
import { SOURCE_COOKIE } from '../cookie/SourceCookie';
import SignupLayout from '../user/signup/SignupLayout';
import { LoadingRing } from '../core/Loading';
import usePlanBySlug from './hooks/usePlanBySlug';
import { DEFAULT_UPGRADE_REQUIRED_COVER_PHOTO_URL } from '../../asset-config';

const { recurlyPublicKey } = environment;

const { recurly } = window as any;
let elements: any = null;

const Promo = ({
  promo,
  recurlyPlan,
}: {
  promo: RecurlyCoupon;
  recurlyPlan: RecurlyPlanEntity;
}) => {
  return (
    <div className="my-4">
      <div className="flex">
        <div className="flex-1">
          <div className="font-medium text-teal-500">Promotion applied:</div>
          <div className="text-xs text-subdued">
            Save {promo.discount.rate && percent(promo.discount.rate)}
            {promo.discount.amount && currency(promo.discount.amount.USD)}{' '}
            {promo.temporal_amount && (
              <>
                off the first {promo.temporal_amount}{' '}
                {pluralize(promo.temporal_unit, promo.temporal_amount)}
              </>
            )}
          </div>
        </div>
        <div className="self-end">
          -
          {promo.discount.rate &&
            currency(recurlyPlan.unitAmount * promo.discount.rate, false)}
          {promo.discount.amount && currency(promo.discount.amount.USD, false)}
        </div>
      </div>
      <div className="font-medium flex border-t-2 border-grey-200 pt-4 mt-4">
        <div>Total:</div>{' '}
        <div className="ml-auto">
          {promo.discount.rate &&
            currency(
              recurlyPlan.unitAmount -
                recurlyPlan.unitAmount * promo.discount.rate,
              false
            )}
          {promo.discount.amount &&
            currency(recurlyPlan.unitAmount - promo.discount.amount.USD, false)}
          {recurlyPlan.intervalUnit === 'yearly' ? '/yr' : '/mo'}
        </div>
      </div>
    </div>
  );
};

const Summary = ({
  recurlyPlan,
  promo,
}: {
  recurlyPlan: RecurlyPlanEntity;
  promo: RecurlyCoupon | null;
}) => (
  <div>
    {promo && <Promo recurlyPlan={recurlyPlan} promo={promo} />}

    {!promo && (
      <div className="font-medium flex mb-4">
        <div>Total:</div>{' '}
        <div className="ml-auto">
          {recurlyPlan ? currency(recurlyPlan.unitAmount) : '-'}
          {recurlyPlan?.intervalUnit === 'yearly' ? '/yr' : '/mo'}
        </div>
      </div>
    )}
  </div>
);

type PaymentProps = {
  plan: PlanEntity;
  recurlyId: string;
  coupon?: string;
};

const Payment = ({ plan, recurlyId, coupon }: PaymentProps) => {
  const {
    isAuthenticated,
    authDetails,
    register: registerUser,
    refresh,
    hasMembership,
    membership,
  } = useContext(UserContext);
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [promo, setPromo] = useState<RecurlyCoupon | null>(null);
  const formRef = useRef(null);
  const {
    handleSubmit,
    register,
    errors,
    setError,
    watch,
    formState,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const [recurlyError, setRecurlyError] = useState<any>();
  const { isSubmitted, isValid } = formState;
  const [isUpgrading] = useState(hasMembership);
  const [sourceCookie] = useCookie(SOURCE_COOKIE);

  const { pageIsReady } = useAnalytics();

  const recurlyPlan = useMemo(() => {
    return plan?.recurlyPlans.find(
      (p: RecurlyPlanEntity) => p.id === recurlyId
    );
  }, [plan, recurlyId]);

  const recurlyPlanCode = useMemo(() => {
    return recurlyPlan?.recurlyCode;
  }, [recurlyPlan]);

  const action = (data: any) => {
    return membership?.id
      ? MembershipApi.updatePlan(membership.id, data)
      : MembershipApi.create(data);
  };

  const doCreateMembership = (data: any) => {
    action(data)
      .then((response: any) => {
        // setLoading(false);
        refresh().then(() => {
          const path = isUpgrading
            ? '/plans/upgrade-success'
            : '/plans/success';
          push(path, {
            email: authDetails.email,
            planId: plan?.id,
            planName: plan?.name,
            planType: plan?.type,
            planPrice: recurlyPlan?.unitAmount,
            planCurrency: recurlyPlan?.currency,
            planCoupon: promo?.code,
            recurlyCode: recurlyPlan?.recurlyCode,
            frequency: recurlyPlan?.intervalUnit,
          });
        });
        return response;
      })
      .catch(error => {
        setLoading(false);
        addResponseError(
          error?.response?.data?.message?.transactionError || error,
          null,
          undefined,
          'alert'
        );
      });
  };

  const createMembership = (data: any) => {
    if (isAuthenticated) doCreateMembership(data);
    else {
      registerUser({ email: authDetails.email, source: sourceCookie || null })
        .then(() => {
          doCreateMembership(data);
        })
        .catch((error: ResponseError | any) => {
          setLoading(false);
          addResponseError(error);
        });
    }
  };

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data: any = {
      billingFirstName: values.firstName,
      billingLastName: values.lastName,
      terms: values.terms,
      planId: plan.id,
      recurlyPlanCode: recurlyPlan?.recurlyCode,
      paymentTokenId: '',
    };

    if (coupon && promo) data.recurlyCouponCodes = [coupon];

    recurly.token(elements, formRef.current, (err: any, token: any) => {
      if (err) {
        setLoading(false);
        // handle error
        setRecurlyError({
          recurly: {
            type: err.code,
            message: err.message,
          },
        });
      } else {
        // save the token.id, and submit it to the Recurly API from your server
        data.paymentTokenId = token.id;
        // create the membership
        createMembership(data);
      }
    });
  };

  // load recurly.js
  // useScript('https://js.recurly.com/v4/recurly.js');

  if (!recurly || !recurly.Recurly)
    throw new Error('Missing Recurly public key');

  useEffect(() => {
    recurly.configure(recurlyPublicKey);
    elements = recurly.Elements();
    // https://developers.recurly.com/reference/recurly-js/#styling-elements
    const cardElement = elements.CardElement({
      // inputType: 'mobileSelect',
      style: {
        fontColor: '#262628',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 'normal',
        fontSize: '1rem',
        textRendering: 'optimizeLegibility',
        fontSmoothing: 'antialiased',
        placeholder: { color: '#95969A !important' },
        invalid: { fontColor: '#D71D4A' },
      },
    });
    cardElement.attach('#recurly-elements');
  }, []);

  useEffect(() => {
    if (recurlyPlanCode && coupon) {
      const url = `https://api.recurly.com/js/v1/coupons/${coupon}?plan_codes[0]=${recurlyPlanCode}&key=${recurlyPublicKey}`;
      axios
        .get(url)
        .then(({ data }: { data: RecurlyCoupon | any }) => {
          const { error } = data;
          if (error) {
            addResponseError(error);
            setError('coupon', {
              type: error.code,
              message: error.message,
            });
            setPromo(null);
          } else {
            setPromo(data);
          }
        })
        .catch((err: any) => {
          addResponseError(err);
          setPromo(null);
        });
    } else {
      setPromo(null);
    }
  }, [coupon, recurlyPlanCode, setError]);

  useEffect(() => {
    if (plan && recurlyPlan) {
      pageIsReady({
        pageName: 'Payment Info',
        email: authDetails.email,
        planId: plan?.id,
        planName: plan?.name,
        planType: plan?.type,
        planPrice: recurlyPlan?.unitAmount,
        planCurrency: recurlyPlan?.currency,
        planCoupon: promo?.code,
        recurlyCode: recurlyPlan?.recurlyCode,
        frequency: recurlyPlan?.intervalUnit,
      });
    }
  }, [plan, recurlyPlan, pageIsReady, promo, authDetails.email]);

  if (!loading && !plan) {
    return (
      <div className="text-center">
        <div className="mb-8">Hmm we couldn&apos;t find that plan :/</div>
        <Link to="/plans" className="text-subdued">
          See all plans <i className="i-caret-right text-xs" />
        </Link>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <ContentHeader heading="Plan information">
          <Breadcrumbs current={3} total={3} />
        </ContentHeader>
      </div>
      <div className="text-center">
        <h3 className="mb-4">
          {hasMembership ? 'Upgrade Plan.' : 'Payment Info.'}
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="flex flex-col grow"
        ref={formRef}
      >
        <div className="row flex">
          <div className="col w-1/2">
            <FirstName
              name="firstName"
              errors={isSubmitted ? errors : {}}
              register={register}
            />
          </div>
          <div className="col w-1/2">
            <LastName
              name="lastName"
              errors={isSubmitted ? errors : {}}
              register={register}
            />
          </div>
        </div>

        <FormGroup name="recurly" errors={recurlyError ?? {}}>
          <div id="recurly-elements" />
        </FormGroup>

        <div className="form-group">
          <div className="flex form-input p-0">
            <Country
              name="country"
              errors={isSubmitted ? errors : {}}
              register={register}
            />
            {watch('country') === 'CA' && (
              <Region
                name="region"
                errors={isSubmitted ? errors : {}}
                register={register}
              />
            )}
            {watch('country') && (
              <PostalCode
                name="postalCode"
                placeholder={
                  watch('country') === 'US' ? 'Zip Code' : 'Postal Code'
                }
                errors={isSubmitted ? errors : {}}
                register={register}
              />
            )}
          </div>

          <ErrorMessage name="country" errors={isSubmitted ? errors : {}} />
          <ErrorMessage name="region" errors={isSubmitted ? errors : {}} />
          <ErrorMessage name="postalCode" errors={isSubmitted ? errors : {}} />
        </div>

        {coupon && (
          <FormGroup name="coupon" errors={errors}>
            <input
              type="text"
              value={coupon}
              className="form-input disabled"
              data-recurly="coupon"
              readOnly
            />
          </FormGroup>
        )}

        <div className="border-t-4 border-grey-200 mb-4" />
        {membership && plan?.type === 'individual' && (
          <>
            <div className="text-subdued text-sm">Current plan</div>
            <div>{membership.plan.name}</div>
            <div className="mb-4" />
            <div className="text-subdued text-sm">Upgrade to</div>
            <div>{plan?.name}</div>
          </>
        )}
        {plan && recurlyPlan && (
          <Summary recurlyPlan={recurlyPlan} promo={promo} />
        )}

        <div className="border-t-2 border-grey-200 mb-4" />
        <div className="px-4">
          <Terms
            name="terms"
            errors={isSubmitted ? errors : {}}
            register={register}
          />
        </div>
        <p className="text-xs text-subdued text-center mt-12 mb-4">
          All prices shown in USD
        </p>
        <div className="mt-auto md:mt-8 text-center">
          <div className="flex">
            <BackButton
              defaultHref="/plans"
              className="btn btn--sm btn--outline btn--full mr-4"
            />
            <SubmitButton
              text={hasMembership ? 'Upgrade now' : 'Sign up'}
              loading={loading}
              disabled={!recurlyId || !recurlyPlan || !isValid}
              fullWidth
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const UpgradeNotice = ({
  membership,
  isOnboardingRequired,
}: {
  membership: MembershipEntity | null;
  isOnboardingRequired: boolean;
}) => {
  return (
    <SignupLayout headerImg={DEFAULT_UPGRADE_REQUIRED_COVER_PHOTO_URL}>
      <div>
        <div className="mb-4">
          <ContentHeader heading="Plan information" />
        </div>
        <div className="text-center mb-4">
          <h3 className="mb-4">Your Plan.</h3>
          {membership && (
            <div className="text-lg mb-4">
              <span className="font-medium">{membership.plan.name}</span>{' '}
              <span className="text-subdued">
                {membership.plan.description}
              </span>
            </div>
          )}
          <div>
            Please contact{' '}
            <a
              className="text-link"
              href="mailto:concierge@communo.com?subject=Upgrade%20Membership"
            >
              concierge@communo.com
            </a>{' '}
            to change your plan.
          </div>
        </div>
        <div className="mt-auto md:mt-8 text-center">
          <div className="flex">
            {/* <BackButton
            defaultHref="/plans"
            className="btn btn--outline btn--full mr-4"
          /> */}
            {isOnboardingRequired ? (
              <Link
                to="/getting-to-know-you"
                className="btn btn--primary btn--full"
              >
                Continue
              </Link>
            ) : (
              <Link to="/feed" className="btn btn--primary btn--full">
                Home
              </Link>
            )}
          </div>
        </div>
      </div>
    </SignupLayout>
  );
};

type WrappedPaymentProps = {
  id: string;
  recurlyId: string;
  coupon?: string;
};

const WrappedPayment = (props: Props) => {
  const { slug, recurlyId, coupon } = props;

  const { plan, isFetching } = usePlanBySlug(slug);

  if (isFetching) {
    return (
      <div className="text-center py-20">
        <LoadingRing isActive color="primary" size="xl" />
      </div>
    );
  }

  return plan ? (
    <Payment plan={plan} recurlyId={recurlyId} coupon={coupon} />
  ) : null;
};

type Props = {
  slug: string;
  recurlyId: string;
  coupon?: string;
};
export default React.memo(({ slug, recurlyId, coupon }: Props) => {
  const {
    hasMembership,
    membership,
    canUpgrade,
    isOnboardingRequired,
  } = useContext(UserContext);
  return (
    <ErrorBoundary message="We're having trouble taking payments :/">
      {!hasMembership || canUpgrade ? (
        <SignupLayout>
          <div>
            <WrappedPayment slug={slug} recurlyId={recurlyId} coupon={coupon} />
          </div>
        </SignupLayout>
      ) : (
        <UpgradeNotice
          membership={membership}
          isOnboardingRequired={isOnboardingRequired}
        />
      )}
    </ErrorBoundary>
  );
});
