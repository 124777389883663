import React from 'react';
import { FormGroup, Input } from '../../core/form';
// import { Link } from '../../core/router';

// const ForgotLinkWrapper = ({ forgotLink, children }: any) =>
//   forgotLink ? (
//     <div className="relative">
//       <div className="absolute top-0 right-0 flex items-center h-full z-1">
//         <Link
//           to="#/auth/forgot-password"
//           className="text-link text-red-900 font-medium text-sm p-2"
//           tabIndex={-1}
//         >
//           Forgot?
//         </Link>
//       </div>
//       <div className="z-0">{children}</div>
//     </div>
//   ) : (
//     children
//   );

const Password = ({
  errors,
  register,
  label = 'Password',
  // forgotLink = false,
  skipSecurityCheck = false,
  labelVisible = false,
}: any) => {
  const minLength = skipSecurityCheck
    ? {}
    : {
        minLength: {
          value: 8,
          message: 'Password must be at least 8 characters',
        },
      };

  return (
    <FormGroup
      labelVisible={labelVisible}
      name="password"
      label={label}
      errors={errors}
    >
      {/* <ForgotLinkWrapper forgotLink={forgotLink}> */}
      <Input
        name="password"
        placeholder="password"
        type="password"
        errors={errors}
        ref={register({
          required: 'Password is required',
          ...minLength,
        })}
      />
      {!skipSecurityCheck && (
        <div className="mt-2 text-sm text-grey-900">
          Must be at least 8 characters
        </div>
      )}
      {/* </ForgotLinkWrapper> */}
    </FormGroup>
  );
};

export default Password;
