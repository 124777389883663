import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { PerkBundle, Perk } from '@communo-corp/shared-types';
import Panels from '../core/panels/panels';
import Filters from './Filters';
import { isMobile } from '../../utilities/Device';
import PageHeaderMobile from '../core/PageHeaderMobile';
import { addResponseError } from '../../services/Messaging';
import perksApi from './Api';
import BundlesDetailHeader from './BundlesDetailHeader';
import PerkCard from '../card/PerkCard';
import CollectionsSidebar from './CollectionsSidebar';
import useAnalytics from '../../hooks/useAnalytics';
import PageView from '../../analytics/PageView';

const BundlesDetail = () => {
  const [bundle, setBundle] = useState<PerkBundle | null>(null);
  const { id: bundleId } = useParams();

  const { pageIsReady } = useAnalytics();

  const getBundle = useCallback((id: string) => {
    perksApi
      .retrieveBundle(id)
      .then((response: PerkBundle) => {
        setBundle(response);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    if (bundleId) {
      getBundle(bundleId);
    }
  }, [getBundle, bundleId]);

  useEffect(() => {
    if (bundle) {
      pageIsReady({
        pageType: PageView.PERK_BUNDLE,
        pageId: bundle?.id,
        pageName: 'Perk Bundle',
        bundleId: bundle?.id,
        bundleName: bundle?.membership ? bundle.membership.name : bundle?.name,
        tagline: bundle?.tagline,
        description: bundle?.description,
        isFeatured: bundle?.isFeatured,
        isNew: bundle?.isNew,
        isPremium: bundle?.isPremium,
        visibleToAgencies: bundle?.visibleToAgencies,
        visibleToFreelancers: bundle?.visibleToFreelancers,
        membershipId: bundle?.membership?.id,
      });
    }
  }, [pageIsReady, bundle]);

  return (
    <Panels
      leftPanel={<Filters backToSearch />}
      contextPanel={<CollectionsSidebar />}
    >
      {toggleLeftPanel => (
        <>
          {bundle && (
            <div>
              {isMobile && (
                <PageHeaderMobile
                  title="Perks"
                  action={
                    // eslint-disable-next-line
                    <button
                      type="button"
                      onClick={() => toggleLeftPanel()}
                      className="btn btn--sm"
                    >
                      Filters <i className="i-filters text-lg ml-xs" />
                    </button>
                  }
                />
              )}
              <BundlesDetailHeader bundle={bundle} />
              <div className="px-4 py-6 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
                <div className="grid grid-cols-1 md:grid-cols-2 4xl:grid-cols-3 gap-4">
                  {bundle?.perks &&
                    bundle.perks
                      .sort((a: Perk, b: Perk) => a.order - b.order)
                      .map((perk: Perk) => {
                        const {
                          id,
                          name,
                          membership,
                          offerSummary,
                          horizontalHeaderImageUrl,
                          descriptionSummary,
                        } = perk;

                        return (
                          <PerkCard
                            key={id}
                            id={`${id}?bundleId=${bundleId}`}
                            name={name}
                            company={membership?.name}
                            offer={offerSummary}
                            logoURL={membership?.logoURL}
                            imageURL={horizontalHeaderImageUrl}
                            description={descriptionSummary}
                          />
                        );
                      })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Panels>
  );
};
export default BundlesDetail;
