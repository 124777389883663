import React from 'react';
import { TwilioMessageReplyDataAttributes } from '../../../@types/chat.d';
import Avatar from '../../avatar/Avatar';
import useChatUser from '../hooks/useChatUser';
import decodeMessage from '../utilities/decodeMessage';

type Props = TwilioMessageReplyDataAttributes & {
  isPreview?: boolean;
  onCancel?: () => void;
};

const MessageReply = ({
  recipientId,
  message,
  isPreview = false,
  onCancel,
}: Props) => {
  const { user, loaded } = useChatUser(recipientId);

  if (!user || !loaded) return null;

  const decodedMessage = decodeMessage(message);
  const {
    friendlyName,
    attributes: { avatarURL },
  } = user;

  return (
    <div className="chat-reply">
      <div className="chat-reply__body">
        <div className="chat-reply__header">
          <Avatar size="xxs" avatarUrl={avatarURL} />
          <div className="chat-reply__author">
            {isPreview ? 'Replying to: ' : null}
            {friendlyName}
          </div>
          {onCancel ? (
            <button
              type="button"
              className="chat-reply__cancel"
              onClick={onCancel}
            >
              <i className="i-close" />
            </button>
          ) : null}
        </div>
        <div className="chat-reply__text">{decodedMessage}</div>
      </div>
    </div>
  );
};

export default MessageReply;
