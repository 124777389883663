import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import BundlesManage from '../components/perk/BundlesManage';

const PerkBundlesManage = () => {
  useAnalytics('Perks Create');

  return (
    <Page
      authGuard
      pageTitle="Manage Bundles"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
    >
      <BundlesManage />
    </Page>
  );
};
export default PerkBundlesManage;
