import React from 'react';
// eslint-disable-next-line no-unused-vars
import { getBorderRadiusSize } from './CardSizes';
import Link from '../core/router/Link';

type Props = {
  children: string | JSX.Element[] | JSX.Element;
  to?: string | null;
  onClick?: Function | null;
  className?: string;
  borderRadius?: 'none' | 'small' | 'medium' | 'large';
  imageComponent?: JSX.Element | null;
};

const Card = ({
  imageComponent = null,
  to = null,
  onClick = null,
  borderRadius = 'medium',
  className = '',
  children,
}: Props) => {
  const Component = to ? Link : 'div';
  const handleClick = () => (onClick ? onClick() : null);

  return (
    <Component
      to={to ?? '#'}
      onClick={handleClick}
      className={`card ${className} ${getBorderRadiusSize(borderRadius)}`}
    >
      {imageComponent ?? null}
      <div className="flex-auto">{children}</div>
    </Component>
  );
};
export default React.memo(Card);
