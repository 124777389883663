import React, { useContext, useEffect } from 'react';
import useIsFormValid from '../../../hooks/useIsFormValid';
import { FormInput } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import { SubmitRecommendationContext } from '../context/SubmitRecommendationContext';

const RecommendationInfoStep = () => {
  const { setCanAdvance } = useStep();
  const { form } = useContext(SubmitRecommendationContext);
  const isValidForm = useIsFormValid(form, ['firstName', 'lastName', 'email']);

  useEffect(() => {
    setCanAdvance(isValidForm);
  }, [setCanAdvance, isValidForm]);

  return (
    <div>
      <FormInput
        name="firstName"
        label="First Name"
        required
        className="mt-2"
        errors={form.errors}
        ref={form.register({
          validate: (val: any) => !!val,
          required: 'First name is required',
        })}
      />
      <FormInput
        name="lastName"
        label="Last Name"
        required
        className="mt-2"
        errors={form.errors}
        ref={form.register({
          required: 'Last name is required',
        })}
      />
      <FormInput
        name="email"
        label="Email"
        required
        disabled
        ref={form.register({
          required: 'Email is required',
        })}
        className="mt-2"
      />
    </div>
  );
};
export default RecommendationInfoStep;
