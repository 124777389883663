import React, { useContext, useState, useCallback, useEffect } from 'react';
import { stringify, parse } from 'querystringify';
import { useLocation } from 'react-router';
import SearchContext from '../search/Context';
import useSearch from '../search/useSearch';
import { Link } from '../core/router';
import useNavigate from '../../hooks/useNavigate';

type Props = {
  backToSearch?: boolean;
  onFilterApplied?: Function;
};

type FilterProps = {
  q?: string;
  category?: string;
};

type LinkItemProps = {
  path: string;
  label: string;
  count: number;
  isActive: boolean;
  onChange?: Function;
};

type Params = {
  q?: string;
  category?: string;
};

const LinkItem = ({
  path,
  label,
  count,
  isActive,
  onChange = () => {},
}: LinkItemProps) => {
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    e.preventDefault();
    onChange(path);
    navigate(path, 'none', 'replace');
  };

  return (
    <Link
      to={path}
      className={`flex justify-between items-center py-2 border-t border-grey-100 ${
        isActive ? 'text-teal-900' : ''
      }`}
      onClick={handleClick}
    >
      <div>
        <span className="font-semibold">{label}</span> ({count})
      </div>
      <i className="i-caret-right text-grey-600 text-sm" />
    </Link>
  );
};

const Filters = ({
  backToSearch = false,
  onFilterApplied = () => {},
}: Props) => {
  const pathname = '/perks';
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { getPerks } = useContext(SearchContext);
  const [results, handleSearch] = useSearch(getPerks);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = parse(search) as Params;
  const loadFacets = useCallback(() => {
    return handleSearch({
      facets: {
        query: queryParams.q || '',
        filters: ['isActive=1', 'isBundleOnly!=1'].join(' AND '),
        facets: ['categories'],
        hitsPerPage: 0,
      },
    });
  }, [handleSearch, queryParams.q]);

  useEffect(() => {
    loadFacets();
  }, [loadFacets]);

  useEffect(() => {
    const { facets } = results;
    setCategories(facets?.facets.categories || []);
    setTotal(facets?.nbHits || 0);
  }, [results]);

  const buildPath = ({ q, category }: FilterProps) => {
    const params = { ...queryParams };
    if (q !== undefined) params.q = q;
    if (category !== undefined) params.category = category;
    const path = stringify(params, `${pathname}?`);
    return path;
  };

  const handleChange = (e: any) => {
    const path = buildPath({ q: e.target.value });
    navigate(path, 'none', 'replace');
  };

  const handleSearchApplied = (e: any) => {
    if (e.key === 'Enter') {
      onFilterApplied();
    }
  };

  return (
    <>
      <h2>Perks</h2>
      <div className="mt-10">
        {backToSearch ? (
          <Link to="/perks" className="text-base font-semibold">
            <i className="i-arrow-left text-sm mr-2" />
            Search
          </Link>
        ) : (
          <>
            <label htmlFor="inputSearch" className="sr-only">
              Search
            </label>
            <div className="input-group">
              <input
                id="inputSearch"
                className="form-input form-input--sm"
                type="search"
                placeholder="Search"
                defaultValue={queryParams.q}
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={handleSearchApplied}
              />
              <div className="input-group__icon">
                <i className="i-search text-xs" />
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="text-xl mt-8 mb-2">Types</div>
      <div className="flex justify-between items-center py-2 border-t border-grey-100">
        <div>
          <span className="font-semibold">All Offers</span> (28)
        </div>
        <i className="i-caret-right text-grey-600 text-sm" />
      </div>
      <div className="flex justify-between items-center py-2 border-t border-grey-100">
        <div>
          <span className="font-semibold">New Offers</span> (4)
        </div>
        <i className="i-caret-right text-grey-600 text-sm" />
      </div>
      <div className="flex justify-between items-center py-2 border-t border-b border-grey-100">
        <div>
          <span className="font-semibold">Premium Offers</span> (8)
        </div>
        <i className="i-caret-right text-grey-600 text-sm" />
      </div> */}

      <div className="text-xl mt-8 mb-2">Categories</div>
      <LinkItem
        path={buildPath({ category: '' })}
        label="All Categories"
        count={total}
        isActive={!queryParams.category}
        onChange={() => onFilterApplied()}
      />
      {Object.entries(categories).map(([label, count]) => (
        <LinkItem
          key={label}
          path={buildPath({ category: label })}
          label={label}
          count={count}
          isActive={queryParams.category === label}
          onChange={() => onFilterApplied()}
        />
      ))}
    </>
  );
};
export default React.memo(Filters);
