import { UserSummary } from '@communo-corp/shared-types';
import React from 'react';
import Avatar from '../../avatar/Avatar';

type Props = {
  user: UserSummary | null;
};

const AcceptedEmailInvite = ({ user }: Props) => {
  if (!user) {
    return null;
  }

  let displayName = 'Unknown user';
  if (user.firstName && user.lastName) {
    displayName = `${user.firstName} ${user.lastName}`;
  }

  return (
    <div className="flex mt-4">
      <Avatar avatarName={displayName} avatarUrl={user.avatarUrl} size="md" />
      <div className="ml-2 text-sm">
        <p className="font-semibold">{displayName}</p>
        <p className="truncate">{user.email}</p>
      </div>
    </div>
  );
};
export default AcceptedEmailInvite;
