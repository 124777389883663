import React from 'react';
import { ChatClientContextProvider } from './ChatClientContext';

/**
 * Provides all Chat related context.
 *
 * @param children any
 */
const ChatContextProvider = ({ children }: any) => {
  return <ChatClientContextProvider>{children}</ChatClientContextProvider>;
};
export default ChatContextProvider;
