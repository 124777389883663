import React from 'react';
import ListActionsItemBase from './ListActionsItemBase';

const ListActionsItemButton = ({
  start,
  end,
  className,
  onClick,
  children,
}: ListActionsItemProps & {
  onClick: () => void;
}) => {
  const classes = ['list-actions__item'];

  if (className?.length) {
    classes.push(className);
  }

  return (
    <button type="button" className={classes.join(' ')} onClick={onClick}>
      <ListActionsItemBase start={start} end={end}>
        {children}
      </ListActionsItemBase>
    </button>
  );
};

export default ListActionsItemButton;
