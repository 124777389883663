/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import { LoadingRing } from './Loading';
import { Link } from './router';

type ButtonLinkProps = {
  href: string;
  loading?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary';
  fill?: 'solid' | 'outline';
  expand?: 'block';
  text?: string;
  icon?: string;
  align?: 'start' | 'end';
  disabled?: boolean;
  fullWidth?: boolean;
  reverse?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

type ButtonLinkType = JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLAnchorElement> &
  React.ButtonHTMLAttributes<HTMLAnchorElement> &
  ButtonLinkProps;

export const ButtonLink = ({
  href,
  loading = false,
  children,
  text,
  color,
  fill,
  icon,
  align,
  fullWidth,
  reverse = false,
  size,
  ...rest
}: ButtonLinkType) => {
  let className = `btn ${rest.className || ''}`;
  if (color) className += ` btn--${color}`;
  if (fill === 'outline') className += ' btn--outline';
  if (align === 'start') className += ' mr-auto';
  if (align === 'end') className += ' ml-auto';
  if (fullWidth) className += ' btn--full';
  if (reverse) className += ' btn--rev';
  if (size) className += ` btn--${size}`;

  return (
    <Link {...rest} to={href} className={`relative ${className}`}>
      {loading ? (
        <div>
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <LoadingRing isActive />
          </div>
          &nbsp;
        </div>
      ) : (
        <>
          {icon && (
            <span className="btn__icon">
              <i className={`i-${icon}`} />
            </span>
          )}
          {text && <span className="btn__text">{text}</span>}
          {!text && children}
        </>
      )}
    </Link>
  );
};

export default ButtonLink;
