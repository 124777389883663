import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

export default ({ errors, register, defaultValue, isRequired = true }: any) => {
  const ref = register({ required: 'Please select a work start time frame' });

  return (
    <>
      <FormGroup
        required={isRequired}
        name="workStart"
        label="When do you need this work to start?"
        errors={errors}
      >
        <FormRadio
          name="workStart"
          value="0"
          defaultValue={defaultValue}
          label="Immediately"
          ref={ref}
        />
        <FormRadio
          name="workStart"
          value="1"
          label="2 to 4 weeks from now"
          ref={ref}
        />
        <FormRadio
          name="workStart"
          value="2"
          label="4 to 6 weeks from now"
          ref={ref}
        />
        {!isRequired && (
          <FormRadio
            name="workStart"
            value={null}
            label="Information not available"
            ref={ref}
          />
        )}
      </FormGroup>
    </>
  );
};
