/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Link, LinkProps } from 'react-router-dom';
import useNavigate from '../../../hooks/useNavigate';

interface HashLinkProps extends LinkProps {
  to: string;
}

export default ({ to, children, ...rest }: HashLinkProps) => {
  const navigate = useNavigate();
  const path = typeof to === 'string' && !to.startsWith('#') ? `#${to}` : to;

  const handleClick = (e: any) => {
    e.preventDefault();

    if (typeof path === 'string') {
      // Remove the hash after a modal is closed (if it has not changed to another hash).
      const params = window.location.search;

      // Note: Use NavContext.navigate to change the path so we don't animate the page behind.
      navigate(`${params}${path}`, 'none', 'push');
    }

    // pass the onClick handler if exists
    if (rest.onClick) {
      rest.onClick(e);
    }
  };

  return (
    <Link to={path} {...rest} onClick={handleClick}>
      {children}
    </Link>
  );
};
