import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../core/Button';
import SchedulerApplication from '../scheduler/SchedulerApplication';

type Props = {
  organizer: UserEntity | null;
  application: ApplicationEntity;
  work: WorkEntity;
  patchApplications: Function;
  hasStartedConversation: boolean;
  sendMeetingLink: (link: string) => void;
};

const ApplicationScheduler = ({
  organizer,
  application,
  work,
  patchApplications,
  hasStartedConversation,
  sendMeetingLink,
}: Props) => {
  const history = useHistory();
  const weblocation = useLocation();
  const editMeetingUrl = `${weblocation.pathname +
    weblocation.search}#/edit-meetings`;

  return (
    <>
      {organizer?.nylasAccessTokenExists ? (
        <SchedulerApplication
          application={application}
          work={work}
          patchApplications={patchApplications}
          editMeetingUrl={editMeetingUrl}
          hasStartedConversation={hasStartedConversation}
          sendMeetingLink={sendMeetingLink}
        />
      ) : (
        <Button
          type="button"
          onClick={() => history.push(editMeetingUrl)}
          color="primary"
          className="w-full"
        >
          <i className="i-calendar btn__icon" />
          <span className="btn__text">Schedule Interview</span>
        </Button>
      )}
    </>
  );
};

export default ApplicationScheduler;
