import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from '../../core/router';
import UserContext from '../Context';

export default () => {
  const { redirectUrl, setRedirectUrl } = useContext(UserContext);
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!redirectUrl) setRedirectUrl(`${pathname}${hash}`);
  }, [hash, pathname, redirectUrl, setRedirectUrl]);

  return (
    <div className="flex flex-col grow text-center">
      <div className="text-center mb-8">
        <h3 className="mb-2">Login to continue.</h3>
        <div className="text-lg mb-4">
          Communo is a closed community with exclusive content for members.
        </div>
        <div className="text-lg">Please log in to view this content.</div>
      </div>

      <div className="mt-auto md:mt-4 xl:mt-10">
        <Link to="/login" className="btn btn--primary btn--sm w-full">
          Login
        </Link>
      </div>
    </div>
  );
};
