import React from 'react';
import { CollectionSummary } from '@communo-corp/shared-types';
import { capitalize } from 'lodash';
import Modal from '../../core/modal/Modal';
import LinkInviteToCollection from './LinkInviteToCollection';
import EmailInviteToCollection from './EmailInviteToCollection';

type Props = {
  collection: CollectionSummary;
  onClose: Function;
};

const Content = ({ collection }: { collection: CollectionSummary }) => {
  const collectionType = capitalize(collection.type);

  return (
    <div>
      <div className="p-4 pt-0 md:p-8 md:pt-0">
        <p>
          Anyone you invite will automatically be added to your{' '}
          <strong>{collectionType}</strong> once they join Communo, or be added
          automatically if they are a Communo member.
        </p>
      </div>
      <EmailInviteToCollection collection={collection} />
      <LinkInviteToCollection collection={collection} />
    </div>
  );
};

const InviteToCollectionModal = ({ collection, onClose }: Props) => {
  const collectionType = capitalize(collection.type);

  return (
    <Modal
      isOpen
      title={`Invite to ${collectionType}`}
      width="3xl"
      paddingX="0"
      onClose={onClose}
    >
      <Content collection={collection} />
    </Modal>
  );
};
export default InviteToCollectionModal;
