import { Recommendation } from '@communo-corp/shared-types';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useUserRecommendations(
  userId: string
): {
  isFetching: boolean;
  recommendations: Recommendation[];
  refetch: Function;
} {
  const { data, isFetching, refetch } = useQuery(
    ['recommendations', userId],
    () => Api.listRecommendationsForUser(userId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isFetching,
    recommendations: data,
    refetch,
  };
}
