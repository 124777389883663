import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { track } from '../../../analytics';
import useNavigate from '../../../hooks/useNavigate';
import { addResponseError } from '../../../services/Messaging';
import cloudinary from '../../../utilities/Cloudinary';
import Initials from '../../../utilities/Initials';
import { SubmitButton } from '../../core/Button';
import { FormActions, FormRadio } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import userApi from '../../user/Api';
import UserContext from '../../user/Context';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';
import ThankYou from '../ThankYou';
import ThankYouMessage from '../ThankYouMessage';

interface GridImageProps {
  src: string;
}

const GridImage = ({ src }: GridImageProps) => {
  return (
    <img
      className="absolute inset-0 w-full h-full object-cover"
      src={cloudinary(src, ['w_600', 'h_600'])}
      alt="Person"
    />
  );
};

const Join = () => {
  const form = useForm({
    mode: 'onChange',
  });
  const { errors, register, handleSubmit } = form;
  const { authDetails } = useContext(UserContext);
  const { oauthProviderId } = authDetails;
  const { user } = useContext(SubmitReferenceContext);
  const { setTitle, setDescription } = useStep();
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();
  const avatarURL = user?.avatar;
  const fullName = user?.fullname || '';
  const nameParts = fullName.toUpperCase().split(' ');
  const initials = Initials(nameParts[0], nameParts[1]);

  const patchOAuthProviderOptIn = async (
    providerId: string,
    optIn: boolean
  ) => {
    try {
      await userApi.updateAuthProvider({
        oauthProviderId: providerId,
        optIn,
      });
      setShowThankYou(true);
    } catch (error) {
      addResponseError(error);
    }
  };

  const onSubmit = (values: any) => {
    // console.log('values', values);

    if (values.createProfile === 'yes') {
      track('Reference: Create Profile', {
        step: 'Join Communo',
      });

      navigate('/get-started', 'forward', 'push');
      return;
    }

    if (values.createProfile === 'maybe' && oauthProviderId) {
      track('Reference: Opt in', {
        step: 'Join Communo',
      });

      patchOAuthProviderOptIn(oauthProviderId, true);
      return;
    }

    if (values.createProfile === 'no' && oauthProviderId) {
      track('Reference: Opt out', {
        step: 'Join Communo',
      });

      setTitle('Thank you for your time');
      setDescription('We appreciate you leaving a reference.');
      patchOAuthProviderOptIn(oauthProviderId, false);
    }
  };

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Join Communo',
    });
  }, []);

  if (showThankYou) {
    return <ThankYouMessage />;
  }

  return (
    <div>
      <div className="content">
        <p>
          We can set you up with a free account and add {fullName} to your bench
          - and you can meet other vetted talent.
        </p>
      </div>

      <div className="mx-auto w-full my-8">
        <div className="grid grid-cols-2 gap-2">
          <div className="aspect-3/4 bg-grey-300 relative">
            {avatarURL ? (
              <GridImage src={avatarURL} />
            ) : (
              <div className="absolute inset-0 text-center flex flex-col justify-center text-white text-2xl font-semibold">
                {initials}
              </div>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 h-full">
              <div className="bg-grey-300 relative">
                <GridImage src="https://res.cloudinary.com/communo/image/upload/v1597602362/prod/user/58b505c425c750f12/images/hfy7eo4i8ighd1rrcvoy.jpg" />
              </div>
              <div className="bg-grey-300 relative">
                <GridImage src="https://res.cloudinary.com/communo/image/upload/v1593183368/prod/user/b43943e1471a635a2/images/nztbiyweiqhzhvzv4njg.jpg" />
              </div>
              <div className="bg-grey-300 relative">
                <GridImage src="https://res.cloudinary.com/communo/image/upload/v1611339933/prod/user/e845d1e09bcf45568a374796869e6a18/image/sgz1qprjqo1skxipeemk.png" />
              </div>
              <div className="bg-grey-300 relative">
                <GridImage src="https://res.cloudinary.com/communo/image/upload/96e496ec4853bddd1/profile/amy1ontdbmx5xn9yiois.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRadio
          name="createProfile"
          label="Yes please, let’s set up a profile for me!"
          value="yes"
          errors={errors}
          ref={register({ required: 'Please select an option' })}
        />
        <FormRadio
          name="createProfile"
          label="No, but I’d like an easier way to find talent."
          value="maybe"
          errors={errors}
          ref={register({ required: 'Please select an option' })}
        />
        <FormRadio
          name="createProfile"
          label="No thanks."
          value="no"
          errors={errors}
          ref={register({ required: 'Please select an option' })}
        />
        <FormActions>
          <div className="w-full max-w-72 mx-auto mt-8">
            <SubmitButton text="Submit" className="w-full" />
          </div>
        </FormActions>
      </form>
    </div>
  );
};

const WrappedJoinStep = () => {
  const { isAuthenticated } = useContext(UserContext);

  return isAuthenticated ? <ThankYou /> : <Join />;
};

export default WrappedJoinStep;
