import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LoadingBalls } from '../core/Loading';
import AvatarForm from '../user/forms/AvatarForm';
import { FormActions, FormInput } from '../core/form';

import { BaseAPI } from '../../Api';
import { SubmitButton } from '../core/Button';
import Alert from '../alert/Alert';

interface FormFields {
  name: string;
  logoURL: string;
}

const CreateCompanyForm = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  const form = useForm<FormFields>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      name: '',
      logoURL: '',
    },
  });

  const { handleSubmit, register, errors, setValue } = form;
  const onSubmit = (values: FormFields) => {
    if (loading) return;
    setLoading(true);

    const { name, logoURL } = values;

    const companyAPI = BaseAPI('company');
    companyAPI
      .create({ name, logoURL })
      .then(() => {
        setLoading(false);
        history.push('/work/create');
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        setShowError(true);
      });
  };

  const handleLogoChange = (logoURL: string) => {
    setValue('logoURL', logoURL);
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <AvatarForm
          id="logoURL"
          label="Company Logo"
          name="logoURL"
          onChange={handleLogoChange}
        />
        <input type="hidden" name="logoURL" ref={register} />
        <FormInput
          name="name"
          label="Company Name"
          defaultValue=""
          placeholder="Company Name"
          errors={errors}
          ref={register({ required: 'Company name is required' })}
        />
        <FormActions className="mt-auto justify-center">
          <div className="flex justify-end w-full">
            <SubmitButton text="Create" loading={loading} />
          </div>
        </FormActions>
      </form>
      <Alert
        isOpen={showError}
        onDidDismiss={() => {
          setShowError(false);
        }}
        header="Error"
        message="Failed to create company, did you miss the logo?"
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
            cssClass: 'alert-primary-btn',
            handler: () => {},
          },
        ]}
      />
    </>
  );
};

export default CreateCompanyForm;
