import React, { useContext, useMemo } from 'react';
import { HashLink } from '../components/core/router';
import MembershipContext from '../components/membership/Context';

const individualVettingUrl = 'https://communo.com/vetting-endorsement/';
const agencyVettingUrl = 'mailto:concierge@communo.com';

const StarterVetting = () => {
  return (
    <>
      <h5 className="text-center text-lg mb-4">Upgrade required for Vetting</h5>
      <div>
        Vetting helps determine your fit within the community. Before you can
        become vetted, you must{' '}
        <span className="font-bold">upgrade to a Freelancer Pro</span> account.
      </div>
      <div className="flex justify-end mt-8">
        <HashLink to="" className="btn btn--outline btn--tertiary btn--sm mr-4">
          Close
        </HashLink>
        <HashLink to="#/upgrade" className="btn btn--primary btn--sm">
          Upgrade to Pro
        </HashLink>
      </div>
    </>
  );
};

const GetVetted = () => {
  const { membership } = useContext(MembershipContext);
  const planType = membership?.plan?.type ?? null;

  const handleDismiss = () => {
    window.location.hash = '';
  };

  if (membership?.plan.name === 'Starter') return <StarterVetting />;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const url = useMemo(() => {
    return planType === 'individual' ? individualVettingUrl : agencyVettingUrl;
  }, [planType]);

  return (
    <>
      <h5 className="text-center text-lg mb-4">Eligible for Vetting</h5>
      <div>
        Vetting helps determine your fit within the community. You can start the
        process by following the link below.
      </div>
      <div className="flex justify-end mt-8">
        <HashLink to="" className="btn btn--outline btn--tertiary btn--sm mr-4">
          Close
        </HashLink>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--primary btn--sm"
          onClick={handleDismiss}
        >
          Get Vetted
        </a>
      </div>
    </>
  );
};

export default React.memo(GetVetted);
