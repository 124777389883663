import React from 'react';
import Button from '../../core/Button';
import Modal from '../../core/modal/Modal';
import AssignRecruiter from '../AssignRecruiter';

type Props = {
  membershipId: string | undefined;
  refreshWork: Function;
  work: WorkEntity;
  showModal: boolean;
  toggleAssignModal: (bool: boolean) => void;
};

const AssignWorkModal = ({
  membershipId,
  refreshWork,
  work,
  showModal,
  toggleAssignModal,
}: Props) => {
  return (
    <Modal
      isOpen={showModal}
      onClose={() => toggleAssignModal(false)}
      title="Assign to a recruiter"
      width="xl"
    >
      <div className="min-h-screen50 justify-between flex flex-col">
        <AssignRecruiter
          work={work}
          membershipId={membershipId || ''}
          refreshWork={refreshWork}
          onComplete={() => {
            toggleAssignModal(false);
          }}
        />
        <div className="flex justify-end mt-6">
          <Button
            fill="outline"
            color="tertiary"
            size="sm"
            onClick={() => toggleAssignModal(false)}
            text="Close"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssignWorkModal;
