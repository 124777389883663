import React, { useContext, useEffect } from 'react';
import { track } from '../../../analytics';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';
import ReferenceRangeSlider from '../ReferenceRangeSlider';

const Rate = () => {
  const { form } = useContext(SubmitReferenceContext);

  const {
    quality,
    speed,
    value: valueRating,
    communication,
    collaboration,
  } = form.watch();

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Rate the candidate',
    });
  }, []);

  return (
    <div className="px-4">
      <ReferenceRangeSlider
        label="Quality of Work"
        className="border-b border-grey-100 mb-2"
        defaultValue={quality}
        onChange={value => {
          form.setValue('quality', value.value);
        }}
      />
      <ReferenceRangeSlider
        label="Speed"
        className="border-b border-grey-100 mb-2"
        defaultValue={speed}
        onChange={value => {
          form.setValue('speed', value.value);
        }}
      />
      <ReferenceRangeSlider
        label="Value"
        className="border-b border-grey-100 mb-2"
        defaultValue={valueRating}
        onChange={value => {
          form.setValue('value', value.value);
        }}
      />
      <ReferenceRangeSlider
        label="Communication"
        className="border-b border-grey-100 mb-2"
        defaultValue={communication}
        onChange={value => {
          form.setValue('communication', value.value);
        }}
      />
      <ReferenceRangeSlider
        label="Collaboration"
        className="mb-2"
        defaultValue={collaboration}
        onChange={value => {
          form.setValue('collaboration', value.value);
        }}
      />
    </div>
  );
};
export default Rate;
