import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CollectionType } from '@communo-corp/shared-types';
import useGlobalCollectionUserIds from '../hooks/useGlobalCollectionUserIds';

type CollectionsContext = {
  isUserInAnyCollection: (userId: string) => boolean;
  isUserInBench: (userId: string) => boolean;
  isUserInList: (userId: string) => boolean;
  isUserInGroup: (userId: string) => boolean;
  updateUserIdsCollectionCache: (
    userIds: string[],
    types: CollectionType[]
  ) => void;
};

export const CollectionsContext = React.createContext<CollectionsContext>({
  isUserInAnyCollection: () => false,
  isUserInBench: () => false,
  isUserInList: () => false,
  isUserInGroup: () => false,
  updateUserIdsCollectionCache: () => {},
});

export const useCollectionsContext = () => useContext(CollectionsContext);

export const CollectionsContextProvider = ({ children }: any) => {
  const [state, setState] = useState<{
    list: {
      [id: string]: boolean;
    };
    bench: {
      [id: string]: boolean;
    };
    group: {
      [id: string]: boolean;
    };
  }>({
    list: {},
    bench: {},
    group: {},
  });

  const { data } = useGlobalCollectionUserIds();

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);

  const contextValue = useMemo(() => {
    return {
      isUserInAnyCollection: (userId: string) => {
        return (
          state?.list?.[userId] !== undefined ||
          state?.bench?.[userId] !== undefined ||
          state?.group?.[userId] !== undefined
        );
      },

      isUserInBench: (userId: string) => {
        return state?.bench?.[userId] !== undefined;
      },

      isUserInList: (userId: string) => {
        return state?.list?.[userId] !== undefined;
      },

      isUserInGroup: (userId: string) => {
        return state?.group?.[userId] !== undefined;
      },

      updateUserIdsCollectionCache: (
        userIds: string[],
        types: CollectionType[]
      ) => {
        setState((prev: any) => {
          const prevState = { ...prev };

          userIds.forEach(userId => {
            // Clear existing values
            delete prevState.bench[userId];
            delete prevState.list[userId];
            delete prevState.group[userId];

            // Reset based on types provided
            types.forEach(type => {
              prevState[type][userId] = true;
            });
          });

          return prevState;
        });
      },
    };
  }, [state]);

  return (
    <CollectionsContext.Provider value={contextValue}>
      {children}
    </CollectionsContext.Provider>
  );
};
