import React from 'react';
import Page from '../components/core/Page';
import { SignupHBCU } from '../components/user/views';

const SignupHBCUPage = () => {
  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--white"
      hideHeader
      unMountOnLeave
    >
      <SignupHBCU />
    </Page>
  );
};
export default SignupHBCUPage;
