/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
// Search Type Definitions

import { SearchOptions, MultipleQueriesQuery } from '@algolia/client-search';

export enum SearchIndexes {
  Category = 'category',
  Industry = 'industry',
  Membership = 'membership',
  Role = 'role',
  School = 'school',
  Skill = 'skill',
  Work = 'work',
  Event = 'event',
  User = 'user',
  Perk = 'perks-bundles',
}

type Search = (params?: SearchOptions) => Promise<Response<T>>;
type UserSearch = (
  params?: SearchOptions,
  visibleOnly?: boolean
) => Promise<Response<T>>;
type Index = (id: string) => Promise<Response<T>>;

type MembershipSearch = (
  params?: SearchOptions,
  visibleOnly?: boolean
) => Promise<Response<T>>;

type ObjectSearch = (index: Indexes, ids: string[]) => Promise<Response<T>>;

type SearchMultipleQueries = (
  queries: MultipleQueriesQuery[]
) => Promise<MultipleQueriesResponse<TObject>>;

interface SearchIndexByType {
  (params?: SearchOptions): Promise<Response<T>>;
}

export type ContextInterface = {
  isReady: boolean;
  client: SearchClient | undefined;
  search(
    indexName: SearchIndexes,
    params?: SearchOptions
  ): Promise<Response<T>>;
  multipleQueries: SearchMultipleQueries;
  getIndex: (indexName: SearchIndexes) => SearchIndex;
  getObjects: ObjectSearch;
  getUsers: UserSearch;
  getWork: Search;
  getEvents: Search;
  getCategories: Search;
  getIndustries: Search;
  getRoles: Search;
  getSkills: Search;
  getMembers: Search;
  getMembersForSearch: Search;
  getUsersForSearch: Search;
  getUserById: Index;
  getMemberships: MembershipSearch;
  getPerks: Search;
  getSchools: (query: string, isHBCU?: boolean) => Promise<Response<T>>;
};
