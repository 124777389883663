import React from 'react';
import cloudinary from '../../utilities/Cloudinary';

const PortfolioItemCover = ({ item }: { item: PortfolioEntity }) => {
  let cover = null;
  if (item.headerPhotoURL) {
    cover = item.headerPhotoURL;
  }

  if (cover === null && item.imageURLs?.length) {
    [cover] = item.imageURLs;
  }

  if (cover === null) return null;

  return (
    <img
      className="absolute bottom-0 right-0 w-full h-full object-cover transform origin-center transition duration-500 group-hover:scale-105"
      src={cloudinary(cover, ['w_726', 'h_408'])}
      alt={item.description || item.title || ''}
    />
  );
};

export default PortfolioItemCover;
