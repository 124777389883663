/**
 * This component is used to preload Ionic components
 * so that the user does not have to wait for the chunked
 * .js file to load when they  interact with the UI.
 */

import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonRadio,
  IonRange,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

const Preloader = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1500);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className="hidden">
      <IonAlert isOpen={false} />
      <IonBackButton />
      <IonButtons />
      <IonHeader />
      <IonIcon />
      <IonLabel />
      <IonList />
      <IonRadio />
      <IonRange />
      <IonSegmentButton />
      <IonTitle />
      <IonToolbar />
    </div>
  );
};
export default Preloader;
