import React, { useContext, useRef } from 'react';
import { addSuccess, addResponseError } from '../../../services/Messaging';
import Button from '../../core/Button';
import { FormGroup, FormInput, FormRadio } from '../../core/form';
import ImageUploader from '../../image/ImageUploader';
import MembershipContext from '../Context';

const Tagline = ({
  form,
  membership,
}: {
  form: any;
  membership: MembershipEntity;
}) => {
  const { update, refresh } = useContext(MembershipContext);
  const graphicRef = useRef();

  const { watch, register, errors } = form;

  const taglineType = watch('taglineType');

  const browseFiles = (pondRef: any) => {
    pondRef.current.removeFile();
    pondRef.current.browse();
  };

  const onGraphicTaglineChange = (imageUrl: any) => {
    update({
      graphicTagline: imageUrl,
    })
      .then((response: MembershipEntity) => {
        addSuccess('Graphic tagline saved!');
        refresh();
        return response;
      })
      .catch(addResponseError);
  };

  return (
    <>
      <FormGroup name="taglineType" label="Tagline" errors={errors}>
        <div className="inline-block mr-4">
          <FormRadio
            name="taglineType"
            label="Text"
            value="text"
            ref={register()}
          />
        </div>
        <div className="inline-block">
          <FormRadio
            name="taglineType"
            label="Graphic"
            value="graphic"
            ref={register()}
          />
        </div>

        {taglineType === 'text' ? (
          <FormInput
            name="tagline"
            label={null}
            placeholder=""
            className="mt-2"
            errors={errors}
            ref={register()}
          />
        ) : null}

        {taglineType === 'graphic' ? (
          <div>
            <ImageUploader
              ref={graphicRef}
              aspectRatio={null}
              dokaConfig={{
                cropMask: null,
              }}
              onChange={onGraphicTaglineChange}
            >
              <>
                {membership.graphicTagline ? (
                  <div className="bg-grey-400 relative">
                    <img src={membership.graphicTagline} alt="base64" />

                    <button
                      type="button"
                      className="rounded-full absolute bottom-0 right-0 p-3 mb-2 mr-2 bg-red-900 text-white leading-none focus:outline-none"
                      onClick={() => onGraphicTaglineChange(null)}
                    >
                      <i className="i-trash" />
                    </button>
                  </div>
                ) : null}
                <div className="mt-4">
                  <Button
                    fill="outline"
                    color="primary"
                    size="sm"
                    onClick={() => browseFiles(graphicRef)}
                  >
                    {membership.graphicTagline ? 'Change' : 'Upload a'} slogan
                    image
                  </Button>
                </div>
              </>
            </ImageUploader>
            <p className="text-grey-800 text-sm mt-2">
              Upload a white, transparent .png.
            </p>
          </div>
        ) : null}
      </FormGroup>
    </>
  );
};
export default Tagline;
