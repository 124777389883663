import React from 'react';
import { IonHeader, IonBackButton } from '@ionic/react';

interface Props {
  title?: string;
  subtitle?: string;
  action?: JSX.Element[] | JSX.Element;
  className?: string;
}

const PageHeaderMobile = ({ title, subtitle, action, className }: Props) => {
  const classes = ['page-header-mobile'];

  if (className?.length) {
    classes.push(className);
  }

  return (
    <IonHeader className={classes.join(' ')}>
      <div className="flex items-center truncate">
        <div>
          <IonBackButton className="text-white text-base" defaultHref="/feed" />
        </div>
        <div className="truncate">
          {title && <h1 className="truncate">{title}</h1>}
          {subtitle && <h4 className="truncate">{subtitle}</h4>}
        </div>
      </div>
      {action}
    </IonHeader>
  );
};
export default PageHeaderMobile;
