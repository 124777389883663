import { CollectionInvite } from '@communo-corp/shared-types';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

interface CollectionInviteResponse extends CollectionInvite {
  brandColor: string;
  brandCoverPhotoURL: string | null;
  brandLogoURL: string | null;
  collectionId: string;
  collectionName: string;
  collectionType: string;
  id: string;
  ownerName: string | null;
  ownerImage: string | null;
}

export default function useCollectionInvite(
  inviteId: string
): {
  isLoading: boolean;
  invite: CollectionInviteResponse;
} {
  const { data, isLoading } = useQuery(
    ['invite', inviteId],
    () => Api.getInviteById(inviteId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    invite: data,
  };
}
