import React, { useState } from 'react';
import { addResponseError, addSuccess } from '../../services/Messaging';
import EyeIcon from '../icons/EyeIcon';
import EyeOffIcon from '../icons/EyeOffIcon';
import Api from './Api';

interface Props {
  id: string;
  visibility: boolean;
  onToggle(): void;
}

const ReferenceVisibilityToggle = ({ id, visibility, onToggle }: Props) => {
  const [isVisible, setIsVisible] = useState(visibility);

  const handleToggle = async () => {
    try {
      const resp = await Api.toggleVisibility(id, !isVisible);
      addSuccess(resp.message);
      setIsVisible(prevState => !prevState);
      onToggle();
    } catch (error) {
      addResponseError(error);
    }
  };

  return (
    <button type="button" onClick={handleToggle} className="py-4">
      {isVisible ? (
        <EyeIcon className="h-6 w-6 text-grey-800 inline-block mr-2" />
      ) : (
        <EyeOffIcon className="h-6 w-6 text-grey-800 inline-block mr-2" />
      )}
      <span className="text-sm">{isVisible ? 'Visible' : 'Hidden'}</span>
    </button>
  );
};
export default ReferenceVisibilityToggle;
