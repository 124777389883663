import React from 'react';
import { FormInput } from '../../core/form';

interface Props {
  errors: any;
  register: any;
}

const Minor = ({ errors, register }: Props) => {
  return (
    <FormInput
      name="minor"
      label="Minor"
      placeholder="Your education minor"
      errors={errors}
      ref={register()}
    />
  );
};
export default Minor;
