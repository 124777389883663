import React, { useEffect, useContext } from 'react';
import { PlanType } from '@communo-corp/shared-types';
import { Link, NavLink } from '../../core/router';
import AboutForm from '../forms/AboutForm';
import BasicForm from '../forms/BasicForm';
import LinksForm from '../forms/LinksForm';
import RolesForm from '../forms/RolesForm';
import PortfolioForm from '../forms/PortfolioForm';
import ErrorBoundary from '../../core/ErrorBoundary';
import UserContext from '../Context';
import Loading from '../../core/Loading';
import EducationForm from '../forms/EducationForm';
import MembershipContext from '../../membership/Context';
import RecommendationsForm from '../forms/RecommendationsForm';
import Capitalize from '../../../utilities/Capitalize';
import { page } from '../../../analytics';
import RateForm from '../forms/RateForm';
import ReferencesForm from '../forms/ReferencesForm';
import ProfileCompletion, {
  freelancerRequirements,
  employeeRequirements,
} from './ProfileCompletion';
import userCompletenessStore, {
  UserProfileAttribute,
} from '../state/userCompletenessStore';
import MetricStatusIcon from '../../candidateScore/MetricStatus';
import ExperienceForm from '../forms/ExperienceForm';
import { ENABLE_EXPERIENCE_LEVEL } from '../../../config';

type Link = {
  to: string;
  label: string;
  permission?: Permission;
  completionRequirements?: UserProfileAttribute[];
};

function getAllRequirements(isIndividualPlan: boolean): UserProfileAttribute[] {
  const requirements = isIndividualPlan
    ? freelancerRequirements
    : employeeRequirements;

  return Object.values(requirements)
    .flat()
    .filter(requirement => {
      // Don't include stage three requirements because they are "nice to have"
      return !requirements.stageThree.includes(requirement);
    });
}

function getMenuItems(isIndividualPlan: boolean): Link[] {
  return [
    {
      to: '#/edit-profile/completion',
      label: 'Profile completion',
      completionRequirements: getAllRequirements(isIndividualPlan),
    },
    {
      to: '#/edit-profile/basic',
      label: 'Basic info',
      completionRequirements: ['title'],
    },
    {
      to: '#/edit-profile/roles',
      label: 'Roles & skills',
      completionRequirements: ['skills', 'industries'],
    },
    {
      to: '#/edit-profile/links',
      label: 'Links',
      completionRequirements: ['socialLinks'],
    },
    {
      to: '#/edit-profile/about',
      label: 'About',
      completionRequirements: ['summary', 'about'],
    },
    {
      to: '#/edit-profile/experience',
      label: 'Experience',
      permission: ENABLE_EXPERIENCE_LEVEL
        ? 'canSetExperience'
        : 'hideSetExperience',
      completionRequirements: ['experience'],
    },
    {
      to: '#/edit-profile/rate',
      label: 'Rate',
      permission: 'canSetRate',
      completionRequirements: isIndividualPlan ? ['rate'] : [],
    },
    {
      to: '#/edit-profile/portfolio',
      label: 'Portfolio',
      completionRequirements: isIndividualPlan ? ['portfolioItems'] : [],
    },
    {
      to: '#/edit-profile/recommendations',
      label: 'Recommendations',
      permission: 'canAddRecommendations',
    },
    {
      to: '#/edit-profile/references-vetting',
      label: 'References',
      // permission: 'canAddRecommendations',
      completionRequirements: isIndividualPlan ? ['references'] : [],
    },
    {
      to: '#/edit-profile/education',
      label: 'Education',
      permission: 'canAddEducation',
      completionRequirements: ['education'],
    },
  ];
}

type Props = {
  match: {
    params: {
      form:
        | 'completion'
        | 'basic'
        | 'about'
        | 'experience'
        | 'rate'
        | 'links'
        | 'roles'
        | 'portfolio'
        | 'education'
        | 'recommendations'
        | 'references-vetting';
      action: any;
    };
  };
  setModalTitle: (title: string) => void;
  title: string;
};

export default ({
  match: {
    params: { form, action },
  },
  setModalTitle,
  title,
}: Props) => {
  const { user, refresh } = useContext(UserContext);
  const { checkAccess, isIndividualPlan, membership } = useContext(
    MembershipContext
  );
  const [completenessStore] = userCompletenessStore;
  const [getStatusForAll] = completenessStore(state => [state.getStatusForAll]);
  const links = getMenuItems(isIndividualPlan);
  const canSeeReferences =
    isIndividualPlan || membership?.plan.type !== PlanType.STUDENT;

  useEffect(() => {
    page(
      `Edit Profile ${Capitalize(form) +
        (action ? ` - ${Capitalize(action)}` : '')}`
    );
  }, [action, form]);

  const filteredLinks = links.filter((link: Link) => {
    const { permission } = link;

    // TODO: We need a better way to do this.
    if (link.label === 'References' && !canSeeReferences) {
      return false;
    }

    if (!permission) {
      return true;
    }

    return checkAccess(permission);
  });

  useEffect(() => {
    const modalTitle = links.find(link => {
      return link.to.includes(form);
    });
    if (modalTitle) {
      // makes the label into sentence case
      const labelToSentenceCase = modalTitle.label
        .toLowerCase() // make sure that we're dealing with an expected string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // capitalizes first character
        .join(' ');
      setModalTitle(labelToSentenceCase);
    } else {
      setModalTitle(title);
    }
  }, [form, title, setModalTitle, links]);

  return (
    <ErrorBoundary>
      <div className="">
        <Loading isActive={!user} />
        <div className="row flex">
          <div
            className={`${
              form ? 'hidden' : ''
            } md:block col w-full md:w-1/3 self-start sticky top-0`}
          >
            <ul className="nav-links">
              {filteredLinks.map(
                ({ to, label, completionRequirements }: Link) => {
                  const status = completionRequirements
                    ? getStatusForAll(completionRequirements)
                    : null;

                  return (
                    <li className="nav-links__item" key={to}>
                      <NavLink className="nav-links__link" to={to}>
                        <div className="flex">
                          <MetricStatusIcon status={status} />
                          <div className="pl-2">{label}</div>
                        </div>
                        <i className="i-caret-right md:hidden" />
                      </NavLink>
                    </li>
                  );
                }
              )}
            </ul>
          </div>

          <div
            className={`${form ? '' : 'hidden'} md:block col w-full md:w-2/3`}
          >
            {form === 'completion' && membership && (
              <ProfileCompletion
                isIndividualPlan={isIndividualPlan}
                membership={membership}
              />
            )}
            {user && form === 'basic' && <BasicForm user={user} />}
            {user && form === 'roles' && <RolesForm user={user} />}
            {user && form === 'about' && <AboutForm user={user} />}
            {ENABLE_EXPERIENCE_LEVEL && user && form === 'experience' && (
              <ExperienceForm />
            )}
            {user && form === 'rate' && <RateForm />}
            {user && form === 'links' && <LinksForm user={user} />}
            {user && form === 'portfolio' && (
              <PortfolioForm user={user} refresh={refresh} />
            )}
            {user && form === 'education' && <EducationForm />}
            {user && form === 'recommendations' && <RecommendationsForm />}
            {user && canSeeReferences && form === 'references-vetting' && (
              <ReferencesForm user={user} />
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
