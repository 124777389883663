/* eslint-disable no-console */
import React, { useCallback, useRef, useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { animated, useSpring } from 'react-spring';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isPlatform } from '@ionic/core';
import { JSONValue } from '@twilio/conversations';
import { IonIcon, IonAlert } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';

import { relativeTime } from '../../../utilities/dateTime';
import Avatar from '../../avatar/Avatar';
import {
  IMessage,
  TwilioMessageReplyDataAttributes,
  IChannel,
} from '../../../@types/chat.d';
import useChatUser from '../hooks/useChatUser';
import ReadReceipts from './ReadReceipts';
import useCurrentUser from '../../../hooks/useCurrentUser';
import MessageBody from './MessageBody';
import useChatMessageInstance from '../hooks/useChatMessageInstance';
import { Link } from '../../core/router';
import MessageReply from './MessageReply';
import { isDesktop } from '../../../utilities/MatchMedia';
import { addError, addSuccess } from '../../../services/Messaging';
import useChannelRole from '../hooks/useChannelRole';

const isNative = isPlatform('capacitor');

const Swipeable = ({
  swipeLeft,
  actions,
  onComplete,
  children,
}: {
  swipeLeft: boolean;
  actions: any;
  onComplete?: () => void;
  children: any;
}) => {
  const isActionable = useRef(false);
  const triggerThreshold = 100;
  const dragDampener = 3;

  const [{ x, o }, set] = useSpring(() => ({
    x: 0,
    o: 0,
    config: { tension: 500, friction: 30 },
  }));
  const bind = useGesture(
    {
      onDrag: ({ down, movement, distance }) => {
        const [moveX] = movement;

        // Restrict user messages from dragging right || Restrict other messages from dragging left
        if ((swipeLeft && moveX > 0) || (!swipeLeft && moveX < 0)) {
          set({ x: 0 });
          return;
        }

        if (distance > triggerThreshold) {
          if (!isActionable.current) {
            if (isNative) Haptics.impact({ style: ImpactStyle.Heavy });
            else if (window.navigator.vibrate) window.navigator.vibrate(100);
          }

          isActionable.current = true;
        } else {
          isActionable.current = false;
        }

        set({ x: down ? moveX / dragDampener : 0 });
        set({ o: down ? distance / triggerThreshold : 0 });
      },
      onDragEnd: () => {
        if (isActionable.current) {
          if (onComplete) onComplete();
          isActionable.current = false;
        }
      },
    },
    {
      drag: {
        axis: 'x',
      },
    }
  );

  return (
    <div className="chat-message__content">
      <animated.div
        className="chat-bubble"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bind()}
        style={{
          transform: x.interpolate(
            // TODO: Fix typescript once React Spring has proper support
            // @ts-ignore
            (xVal: number) => `translate3d(${xVal}px, 0, 0)`
          ),
        }}
      >
        {children}
      </animated.div>
      <animated.div
        className="chat-message__actions"
        style={{
          opacity: o.interpolate((oVal: number) => oVal),
        }}
      >
        {actions}
      </animated.div>
    </div>
  );
};

const MessageContent = ({
  swipeLeft,
  actions,
  onSwipe,
  children,
}: {
  swipeLeft: boolean;
  actions: any;
  onSwipe?: () => void;
  children: any;
}) => {
  if (isDesktop)
    return (
      <div className="chat-message__content">
        <div className="chat-bubble">{children}</div>
        <div className="chat-message__actions">{actions}</div>
      </div>
    );
  return (
    <Swipeable swipeLeft={swipeLeft} actions={actions} onComplete={onSwipe}>
      {children}
    </Swipeable>
  );
};

type MessageProps = {
  channel: IChannel;
  message: IMessage;
  onReply: (payload: TwilioMessageReplyDataAttributes) => void;
  onScrollToReply: (messageSid: string) => void;
};

const Message = ({
  channel,
  message,
  onReply,
  onScrollToReply,
}: MessageProps) => {
  const { sid, author: identity, index, attributes } = message;
  const replyData = attributes?.replyData;

  const { user: currentUser } = useCurrentUser();
  const { user } = useChatUser(identity);
  const { messageInstance } = useChatMessageInstance(sid);
  const currentUserRole = useChannelRole(channel);
  const [
    isShowingDeleteMessagePrompt,
    setIsShowingDeleteMessagePrompt,
  ] = useState(false);

  const isCurrentUser = currentUser?.id === identity;
  const classes = [
    'chat-message',
    isCurrentUser ? 'chat-message--me' : 'chat-message--them',
  ];

  const handleUpdateAttributes = (payload: JSONValue) => {
    // TODO: This could be improved to hit a server endpoint to update message attributes
    // so we're not concerned if logged in user has permissions
    if (isCurrentUser) messageInstance.updateAttributes(payload);
  };

  const handleReply = useCallback(
    () =>
      onReply({
        recipientId: identity,
        messageSid: sid,
        message: message.body,
      }),
    [onReply, identity, sid, message.body]
  );

  const handleScrollToReply = () => {
    if (!replyData) return;

    onScrollToReply(replyData.messageSid);
  };

  const handleDeleteMessage = () => {
    setIsShowingDeleteMessagePrompt(true);
  };

  const canDeleteMessage =
    currentUserRole.isModerator || currentUserRole.isOwner;

  const actions = (
    <>
      {canDeleteMessage && (
        <button
          type="button"
          className="btn btn--circle bg-grey-100 mx-2"
          onClick={handleDeleteMessage}
          aria-label="delete message"
        >
          <IonIcon icon={trashOutline} />
        </button>
      )}
      <button
        type="button"
        className="btn btn--circle bg-grey-100"
        onClick={handleReply}
        aria-label="Reply"
      >
        <i className="i-reply text-grey-700" />
      </button>
    </>
  );

  return (
    <div id={message.sid} className={classes.join(' ')} role="none">
      <div className="chat-message__meta-container">
        <div className="chat-timestamp">
          {relativeTime(message.dateCreated)}
        </div>
      </div>

      {!isCurrentUser ? (
        <div className="chat-message__avatar">
          <Link to={`/members/user/${identity}`}>
            <Avatar
              avatarName={user?.friendlyName}
              avatarUrl={user?.attributes.avatarURL}
            />
          </Link>
        </div>
      ) : null}

      <MessageContent
        swipeLeft={isCurrentUser}
        actions={actions}
        onSwipe={handleReply}
      >
        <div className="chat-bubble__author">{user?.friendlyName}</div>
        <div className="chat-bubble__content">
          {replyData ? (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div className="chat-bubble__reply" onClick={handleScrollToReply}>
              <MessageReply
                recipientId={replyData.recipientId}
                messageSid={replyData.messageSid}
                message={replyData.message}
              />
            </div>
          ) : null}

          <div className="chat-bubble__body">
            <MessageBody
              message={message}
              onUpdateAttributes={handleUpdateAttributes}
            />
          </div>
        </div>
      </MessageContent>

      <div className="chat-message__meta-container">
        <div className="chat-message__meta-status">
          <ReadReceipts channelSid={channel.sid} msgIndex={index} />
        </div>
      </div>
      <IonAlert
        isOpen={isShowingDeleteMessagePrompt}
        onDidDismiss={() => {
          setIsShowingDeleteMessagePrompt(false);
        }}
        header="Deleting Message"
        message="Are you sure you want to delete this message?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'delete',
            handler: async () => {
              try {
                await message.remove();
                addSuccess(`Successfully deleted message`);
              } catch (error) {
                console.log({ error });

                addError(
                  `There was an error while deleting the message`,
                  error
                );
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default React.memo(Message);
