import React, { useCallback, useRef, useState } from 'react';
// import ContentHeader from '../core/ContentHeader';
// import Breadcrumbs from '../core/Breadcrumb';
// import { page } from '../../analytics';
import {
  defaultSteps,
  localsoloSteps,
  agencySteps,
  brandSteps,
  aafSteps,
  collectionInviteSteps,
  hbcuSteps,
  OnboardingStep,
  // culturalIdentityStep,
  rateStep,
  // genericEducationStep,
  // InviteFriendsStep,
  // channelsStep,
} from './steps/index';
import getCookie from '../../utilities/cookie';
import { INVITE_ID_COMMUNO_COOKIE } from '../landing/CollectionInviteLanding';
import useNavigate from '../../hooks/useNavigate';
import OnboardingLayout from './OnboardingLayout';
import Stepper from '../stepper/Stepper';
import Button from '../core/Button';

type Props = {
  user: UserEntity;
  membership: MembershipEntity;
  membershipAccess: MembershipAccess;
  updateUser: (data: Partial<UserEntity>) => Promise<UserEntity>;
  updateMembership: (
    data: Partial<MembershipEntity>
  ) => Promise<MembershipEntity>;
  setShowOnboarding: (bool: boolean) => void;
};

function determineBranding(memberOf: string | null) {
  switch (memberOf) {
    case 'hbcu':
      return 'hbcu';
    case 'locomotus':
      return 'locomotus';
    default:
      return 'communo';
  }
}

const Flow = ({
  user,
  membership,
  membershipAccess,
  setShowOnboarding,
}: Props) => {
  const planType = user.primaryMembershipUser?.membership.plan.type;
  const planName = membership.plan.name;
  const source = user?.source;
  const memberOf =
    source === 'locomotus' || source === 'aaf'
      ? source
      : membership?.plan?.subType ?? null;
  const branding = determineBranding(memberOf);
  const navigate = useNavigate();
  const { isIndividualPlan } = membership.plan;
  const notFirstTime = useRef(!!user.firstName && !!user?.isPartialProfile);
  const isBrandPlanType = planType === 'brand';

  const hasUnfinishedSteps = useCallback(
    (steps: OnboardingStep[]) => {
      return steps.some(
        step => step.isComplete && !step.isComplete({ user, membership })
      );
    },
    [membership, user]
  );

  const [remainingUserSteps] = useState<OnboardingStep[]>(() => {
    const allUserSteps = [];

    const inviteIdCookie = getCookie(INVITE_ID_COMMUNO_COOKIE);
    if (inviteIdCookie) {
      allUserSteps.push(...collectionInviteSteps);
    }

    if (planName === 'LocalSolo' && hasUnfinishedSteps(localsoloSteps))
      allUserSteps.push(...localsoloSteps);
    else if (memberOf === 'aaf' && hasUnfinishedSteps(aafSteps))
      allUserSteps.push(...aafSteps);
    else if (planName === 'Student - Beats' && hasUnfinishedSteps(hbcuSteps))
      allUserSteps.push(...hbcuSteps);
    else if (hasUnfinishedSteps(defaultSteps))
      allUserSteps.push(...defaultSteps);

    // if (planName === 'Freelance - Member') {
    //   allUserSteps.push(InviteFriendsStep);
    // }

    if (isIndividualPlan && planName !== 'Student - Beats') {
      allUserSteps.push(rateStep);
    }

    // TODO: Refactor this to a reduce and count skippable instead of doing it twice
    const unfinishedUserSteps = allUserSteps.filter(
      step => !step.isComplete || !step.isComplete({ user, membership })
    );

    const skippableUserSteps = unfinishedUserSteps.filter(
      step => step.canAdvance
    );

    if (unfinishedUserSteps.length > skippableUserSteps.length) {
      return unfinishedUserSteps;
    }

    return [];
  });

  const [remainingMembershipSteps] = useState<OnboardingStep[]>(() => {
    const allMembershipSteps = [];
    if (
      isBrandPlanType &&
      membership &&
      membershipAccess === 'admin' &&
      hasUnfinishedSteps(brandSteps)
    ) {
      allMembershipSteps.push(...brandSteps);
    } else if (
      planType === 'agency' &&
      membership &&
      membershipAccess === 'admin' &&
      hasUnfinishedSteps(agencySteps)
    ) {
      allMembershipSteps.push(...agencySteps);
    }

    // TODO: Refactor this to a reduce and count skippable instead of doing it twice
    const unfinishedMembershipSteps = allMembershipSteps.filter(
      step => !step.isComplete || !step.isComplete({ user, membership })
    );

    const skippableMembershipSteps = unfinishedMembershipSteps.filter(
      step => step.canAdvance
    );

    if (unfinishedMembershipSteps.length > skippableMembershipSteps.length) {
      return unfinishedMembershipSteps;
    }

    return [];
  });

  let steps;

  // Merge the Brand (Business) steps into the user steps so the breadcrumbs at the top of the
  // screen aren't spread so far apart.
  if (isBrandPlanType) {
    steps = [...remainingUserSteps, ...remainingMembershipSteps];
  } else {
    steps = remainingUserSteps.length
      ? remainingUserSteps
      : remainingMembershipSteps;
  }

  const isMembershipOnboarding = !!(
    remainingUserSteps.length === 0 && remainingMembershipSteps.length > 0
  );

  const onFinish = () => {
    setShowOnboarding(false);

    if (!isMembershipOnboarding && remainingMembershipSteps.length > 0) {
      navigate('/getting-to-know-your-company', 'none', 'replace');
      return setShowOnboarding(true);
    }

    if (isMembershipOnboarding && !notFirstTime.current) {
      return navigate('/welcome-company', 'none', 'replace');
    }

    // If they have a firstname, they are an existing user and shouldn't see welcome again
    if (notFirstTime.current) {
      return navigate('/feed', 'none', 'replace');
    }

    return navigate('/welcome', 'none', 'replace');
  };

  // useEffect(() => {
  //   const { name } = curStep;
  //   page('Onboarding', { step: name });
  // }, [curStep]);

  return (
    <OnboardingLayout
      organization={
        branding === 'communo' && isMembershipOnboarding
          ? 'communoAgency'
          : branding
      }
    >
      {steps.length ? (
        <Stepper
          steps={steps}
          onFinish={onFinish}
          hideDivider
          sliderClassName="overflow-visible pb-0"
        />
      ) : (
        <div>
          <div className="content text-center">
            <h1 className="h2">Welcome to the community!</h1>
            <p>Complete your profile and start connecting with top talent.</p>
          </div>
          <Button
            color="primary"
            fullWidth
            text="View Communo dashboard"
            className="block mx-auto max-w-xs mt-8 xl:mt-16"
            onClick={() => {
              setShowOnboarding(false);
              navigate('/feed', 'none', 'replace');
            }}
          />
        </div>
      )}
    </OnboardingLayout>
  );
};

export default Flow;
