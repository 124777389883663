/* eslint-disable no-console */
import React, { useMemo } from 'react';
import useChatChannelMembers from '../hooks/useChatChannelMembers';
import ReadReceipt from './ReadReceipt';
import useCurrentUser from '../../../hooks/useCurrentUser';

const ReadReceipts = ({
  channelSid,
  msgIndex,
}: {
  channelSid: string;
  msgIndex: number;
}) => {
  const { id: currentUserId } = useCurrentUser();
  const { channelMembers, loaded } = useChatChannelMembers(channelSid);

  const readReceipts = useMemo(
    () =>
      Object.values(channelMembers)
        .filter(
          member =>
            member.lastReadMessageIndex === msgIndex &&
            member.identity !== currentUserId
        )
        .slice(0, 4),
    [channelMembers, msgIndex, currentUserId]
  );

  if (!loaded || !readReceipts.length) return null;

  return (
    <div className="chat-read-receipts">
      {readReceipts.map(member => (
        <ReadReceipt key={member.identity} identity={member.identity} />
      ))}
    </div>
  );
};

export default React.memo(ReadReceipts);
