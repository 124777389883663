import { IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import Page from '../components/core/Page';
import CandidatesPublic from '../components/work/CandidatesPublic';

export default ({ match }: any) => {
  const { workId, status } = match.params;
  return (
    <Page
      // authGuard // Disable to view page without logging in
      publicAuthGuard
      pageTitle="Candidates"
      layout="fullHeight"
      // unMountOnLeave
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/work/${workId}`} />
          </IonButtons>
          <IonTitle slot="start">Candidates Public</IonTitle>
        </IonToolbar>
      )}
    >
      <CandidatesPublic workId={workId} status={status} />
    </Page>
  );
};
