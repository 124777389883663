import React from 'react';
import { IonAlert } from '@ionic/react';

type Props = {
  isOpen: boolean;
  onDidDismiss: any;
  header?: any;
  message: any;
  buttons: any;
};
const Alert = ({ isOpen, onDidDismiss, header, message, buttons }: Props) => {
  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="alert"
      header={header}
      message={message}
      buttons={buttons}
    />
  );
};
export default Alert;
