import React from 'react';

const RecommendationFinishStep = () => {
  return (
    <div>
      <p>
        Thank you for your recommendation. It is being processed, and the
        student will be notified when it is posted on their profile.
      </p>

      <p className="mt-4">
        If you would like to find out more about Communo visit{' '}
        <a href="https://communo.com" className="font-bold text-red-1100">
          communo.com
        </a>
        .
      </p>
    </div>
  );
};
export default RecommendationFinishStep;
