import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { Tagline, Location, Name, PhoneNumber } from '../formElements';
import { SubmitButton } from '../../core/Button';
import FormImage from './FormImage';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import CompanySize from '../formElements/CompanySize';

interface Props {
  membership: MembershipEntity;
  update: (data: any) => Promise<MembershipEntity>;
  refresh: () => void;
}

const BasicForm = ({ membership, update, refresh }: Props) => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      name: membership.name,
      phoneNumber: membership.phoneNumber,
      location: {
        latitude: membership.location?.latitude || null,
        longitude: membership.location?.longitude || null,
        friendlyName: membership.location?.friendlyName || null,
        rawJSON: JSON.stringify(membership.location?.rawJSON) || null,
      },
      tagline: membership.tagline ?? null,
      graphicTagline: membership.graphicTagline ?? null,
      taglineType: membership.graphicTagline ? 'graphic' : 'text',
      size: membership.size,
    },
  });
  const { handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = values;
    if (data.tagline) {
      data.graphicTagline = null;
    }
    data.location = {
      latitude: values.location.latitude || null,
      longitude: values.location.longitude || null,
      friendlyName: values.location.friendlyName || null,
      rawJSON: JSON.parse(values.location.rawJSON) || null,
    };
    data.size = values.size.value;

    update(data)
      .then((response: MembershipEntity) => {
        addSuccess('Membership updated!');
        refresh();
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {membership && (
        <>
          <FormImage
            id={membership.id}
            aspectRatio={[1, 1]}
            name="logoURL"
            label="Logo"
            defaultValue={membership.logoURL}
            roundCrop
          />

          <FormImage
            id={membership.id}
            aspectRatio={[2, 1]}
            name="coverPhotoURL"
            label="Cover image"
            defaultValue={membership.coverPhotoURL}
          />

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Tagline membership={membership} form={form} />
            <Name errors={errors} register={register} />
            <PhoneNumber errors={errors} register={register} />
            <Location
              errors={errors}
              register={register}
              defaultValue={membership.location?.friendlyName || ''}
              form={form}
            />
            <CompanySize form={form} defaultValue={membership.size} />
            <FormActions className="mt-auto justify-center">
              <div className="md:ml-auto">
                <SubmitButton text="Save Changes" />
              </div>
            </FormActions>
          </form>
        </>
      )}
    </>
  );
};

export default BasicForm;
