import React, { useMemo, useState } from 'react';
import useForceMobile from '../../../hooks/useForceMobile';
import { mediaClasses as mc } from '../../../utilities/MatchMedia';
import PortfolioItem from './PortfolioItem';
import PortfolioModal from './PortfolioModal';

type Props = {
  membership: MembershipEntity;
  user?: UserEntity;
};

const ProfilePortfolio = ({ membership, user }: Props) => {
  const [selectedItem, setSelectedItem] = useState<PortfolioEntity | null>(
    null
  );
  const forceMobile = useForceMobile();

  let { logoURL, name } = membership;
  if (user) {
    logoURL = user.avatarURL || '';
    name = `${user.firstName} ${user.lastName}`;
  }

  const portfolioItems = user ? user.portfolioItems : membership.portfolioItems;
  const sortedItems = useMemo(() => {
    return portfolioItems.sort((a, b) => {
      const posA = a.position || 0;
      const posB = b.position || 0;

      return posA - posB;
    });
  }, [portfolioItems]);

  const handleSelectItem = (item: PortfolioEntity) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  if (sortedItems.length === 0) {
    return null;
  }

  return (
    <div
      className={mc('bg-white mt-8 3xl:px-8 4xl:px-12 5xl:px-16', forceMobile)}
    >
      <div className={mc('px-4 lg:px-8', forceMobile)}>
        <div className="flex justify-between items-baseline mb-4">
          <h4>Portfolio</h4>
        </div>
        <section
          className={mc(
            'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8',
            forceMobile
          )}
        >
          {sortedItems.map((item: PortfolioEntity) => (
            <PortfolioItem
              key={item.id}
              item={item}
              onClick={handleSelectItem}
            />
          ))}
        </section>
      </div>

      <PortfolioModal
        logoURL={logoURL}
        name={name}
        item={selectedItem}
        onClose={handleCloseModal}
      />
    </div>
  );
};
export default ProfilePortfolio;
