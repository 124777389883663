import React, { useState, useRef } from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import PerkFeatureSlideMobile from './PerkFeatureSlideMobile';
import FeatureImage from './FeatureImage';
import Swiper from '../swiper/Swiper';
import CollectionFeatureSlideMobile from './CollectionFeatureSlideMobile';

type Props = {
  items: PerkBundleSearchResponse[];
};

const slideOpts = {
  slidesPerView: 1.3,
  freeMode: false,
  spaceBetween: 10,
  centeredSlides: true,
  direction: 'horizontal',
  loop: false,
  grabCursor: true,
};

const PerkFeatureSliderMobile = ({ items }: Props) => {
  const [activeImage, setActiveImage] = useState<number>(0);
  const slider = useRef<any>();

  const updateBackgroundImage = (index: number) => {
    setActiveImage(index);
  };

  const slides = items.map((item: PerkBundleSearchResponse) => {
    const { bundleType, type } = item;
    return (
      <div key={item.objectID}>
        {type === 'bundle' && bundleType === 'collection' ? (
          <CollectionFeatureSlideMobile bundle={item} />
        ) : (
          <PerkFeatureSlideMobile item={item} />
        )}
      </div>
    );
  });

  const readyHandler = (swiper: any) => {
    slider.current = swiper;
  };

  const handleSlideChange = () => {
    updateBackgroundImage(slider.current.activeIndex);
  };

  if (!items.length) return null;

  return (
    <>
      <div className="feature-slider-container">
        {items.map((item: PerkBundleSearchResponse, index) => (
          <FeatureImage
            src={item.verticalHeaderImageUrl}
            alt={item.name}
            imageIndex={index}
            key={item.objectID}
            currentIndex={activeImage}
          />
        ))}
        <Swiper
          paginate
          className="feature-slider absolute inset-0 z-30"
          items={slides}
          swiperConfig={slideOpts}
          onReady={readyHandler}
          eventHandlers={{
            slideChange: handleSlideChange,
          }}
        />
      </div>
      <div className="text-center p-4">
        <i className="i-caret-down text-lg" />
      </div>
    </>
  );
};
export default PerkFeatureSliderMobile;
