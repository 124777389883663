import React from 'react';
import WorkCard from '../../card/WorkCard';

// interface Props extends ListItemComponentProps {}
interface Props {
  item: WorkEntity;
}

const Work = ({ item }: Props) => {
  return (
    <WorkCard
      key={item.id}
      work={item}
      cardStyle="full"
      className="shadow-lg"
    />
  );
};
export default Work;
