import axios from 'axios';
import { utilApi } from '../../../Api';
import { OPENGRAPH_IO_KEY } from '../../../config';
import communoPath from '../utilities/communoPath';

const mappings: { [key: string]: string } = {
  site_name: 'siteName',
};

const fetchLinkPreview = (link: string) => {
  const encodedLink = encodeURIComponent(link);
  const data: { [key: string]: string } = {};
  let apiReq = axios
    .get(`https://opengraph.io/api/1.1/site/${encodedLink}`, {
      params: {
        app_id: OPENGRAPH_IO_KEY,
      },
    })
    .then(res => res.data?.hybridGraph || {});

  const path = communoPath(link);

  if (path) {
    data.siteName = 'Communo';
    data.url = link;

    const urlObj = new URL(link);
    apiReq = utilApi.meta(urlObj.pathname);
  }

  return apiReq
    .then(res => {
      Object.keys(res || {}).forEach((key: string) => {
        data[mappings[key] || key] = res[key];
      });
      return data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(`Invalid link: ${link}`, err);
      throw err;
    });
};

export default fetchLinkPreview;
