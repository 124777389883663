import React, { useEffect } from 'react';
import testData from './testData';

const NotFound = () => <div className="not-found">Item not found</div>;

const ItemView = ({ item }: { item: EventEntity }) => {
  return (
    <div className="item-view">
      {item.id}: {item.name}
    </div>
  );
};

export default ({ id }: { id: string }) => {
  const item = testData.find(data => data.id === id) || null;
  // only load once
  useEffect(() => {}, [id]);
  return (
    <div className="detail-view">
      item {id}
      {item && <ItemView item={item} />}
      {!item && <NotFound />}
    </div>
  );
};
