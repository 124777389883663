import React from 'react';

type Props = {
  children: any;
};
const ModalFooter = ({ children }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <div className="bg-white p-4">
      {/*
        Intentionally putting the `flex` styles in a child div
        because Safari won't respect the height of the
        children if the parent is flex. ¯\_(ツ)_/¯ 
      */}
      <div className="flex justify-between">{children}</div>
    </div>
  );
};
export default ModalFooter;
