import React from 'react';
import { Link } from '../core/router';

const AdminSidebar = () => {
  return (
    <div>
      <Link className="btn btn--primary btn--outline btn--sm" to="/perks">
        All Perks
      </Link>
    </div>
  );
};
export default AdminSidebar;
