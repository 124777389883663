import { BaseAPI, Api } from '../../Api';

const customApi = {
  updateCollection: (collectionId: string, payload: any) =>
    Api.put(`/collection/${collectionId}`, payload),
  listCollectionUserIds: () => Api.get(`/collection/userIds`),
  listUserCollectionIds: (userId: string) =>
    Api.get(`/collection/${userId}/collectionIds`),
  listCollectionUsers: (collectionId: string, page: number, perPage: number) =>
    Api.get(
      `/collection/${collectionId}/users?page=${page}&perPage=${perPage}`
    ),
  listCollectionEmailInvites: (collectionId: string) =>
    Api.get(`/collection/${collectionId}/invites/email`),
  deleteCollectionEmailInvite: (collectionId: string, inviteId: string) =>
    Api.delete(`/collection/${collectionId}/invites/${inviteId}`),
  addUsersToCollection: (collectionId: string, userIds: string[]) =>
    Api.put(`/collection/${collectionId}/users`, {
      userIds,
    }),
  removeUsersToCollection: (collectionId: string, userId: string) =>
    Api.delete(`/collection/${collectionId}/users/${userId}`),
  getCollectionInviteSummary: (collectionId: string) =>
    Api.get(`/collection/${collectionId}/invites/link`),
  getInviteById: (inviteId: string) =>
    Api.get(`/collection/invite/${inviteId}`),
  acceptInvite: (collectionId: string, inviteId: string) =>
    Api.post(`/collection/${collectionId}/invites/${inviteId}/accept`),
  sendEmailInvite: (collectionId: string, email: string) =>
    Api.post(`/collection/${collectionId}/invites`, {
      email,
    }),
};

export default {
  ...BaseAPI('collection'),
  ...customApi,
};
