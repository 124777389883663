import { IonSlide, IonSlides } from '@ionic/react';
import React, { useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import { LocalParticipant, Participant } from 'twilio-video';
import ParticipantCard from './ParticipantCard';

const options = {
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 3,
  spaceBetween: 10,
  preloadImages: false,
  updateOnImagesReady: false,
  direction: 'horizontal',
  freeMode: true,
  freeModeSticky: true,
  breakpoints: {
    992: {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeModeSticky: false,
    },
  },
};

const ParticipantSlider = ({
  participants,
  localParticipant,
  dominantSpeaker,
  users = [],
}: {
  participants: Participant[];
  users: UserEntity[];
  localParticipant: LocalParticipant;
  dominantSpeaker: Participant;
}) => {
  const getUser = useCallback(
    (participant: Participant) => {
      return users.filter(
        (user: UserEntity) => user.id === participant.identity
      )[0];
    },
    [users]
  );

  return (
    <div className="video-participant-slider">
      <IonSlides
        options={options}
        key={`${localParticipant.identity}, ${participants
          .map((participant: Participant) => participant.identity)
          .join(',')}}`}
      >
        <IonSlide>
          <ParticipantCard
            isLocal
            participant={localParticipant}
            user={getUser(localParticipant)}
            isDominant={localParticipant === dominantSpeaker}
          />
        </IonSlide>
        {participants.map((participant: Participant, index: number) => (
          <IonSlide key={`slider-${participant.identity}`}>
            <ParticipantCard
              participant={participant}
              user={getUser(participant)}
              isDominant={dominantSpeaker === participant}
              hideDesktop={index < 4}
            />
          </IonSlide>
        ))}
      </IonSlides>
    </div>
  );
};

export default ParticipantSlider;
