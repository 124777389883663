import React from 'react';

const SystemMessage = ({ message }: { message: any }) => {
  return (
    <div id={message.sid} className="chat-notification">
      <div className="chat-notification__body">{message.body}</div>
    </div>
  );
};

export default React.memo(SystemMessage);
