import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup } from '../../core/form';
import membershipApi from '../../membership/Api';

interface Option {
  value: string;
  label: string;
}

interface Props {
  name: string;
  form: any;
  errors: any;
  membershipId: string;
  defaultValue?: Option;
}

const Contact = ({ name, form, errors, membershipId, defaultValue }: Props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const { setValue, register } = form;

  const getUsers = useCallback((id: string) => {
    membershipApi.users(id).then((response: any[]) => {
      const users = response.map(membership => {
        return {
          value: membership.user.id,
          label: `${membership.user.firstName} ${membership.user.lastName}`,
        };
      });

      setOptions(users as Option[]);
    });
  }, []);

  useEffect(() => {
    getUsers(membershipId);
    register({ name });
  }, [getUsers, membershipId, register, name]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue.value);
    }
  }, [defaultValue, name, setValue]);

  return (
    <FormGroup name={name} label="Contact" errors={errors}>
      <Select
        isClearable
        className="multiselect"
        classNamePrefix="multi"
        options={options}
        defaultValue={defaultValue}
        onChange={(selected: any) => {
          setValue(name, selected?.value || null, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
      />
    </FormGroup>
  );
};
export default Contact;
