import { IonRange } from '@ionic/react';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { FormGroup } from '../../core/form';

const DURATION_MIN = 1;
const DURATION_MAX = 52;

export default ({
  errors,
  register,
  form,
  showDuration,
  defaultValue,
}: any) => {
  const { setValue, unregister } = form;

  useEffect(() => {
    if (!showDuration) {
      unregister('duration');
    }
  }, [showDuration, form, unregister]);

  const [durationIndex, setDurationIndex] = useState(DURATION_MIN);
  const handleDurationChange = useCallback(
    (value: number) => {
      setDurationIndex(value);
      setValue('duration', value);
    },
    [setValue]
  );

  useEffect(() => {
    handleDurationChange(defaultValue || DURATION_MIN);
  }, [defaultValue, setValue, handleDurationChange]);

  return (
    <>
      {showDuration && (
        <FormGroup
          name="duration"
          label="Project duration (weeks)"
          errors={errors}
        >
          <div>{pluralize('week', durationIndex, true)}</div>
          <IonRange
            min={DURATION_MIN}
            max={DURATION_MAX}
            step={1}
            snaps
            ticks={false}
            pin={false}
            value={durationIndex}
            color="communo-red"
            mode="ios"
            onIonChange={(e: any) => {
              return handleDurationChange(e?.target?.value || DURATION_MIN);
            }}
          />
          <input
            type="hidden"
            name="duration"
            ref={register({
              required: 'Duration is required',
            })}
          />
        </FormGroup>
      )}
    </>
  );
};
