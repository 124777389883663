import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { IonTabButton, IonTabs, IonTabBar } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { isMobile } from './utilities/Device';

import beatsLogo from './assets/beats-logo.png';
import UserContext from './components/user/Context';
import MembershipContext from './components/membership/Context';
import AppContext from './AppContext';
import useNavigate from './hooks/useNavigate';
import { HBCU_PLAN_ID } from './config';
import useCurrentPlan from './hooks/useCurrentPlan';
import StackIcon from './components/icons/StackIcon';
import useSchedulerCodeExchange from './components/scheduler/hooks/useSchedulerCodeExchange';
import useBrandConfig from './hooks/useBrandConfig';

const tabPaths = ['/feed', '/chat', '/lists'];
const hideTabPaths = ['/channels'];

const useTabs = isMobile;

const MemoAppTabs = React.memo(({ pathname, navigate, children }: any) => {
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const { ionContentRef } = useContext(AppContext);
  const { hasMembership } = useContext(UserContext);
  const { checkAccess } = useContext(MembershipContext);
  const { availablePages } = useBrandConfig();
  const plan = useCurrentPlan();

  // We don't need IonTabs on desktop, a regular <div> can replace it
  // otherwise we get an error since we don't render a IonTabBar on desktop
  const TabComponent = useMemo(() => {
    return useTabs ? IonTabs : 'div';
  }, []);

  const tabs = useMemo(() => {
    let middleTab = {};
    if (plan?.id === HBCU_PLAN_ID) {
      middleTab = {
        to: '/feed',
        tab: 'feed',
        icon: 'handshake',
        rounded: true,
        content: (
          <div className="w-8 h-8">
            <img src={beatsLogo} className="max-w-full" alt="Beats" />
          </div>
        ),
      };
    } else if (availablePages.channels) {
      middleTab = {
        to: '/channels',
        tab: 'channels',
        icon: 'handshake',
        rounded: true,
      };
    }

    const mobileFooterTabs = [
      {
        to: '/members',
        tab: 'members',
        label: 'Members',
        icon: 'users',
        permission: 'canViewMembershipList',
      },
      {
        to: '/work',
        tab: 'work',
        label: plan?.id === HBCU_PLAN_ID ? 'Program' : 'Work',
        icon: 'work',
      },
      middleTab,
    ];

    if (availablePages.events) {
      mobileFooterTabs.push({
        to: '/events',
        tab: 'events',
        label: 'Events',
        icon: 'calendar',
      });
    }

    if (availablePages.perks) {
      mobileFooterTabs.push({
        to: '/perks',
        tab: 'perks',
        label: 'Perks',
        icon: 'grid-view',
      });
    }

    return mobileFooterTabs.map(tab => {
      const { permission }: any = tab;
      return { ...tab, disabled: permission && !checkAccess(permission) };
    });
  }, [checkAccess, plan, availablePages]);

  const handleShowTabs = useCallback(() => {
    // Force hide tabs on perks
    if (pathname === '/perks' || pathname === '/chat') {
      setShowTabs(false);
      return;
    }

    const tabRoutes: string[] = tabs.map((tab: any) => tab.to);

    // only show tabs on root tab paths
    setShowTabs(
      tabPaths.includes(pathname) ||
        (!hideTabPaths.includes(pathname) && tabRoutes.includes(pathname))
    );
  }, [pathname, tabs]);

  const renderTab = useCallback((icon, rounded, content) => {
    if (content) {
      return content;
    }

    if (rounded) {
      return (
        <div className="stack-icon text-2xl bg-black">
          {/* <i className={`i-${icon}`} /> */}
          <StackIcon className="w-5 h-5" />
        </div>
      );
    }

    return <i className={`i-${icon} text-base my-1 h-4 flex items-center`} />;
  }, []);

  useEffect(() => {
    handleShowTabs();
  }, [handleShowTabs]);

  const handleClick = (e: any, path: string, tabActive: boolean) => {
    e.preventDefault();

    if (tabActive) {
      ionContentRef.current.scrollToTop(500);
      return;
    }

    navigate(path, 'none');
  };

  return (
    <div className={showTabs && hasMembership ? 'show-tabs' : 'hide-tabs'}>
      <TabComponent>
        {children}
        {useTabs ? (
          <IonTabBar slot="bottom">
            {tabs.map(
              ({ to, label, icon, disabled, rounded, content }: any) => {
                const tabActive = to === pathname;
                return (
                  <IonTabButton
                    key={to}
                    // bug: href causes cross tab sub-page navigation to
                    // get stuck in sub-pages with no way back to tab root
                    // href={to}
                    onClick={e => handleClick(e, to, tabActive)}
                    className={
                      tabActive ? 'tab-selected text-teal-900' : 'text-grey-700'
                    }
                    disabled={disabled}
                  >
                    {renderTab(icon, rounded, content)}
                    {label && <span className="text-xs">{label}</span>}
                  </IonTabButton>
                );
              }
            )}
          </IonTabBar>
        ) : null}
      </TabComponent>
    </div>
  );
});

const AppTabs = ({ children }: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useSchedulerCodeExchange();

  return (
    <MemoAppTabs navigate={navigate} pathname={pathname}>
      {children}
    </MemoAppTabs>
  );
};

export default React.memo(AppTabs);
