import React from 'react';

import Stepper from '../../stepper/Stepper';
import RecommendationBodyStep from './RecommendationBodyStep';
import RecommendationInfoStep from './RecommendationInfoStep';
import RecommendationIntroStep from './RecommendationIntroStep';
import RecommendationOrganizationStep from './RecommendationOrganizationStep';
import RecommendationPreviewStep from './RecommendationPreviewStep';
import { StepType } from '../../stepper/Step';
import RecommendationFinishStep from './RecommendationFinishStep';

const steps: StepType[] = [
  {
    title: 'You’ve received the following request…',
    component: () => <RecommendationIntroStep />,
    canAdvance: true,
  },
  {
    title: 'Your Information',
    component: () => <RecommendationInfoStep />,
    nextButtonLabel: 'Continue',
    canAdvance: true,
  },
  {
    title: 'Your Organization',
    component: () => <RecommendationOrganizationStep />,
    nextButtonLabel: 'Continue',
  },
  {
    title: 'Your Recommendation',
    description:
      'Let us know why you think the student is a good candidate for the Beats program',
    component: () => <RecommendationBodyStep />,
    nextButtonLabel: 'Preview',
  },
  {
    title: 'Preview',
    component: () => <RecommendationPreviewStep />,
    nextButtonLabel: 'Post',
    prevButtonLabel: 'Edit',
    canAdvance: true,
  },
  {
    component: () => <RecommendationFinishStep />,
    hideButtons: true,
  },
];

const SubmitRecommendationStepper = () => {
  return (
    <div className="max-w-lg mx-auto">
      <Stepper heading="Recommendation" steps={steps} hideDivider />
    </div>
  );
};
export default SubmitRecommendationStepper;
