export default (url: string) => {
  return {
    id: 'company',
    label: 'Add your company name',
    description: 'It’s kinda hard to find an agency with no name, isn’t it?',
    icon: 'hamburger',
    action: `#/${url}/basic`,
    actionLabel: 'Add Name',
    complete: false,
  };
};
