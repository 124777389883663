import { useEffect } from 'react';
import { useCookie } from 'react-use';

export const SOURCE_COOKIE = 'communosource';

const SourceCookie = () => {
  const [, setCookie] = useCookie(SOURCE_COOKIE);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('t')) {
      setCookie(params.get('t') ?? '', { expires: 86400 * 30, path: '/' });
    }
  }, [setCookie]);

  return null;
};

export default SourceCookie;
