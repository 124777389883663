import React, { useCallback, useEffect, useState } from 'react';
import { Perk, PerkCategory } from '@communo-corp/shared-types';
import { useHistory } from 'react-router';
import Panels from '../core/panels/panels';
import PerkMenu from './PerkMenu';
import { isMobile } from '../../utilities/Device';
import PageHeaderMobile from '../core/PageHeaderMobile';
import perksApi from './Api';
import { LoadingBalls } from '../core/Loading';
import { addResponseError } from '../../services/Messaging';
import PerkBundleTable from './PerkBundleTable';
import AdminSidebar from './AdminSidebar';

const PerksManage = () => {
  const [perks, setPerks] = useState<Perk[]>([]);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();

  const getPerks = useCallback(() => {
    setLoading(true);

    perksApi
      .list()
      .then((response: Perk[]) => {
        setPerks(response);
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getPerks();
  }, [getPerks]);

  const refreshList = () => {
    getPerks();
  };

  const data = React.useMemo(() => {
    return perks.map((perk: Perk) => {
      const {
        id,
        membership,
        categories,
        offerSummary,
        isFeatured,
        order,
        isActive,
        descriptionSummary,
      } = perk;

      return {
        id,
        membership: membership ? membership.name : '', // TODO: Membership should never be null
        category: categories?.map((category: PerkCategory) => {
          return category.name;
        }),
        name: descriptionSummary,
        offer: offerSummary,
        featured: isFeatured ? 'true' : 'false',
        order,
        active: isActive ? 'true' : 'false',
      };
    });
  }, [perks]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Membership',
        accessor: 'membership', // accessor is the "key" in the data
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Title',
        accessor: 'name',
      },
      {
        Header: 'Offer',
        accessor: 'offer',
      },
      {
        Header: 'Featured',
        accessor: 'featured',
      },
      {
        Header: 'Order',
        accessor: 'order',
      },
      {
        Header: 'Active',
        accessor: 'active',
      },
    ];
  }, []);

  const handleClick = (row: any) => {
    const { id } = row.original;
    push(`/perks/${id}/edit`);
  };

  return (
    <Panels leftPanel={<PerkMenu />} contextPanel={<AdminSidebar />}>
      {toggleLeftPanel => (
        <div>
          {isMobile && (
            <PageHeaderMobile
              title="Perks"
              action={
                // eslint-disable-next-line
                <button
                  type="button"
                  onClick={() => toggleLeftPanel()}
                  className="btn btn--sm"
                >
                  Menu <i className="i-hamburger text-lg ml-xs" />
                </button>
              }
            />
          )}
          <div className="px-4 py-6 pt-20 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
            <LoadingBalls isActive={loading} fullscreen />
            <div className="flex justify-between mb-4">
              <h1>Manage Perks</h1>
              <button
                type="button"
                className="btn btn--outline btn--primary btn--sm"
                onClick={refreshList}
              >
                Refresh List
              </button>
            </div>

            <PerkBundleTable
              data={data}
              columns={columns}
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </Panels>
  );
};
export default PerksManage;
