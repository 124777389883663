import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCurrentMembership from '../../../../hooks/useCurrentMembership';
import { addResponseError } from '../../../../services/Messaging';
import { SteppedRangeValue } from '../../../core/SteppedRangeSlider';
import CompanySize from '../../../membership/formElements/CompanySize';
import useStep from '../../../stepper/hooks/useStep';

interface Input {
  size: SteppedRangeValue;
}

const CompanySizeStep = () => {
  const { update } = useCurrentMembership();
  const {
    isLoading,
    setLoading,
    setOnNext,
    goToNextStep,
    setCanAdvance,
  } = useStep();
  const form = useForm<Input>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { handleSubmit } = form;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - CompanySize Page');
  }, [track]);

  const onSubmit = useCallback(
    async (value: Input) => {
      if (isLoading) {
        return;
      }

      setLoading(true);

      const { size } = value;

      try {
        await update({ size: size.value });
        setLoading(false);
        goToNextStep();
      } catch (error) {
        addResponseError(error);
        setLoading(false);
      }
    },
    [goToNextStep, isLoading, setLoading, update]
  );

  useEffect(() => {
    setOnNext(() => {
      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, onSubmit, setOnNext]);

  useEffect(() => {
    setCanAdvance(true);
  }, [setCanAdvance]);

  return <CompanySize form={form} />;
};
export default CompanySizeStep;
