import { useCallback, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

const useIsFormValid = (form: UseFormMethods<any>, fields: string[]) => {
  const watchedFields = form.watch(fields);

  const isFormValid = useCallback(() => {
    return fields.every(field => !!watchedFields[field]);
  }, [fields, watchedFields]);

  const [isValid, setValid] = useState<boolean>(isFormValid());

  useEffect(() => {
    setValid(isFormValid());
  }, [setValid, isFormValid]);

  return isValid;
};
export default useIsFormValid;
