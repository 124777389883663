import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import useAnalytics from '../../hooks/useAnalytics';
import ContentHeader from '../core/ContentHeader';
import UserContext from '../user/Context';
import SplitPanels from '../core/panels/SplitPanels';
import Button from '../core/Button';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../../asset-config';

interface Props {
  type: 'success' | 'upgrade-success';
}

export default ({ type }: Props) => {
  const { isOnboardingRequired, setShowOnboarding, redirectUrl } = useContext(
    UserContext
  );
  const { state } = useLocation();
  const history = useHistory();
  const btnLabel = type === 'upgrade-success' ? 'Continue' : 'Set up profile';
  useAnalytics(
    type === 'upgrade-success' ? 'Upgrade Complete' : 'Payment Complete',
    state
  );

  const handleClick = () => {
    if (isOnboardingRequired) {
      setShowOnboarding(true);
    } else {
      const destination = redirectUrl || '/feed';
      history.replace(destination);
    }
  };

  return (
    <SplitPanels
      headerImg={DEFAULT_ONBOARDING_COVER_PHOTO_URL}
      backgroundClass="bg-red-900"
      logoImg={DEFAULT_ONBOARDING_LOGO_URL}
    >
      <div>
        <div className="content text-center text-white">
          <ContentHeader />
          <h3 className="mb-4">Success!</h3>
          {type === 'upgrade-success' ? (
            <p className="font-light text-lg">
              Your account has been upgraded.
            </p>
          ) : (
            <p className="font-light text-lg">
              Your payment is being processed. Now we need to get to know you
              better.
            </p>
          )}
        </div>
        <Button
          color="primary"
          reverse
          className="w-full mt-8"
          onClick={handleClick}
        >
          {btnLabel}
        </Button>
      </div>
    </SplitPanels>
  );
};
