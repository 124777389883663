import BaseFilterOption from './BaseFilterOption';

class URLFilterOption<T = any> extends BaseFilterOption<T> {
  /**
   * Convert URL params to value
   * @param params URL params
   */
  fromURL(params: { [key: string]: any }): { [key: string]: T | undefined } {
    return {
      [this.name]: params[this.name],
    };
  }

  /**
   * Convert value to URL params
   * @param value
   */
  toURL(value?: T): { [key: string]: string | undefined } {
    return { [this.name]: value ? String(value) : undefined };
  }
}

export default URLFilterOption;
