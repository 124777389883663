import { Api, BaseAPI } from '../../Api';

const customApi = {
  // members: () => Api.get(`/${type}`, data),
  invites: (membershipId: string) =>
    Api.get(`/membership/${membershipId}/invite`),
  invite: (membershipId: string, data: { email: string }) =>
    Api.post(`/membership/${membershipId}/invite`, data),
  deleteInvite: (membershipId: string, inviteId: string) =>
    Api.delete(`/membership/${membershipId}/invite/${inviteId}`),
  users: (membershipId: string) =>
    Api.get(`/membership/${membershipId}/membership-user`),
  updateUser: (membershipId: string, userId: string, data: any) =>
    Api.patch(`/membership/${membershipId}/membership-user/${userId}`, data),
  allUsers: (membershipId: string) =>
    Api.get(`/membership/${membershipId}/membership-user?includeDeactivated=1`),
  deactivated: () => Api.get(`/root/expired-memberships`),
  work: (membershipId: string) => Api.get(`/work?membershipId=${membershipId}`),
  publicWork: (membershipId: string) => Api.get(`/work/-/public?membershipId=${membershipId}`),
  events: (membershipId: string) =>
    Api.get(`/event?membershipId=${membershipId}`),
  updatePlan: (membershipId: string, data: { planId: string }) =>
    Api.put(`/membership/${membershipId}/plan`, data),
  async associateSkill(membershipId: string, skillId: string) {
    const response = await Api.post(
      `/membership/${membershipId}/skill/${skillId}`
    );
    return response;
  },
};

export default { ...BaseAPI('membership'), ...customApi };
