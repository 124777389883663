import createStore from '../../../utilities/createStore';

/**
 * ChatUnreadMessagesCount
 * Stores the unread counts for each channel
 */

type ChannelUnreadCount = {
  [sid: string]: number;
};

export type ChatUnreadMessagesState = {
  channels: ChannelUnreadCount;
  addChannelsUnreadCount: (channelsUnreadCount: ChannelUnreadCount[]) => void;
  updateChannelUnreadCount: (channelUnreadCount: ChannelUnreadCount) => void;
};

const chatUnreadMessagesState = createStore<ChatUnreadMessagesState>(
  (set: any) => ({
    channels: {},
    addChannelsUnreadCount: channelsUnreadCount =>
      set(
        (state: ChatUnreadMessagesState) => ({
          channels: { ...state.channels, ...channelsUnreadCount },
        }),
        'addChannelsUnreadCount'
      ),
    updateChannelUnreadCount: channelUnreadCount =>
      set(
        (state: ChatUnreadMessagesState) => ({
          channels: { ...state.channels, ...channelUnreadCount },
        }),
        'updateChannelUnreadCount'
      ),
  }),
  'ChatUnreadMessagesState'
);

export default chatUnreadMessagesState;
