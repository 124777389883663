import { ListItemComponent } from '@communo-corp/shared-types';
import React from 'react';
import { DiscoverListProps } from '..';
import { DISCOVER_LIST_OPTIONS } from '../../../config';
import { Link } from '../../core/router';
import Swiper from '../../swiper/Swiper';
import {
  Default,
  Full,
  Square,
  Avatar,
  AvatarWithoutName,
  Overlay,
  OverlayWithAvatar,
  Video,
  Work,
  Event,
  Portrait,
  SquareWithoutName,
  FullFlex,
} from '../slides/index';

const ItemComponent: { [key in ListItemComponent]: any } = {
  Avatar,
  AvatarWithoutName,
  Default,
  Overlay,
  OverlayWithAvatar,
  Square,
  SquareWithoutName,
  Full,
  FullFlex,
  Portrait,
  Video,
  WorkCard: Work,
  EventCard: Event,
};

const DiscoverListCarousel = ({
  actionUrl,
  actionText = 'View more',
  items,
  heading,
  swiperOptions = {},
  listItemComponent = ListItemComponent.Default,
  // entityType,
  showNavigation = false,
}: DiscoverListProps) => {
  return (
    <div className="discover-list-container my-20">
      <div
        className={`discover-list discover-list--${listItemComponent} mx-auto`}
      >
        <div className="discover-list__header">
          {heading && <h2 className="discover-list__title">{heading}</h2>}
          {actionUrl && (
            <Link className="discover-list__action" to={actionUrl}>
              {actionText}
            </Link>
          )}
        </div>
        <Swiper
          navigation={showNavigation}
          className="discover-slider"
          // eslint-disable-next-line no-unused-vars
          items={items.map((item: any) => {
            const Slide = ItemComponent[listItemComponent];
            return <Slide item={item} key={item.id} />;
          })}
          swiperConfig={{
            ...DISCOVER_LIST_OPTIONS,
            watchSlidesVisibility: true,
            ...swiperOptions,
          }}
        />
      </div>
    </div>
  );
};
export default DiscoverListCarousel;
