import { useQuery } from '@tanstack/react-query';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Api from '../Api';

/**
 * Returns the global list of userIDs that are in at-least
 * one Collection accessible to the current user.
 */
export default function useGlobalCollectionUserIds() {
  const { id: userId } = useCurrentUser();

  return useQuery(['collectionUserIds'], () => Api.listCollectionUserIds(), {
    enabled: !!userId,
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
