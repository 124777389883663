import { orderBy } from 'lodash';
import { ENABLE_INVITE_TO_APPLY } from '../config';

const invitedActive = ENABLE_INVITE_TO_APPLY;
const awardedActive = !ENABLE_INVITE_TO_APPLY;

export type ApplicationStatusParams = {
  label: string;
  icon: string;
  color: string;
  displayInternally: boolean;
  displayExternally: boolean;
};

export const applicationStatuses: Record<
  ApplicationStatus,
  ApplicationStatusParams
> = {
  invited: {
    label: 'Invited',
    icon: 'user',
    color: '#FFD580',
    displayInternally: invitedActive,
    displayExternally: false,
  },
  interested: {
    label: 'Applied',
    icon: 'user',
    color: '#A096E6',
    displayInternally: true,
    displayExternally: false,
  },
  client_rejected: {
    label: 'Client Rejected',
    icon: 'users',
    color: '#727B88',
    displayInternally: invitedActive,
    displayExternally: true,
  },
  shortlisted: {
    label: 'Shortlisted',
    icon: 'users',
    color: '#45C4F2',
    displayInternally: true,
    displayExternally: true,
  },
  rejected: {
    label: 'Rejected',
    icon: 'users',
    color: '#727B88',
    displayInternally: true,
    displayExternally: false,
  },
  awarded: {
    label: 'Awarded',
    icon: 'alert-positive',
    color: '#00A54F',
    displayInternally: awardedActive,
    displayExternally: false,
  },
};

// JobStakeholder type determines which transitions are available according
// to the position of a stakeholder wrt to the recruitment process
type JobStakeholder = 'internal' | 'external';

// AllowedStatusTransitions is a list of possible status transitions
export type AllowedStatusTransitions = ApplicationStatus[];

// ApplicationStatusManagement matches both stakeholders type to the
//  statuses they are allowed to change an application to.
type ApplicationStatusManagement = Record<
  JobStakeholder,
  AllowedStatusTransitions
>;

// The state transition table maps each application status to each type
// of ApplicationStatusManagement, i.e.,  the allowed transitions per
// stakeholder type. It is used on mobile and desktop, externally and internally.
export const stateTransitionTable: Record<
  ApplicationStatus,
  ApplicationStatusManagement
> = {
  invited: {
    internal: ['invited'],
    external: ['invited'],
  },
  interested: {
    internal: ['interested', 'rejected', 'shortlisted', 'awarded'],
    external: [],
  },
  client_rejected: {
    internal: [
      'interested',
      'client_rejected',
      'rejected',
      'shortlisted',
      'awarded',
    ],
    external: ['client_rejected', 'shortlisted'],
  },
  rejected: {
    internal: ['interested', 'rejected', 'shortlisted', 'awarded'],
    external: [],
  },
  shortlisted: {
    internal: ['interested', 'rejected', 'shortlisted', 'awarded'],
    external: ['client_rejected', 'shortlisted'],
  },
  awarded: {
    internal: ['awarded'],
    external: ['awarded'],
  },
};

// snapTo scaffolds the tailwind grid classes to snap grid elements to
const snapTo = (
  screenSize: string | null,
  colNum?: number | null,
  rowNum?: number | null,
  colSpan?: number | null,
  rowSpan?: number | null
) => {
  const screenPrefix = screenSize ? `${screenSize}:` : '';

  let colPosition = '';
  if (colNum) {
    if (colSpan) {
      colPosition = `${screenPrefix}col-start-${colNum} ${screenPrefix}col-span-${colSpan}`;
    } else {
      colPosition = `${screenPrefix}col-start-${colNum}`;
    }
  } else {
    colPosition = '';
  }

  let rowPosition = '';
  if (rowNum) {
    if (rowSpan) {
      rowPosition = `${screenPrefix}row-start-${rowNum} ${screenPrefix}row-span-${rowSpan}`;
    } else {
      rowPosition = `${screenPrefix}row-start-${rowNum}`;
    }
  } else {
    rowPosition = '';
  }

  return `${colPosition} ${rowPosition}`;
};

// statusColumnPosition maps each application status to their corresponding tailwind classes
export function statusColumnPosition(
  status: ApplicationStatus,
  clientRejectedApplications: boolean
) {
  switch (status) {
    case 'invited':
      return `${snapTo('xl', 1, 1, null, null)} ${snapTo('lg', 1, 1)}`;

    case 'interested':
      return `${snapTo('xl', 2, 1, null, null)} ${snapTo('lg', 2, 1)}`;

    case 'client_rejected':
      return `${snapTo('xl', 2, 1, null, null)} ${snapTo('lg', 1, 2)}`;

    case 'rejected':
      if (clientRejectedApplications) {
        // Put the rejected stack below the client_rejected stack
        return `${snapTo('xl', 3, 3, null, null)} ${snapTo('lg', 1, 3)}`;
      }
      // Let the rejected stack take all the vertical space
      return `${snapTo('xl', 3, 1, null, null)} ${snapTo('lg', 1, 2)}`;

    case 'shortlisted':
      if (clientRejectedApplications) {
        return `${snapTo('xl', 4, 1, null, null)} ${snapTo('lg', 2, 2)}`;
      }
      return `${snapTo('xl', 4, 1, null, null)} ${snapTo('lg', 2, 2)}`;

    case 'awarded':
      return `${snapTo('xl', 4, 1, null, null)} ${snapTo('lg', 2, 2)}`;
    default:
      return '';
  }
}

export const clientShortlistedColumnWidth = 3;

export function publicStatusColumnPosition(status: string) {
  switch (status) {
    case 'client_rejected':
      return `${snapTo('2xl', 1, 1)}`;

    case 'shortlisted':
      return `${snapTo('2xl', 2, 1, clientShortlistedColumnWidth)}`;

    default:
      return '';
  }
}

export function statusFromApi(
  status: ApplicationStatus | null
): ApplicationStatus {
  if (status === null) {
    return 'interested';
  }
  return status;
}

export function statusToApi(
  status: ApplicationStatus | null
): ApplicationStatus | null {
  if (status === 'interested') {
    return null;
  }
  return status;
}

export function statusLabel(
  status: ApplicationStatus | undefined | null,
  isPublic: boolean = false,
  isTab: boolean = false
): string {
  const statusObj = applicationStatuses[status || 'interested'];
  if (status === 'client_rejected') {
    if (isPublic) return 'Rejected';
    if (!isPublic && isTab) return 'Client Rej.';
  }
  return statusObj.label;
}

export function statusIcon(status: ApplicationStatus): string {
  const statusObj = applicationStatuses[status || 'interested'];
  return statusObj.icon;
}

export function statusColor(status: ApplicationStatus): string {
  const statusObj = applicationStatuses[status || 'interested'];
  return statusObj.color;
}

export function sortApplications(applications: ApplicationEntity[]) {
  return orderBy(
    Object.values(applications),
    ['position', 'score', 'updatedAt'],
    ['asc', 'desc', 'asc']
  );
}

export function groupApplicationsByStatus(applications: ApplicationEntity[]) {
  return Object.keys(applicationStatuses).reduce(
    (carry: any, status: any) => {
      const statusToMatch = statusToApi(status);

      // Place 'awarded' applications in the 'shortlisted' column so the 'awarded'
      // column can be removed from the UI.
      const applicationsForStatus: ApplicationEntity[] = applications.filter(
        application => {
          return application.status === statusToMatch;
        }
      );

      if (status === 'awarded' && ENABLE_INVITE_TO_APPLY) {
        status = 'shortlisted';
        return {
          ...carry,
          [status]: [...applicationsForStatus, ...carry[status]],
        };
      }

      return {
        ...carry,
        [status]: [...carry[status], ...applicationsForStatus],
      };
    },
    {
      invited: [],
      interested: [],
      client_rejected: [],
      rejected: [],
      shortlisted: [],
      awarded: [],
    }
  );
}

export const isStatusFrozen = (
  originStatus: ApplicationStatus | null,
  isPublic: boolean
) => {
  const readStatus = statusFromApi(originStatus); // readStatus maps the null status from the API to interested
  const allowedStatusTransitions =
    stateTransitionTable[readStatus][isPublic ? 'external' : 'internal'];

  const cannotChange = allowedStatusTransitions.length === 0;
  const canOnlyStayTheSame =
    allowedStatusTransitions.length === 1 &&
    allowedStatusTransitions.includes(readStatus);
  return cannotChange || canOnlyStayTheSame;
};
