import React, { useState } from 'react';
import { NewConversationUser } from '../../../@types/conversation.d';

type NewGroupContext = {
  members: NewConversationUser[];
  updateMembers: (user: NewConversationUser) => void;
  clearMembers: () => void;
};

const initialState = {
  members: [],
  updateMembers: () => {},
  clearMembers: () => {},
};

const NewGroupChatContext = React.createContext<NewGroupContext>(initialState);

export function NewGroupChatProvider({ children }: { children: any }) {
  const [members, setMembers] = useState<NewConversationUser[]>(
    initialState.members
  );

  const updateMembers = (user: NewConversationUser) =>
    setMembers(prevState => {
      if (prevState.find(({ identity }) => identity === user.identity)) {
        return prevState.filter(({ identity }) => identity !== user.identity);
      }

      const addedUser = { ...user };
      addedUser.isChecked = true;

      return [...prevState, addedUser];
    });

  const clearMembers = () => setMembers([]);

  return (
    <NewGroupChatContext.Provider
      value={{
        members,
        updateMembers,
        clearMembers,
      }}
    >
      {children}
    </NewGroupChatContext.Provider>
  );
}

export default NewGroupChatContext;
