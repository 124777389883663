export default (url: string) => {
  return {
    id: 'profile',
    label: 'Upload a profile photo',
    description:
      'Want people to skip over your profile? Leave your photo blank.',
    icon: 'avatar',
    action: `#/${url}/basic`,
    actionLabel: 'Add Photo',
    complete: false,
  };
};
