import React from 'react';
import Page from '../components/core/Page';
import { Link } from '../components/core/router';
import useAnalytics from '../hooks/useAnalytics';
import cloudinary from '../utilities/Cloudinary';

const LandingLocomotus = () => {
  useAnalytics('Locomotus Landing');

  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--trueBlack"
      hideHeader
    >
      <div className="relative xl:min-h-screen xl:grid xl:grid-cols-2">
        <div className="aspect-square relative xl:pb-0 xl:order-2">
          <img
            src={cloudinary(
              'https://res.cloudinary.com/communo/image/upload/v1613690803/brands/locomotus/Onboarding2---1440x1080.jpg',
              ['w_1024']
            )}
            alt="Beats"
            className="absolute inset-0 object-cover w-full h-full"
          />
        </div>
        <div className="px-8 py-4 pb-8 xl:px-16 xl:py-16 3xl:py-20 xl:order-1 bg-trueBlack">
          <div className="content text-white xl:max-w-3xl xl:pt-40">
            <img
              src={cloudinary(
                'https://res.cloudinary.com/communo/image/upload/v1613690794/brands/locomotus/Locomotus_Logo_white_transp.png',
                ['h_192']
              )}
              alt="Locomotus"
              className="max-w-xs h-auto mb-8"
            />
            <h3>
              Welcome to the New Normal for Marketing, Media and Entertainment.
            </h3>
            <p className="text-large xl:text-xl">
              Locomotus is the on-demand talent network and resource hub
              connecting culturally-fluent, BIPOC creatives with today’s most
              relevant companies and brands.
            </p>
            <p className="text-large xl:text-xl">
              We are cultivating a community for creative misfits, agitators and
              thought- leaders to work, thrive and disrupt the status quo - to
              deliver game-changing solutions to the most complex business or
              social challenges.
            </p>
            <p className="text-large xl:text-xl">
              The Locomotus advantage is the proven success of Identifying,
              training and assisting in job placement for hundreds of alumni of
              the past 15 years through the Marcus Graham Project, the
              award-winning non-profit talent incubator created by Locomotus’
              founders.
            </p>
          </div>

          <p className="py-8 text-xl font-semibold text-white">
            Powered by COMMUNO
          </p>

          <div className="md:flex md:flex-row-reverse md:justify-end -mx-4">
            <div className="px-4">
              <Link
                to="/locomotus/signup"
                className="btn btn--tertiary btn--rev btn--full md:w-auto mt-8"
              >
                GET ON BOARD
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default React.memo(LandingLocomotus);
