import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useUserCollectionIds(userId: string) {
  return useQuery(
    ['userCollectionIds', userId],
    () => Api.listUserCollectionIds(userId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}
