import React, { useContext } from 'react';
import { Recommendation } from '@communo-corp/shared-types';
import UserContext from '../user/Context';
import MembershipContext from '../membership/Context';
import RecommendationBody from './RecommendationBody';

type Props = {
  recommendation: Recommendation;
  className?: string;
  onClickDelete: (recommendationId: string) => void;
};
const RecommendationDetail = ({
  recommendation,
  onClickDelete,
  className = '',
}: Props) => {
  const { id: currentUserId } = useContext(UserContext);
  const { checkAccess } = useContext(MembershipContext);

  const {
    firstName,
    lastName,
    email,
    title,
    school,
    department,
    organization,
  } = recommendation;

  const orgName = school ? school.name : organization;

  const ownsRecommendation = recommendation.user.id === currentUserId;
  const canViewEmail = ownsRecommendation || checkAccess('canPostWork');

  return (
    <div className={className}>
      <div className="flex flex-col md:flex-row">
        <div className="md:mr-8 md:w-68 relative">
          <p className="font-bold">
            {firstName} {lastName}
          </p>

          {canViewEmail ? (
            <p className="font-semibold text-red-900 mt-1">{email}</p>
          ) : null}

          <p className="text-sm mt-1">
            <strong>{orgName}</strong> <br />
            {title || null}
            {department ? `, ${department}` : null}
          </p>

          {ownsRecommendation ? (
            <button
              type="button"
              className="absolute top-0 right-0 md:static md:mt-4 icon-circle bg-red-900 text-white text-lg"
              onClick={() => onClickDelete(recommendation.id)}
            >
              <i className="i-trash" />
            </button>
          ) : null}
        </div>

        <div className="flex-1 mt-8 md:mt-0">
          <RecommendationBody recommendation={recommendation} />
        </div>
      </div>
    </div>
  );
};
export default RecommendationDetail;
