import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import cn from 'classnames';
import { sortBy } from 'lodash';
import InviteMemberForm from './InviteMemberForm';
import MembershipContext from '../Context';
import UserAvatar from '../../avatar/UserAvatar';
import MembershipApi from '../Api';
import UserApi from '../../user/Api';
import UserContext from '../../user/Context';
import Avatar from '../../avatar/Avatar';
import { LoadingBalls } from '../../core/Loading';
import Alert from '../../alert/Alert';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import RemoveCircleIcon from '../../icons/RemoveCircleIcon';
import ShieldOutlineIcon from '../../icons/ShieldOutlineIcon';
import ShieldIcon from '../../icons/ShieldIcon';
import EyeIcon from '../../icons/EyeIcon';
import EyeOffIcon from '../../icons/EyeOffIcon';
import AddCircleIcon from '../../icons/AddCircleIcon';
import Button from '../../core/Button';
import useFeatureGate from '../../../hooks/useFeatureGate';

interface Props {
  membership: MembershipEntity;
}

function isUnderSteatLimit(
  userCount: number,
  inviteCount: number,
  numSeats: number | null
): boolean {
  // null = unlimited
  if (numSeats === null) {
    return true;
  }

  return userCount + inviteCount < numSeats;
}

const TeamForm = ({ membership }: Props) => {
  const { id: userId, membershipAccess, isRoot } = useContext(UserContext);
  const { plan, refresh } = useContext(MembershipContext);
  const { openUpgradeFlowFor, needsUpgrade } = useFeatureGate();
  const [invites, setInvites] = useState<MembershipInvite[]>([]);
  const [allUsers, setAllUsers] = useState<MembershipUserEntity[]>([]);
  const numSeats = plan?.numSeats;
  const numberOfSeats = typeof numSeats === 'undefined' ? 0 : numSeats;
  const [initializing, setInitializing] = useState(true);
  const { id: membershipId } = membership;
  const [deactivateUser, setDeactivateUser] = useState<UserEntity | null>(null);
  const [showUser, setShowUser] = useState<UserEntity | null>(null);
  const [hideUser, setHideUser] = useState<UserEntity | null>(null);
  const [reactivateUser, setReactivateUser] = useState<UserEntity | null>(null);
  const [revokeAdmin, setRevokeAdmin] = useState<UserEntity | null>(null);
  const [promoteUser, setPromoteUser] = useState<UserEntity | null>(null);
  const [deleteInvite, setDeleteInvite] = useState<MembershipInvite | null>(
    null
  );

  const { activeUsers, inactiveUsers }: any = useMemo(() => {
    return sortBy(allUsers, 'membershipAccess').reduce(
      (carry: any, user: any) => {
        const group = user.user.isDeactivated ? 'inactiveUsers' : 'activeUsers';
        carry[group].push(user);
        return carry;
      },
      {
        activeUsers: [],
        inactiveUsers: [],
      }
    );
  }, [allUsers]);

  const refreshInvites = useCallback(() => {
    MembershipApi.invites(membershipId).then((response: MembershipInvite[]) => {
      setInvites(
        response.filter(
          (invite: MembershipInvite) => invite.status === 'active'
        )
      );
      setInitializing(false);
    });
  }, [membershipId]);

  const refreshAllUsers = useCallback(() => {
    MembershipApi.allUsers(membershipId).then(
      (response: MembershipUserEntity[]) => {
        setAllUsers(response);
      }
    );
  }, [membershipId]);

  const inviteCount = useMemo(() => invites.length, [invites]);
  const userCount = activeUsers.length;
  const canSendInvite = useMemo(() => {
    if (isRoot || numberOfSeats === null) return true;
    if (numberOfSeats < 0 || numberOfSeats - inviteCount - userCount > 0)
      return true;
    return false;
  }, [numberOfSeats, inviteCount, userCount, isRoot]);

  useEffect(() => {
    refreshInvites();
  }, [refreshInvites]);

  useEffect(() => {
    refreshAllUsers();
  }, [refreshAllUsers]);

  const confirmDeactivateUser = (user: UserEntity) => {
    setDeactivateUser(user);
  };

  const confirmReactivateUser = (user: UserEntity) => {
    if (!canSendInvite) {
      openUpgradeFlowFor('numSeats');
      return;
    }

    setReactivateUser(user);
  };

  const handleRefresh = () => {
    refreshInvites();
    refreshAllUsers();
    refresh();
  };

  const handleToggleSearchable = (isSearchable: boolean) => {
    const user = showUser || hideUser;
    if (!user) return;

    MembershipApi.updateUser(membership.id, user.id, {
      isSearchable,
    })
      .then(() => {
        addSuccess(
          `${user.firstName} ${user.lastName} is now ${
            isSearchable ? 'visible' : 'hidden'
          }.`
        );
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        if (showUser) setShowUser(null);
        if (hideUser) setHideUser(null);
      });
  };

  const handleDeactivateUser = () => {
    if (!deactivateUser) return;
    UserApi.deactivate(deactivateUser.id)
      .then(() => {
        addSuccess('User has been deactivated');
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        setDeactivateUser(null);
      });
  };

  const handleReactivateUser = () => {
    if (!reactivateUser) return;
    UserApi.reactivate(reactivateUser.id)
      .then(() => {
        addSuccess('User has been reactivated');
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        setReactivateUser(null);
      });
  };

  const confirmDeleteInvite = (invite: MembershipInvite) => {
    setDeleteInvite(invite);
  };

  const handleDeleteInvite = () => {
    if (!deleteInvite) return;
    MembershipApi.deleteInvite(membership.id, deleteInvite.id)
      .then(() => {
        addSuccess('Invite successfully removed');
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        setDeleteInvite(null);
      });
  };

  const confirmrevokeAdmin = (user: UserEntity) => {
    if (user.id === userId) return;
    setRevokeAdmin(user);
  };

  const handlerevokeAdmin = () => {
    if (!revokeAdmin) return;
    if (revokeAdmin.id === userId) return;

    MembershipApi.updateUser(membership.id, revokeAdmin.id, {
      membershipAccess: 'regular',
    })
      .then(() => {
        addSuccess('Admin permission removed succesfully');
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        setRevokeAdmin(null);
      });
  };

  const confirmPromoteUser = (user: UserEntity) => {
    if (user.id === userId) return;
    setPromoteUser(user);
  };

  const handlePromoteUser = () => {
    if (!promoteUser) return;
    if (promoteUser.id === userId) return;

    MembershipApi.updateUser(membership.id, promoteUser.id, {
      membershipAccess: 'admin',
    })
      .then(() => {
        addSuccess('Promoted user to admin successfully');
        handleRefresh();
      })
      .catch(addResponseError)
      .finally(() => {
        setPromoteUser(null);
      });
  };

  if (initializing) return <LoadingBalls isActive />;

  const underSeatLimit = isUnderSteatLimit(
    userCount,
    inviteCount,
    numberOfSeats
  );

  return (
    <>
      <div
        className={cn('text-xs mb-8 border-t-2 p-2 rounded-sm shadow', {
          'border-green-500': underSeatLimit,
          'border-red-500': !underSeatLimit,
        })}
      >
        You have used{' '}
        <span className="font-bold">{userCount + inviteCount}</span> of{' '}
        <span className="font-bold">
          {numberOfSeats === null ? 'unlimited' : numberOfSeats}
        </span>{' '}
        seats.
      </div>

      <div className="font-medium mb-2">Invite a member</div>
      {(membershipAccess === 'admin' || isRoot) && (
        <>
          {canSendInvite ? (
            <InviteMemberForm callback={handleRefresh} />
          ) : (
            <>
              <div className="text-xs my-4">
                In order to add more team members you must either remove an
                existing (or pending) member, or upgrade your membership to
                access more seats.
              </div>
              <Button
                onClick={() => {
                  openUpgradeFlowFor('numSeats');
                }}
                color="primary"
              >
                Upgrade
              </Button>
            </>
          )}
        </>
      )}

      <div className="font-medium mt-4">Team Legend</div>
      <div className="mt-2 flex space-x-6">
        <div>
          <ShieldIcon className="h-6 w-6 text-red-900 inline-block mr-2" />{' '}
          Admin
        </div>
        <div>
          <EyeIcon className="h-6 w-6 text-grey-800 inline-block mr-2" />
          <EyeOffIcon className="h-6 w-6 text-grey-800 inline-block mr-2" />{' '}
          Visibility Toggle
        </div>
      </div>

      <div className="font-medium mt-8">Team</div>
      {activeUsers.length > 0
        ? activeUsers?.map(
            ({
              user,
              membershipAccess: role,
            }: {
              user: UserEntity;
              membershipAccess: any;
            }) => {
              return (
                <div key={user.id} className="flex my-4 text-sm items-center">
                  <div className="mr-4">
                    <UserAvatar user={user} />
                  </div>
                  <div className="truncate">
                    <div className="font-medium">
                      {user.firstName} {user.lastName}{' '}
                      {userId === user.id ? '(me)' : ''}
                    </div>
                    <div className="text-xs text-subdued truncate">
                      {user.email}
                    </div>
                  </div>
                  <div className="text-xs ml-auto">
                    {role === 'admin' ? (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            if (needsUpgrade('canHideFromSearch')) {
                              return setHideUser(null);
                            }

                            if (user.isSearchable) {
                              return setHideUser(user);
                            }
                            return setShowUser(user);
                          }}
                          className="text-grey-800 hover:text-black mr-2"
                        >
                          {user.isSearchable ? (
                            <EyeIcon className="h-6 w-6" />
                          ) : (
                            <EyeOffIcon className="h-6 w-6" />
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            confirmrevokeAdmin(user);
                          }}
                          className={cn({
                            'text-grey-400 cursor-not-allowed':
                              userId === user.id,
                            'text-red-900 hover:text-black': userId !== user.id,
                          })}
                        >
                          <ShieldIcon className="h-6 w-6" />
                        </button>
                      </div>
                    ) : (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            confirmDeactivateUser(user);
                          }}
                          className="text-grey-800 hover:text-black mr-2"
                        >
                          <RemoveCircleIcon className="h-6 w-6" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            if (user.isSearchable) {
                              return setHideUser(user);
                            }
                            return setShowUser(user);
                          }}
                          className="text-grey-800 hover:text-black mr-2"
                        >
                          {user.isSearchable ? (
                            <EyeIcon
                              className="h-6 w-6"
                              onClick={() => setHideUser(user)}
                            />
                          ) : (
                            <EyeOffIcon
                              className="h-6 w-6"
                              onClick={() => setShowUser(user)}
                            />
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            confirmPromoteUser(user);
                          }}
                          className="text-red-500 hover:text-red-900"
                        >
                          <ShieldOutlineIcon className="h-6 w-6" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )
        : ''}

      {invites.length > 0 &&
        invites.map((invite: MembershipInvite) => {
          return (
            <div key={invite.id} className="flex my-4 text-sm items-center">
              <div className="mr-4">
                <Avatar avatarName={invite.email} />
              </div>
              <div>
                <div className="mr-4 font-medium">{invite.email}</div>
                <div className="text-xs text-subdued">pending invite</div>
              </div>
              <div className="text-xs text-subdued ml-auto flex">
                <button
                  type="button"
                  onClick={() => confirmDeleteInvite(invite)}
                  className="text-grey-800 hover:text-black"
                >
                  <RemoveCircleIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          );
        })}

      <div className="font-medium mt-12 mb-2">Inactive</div>
      {inactiveUsers.length > 0
        ? inactiveUsers?.map(
            ({ user }: { user: UserEntity; membershipAccess: any }) => {
              return (
                <div key={user.id} className="flex my-4 text-sm items-center">
                  <div className="mr-4">
                    <UserAvatar user={user} />
                  </div>
                  <div>
                    <div className="font-medium text-subdued">
                      {user.firstName} {user.lastName}{' '}
                      {userId === user.id ? '(me)' : ''}
                    </div>
                    <div className="text-xs text-subdued">({user.email})</div>
                  </div>
                  <div className="text-xs text-subdued ml-auto flex">
                    <button
                      type="button"
                      onClick={() => {
                        confirmReactivateUser(user);
                      }}
                      className="text-grey-800 hover:text-black"
                    >
                      <AddCircleIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              );
            }
          )
        : ''}

      <Alert
        isOpen={!!showUser}
        header="Show user?"
        message={`Showing ${showUser?.firstName} ${showUser?.lastName} will allow them to be discovered and shown in-app.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setShowUser(null);
            },
          },
          {
            text: 'Show',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleToggleSearchable(true);
            },
          },
        ]}
      />

      <Alert
        isOpen={!!hideUser}
        header="Hide user?"
        message={`Hiding ${hideUser?.firstName} ${hideUser?.lastName} will stop them from being discovered and shown in-app, while still allowing direct links to their profile.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setHideUser(null);
            },
          },
          {
            text: 'Hide',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleToggleSearchable(false);
            },
          },
        ]}
      />

      <Alert
        isOpen={!!promoteUser}
        header="Promote user to admin?"
        message={`Making ${promoteUser?.firstName} ${promoteUser?.lastName} admin will let them create more admins and manage the company page.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setPromoteUser(null);
            },
          },
          {
            text: 'Promote to admin',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handlePromoteUser();
            },
          },
        ]}
      />

      <Alert
        isOpen={!!revokeAdmin}
        header="Revoke admin permissions?"
        message={`Revoking admin permissions from ${revokeAdmin?.firstName} ${revokeAdmin?.lastName} will no longer allow them to manage the company page.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setRevokeAdmin(null);
            },
          },
          {
            text: 'Revoke admin',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handlerevokeAdmin();
            },
          },
        ]}
      />

      <Alert
        isOpen={!!reactivateUser}
        header="Reactivate user?"
        message={`Reactivating ${reactivateUser?.firstName} ${reactivateUser?.lastName} will take up 1 seat and automatically allow them to log in again.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setReactivateUser(null);
            },
          },
          {
            text: 'Reactivate',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleReactivateUser();
            },
          },
        ]}
      />

      <Alert
        isOpen={!!deactivateUser}
        header="Deactivate user?"
        message={`Deactivating ${deactivateUser?.firstName} ${deactivateUser?.lastName} will free up 1 seat. You can always re-activate them if there are seats available.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setDeactivateUser(null);
            },
          },
          {
            text: 'Deactivate',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleDeactivateUser();
            },
          },
        ]}
      />

      <Alert
        isOpen={!!deleteInvite}
        header="Remove Invitation?"
        message={`Removing invitation for (${deleteInvite?.email}) will free up 1 seat and prevent this person from joining your team. You can always send another invite.`}
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setDeleteInvite(null);
            },
          },
          {
            text: 'Remove',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleDeleteInvite();
            },
          },
        ]}
      />
    </>
  );
};

export default TeamForm;
