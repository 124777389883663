/* eslint-disable import/prefer-default-export */

export const substring = (s: string, max: number, dots: boolean = true) =>
  s.length > max ? `${s.substr(0, max).trim()}${dots ? '...' : ''}` : s;

export const randomHash = (length = 36) =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, length);

export function stripLeft(haystack: string, needle: string): string {
  if (haystack.startsWith(needle, 0)) {
    return haystack.substring(needle.length, haystack.length);
  }

  return haystack;
}
