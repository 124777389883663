import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import RightArrowCircleIcon from '../icons/RightArrowCircleIcon';
import Swiper from '../swiper/Swiper';
import LeftArrowCircleIcon from '../icons/LeftArrowCircleIcon';
import cloudinary from '../../utilities/Cloudinary';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import useForceMobile from '../../hooks/useForceMobile';

type Props = {
  membership: MembershipEntity;
};

const Slide = React.memo(
  ({ imageURL, alt }: { imageURL: string; alt: string }) => {
    const [isError, setError] = useState(false);

    if (isError) {
      return null;
    }

    return (
      <div className="pb-4 pr-4 rounded-md text-center">
        <img
          src={cloudinary(imageURL, ['w_640', 'q_70'])}
          alt={alt}
          className="w-auto rounded-md inline-block"
          onError={() => setError(true)}
        />
      </div>
    );
  }
);

const CompanyPhotosSlider = ({ membership }: Props) => {
  const swiper = useRef<any>();
  const [slideIndex, setSlideIndex] = useState(0);
  const [pages, setPages] = useState(0);
  const forceMobile = useForceMobile();

  const slides = useMemo(
    () =>
      membership.imageURLs?.map(imageURL => (
        <Slide imageURL={imageURL} alt={membership.name} />
      )) ?? [],
    [membership.imageURLs, membership.name]
  );

  const onNext = useCallback(() => {
    setSlideIndex(prev => Math.min(pages, prev + 1));
  }, [pages]);

  const onPrev = useCallback(() => {
    setSlideIndex(prev => Math.max(0, prev - 1));
  }, []);

  const onSlideChange = useCallback(() => {
    setSlideIndex(swiper.current.activeIndex);
  }, []);

  useEffect(() => {
    if (swiper.current && swiper.current.activeIndex !== slideIndex) {
      swiper.current.slideTo(slideIndex);
    }
  }, [slideIndex]);

  const onPaginationRender = useCallback(() => {
    const pageCount =
      swiper.current?.pagination?.bullets?.length ?? slides.length ?? 0;
    setPages(pageCount);
  }, [slides.length]);

  const isBeginning = slideIndex === 0;
  const isEnd = slideIndex === pages - 1;

  if (slides.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={mc('flex justify-between mt-12 lg:mt-4', forceMobile)}>
        <h4>Life at {membership.name}</h4>
        <div className={mc('hidden sm:block', forceMobile)}>
          <button
            type="button"
            onClick={() => onPrev()}
            disabled={isBeginning}
            className={cn('mr-8', {
              'text-black': !isBeginning,
              'text-grey-300': isBeginning,
            })}
          >
            <LeftArrowCircleIcon className="w-8 h-8" />
          </button>
          <button
            type="button"
            onClick={() => onNext()}
            disabled={isEnd}
            className={cn({
              'text-black': !isEnd,
              'text-grey-300': isEnd,
            })}
          >
            <RightArrowCircleIcon className="w-8 h-8" />
          </button>
        </div>
      </div>
      <div className="mt-4">
        <Swiper
          ref={swiper}
          items={slides}
          paginate
          className="company-photos-slider"
          swiperConfig={{
            slidesPerView: 'auto',
          }}
          eventHandlers={{
            paginationRender: onPaginationRender,
            slideChange: onSlideChange,
          }}
        />
      </div>
    </div>
  );
};
export default CompanyPhotosSlider;
