import React from 'react';
import { Redirect } from 'react-router';
import { LoadingRing } from '../../core/Loading';
import Page from '../../core/Page';
import { Error404 } from '../../error';
import useAccessibleCollections from '../hooks/useAccessibleCollections';

const BenchRedirect = ({ modal }: { modal?: string }) => {
  const { isLoading, collections } = useAccessibleCollections();

  if (isLoading || !collections) {
    return (
      <div className="py-20 text-center">
        <LoadingRing isActive color="primary" size="lg" />
      </div>
    );
  }

  const bench = collections.find(collection => collection.type === 'bench');

  if (!bench) {
    return <Error404 message="Unable to find your bench." />;
  }

  return <Redirect to={`/lists/${bench.id}/${modal ?? ''}`} />;
};

const BenchRedirectPage = ({ match }: any) => {
  const { modal } = match.params;

  return (
    <Page authGuard pageTitle="Bench">
      <BenchRedirect modal={modal} />
    </Page>
  );
};

export default React.memo(BenchRedirectPage);
