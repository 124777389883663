import React, { useEffect, useRef } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import CandidateScore from '../candidateScore/CandidateScore';

const WorkScore = React.memo(
  ({
    work,
    qualification,
    refresh,
  }: {
    work: WorkEntity;
    qualification: QualificationEntity;
    refresh: Function;
  }) => {
    const mountedRef = useRef(false);
    const { user, membership } = useCurrentUser();
    const { scoreMetrics, requirements } = qualification;

    useEffect(() => {
      // Don't call refresh on initial render to prevent
      // the qualification endpoint from being called right away
      if (mountedRef.current) {
        refresh();
      }
    }, [user, membership, refresh]);

    useEffect(() => {
      mountedRef.current = true;
    }, []);

    if (!user || !membership || !work) {
      return null;
    }

    return (
      <div className="mb-16">
        <CandidateScore
          user={user}
          membership={membership}
          work={work}
          // score={score || 0}
          scoreMetrics={scoreMetrics}
          requirements={requirements}
          refresh={refresh}
        />
      </div>
    );
  }
);
export default WorkScore;
