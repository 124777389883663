import { Api } from '../../Api';

export default {
  listEducationForUser: (userId: string, isPublic: boolean = false) =>
    isPublic
      ? Api.get(`/education/${userId}/public`)
      : Api.get(`/education/${userId}`),
  createEducation: (payload: any) => Api.post('/education', payload),
  editEducation: (educationId: string, payload: any) =>
    Api.patch(`/education/${educationId}`, payload),
  deleteEducation: (educationId: string) =>
    Api.delete(`/education/${educationId}`),
  createSchool: (payload: { name: string; countryIsoCode: string }) =>
    Api.post('/school', payload),
};
