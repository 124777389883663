import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormGroup, Autocomplete } from '../form';
import SearchContext from '../../search/Context';
import useFeatureGate from '../../../hooks/useFeatureGate';

interface Props
  extends Pick<
    AutocompleteProps,
    'form' | 'min' | 'defaultValues' | 'isMulti'
  > {
  max?: number | null;
  upgradePath?: 'numUserIndustries' | 'numMembershipIndustries';
  overrideLabel?: string | null;
}

export default ({
  form,
  min,
  max,
  defaultValues,
  isMulti = true,
  upgradePath,
  overrideLabel = null,
}: Props) => {
  const [defaultOptions, setDefaultOptions] = useState<any>([]);
  const { openUpgradeFlowFor } = useFeatureGate();
  const { getIndustries } = useContext(SearchContext);
  const upgradeMessageShown = useRef(false);

  const { errors, watch } = form;
  const name = 'industries';
  const label =
    overrideLabel ||
    `${isMulti ? 'Industries' : 'Industry'}${
      max && max > 0 ? ` (max ${max})` : ''
    }`;
  const watchedIndustries = watch(name);
  const watchedIndustriesCount = watchedIndustries?.length ?? 0;

  useEffect(() => {
    if (
      max &&
      watchedIndustriesCount > max &&
      upgradePath &&
      !upgradeMessageShown.current
    ) {
      openUpgradeFlowFor(upgradePath, max + 1);
      upgradeMessageShown.current = true;
    }
  }, [max, openUpgradeFlowFor, upgradePath, watchedIndustriesCount]);

  const getResults = useCallback(
    (q: string) =>
      getIndustries(q).then(({ hits }: any) => {
        return hits.map((hit: any) => {
          return { value: hit.objectID, label: hit.name };
        });
      }),
    [getIndustries]
  );

  useEffect(() => {
    getResults('').then((results: any) => {
      setDefaultOptions(results);
    });
  }, [getResults]);

  return (
    <FormGroup name={name} label={label} errors={errors}>
      <Autocomplete
        name={name}
        form={form}
        min={min}
        max={max === null ? undefined : max}
        defaultValues={defaultValues}
        defaultOptions={defaultOptions}
        callback={getResults}
        isMulti={isMulti}
        placeholder="Type to search industries..."
      />
    </FormGroup>
  );
};
