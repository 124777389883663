import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { addSuccess, addResponseError } from '../../../services/Messaging';
import { FormInput } from '../../core/form';
import UserContext from '../Context';

const Form = () => {
  const { refresh, update, user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm({});

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    update({
      password: values.password,
      oldPassword: values.oldPassword,
    })
      .then(() => {
        refresh();
        addSuccess('Your password has been updated!');
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} />
      <form
        className="reset-password-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {!user?.isPasswordEmpty ? (
          <FormInput
            name="oldPassword"
            label="Old Password"
            type="password"
            ref={register({ required: 'Old password is required' })}
            errors={errors}
          />
        ) : null}

        <FormInput
          name="password"
          label="New Password"
          type="password"
          ref={register({ required: 'New password is required' })}
          errors={errors}
        />

        <div className="ion-margin-top">
          <div className="mr-2">
            <button className="btn btn--primary" type="submit">
              <span className="btn__text">Save Changes</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
