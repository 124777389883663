import React, { useCallback, useContext, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router';
import { Browser } from '@capacitor/browser';
import Card from './Card';
import CardImage from './CardImage';
import CardLogo from './CardLogo';
import { DEFAULT_COVER_PHOTO_URL } from '../../asset-config';
import UserContext from '../user/Context';
import cloudinary from '../../utilities/Cloudinary';
import { currency } from '../../utilities/Number';
import { ensureHttp } from '../../utilities/Url';
import useAnalytics from '../../hooks/useAnalytics';
import { srcSetFunction } from '../../utilities/Image';

type Props = {
  event: EventEntity;
  cardStyle?: 'limited' | 'full';
  className?: string;
};

function getDisplayTitle(event: EventEntity) {
  if (event.companyName) return event.companyName;
  if (event.membership?.plan?.type === 'individual' && event.user?.firstName)
    return `${event.user?.firstName} ${event.user?.lastName}`;
  return event.membership?.name || '';
}

function getCoverPhotoURL(event: EventEntity) {
  return (
    event.imageURL || event.membership?.coverPhotoURL || DEFAULT_COVER_PHOTO_URL
  );
}

function renderLimitedCard(event: EventEntity, eventDate: Moment | null) {
  return (
    <div className="card__body relative flex">
      <div className="pr-6">
        {event.membership && (
          <CardLogo
            imageUrl={event.membership.logoURL}
            shape="square"
            altText={event.membership?.name}
            logoSize="lg"
          />
        )}
        {!event.membership && event.logoURL && (
          <CardLogo
            imageUrl={event.logoURL}
            shape="square"
            altText={getDisplayTitle(event)}
            logoSize="lg"
          />
        )}
        {eventDate ? (
          <div className="card__date mt-3">
            <div className="text-base">{eventDate.format('MMM')}</div>
            <div className="text-xl">{eventDate.format('DD')}</div>
          </div>
        ) : null}
      </div>
      <div className="flex-1 flex flex-col justify-between overflow-hidden">
        <div>
          <p className="text-large mb-3 leading-snug tracking-tight">
            {getDisplayTitle(event)}
          </p>
          <p className="font-semibold text-grey-500 mt-1">
            {event.category?.name}
          </p>
          <h6 className="mt-1 leading-tight break-words">{event.name}</h6>
          <p className="mt-1">
            {event.isOnline ? 'Online' : event.location?.friendlyName}
          </p>
        </div>
        <div className="flex items-baseline justify-between">
          <p className="">{event.type}</p>
          <p className="text-right text-large mt-4">
            {event.price && event.price > 0 ? currency(event.price) : 'Free'}
          </p>
        </div>
      </div>
    </div>
  );
}

function renderFullCard(event: EventEntity, eventDate: Moment | null) {
  return (
    <>
      {eventDate ? (
        <div className="card__date">
          <div className="text-base">{eventDate.format('MMM')}</div>
          <div className="text-xl">{eventDate.format('DD')}</div>
        </div>
      ) : null}

      <div className="card__body relative flex flex-col justify-between text-left">
        <div>
          <div className="absolute top-0 transform -translate-y-1/2">
            {event.membership && (
              <CardLogo
                imageUrl={event.membership.logoURL}
                altText={event.membership?.name}
                logoSize="xl"
                borderSize="lg"
              />
            )}
            {!event.membership && event.logoURL && (
              <CardLogo
                imageUrl={event.logoURL}
                altText={getDisplayTitle(event)}
                logoSize="xl"
                borderSize="lg"
              />
            )}
          </div>
          <p className="text-xl mb-3 leading-snug tracking-tight">
            {getDisplayTitle(event)}
          </p>
          <p className="font-semibold text-grey-500 mt-1">
            {event.category?.name}
          </p>
          <h5 className="mt-1 leading-tight break-words">{event.name}</h5>
          <p className="mt-1">
            {event.isOnline ? 'Online' : event.location?.friendlyName}
          </p>
        </div>
        <div className="flex items-baseline justify-between">
          <p className="">{event.type}</p>
          <p className="text-right text-large mt-4">
            {event.price && event.price > 0 ? currency(event.price) : 'Free'}
          </p>
        </div>
      </div>
    </>
  );
}

const EventActions = ({ id }: { id: string | null }) => {
  const { push } = useHistory();
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    push(`#/event/${id}/edit`);
  };

  if (!id) return null;

  return (
    <div className="relative w-full" style={{ zIndex: 100 }}>
      <div className="absolute top-0 right-0 pr-4 pt-2">
        <div className="text-sm text-link" onClick={handleClick} role="none">
          Edit
        </div>
      </div>
    </div>
  );
};

const EventCard = ({ event, cardStyle = 'limited', className = '' }: Props) => {
  const { name } = event;
  const { track } = useAnalytics();
  const { membershipId, isRoot } = useContext(UserContext);
  const canEdit: boolean = useMemo(
    () => isRoot || membershipId === event?.membership?.id,
    [isRoot, membershipId, event]
  );
  const coverPhotoURL = getCoverPhotoURL(event);

  const image = useMemo(
    () => (
      <CardImage
        srcSet={srcSetFunction(coverPhotoURL, ['600', '1000', '1300'])}
        imageUrl={cloudinary(coverPhotoURL || '', ['w_826', 'h_414'])}
        altText={name || ''}
        borderRadius="medium"
        roundedBottom={false}
        aspectRatio="2/1"
      />
    ),
    [name, coverPhotoURL]
  );

  const eventDate = event.expiresAt ? moment(event.expiresAt).utc() : null;

  const handleClick = useCallback(() => {
    if (event.externalURL) {
      const {
        id: eventId,
        name: eventName,
        type,
        expiresAt,
        price,
        externalURL,
        isFeatured,
        companyName,
      } = event;

      track('External Event Clicked', {
        eventId,
        eventName,
        type,
        expiresAt,
        price,
        externalURL,
        isFeatured,
        companyName,
        location: event.location?.friendlyName,
        membershipId: event?.membership?.id,
        membershipName: event?.membership?.name,
        category: event?.category?.name,
      });
      Browser.open({ url: ensureHttp(event.externalURL) });
    }
  }, [event, track]);

  const cardStyles = {
    limited: renderLimitedCard,
    full: renderFullCard,
  };

  // purgecss: card--event--limited card--event--full
  return (
    <Card
      imageComponent={cardStyle === 'full' ? image : null}
      className={`card--event card--event--${cardStyle} ${className}`}
      onClick={handleClick}
    >
      <>
        {canEdit && <EventActions id={event.id || event.objectID || null} />}
        {cardStyles[cardStyle](event, eventDate)}
      </>
    </Card>
  );
};
export default React.memo(EventCard);
