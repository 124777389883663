import { SearchResponse } from '@algolia/client-search';
import orderBy from 'lodash/orderBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';
import { thousands } from '../../../utilities/Number';
import { groupRoles } from '../../roles/Util';

export default function getRoleOptions(
  results: { [key: string]: SearchResponse },
  queryFilters: { [key: string]: {} | string },
  roles: RoleEntity[]
) {
  const roleFacetOptions: any = {};
  const userRoleFacetOptions = getFacetValuesFromResponse(
    results['user-role'] || results['user-search'],
    'roles.role.name'
  );
  const membershipRoleFacetOptions = getFacetValuesFromResponse(
    results['membership-role'] || results['membership-search'],
    'roles.role.name'
  );
  Object.keys(userRoleFacetOptions).forEach(key => {
    const count = roleFacetOptions[key] || 0;
    roleFacetOptions[key] = count + userRoleFacetOptions[key];
  });
  Object.keys(membershipRoleFacetOptions).forEach(key => {
    const count = roleFacetOptions[key] || 0;
    roleFacetOptions[key] = count + membershipRoleFacetOptions[key];
  });

  let opts = roleFacetOptions;
  if (queryFilters.type === 'people') {
    opts = userRoleFacetOptions;
  } else if (queryFilters.type === 'agency') {
    opts = membershipRoleFacetOptions;
  }

  const filteredRoles = roles.filter(role => {
    return !!opts[role.name];
  });

  const roleOptions = groupRoles(filteredRoles, role => {
    const count = opts[role.name] ?? 0;

    return {
      value: role.name,
      activeLabel: role.name,
      label: `${role.name} (${thousands(count)})`,
      count,
    };
  });

  return orderBy(roleOptions, ['count'], ['desc']);
}
