/* eslint-disable no-nested-ternary */
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import PullToRefreshContext, {
  PullToRefreshContextProvider,
} from '../../context/PullToRefreshContext';
import useStatusBar from '../../hooks/useStatusBar';
import { isMobile } from '../../utilities/Device';
import MembershipContext from '../membership/Context';
import UserContext from '../user/Context';
import SignupLayout from '../user/signup/SignupLayout';
import LoginRequired from '../user/views/LoginRequired';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import Layout from './Layout';
import { DEFAULT_LOGIN_REQUIRED_COVER_PHOTO_URL } from '../../asset-config';

type ViewCommonProps = {
  children: any; // JSX.Element[] | JSX.Element
  className?: string;
};

type ViewProps = {
  id?: string;
  pageTitle?: string;
  authGuard?: boolean;
  publicAuthGuard?: boolean;
  permissions?: Permission; // todo allow multiple permissions | Permission[];
  unMountOnLeave?: boolean; // allow components to be unmounted, instead of staying hydrated
  darkStatusBar?: boolean;
} & ViewCommonProps;

type ViewContentProps = {
  layout?: Layouts | null;
  layoutClassName?: string;
  pullToRefresh?: boolean;
  noScroll?: boolean; // allow IonContent scroll to be disabled
  [rest: string]: any;
} & ViewCommonProps;

type ViewHeaderProps = {
  end?: React.ReactNode;
  defaultBackHref?: string;
  dark?: boolean;
  onBackButtonClick?: () => void;
} & ViewCommonProps;

type ViewFooterProps = {} & ViewCommonProps;

export const ViewHeaderTitle = ({
  large = false,
  children,
}: {
  large?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      {large ? (
        <h2 className="truncate">{children}</h2>
      ) : (
        <h6 className="truncate text-center">{children}</h6>
      )}
    </>
  );
};

export const ViewHeader = ({
  end,
  defaultBackHref = '',
  dark = false,
  className,
  children,
  onBackButtonClick,
}: ViewHeaderProps) => {
  const classes = ['view-header'];

  if (dark) classes.push('view-header--dark');

  if (className?.length) {
    classes.push(className);
  }

  return (
    <IonHeader mode="ios" className={classes.join(' ')}>
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          {defaultBackHref.length > 0 ? (
            <IonBackButton defaultHref={defaultBackHref} />
          ) : (
            onBackButtonClick && (
              <button
                type="button"
                className="mt-1 ml-3"
                onClick={onBackButtonClick}
              >
                <IonIcon size="large" icon={arrowBackOutline} />
              </button>
            )
          )}
        </IonButtons>
        {children}
        <IonButtons slot="end">{end || null}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export const ViewFooter = ({ className, children }: ViewFooterProps) => {
  const classes = [];

  if (className?.length) {
    classes.push(className);
  }
  return <IonFooter className={classes.join(' ')}>{children}</IonFooter>;
};

export const ViewContent = React.forwardRef(
  (
    {
      layout = 'default',
      layoutClassName = '',
      pullToRefresh = false,
      noScroll = false,
      className,
      children,
      ...rest
    }: ViewContentProps,
    ref?: any
  ) => {
    const { ionContentRef } = useContext(AppContext);
    const { onRefreshStart } = useContext(PullToRefreshContext);
    const contentRef: any = useRef();

    const classes = ['view-content'];

    if (className?.length) {
      classes.push(className);
    }

    const onRefChange = useCallback(
      node => {
        contentRef.current = node;
        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
            return;
          }
          // eslint-disable-next-line no-param-reassign
          ref.current = node;
        }
      },
      [ref]
    );

    useIonViewWillEnter(() => {
      ionContentRef.current = contentRef.current;
    }, []);

    return (
      <IonContent
        className={classes.join(' ')}
        ref={onRefChange}
        scrollY={!noScroll}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {pullToRefresh && isMobile ? (
          <IonRefresher
            slot="fixed"
            onIonRefresh={onRefreshStart}
            closeDuration="280ms"
            snapbackDuration="400ms"
            pullMin={60}
            pullMax={150}
          >
            <IonRefresherContent pullingIcon="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='30' stroke='grey' d='M112 268l144 144 144-144M256 392V100' class='ionicon-fill-none'/></svg>" />
          </IonRefresher>
        ) : null}

        {layout ? (
          <Layout className={layoutClassName} layout={layout}>
            {children}
          </Layout>
        ) : (
          children
        )}
      </IonContent>
    );
  }
);

const RequiresLogin = () => {
  return (
    <>
      {/* <Header /> */}
      <ViewContent
        layout="none"
        layoutClassName="bg-white min-h-full"
        className="page-bg--white"
      >
        <SignupLayout headerImg={DEFAULT_LOGIN_REQUIRED_COVER_PHOTO_URL}>
          <div>
            <LoginRequired />
          </div>
        </SignupLayout>
      </ViewContent>
    </>
  );
};

const AccessDenied = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Header />
      <ViewContent layout="basic" className="page-bg--white">
        <div className="text-center">
          <div className="font-medium">Hmm..</div>
          <div>Nothing to see here</div>
          <Link className="btn btn--sm btn--primary mt-4" to="/feed" replace>
            Get me outta here!
          </Link>
          <div className="text-subdued mt-4 text-sm">{pathname}</div>
        </div>
      </ViewContent>
    </>
  );
};

const View = ({
  id = '',
  children,
  className = 'page-bg--grey',
  pageTitle,
  authGuard = false,
  publicAuthGuard = false,
  permissions,
  unMountOnLeave = false,
  darkStatusBar = false,
}: ViewProps) => {
  const [isMounted, setIsMounted] = useState(true);
  const { setDocumentTitle, refreshing, pageRef } = useContext(AppContext);
  const { isAuthenticated, isPublic } = useContext(UserContext);
  const { checkAccess } = useContext(MembershipContext);

  useStatusBar({ isDark: darkStatusBar });

  const classes = [className];

  if (isMobile) {
    classes.push('isMobileDevice');
  }

  // handle isMounted
  useIonViewWillEnter(() => {
    setIsMounted(true);
  }, []);

  useIonViewWillLeave(() => {
    setIsMounted(false);
  }, []);

  // set/unset page title
  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, [pageTitle, setDocumentTitle]);

  useIonViewWillEnter(() => {
    setDocumentTitle(pageTitle);
  }, [setDocumentTitle, pageTitle]);

  useIonViewWillLeave(() => {
    setDocumentTitle();
  }, [setDocumentTitle]);

  const canBeMounted = useMemo(() => {
    return unMountOnLeave === false || isMounted;
  }, [unMountOnLeave, isMounted]);

  const guarded = useMemo(() => {
    return (authGuard && !isAuthenticated) || (publicAuthGuard && !isPublic);
  }, [authGuard, isAuthenticated, publicAuthGuard, isPublic]);

  const canAccess = useMemo(() => {
    return permissions ? checkAccess(permissions) : true;
  }, [permissions, checkAccess]);

  return (
    <IonPage id={id} ref={pageRef} className={classes.join(' ')}>
      <ErrorBoundary message="This page has a problem :/" fullscreen>
        {guarded ? (
          <RequiresLogin />
        ) : canAccess ? (
          !refreshing && canBeMounted ? (
            children
          ) : null
        ) : (
          <AccessDenied />
        )}
      </ErrorBoundary>
    </IonPage>
  );
};

export default React.memo((props: ViewProps) => {
  return (
    <PullToRefreshContextProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <View {...props} />
    </PullToRefreshContextProvider>
  );
});
