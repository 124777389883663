/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
// eslint-disable-next-line no-unused-vars
import Select, { OptionTypeBase } from 'react-select';
import { GOOGLE_MAPS_API_KEY } from '../../config';
import { ClearIndicator, Input } from '../select/Components';

type Props = {
  google: any;
  onChange?: (location?: {
    coordinates?: google.maps.LatLngLiteral;
    address?: string;
    raw?: google.maps.GeocoderResult;
  }) => void;
  value?: { coordinates?: google.maps.LatLngLiteral; address?: string };
  placeholder?: string;
};

type State = {
  address: string | null;
  selected: OptionTypeBase | null;
};

class LocationSearchInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: props.value?.address ?? null,
      selected: null,
    };
  }

  componentDidMount() {
    const { onChange, value } = this.props;
    if (value?.address) {
      geocodeByAddress(value.address)
        .then(async results => {
          if (results.length && onChange) {
            const choice = results[0];
            this.setState({
              selected: {
                label: choice.formatted_address,
                value: choice.place_id,
              },
            });
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.setState({ address: '' });
          if (onChange) {
            onChange();
          }
        });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;

    if (prevProps.value && !value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        address: null,
        selected: null,
      });
    }
  }

  handleChange = (address: any) => {
    this.setState({ address });
  };

  handleSelect = (address: any) => {
    const { onChange } = this.props;

    if (address) {
      this.setState({ address: address.label, selected: address });
      geocodeByAddress(address.label)
        .then(async results => {
          if (onChange) {
            const latLng = await getLatLng(results[0]);
            onChange({
              coordinates: latLng,
              address: address.label,
              raw: results[0],
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error('Error', error));
    } else {
      this.setState({ address: null, selected: null });
      if (onChange) {
        onChange();
      }
    }
  };

  render() {
    const { address, selected } = this.state;
    const { placeholder = 'Enter city/town' } = this.props;

    const searchOptions = {
      types: ['geocode'],
    };

    return (
      <PlacesAutocomplete
        value={address || ''}
        onChange={this.handleChange}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, loading }) => (
          <Select
            placeholder={placeholder}
            filterOption={() => {
              return true;
            }}
            className="multiselect"
            classNamePrefix="multi"
            isClearable
            isLoading={loading}
            onInputChange={newValue =>
              getInputProps().onChange({ target: { value: newValue } })
            }
            options={suggestions.map(suggestion => ({
              label: suggestion.description,
              value: suggestion.description,
            }))}
            noOptionsMessage={() => null}
            onChange={this.handleSelect}
            value={selected}
            components={{
              Input,
              DropdownIndicator: () => null,
              ClearIndicator,
            }}
            maxMenuHeight={250}
          />
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
})(LocationSearchInput);
