import React from 'react';
import Button, { ButtonFillType } from '../../core/Button';

type Props = {
  buttonFill: ButtonFillType;
  buttonFullWidth: boolean;
  buttonText: string;
  toggleModal: (bool: boolean) => void;
};

const ShareWorkButton = ({
  buttonFill,
  buttonFullWidth,
  buttonText,
  toggleModal,
}: Props) => {
  return (
    <Button
      className="flex-1"
      fill={buttonFill}
      color="primary"
      fullWidth={buttonFullWidth}
      onClick={() => toggleModal(true)}
    >
      {buttonText}
    </Button>
  );
};

export default ShareWorkButton;
