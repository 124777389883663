import React from 'react';
import useForceMobile from '../../hooks/useForceMobile';
import { mediaClasses as mc } from '../../utilities/MatchMedia';

type Props = {
  entityColumn: JSX.Element;
  sidebarColumn: JSX.Element | null;
  detailsColumn: JSX.Element | null;
  body?: JSX.Element | null;
};

const ProfileLayout = ({
  entityColumn,
  sidebarColumn,
  detailsColumn,
  body = null,
}: Props) => {
  const forceMobile = useForceMobile();

  return (
    <div className={mc('px-4 lg:px-8 relative', forceMobile)}>
      <div className="page-container">
        <div
          className={mc(
            'profile-columns mt-8 lg:mt-16 grid gap-8',
            forceMobile
          )}
        >
          <div
            className={mc(
              'profile-columns__entity text-center sm:text-left',
              forceMobile
            )}
          >
            {entityColumn}
          </div>
          <div className="profile-columns__sidebar">{sidebarColumn}</div>
          <div className="profile-columns__details">{detailsColumn}</div>
        </div>

        {body}
      </div>
    </div>
  );
};
export default ProfileLayout;
