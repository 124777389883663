import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormInput } from '../../core/form';
import VideoPlayer from '../../video/VideoPlayer';

const Video = ({ form }: { form: UseFormMethods<any> }) => {
  const { watch, errors, register } = form;

  const videoUrl = watch('videoUrl');

  return (
    <div className="mb-6">
      <FormInput
        name="videoUrl"
        label="Video"
        placeholder="Enter a YouTube or Vimeo URL..."
        hint="We support videos from YouTube, Vimeo, Facebook, and Wistia."
        errors={errors}
        ref={register()}
      />

      {videoUrl ? (
        <div className="mt-2">
          <VideoPlayer
            videoUrl={videoUrl}
            errorContent={
              <div className="notice notice--danger">
                The video URL you entered is not supported.
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default Video;
