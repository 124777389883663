import { ChannelInteraction } from '../../@types/chat.d';
import { Api, BaseAPI } from '../../Api';

const customApi = {
  /**
   * Returns all the channels the user is a part of
   */
  listMine: (): Promise<ChannelInteraction[]> => Api.get(`/channel/mine`),
  interact: (channelId: TCardId, interaction: string, payload?: object) =>
    Api.post(`/channel/${channelId}/interact`, {
      type: interaction,
      ...payload,
    }),
  share: (channelId: TCardId, recipients: string[]) =>
    Api.post(`/channel/${channelId}/share`, {
      recipients,
    }),
  latest: () => Api.get(`/channel/latest`),
  popular: () => Api.get(`/channel/popular`),
  addAdminToChannel: ({ channelId, userId }: TChannelInteractionVariables) => {
    return Api.post(`/channel/${channelId}/admin/add`, { userId });
  },
  revertChannelAdminToParticipant: ({
    channelId,
    userId,
  }: TChannelInteractionVariables) => {
    return Api.post(`/channel/${channelId}/admin/revert`, { userId });
  },
  toggleChannelState: ({ channelId }: { channelId: string }) => {
    return Api.put(`/channel/${channelId}/toggle-state`);
  },
  blockMember: ({ channelId, userId }: TChannelInteractionVariables) => {
    return Api.patch(`/channel/${channelId}/member/${userId}/interaction`, {
      type: 'blocked',
    });
  },
  /**
   * Returns all the blocked members from a channel
   *
   * @param channelId - the channel id
   * @returns an array blocked members
   */
  getBlockedMembers: (channelId: string) =>
    Api.get(`/channel/${channelId}/blocked`),

  /**
   * Updates a user's channel_interaction state to that of an unblocked state
   *
   * @param object.channelId - the channel whose user needs to be unblocked
   * @param object.userId - the user that needs to be unblocked
   */
  unblockMember: ({ channelId, userId }: TChannelInteractionVariables) => {
    return Api.patch(`/channel/${channelId}/member/${userId}/interaction`, {
      action: 'unblock',
    });
  },
  removeChannelParticipant: ({
    channelId,
    userId,
  }: TChannelInteractionVariables) => {
    return Api.delete(`/channel/${channelId}/member/${userId}`);
  },
};

const channelApi = { ...BaseAPI('channel'), ...customApi };

export default channelApi;
