import React from 'react';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';
import ConfirmGuestPlan from '../components/plan/ConfirmGuestPlan';

export default ({ match }: any) => {
  const { slug, userId } = match.params;

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Plan Confirmation"
      mobileHeader={() => null}
      hideHeader
    >
      <SignupLayout>
        <>
          <ConfirmGuestPlan userId={userId} planSlug={slug} />
        </>
      </SignupLayout>
    </Page>
  );
};
