import classNames from 'classnames';
import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import ColorUtil from '../../../utilities/ColorUtil';
import { stripLeft } from '../../../utilities/String';

interface Props {
  form: UseFormMethods;
  name: string;
  defaultValue?: string;
}

const COLORS = [
  '#1F2326',
  '#4E545F',
  '#858F9B',
  '#B63225',
  '#D87059',
  '#DCA7B1',
  '#F1B249',
  '#9CC88D',
  '#4D774F',
  '#8DBCD0',
  '#017CC9',
  '#004E7C',
];

const FormColorPicker = ({ form, name, defaultValue }: Props) => {
  const { register, setValue } = form;
  const { watch } = form;
  const selectedColor = watch(name);
  // Because we display the hex in the input without the # we need to strip it
  const defaultHex = defaultValue ? ColorUtil.formatHex(defaultValue) : null;

  useEffect(() => {
    // Value of 7 because we need to account for the # at the beginning of a hex code

    register(
      { name, type: 'custom' },
      {
        required: 'A valid hex code is required',
        maxLength: {
          value: 7,
          message: 'Must be max of 6 characters',
        },
        minLength: {
          value: 7,
          message: 'Must be min of 6 characters',
        },
      }
    );
  }, [name, register]);

  useEffect(() => {
    // set the default values (only called once)
    setValue(name, defaultHex);
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hexColor = ColorUtil.formatHex(event.target.value);

    setValue(name, hexColor, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleClick = (color: string) => {
    setValue(name, color, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <>
      <div
        className="w-full p-8 flex flex-col justify-center text-center text-white mb-1 rounded-lg+"
        style={{
          backgroundColor: selectedColor,
        }}
      >
        <span>
          This is how text will be shown on this background. Ensure color is
          dark enough to hold white text.
        </span>
      </div>
      <div className="flex flex-wrap -mx-1">
        {COLORS.map(color => {
          return (
            <div className="p-1" key={color}>
              <button
                type="button"
                onClick={() => {
                  handleClick(color);
                }}
                className={classNames(
                  'leading-none rounded-lg overflow-hidden w-12 h-12',
                  {
                    ring: selectedColor === color,
                  }
                )}
                style={{ backgroundColor: color }}
              >
                &nbsp;
              </button>
            </div>
          );
        })}
        <div className="p-1 flex-1">
          <div className="input-group">
            <input
              // id="inputSearch"
              className="form-input"
              type="text"
              name={name}
              placeholder="000000"
              onChange={handleOnChange}
              value={stripLeft(selectedColor, '#')}
              // ref={register({
              //   required: 'A valid hex code is required',
              //   maxLength: {
              //     value: 6,
              //     message: 'Must be max of 6 characters',
              //   },
              //   minLength: {
              //     value: 6,
              //     message: 'Must be min of 6 characters',
              //   },
              // })}
            />
            <div className="input-group__icon">#</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FormColorPicker;
