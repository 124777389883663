import React, { useCallback, forwardRef } from 'react';

import Axios from 'axios';
import FilePond from './FilePond';
import Doka from './Doka';
import { Api } from '../../Api';
import { addError, addResponseError } from '../../services/Messaging';
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_API_KEY } from '../../config';

interface Props {
  aspectRatio?: number | null;
  maxFiles?: number;
  fileDataUrl?: string | null;
  filePondConfig?: object;
  dokaConfig?: object;
  onChange: Function;
  showDropArea?: boolean;
  children?: JSX.Element[] | JSX.Element;
  setLoading?: Function;
}

const ImageUploader = forwardRef(
  (
    {
      aspectRatio = 1,
      maxFiles = 1,
      fileDataUrl = null,
      filePondConfig = {},
      dokaConfig = {},
      onChange,
      showDropArea = false,
      children,
      setLoading,
    }: Props,
    pondRef: any
  ) => {
    const fileRef: any = React.useRef();

    const onpreparefile = useCallback(
      (file: any, output: any) => {
        if (setLoading) {
          setLoading(true);
          // Remove file from filepond and let parent handle loading state/animation
          pondRef.current.removeFile(file.id);
        }

        Api.post('/util/upload-image-signature')
          .then(({ signature, timestamp, folder, colors }) => {
            const formData = new FormData();
            formData.append('api_key', CLOUDINARY_API_KEY);
            formData.append('timestamp', timestamp);
            formData.append('signature', signature);
            formData.append('file', output);
            formData.append('folder', folder);
            formData.append('colors', colors);

            Axios.post(
              `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
              formData
            )
              .then((response: any) => {
                onChange(response.data.secure_url);
              })
              .catch(addResponseError);
          })
          .catch(addResponseError);
      },
      [onChange, setLoading, pondRef]
    );

    const onerror = useCallback(error => {
      addError(`${error.main} - ${error.sub}`);
    }, []);

    React.useLayoutEffect(() => {
      if (fileRef.current && !pondRef.current) {
        const dokaInstance = Doka.createDoka({
          cropAspectRatio: aspectRatio,
          ...dokaConfig,
        });

        const filePondOpts: any = {
          acceptedFileTypes: ['image/*'],
          allowDrop: false,
          maxFiles,
          imageCropAspectRatio: aspectRatio,
          imageEditEditor: dokaInstance,
          onpreparefile,
          onerror,
          allowImagePreview: false,
          ...filePondConfig,
        };

        const pond = FilePond.createFilePond(
          fileRef.current as HTMLElement,
          filePondOpts
        );
        /* eslint-disable-next-line no-param-reassign */
        pondRef.current = pond;
      }
      if (fileDataUrl) {
        pondRef.current.addFile(fileDataUrl);
      }
    }, [
      pondRef,
      fileRef,
      onpreparefile,
      onerror,
      fileDataUrl,
      maxFiles,
      aspectRatio,
      filePondConfig,
      dokaConfig,
    ]);

    return (
      <>
        <input
          className={showDropArea ? 'block' : 'hidden'}
          type="file"
          ref={fileRef}
          data-max-files={maxFiles}
          multiple={maxFiles > 1}
        />
        {children}
      </>
    );
  }
);
export default ImageUploader;
