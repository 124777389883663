export default (url: string) => {
  return {
    id: 'cover',
    label: 'We all judge a book by its cover',
    description:
      'A cover photo will help your profile stand out - use it to show off your personality.',
    icon: 'book',
    action: `#/${url}/basic`,
    actionLabel: 'Add Cover',
    complete: false,
  };
};
