import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useCurrentUser from '../../hooks/useCurrentUser';
import { addResponseError, parseResponseError } from '../../services/Messaging';
import { LoadingRing } from '../core/Loading';
import Api from './Api';
import usePlanBySlug from './hooks/usePlanBySlug';

interface Props {
  userId: string;
  planSlug: string;
}

interface WrappedProps {
  plan: PlanEntity;
  userId: string;
}

const SomethingWentWrong = () => {
  return (
    <div className="text-center">
      <div className="content">
        <h3>Hmmm. Something didn’t work.</h3>
        <p className="text-lg">
          Please contact{' '}
          <a href="mailto:support@communo.com">support@communo.com</a>.
        </p>
      </div>
    </div>
  );
};

const ConfirmGuestPlan = ({ plan, userId }: WrappedProps) => {
  const { id: planId, slug: planSlug } = plan;
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { isAuthenticated, membership } = useCurrentUser();
  const curPlanSlug = membership?.plan.slug;

  const switchPlan = useCallback(async () => {
    try {
      await Api.changePlan({ userId, planId });
    } catch (error) {
      const errorMessage = parseResponseError(error);

      // Not great but needed for now. Backend returns 400 and the below message if the user is already on the plan.
      if (errorMessage !== `User is already on the plan ${planSlug}.`) {
        setHasError(true);
        addResponseError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [planId, planSlug, userId]);

  useEffect(() => {
    if (isAuthenticated && curPlanSlug === planSlug) {
      setIsLoading(false);
      return;
    }

    switchPlan();
  }, [curPlanSlug, isAuthenticated, planSlug, switchPlan]);

  if (isLoading) {
    return (
      <div className="text-center py-20">
        <LoadingRing isActive color="primary" size="lg" />
      </div>
    );
  }

  return (
    <>
      <div className="text-center">
        {!hasError ? (
          <div className="content">
            <h3>Thanks.</h3>
            <p className="text-lg">
              Your account is confirmed as{' '}
              <span className="font-semibold">{plan.name}</span>.
            </p>
          </div>
        ) : (
          <SomethingWentWrong />
        )}

        <Link
          className="btn btn--primary btn--sm w-full max-w-72 mt-16"
          to={isAuthenticated ? '/feed' : '/login'}
        >
          {isAuthenticated ? 'Continue' : 'Login'}
        </Link>
      </div>
    </>
  );
};

const WrappedConfirmGuestPlan = ({ userId, planSlug }: Props) => {
  const { plan, isFetching } = usePlanBySlug(planSlug);

  if (isFetching) {
    return (
      <div className="text-center py-20">
        <LoadingRing isActive color="primary" size="lg" />
      </div>
    );
  }

  return plan ? (
    <ConfirmGuestPlan plan={plan} userId={userId} />
  ) : (
    <SomethingWentWrong />
  );
};
export default WrappedConfirmGuestPlan;
