import React, { useCallback, useMemo } from 'react';
import cloudinary from '../../../utilities/Cloudinary';
import Avatar from '../../avatar/Avatar';
import Image from '../../core/Image';
import Markdown from '../../core/Markdown';
import Modal from '../../core/modal/Modal';
import VideoPlayer from '../../video/VideoPlayer';

type Props = {
  item: PortfolioEntity | null;
  logoURL: string;
  name: string;
  onClose: Function;
};
const PortfolioModal = ({ item, logoURL, name, onClose }: Props) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const itemTitle = item?.title ?? null;

  const header = useMemo(() => {
    return (
      <div className="project-bio">
        <div
          className="flex items-center px-4 md:px-6 py-4"
          style={{ minHeight: '4rem' }}
        >
          <div className="mr-3 md:mr-4">
            <Avatar size="lg" avatarUrl={logoURL} avatarName={name} />
          </div>
          <div className="flex-1">
            {itemTitle ? (
              <div className="text-lg leading-tight font-bold mb-1">
                {itemTitle}
              </div>
            ) : null}
            {name ? (
              <div>
                <span className="text-subdued">by</span>{' '}
                <span className="font-bold">{name}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }, [logoURL, name, itemTitle]);

  return (
    <Modal
      width="4xl"
      paddingX="0"
      showBackButton={false}
      header={header}
      isOpen={!!item}
      onClose={handleClose}
    >
      <div>
        {item ? (
          <>
            {item.videoUrl ? <VideoPlayer videoUrl={item.videoUrl} /> : null}

            <div className="bg-grey-300">
              {item.imageURLs &&
                item.imageURLs.map((image: string) => (
                  <Image
                    key={image}
                    src={cloudinary(image, ['w_1920'])}
                    alt={item.description ?? ''}
                    stretch={false}
                    loadingPlaceholderHeight="h-72"
                  />
                ))}
            </div>

            {item.description && (
              <div className="content p-8">
                <Markdown>{item.description}</Markdown>
              </div>
            )}
          </>
        ) : null}
      </div>
    </Modal>
  );
};
export default PortfolioModal;
