import cn from 'classnames';
import { parse } from 'querystringify';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import useAnalytics from '../../hooks/useAnalytics';
import usePageViews from '../../hooks/usePageViews';
import useWork from '../../hooks/useWork';
import useWorkApplications from '../../hooks/useWorkApplications';
import { isDesktop } from '../../utilities/MatchMedia';
import { LoadingBalls } from '../core/Loading';
import CandidatesHeader from './CandidatesHeader';
import ListWorkApplications from './ListWorkApplications';
import UnreadCandidateMessages from './UnreadCandidateMessages';
import { isMobile } from '../../utilities/Device';

type Props = {
  workId: string;
  status: ApplicationStatus;
};

type WorkInfo = {
  work: WorkEntity | null;
  pageViews: PageView | null;
  applications: KeyedApplications;
};

type Params = {
  applicationId?: string;
  status?: string;
};

const WorkInfo = ({ work, applications }: WorkInfo) => {
  if (!work) return <LoadingBalls isActive fullscreen />;

  return (
    <div className="flex justify-between mt-8 mb-6">
      <CandidatesHeader work={work} />

      <div className="md:block ml-4">
        <div className="flex flex-col flex-1 items-end">
          <UnreadCandidateMessages applications={applications} />
        </div>
      </div>
    </div>
  );
};

const CandidatesPublic = ({ workId, status }: Props) => {
  const { search } = useLocation();
  const work = useWork(workId);
  const { state, patch, hire, reject } = useWorkApplications(workId);
  const applications = state.data;
  const pageViews = usePageViews('work', workId);
  const { applicationId } = parse(search) as Params;
  useAnalytics('Work Candidates', { workId });

  const selected = useMemo(() => state.data[applicationId || ''] || null, [
    applicationId,
    state.data,
  ]);

  return (
    <main className={cn('h-full max-h-full', isMobile ? '' : 'pb-8')}>
      <LoadingBalls isActive={state.loading || work.loading} fullscreen />
      <div className="flex flex-col h-full">
        {isDesktop && (
          <WorkInfo
            work={work.data}
            pageViews={pageViews.data}
            applications={applications}
          />
        )}
        {!state.loading && work.data && (
          <ListWorkApplications
            patchApplications={patch}
            applications={applications}
            hire={hire}
            reject={reject}
            selected={selected}
            work={work.data}
            status={status}
          />
        )}
      </div>
      {/* Display Selected (modal / preview pane) */}
    </main>
  );
};

export default React.memo(CandidatesPublic);
