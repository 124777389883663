import React from 'react';
import { addError } from '../../services/Messaging';
import { LoadingBalls } from '../core/Loading';
import Empty from '../empty/Empty';
import CardEmpty from './CardEmpty';
import ChannelCard from './ChannelCard';
import ChannelQueries from './queries';

type JoinedChannelsCardsProps = {
  onShare?: (id: TCardId) => void;
  onView: (id: TCardId) => void;
  setJoinedFlow?: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: 'all' | 'mine';
  handleTabChange: React.Dispatch<React.SetStateAction<'mine' | 'all'>>;
};

const JoinedChannelsCards = ({
  onView,
  currentTab,
  handleTabChange,
}: JoinedChannelsCardsProps) => {
  const joinedChannels = ChannelQueries.useGetJoinedChannels();

  const navigateToAllChannels = () => handleTabChange('all');

  if (joinedChannels.status === 'loading') {
    return <LoadingBalls isActive />;
  }

  if (joinedChannels.status === 'error') {
    addError('An error occurred while fetching user cards');
    return null;
  }

  if (joinedChannels.data.length === 0) {
    return (
      <Empty
        title="You haven’t joined any channel yet."
        subtitle="This is where you will find them when you do."
        buttonLabel="Browse channels"
        handleClick={navigateToAllChannels}
        name="generosity"
      />
    );
  }

  return (
    <>
      {/*
        Workaround for bug: https://github.com/ionic-team/ionic/issues/18782
        IonSlides causes React to throw a "Failed to execute 'removeChild' on 'Node'" error
        when a slide is removed. Using a key on IonSlides causes it to be re-created as a new instance
      */}
      <div className="w-fit items-center">
        {joinedChannels.data.length > 0 ? (
          <div
            className={`${
              currentTab === 'mine'
                ? 'grid sm:grid-col-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 justify-items-center place-content-center'
                : 'hidden'
            } `}
          >
            {joinedChannels.data.map((card, i) => (
              <ChannelCard
                currentTab={currentTab}
                key={`${card.id}-${i.toString()}`}
                details={card}
                onViewChannel={() => onView(card.id)}
              />
            ))}
          </div>
        ) : (
          <div className="card-empty">
            <CardEmpty isLoading={joinedChannels.isFetching} />
          </div>
        )}{' '}
      </div>
    </>
  );
};

export default JoinedChannelsCards;
