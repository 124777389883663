import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ethnicitiesApi } from '../../../../Api';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { addResponseError } from '../../../../services/Messaging';
import Button from '../../../core/Button';
import { FormCheckbox, FormGroup } from '../../../core/form';
import useModal from '../../../core/modal/hooks/useModal';
import Modal from '../../../core/modal/Modal';
import useStep from '../../../stepper/hooks/useStep';
import Api from '../../../user/Api';

interface Props {
  userId: string;
  refreshUser(): Promise<AuthenticatedUser>;
}

interface CulturalIdentity {
  name: string;
  id: string;
}

const DONT_DISCLOSE_ID = '3d268789-f722-4aed-987a-0f9cb48c4b66';

const descriptionLookup = new Map<string, string>([
  [
    'Indigenous',
    '(ex. Aboriginal Australian, Alaska Native, First Nations, Native American, Native Hawaiian, Samoan)',
  ],
  [
    'Hispanic/Latino/Latinx',
    '(ex. identify as Hispanic or Latino with ties in Latin America, and can be of any race)',
  ],
]);

const Footer = () => {
  const { closeModal } = useModal();

  const handleClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <div className="text-right w-full px-6">
      <Button fill="outline" color="tertiary" size="sm" onClick={handleClick}>
        Close
      </Button>
    </div>
  );
};

const CulturalIdentity = ({ userId, refreshUser }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    setOnNext,
    setCanAdvance,
    isLoading,
    setLoading,
    goToNextStep,
  } = useStep();
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { register, handleSubmit, formState, watch, reset } = form;
  const { isDirty, isValid } = formState;
  const identity: string[] | undefined = watch('culturalIdentity');
  const [identities, setIdentities] = useState<CulturalIdentity[] | null>(null);

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - CulturalIdentity Page');
  }, [track]);

  const areOptionsDisabled = useMemo(() => {
    if (identity?.includes(DONT_DISCLOSE_ID)) {
      return true;
    }

    return false;
  }, [identity]);

  const onSubmit = useCallback(
    async (values: any) => {
      if (isLoading || !userId) {
        return;
      }

      setLoading(true);
      const data = values.culturalIdentity;

      try {
        await Api.associateEthnicities(userId, data);
        await refreshUser();

        setLoading(false);
        goToNextStep();
      } catch (error) {
        setLoading(false);
        addResponseError(error);
      }
    },
    [goToNextStep, isLoading, refreshUser, setLoading, userId]
  );

  const getIdentities = useCallback(() => {
    ethnicitiesApi
      .ethnicities()
      .then(values => {
        setIdentities(values);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    getIdentities();
  }, [getIdentities]);

  useEffect(() => {
    // if "I prefer not to disclose" selected, clear any other values
    if (identity?.includes(DONT_DISCLOSE_ID) && identity.length > 1) {
      reset({
        culturalIdentity: [DONT_DISCLOSE_ID],
      });
    }
  }, [identity, reset]);

  useEffect(() => {
    setOnNext(() => {
      if (isValid && !isDirty) {
        return true;
      }

      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, isDirty, isValid, onSubmit, setOnNext]);

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  return (
    <>
      <div>
        <p className="text-center mb-8">
          Find out more about{' '}
          <button
            type="button"
            onClick={() => {
              setModalOpen(true);
            }}
            className="font-medium text-link"
          >
            why we ask this
          </button>
          .
        </p>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup
            label="Choose all that apply:"
            name="culturalIdentity"
            className="mt-4"
          >
            {identities?.map(value => {
              const desc = descriptionLookup.get(value.name);

              return (
                <FormCheckbox
                  key={value.id}
                  name="culturalIdentity[]"
                  value={value.id}
                  ref={register({
                    required: 'Please select an option',
                  })}
                  disabled={areOptionsDisabled && value.id !== DONT_DISCLOSE_ID}
                >
                  {value.name}{' '}
                  {desc ? <span className="font-normal">{desc}</span> : null}
                </FormCheckbox>
              );
            })}
          </FormGroup>
        </form>
      </div>

      <Modal
        isOpen={modalOpen}
        footer={<Footer />}
        onClose={() => {
          setModalOpen(false);
        }}
        title="Communo Cares"
      >
        <div className="content px-6">
          <svg width="78" height="69" fill="none" className="mx-auto mb-8">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.205 2.31a21.667 21.667 0 0123.617 4.7 21.675 21.675 0 016.35 15.323 21.666 21.666 0 01-6.35 15.324s.001 0 0 0L41.357 67.124a3.333 3.333 0 01-4.714 0L7.175 37.657A21.671 21.671 0 1137.823 7.01l1.176 1.176 1.176-1.176a21.67 21.67 0 017.03-4.7zM55.5 7.328a15 15 0 00-10.609 4.396l-3.534 3.534a3.333 3.333 0 01-4.714 0l-3.533-3.533a15.004 15.004 0 10-21.22 21.219L39 60.053l27.11-27.11a15.003 15.003 0 00-10.61-25.616z"
              fill="#1F2326"
            />
          </svg>

          <p>
            At Communo we are passionate about diversity in the creative
            industries. We collect this information so that we are able to
            identify issues and work with our community to address them. This
            information will never be public, or used as a criteria for hiring
            or filtering searches.
          </p>
        </div>
      </Modal>
    </>
  );
};

const WrappedStep = () => {
  const { user, refresh } = useCurrentUser();

  return (
    <>
      {user && !user.isEthnicitySet ? (
        <CulturalIdentity userId={user.id} refreshUser={refresh} />
      ) : (
        <div>Due to privacy concerns, this step can no longer be edited</div>
      )}
    </>
  );
};

export default WrappedStep;
