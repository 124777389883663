import React, { useContext, useState, useEffect, useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormGroup, Autocomplete } from '../form';
import SearchContext from '../../search/Context';
import RoleUtil, {
  defaultRoleTransformer,
  membershipRoleTransformer,
} from '../../roles/Util';

interface Props
  extends Pick<
    AutocompleteProps,
    | 'form'
    | 'min'
    | 'max'
    | 'defaultValues'
    | 'isMulti'
    | 'isRequired'
    | 'isDisabled'
  > {
  limitRolesTo?: string[];
  upgradePath?: 'numUserRoles' | 'numMembershipRoles';
  labelOverride?: string;
  useMembershipNames?: boolean;
  name?: string;
}

const Roles = ({
  form,
  min,
  max,
  defaultValues,
  isMulti = true,
  isRequired = false,
  isDisabled = false,
  limitRolesTo,
  labelOverride,
  useMembershipNames = false,
  name = 'roles',
}: Props) => {
  const { getRoles } = useContext(SearchContext);
  const { errors }: UseFormMethods = form;
  let label = `${isMulti ? 'Roles' : 'Role'}${
    max && max > 0 ? ` (max ${max})` : ''
  }`;
  const [defaultOptions, setDefaultOptions] = useState<any>([]);
  const roleTransformer = useMembershipNames
    ? membershipRoleTransformer
    : defaultRoleTransformer;

  if (labelOverride) {
    label = labelOverride;
  }

  const getResults = useCallback(
    (q: any) => {
      return getRoles(q).then(({ hits }: any) => {
        if (limitRolesTo) {
          const filteredRoles = hits.filter((role: any) =>
            limitRolesTo.includes(role.name)
          );
          return filteredRoles.map((role: any) => defaultRoleTransformer(role));
        }

        return RoleUtil.groupRoles(hits, roleTransformer);
      });
    },
    [getRoles, limitRolesTo, roleTransformer]
  );

  useEffect(() => {
    getResults({}).then((results: any) => {
      setDefaultOptions(results);
    });
  }, [getResults, limitRolesTo]);

  return (
    <FormGroup required={isRequired} name={name} label={label} errors={errors}>
      <Autocomplete
        name={name}
        form={form}
        min={min}
        max={max}
        defaultValues={defaultValues}
        defaultOptions={defaultOptions}
        callback={getResults}
        isMulti={isMulti}
        isRequired={isRequired}
        isCreatable={false}
        isDisabled={isDisabled}
        placeholder={isMulti ? 'Type to search roles...' : 'Select a role'}
      />
    </FormGroup>
  );
};

export default Roles;
