import { Perk } from '@communo-corp/shared-types';
import { TALENT_PLAN_ID } from '../../config';

const plan: any = {
  id: TALENT_PLAN_ID,
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: new Date(),
  version: 2,
  name: 'Free',
  description: '',
  subType: null,
  isIndividualPlan: true,
  status: 'active',
  type: 'individual',
  isPaid: false,
  numSeats: 1,
  numMembershipRoles: 1,
  canPostEvents: false,
  canPostCommunoWork: false,
  recurlyPlans: [],
};

export const roleData = [
  {
    label: 'Account Services',
    options: [
      {
        value: 'account-manager',
        label: 'Account Manager',
      },
      {
        value: 'business-development',
        label: 'Business Development',
      },
      {
        value: 'client-services',
        label: 'Client Services',
      },
    ],
  },
  {
    label: 'Content',
    options: [
      { value: 'audio', label: 'Audio' },
      { value: 'video', label: 'Video' },
    ],
  },
];

export const locationData = [{ value: 'vancouver-bc', label: 'Vancouver BC' }];

export const eventData = {
  id: 'b0e2d1712b384890a32d8c56c8650135',
  membership: {
    id: 'babb9be52e224d779aedbcc70f2009e4',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: new Date(),
    version: 1,
    name: 'Cult Collective',
    recurlyAccountCode: null,
    coverPhotoURL: null,
    slug: null,
    imageURLs: ['string'],
    logoURL: 'https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo',
    numMembers: 2,
    about: 'string',
    summary: 'string',
    videoUrl: null,
    size: 2,
    vettingStatus: null,
    numConciergePosts: 0,
    numSinglePosts: 0,
    location: {
      latitude: null,
      longitude: null,
      friendlyName: null,
    },
    socialLinks: {
      WebsiteURL: null,
      FacebookURL: null,
      TwitterURL: null,
      InstagramURL: null,
      LinkedInURL: null,
      VimeoURL: null,
      BehanceURL: null,
    },
    plan,
    portfolioItems: [],
    roles: [],
    skills: [],
    industries: [],
    getId: () => {
      return { name: 'string' };
    },
    expiresAt: new Date(),
  },
  deletedAt: new Date(),
  imageURL: 'https://source.unsplash.com/random/800x600',
  expiresAt: new Date(),
  name: 'The Gathering: An Exclusive Union of the World’s Bravest Brands',
  type: 'Conference',
  price: 1499,
  externalURL: null,
  location: {
    latitude: null,
    longitude: null,
    friendlyName: 'Banff, AB',
    rawJSON: null,
  },
  category: {
    id: 'e7a52e138f4b469fa3f5c2d5cdffd299',
    createdAt: '2020-02-11T23:52:42.597Z',
    updatedAt: '2020-02-11T23:52:42.597Z',
    deletedAt: new Date(),
    version: 1,
    name: 'Marketing & Communications',
  },
  createdAt: new Date(),
  updatedAt: new Date(),
  version: 1,
};

export const perkData: Perk = {
  id: 'e7a52e138f4b469fa3f5c2d5cdffd299',
  name: 'sint',
  descriptionSummary: 'architecto eaque excepturi',
  description: 'corrupti et modi',
  offerSummary: 'sequi velit consequatur',
  offer: 'ipsum laboriosam consequatur',
  offerDetails: 'ipsum laboriosam consequatur',
  about: 'Something something about a membership',
  redeemUrl: 'https://google.ca',
  horizontalHeaderImageUrl: 'https://source.unsplash.com/random/800x600',
  verticalHeaderImageUrl: 'https://source.unsplash.com/random/800x600',
  featuredLogoImageUrl: 'https://source.unsplash.com/random/800x600',
  assetImageUrls: [],
  assetVideoUrls: [],
  usesLightTheme: false,
  order: 1.0,
  visibleToAgencies: false,
  visibleToFreelancers: false,
  isPremium: false,
  isFeatured: false,
  isActive: false,
  isBundleOnly: false,
  isNew: false,
  expiresAt: null,
  createdAt: 1594345430905,
  categories: [
    {
      id: '357e69e6-c608-470d-adda-d09feb7a0883',
      name: 'ut',
    },
  ],
  countries: [
    {
      isoCode: 'CA',
      name: 'Canada',
    },
  ],
};

export const workData = {
  id: 'e142fcfff67e42e1b6f190cf6b8af44c',
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: new Date(),
  notificationSentAt: null,
  version: 1,
  title: 'Work Post Title, may be longer than one line',
  type: 'Project Hire',
  description: 'string',
  expiresAt: new Date(),
  distanceInKM: null,
  isRemote: false,
  price: 5000,
  isExternal: null,
  externalURL: null,
  logoURL: 'https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo',
  companyName: null,
  workStart: null,
  duration: null,
  budgetType: null,
  hoursPerWeek: null,
  hourlyRate: null,
  redactionLevel: 0,
  isOneClickApply: false,
  magicPublicJwt: null,
  location: {
    latitude: null,
    longitude: null,
    friendlyName: 'Calgary, AB',
  },
  role: {
    name: 'Role',
    nameForMembership: 'Role Cat',
    category: { name: 'Category' },
  },
  skills: [],
  industries: [],
  requirements: 'There are the requirements',
  audience: 'both',
  collections: [],
  isCampaign: false,
  gatingRequirements: [],
  user: {
    id: '7c97676c80314db8a846dfc8b3d60137',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: new Date(),
    version: 2,
    firstName: 'Isaias',
    lastName: 'Gerlach',
    email: 'Mervin.Bailey@mailinator.com',
    recurlyAccountCode: '7c97676c80314db8a846dfc8b3d60137',
    avatarURL: 'https://i.pravatar.cc/200?img=34',
    summary: '',
    nylasAccessTokenExists: false,
    about: '',
    videoUrl: null,
    location: {
      latitude: null,
      longitude: null,
      friendlyName: null,
    },
    socialLinks: {
      WebsiteURL: null,
      FacebookURL: null,
      TwitterURL: null,
      InstagramURL: null,
      LinkedInURL: null,
      VimeoURL: null,
      BehanceURL: null,
    },
    settings: {
      isChatEmailDisabled: false,
      isWorkEmailDisabled: false,
    },
    oauthProviders: [],
    roles: [],
    skills: [],
    industries: [],
    schools: [],
    collections: [],
    collectionSummary: {
      numLists: 1,
      numBenches: 1,
      badges: null,
    },
    portfolioItems: [],
    rate: null,
    timezone: null,
    references: [],
    isPartialProfile: false,
  },
  membership: {
    id: 'babb9be52e224d779aedbcc70f2009e4',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: new Date(),
    version: 1,
    name: 'Agency Name',
    recurlyAccountCode: null,
    coverPhotoURL: null,
    slug: null,
    imageURLs: ['string'],
    logoURL: 'https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo',
    numMembers: 2,
    about: 'string',
    summary: 'string',
    videoUrl: null,
    size: 2,
    vettingStatus: null,
    numConciergePosts: 0,
    numSinglePosts: 0,
    location: {
      latitude: null,
      longitude: null,
      friendlyName: null,
    },
    socialLinks: {
      WebsiteURL: null,
      FacebookURL: null,
      TwitterURL: null,
      InstagramURL: null,
      LinkedInURL: null,
      VimeoURL: null,
      BehanceURL: null,
    },
    plan,
    portfolioItems: [],
    roles: [],
    skills: [],
    industries: [],
    getId: () => {
      return { name: 'string' };
    },
    expiresAt: new Date(),
  },
} as WorkEntity;
