import React from 'react';
import { useHistory } from 'react-router-dom';
import Link from '../components/core/router/Link';
import useAnalytics from '../hooks/useAnalytics';
import View, {
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import useChatClient from '../components/chat/hooks/useChatClient';
import { LoadingBalls } from '../components/core/Loading';
import Channels from '../components/chat/components/Channels';

const NewMessageLink = () => (
  <Link to="/chat/new-message" className="btn btn--circle btn--primary">
    <i className="i-edit" />
  </Link>
);

export default () => {
  const { loaded } = useChatClient();
  const history = useHistory();

  useAnalytics('Conversations List');

  if (isDesktop)
    return (
      <>
        <ViewHeader onBackButtonClick={history.goBack} end={<NewMessageLink />}>
          <ViewHeaderTitle large>Messages</ViewHeaderTitle>
        </ViewHeader>
        <ViewContent layout="chatlist">
          <Channels />
        </ViewContent>
      </>
    );

  return (
    <View authGuard darkStatusBar pageTitle="Chat">
      {!loaded ? (
        <LoadingBalls isActive fullscreen />
      ) : (
        <>
          <ViewHeader defaultBackHref="/feed" end={<NewMessageLink />}>
            <ViewHeaderTitle large>Messages</ViewHeaderTitle>
          </ViewHeader>

          <ViewContent layout="chatlist">
            <Channels />
          </ViewContent>
        </>
      )}
    </View>
  );
};
