import { BaseAPI, Api } from '../../Api';

interface BatchData {
  [key: string]: {
    status: ApplicationStatus | null;
    position: number;
  };
}

const customApi = {
  myWork: () => Api.get(`/work/mine`),
  myApplications: () => Api.get(`/application/mine`),
  applications: (workId: string) => Api.get(`/work/${workId}/application`),
  publicApplications: (workId: string) =>
    Api.get(`/work/${workId}/application/public`),
  createPublicUrl: (workId: string) =>
    Api.post(`/work/${workId}/application/public-url`),
  deletePublicUrl: (workId: string) =>
    Api.delete(`/work/${workId}/application/public-url`),
  myApplication: (workId: string) =>
    Api.get(`/work/${workId}/application/qualification`),
  patchApplication: (workId: string, applicationId: string, data: {}) =>
    Api.patch(`/work/${workId}/application/${applicationId}`, data),
  patchApplications: (workId: string, data: BatchData) =>
    Api.patch(`/work/${workId}/application`, data),
  assignRecruiter: (workId: string, data: { recruiterId: string }) =>
    Api.patch(`/work/${workId}`, data),
  apply: (workId: string, data: { body: string }) =>
    Api.post(`/work/${workId}/application`, data),
  invite: (workId: string, data: { userId: string }) =>
    Api.post(`/work/${workId}/application/invite`, data),
  createChat: (workId: string, applicationId: string) =>
    Api.post(`/work/${workId}/application/${applicationId}/chat`),
  joinChat: (workId: string, applicationId: string) =>
    Api.post(`/work/${workId}/application/${applicationId}/chat/join`),
  chatMembers: (workId: string, applicationId: string) =>
    Api.get(`/work/${workId}/application/${applicationId}/chat/members`),
  hire: (workId: string, applicationId: string) =>
    Api.post(`/work/${workId}/application/${applicationId}/hire`, {}),
  createApplicationNote: (
    workId: string,
    applicationId: string,
    data: {
      userId: string;
      note: string;
      rating: number;
      name: string;
      commenterUserId: string | null;
    }
  ) => Api.post(`/work/${workId}/application/${applicationId}/note`, data),
  getApplicationNotes: (
    workId: string,
    applicationId: string
  ): Promise<ApplicationNoteEntity[]> =>
    Api.get(`/work/${workId}/application/${applicationId}/note`),
  getSuggestedCandidates: (workId: string, queryString: string) =>
    Api.get(`/work/${workId}/suggested${queryString}`),
};

export default { ...BaseAPI('work'), ...customApi };
