import React from 'react';
import { addInfo } from '../../services/Messaging';
import Button from '../core/Button';

type ShareShortlistAccessUrl = {
  publicUrl: string;
  isPublicUrlExpired: boolean;
};

const ShareShortlistAccessUrl = ({
  publicUrl,
  isPublicUrlExpired,
}: ShareShortlistAccessUrl) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(publicUrl);
    addInfo('Shortlist link copied to clipboard.');
  };

  return isPublicUrlExpired ? (
    <>
      {/* {    // <Button color="tertiary" className="m-1 w-56" disabled>
    //   <span>
    //     Link expired&nbsp;
    //     <i className="i-alert-warning btn__icon" />
    //   </span>
    // </Button>} */}
    </>
  ) : (
    <Button onClick={copyToClipboard} color="secondary" className="m-1 w-56">
      <span className="btn__text">
        <i className="i-link btn__icon" />
        &nbsp;Share Shortlist
      </span>
    </Button>
  );
};

export default ShareShortlistAccessUrl;
