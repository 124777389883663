export default (url: string) => {
  return {
    id: 'industries',
    label: 'List the industries you’ve worked in',
    description:
      'Increase your chances of being found by highlighting your industry experience.',
    icon: 'scroll',
    action: `#/${url}/roles`,
    actionLabel: 'Add Industries',
    complete: false,
  };
};
