/* eslint-disable no-console */
import React from 'react';
import { IonRange } from '@ionic/react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import style from '../components/styleguide/styleguide.module.css';
import Page from '../components/core/Page';
import {
  roleData,
  locationData,
  eventData,
  // perkData,
  workData,
} from '../components/styleguide/SampleData';
import Avatar from '../components/avatar/Avatar';
import VettedBadge from '../components/badge/VettedBadge';
import OrganizationCard from '../components/card/OrganizationCard';
import EventCard from '../components/card/EventCard';
// import PerkCard from '../components/card/PerkCard';
import WorkCard from '../components/card/WorkCard';
import Hero from '../components/styleguide/Hero';
import Link from '../components/core/router/Link';
import Accordion from '../components/accordion/Accordion';

const Input = ({ onChange, onBlur, onFocus, value }: any) => {
  return (
    <input
      type="text"
      className="px-4 border-none focus:outline-none flex-1"
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
    />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <i className="i-caret-down" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...restInnerProps} ref={ref}>
      <div className="multi__clear mr-4">
        <i className="i-close" />
      </div>
    </div>
  );
};

export default () => {
  return (
    <Page>
      <div className="page-container">
        <Hero title="WIP" />

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Accordion</h5>
          <div className="page-section__container">
            <Accordion title="Accordion Title Here">
              <h1>Lorem Ipsum</h1>

              <p>
                <strong>Pellentesque habitant morbi tristique</strong> senectus
                et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                Donec eu libero sit amet quam egestas semper.{' '}
                <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                leo. Quisque sit amet est et sapien ullamcorper pharetra.
                Vestibulum erat wisi, condimentum sed,{' '}
                <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                orci, sagittis tempus lacus enim ac dui. Donec non enim in
                turpis pulvinar facilisis. Ut felis.
              </p>

              <h2>Header Level 2</h2>

              <ol>
                <li>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li>Aliquam tincidunt mauris eu risus.</li>
              </ol>

              <blockquote>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                  eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                  libero at sagittis mollis, tellus est malesuada tellus, at
                  luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                </p>
              </blockquote>

              <h3>Header Level 3</h3>

              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li>Aliquam tincidunt mauris eu risus.</li>
              </ul>
            </Accordion>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Role Picker</h5>
          <div className="page-section__container">
            <Link to="/styleguide/role-picker">See: Role Picker</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Recommendation</h5>
          <div className="page-section__container">
            <Link to="/styleguide/recommendation">See: Recommendation</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Range Sliders</h5>
          <div className="page-section__container">
            <Link to="/styleguide/range">See: Range Sliders</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Modals</h5>
          <div className="page-section__container">
            <Link to="/styleguide/modals">See: Modals</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Discover</h5>
          <div className="page-section__container">
            <Link to="/styleguide/discover">See: Discover</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Channels</h5>
          <div className="page-section__container">
            <Link to="/styleguide/channels">See: Channels</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Chat</h5>
          <div className="page-section__container">
            <Link to="/styleguide/chat">See: Chat</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Notice</h5>
          <div className="page-section__container">
            <Link to="/styleguide/notices">See: Notice</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Breadcrumbs</h5>
          <div className="page-section__container">
            <Link to="/styleguide/breadcrumbs">See: Breadcrumbs</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Blobs</h5>
          <div className="page-section__container">
            <Link to="/styleguide/blobs">See: Blobs</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Portfolio Card</h5>
          <div className="page-section__container">
            <div className="row-flex">
              <div className="col w-1/3">
                <div className="group relative aspect-video bg-grey-500 rounded-lg+ overflow-hidden cursor-pointer">
                  <img
                    className="absolute bottom-0 right-0 w-full h-full object-cover transform origin-center transition duration-500 group-hover:scale-105"
                    src="http://placeimg.com/640/480/any"
                    alt=""
                  />
                </div>
                <p className="font-medium mt-4">A title here</p>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Filters</h5>
          <div className="page-section__container">
            <div className={`mt-6 ${style.iPhoneWindow} overflow-y-scroll p-6`}>
              <div className="filter-group">
                <h5 className="filter-group__title">Type of Member</h5>
                <div className="filter-group__item">
                  People
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterMember[]"
                      type="checkbox"
                      value="memberPeople"
                    />
                  </label>
                </div>
                <div className="filter-group__item">
                  Agency
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterAgency[]"
                      type="checkbox"
                      value="filterAgency"
                    />
                  </label>
                </div>
                <div className="filter-group__item">
                  Partner
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterPartner[]"
                      type="checkbox"
                      value="filterPartner"
                    />
                  </label>
                </div>
              </div>
              <div className="filter-group">
                <h5 className="filter-group__title">Type of Member</h5>
                <div className="filter-group__item justify-start">
                  <div className="radio-filter mr-2">
                    <input
                      type="radio"
                      name="typeFilter[]"
                      id="typeFilterOne"
                      value="one"
                      defaultChecked
                    />
                    <label htmlFor="typeFilterOne">All People</label>
                  </div>
                  <div className="radio-filter">
                    <input
                      type="radio"
                      name="typeFilter[]"
                      id="typeFilterTwo"
                      value="filtwoterTwo"
                    />
                    <label htmlFor="typeFilterTwo">Only Talent members</label>
                  </div>
                </div>
              </div>
              <div className="filter-group">
                <h5 className="filter-group__title">Size of Agency</h5>
                <div className="filter-group__item">
                  Small (1-10)
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterMember[]"
                      type="checkbox"
                      value="memberPeople"
                    />
                  </label>
                </div>
                <div className="filter-group__item">
                  Medium (11-50)
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterAgency[]"
                      type="checkbox"
                      value="filterAgency"
                    />
                  </label>
                </div>
                <div className="filter-group__item">
                  Large (51-100)
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterPartner[]"
                      type="checkbox"
                      value="filterPartner"
                    />
                  </label>
                </div>
                <div className="filter-group__item">
                  Enterprise (101+)
                  <label htmlFor="stylizedOne" className="checkbox-stylized">
                    <input
                      id="memberPeople"
                      name="filterPartner[]"
                      type="checkbox"
                      value="filterPartner"
                    />
                  </label>
                </div>
              </div>
              <div className="filter-group">
                <h5 className="filter-group__title">Role</h5>
                <div className="filter-group__item">
                  <CreatableSelect
                    isClearable
                    placeholder="Select..."
                    options={roleData}
                    className="multiselect"
                    classNamePrefix="multi"
                    components={{
                      Input,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                  />
                </div>
              </div>
              <div className="filter-group">
                <h5 className="filter-group__title">Location</h5>
                <div className="filter-group__item">
                  <CreatableSelect
                    isClearable
                    placeholder="Select..."
                    options={locationData}
                    className="multiselect"
                    classNamePrefix="multi"
                    components={{
                      Input,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                  />
                </div>
                <div className="filter-group__item">
                  <div className="w-full">
                    <div>30</div>
                    <div className="text-sm text-subdued">Search Radius</div>
                    <IonRange
                      min={0}
                      max={100}
                      step={10}
                      snaps
                      ticks={false}
                      pin={false}
                      color="communo-red"
                      value={30}
                      mode="ios"
                      onIonChange={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div className="py-5">
                <button type="button" className="btn btn--primary btn--full">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section my-md">
          <h5 className={style.sectionHeader}>React Select</h5>
          <div className="page-section__container">
            <div className="row-flex">
              <div className="col w-1/2">
                <div className="form-group">
                  <h5>Single</h5>
                  <CreatableSelect
                    isClearable
                    placeholder="Select..."
                    options={roleData}
                    className="multiselect"
                    classNamePrefix="multi"
                    components={{
                      Input,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                  />
                </div>
              </div>
              <div className="col w-1/2">
                <div className="form-group">
                  <h5>Multi</h5>
                  <p className="text-sm text-alert-negative">
                    <i className="i-alert-warning" /> These are not the final
                    styles. Please ask Justin if you need these flushed out.
                  </p>
                  <CreatableSelect
                    isClearable
                    isMulti
                    placeholder="Select..."
                    options={roleData}
                    className="multiselect"
                    classNamePrefix="multi"
                    components={{
                      Input,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Work Cards</h5>
          <div className="page-section__container">
            <div className="md:row-flex">
              <div className="col md:w-1/3">
                <p>
                  cardStyle = <code>limited</code>
                </p>
                <WorkCard work={workData} />
              </div>
              <div className="col md:w-2/3">
                <p>
                  cardStyle = <code>full</code>
                </p>
                <div className="flex">
                  <WorkCard
                    work={workData}
                    cardStyle="full"
                    className="w-1/2"
                  />
                  <WorkCard
                    work={{ ...workData, title: 'short title' }}
                    cardStyle="full"
                    className="w-1/2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Event Cards</h5>
          <div className="page-section__container">
            <div className="md:row-flex">
              <div className="col md:w-1/3">
                <p>
                  cardStyle = <code>limited</code>
                </p>
                <EventCard event={eventData} />
              </div>
              <div className="col md:w-2/3">
                <p>
                  cardStyle = <code>full</code>
                </p>
                <div className="flex">
                  <EventCard
                    event={eventData}
                    cardStyle="full"
                    className="w-1/2"
                  />
                  <EventCard
                    event={{ ...eventData, name: 'short title' }}
                    cardStyle="full"
                    className="w-1/2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Perk Cards</h5>
          <div className="page-section__container">
            <div className="md:row-flex">
              <div className="col md:w-1/3">
                <p>TODO</p>
                {/* <PerkCard
                  name={perkData.name}
                  company={perkData.membership.name}
                  offer={perkData.offerSummary}
                  logoURL={perkData.membership.logoURL}
                  imageURL={perkData.horizontalHeaderImageUrl}
                  description={perkData.descriptionSummary}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Member Cards</h5>
          <div className="page-section__container">
            <Link to="/styleguide/member-cards">See: Member Cards</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Organization Cards</h5>
          <div className="page-section__container">
            <div className="md:row-flex">
              <div className="col md:w-1/4">
                <OrganizationCard
                  logoURL="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  orgName="Quarter Section Creative"
                  label="Graphic Designer"
                />
              </div>
              <div className="col md:w-1/4">
                <OrganizationCard
                  logoURL="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  orgName="Quarter Section Creative"
                  label="Graphic Designer"
                  vetted
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>SVG Icons</h5>
          <div className="page-section__container">
            <Link to="/styleguide/svg-icons">See: SVG Icons</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Icon Font</h5>
          <div className="page-section__container">
            <h5 className="mb-6">Icon Circles</h5>

            <span className="ml-2 mb-2">
              <div className="icon-circle">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--primary">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--secondary">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--tertiary">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--outline">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--primary icon--outline">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--secondary icon--outline">
                <i className="i-users" />
              </div>
            </span>

            <span className="ml-2 mb-2">
              <div className="icon-circle icon--tertiary icon--outline">
                <i className="i-users" />
              </div>
            </span>

            <h5 className="mt-6">Icons</h5>
            <p className="text-sm mb-6">
              Use with the <code>{`<i className="i-add" />`}</code> element and
              give it the appropriate class or by copying the content code{' '}
              <code>
                font-family: &ldquo;icomoon&rdquo;; content: &ldquo;\e900&rdquo;
              </code>
              . There is also a utility class <code>.font-icon</code> if you
              need it.
            </p>
            <div className="flex flex-wrap">
              <div className={style.styleguideIconBucket}>
                <i className="i-add" />
                <span className={style.styleguideIconLabel}>i-add - e900</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-remove" />
                <span className={style.styleguideIconLabel}>
                  i-remove - e901
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-arrow-left" />
                <span className={style.styleguideIconLabel}>
                  i-arrow-left - e902
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-arrow-right" />
                <span className={style.styleguideIconLabel}>
                  i-arrow-right - e903
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-caret-up" />
                <span className={style.styleguideIconLabel}>
                  i-caret-up - e904
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-caret-right" />
                <span className={style.styleguideIconLabel}>
                  i-caret-right - e905
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-caret-down" />
                <span className={style.styleguideIconLabel}>
                  {' '}
                  i-caret-down - e906
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-caret-left" />
                <span className={style.styleguideIconLabel}>
                  i-caret-left - e907
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-error" />
                <span className={style.styleguideIconLabel}>
                  i-alert-error - e908
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-positive" />
                <span className={style.styleguideIconLabel}>
                  i-alert-positive - e909
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-warning" />
                <span className={style.styleguideIconLabel}>
                  i-alert-warning - e90a
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-grid-view" />
                <span className={style.styleguideIconLabel}>
                  i-grid-view - e90b
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-search" />
                <span className={style.styleguideIconLabel}>
                  i-search - e90c
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-calendar" />
                <span className={style.styleguideIconLabel}>
                  i-calendar - e90d
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-users" />
                <span className={style.styleguideIconLabel}>
                  i-users - e90e
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-user" />
                <span className={style.styleguideIconLabel}>i-user - e90f</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-check" />
                <span className={style.styleguideIconLabel}>
                  i-check - e910
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-close" />
                <span className={style.styleguideIconLabel}>
                  i-close - e911
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-circle" />
                <span className={style.styleguideIconLabel}>
                  i-circle - e912
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-home" />
                <span className={style.styleguideIconLabel}>i-home - e913</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-upload" />
                <span className={style.styleguideIconLabel}>
                  i-upload - e914
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-work" />
                <span className={style.styleguideIconLabel}>i-work - e915</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-edit" />
                <span className={style.styleguideIconLabel}>i-edit - e916</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-pencil" />
                <span className={style.styleguideIconLabel}>
                  i-pencil - e917
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-notification" />
                <span className={style.styleguideIconLabel}>
                  i-notification - e918
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-chat" />
                <span className={style.styleguideIconLabel}>i-chat - e919</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-web" />
                <span className={style.styleguideIconLabel}>i-web - e91a</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-instagram" />
                <span className={style.styleguideIconLabel}>
                  i-instagram - e91b
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-twitter" />
                <span className={style.styleguideIconLabel}>
                  i-twitter - e91c
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-badge" />
                <span className={style.styleguideIconLabel}>
                  i-badge - e91d
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-check-bold" />
                <span className={style.styleguideIconLabel}>
                  i-check-bold - e91e
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-filters" />
                <span className={style.styleguideIconLabel}>
                  i-filters - e91f
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-mail" />
                <span className={style.styleguideIconLabel}>i-mail - e920</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-lock" />
                <span className={style.styleguideIconLabel}>i-lock - e921</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-marker" />
                <span className={style.styleguideIconLabel}>
                  i-marker - e922
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-grid" />
                <span className={style.styleguideIconLabel}>i-grid - e923</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-grid-sm" />
                <span className={style.styleguideIconLabel}>
                  i-grid-sm - e924
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-facebook" />
                <span className={style.styleguideIconLabel}>
                  i-facebook - e925
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-linkedin" />
                <span className={style.styleguideIconLabel}>
                  i-linkedin - e926
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-vimeo" />
                <span className={style.styleguideIconLabel}>
                  i-vimeo - e927
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-youtube" />
                <span className={style.styleguideIconLabel}>
                  i-youtube - e928
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-behance" />
                <span className={style.styleguideIconLabel}>
                  i-behance - e929
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-trash" />
                <span className={style.styleguideIconLabel}>
                  i-trash - e92a
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-settings" />
                <span className={style.styleguideIconLabel}>
                  i-settings - e92b
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-hamburger" />
                <span className={style.styleguideIconLabel}>
                  i-hamburger - e92c
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-avatar" />
                <span className={style.styleguideIconLabel}>
                  i-avatar - e92d
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-megaphone" />
                <span className={style.styleguideIconLabel}>
                  i-megaphone - e92e
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-speaker" />
                <span className={style.styleguideIconLabel}>
                  i-speaker - e92f
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-book" />
                <span className={style.styleguideIconLabel}>i-book - e930</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-image" />
                <span className={style.styleguideIconLabel}>
                  i-image - e931
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-link" />
                <span className={style.styleguideIconLabel}>i-link - e932</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-b" />
                <span className={style.styleguideIconLabel}>i-b - e933</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-scroll" />
                <span className={style.styleguideIconLabel}>
                  i-scroll - e934
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-lightening" />
                <span className={style.styleguideIconLabel}>
                  i-lightening - e935
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-heart" />
                <span className={style.styleguideIconLabel}>
                  i-heart - e936
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-handshake" />
                <span className={style.styleguideIconLabel}>
                  i-handshake - e937
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-plane" />
                <span className={style.styleguideIconLabel}>
                  i-plane - e938
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-info" />
                <span className={style.styleguideIconLabel}>i-info - e939</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-info-circle" />
                <span className={style.styleguideIconLabel}>
                  i-info-circle - e93a
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-plane-outline" />
                <span className={style.styleguideIconLabel}>
                  i-plane-outline - e93b
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-back" />
                <span className={style.styleguideIconLabel}>i-back - e93c</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-box" />
                <span className={style.styleguideIconLabel}>i-box - e93d</span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-leave" />
                <span className={style.styleguideIconLabel}>
                  i-leave - e93e
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-mute-notification" />
                <span className={style.styleguideIconLabel}>
                  i-mute-notification - e93f
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-share" />
                <span className={style.styleguideIconLabel}>
                  i-share - e940
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-remove" />
                <span className={style.styleguideIconLabel}>
                  i-alert-remove - e941
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-add" />
                <span className={style.styleguideIconLabel}>
                  i-alert-add - e942
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-alert-info" />
                <span className={style.styleguideIconLabel}>
                  i-alert-info - e943
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-reply" />
                <span className={style.styleguideIconLabel}>
                  i-reply - e944
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-camera" />
                <span className={style.styleguideIconLabel}>
                  i-camera - e945
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-camera-off" />
                <span className={style.styleguideIconLabel}>
                  i-camera-off - e946
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-video-camera" />
                <span className={style.styleguideIconLabel}>
                  i-video-camera - e947
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-video-camera-off" />
                <span className={style.styleguideIconLabel}>
                  i-video-camera-off - e948
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-microphone" />
                <span className={style.styleguideIconLabel}>
                  i-microphone - e949
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-microphone-off" />
                <span className={style.styleguideIconLabel}>
                  i-microphone-off - e94a
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-sound" />
                <span className={style.styleguideIconLabel}>
                  i-sound - e94e
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-sound-off" />
                <span className={style.styleguideIconLabel}>
                  i-sound-off - e94f
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-phone" />
                <span className={style.styleguideIconLabel}>
                  i-phone - e950
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-phone-off" />
                <span className={style.styleguideIconLabel}>
                  i-phone-off - e951
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-visible" />
                <span className={style.styleguideIconLabel}>
                  i-visible - e952
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-visible-off" />
                <span className={style.styleguideIconLabel}>
                  i-visible-off - e953
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-user-add" />
                <span className={style.styleguideIconLabel}>
                  i-user-add - e954
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-more-vert" />
                <span className={style.styleguideIconLabel}>
                  i-more-vert - e94b
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-more-horiz" />
                <span className={style.styleguideIconLabel}>
                  i-more-horiz - e94c
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-shield-fill" />
                <span className={style.styleguideIconLabel}>
                  i-shield-fill - e94d
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-shield-outline" />
                <span className={style.styleguideIconLabel}>
                  i-shield-outline - e955
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-link-external" />
                <span className={style.styleguideIconLabel}>
                  i-link-external - e956
                </span>
              </div>
              <div className={style.styleguideIconBucket}>
                <i className="i-star-solid" />
                <span className={style.styleguideIconLabel}>
                  i-star-solid - e957
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Badges</h5>
          <div className="page-section__container">
            <h5>Notification</h5>
            <p>
              Easy peasy, just give an div or span element a class of{' '}
              <code>.badge</code>
            </p>
            <div>
              <div className="badge">23</div>
            </div>

            <h5 className="mt-6">Vetted</h5>
            <div className="lg:row-flex mt-6">
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>sm</code>
                </p>
                <VettedBadge size="sm" />
                <p>2rem</p>
              </div>
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>md</code>
                </p>
                <VettedBadge size="md" />
                <p>2.5rem</p>
              </div>
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>lg</code>
                </p>
                <VettedBadge size="lg" />
                <p>3rem</p>
              </div>
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>xl</code>
                </p>
                <VettedBadge size="xl" />
                <p>5rem</p>
              </div>
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>2xl</code>
                </p>
                <VettedBadge size="2xl" />
                <p>8rem</p>
              </div>
              <div className="col lg:w-1/6 text-center">
                <p>
                  size = <code>3xl</code>
                </p>
                <VettedBadge size="3xl" />
                <p>10rem</p>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Aspect Ratio</h5>
          <div className="page-section__container">
            <div className="row-flex">
              <div className="col w-1/4 my-4">
                <code>.aspect-square</code>
                <div className="aspect-square bg-grey-100" />
              </div>
              <div className="col w-1/4 my-4">
                <code>.aspect-video</code>
                <div className="aspect-video bg-grey-100" />
              </div>
              <div className="col w-1/4 my-4">
                <code>.aspect-2/1</code>
                <div className="aspect-2/1 bg-grey-100" />
              </div>
              <div className="col w-1/4 my-4">
                <code>.aspect-4/3</code>
                <div className="aspect-4/3 bg-grey-100" />
              </div>
            </div>
            <div className="row-flex">
              <div className="col w-1/4 my-4">
                <code>.aspect-21/9</code>
                <div className="aspect-21/9 bg-grey-100" />
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Avatars</h5>
          <div className="page-section__container">
            <h5>Avatars w/ Cropped Images</h5>
            <p>
              Keep in mind that the <code>.avatar</code> class is the base
              class. All the variants are also responsive utility classes.
              Meaning that you can use the responsive variants if you need to
              change the avatar size at different breakpoints. For example, all
              of these classes exist for the <code>.avatar--md</code> variant -{' '}
              <code>.avatar--md</code>, <code>.xs:avatar--md</code>,{' '}
              <code>.sm:avatar--md</code>, <code>.md:avatar--md</code>,{' '}
              <code>.lg:avatar--md</code>, <code>.xl:avatar--md</code>,{' '}
              <code>.2xl:avatar--md</code>
            </p>
            <div className="lg:row-flex mt-6">
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--sm</code>
                </p>
                <Avatar
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="Default Avatar"
                />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--md</code>
                </p>
                <Avatar
                  size="md"
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="Medium Avatar"
                />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--lg</code>
                </p>
                <Avatar
                  size="lg"
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="Large Avatar"
                />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--xl</code>
                </p>
                <Avatar
                  size="xl"
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="XL Avatar"
                />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--2xl</code>
                </p>
                <Avatar
                  size="2xl"
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="2XL Avatar"
                />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--3xl</code>
                </p>
                <Avatar
                  size="3xl"
                  avatarUrl="https://i.pravatar.cc/200?img=34"
                  avatarName="3XL Avatar"
                />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>

            <h5>Square Avatars</h5>
            <p>
              <code>.avatar .avatar--square</code>
            </p>
            <div className="lg:row-flex mb-6">
              <div className="col lg:w-1/6">
                <div className="avatar avatar--sm avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
              </div>
              <div className="col lg:w-1/6">
                <div className="avatar avatar--md avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
              </div>
              <div className="col lg:w-1/6">
                <div className="avatar avatar--lg avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
                <div className="avatar avatar--lg avatar--square">
                  <span className="avatar__initials">JM</span>
                </div>
              </div>
              <div className="col lg:w-1/6">
                <div className="avatar avatar--xl avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
              </div>
              <div className="col lg:w-1/6">
                <div className="avatar avatar--2xl avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
              </div>
              <div className="col lg:w-1/6">
                <div className="avatar avatar--3xl avatar--square">
                  <img src="https://via.placeholder.com/150" alt="" />
                </div>
              </div>
            </div>

            <h5>Avatars w/ Initials</h5>
            <div className="lg:row-flex">
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--sm</code>
                </p>
                <Avatar avatarName="Justin Martin" />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--md</code>
                </p>
                <Avatar size="md" avatarName="Justin Martin" />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--lg</code>
                </p>
                <Avatar size="lg" avatarName="Justin Martin" />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--xl</code>
                </p>
                <Avatar size="xl" avatarName="Justin Martin" />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--2xl</code>
                </p>
                <Avatar size="2xl" avatarName="Justin Martin" />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <p>
                  <code>.avatar .avatar--3xl</code>
                </p>
                <Avatar size="3xl" avatarName="Justin Martin" />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>

            <h5>Avatars - User Status</h5>
            <p className="text-sm">
              Adding a class of <code>.avatar--online</code> will display a
              status icon.
            </p>
            <div className="lg:row-flex">
              <div className="col lg:w-1/6">
                <Avatar isOnline avatarName="Justin Martin" />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar isOnline size="md" avatarName="Justin Martin" />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar isOnline size="lg" avatarName="Justin Martin" />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar isOnline size="xl" avatarName="Justin Martin" />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar isOnline size="2xl" avatarName="Justin Martin" />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar isOnline size="3xl" avatarName="Justin Martin" />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>

            <h5>Avatars w/ Company Logo</h5>
            <div className="lg:row-flex mt-6">
              <div className="col lg:w-1/6">
                <Avatar
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="md"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="lg"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="2xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="3xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>

            <h5>Avatars w/ Close Action</h5>
            <div className="lg:row-flex mt-6">
              <div className="col lg:w-1/6">
                <Avatar
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="md"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="lg"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="2xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="3xl"
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>

            <h5>Loaded Avatars</h5>
            <div className="lg:row-flex mt-6">
              <div className="col lg:w-1/6">
                <Avatar
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">2rem x 2rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="md"
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">2.5rem x 2.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="lg"
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">3.5rem x 3.5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="xl"
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">5rem x 5rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="2xl"
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">8rem x 8rem</p>
              </div>
              <div className="col lg:w-1/6">
                <Avatar
                  size="3xl"
                  isOnline
                  avatarName="Justin Martin"
                  avatarUrl="https://i.pravatar.cc/200?img=3"
                  logoUrl="https://via.placeholder.com/150/4562CE/FFFFFF?text=Logo"
                  logoName="Company Name"
                  onClose={() => console.log('Clicked close button')}
                />
                <p className="text-sm">10rem x 10rem</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Typography</h5>
          <div className="page-section__container">
            <Link to="/styleguide/typography">See: Typography</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Text Utilities</h5>
          <div className="page-section__container">
            <Link to="/styleguide/typography">See: Text Utilities</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Buttons</h5>
          <div className="page-section__container">
            <Link to="/styleguide/buttons">See: Buttons</Link>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Filters</h5>
          <div className="page-section__container">
            <div className="py-4">
              <h5>Buttons</h5>
              <p>
                Filters can be used with a caret to denote opening additional
                options or without to denote a toggle. To show the
                active/applied active/applied state of a filter just give the
                button a class of <code>filter--active</code>.
              </p>
              <div className="row-flex mt-4">
                <div className="col">
                  <p className="text-sm">
                    <code>.filter</code>
                  </p>
                  <button type="button" className="filter">
                    Only Talent members
                    <i className="i-caret-down filter__icon" />
                  </button>
                </div>
                <div className="col">
                  <p className="text-sm">Filter w/ No icon</p>
                  <button type="button" className="filter">
                    Only Talent members
                  </button>
                </div>
                <div className="col">
                  <p className="text-sm">
                    <code>.filter--active</code>
                  </p>
                  <button type="button" className="filter filter--active">
                    Only Talent members
                    <i className="i-caret-down filter__icon" />
                  </button>
                </div>
              </div>

              <h5 className="mt-4">Radio button groups</h5>
              <div className="row-flex mt-4">
                <div className="col">
                  <div className="radio-filter">
                    <input
                      type="radio"
                      name="filter[]"
                      id="filterOne"
                      value="filterOne"
                      defaultChecked
                    />
                    <label htmlFor="filterOne">All People</label>
                  </div>
                </div>
                <div className="col">
                  <div className="radio-filter">
                    <input
                      type="radio"
                      name="filter[]"
                      id="filterTwo"
                      value="filterTwo"
                    />
                    <label htmlFor="filterTwo">Only Talent members</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Filters</h5>
          <div className="page-section__container">
            <div className="py-4">
              <h5>Buttons</h5>
              <p>
                Filters can be used with a caret to denote opening additional
                options or without to denote a toggle. To show the
                active/applied active/applied state of a filter just give the
                button a class of <code>filter--active</code>.
              </p>
              <div className="row-flex mt-4">
                <div className="col">
                  <p className="text-sm">
                    <code>.filter</code>
                  </p>
                  <button type="button" className="filter">
                    Only Talent members
                    <i className="i-caret-down filter__icon" />
                  </button>
                </div>
                <div className="col">
                  <p className="text-sm">Filter w/ No icon</p>
                  <button type="button" className="filter">
                    Only Talent members
                  </button>
                </div>
                <div className="col">
                  <p className="text-sm">
                    <code>.filter--active</code>
                  </p>
                  <button type="button" className="filter filter--active">
                    Only Talent members
                    <i className="i-caret-down filter__icon" />
                  </button>
                </div>
              </div>

              <h5 className="mt-4">Radio button groups</h5>
              <div className="row-flex mt-4">
                <div className="col">
                  <div className="radio-filter">
                    <input
                      type="radio"
                      name="filter[]"
                      id="filterOne"
                      value="filterOne"
                      defaultChecked
                    />
                    <label htmlFor="filterOne">All People</label>
                  </div>
                </div>
                <div className="col">
                  <div className="radio-filter">
                    <input
                      type="radio"
                      name="filter[]"
                      id="filterTwo"
                      value="filterTwo"
                    />
                    <label htmlFor="filterTwo">Only Talent members</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <h5 className={style.sectionHeader}>Forms</h5>
          <div className="page-section__container">
            <Link to="/styleguide/forms">See: Forms</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden my-md">
          <div className="bg-red-500">
            <h5 className="text-white uppercase px-lg py-sm">Grid</h5>
          </div>
          <div className="p-sm md:p-md lg:p-lg">
            <div className="container">
              <div className="row flex">
                <div className="col w-1/3 bg-lavendar-500 h-8 border">
                  col w-1/3
                </div>
                <div className="col w-1/3 bg-lavendar-500 h-8 border">
                  col w-1/3
                </div>
                <div className="col w-1/3 bg-lavendar-500 h-8 border">
                  col w-1/3
                </div>
              </div>
            </div>

            <div className="row flex mt-4">
              <div className="col w-1/3">
                <div className="h-8 bg-lavendar-500 border">col w-1/3</div>
              </div>
              <div className="col w-1/3">
                <div className="h-8 bg-lavendar-500 border">col w-1/3</div>
              </div>
              <div className="col w-1/3">
                <div className="h-8 bg-lavendar-500 border">col w-1/3</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
