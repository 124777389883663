import React from 'react';

const ListActions = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  const classes = ['list-actions'];

  if (className?.length) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

export default ListActions;
