import React from 'react';

type Props = {
  heading?: string | null;
  className?: string;
  children?: any;
};

const ContentHeader = ({ heading, className, children }: Props) => {
  let childrenTag;
  if (children) {
    childrenTag = <div className="h-4">{children || null}</div>;
  } else {
    childrenTag = null;
  }

  return (
    <div className={`content-header px-4 ${className || ''}`}>
      <div className="h-5 mb-4">
        {heading && <h6 className="text-center truncate">{heading}</h6>}
      </div>
      {childrenTag}
    </div>
  );
};

export default React.memo(ContentHeader);
