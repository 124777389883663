// eslint-disable-next-line
import React from 'react';

interface Props {
  id: string;
  setTitle: Function;
}

// eslint-disable-next-line no-unused-vars
const PortfolioView = ({ id, setTitle }: Props) => {
  // const [loading, setLoading] = useState(false);
  // const [item, setItem] = useState<PortfolioEntity>();
  // const { addResponseError } = useContext(AppContext);
  // const loadItem = useCallback(
  // (itemId: string) => {
  // if (!itemId) return;
  // set the loading animation
  // setLoading(true);
  // Api.getProject('5d7c0c0a0d96d5054a01eb1f', itemId)
  //   .then((response: PortfolioEntity) => {
  //     setItem(response);
  //     setTitle(response.title);
  //   })
  //   .catch((error: any) => {
  //     addResponseError(error);
  //     return error;
  //   })
  //   .finally(() => {
  //     setLoading(false);
  //   });
  // },
  // []
  // );
  //
  // useEffect(() => {
  //   loadItem(id);
  // }, [id, loadItem]);
  //
  // return (
  //   <>
  //     <Loading isActive={loading} />
  //     {!loading && item ? <div>Porfolio Item here</div> : null}
  //   </>
  // );

  return 'Portfolio Item';
};

export default PortfolioView;
