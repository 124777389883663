import React, { useContext, useEffect } from 'react';
import { useCookie } from 'react-use';
import { TALENT_PLAN_SLUG } from '../../../config';
import ButtonLink from '../../core/ButtonLink';
import UserContext from '../../user/Context';
import { SOURCE_COOKIE } from '../../cookie/SourceCookie';

type Props = any & {
  work: WorkEntity;
};
const WorkApplyNowCTA = (props: Props) => {
  const { work, ...rest } = props;

  const { pathname } = window.location;
  const { id: userId, redirectUrl, setRedirectUrl } = useContext(UserContext);

  const [, setSourceCookie] = useCookie(SOURCE_COOKIE);

  useEffect(() => {
    if (!redirectUrl && !userId) setRedirectUrl(pathname);
  }, [pathname, redirectUrl, setRedirectUrl, userId]);

  if (!work) {
    return null;
  }

  let path;
  if (work.isCampaign) {
    path = '/login';
  } else {
    path = work.type === 'Student' ? '/beats' : `/plans/${TALENT_PLAN_SLUG}`;
  }

  return (
    <ButtonLink
      onClick={() => {
        setSourceCookie(`Work-Apply:${work.id}`, {
          expires: 86400 * 30,
          path: '/',
        });
      }}
      href={path}
      color="primary"
      fill="solid"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      Apply Now
    </ButtonLink>
  );
};

export default WorkApplyNowCTA;
