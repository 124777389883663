import React from 'react';
import cn from 'classnames';

import { DEFAULT_COVER_PHOTO_URL } from '../../asset-config';
import cloudinary from '../../utilities/Cloudinary';
import useForceMobile from '../../hooks/useForceMobile';
import { srcSetFunction } from '../../utilities/Image';
import ProfileTagline from './ProfileTagline';

type Props = {
  url: string | null;
  altText: string;
  tagline?: string | null;
  graphicTagline?: string | null;
};

const ProfileCoverPhoto = ({
  url,
  altText = 'Profile Cover Photo',
  tagline = null,
  graphicTagline = null,
}: Props) => {
  const forceMobile = useForceMobile();

  return (
    <div
      className={cn('profile__cover relative z-0', {
        'h-screen35': !forceMobile,
      })}
    >
      <img
        srcSet={srcSetFunction(url || DEFAULT_COVER_PHOTO_URL, [
          '750',
          '1125',
          '1680',
          '2560',
        ])}
        src={cloudinary(url || DEFAULT_COVER_PHOTO_URL, ['w_2680'])}
        alt={altText}
        className="absolute inset-0 w-full h-full object-cover z-10"
      />
      <ProfileTagline tagline={tagline} graphicTagline={graphicTagline} />
    </div>
  );
};
export default ProfileCoverPhoto;
