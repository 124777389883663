import React from 'react';
import { IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import Page from '../components/core/Page';
import SuggestedCandidatesProvider from '../components/suggestedCandidates/search/SuggestedCandidatesProvider';

export default function SuggestedCandidates() {
  return (
    <Page
      pageTitle="Suggested Candidates"
      layout="none"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/work" />
          </IonButtons>
          <IonTitle slot="start">Suggested Candidates</IonTitle>
        </IonToolbar>
      )}
    >
      <SuggestedCandidatesProvider />
    </Page>
  );
}
