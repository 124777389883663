import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react';
import querystringify from 'querystringify';
import { useLocation } from 'react-router';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import { isMobile } from '../../utilities/Device';
import Panels from '../core/panels/panels';
import PageHeaderMobile from '../core/PageHeaderMobile';
import PerkFeatureSliderMobile from './PerkFeatureSliderMobile';
import PerkFeatureSliderDesktop from './PerkFeatureSliderDesktop';
import Filters from './Filters';
import useSearch from '../search/useSearch';
import SearchContext from '../search/Context';
import PerkOrBundleCard from './PerkOrBundleCard';
import CollectionsSidebar from './CollectionsSidebar';

const Perks = () => {
  const location = useLocation();
  const panelRef = useRef<PanelRef>();
  const { getPerks } = useContext(SearchContext);
  const [results, handleSearch] = useSearch(getPerks);
  const [featured, setFeatured] = useState<PerkBundleSearchResponse[]>([]);
  const hits = useMemo(() => {
    const { search } = results;
    return search?.hits || [];
  }, [results]);
  const { q, category } = querystringify.parse(location.search) as any;
  const isSearching = useMemo(() => q, [q]);

  const closeFilters = () => {
    if (panelRef.current) {
      panelRef.current.leftPanelOpen(false);
    }
  };

  const loadPerks = useCallback(() => {
    return handleSearch({
      search: {
        query: q || '',
        filters: ['isActive=1', 'isBundleOnly!=1'].join(' AND '),
        facetFilters: [category ? [`categories:${category}`] : null],
        hitsPerPage: 200,
        page: 0,
      },
    });
  }, [handleSearch, category, q]);

  useEffect(() => {
    loadPerks();
  }, [loadPerks]);

  useEffect(() => {
    const { search } = results;
    setFeatured(
      search?.hits.filter(
        (item: PerkBundleSearchResponse) => item.isFeatured
      ) || []
    );
  }, [results]);

  const FiltersButton = ({ toggleLeftPanel }: { toggleLeftPanel: any }) => (
    <button
      type="button"
      onClick={toggleLeftPanel}
      className="btn btn--sm pr-2"
    >
      Filters <i className="i-filters text-lg ml-xs" />
    </button>
  );

  return (
    <Panels
      leftPanel={<Filters onFilterApplied={() => closeFilters()} />}
      contextPanel={<CollectionsSidebar />}
      leftPanelIcon="i-filters"
      ref={panelRef}
    >
      {toggleLeftPanel => (
        <>
          {isMobile && (
            <PageHeaderMobile
              title={category ? undefined : 'Perks'}
              subtitle={category || undefined}
              action={<FiltersButton toggleLeftPanel={toggleLeftPanel} />}
            />
          )}
          {!isSearching &&
            (isMobile ? (
              <PerkFeatureSliderMobile items={featured} />
            ) : (
              <PerkFeatureSliderDesktop items={featured} />
            ))}
          <div className="px-4 py-6 md:px-16 md:py-8 4xl:px-20 4xl:py-12 5xl:px-24 5xl:py-16">
            {isSearching && (
              <div className="mb-8 pt-20">
                <h4>Search Results</h4>
                {!hits.length && (
                  <div className="mt-8">No results found :-/</div>
                )}
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 4xl:grid-cols-3 gap-4">
              {hits.map((hit: any) => (
                <PerkOrBundleCard key={hit.objectID} perkBundle={hit} />
              ))}
            </div>
          </div>
        </>
      )}
    </Panels>
  );
};
export default Perks;
