import React from 'react';
import { FormGroup } from '../../core/form';

export default ({ name, errors, register }: any) => (
  <FormGroup errors={errors} name={name}>
    <input
      type="text"
      id={name}
      name={name}
      placeholder="Last Name"
      className="form-input"
      data-recurly="last_name"
      ref={register({
        required: 'Last name is required',
      })}
    />
  </FormGroup>
);
