import React from 'react';
import { IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import Page from '../components/core/Page';
import Candidates from '../components/work/Candidates';

export default ({ match }: any) => {
  const { workId, status } = match.params;

  return (
    <Page
      authGuard
      pageTitle="Candidates"
      layout="fullHeight"
      // unMountOnLeave
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/work/${workId}`} />
          </IonButtons>
          <IonTitle slot="start">Candidates</IonTitle>
        </IonToolbar>
      )}
    >
      <Candidates workId={workId} status={status} />
    </Page>
  );
};
