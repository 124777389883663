import React, { useCallback, useContext, useEffect, useState } from 'react';
import numeral from 'numeral';
import { feedApi } from '../../Api';
import { addResponseError } from '../../services/Messaging';
import { currency } from '../../utilities/Number';
import MembershipContext from '../membership/Context';
import ProfileProgress from '../profileCompleteness/ProfileProgress';
import useBrandConfig from '../../hooks/useBrandConfig';

const Header = () => {
  const { name } = useBrandConfig();
  const { membership } = useContext(MembershipContext);
  const [stats, setStats] = useState<StatsEntity>();

  const loadFeed = useCallback(() => {
    return feedApi
      .stats()
      .then((response: FeedData) => {
        setStats(response.stats);
      })
      .catch(error => {
        addResponseError(error);
      });
  }, []);

  useEffect(() => {
    loadFeed();
  }, [loadFeed]);

  const formatNumber = (number: number) => {
    return numeral(number).format('0,0');
  };

  // const averageValue = (total = 0, value = 0) => {
  //   return total / value;
  // };

  return (
    <div className="discover-header flex flex-col justify-center">
      <img
        className="absolute object-cover inset-0 w-full h-full z-0"
        src="https://res.cloudinary.com/communo/image/upload/v1601328653/assets/feed/discover.jpg"
        alt="header"
      />
      <div className="page-container">
        <div className="w-9/10 3xl:w-full mx-auto text-white pt-4 md:pt-8 pb-12 3xl:px-4 lg:flex justify-between relative z-20">
          <div className="lg:w-7/12 xl:w-6/12 flex flex-col justify-center">
            <div className="content">
              <h1 className="h0 uppercase font-sans">Discover</h1>
              {stats ? (
                <p className="text-large font-medium">
                  Welcome to Discover, your personalized content feed. With{' '}
                  {formatNumber(stats.userCount)} members and growing, we’re
                  making it easier to find the members who matter most to you.
                </p>
              ) : null}
            </div>
            {stats ? (
              <div className="row-flex">
                <div className="col mt-5 sm:mt-10">
                  <span className="text-sm text-white font-semibold uppercase">
                    Users In {name}
                  </span>
                  <div className="h2 font-sans font-bold">
                    {formatNumber(stats.userCount)}
                  </div>
                </div>
                <div className="col mt-5 sm:mt-10">
                  <span className="text-sm text-white font-semibold uppercase">
                    Agencies
                  </span>
                  <div className="h2 font-sans font-bold">
                    {formatNumber(stats.agencyCount)}
                  </div>
                </div>
                <div className="col mt-5 sm:mt-10">
                  <span className="text-sm text-white font-semibold uppercase">
                    Total Value
                  </span>
                  <div className="h2 font-sans font-bold">
                    {currency(stats.workTotal)}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-8 lg:mt-0 lg:w-5/12 xl:w-6/12">
            {membership && (
              <ProfileProgress variation="circle" membership={membership} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
