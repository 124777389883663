import React from 'react';
import cn from 'classnames';

type Props = {
  title: string;
  message: string;
  className?: string;
};

const ErrorBanner = ({ title, message, className }: Props) => {
  return (
    <div className={cn('p-4 bg-red-100 text-red-1100', className)}>
      <p>
        <strong>{title}</strong>
      </p>
      <div>{message}</div>
    </div>
  );
};
export default ErrorBanner;
