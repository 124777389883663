import React from 'react';
import Avatar from '../avatar/Avatar';
import cloudinary from '../../utilities/Cloudinary';
import { Link } from '../core/router';
import { srcSetFunction } from '../../utilities/Image';

type Props = {
  id: string;
  name: string;
  company?: string;
  offer: string;
  logoURL?: string;
  imageURL: string;
  description: string;
  className?: string;
  country?: any;
};

const PerkCard = ({
  id,
  name,
  company,
  offer,
  logoURL,
  imageURL,
  description,
  className,
  country,
}: Props) => {
  const countryLocation = (location: any) => {
    if (location === 'CA') {
      return (
        <p className="absolute top-0 right-0 px-6 py-2 text-sm bg-black text-white">
          Canada Only
        </p>
      );
    }
    if (location === 'US') {
      return (
        <p className="absolute top-0 right-0 px-6 py-2 text-sm bg-black text-white">
          USA Only
        </p>
      );
    }
    return null;
  };
  return (
    <Link
      className={`bg-white block rounded-md ${className}`}
      to={`/perks/${id}`}
    >
      <div className="aspect-2/1 bg-grey-300 relative rounded-md rounded-b-none overflow-hidden">
        <img
          srcSet={srcSetFunction(imageURL, ['600', '1000', '1300'])}
          src={cloudinary(imageURL, ['w_826', 'h_414', 'g_auto'])}
          alt={name}
          className="absolute inset-0 w-full h-full object-cover"
        />
        {countryLocation(country)}
      </div>
      <div className="p-4 2xl:p-6 relative">
        {logoURL && company && (
          <Avatar
            size="xl"
            avatarUrl={logoURL}
            avatarName={company}
            className="absolute top-0 right-0 transform -translate-y-1/2 mr-4 2xl:mr-6"
            borderWidth={4}
          />
        )}
        {company && (
          <div className="text-xl leading-snug tracking-tight pr-20">
            {company}
          </div>
        )}
        <div className="font-semibold text-grey-500 mt-3">{name}</div>
        <h5 className="mt-1 leading-tight">{description}</h5>
        <div className="text-large text-right mt-3 tracking-tight">{offer}</div>
      </div>
    </Link>
  );
};
export default PerkCard;
