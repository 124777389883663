import React, { useContext, useState } from 'react';
import UserContext from '../user/Context';
import Button from '../core/Button';
import useUserRecommendations from '../recommendations/hooks/useUserRecommendations';
import { LoadingRing } from '../core/Loading';
import RecommendationSummary from '../recommendations/RecommendationSummary';
import RequestRecommendationButton from '../recommendations/RequestRecommendationButton';
import ViewRecommendationsModal from '../recommendations/ViewRecommendationsModal';

type Props = {
  user: UserEntity;
};
const ProfileRecommendations = ({ user }: Props) => {
  const { id } = useContext(UserContext);
  const { recommendations, isFetching } = useUserRecommendations(user.id);
  const [viewingRecommendations, setViewingRecommendations] = useState(false);

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  const isCurrentUser = user.id === id;
  const recentRecommendations = recommendations.slice(0, 2);

  return (
    <div className="mb-8">
      <p className="profile__section-title">
        Recommendations
        <span className="ml-5 px-2 font-medium bg-grey-500 text-white rounded">
          {recommendations.length}
        </span>
      </p>

      {recommendations.length > 0 ? (
        <>
          <button
            type="button"
            onClick={() => setViewingRecommendations(true)}
            className="text-left w-full"
          >
            {recentRecommendations.map(recommendation => (
              <RecommendationSummary
                key={recommendation.id}
                recommendation={recommendation}
              />
            ))}
          </button>
          <Button
            type="button"
            fill="outline"
            color="primary"
            text="View All Recommendations"
            onClick={() => setViewingRecommendations(true)}
          />
        </>
      ) : (
        <div className="mt-4">
          <NoRecommendations isCurrentUser={isCurrentUser} />
        </div>
      )}

      {viewingRecommendations ? (
        <ViewRecommendationsModal
          userId={user.id}
          onClose={() => setViewingRecommendations(false)}
        />
      ) : null}
    </div>
  );
};
export default ProfileRecommendations;

const NoRecommendations = ({ isCurrentUser }: { isCurrentUser: boolean }) => {
  if (isCurrentUser) {
    return <RequestRecommendationButton />;
  }

  return <p>This user has no recommendations.</p>;
};
