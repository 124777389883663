import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FolderIcon from '../../icons/FolderIcon';
import MembershipContext from '../../membership/Context';
import { CollectionsContext } from '../context/CollectionsContext';
import AddUserToCollectionModal from './AddUserToCollectionModal';

type Props = {
  userId: string;
  onUpdate?: (collectionIdsUserIsIn: string[]) => void;
  size?: 'small' | 'large';
  className?: string;
};

const WrappedUserCollectionIcon = (props: Props) => {
  const { checkAccess } = useContext(MembershipContext);

  if (!checkAccess('canAccessCollections')) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UserCollectionIcon {...props} />;
};

const UserCollectionIcon = ({
  userId,
  onUpdate,
  size = 'small',
  className = '',
}: Props) => {
  const isMounted = useRef(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { isUserInBench, isUserInList } = useContext(CollectionsContext);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onCloseModal = useCallback(
    (collectionIdsUserIsIn: string[]) => {
      if (isMounted.current) {
        setModalOpen(false);
      }

      if (onUpdate) {
        onUpdate(collectionIdsUserIsIn);
      }
    },
    [onUpdate]
  );

  const inBench = isUserInBench(userId);

  let colorClasses = 'bg-white text-grey-500 hover:text-black';
  if (inBench) {
    colorClasses = 'bg-red-900 text-white';
  } else if (isUserInList(userId)) {
    colorClasses = 'bg-black text-white';
  }

  const sizeClasses = size === 'small' ? 'h-8 w-8 p-1' : 'h-10 w-10 p-2';

  return (
    <div className={className}>
      <button
        type="button"
        className={`${colorClasses} ${sizeClasses} rounded-lg flex items-center justify-center`}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        {inBench ? (
          <i className="i-star-solid text-xl" />
        ) : (
          <FolderIcon className="w-full h-full" />
        )}
      </button>
      {isModalOpen ? (
        <AddUserToCollectionModal userId={userId} onClose={onCloseModal} />
      ) : null}
    </div>
  );
};

export default WrappedUserCollectionIcon;
