import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import useMyApplications from '../../hooks/useMyApplications';
import {
  fromNow,
  isDatePassed,
  relativeDate,
  timeDiff,
} from '../../utilities/dateTime';
import { currency } from '../../utilities/Number';
import { LoadingBalls } from '../core/Loading';

import Empty from '../empty/Empty';
import useNavigate from '../../hooks/useNavigate';

const ListItem = ({
  application,
  index,
}: {
  application: ApplicationEntity;
  index: number;
}) => {
  const { push } = useHistory();
  const { work } = application;
  const isClosed = isDatePassed(work.expiresAt);

  const handleClick = () => {
    push(`/work/${application.work.id}`);
  };

  return (
    <div
      onClick={handleClick}
      role="none"
      className={`flex cursor-pointer overflow-hidden justify-center md:justify-start text-center md:text-left bg-white rounded-xl p-4 md:px-8 flex-wrap md:flex-nowrap ${
        index > 0 ? 'mt-2 pt-4' : ''
      }`}
    >
      <div className="truncate md:mr-4">
        <div className="font-bold truncate h5">{work.title}</div>
        <div className="flex text-xs whitespace-nowrap">
          <div>{work.isRemote ? 'Remote OK' : work.location.friendlyName}</div>
          {work.type && (
            <>
              <div className="mx-2">|</div>
              <div>{work.type}</div>
            </>
          )}
          {work.price && (
            <>
              <div className="mx-2">|</div>
              <div>{currency(work.price)}</div>
            </>
          )}
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-end ml-auto whitespace-nowrap">
        <div className="flex justify-end text-xs">
          <div>Applied {relativeDate(application.createdAt)}</div>
        </div>
        <div className="flex justify-end text-xs">
          <div className="font-medium">
            {isClosed ? 'Closed' : `Closes`} {fromNow(work.expiresAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

const List = ({
  applications,
}: {
  applications: ApplicationEntity[] | null;
}) => {
  const navigate = useNavigate();
  const navigateToWorkPage = () => navigate('work');

  return !applications || applications.length < 1 ? (
    <div className="w-fit m-auto">
      <Empty
        title="You haven’t applied for any jobs yet."
        subtitle="This is where you will find them when you do."
        buttonLabel="Browse job opportunities"
        handleClick={navigateToWorkPage}
        name="myapplications"
      />
    </div>
  ) : (
    <div className="grid gap-4">
      {applications
        .sort((a, b) => timeDiff(a.createdAt, b.createdAt, 'minutes'))
        .slice(0, 3)
        .map((item, i) => (
          <ListItem key={item.id} index={i} application={item} />
        ))}
    </div>
  );
};

const MyApplications = () => {
  const { data, loading, refresh } = useMyApplications();
  const didMount = useRef(false);

  const { key, pathname } = useLocation();

  useEffect(() => {
    if (didMount.current && pathname.includes('feed')) refresh();
    else didMount.current = true;
  }, [refresh, key, pathname]);

  return loading ? <LoadingBalls isActive /> : <List applications={data} />;
};

export default MyApplications;
