import React from 'react';
import { ListItemComponentProps } from '..';
import { Link } from '../../core/router';

interface Props extends ListItemComponentProps {}

const Category = ({ item }: Props) => {
  const { heading, imageUrl, actionUrl } = item;

  return (
    <Link to={actionUrl || ''}>
      <div className="discover-category">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          alt={heading}
          src={imageUrl || ''}
        />
        <div className="discover-category__inset">{heading}</div>
      </div>
    </Link>
  );
};
export default Category;
