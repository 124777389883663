import React, { useEffect, useState } from 'react';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import ChatMessageContext from '../chat/context/ChatMessageContext';
import ApplicationConversation from './ApplicationConversation';

type Props = {
  application: ApplicationEntity;
  messageTemplate: string;
  patchApplications: Function;
  segment: string;
  work: WorkEntity;
};

const CandidatesChatTab = ({
  application,
  messageTemplate,
  patchApplications,
  segment,
  work,
}: Props) => {
  const { user } = application;
  const { firstName, lastName } = user;
  const name = `${firstName} ${lastName}`;

  const [message, setMessage] = useState<string>(messageTemplate);

  useEffect(() => {
    setMessage(messageTemplate);
  }, [application.id, messageTemplate]);

  return (
    <ChatMessageContext.Provider value={message}>
      <div className="flex flex-col pt-6 pb-2 px-4 items-center w-full gap-6 h-full">
        <ScoreAvatar
          name={name}
          imageUrl={user.avatarURL}
          percentage={application.score}
          size={20}
        />

        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-px items-center">
            <h4>{name}</h4>
            <p>{user.title}</p>
            {user.location?.friendlyName ? (
              <p>{user.location?.friendlyName}</p>
            ) : null}
          </div>
        </div>
        <div className="border-t-2 border-grey-300 h-full w-full">
          <ApplicationConversation
            key={application.id}
            workId={work.id}
            application={application}
            patchApplications={patchApplications}
            isActive={segment === 'chat'}
          />
        </div>
      </div>
    </ChatMessageContext.Provider>
  );
};

export default CandidatesChatTab;
