import React, { useContext, useEffect, useState } from 'react';
import { isPlatform } from '@ionic/core';
import { useLocation } from 'react-router';
import { StepType } from '../stepper/Step';
import Stepper from '../stepper/Stepper';
import UserContext from '../user/Context';
import Authenticate from './steps/Authenticate';
// import ConnectLinkedin from './steps/ConnectLinkedin';
import Endorsement from './steps/Endorsement';
import Experience from './steps/Experience';
import InvitationStep from './steps/InvitationStep';
import Join from './steps/Join';
import MoreInfo from './steps/MoreInfo';
import Rate from './steps/Rate';
import Review from './steps/Review';
// import SignIn from './steps/SignIn';
import Welcome from './steps/Welcome';
import useNavigate from '../../hooks/useNavigate';
import { SubmitReferenceContext } from './context/SubmitReferenceContext';
import RoleWorkedWith from './steps/RoleWorkedWith';

const isCapacitor = isPlatform('capacitor');

const invitationStep: StepType = {
  title: 'You’ve received the following request…',
  component: InvitationStep,
  canAdvance: true,
};

const authenticateStep: StepType = {
  title: 'Sign in',
  description:
    'We use LinkedIn to verify those giving a reference. We’ll grab your particulars from your profile to speed up the process.',
  component: Authenticate,
  canAdvance: true,
  hideButtons: true,
};

// const connectStep: StepType = {
//   title: 'Connect your LinkedIn',
//   description:
//     'We use LinkedIn to verify those giving a reference. By continuing with LinkedIn we’ll connect your LinkedIn and Communo accounts.',
//   component: ConnectLinkedin,
//   canAdvance: true,
//   hideButtons: true,
// };

const welcomeStep: StepType = {
  title: 'Welcome',
  description:
    'We’ve imported the following from your LinkedIn profile. Don’t worry, your email will not be displayed.',
  component: Welcome,
  hideButtons: true,
};

const roleWorkedWithStep: StepType = {
  title: 'Role Worked With',
  description: 'Your role when you worked with them.',
  component: RoleWorkedWith,
  canAdvance: false,
  canGoBack: true,
};

const referenceSteps: StepType[] = [
  {
    title: 'Almost there!',
    description: 'We just need a bit more information.',
    component: MoreInfo,
  },
  {
    title: 'Rate the candidate',
    description: 'How did they meet your expectations?',
    component: Rate,
    canAdvance: true,
  },
  {
    title: 'Leave a brief endorsement',
    description:
      'Something short (under 500 characters) to display on the candidate’s profile.',
    component: Endorsement,
    canAdvance: true,
  },
  {
    title: 'Review',
    description: 'Your reference will appear as follows.',
    component: Review,
    canAdvance: true,
    nextButtonLabel: 'Submit',
  },
  {
    title: 'One last thing…',
    description:
      'Hiring qualified talent is no walk in the park, right? Where do you usually look to hire freelance talent?',
    component: Experience,
    hideButtons: true,
  },
  {
    title: 'Welcome to Communo!',
    description:
      'Fulfill your need for freelance talent on the Communo platform, and get direct access to talent.',
    component: Join,
    hideButtons: true,
  },
];

const SubmitReferenceStepper = () => {
  const { user, isAuthenticated } = useContext(UserContext);
  const { referenceRequest, leaveFor } = useContext(SubmitReferenceContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const hasLinkedInProvider = user?.oauthProviders?.find(
    provider => provider.providerType === 'linkedin'
  );

  useEffect(() => {
    if (isCapacitor && !isAuthenticated) {
      navigate(`/login?redirectUrl=${pathname}`, 'forward', 'replace');
    }

    // eslint-disable-next-line
  }, []);

  const [steps] = useState(() => {
    const combinedSteps = [];

    // If leaving a reference by request, show a step to review it
    if (referenceRequest) {
      combinedSteps.push(invitationStep);
    }

    if (!hasLinkedInProvider && !isCapacitor) {
      combinedSteps.push(authenticateStep, welcomeStep);
    }

    // If leaving a reference for someone unprompted...
    if (leaveFor) {
      combinedSteps.push(roleWorkedWithStep);
    }

    combinedSteps.push(...referenceSteps);

    return combinedSteps;
  });

  return (
    <div>
      <Stepper
        heading="Reference"
        hideDivider
        steps={steps}
        sliderClassName="overflow-visible pb-0"
      />
    </div>
  );
};
export default SubmitReferenceStepper;
