import shallow from 'zustand/shallow';
import channelMembersStoreCreator, {
  ChatChannelMembersState,
} from '../state/ChatChannelMembersState';

/**
 * useChatChannelMember
 *
 * Hook that returns a Member object for a given Channel and identity.
 */
const useChatChannelMember = (sid: string, identity: string) => {
  const [channelMembersStore] = channelMembersStoreCreator(sid);
  const [members] = channelMembersStore(
    (state: ChatChannelMembersState) => [state.members],
    shallow
  );

  const member = members[identity];

  return {
    member,
  };
};
export default useChatChannelMember;
