import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { addError, addSuccess } from '../../services/Messaging';
import VideoContext from './Context';
import Invite from './Invite';
import Modal from '../core/modal/Modal';
import { appUrl } from '../../utilities/Url';

const MenuButton = ({ onClick, icon }: { onClick: any; icon: string }) => {
  return (
    <button
      className="btn--primary mr-2 rounded-full w-12 h-12 relative"
      type="button"
      onClick={onClick}
    >
      <span className="text-xl">
        <i className={icon} />
      </span>
    </button>
  );
};

const MenuBar = () => {
  const {
    room,
    disconnect,
    enableVideo,
    disableVideo,
    videoEnabled,
    mute,
    unmute,
    muted,
    canShareScreen,
    isSharingScreen,
    startScreenShare,
    stopScreenShare,
  } = useContext(VideoContext);

  const [inviteOpen, setInviteOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const copyInviteURL = () => {
    if (!room?.name) {
      addError('Failed to copy link to clipboard');
      return;
    }

    const url = appUrl(`/video-chat/${room.name}`);
    navigator.clipboard
      .writeText(url)
      .then(() => addSuccess(`Copied invite link to clipboard`));
  };

  return room ? (
    <>
      <div className="video-menu">
        <p className="text-center text-white w-auto mb-2">
          Connected to room: {room.name}
        </p>
        <div className="flex justify-center">
          {canShareScreen() && (
            <MenuButton
              icon={isSharingScreen ? 'i-user' : 'i-image'}
              onClick={() => {
                if (isSharingScreen) stopScreenShare();
                else startScreenShare();
              }}
            />
          )}
          <MenuButton
            icon={videoEnabled ? 'i-video-camera' : 'i-video-camera-off'}
            onClick={() => (videoEnabled ? disableVideo() : enableVideo())}
          />
          <MenuButton
            icon={muted ? 'i-microphone-off' : 'i-microphone'}
            onClick={() => (muted ? unmute() : mute())}
          />
          <MenuButton
            icon="i-close"
            onClick={() => {
              disconnect();
              history.push(`/video-chat${location?.search || ''}`);
            }}
          />
          <MenuButton icon="i-link" onClick={() => copyInviteURL()} />
          <MenuButton icon="i-user-add" onClick={() => setInviteOpen(true)} />
        </div>
      </div>
      {inviteOpen && (
        <Modal
          title="Invite to Video Chat"
          isOpen={inviteOpen}
          onClose={() => setInviteOpen(false)}
        >
          <Invite roomId={room.name} onComplete={() => setInviteOpen(false)} />
        </Modal>
      )}
    </>
  ) : null;
};

export default MenuBar;
