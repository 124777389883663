/* eslint-disable no-console */
import messageStoreCreator from '../state/ChatChannelMessagesState';
import { IChannel, IMessage } from '../../../@types/chat.d';
import { CHAT_MESSAGES_PER_PAGE } from '../../../config';

const loadChannelMessagesToState = async (channelInstance: IChannel) => {
  const { sid } = channelInstance;
  const [, messagesApi] = messageStoreCreator(sid);
  const { addMessages } = messagesApi.getState();

  const { items, hasPrevPage } = await channelInstance.getMessages(
    CHAT_MESSAGES_PER_PAGE
  );

  const mappedItems = items.map(item => item as IMessage);
  addMessages(mappedItems, hasPrevPage);
  return mappedItems;
};

export default loadChannelMessagesToState;
