import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import SearchContext from '../../search/Context';
import useSearch from '../../search/useSearch';
import Avatar from '../../avatar/Avatar';
import {
  ListActionsItemButton,
  ListActionsItemCheckbox,
  ListActions,
} from '../../listActions';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import FormSearch from '../../core/form/FormSearch';
import { LoadingBalls } from '../../core/Loading';
import Modal from '../../core/modal/Modal';
import useFeatureGate from '../../../hooks/useFeatureGate';
import { useCollectionsContext } from '../../collections/context/CollectionsContext';
import { getPermissionForChat } from '../../../utilities/permissionUtil';
import { NewConversationUser } from '../../../@types/conversation.d';

type AlgoliaUser = {
  objectID: string;
  fullName: string;
  avatarURL: string | null;
  primaryMembershipUser: {
    membership: MembershipEntity;
  } | null;
};

const ListActionsUser = ({
  user,
  isCheckable,
  onAddMember,
}: {
  user: NewConversationUser;
  isCheckable: boolean;
  onAddMember: (user: NewConversationUser, canMessage: boolean) => void;
}) => {
  const { identity, fullName, avatarURL, canMessage, isChecked } = user;
  const UserAvatar = (
    <Avatar avatarName={fullName} avatarUrl={avatarURL} size="md" />
  );

  return isCheckable ? (
    <ListActionsItemCheckbox
      className={`${!canMessage ? 'opacity-50' : ''}`}
      start={UserAvatar}
      name={identity}
      value={identity}
      isChecked={isChecked}
      onClick={() => onAddMember(user, canMessage)}
    >
      {fullName}
    </ListActionsItemCheckbox>
  ) : (
    <ListActionsItemButton
      className={`${!canMessage ? 'opacity-50' : ''}`}
      start={UserAvatar}
      onClick={() => onAddMember(user, canMessage)}
    >
      {fullName}
    </ListActionsItemButton>
  );
};

const AddMembers = ({
  members,
  multiSelect = false,
  onAddMember,
  children,
}: {
  members?: NewConversationUser[];
  multiSelect?: boolean;
  onAddMember: (user: NewConversationUser) => void;
  children?: React.ReactNode;
}) => {
  const { checkAccess } = useCurrentMembership();
  const { getUsers } = useContext(SearchContext);
  const [results, handleSearch, loading] = useSearch(getUsers, 300);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const searchInputRef: any = useRef();

  const { openUpgradeFlowFor } = useFeatureGate();
  const { isUserInBench } = useCollectionsContext();

  const memoResults = useMemo(
    (): NewConversationUser[] =>
      results?.hits.map((user: AlgoliaUser) => {
        const { objectID: identity, fullName, avatarURL } = user;
        const membership = user.primaryMembershipUser?.membership;
        const planType = membership?.plan?.type ?? '';
        const isChecked = members?.some(
          (member: NewConversationUser) => member.identity === identity
        );
        const permission = getPermissionForChat(planType);
        const userInBench = isUserInBench(identity);
        const canMessageViaPermission = permission
          ? checkAccess(permission)
          : false;
        const canMessage = userInBench || canMessageViaPermission;

        return {
          identity,
          fullName,
          avatarURL,
          canMessage,
          permission,
          isChecked,
        };
      }),
    [results, members, isUserInBench, checkAccess]
  );

  const handleAddMember = useCallback(
    (user: NewConversationUser) => {
      if (user.canMessage) {
        searchInputRef.current.value = '';
        return onAddMember(user);
      }

      return openUpgradeFlowFor(user.permission);
    },
    [onAddMember, openUpgradeFlowFor]
  );

  return (
    <>
      <FormSearch
        ref={searchInputRef}
        placeholder="To:"
        onChange={(e: any) => handleSearch({ query: e.target.value })}
      />

      {children}

      {memoResults ? (
        <>
          {loading ? (
            <LoadingBalls isActive />
          ) : (
            <ListActions>
              {memoResults.map(user => (
                <ListActionsUser
                  key={user.identity}
                  user={user}
                  isCheckable={multiSelect}
                  onAddMember={handleAddMember}
                />
              ))}
            </ListActions>
          )}
        </>
      ) : null}

      <Modal
        title="Upgrade"
        isOpen={showUpgrade}
        onClose={() => setShowUpgrade(false)}
      >
        <div className="text-center">
          <p className="my-4">
            Your current plan does not allow you communicate with all members.
          </p>
          <p className="my-4 text-sm text-subdued">
            Please contact{' '}
            <a
              className="text-link"
              href="mailto:concierge@communo.com?subject=Upgrade%20Membership"
            >
              concierge@communo.com
            </a>{' '}
            to upgrade and access the whole community.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AddMembers;
