import React from 'react';
// import Markdown from '../../core/Markdown';
import RichText from '../../core/RichText';

interface Props {
  title: string | null;
  description: string;
}

const Body = ({ title, description }: Props) => {
  return (
    <div className="px-6 md:px-8 bg-white">
      <h3 className="mt-12 md:mt-0">{title}</h3>
      {description ? (
        <div className="mt-4">
          <RichText html={description} classVariant="ql-editor" />
        </div>
      ) : null}
    </div>
  );
};

export default Body;
