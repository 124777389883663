import { useEffect, useState } from 'react';
import { RecurlyError } from '@recurly/recurly-js';
import Api from '../Api';
import environment from '../../../environment';
import { addResponseError } from '../../../services/Messaging';

const { recurlyPublicKey } = environment;

const useRecurlyItem = (recurlyPlanCode: string, item?: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<RecurlyItem>();
  const [error, setError] = useState<RecurlyError>();

  useEffect(() => {
    if (!recurlyPlanCode || !item) {
      return;
    }

    Api.getItem({
      recurlyPublicKey,
      recurlyPlanCode,
      item,
    })
      .then(res => {
        const { data: resData } = res;

        // Recurly returns an error as a success response. **insert eye roll**
        if (Object.prototype.hasOwnProperty.call(resData, 'error')) {
          setError(resData);
          return;
        }

        setError(undefined);
        setData(resData);
      })
      .catch((err: any) => {
        addResponseError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [item, recurlyPlanCode]);

  return { data, error, isLoading: loading };
};
export default useRecurlyItem;
