import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserContext from '../../user/Context';
import useImportScript from '../../../hooks/useImportScript';

const useSchedulerCodeExchange = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { fetchNylasTokenCallback, user } = useContext(UserContext);

  useImportScript(
    'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js'
  );

  const fetchCallback = async (nylasCode: string) =>
    fetchNylasTokenCallback(nylasCode);

  useEffect(() => {
    if (!user?.nylasAccessTokenExists && search.includes('?state')) {
      // retrieve application state from nylas url
      const nylasCode = new URLSearchParams(search).get('code') as string;

      const nylasState = new URLSearchParams(search).get('state');

      history.push(`${nylasState}#/edit-meetings`);
      fetchCallback(nylasCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};

export default useSchedulerCodeExchange;
