import React from 'react';
import Location from '../../core/formElements/Location';

export default ({ errors, register, defaultValue = '', form }: any) => (
  <Location
    label="Location"
    form={form}
    errors={errors}
    register={register}
    defaultValue={defaultValue}
  />
);
