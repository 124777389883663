import React, { useContext, useMemo, useCallback } from 'react';
import { NavLink } from '../core/router';
import UserContext from '../user/Context';
import MembershipContext from '../membership/Context';
import AppContext from '../../AppContext';
import useCurrentPlan from '../../hooks/useCurrentPlan';
import { HBCU_PLAN_ID } from '../../config';
import useBrandConfig from '../../hooks/useBrandConfig';

type Link = {
  to: string;
  label: string;
  permission?: string;
  refreshOnClick?: boolean;
};

export default () => {
  const { availablePages } = useBrandConfig();
  const { isAuthenticated, hasMembership } = useContext(UserContext);
  const plan = useCurrentPlan();
  const { pathname } = window.location;
  const { checkAccess } = useContext(MembershipContext);
  const { refresh } = useContext(AppContext);

  const links: Link[] = [
    {
      to: '/members',
      label: 'Members',
      permission: 'canViewMembershipList',
      refreshOnClick: true,
    },
    {
      to: '/work',
      label: plan?.id === HBCU_PLAN_ID ? 'Program' : 'Work',
      refreshOnClick: true,
    },
  ];

  if (availablePages.channels) {
    links.push({
      to: '/channels',
      label: 'Channels',
      permission: 'canViewChannels',
    });
  }

  if (availablePages.events) {
    links.push({
      to: '/events',
      label: 'Events',
      refreshOnClick: true,
    });
  }

  if (availablePages.perks) {
    links.push({
      to: '/perks',
      label: 'Perks',
    });
  }

  const allowedLinks = useMemo(() => {
    return links.filter(link => {
      const { permission }: any = link;
      return !permission || checkAccess(permission);
    });
  }, [checkAccess, links]);

  const handleRefresh = useCallback(
    (to: string) => {
      if (pathname === to) refresh();
    },
    [pathname, refresh]
  );

  if (!isAuthenticated) return null;

  if (!hasMembership)
    return (
      <div className="hidden md:flex primary-menu text-lg items-center">
        {[
          {
            to: '/plans',
            label: 'Plans',
          },
        ].map(({ to, label }) => (
          <NavLink key={to} to={to} className="px-4 xl:px-6">
            {label}
          </NavLink>
        ))}
      </div>
    );

  return (
    <div className="hidden md:flex primary-menu text-lg items-center">
      {allowedLinks.map(({ to, label, refreshOnClick }) => (
        <NavLink
          key={to}
          to={to}
          className="px-4 xl:px-6"
          onClick={() => refreshOnClick && handleRefresh(to)}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
};
