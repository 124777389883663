import React, { useContext, useCallback } from 'react';
import { useIonViewDidEnter } from '@ionic/react';
// import { useHistory } from 'react-router';
import UserContext from '../components/user/Context';
import { LoadingBalls } from '../components/core/Loading';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';

const LogoutPage = () => {
  const { logout } = useContext(UserContext);
  useAnalytics('Logout');
  // const history = useHistory();

  const doLogout = useCallback(() => {
    logout(() => {});
  }, [logout]);

  useIonViewDidEnter(() => {
    doLogout();
  });

  return (
    <Page layout="basic" hideHeader>
      <LoadingBalls isActive fullscreen message="See you soon!" />
    </Page>
  );
};

export default LogoutPage;
