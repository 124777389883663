import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Form from './Form';
import MembershipContext from '../membership/Context';
// import ContentHeader from '../core/ContentHeader';

const ProtectedCreateForm = () => {
  const { checkAccess } = useContext(MembershipContext);

  // TODO: Fix permission check
  return <>{checkAccess('canPostEvents') ? <CreateForm /> : null}</>;
};

const CreateForm = () => {
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });

  return <Form form={form} />;
};

export default ProtectedCreateForm;
