const borderRadiusSizes: any = {
  small: 'rounded-xl',
  medium: 'rounded-md',
  large: 'rounded-2xl',
};

// eslint-disable-next-line import/prefer-default-export
export function getBorderRadiusSize(size: string): string {
  return borderRadiusSizes[size];
}
