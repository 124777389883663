import React, { useContext } from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import UserContext from '../components/user/Context';
import Unknown from '../components/user/views/Unknown';

const AuthUnknown = () => {
  const { authDetails } = useContext(UserContext);
  useAnalytics('Unknown Login', { email: authDetails.email });

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Unknown Login"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      <Unknown />
    </Page>
  );
};

export default AuthUnknown;
