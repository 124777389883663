import React from 'react';

const Welcome = () => (
  <>
    {/* <div className="text-center">
      <h2 className="mb-4" data-swiper-parallax="-300">
        We&apos;ve added your profile.
      </h2>
      <p className="text-lg font-light text-white" data-swiper-parallax="-200">
        Nothing to set up - it&apos;s been done for you. You can update anything
        you like.
      </p>
    </div>

    <div className="mt-auto md:mt-8">
      <div className="flex justify-center">
        <Button
          className="btn--primary btn--rev"
          isValid
          handleSubmit={() => goTo('next')}
        >
          Continue
        </Button>
      </div>
    </div> */}
  </>
);

export default Welcome;
