import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { LoadingBalls } from '../../core/Loading';
import { FormActions, FormInput } from '../../core/form';
import { SubmitButton } from '../../core/Button';
import AvatarForm from './AvatarForm';
import CoverPhotoForm from './CoverPhotoForm';
import { Title, Location } from '../formElements';
import MembershipContext from '../../membership/Context';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import UserContext from '../Context';

interface Props {
  user: UserEntity;
}

const BasicForm = ({ user }: Props) => {
  const { update, refresh } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      title: user.title || '',
      // company: user.company || '',
      location: {
        latitude: user.location?.latitude || null,
        longitude: user.location?.longitude || null,
        friendlyName: user.location?.friendlyName || null,
        rawJSON: JSON.stringify(user.location?.rawJSON) || null,
      },
    },
  });
  const { handleSubmit, register, errors, reset } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = {
      // company: values.company,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      location: {
        latitude: values.location.latitude || null,
        longitude: values.location.longitude || null,
        friendlyName: values.location.friendlyName || null,
        rawJSON: JSON.parse(values.location.rawJSON) || null,
      },
    };

    update(data)
      .then((response: UserEntity) => {
        const { title, location } = response;
        const resetData = {
          title: title || '',
          location: {
            friendlyName: location?.friendlyName,
            latitude: location?.latitude,
            longitude: location?.longitude,
            rawJSON: JSON.stringify(location?.rawJSON),
          },
        };
        reset(resetData);
        addSuccess('Profile updated!');
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <label htmlFor="avatarURL">Profile Picture or Avatar</label>
      <AvatarForm
        label="image"
        id={user.id}
        name="avatarURL"
        defaultValue={user.avatarURL}
      />
      {membership?.plan.isIndividualPlan && (
        <div>
          <label htmlFor="coverPhotoURL">Cover Image</label>
          <CoverPhotoForm
            label="cover image"
            id={membership.id}
            name="coverPhotoURL"
            defaultValue={membership.coverPhotoURL}
          />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormInput
          name="firstName"
          label="First name"
          defaultValue={user.firstName}
          placeholder="John"
          errors={errors}
          ref={register({ required: 'First name is required' })}
        />
        <FormInput
          name="lastName"
          label="Last name"
          defaultValue={user.lastName}
          placeholder="Smith"
          errors={errors}
          ref={register({ required: 'Last name is required' })}
        />
        <FormInput
          name="email"
          label="Email"
          type="email"
          defaultValue={user.email}
          placeholder="name@email.com"
          errors={errors}
          ref={register({
            required: 'Email is required',
            validate: value => validator.isEmail(value) || 'Invalid email',
          })}
        />
        <FormInput
          name="phoneNumber"
          label="Phone number"
          type="tel"
          defaultValue={user.phoneNumber}
          placeholder="123-456-7890"
          errors={errors}
          ref={register({
            // required: 'Phone Number is required',
            validate: value =>
              validator.isEmpty(value) ||
              validator.isMobilePhone(value) ||
              'Invalid Phone Number',
          })}
        />
        <Title errors={errors} register={register} />
        <Location
          errors={errors}
          register={register}
          defaultValue={user.location?.friendlyName || ''} // address
          form={form}
        />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default BasicForm;
