import { useContext } from 'react';
import MyWorkContext from '../components/work/Context';

export default function(): {
  data: MyWork[] | null;
  loading: boolean;
  refresh: Function;
} {
  const { data, loading, refresh } = useContext(MyWorkContext);

  return {
    data,
    loading,
    refresh,
  };
}
