/* eslint-disable no-console */
import React, { useContext, useState, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Client } from '@twilio/conversations';
import { IClient } from '../../../@types/chat.d';
import UserContext from '../../user/Context';
import {
  addEventListeners,
  removeEventListeners,
} from '../listeners/twilioEventListeners';
import initChat from '../services/init';
import { NewGroupChatProvider } from './ChatNewGroupContext';
import { NewOneToManyChatProvider } from './ChatNewOneToManyContext';
import { addError } from '../../../services/Messaging';

type ChatClientContext = {
  clientInstance: IClient | null;
  loading: boolean;
  loaded: boolean;
};

export const ChatClientContext = React.createContext<ChatClientContext>({
  clientInstance: null,
  loading: true,
  loaded: false,
});

export const ChatClientContextProvider = ({ children }: any) => {
  const [state, setState] = useState<ChatClientContext>({
    clientInstance: null,
    loading: true,
    loaded: false,
  });
  const { clientInstance, loaded } = state;
  const { messagingToken } = useContext(UserContext);
  const currentTokenRef = useRef<string>(messagingToken);

  useAsyncEffect(async () => {
    // If there is no client instance or the chat service is loaded, do nothing
    if (!clientInstance || loaded) return;
    // Otherwise initiate chat service with client instance
    try {
      await initChat(clientInstance as IClient);
      addEventListeners(clientInstance as IClient);

      setState(prev => ({
        ...prev,
        loading: false,
        loaded: true,
      }));
    } catch (error) {
      addError(error.message);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (!clientInstance || loaded) return;

      removeEventListeners(clientInstance as IClient);
    };
  }, [clientInstance, loaded]);

  useAsyncEffect(async () => {
    // If there is a client instance or no messaging token, do nothing
    if (clientInstance || !messagingToken) return;
    // Otherwise create client instance with messaging token
    try {
      const twilioClient = await Client.create(messagingToken);

      if (
        twilioClient.connectionState === 'denied' ||
        twilioClient.connectionState === 'error'
      ) {
        throw new Error('Could not connect to Twilio Client services');
      }

      setState(prev => ({
        ...prev,
        clientInstance: twilioClient,
      }));
    } catch (error) {
      addError(error.message);
    }
  }, [clientInstance, messagingToken]);

  useAsyncEffect(async () => {
    // If there is a client instance with an invalid token, renew it
    if (clientInstance && currentTokenRef.current !== messagingToken) {
      // Token has changed, update the token in the Twilio client

      // TODO: Handle token update failing
      await clientInstance.updateToken(messagingToken);
      currentTokenRef.current = messagingToken;
    }
  }, [clientInstance, messagingToken, currentTokenRef.current]);

  return (
    <ChatClientContext.Provider value={state}>
      <NewGroupChatProvider>
        <NewOneToManyChatProvider>{children}</NewOneToManyChatProvider>
      </NewGroupChatProvider>
    </ChatClientContext.Provider>
  );
};
