import { useEffect, useState, useCallback, useRef } from 'react';
import Api from '../components/work/Api';

type WorkType = {
  data: WorkEntity | null;
  loading: boolean;
};

export default function(
  workId: string
): {
  data: WorkEntity | null;
  loading: boolean;
  refresh: Function;
} {
  const isMounted = useRef(false);
  const [work, setWork] = useState({
    data: null,
    loading: true,
  } as WorkType);

  const updateState = useCallback((key, value) => {
    if (!isMounted.current) {
      return;
    }

    setWork(workState => ({
      ...workState,
      [key]: value,
    }));
  }, []);

  const refresh = useCallback(() => {
    isMounted.current = true;

    if (workId) {
      updateState('loading', true);

      Api.retrieve(workId)
        .then(response => {
          updateState('data', response);
        })
        .catch(() => {
          updateState('data', null);
        })
        .finally(() => {
          updateState('loading', false);
        });
    }

    return () => {
      isMounted.current = false;
    };
  }, [workId, updateState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data: work.data,
    loading: work.loading,
    refresh,
  };
}
