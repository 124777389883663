import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import Button from '../../core/Button';
import useMagicLinkLogin from '../../../hooks/useMagicLinkLogin';
import UserContext from '../Context';
import useNavigate from '../../../hooks/useNavigate';
import useForgotPassword from '../../../hooks/useForgotPassword';
import { AuthEmail } from '../formElements';
import SignupLayout from '../signup/SignupLayout';
import { DEFAULT_FORGOT_COVER_PHOTO_URL } from '../../../asset-config';

const OrBlock = () => (
  <div className="my-8 border-t border-grey-200 relative mx-12">
    <div className="absolute -mt-3 bg-white inline-block w-12 -ml-6 text-grey-500">
      or
    </div>
  </div>
);

export default () => {
  const navigate = useNavigate();
  const { goBack } = useHistory();
  const { authDetails } = useContext(UserContext);
  const {
    sendForgotPasswordEmail,
    sendingForgotPasswordEmail,
  } = useForgotPassword();
  const { sendMagicLink, sendingMagicLink } = useMagicLinkLogin();

  const { errors, formState, handleSubmit, register, trigger } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: authDetails.email,
    },
  });
  const { isValid, isSubmitted } = formState;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useAnalytics('No Password');

  const handleSendMagicLink = useCallback(() => {
    sendMagicLink(authDetails.email);
  }, [sendMagicLink, authDetails]);

  const handleSendForgotPasswordEmail = useCallback(() => {
    sendForgotPasswordEmail(authDetails.email);
  }, [sendForgotPasswordEmail, authDetails]);

  useEffect(() => {
    if (!authDetails.email.trim()) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, []);

  if (!authDetails.email) {
    return null;
  }

  return (
    <SignupLayout headerImg={DEFAULT_FORGOT_COVER_PHOTO_URL}>
      <div className="text-center">
        <h3>Your account doesn&apos;t have a password set</h3>

        <form
          onSubmit={handleSubmit(handleSendForgotPasswordEmail)}
          noValidate
          className="flex flex-col grow"
        >
          <div className="mt-4 xl:mt-10 text-left">
            <AuthEmail errors={isSubmitted ? errors : {}} register={register} />
          </div>

          <div className="text-lg font-light mt-8">
            You can set a password on your account:
          </div>
          <Button
            type="submit"
            className="mt-4"
            disabled={!isValid}
            loading={sendingForgotPasswordEmail}
            color="primary"
            fill="solid"
            fullWidth
            text="Send password email"
          />

          <OrBlock />

          <div className="text-lg font-light">
            You can log in via a magic link:
          </div>
          <Button
            type="button"
            className="mt-4"
            disabled={!isValid}
            loading={sendingMagicLink}
            color="primary"
            fill="outline"
            fullWidth
            text="Send a magic link"
            onClick={handleSendMagicLink}
          />

          <OrBlock />

          <div className="text-lg font-light">Go back to the login page:</div>
          <Button
            className="mt-4"
            fill="outline"
            fullWidth
            text="Back"
            onClick={goBack}
          />
        </form>
      </div>
    </SignupLayout>
  );
};
