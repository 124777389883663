import React from 'react';

import { Error404 } from '../error';
import EmployeeEntityColumn from './EmployeeEntityColumn';
import EmployeeSidebarColumn from './EmployeeSidebarColumn';
import ProfileCoverPhoto from './ProfileCoverPhoto';
import ProfileDetailsColumn from './ProfileDetailsColumn';
import ProfileLayout from './ProfileLayout';
import ProfilePortfolio from './portfolio/ProfilePortfolio';

type Props = {
  user: UserEntity;
};

const EmployeeProfile = ({ user }: Props) => {
  const membership = user.primaryMembershipUser?.membership;
  const userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;

  if (!membership || !user) {
    return (
      <Error404 title="Member 404" message="We couldn't find that member..." />
    );
  }

  return (
    <div className="profile profile-individual">
      <ProfileCoverPhoto url={membership.coverPhotoURL} altText={userName} />

      <ProfileLayout
        entityColumn={<EmployeeEntityColumn user={user} />}
        sidebarColumn={<EmployeeSidebarColumn user={user} />}
        detailsColumn={
          <ProfileDetailsColumn
            name={userName}
            summary={user.summary}
            about={user.about}
            roles={user.roles.map(assoc => assoc.role.name)}
            skills={user.skills.map(assoc => assoc.skill.name)}
            industries={user.industries.map(assoc => assoc.industry.name)}
            videoUrl={user.videoUrl}
            tagline={membership.tagline}
            graphicTagline={membership.graphicTagline}
          />
        }
      />
      <ProfilePortfolio membership={membership} user={user} />
    </div>
  );
};
export default EmployeeProfile;
