import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '../../core/Button';
import AuthEmail from '../formElements/AuthEmail';
import UserContext from '../Context';
import useForgotPassword from '../../../hooks/useForgotPassword';

export default () => {
  const {
    sendForgotPasswordEmail,
    sendingForgotPasswordEmail,
  } = useForgotPassword();
  const { authDetails } = useContext(UserContext);
  const { handleSubmit, errors, formState, register } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: authDetails.email,
    },
  });
  const { isValid, isSubmitted } = formState;

  const onSubmit = (values: any) => {
    const { email } = values;

    sendForgotPasswordEmail(email);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col grow"
    >
      <AuthEmail errors={isSubmitted ? errors : {}} register={register} />

      <div className="text-center md:mt-4 xl:mt-10">
        <SubmitButton
          loading={sendingForgotPasswordEmail}
          className={`btn--full ${!isValid ? 'disabled' : ''}`}
          text="Send password reset"
        />
      </div>
    </form>
  );
};
