/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormGroup from './FormGroup';
import Input from './Input';

export default React.forwardRef(
  (
    {
      name,
      label,
      labelSubtext,
      labelVisible = true,
      required = false,
      errors,
      placeholder = '',
      type = 'text',
      className = '',
      hint = '',
      children,
      ...rest
    }: any,
    ref
  ) => {
    return (
      <FormGroup
        className={className}
        hint={hint}
        name={name}
        label={label}
        labelSubtext={labelSubtext}
        labelVisible={labelVisible}
        required={required}
        errors={errors}
      >
        <Input
          {...rest}
          name={name}
          placeholder={placeholder}
          type={type}
          ref={ref}
        />
        {children}
      </FormGroup>
    );
  }
);
