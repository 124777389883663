import React from 'react';
import { LoadingRing } from '../core/Loading';
import { Error404 } from '../error';
import { SubmitReferenceContextProvider } from './context/SubmitReferenceContext';
import useReferenceRequestByToken from './hooks/useReferenceRequestByToken';
import SubmitReferenceStepper from './SubmitReferenceStepper';

interface Props {
  referenceToken: string;
}

const SubmitReferenceView = ({ referenceToken }: Props) => {
  const { isFetching, request, error } = useReferenceRequestByToken(
    referenceToken
  );

  if (error) {
    return (
      <Error404
        title="Reference 404"
        message="We couldn't find that request..."
        btnLabel="Take Me Home"
      />
    );
  }

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  return (
    <SubmitReferenceContextProvider
      referenceToken={referenceToken}
      request={request}
    >
      <SubmitReferenceStepper />
    </SubmitReferenceContextProvider>
  );
};
export default SubmitReferenceView;
