import React from 'react';
import ShareShortlistAccessUrl from './ShortlistAccessUrl';
import ShortlistCreateButton from './ShortlistCreateButton';
import ShortlistDeleteButton from './ShortlistDeleteButton';

type ShareShortlistProps = {
  publicUrl: string | null;
  isPublicUrlExpired: boolean;
  doCreatePublicUrl: () => void;
  doDeletePublicUrl: () => void;
};

const ShareShortlist = ({
  publicUrl,
  isPublicUrlExpired,
  doCreatePublicUrl,
  doDeletePublicUrl,
}: ShareShortlistProps) => {
  const publicPresentationUrl = publicUrl ?? '';
  const urlExists = !!publicUrl;
  const generateLink = !urlExists;
  const regenerateLink = urlExists && isPublicUrlExpired;
  const showGenerateLink = generateLink || regenerateLink;

  return (
    <div className="flex flex-row items-center justify-end ml-auto w-fit">
      {publicUrl && (
        <>
          <ShareShortlistAccessUrl
            publicUrl={publicPresentationUrl}
            isPublicUrlExpired={isPublicUrlExpired}
          />
        </>
      )}
      {showGenerateLink && (
        <ShortlistCreateButton
          isPublicUrlExpired
          doCreatePublicUrl={doCreatePublicUrl}
        />
      )}
      {publicUrl && !isPublicUrlExpired && (
        <ShortlistDeleteButton doDeletePublicUrl={doDeletePublicUrl} />
      )}
    </div>
  );
};

export default ShareShortlist;
