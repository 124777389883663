import React from 'react';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import useAnalytics from '../../../hooks/useAnalytics';

export default () => {
  useAnalytics('Forgot Password');

  return (
    <>
      <div className="text-center mb-8">
        <h3 className="mb-2">Forgot your password?</h3>
        <div className="text-lg">
          No problem, we&apos;ll get you back on track in no time.
        </div>
      </div>

      <ForgotPasswordForm />
    </>
  );
};
