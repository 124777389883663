/* eslint-disable no-console */
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

type ModalContext = {
  isVisible: boolean;
  isPresented: boolean;
  setPresented: (visible: boolean) => void;
  closeModal: () => void;
};

export const ModalContext = React.createContext<ModalContext>({} as any);

type Props = {
  isOpen: boolean;
  children: any;
};
export const ModalContextProvider = ({ isOpen, children }: Props) => {
  const [state, setState] = useState({
    // Indicates if the modal has finished transitioning
    // and the content is now being presented
    isPresented: false,

    // Indicates if the modal is visible (set to isOpen true),
    // used to control the transition in/out
    isVisible: false,
  });

  const setPresented = useCallback((isPresented: boolean) => {
    setState(prev => ({ ...prev, isPresented }));
  }, []);

  const setVisible = useCallback((isVisible: boolean) => {
    setState(prev => ({ ...prev, isVisible }));
  }, []);

  // Helper function to start the closing transition
  const closeModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const context = useMemo(() => {
    return {
      ...state,
      setVisible,
      setPresented,
      closeModal,
    };
  }, [state, setVisible, setPresented, closeModal]);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      setVisible(isOpen);
    });
  }, [isOpen, setVisible]);

  if (!state.isPresented && !state.isVisible && !isOpen) {
    return null;
  }

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};
