import { memoize, keyBy, pick, sortBy, debounce } from 'lodash';
import * as Sentry from '@sentry/react';
import createStore from '../../../utilities/createStore';
import { IMessage, IPaginator, isMessage } from '../../../@types/chat.d';
import chatUnreadMessagesState from './ChatUnreadMessagesState';
import MessageInstances from '../instances/MessageInstances';
import ChannelInstances from '../instances/ChannelInstances';
import { CHAT_MESSAGES_PER_PAGE } from '../../../config';
import { addError } from '../../../services/Messaging';

const keysToPick = [
  'author',
  'body',
  'attributes',
  'dateUpdated',
  'index',
  'lastUpdatedBy',
  'media',
  'memberSid',
  'sid',
  'timestamp',
  'type',
];

/**
 * ChatChannelMessagesState
 * Stores the Twilio Message objects for a given channelId.
 * A new store is created for each Channel accessed.
 */

export type ChatChannelMessagesState = {
  channelId: string;
  messages: {
    [sid: string]: IMessage;
  };
  unreadCount: number;
  anchor: number;
  hasMoreMessages: boolean;
  loading: boolean;
  loaded: boolean;
  loadingMessages: () => void;
  loadedMessages: () => void;
  loadMessages: (
    ignoreAnchor?: boolean,
    perPage?: number
  ) => Promise<IPaginator | undefined>;
  loadMessagesToSid: (sid: string) => Promise<true | undefined>;
  addMessages: (messages: IMessage[], hasMoreMessages: boolean) => void;
  addMessage: (message: IMessage) => void;
  updateMessage: (message: IMessage) => void;
  removeMessage: (message: IMessage) => void;
  updateUnreadCount: () => void;
};

const messageStoreCreator = (channelId: string) =>
  createStore<ChatChannelMessagesState>(
    (set: any, get: () => ChatChannelMessagesState) => ({
      channelId,
      messages: {},
      unreadCount: 0,
      anchor: 0,
      hasMoreMessages: false,
      loading: false,
      loaded: false,
      loadingMessages: () =>
        set({ loading: true, loaded: false }, 'loadingMessages'),
      loadedMessages: () =>
        set({ loading: false, loaded: true }, 'loadedMessages'),
      loadMessages: async (
        ignoreAnchor = false,
        perPage = CHAT_MESSAGES_PER_PAGE
        // eslint-disable-next-line consistent-return
      ) => {
        const channelInstance = ChannelInstances.select(channelId);
        const { anchor, addMessages } = get();

        try {
          if (channelInstance.status === 'joined') {
            const messages = await channelInstance.getMessages(
              perPage,
              ignoreAnchor ? undefined : anchor
            );
            const { items, hasPrevPage } = messages;

            const areValidMessages = items.every(item => isMessage(item));

            if (areValidMessages) {
              const mappedItems = items.map(item => item as IMessage);
              addMessages(mappedItems, hasPrevPage);
            }

            return messages;
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              channelInstance,
            },
          });
          addError('Cannot load messages for channel');
        }
      },
      loadMessagesToSid: async sid => {
        // eslint-disable-next-line consistent-return
        async function checkIfLoaded(): Promise<true> {
          const { messages, loadMessages } = get();
          const isMessageLoaded = messages[sid];

          if (!isMessageLoaded) {
            await loadMessages();
            return checkIfLoaded();
          }

          return true;
        }

        return checkIfLoaded();
      },
      addMessages: (messages, hasMoreMessages) => {
        const { updateUnreadCount } = get();
        const anchor = messages[0]?.index || 0;

        set((state: ChatChannelMessagesState) => {
          MessageInstances.add(messages);

          return {
            messages: {
              ...state.messages,
              ...keyBy(messages, 'sid'),
            },
            anchor,
            hasMoreMessages,
          };
        }, 'addMessages');

        updateUnreadCount();
      },
      addMessage: message => {
        const { updateUnreadCount } = get();
        MessageInstances.add([message]);

        set(
          (state: ChatChannelMessagesState) => ({
            messages: {
              ...state.messages,
              [message.sid]: pick(message, keysToPick),
            },
          }),
          'addMessage'
        );

        updateUnreadCount();
      },
      updateMessage: message => {
        MessageInstances.update([message]);

        set(
          (state: ChatChannelMessagesState) => ({
            messages: {
              ...state.messages,
              [message.sid]: pick(message, keysToPick),
            },
          }),
          'updateMessage'
        );
      },
      removeMessage: message =>
        set((state: ChatChannelMessagesState) => {
          const messages = { ...state.messages };

          delete messages[message.sid];
          MessageInstances.remove([message]);

          return {
            ...state,
            messages: {
              ...messages,
            },
          };
        }, 'removeMessage'),
      updateUnreadCount: debounce(
        () => {
          const channelInstance = ChannelInstances.select(channelId);
          const {
            lastReadMessageIndex: lastConsumedMessageIndex,
          } = channelInstance;
          const {
            messages,
            hasMoreMessages,
            loadMessages,
            loadedMessages,
          } = get();

          let count = 0;
          let hasMatchingIndex = false;

          const m = sortBy(
            messages,
            (message: IMessage) => message.dateCreated
          ).reverse();

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < m.length; i++) {
            const el = m[i];
            if (el.index === lastConsumedMessageIndex) {
              hasMatchingIndex = true;
              break;
            }
            count += 1;
          }

          if (!hasMatchingIndex && hasMoreMessages) {
            loadMessages();
            return;
          }

          loadedMessages();

          set((state: ChatChannelMessagesState) => {
            const [, api] = chatUnreadMessagesState;
            api
              .getState()
              .updateChannelUnreadCount({ [state.channelId]: count });

            return {
              unreadCount: count,
            };
          }, 'updateUnreadCount');
        },
        1000,
        {
          leading: false,
          trailing: true,
        }
      ),
    }),
    `ChatChannelMessagesState: ${channelId}`
  );

export default memoize(messageStoreCreator);
