import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Messaging from '../../../../services/Messaging';
import { Name } from '../../../membership/formElements';
import MembershipContext from '../../../membership/Context';
import useStep from '../../../stepper/hooks/useStep';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

const AgencyName = () => {
  const { membership, update: updateMembership } = useContext(
    MembershipContext
  );
  const { goToNextStep, setCanAdvance, setOnNext } = useStep();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: membership?.name,
    },
  });
  const { handleSubmit, register, errors, reset, formState } = form;
  const { isValid, isSubmitted } = formState;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AgencyName Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (loading) return;

      // if (!isDirty) {
      //   goTo('next');
      //   return;
      // }

      setLoading(true);
      updateMembership(values)
        .then(() => {
          // reset the form so it will not be 'dirty'
          reset({
            name: values.name,
          });
          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, loading, reset, updateMembership]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [setCanAdvance, isValid]);

  useEffect(() => {
    setOnNext(() => {
      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Name errors={isSubmitted ? errors : {}} register={register} />
    </form>
  );
};

export default AgencyName;
