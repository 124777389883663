import React, { useContext } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import querystringify from 'querystringify';
import useNavigate from '../hooks/useNavigate';
import Page from '../components/core/Page';
import UserContext from '../components/user/Context';
import Authenticate, { Flow } from '../components/user/views/Authenticate';

type Props = {
  match: {
    params: {
      // in the route definition, flow may not exist, that is what default is for
      // it will never be a param, thus the exclusion
      flow?: Exclude<Flow, 'default'>;
    };
  };
};

const AuthPage = ({ match: { params } }: Props) => {
  const { flow = 'default' } = params;
  const { redirectUrl } = querystringify.parse(window.location.search) as any;
  const { authDetails } = useContext(UserContext);
  const navigate = useNavigate();

  useIonViewWillEnter(() => {
    if (!authDetails.email) {
      const url = redirectUrl ? `/login?redirectUrl=${redirectUrl}` : '/login';
      navigate(url, 'none', 'replace');
    }
  }, [authDetails.email]);

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Login / Sign Up"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      {authDetails.email && <Authenticate flow={flow} />}
    </Page>
  );
};

export default AuthPage;
