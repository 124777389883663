import React, { useCallback, useContext } from 'react';
import { Api } from '../../Api';
import { addSuccess, addResponseError } from '../../services/Messaging';
import Button from '../core/Button';
import UserContext from '../user/Context';

type Props = {
  membershipId: string | null;
  isDeactivated: boolean;
};

const ToggleMembershipButton = ({ membershipId, isDeactivated }: Props) => {
  const { isRoot } = useContext(UserContext);

  const handleClick = useCallback(() => {
    const method = isDeactivated ? 'reactivate' : 'deactivate';

    Api.patch(`/root/membership/${membershipId}/${method}`, {
      membershipId,
    })
      .then(() => {
        const message = isDeactivated
          ? 'Membership Reactivated'
          : 'Membership Deactivated';

        addSuccess(message);
        window.location.reload();
      })
      .catch(addResponseError);
  }, [isDeactivated, membershipId]);

  if (!isRoot || !membershipId) {
    return null;
  }

  return (
    <div className="mb-4">
      <Button
        type="button"
        color="primary"
        fill="outline"
        onClick={handleClick}
      >
        {isDeactivated ? 'Reactivate' : 'Deactivate'} Membership
      </Button>
    </div>
  );
};
export default ToggleMembershipButton;
