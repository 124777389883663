import React, { useContext, useEffect, useState } from 'react';
import { PlanType } from '@communo-corp/shared-types';
import FreelancerProgress from './FreelancerProgress';
import UserContext from '../user/Context';
import Api from '../user/Api';
import EmployeeProgress from './EmployeeProgress';
import AgencyProgress from './AgencyProgress';
import PartnerProgress from './PartnerProgress';
import { addResponseError } from '../../services/Messaging';

declare type Props = {
  membership: MembershipEntity;
  variation?: MeterVariation;
};

const ProfileProgress = ({ membership, variation }: Props) => {
  const { id: userId, membershipAccess } = useContext(UserContext);
  const [user, setUser] = useState<UserEntity>();

  useEffect(() => {
    if (userId === undefined) {
      return;
    }

    Api.retrieve(userId)
      .then((response: UserEntity) => {
        setUser(response);
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
        return error;
      });
  }, [userId, setUser]);

  const isPartner = membership?.plan.type === PlanType.PARTNER;
  const isAgency = membership?.plan.type === PlanType.AGENCY;
  const isIndividualPlan = membership?.plan.isIndividualPlan;
  const isAdminUser = membershipAccess === 'admin';

  return (
    <div className="profile-progress-wrapper">
      {isPartner && isAdminUser && (
        <PartnerProgress variation={variation} membership={membership} />
      )}
      {isAgency && isAdminUser && (
        <AgencyProgress variation={variation} membership={membership} />
      )}
      {user && isIndividualPlan && (
        <FreelancerProgress variation={variation} user={user} />
      )}
      {user && isAgency && (
        <EmployeeProgress variation={variation} user={user} />
      )}
      {user && isPartner && (
        <EmployeeProgress variation={variation} user={user} />
      )}
    </div>
  );
};
export default ProfileProgress;
