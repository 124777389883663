import React from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import Page from '../../core/Page';
import MyCollections from '../components/MyCollections';

const MyCollectionsPage = () => {
  useAnalytics('My Collections');

  return (
    <Page
      authGuard
      permissions="canAccessCollections"
      pageTitle="My Collections"
    >
      <MyCollections />
    </Page>
  );
};

export default React.memo(MyCollectionsPage);
