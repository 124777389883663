import React from 'react';
import useForceMobile from '../../hooks/useForceMobile';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import Avatar from '../avatar/Avatar';

type Props = {
  url: string | null;
  altText: string;
};

const MembershipProfilePhoto = ({ url, altText }: Props) => {
  const forceMobile = useForceMobile();
  const nameParts = altText.toUpperCase().split(' ');
  const initials =
    (nameParts[0] || '').charAt(0) + (nameParts[1] || '').charAt(0);

  return (
    <div
      className={mc(
        'profile-columns__avatar mx-auto sm:mx-0 max-w-48 sm:max-w-56 aspect-square',
        forceMobile
      )}
    >
      <div className="w-full h-full rounded-md overflow-hidden">
        <div className="h-full w-full">
          {url ? (
            <Avatar
              avatarUrl={url}
              avatarName={altText}
              size="full"
              className="rounded-t-md rounded-b-none"
              circle={false}
              borderColor="white"
              borderWidth={4}
            />
          ) : (
            <div className="h-full bg-grey-500 text-white flex flex-col justify-center items-center text-2xl">
              {initials}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MembershipProfilePhoto;
