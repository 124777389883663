import {
  List,
  ListEntityType,
  ListItemComponent,
} from '@communo-corp/shared-types';
import { PageSize } from '@communo-corp/shared-types/dist/PageSize';

export enum OverlayStyle {
  Solid = 'solid',
  Gradient = 'gradient',
}
export interface DiscoverListItem {
  id: string;
  heading?: string;
  subHeading?: string;
  tertiaryHeading?: string;
  imageUrl: string | null;
  backgroundUrl: string | null;
  actionText?: string;
  actionUrl?: string;
  location?: string | null;
}

export interface DiscoverListSwiperOptions {
  slidesPerView?: number;
  spaceBetween?: number;
  breakpoints?: {
    [key in PageSize]?: {
      slidesPerView: number;
    };
  };
}

export interface DiscoverListProps {
  heading?: string;
  subHeading?: string;
  imageUrl?: string;
  actionUrl?: string;
  actionText?: string;
  // items: DiscoverListItem[];
  items: any[];
  swiperOptions?: DiscoverListSwiperOptions;
  listItemComponent?: ListItemComponent;
  entityType: ListEntityType;
  showNavigation: boolean;
}

export interface ListItemComponentProps {
  item: DiscoverListItem;
}

export interface DiscoverList extends List {
  name: string;
}
