import React, { useRef } from 'react';
import ImageUploader from '../../image/ImageUploader';
import FormGroup from './FormGroup';

type Props = {
  name: string;
  errors?: any;
  onChange: any; // (url: string): string | null;
  aspectRatio?: number | null;
  defaultValue?: string | null;
  setLoading?: Function;
};

const FormImageBrowser = React.forwardRef(
  (
    { onChange, name, errors, aspectRatio, setLoading = () => {} }: Props,
    ref: any
  ) => {
    const imageRef = useRef();

    const handleOnChange = (imageUrl: string) => {
      onChange(imageUrl);
    };

    return (
      <FormGroup name={name} errors={errors}>
        <ImageUploader
          aspectRatio={aspectRatio}
          dokaConfig={{
            cropMask: null,
          }}
          filePondConfig={{
            allowDrop: true,
            allowImagePreview: false,
          }}
          ref={imageRef}
          onChange={handleOnChange}
          maxFiles={12}
          showDropArea
          setLoading={setLoading}
        />
        <input name={name} type="hidden" ref={ref} />
      </FormGroup>
    );
  }
);
export default FormImageBrowser;
