import React, { useState, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { Loading } from '../../core/Loading';
import Api from '../Api';
import UserContext from '../../user/Context';
import { FormGroup } from '../../core/form';
import { addResponseError, addSuccess } from '../../../services/Messaging';

type Invite = {
  email: string;
};

export default ({ callback }: { callback?: any }) => {
  // const history = useHistory();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { membershipId, membershipAccess } = userContext;

  const { handleSubmit, register, errors, setValue } = useForm({
    shouldFocusError: true,
  });

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data: Invite = {
      email: values.email,
    };

    if (membershipAccess !== 'admin') return;

    // history.push('go-somewhere');
    if (membershipId) {
      Api.invite(membershipId, data)
        .then(() => {
          setLoading(false);
          addSuccess('Invite sent!');
          if (callback) callback();
          setValue('email', '');
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          addResponseError(error);
        });
    }
  };

  return (
    <form
      className="reset-password-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Loading isActive={loading} />

      <div className="sm:flex items-end">
        <div className="grow">
          <FormGroup errors={errors} name="email">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="name@email.com"
              className="form-input"
              defaultValue=""
              ref={register({
                required: 'Email is required',
                validate: value => validator.isEmail(value) || 'Invalid email',
              })}
            />
          </FormGroup>
        </div>
        <div className="text-center mb-6 sm:ml-4">
          <button className="btn btn--primary w-full" type="submit">
            Send Invite
          </button>
        </div>
      </div>
    </form>
  );
};
