import React from 'react';
import MemberCard from './MemberCard';

interface Props {
  membership: MembershipEntity;
}

const MembershipEntityCard = ({ membership }: Props) => {
  const id = membership.id || membership.objectID;
  const { logoURL, name, roles } = membership;
  // const { role } = roles[0];
  let nameForMembership;

  // TODO: Hack for now because roles shouldn't be empty, but they are now that we are allowing people to switch plans
  if (roles.length > 0) {
    const { role } = roles[0];
    nameForMembership = role.nameForMembership;
  }

  if (!id) {
    return null;
  }

  return (
    <MemberCard
      membershipId={id}
      logoUrl={logoURL}
      name={name}
      membershipRole={nameForMembership}
      // location={location?.friendlyName}
    />
  );
};
export default MembershipEntityCard;
