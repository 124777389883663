import React from 'react';
import Page from '../components/core/Page';
import useScript from '../components/hooks/useScript';
import GetToKnowYou from '../components/user/views/GetToKnowYou';

const GetToKnowYouPage = () => {
  const status = useScript(
    '//storage.googleapis.com/instapage-assets/conversion-external.js'
  );

  const handleIFrameLoad = () => {
    // @ts-ignore
    window.InstapageExternalConversion(21754887);

    // @ts-ignore
    window.InstapageExternalConversion(21687057);
  };

  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--trueBlack"
      hideHeader
      authGuard
    >
      <>
        <GetToKnowYou />
        {status === 'ready' ? (
          <iframe
            title="Instapage Conversion"
            className="instapage-noscrap"
            src="//d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
            name="instapage-local-storage"
            height="0px"
            width="0px"
            onLoad={handleIFrameLoad}
            style={{ display: 'block' }}
          />
        ) : null}
      </>
    </Page>
  );
};
export default GetToKnowYouPage;
