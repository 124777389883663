import React, { useContext } from 'react';
import useNavigate from '../../../hooks/useNavigate';
import Button from '../../core/Button';
import MembershipContext from '../../membership/Context';
import OnboardingLayout from '../../onboarding/OnboardingLayout';
import UserContext from '../Context';
import useClientBrandingStore from '../../../hooks/stores/useClientBrandingStore';
import useBrandConfig from '../../../hooks/useBrandConfig';

function determineBranding(memberOf: string | null) {
  switch (memberOf) {
    case 'hbcu':
      return 'hbcu';
    case 'locomotus':
      return 'locomotus';
    default:
      return 'communo';
  }
}

const Welcome = () => {
  const { user } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const navigate = useNavigate();
  const brandColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );
  const planType = membership?.plan.type;
  const memberOf =
    user?.source === 'locomotus'
      ? 'locomotus'
      : membership?.plan?.subType ?? null;
  const branding = determineBranding(memberOf);
  const brandTradeName = useBrandConfig().tradeName;

  // const portfolioUrl =
  //   planType === 'agency'
  //     ? `/members/membership/${membership?.id}`
  //     : `/members/user/${user?.id}`;

  const portfolioUrl =
    planType === 'agency'
      ? `/feed#/edit-page/completion`
      : `/feed#/edit-profile/completion`;

  return (
    <OnboardingLayout organization={branding} theme="dark">
      <div>
        {memberOf === 'locomotus' ? (
          <h1 className="h2 mb-8 text-white text-center">
            Welcome to the Locomotus network on Communo.
          </h1>
        ) : (
          <h1 className="h2 mb-8 text-white text-center">
            Welcome to {brandTradeName}, {user?.firstName}!
          </h1>
        )}
        <div className="content text-white text-center">
          <p className="text-large">
            Complete the minimum profile requirements to be visible in the app.
          </p>
        </div>

        {/* {memberOf === 'hbcu' ? (
          <Button
            color="tertiary"
            reverse
            fullWidth
            text="Select a Program"
            className="mt-8 block mx-auto max-w-xs"
            onClick={() => {
              navigate('/work', 'forward', 'replace');
            }}
          />
        ) : null} */}

        {memberOf === 'locomotus' ? (
          <Button
            color="primary"
            reverse
            fullWidth
            fill="outline"
            text="Continue"
            className="mt-8"
            onClick={() => {
              navigate(
                `/members/membership/29a7237d3f9e4e9c838dc4d873982a0e`,
                'forward',
                'replace'
              );
            }}
          />
        ) : (
          <>
            <Button
              color={
                branding === 'locomotus' || memberOf === 'hbcu'
                  ? 'tertiary'
                  : 'primary'
              }
              reverse
              fullWidth
              text="Complete your profile"
              className="mt-8 block mx-auto max-w-xs"
              onClick={() => {
                navigate(portfolioUrl, 'forward', 'replace');
              }}
              style={{
                color: brandColor ?? undefined,
              }}
            />
            <Button
              color="primary"
              reverse
              fullWidth
              fill="outline"
              text="Continue"
              className="mt-6 block mx-auto max-w-xs"
              onClick={() => {
                navigate(`/feed`, 'forward', 'replace');
              }}
            />
          </>
        )}
      </div>
    </OnboardingLayout>
  );
};
export default Welcome;
