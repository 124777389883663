import userCompletenessStore from '../state/userCompletenessStore';

const initUserCompletenessStore = async (user: UserEntity) => {
  const [, storeApi] = userCompletenessStore;
  const { evaluateUser } = storeApi.getState();

  evaluateUser(user);
};

export default initUserCompletenessStore;
