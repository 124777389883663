import { IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import React from 'react';
import Page from '../../core/Page';
import ViewCollection from '../components/ViewCollection';

const ViewCollectionPage = ({ match }: any) => {
  const { collectionId, modal } = match.params;

  return (
    <Page
      authGuard
      pageTitle="List Details"
      permissions="canAccessCollections"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/lists" />
          </IonButtons>
          <IonTitle slot="start">List Details</IonTitle>
        </IonToolbar>
      )}
    >
      <ViewCollection collectionId={collectionId} modal={modal} />
    </Page>
  );
};

export default React.memo(ViewCollectionPage);
