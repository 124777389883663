import { PlanType } from '@communo-corp/shared-types';
import { UpgradeablePermissions } from '../components/featureGate/Context';

// TODO: Refactor to have same functionality of and replace membershipContext.canAccess, e.g. 'canBatchSendMessages'

interface AccessProps {
  plan: PlanEntity;
  permission: UpgradeablePermissions;
  qualifier?: number;
}

export function isNumericPermission(permission: Permission) {
  return [
    'numSeats',
    'numRoles',
    'numUserRoles',
    'numMembershipRoles',
    'numIndustries',
    'numEvents',
    'numWorkPosts',
    'numUserIndustries',
    'numMembershipIndustries',
    'benchSizeLimit',
    'listSizeLimit',
  ].includes(permission);
}

export function getPermissionForChat(
  planType: string
): UpgradeablePermissions | null {
  if (planType === PlanType.INDIVIDUAL) {
    return 'canChatToIndividuals';
  }

  if (planType === PlanType.AGENCY) {
    return 'canChatToAgencies';
  }

  if (planType === PlanType.BRAND) {
    return 'canChatToBusinesses';
  }

  return null;
}

export function planCanAccess({
  plan,
  permission,
  qualifier = 0,
}: AccessProps): boolean {
  if (permission === 'canPostWork') {
    return plan.canPostWorkToCommuno || plan.canPostWorkToBench;
  }

  if (isNumericPermission(permission)) {
    const attributeValue = plan[permission];

    // null = unlimited
    if (attributeValue === null) {
      return true;
    }

    return attributeValue >= qualifier;
  }

  return !!plan[permission];
}
