import moment from 'moment';

export const relativeDate = (date: any) => {
  return moment(date).calendar('', {
    lastDay: '[Yesterday]',
    sameDay: 'LT',
    nextDay: '[Tomorrow]',
    lastWeek: 'MMM D',
    nextWeek: 'MMM D',
    sameElse: 'MMM D, YYYY',
  });
};

export const relativeTime = (date: any) => {
  return moment(date).calendar('', {
    lastDay: '[Yesterday], LT',
    sameDay: '[Today], LT',
    nextDay: '[Tomorrow], LT',
    lastWeek: 'ddd, h:mm A',
    nextWeek: 'ddd, h:mm A',
    sameElse: 'MMM Do, h:mm A',
  });
};

export const formatDate = (date: string) => {
  const day = moment(date).utc();
  return day.format('MMMM D, YYYY');
};

export const formatDateShortMonth = (date: string) => {
  const day = moment(date).utc();
  return day.format('MMM D, YYYY');
};

export const formatDateDate = (date: Date) => {
  const day = moment(date).utc();
  return day.format('MMMM D, YYYY');
};

export const formatDateTime = (date: string) => {
  const day = moment(date).utc();
  return day.format('MMM D, YYYY – h:mm a');
};

export const formatDateInput = (date: any) => {
  return moment(date)
    .utc()
    .format('YYYY-MM-DD');
};

export const fromNow = (date: any, withoutSuffix = false) =>
  moment(date)
    .utc()
    .fromNow(withoutSuffix);

export const toNow = (date: any) =>
  moment(date)
    .utc()
    .toNow();

// More control over the output of date compared to fromNow
export const timeAgo = (date: any) => {
  const hours = Math.abs(moment(new Date()).diff(moment(date), 'hours'));
  return hours <= 24 ? fromNow(date) : formatDate(date);
};

export const isDatePassed = (date: any) => moment().utc() > moment.utc(date);

export const timeDiff = (to: any, from: any = null, precision: any = 'hours') =>
  moment(from || new Date()).diff(moment(to), precision);
