import React from 'react';
import useChatChannelsUnreadCount from '../chat/hooks/useChatChannelsUnreadCount';

const UnreadIndicator = ({
  channelSids,
  className,
}: {
  channelSids: (string | null)[];
  className?: string;
}) => {
  const classes = ['dot-badge'];
  if (className) classes.push(className);

  const { totalUnreadCount } = useChatChannelsUnreadCount(channelSids);

  if (!totalUnreadCount) return null;

  return <span className={classes.join(' ')} />;
};

export default UnreadIndicator;
