import React from 'react';
import Modal from '../core/modal/Modal';
import RequestReferenceForm from './forms/RequestReferenceForm';

interface Props {
  isOpen: boolean;
  onClose(isOpen: boolean): void;
}

const RequestReferenceModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      title="Request a Reference"
      paddingX="8"
      width="3xl"
      isOpen={isOpen}
      onClose={() => {
        onClose(false);
      }}
    >
      <>
        <div className="text-center mb-8">
          <p className="text-base">
            Complete the form below to request a reference from a client,
            employer or colleague.
          </p>
        </div>
        <RequestReferenceForm
          onDone={() => {
            onClose(false);
          }}
          onCancel={() => {
            onClose(false);
          }}
        />
      </>
    </Modal>
  );
};
export default RequestReferenceModal;
