/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';
import { StepType } from '../Step';

type StepContext = {
  prevButtonLabel: string;
  nextButtonLabel: string;
  title?: string;
  description?: string;
  canAdvance: boolean;
  canGoBack: boolean;
  onPrev: () => boolean;
  onNext: () => boolean;
  goToPrevStep: Function;
  goToNextStep: Function;
  setLoading: (loading: boolean) => void;
  setCanAdvance: (canAdvance: boolean) => void;
  setCanGoBack: (canGoBack: boolean) => void;
  setPrevButtonLabel: (prevButtonLabel: string) => void;
  setNextButtonLabel: (nextButtonLabel: string) => void;
  setOnPrev: (onPrev: () => boolean) => void;
  setOnNext: (onNext: () => boolean) => void;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  isFirstStep: boolean;
  isLoading: boolean;
};

export const StepContext = React.createContext<StepContext>({} as any);

export const StepContextProvider = ({
  isFirstStep,
  step,
  goToPrevStep,
  goToNextStep,
  children,
}: {
  isFirstStep: boolean;
  step: StepType;
  goToPrevStep: Function;
  goToNextStep: Function;
  children: any;
}) => {
  const [state, setState] = useState({
    prevButtonLabel: step.prevButtonLabel || 'Back',
    nextButtonLabel: step.nextButtonLabel || 'Continue',
    title: step.title,
    description: step.description,
    isLoading: false,
    canAdvance: step.canAdvance || false,
    canGoBack: step.canGoBack || false,
    onPrev: () => true,
    onNext: () => true,
    goToPrevStep,
    goToNextStep,
  });

  const setCanAdvance = useCallback((canAdvance: boolean) => {
    setState(prev => ({ ...prev, canAdvance }));
  }, []);

  const setCanGoBack = useCallback((canGoBack: boolean) => {
    setState(prev => ({ ...prev, canGoBack }));
  }, []);

  const setPrevButtonLabel = useCallback((prevButtonLabel: string) => {
    setState(prev => ({ ...prev, prevButtonLabel }));
  }, []);

  const setNextButtonLabel = useCallback((nextButtonLabel: string) => {
    setState(prev => ({ ...prev, nextButtonLabel }));
  }, []);

  const setOnPrev = useCallback(onPrev => {
    setState(prev => ({ ...prev, onPrev }));
  }, []);

  const setOnNext = useCallback(onNext => {
    setState(prev => ({ ...prev, onNext }));
  }, []);

  const setLoading = useCallback((isLoading: boolean) => {
    setState(prev => ({ ...prev, isLoading }));
  }, []);

  const setTitle = useCallback((title: string) => {
    setState(prev => ({ ...prev, title }));
  }, []);

  const setDescription = useCallback((description: string) => {
    setState(prev => ({ ...prev, description }));
  }, []);

  return (
    <StepContext.Provider
      value={{
        ...state,
        isFirstStep,
        setCanAdvance,
        setCanGoBack,
        setPrevButtonLabel,
        setNextButtonLabel,
        setOnPrev,
        setOnNext,
        setLoading,
        setTitle,
        setDescription,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
