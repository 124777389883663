import React from 'react';
import { captureException } from '@sentry/react';
import { APP_ENVIRONMENT } from '../../config';

type Props = {
  children: JSX.Element | JSX.Element[];
  message?: string;
  fullscreen?: boolean; // allow slightly varied formatting
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    if (APP_ENVIRONMENT === 'development') {
      // eslint-disable-next-line no-console
      console.log('derived state', error);
    }

    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    if (APP_ENVIRONMENT === 'production') captureException(error);
    // eslint-disable-next-line no-console
    else console.error('ErrorBoundary', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, message, fullscreen } = this.props;
    const { location } = window;

    const className = fullscreen
      ? 'page-container bg-white md:bg-transparent px-12 pt-32 pb-12 md:max-w-sm min-h-full flex flex-col'
      : 'md:max-w-sm flex flex-col mx-auto';

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={className}>
          <h3 className="text-center mb-8">Aww snap!</h3>
          <div className="text-center text-sm">
            <div className="text-subdued mb-4">
              {message || 'Something went wrong :/'}
            </div>
            <div>
              <a href={location.href}>Reload</a>{' '}
              <span className="text-subdued">or</span>{' '}
              <a href="/">start over</a>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
