import React, { useContext, useState } from 'react';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import SearchContext from '../../../search/Context';
import useSearch from '../../../search/useSearch';
import UserContext from '../../Context';
import { addResponseError, addSuccess } from '../../../../services/Messaging';
import Alert from '../../../alert/Alert';

const User = ({ user, selectUser }: { user: UserEntity; selectUser: any }) => {
  const handleSelectUser = () => {
    selectUser(user);
  };

  return (
    <IonItem onClick={handleSelectUser} detail>
      <IonLabel className="px-2">
        <div className="text-sm">
          <span className="font-bold">
            {user.firstName} {user.lastName}
          </span>{' '}
          {user.company ? `- ${user.company}` : null}
        </div>
        <div className="text-sm text-subdued">{user.email}</div>
      </IonLabel>
    </IonItem>
  );
};

const Become = () => {
  const { getUsers } = useContext(SearchContext);
  const { become, refresh } = useContext(UserContext);
  const [results, handleSearch] = useSearch((params: any) =>
    getUsers(params, false)
  );
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBecomeUser = (id: string) => {
    if (loading) return;
    setLoading(true);
    become(id)
      .then(() => {
        // setLoading(false);
        refresh();
        addSuccess(
          `You are now logged in as ${selectedUser?.firstName} ${selectedUser?.lastName}.`
        );
        window.location.href = window.location.pathname;
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error, 'Unable to switch identities.');
      });
  };

  const handleSelectUser = (user: UserEntity) => {
    setSelectedUser(user || null);
    setIsOpen(!!user);
  };

  const confirmMessage =
    `You are about to log in as: <br><br> ` +
    `${selectedUser?.firstName} ${selectedUser?.lastName} <br>${selectedUser?.company} <br>${selectedUser?.email}`;

  return (
    <div className="py-8">
      <input
        type="text"
        className="form-input"
        placeholder="Search users..."
        onChange={e => handleSearch({ query: e.target.value })}
      />

      <Alert
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
          setSelectedUser(null);
        }}
        header="Switch Identity"
        message={confirmMessage}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Yes, Switch',
            cssClass: 'alert-primary-btn',
            handler: () => {
              setIsOpen(false);
              if (selectedUser?.objectID)
                handleBecomeUser(selectedUser.objectID);
            },
          },
        ]}
      />

      <IonList>
        {results?.hits?.map((user: UserEntity) => (
          <User key={user.objectID} user={user} selectUser={handleSelectUser} />
        ))}
      </IonList>
    </div>
  );
};

export default Become;
