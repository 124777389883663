import React, { useCallback, useContext, useEffect } from 'react';
import { track } from '../../../analytics';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import useStep from '../../stepper/hooks/useStep';
import UserContext from '../../user/Context';
import referenceApi from '../Api';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';
import ReferenceAvatar from '../ReferenceAvatar';

const Review = () => {
  const { form, referenceToken, leaveFor } = useContext(SubmitReferenceContext);
  const { authDetails, user, isAuthenticated } = useContext(UserContext);
  const { setOnNext, setLoading, isLoading, goToNextStep } = useStep();
  const { title, endorsement } = form.watch();

  const firstName = user?.firstName || authDetails.firstName;
  const lastName = user?.lastName || authDetails.lastName;
  const avatarUrl = user?.avatarURL || authDetails.avatarURL;
  const { oauthProviderId } = authDetails;

  const submitForm = useCallback(async () => {
    if (isLoading) {
      return;
    }

    let token = referenceToken;
    const values = form.getValues();
    setLoading(true);

    const { role } = values;
    const selectedRoleId = Array.isArray(role) ? role[0].value : role?.value;

    // If leaving a reference, we wont have a request, so create one
    if (!token && user && selectedRoleId) {
      try {
        const referenceRequest = await referenceApi.requestReference({
          roleId: selectedRoleId,
          contactEmail: user?.email,
          message: 'Left an unrequested reference',
          suppressEmail: true,
          leaveFor,
        });

        token = referenceRequest.data.referenceToken;
      } catch (error) {
        addResponseError(error);
        return;
      }
    }

    const data = {
      referenceToken: token,
      endorsement,
      ratings: [
        { attribute: 'Communication', value: values.communication },
        { attribute: 'Quality of Work', value: values.quality },
        { attribute: 'Speed', value: values.speed },
        { attribute: 'Value', value: values.value },
        { attribute: 'Collaboration', value: values.collaboration },
      ],
      providerId: oauthProviderId,
      roleWorkedWith: selectedRoleId,
    };

    try {
      const res = await referenceApi.create(data);
      setLoading(false);
      addSuccess(res.message);
      goToNextStep();
    } catch (error) {
      setLoading(false);
      addResponseError(error);
    }
  }, [
    endorsement,
    form,
    goToNextStep,
    isLoading,
    leaveFor,
    oauthProviderId,
    referenceToken,
    setLoading,
    user,
  ]);

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Review',
    });
  }, []);

  useEffect(() => {
    setOnNext(() => {
      submitForm();
      return false;
    });
  }, [setOnNext, submitForm]);

  return (
    <div>
      <div>
        <ReferenceAvatar
          title={title}
          name={`${firstName} ${lastName}`}
          avatarUrl={avatarUrl}
          // className="justify-center"
          isCommunoUser={isAuthenticated}
        />
      </div>
      <div className="mt-6">
        <div className="whitespace-pre-line">{endorsement}</div>
      </div>
    </div>
  );
};
export default Review;
