import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { useLocation } from 'react-router';
import { ZENDESK_KEY } from '../config';
import { isMobile } from '../utilities/Device';
import UserContext from '../components/user/Context';

type ZendeskWidgetContextState = {
  hideZendeskWidget(origin?: string): void;
  showZendeskWidget(): void;
  openZendeskWidget(): void;
  toggleZendeskWidget(): void;
};

const ZendeskWidgetContext = React.createContext<ZendeskWidgetContextState>({
  hideZendeskWidget() {},
  showZendeskWidget() {},
  openZendeskWidget() {},
  toggleZendeskWidget() {},
});

const blockList = [
  '/login',
  '/signup',
  '/auth',
  '/get-started',
  '/plans/freelance-member',
  '/plans/agency-guest',
  '/plans/business-guest',
  '/getting-to-know-you',
  '/getting-to-know-your-company',
  '/leave-reference',
  '/submit-reference',
  '/chat',
  '/candidates/list',
  '/beats',
  '/hbcu-signup',
  '/welcome',
  '/channels',
];

export const ZendeskWidgetProvider = ({ children }: { children: any }) => {
  const { pathname } = useLocation();

  const [zendeskWidgetIsLoaded, setZendeskWidgetIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(UserContext);
  const userFullName = user ? `${user.firstName} ${user.lastName}` : null;
  const userEmail = user ? user.email : null;

  const hideZendeskWidget = useCallback(() => {
    if (zendeskWidgetIsLoaded) {
      ZendeskAPI('webWidget', 'hide');
    }
  }, [zendeskWidgetIsLoaded]);

  const showZendeskWidget = useCallback(() => {
    if (zendeskWidgetIsLoaded) {
      ZendeskAPI('webWidget', 'show');
    }
    // eslint-disable-next-line
  }, [pathname]);

  const openZendeskWidget = useCallback(() => {
    if (isMobile) {
      ZendeskAPI('webWidget', 'show');
    }
    ZendeskAPI('webWidget', 'open');
    setIsOpen(true);
  }, []);

  const closeZendeskWidget = useCallback(() => {
    ZendeskAPI('webWidget', 'close');
    setIsOpen(false);
    if (isMobile) {
      ZendeskAPI('webWidget', 'hide');
    }
  }, []);

  const toggleZendeskWidget = useCallback(() => {
    if (zendeskWidgetIsLoaded && isOpen) {
      closeZendeskWidget();
    } else {
      openZendeskWidget();
    }
  }, [zendeskWidgetIsLoaded, openZendeskWidget, closeZendeskWidget, isOpen]);

  useEffect(() => {
    if (zendeskWidgetIsLoaded && isMobile) {
      ZendeskAPI('webWidget:on', 'close', () => {
        setIsOpen(false);
        hideZendeskWidget();
      });
    }
    if (zendeskWidgetIsLoaded) {
      ZendeskAPI('webWidget:on', 'close', () => {
        setIsOpen(false);
      });
    }
  }, [zendeskWidgetIsLoaded, hideZendeskWidget]);

  const isBlockListed = useMemo(() => {
    if (pathname === '/') {
      return true;
    }
    const foundItem = blockList.find(string => {
      return pathname.includes(string);
    });
    return !!foundItem;
  }, [pathname]);

  useEffect(() => {
    if (!isMobile && isBlockListed) {
      hideZendeskWidget();
    } else {
      showZendeskWidget();
    }
  }, [pathname, hideZendeskWidget, showZendeskWidget, isBlockListed]);

  useEffect(() => {
    if (isMobile && zendeskWidgetIsLoaded) {
      hideZendeskWidget();
    }
  }, [zendeskWidgetIsLoaded, hideZendeskWidget, pathname]);

  useEffect(() => {
    if (zendeskWidgetIsLoaded && !isBlockListed && user) {
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: userFullName,
          readOnly: true, // optional
        },
        email: {
          value: userEmail,
          readOnly: true, // optional
        },
      });
    }
  }, [isBlockListed, user, userEmail, userFullName, zendeskWidgetIsLoaded]);

  useEffect(() => {
    if (zendeskWidgetIsLoaded) {
      if (pathname.includes('work')) {
        ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
          search: 'work',
        });
      }
      if (pathname.includes('/members/user/')) {
        ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
          search: 'reference',
        });
      }
      if (pathname.includes('/lists')) {
        ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
          search: 'bench',
        });
      }
      if (pathname.includes('/feed')) {
        ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
          labels: ['FAQ'],
        });
      }
    }
  }, [zendeskWidgetIsLoaded, pathname]);

  return (
    <ZendeskWidgetContext.Provider
      value={{
        hideZendeskWidget,
        showZendeskWidget,
        openZendeskWidget,
        toggleZendeskWidget,
      }}
    >
      {children}
      <Zendesk
        defer // Produces a warning. will be fixed by https://github.com/B3nnyL/react-zendesk/pull/24
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => setZendeskWidgetIsLoaded(true)}
      />
    </ZendeskWidgetContext.Provider>
  );
};

export const ZendeskWidgetContextConsumer = ZendeskWidgetContext.Consumer;

export default ZendeskWidgetContext;
