import React from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import useForceMobile from '../../../hooks/useForceMobile';
import cloudinary from '../../../utilities/Cloudinary';
import { mediaClasses as mc } from '../../../utilities/MatchMedia';
import Image from '../../core/Image';

type PortfolioItemProps = {
  item: PortfolioEntity;
  onClick: (item: PortfolioEntity) => void;
};
const PortfolioItem = ({ item, onClick }: PortfolioItemProps) => {
  const { track } = useAnalytics();
  const forceMobile = useForceMobile();

  const openModal = (e: any) => {
    track('Portfolio Item Clicked', {
      itemId: item.id,
    });
    e.preventDefault();
    e.stopPropagation();

    onClick(item);
  };

  let coverPhoto = item.headerPhotoURL;
  if (!coverPhoto && item.imageURLs && item.imageURLs.length > 0) {
    [coverPhoto] = item.imageURLs;
  }
  if (!coverPhoto) {
    return null;
  }

  const description = item.title ?? item.description ?? '';

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className={mc(
          'block relative rounded-md text-left mb-8 md:mb-0',
          forceMobile
        )}
      >
        <Image
          src={cloudinary(coverPhoto, ['w_960', 'q_70'])}
          alt={description}
          className="aspect-video rounded-md overflow-hidden"
          lazy
        />
        <div
          className={mc(
            'mt-2 md:mt-0 absolute left-0 right-0 truncate md:inset-0 inset-gradient rounded-b-md flex flex-auto flex-col justify-end md:opacity-0 transition duration-500 ease-in-out transform hover:opacity-100',
            forceMobile
          )}
        >
          <p
            className={mc(
              'text-base xl:text-lg leading-snug font-semibold text-black truncate md:text-white pb-0 md:p-4 xl:p-6',
              forceMobile
            )}
          >
            {description}
          </p>
        </div>
      </button>
    </>
  );
};

export default PortfolioItem;
