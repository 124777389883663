import React, { useMemo } from 'react';
import Account from './Account';
import Primary from './Primary';
import Secondary from './Secondary';

type Props = {
  name: 'account' | 'primary' | 'secondary';
};

const menu = {
  account: Account,
  primary: Primary,
  secondary: Secondary,
};

// usage example: <Menu name="account" />
export default ({ name }: Props) => {
  const Menu = useMemo(() => menu[name], [name]);
  return <Menu />;
};
