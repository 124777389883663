import React, { useCallback, useMemo, useState } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import ViewRecommendationsModal from '../../recommendations/ViewRecommendationsModal';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

const getRecommendationsStatus = (
  criteria: ScoreMetricsCriteria
): MetricStatus => {
  if (criteria.multipleRecommendations) {
    return 'complete';
  }

  return null;
};

type Props = {
  scoreMetrics: ScoreMetrics;
};

const RecommendationsMetric = ({ scoreMetrics }: Props) => {
  const { id } = useCurrentUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const { criteria } = scoreMetrics;
  const { multipleRecommendations, oneRecommendation } = criteria;

  const labelSubtext = useMemo(() => {
    if (multipleRecommendations) {
      return '(2/2)';
    }

    if (oneRecommendation === 1) {
      return '(1/2)';
    }

    return '(0/2)';
  }, [multipleRecommendations, oneRecommendation]);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  if (!id) {
    return null;
  }

  return (
    <>
      <Metric
        label="Recommendations"
        onClick={handleClick}
        labelSubtext={labelSubtext}
        status={getRecommendationsStatus(criteria)}
      />
      {isModalOpen ? (
        <ViewRecommendationsModal
          userId={id}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default RecommendationsMetric;
