import { useState, useReducer, useCallback, useEffect, useMemo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';
// eslint-disable-next-line no-unused-vars
import { URLFilterOption } from '../filter/options';
import useFilter from '../filter/useFilter';
import useSearch from './useSearch';
import { usePullToRefreshContext } from '../../hooks/usePullToRefresh';
import {
  buildSearchQueries,
  buildAlgoliaParams,
} from '../../utilities/Algolia';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        page: 0,
        filters: action.payload.filters,
        filterLoaded: true,
      };
    case 'INCREMENT_PAGE':
      return {
        ...state,
        page: state.page + 1,
      };
    case 'RESET_PAGE':
      return {
        ...state,
        page: 0,
      };
    default:
      return state;
  }
}

type Props = {
  getFilterOptions: (results: {
    [key: string]: SearchResponse;
  }) => URLFilterOption[];
  searchFunction: Function;
  facetQueryOverrides: any;
  extraQueries?: any;
  shouldSearch?: boolean;
  prefixes?: string[];
};

type Return = {
  rawResults: {
    [key: string]: SearchResponse;
  };
  allResults: any[];
  searchResults: SearchResponse;
  onLoadMore: Function;
  readyToSearch: boolean;
  filterContext: any;
};

function useSearchProvider({
  getFilterOptions,
  searchFunction,
  facetQueryOverrides,
  extraQueries = {},
  shouldSearch = true,
}: Props): Return {
  const [allResults, setAllResults] = useState<any>([]);
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    filters: {},
    filterLoaded: false,
  });

  const {
    setRefreshHandler,
    onRefreshFinish,
    pullToRefreshToken,
    resetPullToRefreshToken,
  } = usePullToRefreshContext();

  const [results, handleSearch] = useSearch(searchFunction);
  const searchResults = results?.search;

  const filterOptions = useMemo(() => {
    return getFilterOptions(results);
  }, [getFilterOptions, results]);
  const filterContext = useFilter({ options: filterOptions });

  const readyToSearch = state.filterLoaded && shouldSearch;

  const searchQueries = useMemo(() => {
    const facetAlgoliaQueries = buildSearchQueries({
      filterOptions,
      filterValues: state.filters,
      page: state.page,
      ...facetQueryOverrides,
    });

    const extraAlgoliaQueries = Object.keys(extraQueries).reduce(
      (carry: any, key: string) => {
        // eslint-disable-next-line no-param-reassign
        carry[key] = buildAlgoliaParams({
          filterOptions,
          filterValues: state.filters,
          page: 0,
          excludeFilter: null,
          ...extraQueries[key],
        });
        return carry;
      },
      []
    );

    return {
      ...facetAlgoliaQueries,
      ...extraAlgoliaQueries,
    };
  }, [state, filterOptions, facetQueryOverrides, extraQueries]);

  useEffect(() => {
    if (searchResults) {
      setAllResults((prev: any) => {
        const hits = searchResults.hits || [];
        const nextResults =
          searchResults.page === 0 ? hits : [...prev, ...hits];

        return nextResults;
      });
    }
  }, [searchResults]);

  useDeepCompareEffect(() => {
    if (readyToSearch) {
      handleSearch(searchQueries, onRefreshFinish);
    }
  }, [
    handleSearch,
    searchQueries,
    readyToSearch,
    onRefreshFinish,
    pullToRefreshToken,
  ]);

  useDeepCompareEffect(() => {
    if (filterContext.paramsLoaded) {
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: {
          filters: filterContext.values,
        },
      });
    }
  }, [filterContext.values, filterContext.paramsLoaded]);

  const onLoadMore = useCallback(() => {
    dispatch({
      type: 'INCREMENT_PAGE',
    });
  }, []);

  useEffect(() => {
    setRefreshHandler(() => {
      dispatch({
        type: 'RESET_PAGE',
      });
      resetPullToRefreshToken();
    });
    // eslint-disable-next-line
  }, []);

  return {
    rawResults: results,
    allResults,
    searchResults,
    onLoadMore,
    readyToSearch,
    filterContext,
  };
}
export default useSearchProvider;
