import {
  CollectionSummary,
  CollectionUserSummary,
} from '@communo-corp/shared-types';
import { useIonViewDidEnter } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import pluralize from 'pluralize';
import Button from '../../core/Button';
import { LoadingBalls } from '../../core/Loading';
import { Link } from '../../core/router';
import BenchMembersIcon from '../../icons/BenchMembersIcon';
import useAccessibleCollections from '../hooks/useAccessibleCollections';
import CreateCollectionModal from './CreateCollectionModal';
import { thousands } from '../../../utilities/Number';
import cloudinary from '../../../utilities/Cloudinary';
import Image from '../../core/Image';

type CollectionItemProps = {
  collection: CollectionSummary;
};

const UserImage = ({
  user,
  className = '',
}: {
  user?: CollectionUserSummary;
  className?: string;
}) => {
  const url = user ? cloudinary(user?.avatarURL, ['w_600', 'h_600']) : null;
  const initials =
    (user?.firstName?.toUpperCase() ?? '').charAt(0) +
    (user?.lastName?.toUpperCase() ?? '').charAt(0);

  let content = null;
  if (user && url) {
    content = (
      <Image
        src={url}
        className="object-cover w-full h-full rounded-md"
        alt={`${user.firstName} ${user.lastName}`}
      />
    );
  } else if (user) {
    content = (
      <span className="object-cover w-full h-full flex items-center justify-center text-4xl text-white">
        {initials || 'UU'}
      </span>
    );
  }

  return (
    <div
      className={`${className} relative rounded-md bg-grey-500 overflow-hidden`}
    >
      {content}
    </div>
  );
};

const BenchItem = ({ collection }: CollectionItemProps) => {
  const { recentUsers } = collection;

  return (
    <Link
      to={`/lists/${collection.id}`}
      className="block xl:grid xl:grid-cols-3 grid-rows-1 xl:gap-2"
    >
      <div className="xl:col-span-1 xl:col-start-3 rounded-md bg-red-900 text-white text-center flex flex-col items-center justify-center px-2 py-8 xl:py-32">
        <BenchMembersIcon className="inline-block h-24 w-24 text-black" />
        <h3 className="mt-4 font-black text-7xl">{collection.name}</h3>
        <p className="text-lg">{collection.description}</p>
        <p className="text-black font-bold text-2xl mt-4">
          {thousands(collection.userCount)}{' '}
          {pluralize('Member', collection.userCount)}
        </p>
      </div>
      <div className="h-96 xl:h-full mt-2 xl:mt-0 col-span-3 xl:col-span-2 row-start-2 xl:row-start-1 row-span-2 xl:row-span-1 grid grid-cols-3 sm:grid-cols-4 2xl:grid-cols-6 grid-rows-2 sm:grid-rows-3 gap-2">
        <UserImage
          user={recentUsers[0]}
          className="col-span-1 sm:col-span-2 2xl:col-span-3 row-span-1 sm:row-span-2 2xl:row-span-3"
        />
        <UserImage
          user={recentUsers[1]}
          className="col-span-1 row-span-1 sm:col-span-2 sm:row-span-2"
        />
        <UserImage user={recentUsers[2]} />
        <UserImage user={recentUsers[3]} />
        <UserImage user={recentUsers[4]} />
        <UserImage user={recentUsers[5]} />
        <UserImage user={recentUsers[6]} className="hidden 2xl:block" />
      </div>
    </Link>
  );
};

const ListItem = ({ collection }: CollectionItemProps) => {
  const { recentUsers } = collection;

  return (
    <Link to={`/lists/${collection.id}`} className="mt-16 sm:mt-0 text-center">
      <div className="h-84 grid grid-cols-3 grid-rows-2 gap-2">
        <UserImage user={recentUsers[0]} className="col-span-2 row-span-2" />
        <UserImage user={recentUsers[1]} />
        <UserImage user={recentUsers[2]} />
      </div>
      <h3 className="mt-4 font-font text-xl truncate">{collection.name}</h3>
      <p>{collection.description}</p>
      <p className="font-bold text-lg mt-2">
        {thousands(collection.userCount)}{' '}
        {pluralize('Member', collection.userCount)}
      </p>
    </Link>
  );
};

const MyCollections = () => {
  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const { isLoading, collections, refetch } = useAccessibleCollections();

  const closeCreateCollectionModal = useCallback(() => {
    refetch();
    setCreateCollectionOpen(false);
  }, [refetch]);

  useIonViewDidEnter(() => {
    refetch();
  }, []);

  const { benches, lists } = collections.reduce(
    (carry: any, collection: any) => {
      if (collection.type === 'bench') {
        carry.benches.push(collection);
        return carry;
      }

      carry.lists.push(collection);
      return carry;
    },
    {
      benches: [],
      lists: [],
    }
  );

  return (
    <div className="mt-8 px-4">
      <div className="flex justify-between">
        <h2>My Lists</h2>
        <Button
          text="New List"
          fill="outline"
          icon="add"
          size="sm"
          onClick={() => setCreateCollectionOpen(true)}
        />
      </div>

      {isLoading ? (
        <LoadingBalls isActive />
      ) : (
        <>
          <div className="mt-8">
            {benches.map((collection: CollectionSummary) => (
              <BenchItem key={collection.id} collection={collection} />
            ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-0 sm:gap-16 sm:mt-16">
            {lists.map((collection: CollectionSummary) => (
              <ListItem key={collection.id} collection={collection} />
            ))}
          </div>
        </>
      )}

      <CreateCollectionModal
        isOpen={createCollectionOpen}
        onClose={closeCreateCollectionModal}
      />
    </div>
  );
};
export default MyCollections;
