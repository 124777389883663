/* eslint-disable no-console */
import channelStoreCreator from '../state/ChatChannelState';
import { IChannel, IMember, isMember } from '../../../@types/chat.d';
import loadChannelMembersToState from './loadChannelMembersToState';
import loadMembersUserToState from './loadMembersUserToState';
import loadChannelMessagesToState from './loadChannelMessagesToState';

const loadChannelsToState = async (channels: IChannel[]) =>
  Promise.all(
    channels.map(async channel => {
      const [, channelApi] = channelStoreCreator(channel.sid);
      const { setChannel } = channelApi.getState();

      setChannel(channel);

      // Get members for each channel
      const members = await loadChannelMembersToState(channel);

      if (members !== null && members.length > 0) {
        const filteredMembers = members.filter(member => isMember(member));
        const mappedMembers = filteredMembers.map(x => x as IMember);

        // Get user for each member (check if user already exists in state)
        await loadMembersUserToState(mappedMembers);
      }
      // Get last x messages
      await loadChannelMessagesToState(channel);
    })
  );

export default loadChannelsToState;
