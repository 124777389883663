import React from 'react';
import { HashRoute } from '../Routes';
import List from '../../portfolio/List';
import Create from '../../portfolio/Create';
import Edit from '../../portfolio/Edit';

interface Props {
  user: UserEntity;
  refresh: Function;
}

// eslint-disable-next-line no-unused-vars
export default ({ user, refresh }: Props) => {
  const membership = user.primaryMembershipUser?.membership;

  return (
    <>
      <HashRoute
        hash="#/edit-profile/portfolio/edit/:projectId"
        component={Edit}
        membership={membership}
        refresh={refresh}
      />
      <HashRoute
        hash="#/edit-profile/portfolio/create"
        component={Create}
        membership={membership}
        refresh={refresh}
      />
      <HashRoute
        hash="#/edit-profile/portfolio"
        component={List}
        membership={membership}
        refresh={refresh}
      />
    </>
  );
};
