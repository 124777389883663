import { CollectionUser } from '@communo-corp/shared-types';
import { useInfiniteQuery, QueryFunctionContext } from '@tanstack/react-query';
import useUserAllowList from '../../user/hooks/useUserAllowList';
import Api from '../Api';

type Result = {
  data: CollectionUser[];
  page: number;
  perPage: number;
};

const userCollectionsQueryKey = (collectionId: string, perPage: number) => {
  return ['collection-users', collectionId, perPage] as const;
};

const fetchUserCollections = async ({
  queryKey: [, collectionId, perPage],
  pageParam = 0,
}: QueryFunctionContext<
  ReturnType<typeof userCollectionsQueryKey>,
  number
>) => {
  const response = await Api.listCollectionUsers(
    collectionId,
    pageParam,
    perPage
  );

  return response;
};

type UseCollectionUsersProps = {
  users: Result[];
  isLoading: boolean;
  fetchMoreUsers: Function;
  clearUsers: Function;
};

export default function useCollectionUsers(
  collectionId: string,
  perPage: number = 100
): UseCollectionUsersProps {
  const { invalidateAllowList } = useUserAllowList();
  const { data, isFetching, fetchNextPage, remove } = useInfiniteQuery(
    ['collection-users', collectionId, perPage],
    fetchUserCollections,
    {
      refetchOnMount: 'always',
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      getNextPageParam: lastPage => lastPage.page + 1,
      onSuccess: () => {
        /**
         * Invalidate the allow list cache. Dont need to force a refetch yet as they might not
         * interact with the user(s) but if they do at least we know they will get a new allow list
         * to check permissions.
         */
        invalidateAllowList();
      },
    }
  );

  return {
    users: data?.pages ?? [],
    isLoading: isFetching,
    fetchMoreUsers: fetchNextPage,
    clearUsers: remove,
  };
}
