import React from 'react';
import classnames from 'classnames';
import Avatar from '../avatar/Avatar';

interface Props {
  children: JSX.Element | JSX.Element[];
  avatarName?: string | null;
  avatarUrl?: string | null;
  isIndividualPlan: boolean;
}

const ScoreCardLayout = ({
  children,
  avatarName,
  avatarUrl,
  isIndividualPlan,
}: Props) => {
  return (
    <div className="py-6 px-8 border border-grey-200 rounded-lg">
      <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-8">
        <div>
          <div
            className={classnames('block relative rounded-md overflow-hidden', {
              'aspect-3/4': isIndividualPlan,
              'aspect-square': !isIndividualPlan,
            })}
          >
            <div className="absolute inset-0 w-full h-full ">
              <Avatar
                avatarUrl={avatarUrl}
                avatarName={avatarName}
                size="full"
                circle={false}
                square={!isIndividualPlan}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
export default ScoreCardLayout;
