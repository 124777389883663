import React from 'react';
import RichText from '../core/RichText';

type Props = {
  application: ApplicationEntity;
  membership: MembershipEntity | undefined;
  planType: PlanType | undefined;
};

const ApplicationBody = ({ application, membership, planType }: Props) => {
  const { user } = application;
  const { firstName } = user;
  return (
    <div className="flex flex-col gap-0">
      <div className="text-lg font-bold mb-4">
        {firstName}{' '}
        {planType === 'agency' && membership && `@ ${membership.name}`} says:
      </div>
      <div className="-mt-4">
        <RichText html={application.body} classVariant="ql-editor" />
      </div>
    </div>
  );
};

export default ApplicationBody;
