import React from 'react';

type Props = {
  title: string;
  subtitle: string;
  buttonLabel: string;
  handleClick: () => void;
  name: string;
};

const Empty = ({ title, subtitle, buttonLabel, handleClick, name }: Props) => {
  return (
    <div className={`${name}__empty`}>
      <img
        className="mt-6 mb-4 mx-auto"
        alt=""
        width="144"
        height="144"
        src="/assets/communo/types/megaphone.svg"
      />
      <div>
        <h3
          className={`${name}__empty-inside h3 font-normal text-black mb-2 text-center`}
        >
          {title}
        </h3>
        <p
          className={`${name}__empty-inside font-normal text-black mb-2 text-center`}
        >
          {subtitle}
        </p>
      </div>
      <div className="text-center mb-10">
        <button
          className="btn btn--full btn--sm btn--tertiary"
          type="button"
          onClick={() => handleClick()}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default React.memo(Empty);
