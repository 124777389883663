import React from 'react';
import ScoreMetrics from './ScoreMetrics';
import { ScoreCardProps } from './CandidateScore';

const StudentCandidateScoreCard = ({
  isUser,
  work,
  scoreMetrics,
}: ScoreCardProps) => {
  return (
    <>
      <div>
        <ScoreMetrics
          isUser={isUser}
          work={work}
          scoreMetrics={scoreMetrics}
          showRoleMetric={false}
        />
      </div>
    </>
  );
};
export default StudentCandidateScoreCard;
