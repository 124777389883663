import { SetState, GetState } from 'zustand';
import createStore from '../../../utilities/createStore';
import { MetricStatus } from '../../candidateScore/MetricStatus';

export type UserProfileAttribute = keyof UserEntity | 'coverPhotoURL';

interface UserCompletenessStore {
  loaded: boolean;
  evaluation: Record<string, MetricStatus>;
  userId: string;
  evaluateUser(user: UserEntity): void;
  userCompleted(attribute: UserProfileAttribute): boolean;
  userCompletedAll(attribute: UserProfileAttribute[]): boolean;
  userCompletedAny(attributes: UserProfileAttribute[]): boolean;
  getStatusFor(attribute: UserProfileAttribute): MetricStatus;
  getStatusesFor(attributes: UserProfileAttribute[]): MetricStatus[];
  getStatusForAll(attributes: UserProfileAttribute[]): MetricStatus;
}

interface Criteria {
  key: string;
  getStatus(prop: {
    user: UserEntity;
    membership: MembershipEntity | null;
  }): MetricStatus;
}

const criteria: Criteria[] = [
  {
    key: 'avatarURL',
    getStatus: ({ user: { avatarURL } }) => (avatarURL ? 'complete' : null),
  },
  {
    key: 'summary',
    getStatus: ({ user: { summary } }) =>
      summary && summary.length > 0 ? 'complete' : null,
  },
  {
    key: 'about',
    getStatus: ({ user: { about } }) => (about ? 'complete' : null),
  },
  {
    key: 'socialLinks',
    getStatus: ({ user: { socialLinks } }) => {
      const links = Object.values(socialLinks).filter(link => {
        // Results in false if null or empty string
        return link;
      });

      return links.length > 0 ? 'complete' : null;
    },
  },
  {
    key: 'title',
    getStatus: ({ user: { title } }) => (title ? 'complete' : null),
  },
  {
    key: 'skills',
    getStatus: ({ user: { skills } }) =>
      skills.length > 0 ? 'complete' : null,
  },
  {
    key: 'industries',
    getStatus: ({ user: { industries } }) =>
      industries.length > 0 ? 'complete' : null,
  },
  {
    key: 'experience',
    getStatus: ({ user: { experience } }) => (experience ? 'complete' : null),
  },
  {
    key: 'rate',
    getStatus: ({ user: { rate } }) => (rate ? 'complete' : null),
  },
  {
    key: 'portfolioItems',
    getStatus: ({ user: { portfolioItems } }) => {
      // Leaving this logic here for now just in case
      // const isIndividualPlan = membership?.plan.isIndividualPlan;
      // const membershipPortfolioItems = membership?.portfolioItems ?? [];
      // const items = isIndividualPlan
      //   ? portfolioItems
      //   : membershipPortfolioItems;
      const items = portfolioItems;

      if (items.length >= 3) {
        return 'complete';
      }

      if (items.length > 0) {
        return 'partial';
      }

      return null;
    },
  },
  {
    key: 'references',
    getStatus: ({ user: { userReferenceRequest } }) => {
      const completed = userReferenceRequest?.filter(request => {
        const { referenceRequest } = request;
        const { reference } = referenceRequest;
        return referenceRequest.status === 'completed' && reference?.visibility;
      });

      if (!completed) {
        return null;
      }

      if (completed.length >= 3) {
        return 'complete';
      }

      if (completed.length > 0) {
        return 'partial';
      }

      return null;
    },
  },
  {
    key: 'education',
    getStatus: ({ user: { education } }) => {
      if (education) {
        return education.length > 0 ? 'complete' : null;
      }

      return null;
    },
  },
  {
    key: 'videoUrl',
    getStatus: ({ user: { videoUrl } }) => (videoUrl ? 'complete' : null),
  },
  {
    key: 'coverPhotoURL',
    getStatus: ({ membership }) => {
      const coverPhotoURL = membership?.coverPhotoURL;

      if (coverPhotoURL) {
        return coverPhotoURL ? 'complete' : null;
      }

      return null;
    },
  },
];

const userCompletenessStore = createStore<UserCompletenessStore>(
  (
    set: SetState<UserCompletenessStore>,
    get: GetState<UserCompletenessStore>
  ) => ({
    loaded: false,
    // An array of the results from evaluating the user properties
    evaluation: {},
    userId: '',
    evaluateUser: (user: UserEntity) => {
      const membership = user.primaryMembershipUser?.membership ?? null;

      const results = criteria.reduce((acc, current) => {
        const { key, getStatus } = current;

        acc[key] = getStatus({ user, membership });

        return acc;
      }, {} as any);

      set(() => ({
        evaluation: results,
        loaded: true,
        userId: user.id,
      }));
    },
    // Check if the user has completed the given field
    userCompleted: (attribute: UserProfileAttribute): boolean => {
      const { evaluation } = get();

      if (Object.prototype.hasOwnProperty.call(evaluation, attribute)) {
        return evaluation[attribute] === 'complete';
      }

      return false;
    },
    userCompletedAll: (attributes: UserProfileAttribute[]) => {
      const { userCompleted } = get();

      return attributes.every(attribute => userCompleted(attribute));
    },
    userCompletedAny: (attributes: UserProfileAttribute[]) => {
      const { userCompleted } = get();
      const foundIndex = attributes.findIndex(attribute =>
        userCompleted(attribute)
      );

      return foundIndex >= 0;
    },
    getStatusFor: (attribute: UserProfileAttribute) => {
      const { evaluation } = get();

      if (Object.prototype.hasOwnProperty.call(evaluation, attribute)) {
        return evaluation[attribute];
      }

      return null;
    },
    getStatusesFor: (attributes: UserProfileAttribute[]) => {
      const { getStatusFor } = get();

      return attributes.map(attribute => {
        return getStatusFor(attribute);
      });
    },
    getStatusForAll: (attributes: UserProfileAttribute[]) => {
      const { getStatusFor } = get();
      // array of true/false
      const statuses = attributes.map(attribute => getStatusFor(attribute));

      // need statuses.length because .every() will return true for empty array
      if (statuses.length && statuses.every(status => status === 'complete')) {
        return 'complete';
      }

      if (
        statuses.some(status => status === 'partial' || status === 'complete')
      ) {
        return 'partial';
      }

      return null;
    },
  }),
  'userCompletenessStore'
);

export default userCompletenessStore;
