import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import MembershipContext from '../../membership/Context';
import { FormGroup, FormRadio } from '../../core/form';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Button from '../../core/Button';
import useFeatureGate from '../../../hooks/useFeatureGate';

const Form = ({ isSearchable, update }: any) => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      isSearchable: isSearchable ? 'true' : 'false',
    },
  });
  const { handleSubmit, register, getValues } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    update({
      isSearchable: values.isSearchable === 'true',
    })
      .then((response: MembershipEntity) => {
        addSuccess('Membership updated!');
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  const autoSave = () => {
    const formValues = getValues();
    onSubmit(formValues);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup
          label="Set company page to:"
          hint="This will hide your company page and all attached members from appearing in search results."
          name="isSearchable"
          className="mt-4"
        >
          <FormRadio
            name="isSearchable"
            label="Visible"
            value="true"
            ref={register}
            onChange={autoSave}
          />
          <FormRadio
            name="isSearchable"
            label="Hidden"
            value="false"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>
      </form>
    </>
  );
};

const NeedsUpgrade = () => {
  const { openUpgradeFlowFor } = useFeatureGate();

  return (
    <div>
      <p className="my-4">
        In order to hide your company page and all attached members from
        appearing in search results you need to upgrade your plan.
      </p>
      <Button
        color="primary"
        onClick={() => {
          openUpgradeFlowFor('canHideFromSearch');
        }}
      >
        Upgrade
      </Button>
    </div>
  );
};

const VisibilityForm = () => {
  const { membership, update, plan } = useContext(MembershipContext);
  const canHideFromSearch = plan?.canHideFromSearch;

  return (
    <>
      <h4 className="text-2xl font-normal">Company Settings</h4>
      {canHideFromSearch ? (
        <Form isSearchable={membership?.isSearchable ?? true} update={update} />
      ) : (
        <NeedsUpgrade />
      )}
    </>
  );
};

export default VisibilityForm;
