import React from 'react';
import ChannelsView from '../components/channels/View';
import View, { ViewContent } from '../components/core/View';
import Header from '../components/core/Header';
import { isMobile } from '../utilities/Device';

const Channels = () => {
  return (
    <View className="" authGuard pageTitle="Channels">
      {!isMobile ? <Header showAuthLinks /> : null}
      <ViewContent layout="channel" className="page--channels">
        <ChannelsView />
      </ViewContent>
    </View>
  );
};

export default Channels;
