import React from 'react';
import cloudinary from '../../utilities/Cloudinary';
import Image from '../core/Image';
import { Link } from '../core/router';

interface Props {
  name: string;
  membershipId: string;
  logoUrl?: string;
  location?: string | null;
  membershipRole?: string;
}

const MemberCard = ({
  membershipId,
  name,
  logoUrl,
  location,
  membershipRole,
}: Props) => {
  const nameParts = name.toUpperCase().split(' ');
  const initials =
    (nameParts[0] || '').charAt(0) + (nameParts[1] || '').charAt(0);

  return (
    <Link to={`/members/membership/${membershipId}`} className="block h-full">
      <div className="flex flex-col justify-between w-full rounded-md overflow-hidden h-full">
        <div className="aspect-square w-full h-full bg-white">
          {logoUrl ? (
            <Image src={cloudinary(logoUrl, ['w_800', 'h_800'])} alt={name} />
          ) : (
            <div className="w-full h-full bg-grey-500 text-white flex flex-col justify-center items-center text-2xl">
              {initials}
            </div>
          )}
        </div>

        <div className="text-left bg-white px-6 py-4 shrink-0 grow mt-05">
          <h5 className="w-full whitespace-nowrap truncate">{name}</h5>
          {membershipRole ? (
            <div className="text-base font-semibold w-full whitespace-nowrap truncate">
              {membershipRole}
            </div>
          ) : null}
          {location ? (
            <div className="text-small w-full whitespace-nowrap truncate">
              {location}
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};
export default React.memo(MemberCard);
