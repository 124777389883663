import React from 'react';
import { PerkBundle } from '@communo-corp/shared-types';
import cloudinary from '../../utilities/Cloudinary';

interface Props {
  bundle: PerkBundle;
}

const BundleDetailsHeader = ({ bundle }: Props) => {
  const {
    horizontalHeaderImageUrl,
    name: bundleName,
    tagline,
    description,
    usesLightTheme,
    membership,
    featuredLogoImageUrl,
  } = bundle;

  let isPartnerBundle;
  if (membership) {
    isPartnerBundle = membership.name || false; // Collections sometimes have a membership and membership ID but null name for some reason
  } else {
    isPartnerBundle = false;
  }

  const name = membership ? membership.name : bundleName;

  return (
    <div className="relative sm:aspect-8/5 md:aspect-2/1 4xl:aspect-3/1 bg-grey-300">
      <img
        src={cloudinary(horizontalHeaderImageUrl, [
          'w_1300',
          'h_430',
          'g_auto',
        ])}
        alt={name || ''}
        className="absolute inset-0 sm:w-full h-full object-cover"
      />
      <div className="relative sm:absolute inset-0">
        <div
          className={`py-8 px-10 pt-20 md:px-0 md:ml-12 md:pt-8 4xl:ml-16 5xl:ml-24 max-w-screen-xs ${
            usesLightTheme ? 'text-white' : 'text-black'
          }`}
        >
          {isPartnerBundle && featuredLogoImageUrl && (
            <img
              src={featuredLogoImageUrl}
              className="max-w-16 xl:max-w-20 3xl:max-w-24 h-auto"
              alt={name || ''}
            />
          )}
          <div className="text-2xl mt-1 xl:mt-2">{name}</div>
          {/* <h3 className="h4 md:h3 mb-4">{name}</h3> */}
          <h3 className="h4 2xl:h3 mt-3 2xl:mt-6">{tagline}</h3>
          <div className="text-base 2xl:text-large mt-2 xl:mt-4">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BundleDetailsHeader;
