/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useLayoutEffect, forwardRef } from 'react';
import SwiperLib from 'swiper';

import 'swiper/css/swiper.css';
import { isPlatform } from '@ionic/core';

const isDesktop = isPlatform('desktop');

export type Breakpoints = {
  [num in number]: {
    slidesPerView: number;
  };
};

type Props = {
  items: any[];
  paginate?: boolean;
  full?: boolean;
  offset?: Function;
  swiperConfig?: object;
  navigation?: boolean;
  className?: string;
  onReady?: Function;
  eventHandlers?: object;
};
const Swiper = forwardRef(
  (
    {
      items = [],
      paginate = false,
      full,
      offset = () => null,
      swiperConfig = {},
      navigation = false,
      className = '',
      onReady = () => {},
      eventHandlers = {},
    }: Props,
    parentInstanceRef?: any
  ) => {
    const swiperElRef: any = useRef();
    const paginationRef: any = useRef();
    const scrollbarRef: any = useRef();
    const offsetValue = useRef(offset());
    const navigateNextRef: any = useRef();
    const navigatePrevRef: any = useRef();

    const localInstanceRef: any = useRef();
    const swiperInstance: any = parentInstanceRef || localInstanceRef;

    const classes = ['swiper-container'];

    classes.push(className);

    if (full) classes.push('swiper-container--full');

    if (navigation && items.length > 1) {
      classes.push('swiper-hasNavigation');
    }

    const classNames = classes.join(' ');

    useLayoutEffect(() => {
      if (swiperElRef.current && !swiperInstance.current) {
        /* eslint-disable-next-line no-new */
        swiperInstance.current = new SwiperLib(swiperElRef.current, {
          initialSlide: 0,
          observer: true,
          observeParents: true,
          direction: 'horizontal',
          slidesPerView: 'auto',
          freeMode: true,
          freeModeSticky: true,
          freeModeMomentumVelocityRatio: 0.5,
          mousewheel: {
            invert: true,
            forceToAxis: true,
          },
          pagination: {
            el: paginationRef.current,
          },
          navigation: {
            nextEl: navigateNextRef.current,
            prevEl: navigatePrevRef.current,
          },
          scrollbar: {
            el: isDesktop ? scrollbarRef.current : null,
            draggable: true,
          },
          slidesOffsetBefore: offsetValue.current,
          on: {
            resize: () => {
              offsetValue.current = offset();
              swiperInstance.current.params.slidesOffsetBefore =
                offsetValue.current;
            },
            ...eventHandlers,
          },
          ...swiperConfig,
        });

        onReady(swiperInstance.current);
      }
    }, [
      offset,
      swiperConfig,
      onReady,
      eventHandlers,
      swiperElRef,
      swiperInstance,
    ]);

    return (
      <div ref={swiperElRef} className={classNames}>
        <div className="swiper-wrapper">
          {items.map((item, index) => (
            <div key={item.key || index} className="swiper-slide">
              {item}
            </div>
          ))}
        </div>
        {paginate ? (
          <div ref={paginationRef} className="swiper-pagination" />
        ) : null}
        {navigation && items.length > 1 ? (
          <div className="swiper-navigation">
            <div
              ref={navigateNextRef}
              className="swiper-button-next bg-white rounded-lg"
            />
            <div
              ref={navigatePrevRef}
              className="swiper-button-prev bg-white rounded-lg"
            />
          </div>
        ) : null}
      </div>
    );
  }
);
export default React.memo(Swiper);
