import React from 'react';
import { translateCompanySize } from '@communo-corp/shared-types';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import ChatButton from '../chat/components/ChatButton';
import useCurrentUser from '../../hooks/useCurrentUser';
import MembershipProfilePhoto from './MembershipProfilePhoto';
import useForceMobile from '../../hooks/useForceMobile';
import ButtonLink from '../core/ButtonLink';
import useShowFullProfile from '../../hooks/useShowFullProfile';

type Props = {
  membership: MembershipEntity;
  membershipUsers: MembershipUserEntity[];
};

const MembershipEntityColumn = ({ membership, membershipUsers }: Props) => {
  const { membershipId: authMembershipId } = useCurrentUser();
  const forceMobile = useForceMobile();
  const showFullProfile = useShowFullProfile();

  if (!membership) {
    return null;
  }

  const visibleEmployees = membershipUsers.filter(mu => mu.user.isSearchable);
  const adminUser = visibleEmployees.find(
    membershipUser => membershipUser.membershipAccess === 'admin'
  );
  const size = membership.size ? translateCompanySize(membership.size) : null;

  const shouldRenderChat =
    !forceMobile && membership.id !== authMembershipId && !!adminUser?.user;

  return (
    <>
      <MembershipProfilePhoto
        url={membership.logoURL}
        altText={membership.name}
      />

      <h4 className="mt-8">{membership.name}</h4>

      {membership.location?.friendlyName ? (
        <p className="mt-2">{membership.location.friendlyName}</p>
      ) : null}

      {size ? (
        <>
          <div className="profile__section-title">Company Size</div>
          <p className="mt-2">{size}</p>
        </>
      ) : null}

      {shouldRenderChat && !!adminUser ? (
        <ChatButton
          className={mc('mt-8 btn--sm 2xl:btn--md', forceMobile)}
          identity={adminUser.user.id}
          upgradeLink="#/upgrade"
        />
      ) : null}

      {forceMobile && showFullProfile ? (
        <ButtonLink
          color="primary"
          size="sm"
          href={`/members/membership/${membership.id}`}
          text="View Full Profile"
          className="mt-6 w-full"
        />
      ) : null}
    </>
  );
};
export default MembershipEntityColumn;
