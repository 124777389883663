import React from 'react';
import VettingStatus from './metrics/VettingStatus';
import RecommendationsMetric from './metrics/RecommendationsMetric';
import ReferencesMetric from './metrics/ReferencesMetric';

interface Props {
  showVettingMetric?: boolean;
  showRecommendationsMetric?: boolean;
  showReferenceMetric?: boolean;
  scoreMetrics: ScoreMetrics;
}

const ReputationMetrics = ({
  showVettingMetric = true,
  showRecommendationsMetric = false,
  showReferenceMetric = false,
  scoreMetrics,
}: Props) => {
  return (
    <>
      {showVettingMetric ? <VettingStatus scoreMetrics={scoreMetrics} /> : null}

      {showRecommendationsMetric ? (
        <RecommendationsMetric scoreMetrics={scoreMetrics} />
      ) : null}

      {showReferenceMetric ? (
        <ReferencesMetric scoreMetrics={scoreMetrics} />
      ) : null}
    </>
  );
};
export default ReputationMetrics;
