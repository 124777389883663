import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import UserContext from '../Context';
import { Loading } from '../../core/Loading';
// import FormGroup from '../../core/form/FormGroup';
import { addResponseError } from '../../../services/Messaging';
import { SubmitButton } from '../../core/Button';

type Props = {
  code: string;
  email: string;
};

const InviteSignupForm = ({ code, email }: Props) => {
  const { push, replace } = useHistory();
  const { isAuthenticated, registerInvite, refresh: refreshUser } = useContext(
    UserContext
  );
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
  });

  useEffect(() => {
    // if logged in, redirect to feed
    if (isAuthenticated) {
      replace('/feed');
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    if (loading) return;
    setLoading(true);

    const data: RegisterInviteUser = {
      // firstName: values.firstName,
      // lastName: values.lastName,
      email,
      // password: values.password,
      inviteCode: code,
    };

    registerInvite(data)
      .then(response => {
        refreshUser().then(() => {
          setLoading(false);
          push('/getting-to-know-you');
        });
        return response;
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col grow"
    >
      <Loading isActive={loading} />

      {/* <FormGroup label="Your first name" errors={errors} name="firstName">
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="John"
          className="form-input"
          // defaultValue=""
          ref={register({
            required: 'First name is required',
          })}
        />
      </FormGroup>

      <FormGroup label="Your last name" errors={errors} name="lastName">
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Smith"
          className="form-input"
          // defaultValue=""
          ref={register({
            required: 'Last name is required',
          })}
        />
      </FormGroup>

      <FormGroup label="Enter a password" errors={errors} name="password">
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          className="form-input"
          // defaultValue=""
          ref={register({
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
          })}
        />
      </FormGroup> */}

      <div className="mt-auto md:mt-8 text-center">
        <SubmitButton text="Join team" loading={loading} fullWidth />
      </div>
    </form>
  );
};

export default InviteSignupForm;
