/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { isMobile } from '../../../utilities/Device';

interface Props {
  leftPanel?: JSX.Element[] | JSX.Element;
  children(toggleLeftPanel: Function): JSX.Element[] | JSX.Element;
  contextPanel?: JSX.Element[] | JSX.Element;
  leftPanelIcon?: string;
  contextClassName?: string;
  mainClassName?: string;
  leftClassName?: string;
  contextButtonLabel?: string;
  showContextMobile?: boolean;
}

const Panels = (
  {
    leftPanel,
    children,
    contextPanel,
    leftPanelIcon = 'i-hamburger',
    contextClassName = 'bg-white',
    mainClassName = '',
    leftClassName = 'bg-white',
    contextButtonLabel = 'Context',
    showContextMobile = false,
  }: Props,
  ref: any
) => {
  const [leftPanelOpen, setLeftPanelOpen] = useState(false);
  const [contextOpen, setContextOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    leftPanelOpen: (isOpen: boolean) => {
      setLeftPanelOpen(isOpen);
    },
  }));

  const contextClasses = contextClassName.split(' ');

  if (contextOpen) {
    contextClasses.push('contextOpen');
  }

  const toggleLeftPanel = () => {
    setLeftPanelOpen(!leftPanelOpen);
  };

  return (
    <>
      <div id="panels" className={leftPanelOpen ? 'isOpen' : ''}>
        <div id="left" className={leftClassName}>
          <div className="panel-scroller">
            <div className="side-panel-container px-8 py-8 ">{leftPanel}</div>
          </div>
        </div>
        <div id="main" className={mainClassName}>
          <button
            type="button"
            id="menu-rail"
            onClick={() => toggleLeftPanel()}
          >
            {leftPanelOpen ? (
              <i className="i-close text-lg btn__icon" />
            ) : (
              <i className={`${leftPanelIcon} text-lg btn__icon`} />
            )}
          </button>
          <div className="panel-scroller">{children(toggleLeftPanel)}</div>
          <div id="toggle-overlay" onClick={() => toggleLeftPanel()} />
        </div>
        {(showContextMobile || !isMobile) && (
          <div id="context" className={contextClasses.join(' ')}>
            <div className="panel-scroller">
              <div className="side-panel-container px-8 py-8">
                {contextPanel}
              </div>
            </div>
          </div>
        )}
      </div>
      {!leftPanelOpen && showContextMobile && (
        <button
          id="context-toggle"
          type="button"
          className="p-3 px-6 border rounded-full border-gray-500 lg:hidden bg-white"
          onClick={() => setContextOpen(!contextOpen)}
        >
          {contextOpen ? 'Close' : contextButtonLabel}
        </button>
      )}
    </>
  );
};
export default forwardRef(Panels);
