import React, { useMemo } from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import Swiper from '../swiper/Swiper';
import PerkFeatureSlideDesktop from './PerkFeatureSlideDesktop';
import CollectionFeatureSlideDesktop from './CollectionFeatureSlideDesktop';

type Props = {
  items: PerkBundleSearchResponse[];
};

const PerkFeatureSliderDesktop = ({ items }: Props) => {
  const slides = useMemo(() => {
    return items.map((item: PerkBundleSearchResponse) => {
      const { bundleType, type } = item;

      if (type === 'bundle' && bundleType === 'collection') {
        return (
          <CollectionFeatureSlideDesktop key={item.objectID} bundle={item} />
        );
      }

      return <PerkFeatureSlideDesktop key={item.objectID} perk={item} />;
    });
  }, [items]);

  if (!items.length) return null;

  return (
    <div className="relative w-full aspect-2/1 4xl:aspect-21/9 bg-grey-300">
      {/* <div className="absolute inset-0"> */}
      <Swiper
        navigation
        paginate
        className="pb-0 perk-slider"
        items={slides}
        swiperConfig={{
          slidesPerView: 1,
          freeMode: false,
        }}
      />
    </div>
    // </div>
  );
};
export default PerkFeatureSliderDesktop;
