import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../components/work/Api';
import { addResponseError, addSuccess } from '../services/Messaging';

type Props = {
  workId: string;
  applicationId: string;
};

type NoteData = {
  userId: string;
  note: string;
  rating: number;
  name: string;
  commenterUserId: string | null;
};

const getAggregateRating = (data: ApplicationNoteEntity[] | undefined) => {
  if (!data) return { average: 0, count: 0 };
  const ratings = data.map(note => note.rating);
  const sum: number = ratings.reduce(
    (acc: number, curr: number | null) => acc + (curr || 0),
    0
  );
  const count = ratings.length;
  return { average: sum / count, count };
};

export default function useApplicationNotes({ workId, applicationId }: Props) {
  const queryClient = useQueryClient();

  const notesQuery = useQuery({
    queryKey: ['notes', workId, applicationId],
    queryFn: async () => Api.getApplicationNotes(workId, applicationId),
    onError: error => {
      addResponseError(error as ResponseError);
    },
  });

  const notesMutation = useMutation({
    mutationFn: (noteData: NoteData) =>
      Api.createApplicationNote(workId, applicationId, noteData),
    onSuccess: (data, variables) => {
      addSuccess('Note has been saved!');
      queryClient.invalidateQueries({
        queryKey: ['notes', workId, applicationId],
      });
      queryClient.setQueryData(['notes', { note: variables.note }], data);
    },
    onError: error => {
      addResponseError(error as ResponseError);
    },
  });

  const { isLoading, error, data } = notesQuery;
  const aggregateRating = getAggregateRating(data);

  return {
    data,
    aggregateRating,
    loading: isLoading,
    error,
    notesMutation,
    notesQuery,
  };
}
