import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../Api';
import { addResponseError } from '../../../services/Messaging';

declare type SchedulerPage = {
  config: {
    event: {
      duration: Number;
      location: String;
      title: String;
    };
  };
  // eslint-disable-next-line camelcase
  edit_token: String;
  id: Number;
  name: String;
  slug: String;
};

declare type SchedulerPages = {
  schedulerPages: SchedulerPage[];
};

const useSchedulerPages = () => {
  const queryClient = useQueryClient();
  const schedulerPagesQuery = useQuery<any>({
    queryKey: ['schedulingPages'],
    queryFn: async () => Api.fetchSchedulerPages(),
  });

  const { mutate: schedulerDelete, status: scheduleDeleteStatus } = useMutation(
    {
      mutationFn: async (pageId: string) => Api.deleteSchedulerPage(pageId),
      onSuccess: ({ pageId }) => {
        queryClient.setQueryData(
          ['schedulingPages'],
          (previous: SchedulerPages | undefined) => {
            return {
              schedulerPages: previous!.schedulerPages.filter(
                page => page.id !== pageId
              ),
            };
          }
        );
      },
      onError: error => {
        addResponseError(error as ResponseError);
      },
    }
  );

  const {
    mutate: schedulerMutation,
    status: scheduleMutationStatus,
  } = useMutation({
    mutationFn: () => Api.newPage(),
    onSuccess: async newSchedulePage => {
      queryClient.setQueryData(
        ['schedulingPages'],
        (previous: SchedulerPages | undefined) => {
          return {
            schedulerPages: [...previous!.schedulerPages, newSchedulePage.data],
          };
        }
      );
    },
    onError: error => {
      addResponseError(error as ResponseError);
    },
  });

  const {
    data,
    isLoading,
    refetch,
    fetchStatus,
    isSuccess,
  } = schedulerPagesQuery;

  return {
    data,
    isLoading,
    fetchStatus,
    isSuccess,
    refetch,
    schedulerDelete,
    scheduleDeleteStatus,
    schedulerMutation,
    scheduleMutationStatus,
  };
};

export default useSchedulerPages;
