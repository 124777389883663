import React from 'react';
import { FormInput } from '../../core/form';

export default ({ required, errors, register }: any) => (
  <FormInput
    required={required}
    name="firstName"
    label="First name"
    placeholder="Enter first name"
    errors={errors}
    ref={register({
      required: 'First name is required',
    })}
  />
);
