import React from 'react';
import LoginSignupForm from '../forms/LoginSignupForm';

type Props = {
  redirectUrl?: string;
};

const Login = ({ redirectUrl }: Props) => (
  <LoginSignupForm action="login" redirectUrl={redirectUrl} />
);

export default Login;
