import { useEffect, useState, useCallback, useRef } from 'react';
import { BaseAPI } from '../Api';

export type WorkCompanyType = {
  data: WorkCompany[] | null;
  loading: boolean;
};

export default function(): {
  data: WorkCompany[] | null;
  loading: boolean;
  refresh: Function;
} {
  const isMounted = useRef(false);
  const [state, setState] = useState({
    data: null,
    loading: true,
  } as WorkCompanyType);

  const updateState = useCallback(obj => {
    if (!isMounted.current) {
      return;
    }

    // eslint-disable-next-line no-shadow
    setState((state: any) => ({
      ...state,
      ...obj,
    }));
  }, []);

  const refresh = useCallback(async () => {
    isMounted.current = true;

    setState({
      loading: true,
      data: null,
    });

    try {
      const companies = await BaseAPI('company').list();
      await updateState({
        data: companies,
      });
    } catch (e) {
      await updateState({ data: null });
    }
    await updateState({ loading: false });

    return () => {
      isMounted.current = false;
    };
  }, [updateState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data: state.data,
    loading: state.loading,
    refresh,
  };
}
