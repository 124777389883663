import { useQuery } from '@tanstack/react-query';
import Api from '../components/membership/Api';

export default function(
  membershipId: string
): {
  membership: MembershipEntity | null;
  isLoading: boolean;
  isFetching: boolean;
  refetch: Function;
} {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['membership', membershipId],
    () => Api.retrieve(membershipId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    membership: data,
    isLoading,
    isFetching,
    refetch,
  };
}
