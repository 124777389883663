// /* eslint-disable no-console */
import chatChannelsStore from '../state/ChatChannelsState';
import messageStoreCreator from '../state/ChatChannelMessagesState';
import userStoreCreator from '../state/ChatUserState';
import channelMembersStoreCreator from '../state/ChatChannelMembersState';
import channelStoreCreator from '../state/ChatChannelState';
import {
  IClient,
  IChannel,
  IMessage,
  IMember,
  IUser,
  IClientConnectionState,
  IMemberUpdatedEventArgs,
  IUserUpdatedEventArgs,
  IMessageUpdatedEventArgs,
  IChannelUpdatedEventArgs,
} from '../../../@types/chat.d';

const listeners: {
  [event: string]: Function;
} = {
  conversationJoined: (channel: IChannel) => {
    const [, api] = chatChannelsStore;
    const existingChannel = api.getState().channels[channel.sid];

    if (existingChannel) return;

    api.getState().addChannel(channel);

    // console.log('channelJoined', channel);
  },

  conversationUpdated: (e: IChannelUpdatedEventArgs) => {
    const { conversation: channel } = e;
    const [, channelApi] = channelStoreCreator(channel.sid);

    channelApi.getState().updateChannel(channel as IChannel);
    // console.log('channelUpdated', e);
  },

  conversationRemoved: (channel: IChannel) => {
    const [, api] = chatChannelsStore;

    api.getState().removeChannel(channel);

    // console.log('channelRemoved', channel);
  },

  messageAdded: (message: IMessage) => {
    const [, api] = messageStoreCreator(message.conversation.sid);

    api.getState().addMessage(message);
    // console.log('messageAdded', message);
  },

  messageUpdated: (e: IMessageUpdatedEventArgs) => {
    const { message } = e;
    const [, api] = messageStoreCreator(message.conversation.sid);

    api.getState().updateMessage(message as IMessage);

    // console.log('messageUpdated', message);
  },

  messageRemoved: (message: IMessage) => {
    const [, api] = messageStoreCreator(message.conversation.sid);

    api.getState().removeMessage(message);

    // console.log('messageRemoved', message);
  },

  typingStarted: (member: IMember) => {
    const [, api] = channelMembersStoreCreator(member.conversation.sid);

    api.getState().addMemberIsTyping(member.identity);
    // console.log('typingStarted', member);
  },

  typingEnded: (member: IMember) => {
    const [, api] = channelMembersStoreCreator(member.conversation.sid);

    api.getState().removeMemberIsTyping(member.identity);
    // console.log('typingEnded', member);
  },

  participantJoined: (member: IMember) => {
    const [, api] = channelMembersStoreCreator(member.conversation.sid);

    api.getState().addMember(member);
    // console.log('memberJoined', member);
  },

  participantLeft: (member: IMember) => {
    const [, api] = channelMembersStoreCreator(member.conversation.sid);

    api.getState().removeMember(member);
    // console.log('memberLeft', member);
  },

  participantUpdated: (e: IMemberUpdatedEventArgs) => {
    const { participant: member } = e;
    const [, api] = channelMembersStoreCreator(member.conversation.sid);

    api.getState().updateMember(member as IMember);
    // console.log('memberUpdated', e);
  },

  userUpdated: (e: IUserUpdatedEventArgs) => {
    const { user } = e;
    const [, api] = userStoreCreator(user.identity);

    api.getState().updateUser(user as IUser);

    // const { updateReasons } = e;
    // console.log('userUpdated', updateReasons, user);
  },

  connectionStateChanged: (e: IClientConnectionState) => {
    console.log('connectionStateChanged', e);
  },

  connectionError: (e: any) => {
    console.log('connectionError', e);
  },
};

export const addEventListeners = (client: IClient) => {
  // console.log('Adding Twilio event listeners for Client', client.token);

  Object.keys(listeners).forEach((event: string) => {
    const handler: any = listeners[event];
    client.addListener(event as any, handler);
  });
};

export const removeEventListeners = (client: IClient) => {
  // console.log('Removing Twilio event listeners for Client', client.token);

  Object.keys(listeners).forEach((event: string) => {
    const handler: any = listeners[event];
    client.removeListener(event as any, handler);
  });
};
