import React from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const ScreenContent = ({ children }: Props) => {
  return <div className="content text-white text-large">{children}</div>;
};
export default ScreenContent;
