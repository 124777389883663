import React, { useContext, useEffect, useState } from 'react';
import useAnalytics from '../../../../hooks/useAnalytics';
import Avatar from '../../../avatar/Avatar';
import MembershipContext from '../../../membership/Context';
import FormImage from '../../../membership/forms/FormImage';
import useStep from '../../../stepper/hooks/useStep';

const Logo = () => {
  const { membership } = useContext(MembershipContext);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(() => {
    if (membership) {
      return membership.logoURL;
    }

    return null;
  });
  const { setNextButtonLabel } = useStep();

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AgencyLogo Page');
  }, [track]);

  const onChangeHandler = (url: string) => {
    setAvatarUrl(url);
    setNextButtonLabel('Continue');
  };

  return (
    <>
      {membership && (
        <div className="text-center">
          <Avatar avatarUrl={avatarUrl} size="xl" className="mx-auto mb-4" />

          <FormImage
            id={membership.id}
            aspectRatio={[1, 1]}
            name="logoURL"
            label="logo"
            hideAvatar
            roundCrop
            btnClassName="btn btn--primary btn--outline"
            defaultValue={membership.logoURL}
            onChange={onChangeHandler}
          />
        </div>
      )}
    </>
  );
};

export default Logo;
