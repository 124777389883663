import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { track } from '../../../analytics';
import useStep from '../../stepper/hooks/useStep';
import { Role } from '../../user/formElements';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';

const RoleWorkedWith = () => {
  const { setCanAdvance, setOnPrev } = useStep();
  const { form } = useContext(SubmitReferenceContext);
  const { goBack } = useHistory();
  const { role } = form.watch();
  let defaultValue: Array<{ value: string; label: string }> = [];

  if (Array.isArray(role)) {
    defaultValue = role;
  } else if (role) {
    defaultValue = [role];
  }

  const isValid = Array.isArray(defaultValue) && defaultValue.length > 0;

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Role Worked With',
    });
  }, []);

  useEffect(() => {
    setOnPrev(() => {
      goBack();
      return false;
    });
  }, [goBack, setOnPrev]);

  useEffect(() => {
    setCanAdvance(isValid);
  }, [setCanAdvance, isValid]);

  return (
    <div>
      <Role
        name="role"
        labelOverride="Your role when you worked with them"
        form={form}
        isMulti={false}
        isRequired
        defaultValues={defaultValue}
      />
    </div>
  );
};
export default RoleWorkedWith;
