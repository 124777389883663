import React from 'react';
import { CardElement } from '@recurly/react-recurly';
import { UseFormMethods } from 'react-hook-form';
import {
  Country,
  FirstName,
  LastName,
  PostalCode,
  Region,
} from '../formElements';
import { ErrorMessage, FormGroup } from '../../core/form';

type Props = {
  form: UseFormMethods<any>;
  recurlyError: string | null;
};
const UpgradePaymentForm = ({ form, recurlyError = null }: Props) => {
  const { errors, watch, register } = form;

  const formattedRecurlyError = recurlyError
    ? {
        type: 'error',
        message: recurlyError,
      }
    : null;

  return (
    <div className="mt-8">
      <div className="grid grid-cols-2 gap-4">
        <FirstName name="firstName" errors={errors} register={register} />
        <LastName name="lastName" errors={errors} register={register} />
      </div>

      <FormGroup
        name="recurly"
        errors={{
          recurly: formattedRecurlyError,
        }}
      >
        <CardElement />
      </FormGroup>

      <div className="form-group">
        <div className="flex form-input p-0">
          <Country name="country" errors={errors} register={register} />
          {watch('country') === 'CA' && (
            <Region name="region" errors={errors} register={register} />
          )}
          <PostalCode
            name="postalCode"
            placeholder={watch('country') === 'US' ? 'Zip Code' : 'Postal Code'}
            errors={errors}
            register={register}
          />
        </div>

        <ErrorMessage name="country" errors={errors} />
        <ErrorMessage name="region" errors={errors} />
        <ErrorMessage name="postalCode" errors={errors} />
      </div>
    </div>
  );
};

export default UpgradePaymentForm;
