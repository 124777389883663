import React from 'react';
import Page from '../components/core/Page';
import GetToKnowYourAgency from '../components/membership/GetToKnowYourAgency';

const GetToKnowYourAgencyPage = () => {
  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--trueBlack"
      hideHeader
      authGuard
    >
      <GetToKnowYourAgency />
    </Page>
  );
};
export default GetToKnowYourAgencyPage;
