import React from 'react';

type Props = {
  onClick: (e: any) => void;
  children: any;
};

const LinkedInButton = ({ onClick, children }: Props) => {
  return (
    <button
      type="button"
      className="btn btn--social btn--linkedin btn--sm w-full"
      onClick={onClick}
    >
      <span className="btn__icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.39654 7.95596H5.37118V23.9611H0.39654V7.95596ZM2.88496 0C3.64933 0.000726507 4.38218 0.304786 4.92257 0.845413C5.46297 1.38604 5.76674 2.11904 5.76717 2.88345C5.76703 3.64806 5.46339 4.38134 4.92296 4.9222C4.38253 5.46307 3.64952 5.76728 2.88496 5.768C2.11991 5.768 1.3862 5.46411 0.845178 4.92317C0.304159 4.38223 0.000145446 3.64853 0 2.88345C0.000436228 2.11856 0.304575 1.38515 0.845563 0.844444C1.38655 0.303739 2.1201 -1.24406e-07 2.88496 0ZM8.49033 7.95596H13.2609V10.1373H13.3267C13.9849 8.87908 15.6139 7.55227 18.0326 7.55227C23.0698 7.55227 24 10.8674 24 15.1764V23.9551H19.0303V16.1664C19.0303 14.3103 18.9963 11.9227 16.4454 11.9227C13.8566 11.9227 13.46 13.9445 13.46 16.032V23.9496H8.49088V7.94444L8.49033 7.95596Z"
            fill="white"
          />
        </svg>
      </span>

      {children}
    </button>
  );
};
export default LinkedInButton;
