import React from 'react';
import AuthForm from '../forms/AuthForm';
import useAnalytics from '../../../hooks/useAnalytics';
import SignupLayout from '../signup/SignupLayout';
import {
  DEFAULT_ENTER_PASSWORD_COVER_PHOTO_URL,
  DEFAULT_WELCOME_BACK_COVER_PHOTO_URL,
  DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL,
} from '../../../asset-config';

// flows are defined in kebab-case since that is what url parameters come as
// if we define them as camelCase it creates additional JS which isn't necessary
const flows = {
  default: {
    pageName: 'Enter Your Password',
    heading: 'Enter your password.',
    copy: 'Just need to verify that you are indeed you.',
    imageSrc: DEFAULT_ENTER_PASSWORD_COVER_PHOTO_URL,
  },
  'welcome-back': {
    pageName: 'Welcome Back',
    heading: 'Welcome Back.',
    copy: 'You already have an account, just sign in.',
    imageSrc: DEFAULT_WELCOME_BACK_COVER_PHOTO_URL,
  },
  'wrong-password': {
    pageName: 'Wrong Password',
    heading: "Hmm, that's not right.",
    copy: 'You already have an account, just sign in.',
    imageSrc: DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL,
  },
} as const;

export type Flow = keyof typeof flows;

type Props = {
  flow: Flow;
};

const Auth = ({ flow = 'default' }: Props) => {
  const { imageSrc, heading, copy, pageName } = flows[flow];

  useAnalytics(pageName);

  return (
    <SignupLayout headerImg={imageSrc}>
      <div>
        <div className="text-center mb-8">
          <h3 className="mb-2">{heading}</h3>
          <div className="text-lg">{copy}</div>
        </div>

        <AuthForm />
      </div>
    </SignupLayout>
  );
};

export default Auth;
