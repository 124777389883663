import { SearchResponse } from '@algolia/client-search';
import orderBy from 'lodash/orderBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';
import { thousands } from '../../../utilities/Number';

const planLabels: any = {
  individual: 'Talent',
  agency: 'Employee',
  student: 'Student',
};

export function formatPlanType(planType: string) {
  return planLabels[planType] ?? 'User';
}

export default function getPlanTypeOptions(results: {
  [key: string]: SearchResponse;
}) {
  const facetOptions = getFacetValuesFromResponse(
    results['user-isIndividualPlan'] || results['user-search'],
    'primaryMembershipUser.membership.plan.isIndividualPlan'
  );

  const planTypeOptions = Object.keys(facetOptions).map(
    (isIndividualPlan: string) => {
      const count = facetOptions[isIndividualPlan] ?? 0;
      const label = isIndividualPlan === 'true' ? 'Talent' : 'Employees';

      return {
        value: isIndividualPlan,
        activeLabel: label,
        label: `${label} (${thousands(count)})`,
        count,
      };
    }
  );

  return orderBy(planTypeOptions, ['count'], ['desc']);
}
