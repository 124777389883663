import React from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useFeatureGate from '../../hooks/useFeatureGate';
import { substring } from '../../utilities/String';

interface Props {
  message: string;
  isOwnProfile: boolean;
}

interface NeedsUpgradeProps {
  message: string;
}

const MAX_LENGTH = 100;

const NeedsUpgrade = ({ message }: NeedsUpgradeProps) => {
  const { openUpgradeFlowFor } = useFeatureGate();
  const redactedMessage = substring(message, MAX_LENGTH);

  const handleClick = () => {
    openUpgradeFlowFor('canViewReferences');
  };

  return (
    <p>
      {redactedMessage}
      {message.length > MAX_LENGTH ? (
        <button
          className="text-link font-semibold"
          type="button"
          onClick={handleClick}
        >
          See all
        </button>
      ) : null}
    </p>
  );
};

const ReferenceEndorsement = ({ message, isOwnProfile }: Props) => {
  const { checkAccess, isIndividualPlan, plan } = useCurrentMembership();
  const canAccess = checkAccess('canViewReferences');
  const planOrder = plan?.order ?? 0;

  if (
    (!isOwnProfile && isIndividualPlan && planOrder < 2) ||
    (!canAccess && !isOwnProfile)
  ) {
    return <NeedsUpgrade message={message} />;
  }

  return <div className="whitespace-pre-line">{message}</div>;
};
export default ReferenceEndorsement;
