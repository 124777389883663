// eslint-disable-next-line no-unused-vars

import { Paginator } from '@twilio/conversations';
import chatChannelsStore from '../state/ChatChannelsState';
import { CHAT_CHANNELS_PER_PAGE } from '../../../config';
import loadChannelsToState from './loadChannelsToState';
import sortChannels from '../utilities/sortChannels';
import { IClient, IChannel } from '../../../@types/chat.d';
import chatUnreadMessagesState from '../state/ChatUnreadMessagesState';

const getAllChannels = async (client: IClient) => {
  if (!client) throw new Error('Must have a valid client instance.');

  const arr: IChannel[] = [];
  const res = await client.getSubscribedConversations();

  const gather = async (result: Paginator<IChannel>): Promise<IChannel[]> => {
    arr.push(...result.items);

    if (result.hasNextPage) {
      const nextPageResults = await result.nextPage();
      return gather(nextPageResults);
    }

    return Promise.resolve(arr);
  };

  const channels = await gather(res as Paginator<IChannel>);
  const sortedChannels = sortChannels(channels);

  return sortedChannels;
};

const loadChannelsUnreadMessagesCountToState = async (channels: IChannel[]) =>
  Promise.all(
    channels.map(async channel => {
      let count = await channel.getUnreadMessagesCount();

      if (count === null) count = await channel.getMessagesCount();

      return [channel.sid, count];
    })
  );

const initChat = async (client: IClient) => {
  const [, channelsApi] = chatChannelsStore;
  const { addChannels, loadingChannels } = channelsApi.getState();

  loadingChannels();

  const channels = await getAllChannels(client);
  const channelsToLoad = channels.slice(0, CHAT_CHANNELS_PER_PAGE);

  addChannels(channels);

  await loadChannelsToState(channelsToLoad);

  const channelsUnreadCount = await loadChannelsUnreadMessagesCountToState(
    channels
  );
  const [, unreadMessagesApi] = chatUnreadMessagesState;
  unreadMessagesApi
    .getState()
    .addChannelsUnreadCount(Object.fromEntries(channelsUnreadCount));

  return Promise.resolve(true);
};

export default initChat;
