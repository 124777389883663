import React from 'react';

export default ({ name, register, placeholder }: any) => (
  <input
    type="text"
    id={name}
    name={name}
    placeholder={placeholder}
    className="form-input w-32"
    data-recurly="postal_code"
    ref={register({
      required: 'Postal / ZIP code is required',
    })}
  />
);
