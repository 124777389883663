import React from 'react';
import { ListItemComponentProps } from '..';
import AvatarBase from './AvatarBase';

interface Props extends ListItemComponentProps {
  headingClassName?: string;
  subHeadingClassName?: string;
}

const AvatarSlide = ({
  item,
  headingClassName,
  subHeadingClassName,
}: Props) => {
  const { actionUrl, imageUrl, heading, subHeading } = item;
  return (
    <AvatarBase
      to={actionUrl || ''}
      imageUrl={imageUrl || ''}
      showName
      heading={heading || ''}
      subHeading={subHeading || ''}
      headingClassName={headingClassName}
      subHeadingClassName={subHeadingClassName}
    />
  );
};
export default AvatarSlide;
