import React, { useState } from 'react';
import { ButtonFillType } from '../../core/Button';
import ShareWorkButton from './ShareWorkButton';
import ShareWorkModal from './ShareWorkModal';

type Props = {
  buttonFill: ButtonFillType;
  buttonText: string;
  buttonFullWidth?: boolean;
  modalTitle: string;
  modalFormLabel: string;
  userId: string;
  work: WorkEntity;
};

const ShareWork = ({
  buttonFill,
  buttonText,
  buttonFullWidth = false,
  modalTitle,
  modalFormLabel,
  userId,
  work,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (bool: boolean) => setShowModal(bool);

  return (
    <>
      <ShareWorkButton
        buttonFill={buttonFill}
        buttonText={buttonText}
        buttonFullWidth={buttonFullWidth}
        toggleModal={toggleModal}
      />
      {showModal && (
        <ShareWorkModal
          modalTitle={modalTitle}
          modalFormLabel={modalFormLabel}
          userId={userId}
          work={work}
          showModal={showModal}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default ShareWork;
