import React, { useMemo } from 'react';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

const getIndustryMatchStatus = (criteria: ScoreMetricsCriteria): MetricStatus =>
  (!!criteria.hasIndustry && 'complete') || 'incomplete';

type Props = {
  industries: IndustryEntity[];
  scoreMetrics: ScoreMetrics;
};

const IndustryMetric = ({ industries, scoreMetrics }: Props) => {
  const { criteria } = scoreMetrics;
  const label = industries.length > 0 ? industries[0].name : 'Industry';
  const status = useMemo(() => getIndustryMatchStatus(criteria), [criteria]);
  return <Metric label={label} status={status} />;
};

export default IndustryMetric;
