import React from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import { Link } from '../core/router';

interface Props {
  bundle: PerkBundleSearchResponse;
}

const CollectionFeatureSlideMobile = ({ bundle }: Props) => {
  const { objectID, name, tagline } = bundle;

  return (
    <div className="swiper-slide__content text-left">
      <h5 className="mt-2">{tagline}</h5>
      <div className="mt-2 text-large">{name}</div>
      <Link
        to={`/perks/bundles/${objectID}`}
        className="btn btn--primary btn--outline btn--sm w-full mt-6"
      >
        Discover More
      </Link>
    </div>
  );
};
export default CollectionFeatureSlideMobile;
