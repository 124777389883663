import React from 'react';
import { ReactComponent as BrandLogo } from '../../assets/brand-logo.svg';
import { ReactComponent as BrandText } from '../../assets/brand-text.svg';
import { ReactComponent as BrandFull } from '../../assets/brand-full.svg';

type Props = {
  variant?: 'logo' | 'text' | 'full';
  color?: string; // eg 'white', 'black', 'red-300'
  size?:
    | 'xs'
    | 'sm'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
};

const Brand = ({ variant = 'full', color = 'white', size = 'base' }: Props) => (
  <div
    className={`flex items-center justify-center brand brand-${variant} brand-${size} text-${color} fill-current`}
  >
    {variant === 'logo' && <BrandLogo />}
    {variant === 'text' && <BrandText />}
    {variant === 'full' && <BrandFull />}
  </div>
);

export default Brand;
