import React from 'react';
import useAnalytics from '../hooks/useAnalytics';
import View, {
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import NewConversation from '../components/chat/views/NewConversation';

const ChatNewConversation = () => {
  useAnalytics('New Message');

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New Message</h6>
          <NewConversation />
        </ViewContent>
      </>
    );

  return (
    <View authGuard darkStatusBar pageTitle="New Direct Message">
      <ViewHeader defaultBackHref="/chat">
        <ViewHeaderTitle>New Message</ViewHeaderTitle>
      </ViewHeader>

      <ViewContent layout="chat">
        <NewConversation />
      </ViewContent>
    </View>
  );
};

export default ChatNewConversation;
