import React, { useMemo, useState } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import RequestReferenceButton from '../reference/RequestReferenceButton';
import useReferencesForUser from '../reference/hooks/useReferencesForUser';
import ReferenceAvatar from '../reference/ReferenceAvatar';
import ReferenceAverage from '../reference/ReferenceAverage';
import ViewReferencesModal from '../reference/ViewReferencesModal';
import ReferenceRatingsNeedsUpgrade from '../reference/ReferenceRatingsNeedsUpgrade';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Link } from '../core/router';

interface Props {
  user: UserEntity;
}

const NUM_TO_SHOW = 4;

interface AvgRatingProps {
  references: Reference[];
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const AvgRating = ({ references, onClick }: AvgRatingProps) => {
  const { checkAccess, isIndividualPlan, plan } = useCurrentMembership();
  const canAccess = checkAccess('canViewReferences');
  const planOrder = plan?.order ?? 0;
  const totalRatings = useMemo(() => {
    return references.reduce((accumulator, currentValue) => {
      const { ratings } = currentValue;

      if (!ratings) {
        return accumulator;
      }

      ratings.forEach(rating => {
        const { attribute, value } = rating;
        // Values are based on an index 0-4 but we want to display scores as 1-5
        const displayValue = value + 1;

        if (Object.prototype.hasOwnProperty.call(accumulator, attribute)) {
          accumulator[attribute] += displayValue;
        } else {
          accumulator[attribute] = displayValue;
        }
      });

      return accumulator;
    }, {} as any);
  }, [references]);

  if (isIndividualPlan && planOrder < 3) {
    return null;
  }

  if (!canAccess) {
    return <ReferenceRatingsNeedsUpgrade />;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full relative text-left"
    >
      {Object.entries(totalRatings).map((rating: any) => {
        const [key, value] = rating;
        const totalPossible = 5 * references.length;

        return (
          <ReferenceAverage
            key={key}
            className="my-2"
            label={key}
            percentage={(value / totalPossible) * 100}
          />
        );
      })}
    </button>
  );
};

const ProfileReference = ({ user }: Props) => {
  const { user: authUser } = useCurrentUser();
  const [modalOpen, setModalOpen] = useState(false);
  const { response } = useReferencesForUser(user.id);
  const references = response?.references ?? [];
  const authUserId = authUser?.id;

  const visibleReferences = useMemo(() => {
    return references.filter(reference => reference.visibility);
  }, [references]);

  const referencesToShow = useMemo(() => {
    if (visibleReferences.length === 0) {
      return [];
    }

    return visibleReferences.slice(0, Math.min(NUM_TO_SHOW, references.length));
  }, [references.length, visibleReferences]);
  const skippedCount = visibleReferences.length - referencesToShow.length;

  const hasPrefiouslyLeftaReference = useMemo((): boolean => {
    if (authUserId) {
      const foundReference = references.find(reference => {
        const { userId, visibility } = reference;

        return userId === authUserId && visibility;
      });
      return !!foundReference;
    }

    return false;
  }, [authUserId, references]);

  return (
    <>
      <div className="mb-8">
        <p className="profile__section-title">
          REFERENCES{' '}
          <span className="badge ml-2 bg-grey-300">
            {visibleReferences.length}
          </span>
        </p>
        {referencesToShow.length > 0 ? (
          <>
            <div className="mb-6">
              <AvgRating
                references={visibleReferences}
                onClick={() => {
                  setModalOpen(true);
                }}
              />
            </div>
            <div className="mb-6">
              {referencesToShow.map(reference => {
                const avatarUrl =
                  reference.avatarURL ?? reference.oAuthAvatarURL;
                const fullName = reference?.fullname || reference.oAuthFullname;
                const title = reference?.userTitle ?? reference?.oAuthTitle;
                const isCommunoUser = !!reference.userId;

                return (
                  <ReferenceAvatar
                    key={reference.referenceId}
                    title={title ?? ''}
                    name={fullName || ''}
                    avatarUrl={avatarUrl}
                    className="mb-4 cursor-pointer"
                    isCommunoUser={isCommunoUser}
                    clickable
                    onCLick={e => {
                      e.preventDefault();
                      setModalOpen(true);
                    }}
                  />
                );
              })}

              {skippedCount > 0 ? (
                <button
                  type="button"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <div className="avatar avatar--lg avatar--circle bg-grey-500">
                    <span className="avatar__initials">+{skippedCount}</span>
                  </div>
                </button>
              ) : null}

              <button
                type="button"
                className="text-left w-full py-4 font-semibold"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                View Details <i className="i-caret-right" />
              </button>
            </div>
          </>
        ) : null}

        {authUser?.id === user.id ? (
          <RequestReferenceButton />
        ) : (
          <Link
            to={`/leave-reference/${user.id}`}
            className="btn btn--primary btn--outline btn--sm w-full"
            disabled={hasPrefiouslyLeftaReference}
          >
            Leave a Reference
          </Link>
        )}
      </div>

      <ViewReferencesModal
        user={user}
        references={references}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default ProfileReference;
