import React, { useState } from 'react';

const Accordion = ({ title, children }: { title: string; children: any }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <h4 className="accordion-title form-section__header my-4 p-4 flex justify-between items-center cursor-pointer hover:bg-grey-100 rounded-2xl">
          <div>{title}</div>
          <i className={isActive ? 'i-caret-down' : 'i-caret-right'} />
        </h4>
      </div>
      {/* It's important that the content is height 0 and not simply not rendered because then the
      form contents won't be submitted */}
      <div
        className={`accordion-content px-4 overflow-hidden ${
          isActive ? 'h-auto' : 'h-0'
        }`}
      >
        {children}
      </div>
    </>
  );
};

export default Accordion;
