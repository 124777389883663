import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { Education } from '@communo-corp/shared-types';
import Avatar from '../avatar/Avatar';
import RibbonIcon from '../icons/RibbonIcon';
import Button from '../core/Button';
import useNavigate from '../../hooks/useNavigate';
import { addError, addSuccess } from '../../services/Messaging';
import EducationContext from './Context';
import UserContext from '../user/Context';

type Props = {
  education: Education;
  showEdit?: boolean;
  isStudentEducation?: boolean;
  onDelete?: Function;
};
const EducationSummary = ({
  education,
  showEdit = false,
  isStudentEducation = true,
}: Props) => {
  const navigate = useNavigate();
  const { refresh } = useContext(UserContext);
  const { removeEducation } = useContext(EducationContext);
  const dateFormat = isStudentEducation ? 'MMMM YYYY' : 'YYYY';
  const graduationDateFormatted = moment(education.graduationDate).format(
    dateFormat
  );
  const avatarUrl =
    education.school.imageUrl ??
    'https://res.cloudinary.com/communo/image/upload/v1618438042/assets/avatar-university-default.png';

  const { id: educationId } = education;
  const onSubmit = useCallback(async () => {
    try {
      await removeEducation(educationId);
      await refresh();
      addSuccess('Education Updated!');
    } catch (err) {
      addError('Could not delete Education');
    }
  }, [educationId, removeEducation, refresh]);

  return (
    <div className="mb-4">
      <div className="flex">
        <div className="w-16">
          <Avatar
            avatarName={education.school.name}
            avatarUrl={avatarUrl}
            circle
            size="lg"
            className="mx-auto bg-grey-500"
          />
        </div>
        <div className="flex-1 ml-3">
          <p className="font-semibold text-lg">{education.school.name}</p>
          <p className="mt-1">{education.school.country.name}</p>
        </div>
      </div>

      <div className="flex mt-4">
        <div className="w-16 text-center">
          <RibbonIcon className="text-grey-500 w-8 h-8 mx-auto inline-block" />
        </div>
        <div className="flex-1 ml-3">
          <p className="mb-2">
            <span className="font-semibold">MAJOR: </span>
            {education.major}
          </p>
          {education.minor ? (
            <p className="mb-2">
              <span className="font-semibold">MINOR: </span>
              {education.minor}
            </p>
          ) : null}
          <p className="mt-1 text-sm">Graduation - {graduationDateFormatted}</p>
        </div>
      </div>

      {showEdit ? (
        <div className="mt-4">
          <Button
            fill="outline"
            color="primary"
            onClick={() => {
              navigate(`#/edit-profile/education/edit/${education.id}`);
            }}
          >
            Edit
          </Button>
          <Button fill="outline" className="ml-4" onClick={onSubmit}>
            Delete
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default EducationSummary;
