import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Video, {
  LocalAudioTrackPublication,
  LocalDataTrack,
  LocalVideoTrackPublication,
  Participant,
  RemoteTrack,
  Room,
  LocalVideoTrack,
} from 'twilio-video';
import { Browser } from '@capacitor/browser';
import useAnalytics from '../../hooks/useAnalytics';
import { addResponseError } from '../../services/Messaging';
import UserAPI from '../user/Api';
import UserContext from '../user/Context';
import Api from './Api';
import { appUrl } from '../../utilities/Url';

const MAGIC_LINK_EXPIRY = 15 * 60 * 1000; // Expiry time in milliseconds
const AUDIO_THRESHOLD = 40; // Audio level needed before you become dominant speaker

export interface VideoMagicOp {
  code: string | null;
  date: Date | null;
}

export interface SpeakerStats {
  [key: string]: SingleSpeakerStats;
}

export interface SingleSpeakerStats {
  start: Date | null;
  time: number;
}

export interface VideoCallStats {
  room: string | null;
  callStart: Date | null;
  callEnd: Date | null;
  totalParticipants: UserEntity[];
  speakerStats: SpeakerStats;
}

export interface IVideoContext {
  room?: Room;
  getMagicOp: () => Promise<string>;
  getMagicLink: (roomId: string) => Promise<string>;
  openBrowser: (url: string) => Promise<void>;
  connect: (name: string) => void;
  disconnect: () => void;
  isConnecting: boolean;
  participants: Participant[];
  dominantSpeaker: Participant;
  enableVideo: () => void;
  disableVideo: () => void;
  videoEnabled: boolean;
  mute: () => void;
  unmute: () => void;
  muted: boolean;
  isSharingScreen: boolean;
  canShareScreen: () => boolean;
  startScreenShare: () => void;
  stopScreenShare: () => void;
  callStats: VideoCallStats;
  statusText: string;
}
const VideoContext = React.createContext<IVideoContext>(null as any);

export const VideoProvider = ({ children }: { children: any }) => {
  const { track: analyticsTrack } = useAnalytics();
  const { id } = useContext(UserContext);
  const [room, setRoom] = useState<Room>(null!);
  const [isConnecting, setIsConnecting] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [dominantSpeaker, setDominantSpeaker] = useState<Participant>(null!);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [statusText, setStatusText] = useState('');
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [muted, setMuted] = useState(false);
  const [magicOp, setMagicOp] = useState<VideoMagicOp>({
    code: null,
    date: null,
  });
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const dataTrackRef = useRef<LocalDataTrack>(null!);
  const screenTrackRef = useRef<LocalVideoTrack>(null!);
  const [audioLevels, setAudioLevels] = useState<{
    [key: string]: {
      userId: string;
      level: number;
    };
  }>({});
  const [callStats, setCallStats] = useState<VideoCallStats>({
    room: null,
    callStart: null,
    callEnd: null,
    totalParticipants: [],
    speakerStats: {},
  });
  const callStatsRef = useRef<VideoCallStats>(callStats);
  callStatsRef.current = callStats;

  // Sends data over the data track
  const sendData = useCallback(
    (data: Record<string, any>) => {
      if (!dataTrackRef.current) return;
      dataTrackRef.current.send(JSON.stringify(data));
    },
    [dataTrackRef]
  );

  // Merges passed in data with the current callStats
  const updateCallStats = useCallback(
    (
      data:
        | Partial<VideoCallStats>
        | ((prev: VideoCallStats) => Partial<VideoCallStats>)
    ) => {
      setCallStats(prev => {
        return { ...prev, ...(typeof data === 'function' ? data(prev) : data) };
      });
    },
    [setCallStats]
  );

  // Merges passed in data with the current callStats.speakerStats
  const updateSpeakerStats = useCallback(
    (data: SpeakerStats | ((prev: SpeakerStats) => SpeakerStats)) => {
      updateCallStats(prev => {
        return {
          speakerStats: {
            ...prev.speakerStats,
            ...(typeof data === 'function' ? data(prev.speakerStats) : data),
          },
        };
      });
    },
    [updateCallStats]
  );

  // Check if browser supports screen sharing
  const canShareScreen = (): boolean => {
    // TODO: Perhaps check user agent to detect browser version?
    return true;
  };

  // Stop sharing screen
  const stopScreenShare = () => {
    if (screenTrackRef.current) {
      screenTrackRef.current.stop();
      room.localParticipant.emit('trackUnsubscribed', screenTrackRef.current);
      room.localParticipant.unpublishTrack(screenTrackRef.current);
      screenTrackRef.current = null!;
      setIsSharingScreen(false);
    }
  };

  // Start sharing screen
  const startScreenShare = () => {
    if (screenTrackRef.current || isSharingScreen) return;

    const mediaDevices = navigator.mediaDevices as any;
    mediaDevices
      .getDisplayMedia({ audio: false, video: true })
      .then((stream: any) => {
        const track = stream.getTracks()[0];
        track.addEventListener('ended', () => {
          stopScreenShare();
        });
        screenTrackRef.current = new LocalVideoTrack(track);
        room.localParticipant.publishTrack(screenTrackRef.current);
        room.localParticipant.emit('trackSubscribed', screenTrackRef.current);
        setIsSharingScreen(true);
      })
      .catch((err: any) => {
        if (err?.message !== 'Permission denied') {
          addResponseError(err);
        }
      });
  };

  // Generates a new magic code, or returns the last magic code if it has not yet expired
  const getMagicOp = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (
        magicOp.code &&
        magicOp.date &&
        new Date().getTime() - magicOp.date.getTime() < MAGIC_LINK_EXPIRY
      ) {
        resolve(magicOp.code);
        return;
      }

      UserAPI.sendMagicLinkAuth()
        .then((res: any) => {
          setMagicOp({
            code: res.magicLinkCode,
            date: new Date(),
          });

          resolve(res.magicLinkCode);
        })
        .catch(reject);
    });
  };

  // Returns formatted magic link
  const getMagicLink = (roomId: string): Promise<string> => {
    return getMagicOp().then((res: string) => {
      const magicPath = `/magic/${res}?directRedirect=true&redirectUrl=/video-chat/${roomId}?hideControls`;
      return appUrl(magicPath);
    });
  };

  // Open browser on iOS and set toolbar color
  const openBrowser = (url: string): Promise<void> => {
    return Browser.open({ url, toolbarColor: '#1f2326' });
  };

  // If the participant has not been seen before yet, create user and stat data
  const setupParticipant = (participant: Participant) => {
    if (
      callStatsRef.current.totalParticipants.filter(
        (p: UserEntity) => p.id === participant.identity
      ).length !== 0
    ) {
      return;
    }

    updateSpeakerStats({
      [participant.identity]: {
        start: null,
        time: 0,
      },
    });

    // Fetch user data
    UserAPI.retrieve(participant.identity)
      .then((user: UserEntity) => {
        updateCallStats((prevStats: VideoCallStats) => {
          return {
            totalParticipants: [...prevStats.totalParticipants, user],
          };
        });
      })
      .catch(addResponseError);
  };

  // Parse message received over data track
  // eslint-disable-next-line no-unused-vars
  const participantTrackMessage = (participant: Participant) => {
    return (dataStr: string) => {
      const data = JSON.parse(dataStr || '');
      if (data?.name === 'audioLevelsChanged') {
        setAudioLevels(prev => {
          return { ...prev, ...data.value };
        });
      }
    };
  };

  // Start listening to data track messages
  const participantTrackSubscribed = (participant: Participant) => {
    return (track: RemoteTrack) => {
      if (track?.kind === 'data') {
        track.on('message', participantTrackMessage(participant));
      }
    };
  };

  // Cleanup subscriptions to data track messages
  const participantTrackUnsubscribed = (participant: Participant) => {
    return (track: RemoteTrack) => {
      if (track?.kind === 'data') {
        track.off('message', participantTrackMessage(participant));
      }
    };
  };

  // Subscribe to events on participant connect
  const participantConnected = (participant: Participant) => {
    setParticipants(prevParticipants => [...prevParticipants, participant]);
    setupParticipant(participant);

    participant.on('trackSubscribed', participantTrackSubscribed(participant));
    participant.on(
      'trackUnsubscribed',
      participantTrackUnsubscribed(participant)
    );
  };

  // Cleanup event subscribers when a participant disconnects
  const participantDisconnected = (participant: Participant) => {
    setParticipants(prevParticipants =>
      prevParticipants.filter(
        (p: Participant) => p.identity !== participant.identity
      )
    );

    participant.off('trackSubscribed', participantTrackSubscribed(participant));
    participant.off(
      'trackUnsubscribed',
      participantTrackUnsubscribed(participant)
    );
  };

  // Called when dominant speaker is changed
  const updateDominantSpeaker = useCallback(
    (participant: Participant) => {
      setDominantSpeaker((prevSpeaker: Participant) => {
        // If there was a previous dominant speaker, update their stats to reflect the amount of time they talked (in ms)
        if (prevSpeaker) {
          updateSpeakerStats((prevStats: SpeakerStats) => {
            const time = prevStats[prevSpeaker.identity]?.start
              ? new Date().getTime() -
                prevStats[prevSpeaker.identity].start!.getTime()
              : 0;

            return {
              [prevSpeaker.identity]: {
                start: null!,
                time: (prevStats[prevSpeaker.identity]?.time || 0) + time,
              },
            };
          });
        }

        // If the new dominant speaker is not null, set their speak start time
        if (participant) {
          updateSpeakerStats((prevStats: SpeakerStats) => {
            return {
              [participant.identity]: {
                start: new Date(),
                time: prevStats[participant.identity]?.time || 0,
              },
            };
          });
        }

        // Update dominant speaker
        return participant;
      });
    },
    [setDominantSpeaker, updateSpeakerStats]
  );

  // Connect to room with given name
  const connect = (name: string) => {
    if (!id) return;
    setStatusText('');

    if (isConnecting) {
      setStatusText(prev => `${prev}Already trying to connect\n`);
      return;
    }

    setIsConnecting(true);
    setStatusText(prev => `${prev}Getting token...\n`);

    Api.getToken(id, name).then((res: any) => {
      const { token } = res;
      setStatusText(prev => `${prev}Connecting to room...\n`);

      Video.connect(token, {
        name,
        dominantSpeaker: true,
        networkQuality: { local: 1, remote: 1 },
        audio: true,
        video: { height: 720, frameRate: 24, width: 1280 },
        maxAudioBitrate: 16000,
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
      })
        .then((chatRoom: Room) => {
          setStatusText(prev => `${prev}Connected\n`);
          setRoom(chatRoom);
          setIsConnecting(false);
          setCallStats({
            room: name,
            callStart: new Date(),
            callEnd: null,
            totalParticipants: [],
            speakerStats: {},
          });
          setupParticipant(chatRoom.localParticipant);

          Array.from(chatRoom?.participants.values() || []).forEach(
            participantConnected
          );

          dataTrackRef.current = new LocalDataTrack();
          chatRoom.localParticipant.publishTrack(dataTrackRef.current);

          chatRoom.on('participantConnected', participantConnected);
          chatRoom.on('participantDisconnected', participantDisconnected);
          // chatRoom.on('dominantSpeakerChanged', speakerChanged);

          if (intervalId) {
            clearInterval(intervalId);
          }

          // Every second, find the user with the largest audio level and send
          // their id/level to all other participants
          setIntervalId(
            setInterval(async () => {
              if (!chatRoom?.localParticipant) return;
              const stats = (await chatRoom.getStats())[0];

              if (!stats) return;

              const audio: any = {};
              let maxSid: string | null = null;

              // Get audio level of each track and find the loudest
              stats.remoteAudioTrackStats.forEach(track => {
                if (!audio[track.trackSid]) {
                  let userId = null;

                  try {
                    chatRoom.participants.forEach(participant => {
                      participant.audioTracks.forEach(t => {
                        if (t.trackSid === track.trackSid) {
                          userId = participant.identity;
                          throw userId;
                        }
                      });
                    });
                  } catch (e) {
                    // Do nothing, it's not an error - just breaking out of nested for loops early
                  }

                  audio[track.trackSid] = {
                    userId,
                    level: 0,
                  };
                }

                audio[track.trackSid].level = track.audioLevel;

                if (
                  !maxSid ||
                  audio[maxSid].level < audio[track.trackSid].level
                ) {
                  maxSid = track.trackSid;
                }
              });

              // Send data to all other participants
              if (maxSid) {
                sendData({
                  name: 'audioLevelsChanged',
                  value: { [maxSid]: audio[maxSid] },
                });
              }

              setAudioLevels(prev => {
                return { ...prev, ...audio };
              });
            }, 1000)
          );

          analyticsTrack('Video Chat Connected', {
            user: id,
            room: chatRoom.name,
          });
        })
        .catch((err: any) => {
          setIsConnecting(false);
          // eslint-disable-next-line no-console
          console.log(err);

          // eslint-disable-next-line no-alert
          alert(err);
        });
    });
  };

  // Disconnect from room
  const disconnect = () => {
    if (!room) return;

    // Stop interval if it exists
    if (intervalId) clearInterval(intervalId);

    // Record stats for whoever was speaking last before leaving
    updateDominantSpeaker(null!);

    // Stop screen sharing
    stopScreenShare();

    // Remove all remaining listeners and reset states
    room.off('participantConnected', participantConnected);
    room.off('participantDisconnected', participantDisconnected);
    // room.off('dominantSpeakerChanged', speakerChanged);
    room.once('disconnected', () => {
      setParticipants([]);
      setIsConnecting(false);
      setMuted(false);
      setVideoEnabled(true);
      setRoom(null!);
      updateCallStats({
        callEnd: new Date(),
      });
      setStatusText(prev => `${prev}Disconnected\n\n`);

      // Analytics
      analyticsTrack('Video Chat Disconnected', {
        user: id,
        room: room.name,
        callStats: { ...callStats, ...{ callEnd: new Date() } },
      });
    });

    room.disconnect();
  };

  // Enable local video tracks
  const enableVideo = () => {
    room.localParticipant.videoTracks.forEach(
      (pub: LocalVideoTrackPublication) => {
        pub.track.enable();
      }
    );

    setVideoEnabled(true);
  };

  // Disable local video track
  const disableVideo = () => {
    room.localParticipant.videoTracks.forEach(
      (pub: LocalVideoTrackPublication) => {
        if (!pub || !pub.track) return;
        if (pub?.track?.name !== screenTrackRef.current?.name) {
          pub.track.disable();
        }
      }
    );

    setVideoEnabled(false);
  };

  // Mute local audio tracks
  const mute = () => {
    room.localParticipant.audioTracks.forEach(
      (pub: LocalAudioTrackPublication) => {
        pub.track.disable();
      }
    );

    setMuted(true);
  };

  // Unmute local audio tracks
  const unmute = () => {
    room.localParticipant.audioTracks.forEach(
      (pub: LocalAudioTrackPublication) => {
        pub.track.enable();
      }
    );

    setMuted(false);
  };

  // Detect if you are the dominant speaker
  // TODO: This should not happen inside of an effect. Since all participants send
  // data about the loudest speaker every second, this effect will run at least
  // once for every participant in the call per second.
  useEffect(() => {
    if (
      !audioLevels ||
      Object.values(audioLevels).length <= 0 ||
      !room ||
      !participants
    )
      return;

    const max = Object.values(audioLevels).reduce((prev, cur) =>
      prev.level > cur.level ? prev : cur
    );

    if (!max) return;

    if (max.level <= AUDIO_THRESHOLD) {
      updateDominantSpeaker(null!);
      return;
    }

    if (max.userId === room.localParticipant.identity) {
      updateDominantSpeaker(room.localParticipant);
      return;
    }

    const speakers = participants.filter(
      participant => participant.identity.toString() === max.userId.toString()
    );

    if (speakers && speakers.length >= 1) {
      updateDominantSpeaker(speakers[0]);
    } else {
      updateDominantSpeaker(null!);
    }
  }, [audioLevels, participants, room, updateDominantSpeaker]);

  return (
    <VideoContext.Provider
      value={{
        room,
        connect,
        disconnect,
        isConnecting,
        participants,
        dominantSpeaker,
        enableVideo,
        disableVideo,
        videoEnabled,
        mute,
        unmute,
        muted,
        callStats,
        statusText,
        canShareScreen,
        isSharingScreen,
        startScreenShare,
        stopScreenShare,
        getMagicOp,
        getMagicLink,
        openBrowser,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoContext;
