import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router';
import Api from './Api';
import {
  Title,
  Location,
  EventDate,
  Category,
  Logo,
  Type,
  Price,
  Company,
  Url,
  CoverPhoto,
  IsOnline,
} from './formElements';
import { FormActions, FormCheckbox, FormGroup, FormRadio } from '../core/form';
import { SubmitButton } from '../core/Button';
import { addSuccess, addResponseError } from '../../services/Messaging';
import { LoadingBalls } from '../core/Loading';
import MembershipContext from '../membership/Context';
import UserContext from '../user/Context';
import { DEFAULT_COVER_PHOTO_URL } from '../../asset-config';

type Props = {
  form: any;
  defaultValues?: EventEntity;
  isEdit?: boolean;
};

const Form = ({ form, defaultValues, isEdit = false }: Props) => {
  const { isRoot } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const [loading, setLoading] = useState(isEdit);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { id: membershipId } = membership || {};
  const { handleSubmit, errors, register, watch, setValue, getValues } = form;
  const coverPhotoURL =
    defaultValues?.imageURL ||
    defaultValues?.membership?.coverPhotoURL ||
    (isRoot && isEdit ? undefined : membership?.coverPhotoURL) ||
    DEFAULT_COVER_PHOTO_URL;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data: any = {
      companyName: values.companyName,
      logoURL: values.logoURL || null,
      name: values.name,
      //   empty strings are not allowed for location fields
      location: {
        latitude: values.location.latitude || null,
        longitude: values.location.longitude || null,
        friendlyName: values.location.friendlyName || null,
        rawJSON: JSON.parse(values.location.rawJSON) || null,
      },
      expiresAt: values.expiresAt,
      categoryId: values.categoryId,
      type: values.type,
      price: values.price ? parseFloat(values.price) : null,
      externalURL: values.externalURL,
      imageURL: values.imageURL || null,
      isOnline: !!values.isOnline,
    };

    if (isRoot) {
      data.isFeatured = !!values.isFeatured;
      data.membershipId = values.membershipId || null;
    }

    const apiCall =
      isEdit && !!defaultValues
        ? Api.update(defaultValues.id, data)
        : Api.create(data);

    apiCall
      .then((response: EventEntity) => {
        push(pathname);
        addSuccess(`Event has been ${isEdit ? 'Updated' : 'Created'}`);
        return response;
      })
      .catch((error: any) => {
        setLoading(false);
        addResponseError(error);
        return error;
      });
  };

  useEffect(() => {
    setValue('membershipId', membershipId);
  }, [membershipId, setValue]);

  useEffect(() => {
    setLoading(isEdit && !defaultValues);
  }, [isEdit, defaultValues]);

  return (
    <>
      <LoadingBalls isActive={loading} />
      {((isEdit && defaultValues) || !isEdit) && (
        <form
          className="form-lg flex flex-col min-h-full"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <input type="hidden" name="membershipId" ref={register} />
          {isRoot && (
            <FormGroup name="membershipId" label="Membership">
              {!isEdit && (
                <>
                  <FormRadio
                    label={membership?.name}
                    name="membershipId"
                    value={membershipId}
                    errors={errors}
                    ref={register}
                  />
                  <FormRadio
                    label="None"
                    name="membershipId"
                    value=""
                    errors={errors}
                    ref={register}
                  />
                </>
              )}
              {isEdit && (
                <>{watch('membershipId') ? membership?.name : 'None'}</>
              )}
            </FormGroup>
          )}
          {!watch('membershipId') && (
            <>
              <Company errors={errors} register={register} />
              <Logo
                errors={errors}
                register={register}
                form={form}
                defaultValue={defaultValues?.logoURL}
                squarePreview={!(getValues()?.isFeatured || false)}
                roundCrop={getValues()?.isFeatured || false}
              />
            </>
          )}
          {isRoot && (
            <FormGroup name="isFeatured" label="Featured">
              <FormCheckbox
                label="Feature this event?"
                name="isFeatured"
                errors={errors}
                ref={register}
              />
            </FormGroup>
          )}
          {watch('isFeatured') && (
            <CoverPhoto
              errors={errors}
              register={register}
              form={form}
              defaultValue={coverPhotoURL}
            />
          )}
          <Title errors={errors} register={register} />
          <Location
            form={form}
            errors={errors}
            register={register}
            defaultValue={defaultValues?.location?.friendlyName || ''}
          />
          <EventDate
            errors={errors}
            register={register}
            defaultValue={defaultValues?.expiresAt}
            form={form}
          />
          <Category
            errors={errors}
            register={register}
            defaultValue={defaultValues?.category}
            form={form}
          />
          <Type errors={errors} register={register} />
          <IsOnline errors={errors} register={register} />
          <Price errors={errors} register={register} />
          <Url errors={errors} register={register} />
          <FormActions className="mt-auto justify-center">
            <div className="md:ml-auto">
              <SubmitButton text={!isEdit ? 'Create' : 'Update'} />
            </div>
          </FormActions>
        </form>
      )}
    </>
  );
};

export default Form;
