import React from 'react';

interface Props {
  text: string;
}

const ListItemBullet = ({ text }: Props) => {
  return (
    <div className="flex items-center py-2 text-large leading-none">
      <div className="text-2xl">
        <i className="i-alert-positive" />
      </div>
      <div className="pl-4">{text}</div>
    </div>
  );
};
export default ListItemBullet;
