import React, { useContext } from 'react';
import ButtonLink from '../core/ButtonLink';
import ApplicationBody from './ApplicationBody';
import ApplicationHeader from './ApplicationHeader';
import ApplicationHireButton from './ApplicationHireButton';
import ApplicationScheduler from './ApplicationScheduler';
import { SHOW_SCHEDULER } from '../../config';
import UserContext from '../user/Context';
import ApplicationRejectButton from './ApplicationRejectButton';

type Props = {
  application: ApplicationEntity;
  hasStartedConversation: boolean;
  hire: (applicationId: string, sendEmail: boolean) => void;
  reject: (applicationId: string, sendEmail: boolean) => void;
  membership: MembershipEntity | undefined;
  patchApplications: Function;
  planType: PlanType | undefined;
  role: string | undefined;
  sendMeetingLink: (link: string) => void;
  showProfile: () => void;
};

const CandidatesApplicationTab = ({
  application,
  hasStartedConversation,
  hire,
  reject,
  membership,
  patchApplications,
  planType,
  role,
  sendMeetingLink,
  showProfile,
}: Props) => {
  const { isPublic } = useContext(UserContext);
  const isAwarded = application.status === 'awarded';
  const isRejected = application.status === 'client_rejected';
  const hiredButtonText = isAwarded ? 'Unaward' : 'Award';
  const rejectedButtonText = isRejected ? 'Reconsider' : 'Reject';

  const { user, work } = application;

  const { user: organizer } = useContext(UserContext);

  const handleHireButton = () => {
    hire(application.id, isPublic);
  };

  const handleRejectButton = () => {
    reject(application.id, isPublic);
  };

  const showInterviewScheduler = SHOW_SCHEDULER && !isPublic;

  const showViewProfileButton = !isPublic;

  return (
    <>
      <ApplicationHeader
        application={application}
        membership={membership}
        planType={planType}
        role={role}
        showProfile={showProfile}
        user={user}
      />
      <ApplicationHireButton
        handleHireButton={handleHireButton}
        hiredButtonText={hiredButtonText}
      />
      {isPublic && (
        <ApplicationRejectButton
          handleRejectButton={handleRejectButton}
          rejectedButtonText={rejectedButtonText}
        />
      )}
      {showInterviewScheduler && (
        <ApplicationScheduler
          organizer={organizer}
          application={application}
          work={work}
          patchApplications={patchApplications}
          hasStartedConversation={hasStartedConversation}
          sendMeetingLink={sendMeetingLink}
        />
      )}
      {application.body && (
        <ApplicationBody
          application={application}
          membership={membership}
          planType={planType}
        />
      )}
      {showViewProfileButton && (
        <ButtonLink
          color="primary"
          size="sm"
          href={`/members/user/${user.id}`}
          text="View Full Profile"
          className="w-full"
        />
      )}
    </>
  );
};

export default CandidatesApplicationTab;
