import React from 'react';
import ListActionsItemBase from './ListActionsItemBase';

const Checkbox = ({
  name,
  value,
  isChecked,
}: {
  name: string;
  value: string;
  isChecked: boolean;
}) => (
  <span className="checkbox-stylized text-teal-900">
    <input
      name={name}
      type="checkbox"
      value={value}
      checked={isChecked}
      // Satisfied React warning, likely a better way to do this
      onChange={() => null}
    />
  </span>
);

const ListActionsItemCheckbox = ({
  start,
  className = '',
  value,
  name,
  isChecked,
  onClick,
  children,
}: ListActionsItemProps & {
  name: string;
  value: string;
  isChecked: boolean;
  onClick: () => void;
}) => {
  const classes = ['list-actions__item'];

  if (className?.length) {
    classes.push(className);
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <label htmlFor={name} className={classes.join(' ')} onClick={onClick}>
      <ListActionsItemBase
        start={start}
        end={<Checkbox name={name} value={value} isChecked={isChecked} />}
      >
        {children}
      </ListActionsItemBase>
    </label>
  );
};

export default ListActionsItemCheckbox;
