import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import BundlesEdit from '../components/perk/BundlesEdit';

const PerkBundlesEdit = () => {
  useAnalytics('Bundle Edit');

  return (
    <Page
      authGuard
      pageTitle="Edit Bundle"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
    >
      <BundlesEdit />
    </Page>
  );
};
export default PerkBundlesEdit;
