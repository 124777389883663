import React from 'react';

type Props = {
  message: string;
  children: React.ReactNode;
  positionClasses?: string;
};

const ToolTip = ({ message, children, positionClasses }: Props) => {
  return (
    <div className="relative flex flex-col items-center group h-max">
      {children}
      <div
        className={`absolute flex-col items-center invisible group-hover:delay-500 group-hover:visible ${positionClasses} pointer-events-none w-max flex`}
      >
        <span className="relative z-10 p-2 text-xs leading-none text-white bg-grey-1000 rounded-md font-bold">
          {message}
        </span>
        <div className="absolute z-1 -translate-y-1 w-3 h-3 rotate-45 bg-grey-1000" />
      </div>
    </div>
  );
};

export default ToolTip;
