import React from 'react';
import BadgePro from '../icons/BadgePro';
import ProfileBadge from './ProfileBadge';

const VettedBadge = React.memo(() => {
  return (
    <ProfileBadge
      title="Expert-Vetted"
      svg={<BadgePro className="w-5 mr-2 text-white" />}
    />
  );
});
export default VettedBadge;
