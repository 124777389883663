import React from 'react';
import { ensureHttp } from '../../utilities/Url';
import useAnalytics from '../../hooks/useAnalytics';
import useCurrentUser from '../../hooks/useCurrentUser';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useFeatureGate from '../../hooks/useFeatureGate';
import useUserAllowList from '../user/hooks/useUserAllowList';

const iconMap: { [key: string]: string } = {
  WebsiteURL: 'web',
  FacebookURL: 'facebook',
  TwitterURL: 'twitter',
  InstagramURL: 'instagram',
  LinkedInURL: 'linkedin',
  VimeoURL: 'vimeo',
  YouTubeURL: 'youtube',
  BehanceURL: 'behance',
};

const SocialLink = ({
  userId,
  url,
  link,
}: {
  userId: string;
  url: string;
  link: string;
}) => {
  const { track } = useAnalytics();

  const handleClick = () => {
    track('Social Link Clicked', {
      type: link,
      url,
      userId,
    });
  };

  const icon = iconMap[link];

  if (!url || !icon) {
    return null;
  }

  return (
    <a
      href={ensureHttp(url)}
      onClick={() => handleClick()}
      key={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      <i className={`i-${icon}`} />
    </a>
  );
};

const UpgradeSocialLink = ({ url, link }: { url: string; link: string }) => {
  const { openUpgradeFlowFor } = useFeatureGate();

  const handleClick = () => {
    openUpgradeFlowFor('canClickSocialLinks');
  };

  const icon = iconMap[link];

  if (!url || !icon) {
    return null;
  }

  return (
    <>
      <button type="button" onClick={handleClick}>
        <i className={`i-${icon}`} />
      </button>
    </>
  );
};

type Props = {
  socialLinks?: SocialLinksEntity | null;
  profileUserId: string | string[];
};
const ProfileSocialLinks = ({ socialLinks, profileUserId }: Props) => {
  const { id: userId } = useCurrentUser();
  const { checkAccess } = useCurrentMembership();
  const { usersInAllowList } = useUserAllowList();
  const profileUserIds = Array.isArray(profileUserId)
    ? profileUserId
    : [profileUserId];

  const canClickLinks = checkAccess('canClickSocialLinks');
  const isOwnProfile = userId && profileUserIds.includes(userId);
  const inAllowList = isOwnProfile || usersInAllowList(profileUserIds);

  if (!socialLinks || !userId) {
    return null;
  }

  const linksToDisplay = Object.keys(socialLinks ?? {}).filter(
    key => !!socialLinks[key]
  );
  if (linksToDisplay.length === 0) {
    return null;
  }

  return (
    <>
      <p className="profile__section-title">Online</p>

      {linksToDisplay.map((link: string) => {
        const url = socialLinks[link];
        const icon = iconMap[link] || null;

        if (!url || !icon) {
          return null;
        }

        return (
          <span key={link} className="inline-block mr-5 text-lg">
            {inAllowList || canClickLinks ? (
              <SocialLink userId={userId} link={link} url={url} />
            ) : (
              <UpgradeSocialLink link={link} url={url} />
            )}
          </span>
        );
      })}
    </>
  );
};
export default ProfileSocialLinks;
