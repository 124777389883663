import { useCallback, useState } from 'react';
import Api from '../components/user/Api';
import { addResponseError, addSuccess } from '../services/Messaging';
import useNavigate from './useNavigate';

export default function useForgotPassword() {
  const navigate = useNavigate();
  const [sendingForgotPasswordEmail, setSendingForgotPasswordEmail] = useState(
    false
  );

  const sendForgotPasswordEmail = useCallback(
    (email: string) => {
      if (sendingForgotPasswordEmail) return;
      setSendingForgotPasswordEmail(true);

      Api.forgotPassword({ email })
        .then(() => {
          setSendingForgotPasswordEmail(false);
          addSuccess('Email has been sent!');
          navigate('/auth/sent-forgot-password');
        })
        .catch((error: ResponseError) => {
          setSendingForgotPasswordEmail(false);
          addResponseError(error);
        });
    },
    [navigate, sendingForgotPasswordEmail]
  );

  return {
    sendForgotPasswordEmail,
    sendingForgotPasswordEmail,
  };
}
