import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import useMyApplications from '../../hooks/useMyApplications';
import { fromNow, isDatePassed, relativeDate } from '../../utilities/dateTime';
import { isMobile } from '../../utilities/MatchMedia';
import { currency } from '../../utilities/Number';
import Avatar from '../avatar/Avatar';
import { LoadingBalls } from '../core/Loading';
import { Link } from '../core/router';

import { SHOW_COMPANY_ON_JOB_POST } from '../../config';

const ListItem = ({
  application,
  index,
}: {
  application: ApplicationEntity;
  index: number;
}) => {
  const { push } = useHistory();
  const { work } = application;
  const { membership, user } = work;
  const isClosed = isDatePassed(work.expiresAt);
  const avatar = useMemo(() => {
    const isUser = membership?.plan?.type === 'individual';
    if (SHOW_COMPANY_ON_JOB_POST && !!work.company) {
      return {
        url: work.company.logoURL,
        name: work.company.name,
      };
    }
    return {
      url: isUser ? user?.avatarURL : membership?.logoURL,
      name: isUser ? `${user?.firstName} ${user.location}` : membership?.name,
    };
  }, [membership, user, work]);

  const handleClick = (e: any) => {
    // if a link was clicked, no need to handle anything more
    if (e.target.tagName === 'A') return;

    // only mobile should expect the full item to be clickable
    if (isMobile) push(`/work/${application.work.id}`);
  };

  return (
    <div
      onClick={handleClick}
      role="none"
      className={`flex ${
        index > 0 ? 'border-t border-grey-100 mt-4 pt-4' : ''
      }`}
    >
      <div className="mr-4">
        <Link to={`/work/${application.work.id}`}>
          <Avatar
            avatarUrl={avatar.url}
            avatarName={`${avatar.name}`}
            size="lg"
          />
        </Link>
      </div>
      <div className="truncate md:mr-4">
        {work.role && (
          <div className="font-medium text-grey-500 text-sm">
            {work.role?.name}
          </div>
        )}
        <div className="font-bold truncate">
          <Link to={`/work/${application.work.id}`}>{work.title}</Link>
        </div>
        <div className="flex text-xs whitespace-nowrap">
          <div>{work.isRemote ? 'Remote OK' : work.location.friendlyName}</div>
          {work.type && (
            <>
              <div className="mx-2">|</div>
              <div>{work.type}</div>
            </>
          )}
          {work.price && (
            <>
              <div className="mx-2">|</div>
              <div>{currency(work.price)}</div>
            </>
          )}
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-end ml-auto whitespace-nowrap">
        <div className="flex justify-end text-xs">
          <div>Applied {relativeDate(application.createdAt)}</div>
        </div>
        <div className="flex justify-end text-xs">
          <div className="font-medium">
            {isClosed ? 'Closed' : `Closes`} {fromNow(work.expiresAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

const List = ({
  applications,
}: {
  applications: ApplicationEntity[] | null;
}) => {
  if (!applications || applications.length < 1)
    return (
      <div>
        Any applications you make to jobs will appear here. Browse the{' '}
        <Link to="/work" className="text-link font-semibold">
          current opportunities
        </Link>
        .
      </div>
    );
  return (
    <div>
      {applications.map((item, i) => (
        <ListItem key={item.id} index={i} application={item} />
      ))}
    </div>
  );
};

const MyApplications = () => {
  const { data, loading } = useMyApplications();
  return (
    <div className="min-h-screen50">
      {loading && <LoadingBalls isActive />}
      {!loading && <List applications={data} />}
    </div>
  );
};

export default React.memo(MyApplications);
