import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { addResponseError } from '../../../../services/Messaging';
import useStep from '../../../stepper/hooks/useStep';
import Rate from '../../../user/formElements/Rate';

interface Inputs {
  rate: string | null;
}

const RateStep = () => {
  const { user, update: updateUser } = useCurrentUser();
  const {
    setCanAdvance,
    setOnNext,
    setNextButtonLabel,
    isLoading,
    setLoading,
    goToNextStep,
  } = useStep();
  const form = useForm<Inputs>({
    mode: 'onChange',
    shouldFocusError: true,
    // defaultValues: {
    //   rate: user?.rate ? `${user.rate}` : null,
    // },
  });
  const { handleSubmit, formState, watch } = form;
  const { isValid, isDirty } = formState;
  const rate = watch('rate');
  const defaultRate = user?.rate ? `${user.rate}` : null;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - Rate Page');
  }, [track]);

  const canAdvance = useMemo(() => {
    // No change
    if (!isDirty) {
      return true;
    }

    // There is a value and it's valid
    if (rate && isValid) {
      return true;
    }

    // cleared the input
    if (rate?.length === 0) {
      return true;
    }

    return false;
  }, [isDirty, isValid, rate]);

  const onSubmit = useCallback(
    (values: Inputs) => {
      if (isLoading) return;
      setLoading(true);

      const userRate = values.rate ? parseFloat(values.rate) : null;

      updateUser({ rate: userRate })
        .then(() => {
          goToNextStep();
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          addResponseError(e);
        });
    },
    [goToNextStep, isLoading, setLoading, updateUser]
  );

  useEffect(() => {
    setCanAdvance(canAdvance);
  }, [canAdvance, setCanAdvance]);

  useEffect(() => {
    setNextButtonLabel(isDirty ? 'Continue' : 'Skip');
  }, [isDirty, setNextButtonLabel]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <div>
      <p className="mb-8 text-center">
        All rates must be in USD. For assistance with conversion, use the{' '}
        <a
          href="https://www.google.com/search?q=google+currency+converter"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-link"
        >
          Google Currency Converter
        </a>
        .
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Rate form={form} defaultValue={defaultRate} />
      </form>
    </div>
  );
};
export default RateStep;
