/* eslint-disable no-console */
import React, { useCallback } from 'react';
import cn from 'classnames';
import { Link } from '../../core/router';
import { getPermissionForChat } from '../../../utilities/permissionUtil';
// import { useCollectionsContext } from '../../collections/context/CollectionsContext';
import useUser from '../../../hooks/useUser';
import { LoadingRing } from '../../core/Loading';
import useFeatureGate from '../../../hooks/useFeatureGate';
import useUserAllowList from '../../user/hooks/useUserAllowList';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import isTalentPlan from '../../plan/utilities/isTalentPlan';

type Props = {
  identity: string;
  type?: 'primary' | 'secondary';
  upgradeLink?: string;
  className?: string;
};

const ChatButton = ({ identity, type = 'primary', className = '' }: Props) => {
  const { user, isFetching } = useUser(identity);
  const { needsUpgrade } = useFeatureGate();
  // const { isUserInBench } = useCollectionsContext();
  const { allowList } = useUserAllowList();

  const userPlan = user?.primaryMembershipUser?.membership?.plan ?? null;

  const hideChatForTalent =
    isTalentPlan(useCurrentPlan()) && userPlan?.type !== 'individual';

  const onClick = useCallback(
    e => {
      if (!userPlan) {
        e.preventDefault();
        return false;
      }

      const permission = getPermissionForChat(userPlan.type);
      // const userInBench = isUserInBench(identity);
      const userInAllowList = allowList.includes(identity);

      if (!userInAllowList && permission && needsUpgrade(permission)) {
        e.preventDefault();
        return false;
      }

      return true;
    },
    [allowList, identity, needsUpgrade, userPlan]
  );

  if (!userPlan || isFetching) {
    return <LoadingRing isActive />;
  }

  const { isDeactivated, isOblivious, isNotContactable } = user;

  if (isDeactivated || (!isOblivious && isNotContactable)) {
    return (
      <>
        <div className="btn btn--tertiary mt-8 mb-2 disabled">
          <i className="i-chat mr-2" /> Chat
        </div>
        <div className="text-sm">
          This user is inactive and cannot receive messages.
        </div>
      </>
    );
  }

  if (!hideChatForTalent) {
    return (
      <Link
        to={`/chat/new-direct-message/${identity}`}
        onClick={onClick}
        className={cn(`btn btn--${type} ${className}`)}
      >
        <i className="i-chat btn__icon" />
        <span className="btn__text">Chat</span>
      </Link>
    );
  }
  return <></>;
};

export default React.memo(ChatButton);
