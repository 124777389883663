/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';

const ParticipantAudio = ({
  track,
  hideInfo = false,
}: {
  track: LocalAudioTrack | RemoteAudioTrack;
  hideInfo?: boolean;
}) => {
  const ref = useRef<HTMLVideoElement>(null as any);

  useEffect(() => {
    if (!track) return;
    track.attach(ref.current);
  }, [track]);

  return (
    <>
      <audio ref={ref} />
      {!track?.isEnabled && !hideInfo && (
        <i className="i-microphone-off absolute right-0 top-0 p-2 text-danger-900 text-xl" />
      )}
    </>
  );
};

export default ParticipantAudio;
