import React from 'react';
import { ListItemComponentProps } from '..';
import SquareBase from './SquareBase';

interface Props extends ListItemComponentProps {}

const SquareWithoutName = ({ item }: Props) => {
  const { actionUrl, imageUrl, heading, subHeading } = item;

  return (
    <SquareBase
      to={actionUrl || ''}
      imageUrl={imageUrl || ''}
      heading={heading || ''}
      subHeading={subHeading}
    />
  );
};
export default SquareWithoutName;
