import { forceHttps } from './Url';

function isCloudinaryImage(url: string) {
  if (
    url.startsWith('https://res.cloudinary.com') ||
    url.startsWith('http://res.cloudinary.com')
  ) {
    return true;
  }
  return false;
}

const cloudinary = (url: string, params: string[] = []) => {
  if (!url || url.length === 0 || !isCloudinaryImage(url)) {
    return url;
  }

  const defaults = ['c_fill', 'f_auto', 'q_auto']; // was = ['c_fill', 'fl_lossy', 'f_auto', 'q_80'];

  const transforms = [...defaults, ...params];

  return forceHttps(
    url
      .replace('/raw/', '/image/')
      .replace('/upload/', `/upload/${transforms.join(',')}/`)
  );
};

export default cloudinary;
