import React from 'react';
import { LoadingBalls } from '../../core/Loading';
import useChatChannel from '../hooks/useChatChannel';
import Conversation from '../views/Conversation';

type InlineConversationProps = {
  sid: string;
  channelsLook?: boolean;
  onMessageSent?: Function;
  hideHeader?: boolean;
  onClosePane?: () => void;
};

const InlineConversation = ({
  sid,
  channelsLook,
  onMessageSent,
  hideHeader,
  onClosePane,
}: InlineConversationProps) => {
  const { channel, loaded } = useChatChannel(sid);

  if (!channel || !loaded)
    return (
      <div className="py-4">
        <LoadingBalls isActive />
      </div>
    );

  return (
    <div className={channelsLook ? 'chat-channels' : 'chat-inline'}>
      <Conversation
        onBackButtonClick={onClosePane}
        hideHeader={hideHeader}
        channel={channel}
        onMessageSent={onMessageSent}
        isInline
      />
    </div>
  );
};

export default InlineConversation;
