import React from 'react';
import { ListActions, ListActionsItemLink } from '../../listActions';
import useCurrentMembership from '../../../hooks/useCurrentMembership';

const UserIcon = () => (
  <div className="icon-circle icon--primary">
    <i className="i-user" />
  </div>
);

const UsersIcon = () => (
  <div className="icon-circle icon--primary">
    <i className="i-users" />
  </div>
);

const ShareIcon = () => (
  <div className="icon-circle icon--primary">
    <i className="i-share" />
  </div>
);

const NewConversation = () => {
  const { checkAccess } = useCurrentMembership();

  return (
    <div className="md:max-w-xl md:mx-auto">
      <p className="text-lg text-center mb-8">
        Send a new message to another member or a group of members.
      </p>
      <ListActions className="mb-8">
        <ListActionsItemLink
          start={<UserIcon />}
          linkTo="/chat/new-direct-message"
        >
          <div className="flex flex-col">
            <span>Direct Message</span>
            <span className="text-subdued font-normal">
              Send a new message to a member.
            </span>
          </div>
        </ListActionsItemLink>
        <ListActionsItemLink
          start={<UsersIcon />}
          linkTo="/chat/new-group-message"
        >
          <div className="flex flex-col">
            <span>Group Message</span>
            <span className="text-subdued font-normal">
              Send a new message to a group of members.
            </span>
          </div>
        </ListActionsItemLink>
        {checkAccess('canBatchSendMessages') ? (
          <ListActionsItemLink
            start={<ShareIcon />}
            linkTo="/chat/new-one-to-many-message"
          >
            <div className="flex flex-col">
              <span>One-to-Many Message</span>
              <span className="text-subdued font-normal">
                Quickly initiate a series of one-on-one messages.
              </span>
            </div>
          </ListActionsItemLink>
        ) : null}
      </ListActions>
    </div>
  );
};

export default NewConversation;
