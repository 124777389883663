import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function usePlanBySlug(
  slug: string
): {
  isFetching: boolean;
  plan?: PlanEntity;
} {
  const { data, isFetching } = useQuery(
    ['plan', slug],
    () => Api.getPlanBySlug(slug),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    }
  );

  return {
    isFetching,
    plan: data,
  };
}
