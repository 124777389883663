import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import Manage from '../components/perk/PerksManage';

const PerksManage = () => {
  useAnalytics('Perks Manage');

  return (
    <Page
      authGuard
      pageTitle="Manage Perks"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
    >
      <Manage />
    </Page>
  );
};
export default PerksManage;
