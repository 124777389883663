import React, { useCallback, useContext, useEffect, useState } from 'react';
import { timeDiff } from '../../utilities/dateTime';
import MembershipContext from '../membership/Context';
import Api from './Api';

interface MyWorkInterface {
  data: MyWork[] | null;
  loading: boolean;
  refresh: Function;
}

const myWorkInterface: MyWorkInterface = {
  data: null,
  loading: false,
  refresh: () => {},
};

const MyWorkContext = React.createContext<MyWorkInterface>(myWorkInterface);

const MyWorkProviderC = ({ children }: { children: any }) => {
  const { checkAccess } = useContext(MembershipContext);

  const [canPostWork, setCanPostWork] = useState(false);
  const canPostWorkCheck = checkAccess('canPostWork');

  if (canPostWork !== canPostWorkCheck) {
    setCanPostWork(canPostWorkCheck);
  }

  const [data, setData] = useState({
    data: null,
    loading: true,
  });

  const updateState = useCallback((key, value) => {
    setData(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const refresh = useCallback(() => {
    updateState('loading', true);
    Api.myWork()
      .then(response => {
        const sorted = response.sort((a: any, b: any) =>
          timeDiff(a.work.expiresAt, b.work.expiresAt)
        );
        updateState('data', sorted);
      })
      .catch(() => {
        updateState('data', null);
      })
      .finally(() => {
        updateState('loading', false);
      });
  }, [updateState]);

  useEffect(() => {
    if (canPostWork) {
      refresh();
    }
  }, [refresh, canPostWork]);

  return (
    <MyWorkContext.Provider
      value={{
        data: data.data,
        loading: data.loading,
        refresh,
      }}
    >
      {children}
    </MyWorkContext.Provider>
  );
};

const MemoProvider = React.memo(MyWorkProviderC);

export const MyWorkProvider = ({ children }: any) => {
  return <MemoProvider>{children}</MemoProvider>;
};

export const MyWorkConsumer = React.memo(MyWorkContext.Consumer);

export default MyWorkContext;
