import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Perk } from '@communo-corp/shared-types';
import { isMobile } from '../../utilities/Device';
import Panels from '../core/panels/panels';
import PageHeaderMobile from '../core/PageHeaderMobile';
import Form from './forms/Form';
import PerkMenu from './PerkMenu';
import perkApi from './Api';
import { addResponseError } from '../../services/Messaging';
import { Link } from '../core/router';
import AdminSidebar from './AdminSidebar';

const PerksEdit = () => {
  const [perk, setPerk] = useState<Perk>();
  const { id: perkId } = useParams();

  const getPerk = useCallback((id: string) => {
    perkApi
      .retrieve(id)
      .then((response: Perk) => {
        setPerk(response);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    if (perkId) {
      getPerk(perkId);
    }
  }, [getPerk, perkId]);

  return (
    <Panels leftPanel={<PerkMenu />} contextPanel={<AdminSidebar />}>
      {toggleLeftPanel => (
        <div>
          {isMobile && (
            <PageHeaderMobile
              title="Perks"
              action={
                // eslint-disable-next-line
                <button
                  type="button"
                  onClick={() => toggleLeftPanel()}
                  className="btn btn--sm"
                >
                  Menu <i className="i-hamburger text-lg ml-xs" />
                </button>
              }
            />
          )}
          <div className="px-4 py-6 pt-20 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
            <div className="flex justify-between items-center">
              <h1>Edit Perk</h1>
              <Link
                to={`/perks/${perk?.id}`}
                className="btn btn--outline btn--primary btn--sm"
              >
                View
              </Link>
            </div>
            {perk && <Form perk={perk} isEdit />}
          </div>
        </div>
      )}
    </Panels>
  );
};
export default PerksEdit;
