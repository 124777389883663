import React from 'react';
import Button from '../Button';
import Modal from './Modal';

type Props = {
  isOpen?: boolean;
  title: string;
  onClose: Function;
  children: JSX.Element;
};

const InfoModal = ({ isOpen = true, title, onClose, children }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      paddingX="8"
      width="2xl"
    >
      <>
        {children}

        <div className="mt-8 flex justify-end">
          <Button fill="outline" color="primary" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </>
    </Modal>
  );
};
export default InfoModal;
