import React from 'react';
import { FormGroup, FormCheckbox } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormGroup name="isOnline" label="Online">
    <FormCheckbox
      label="Is this an online event?"
      name="isOnline"
      errors={errors}
      ref={register}
    />
  </FormGroup>
);
