import React, { useCallback, useState } from 'react';
import Button from '../core/Button';
import ViewRecommendationsModal from './ViewRecommendationsModal';

type Props = {
  userId: string;
  fill?: 'outline' | 'solid';
  className?: string;
};
const ViewRecommendationsButton = ({
  userId,
  fill = 'outline',
  className = '',
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <Button
        type="button"
        color="primary"
        fill={fill}
        text="View Recommendations"
        onClick={handleClick}
        className={className}
      />

      {isModalOpen ? (
        <ViewRecommendationsModal
          userId={userId}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </>
  );
};
export default ViewRecommendationsButton;
