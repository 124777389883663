import React from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useNavigate from '../../hooks/useNavigate';
import Metric from '../candidateScore/Metric';
import { MetricStatus } from '../candidateScore/MetricStatus';
import MembershipProfileCompletionHeader from '../profileCompleteness/MembershipProfileCompletionHeader';
import {
  getMembershipCompletionStage,
  getMembershipProfileRequirements,
} from '../profileCompleteness/utils/profile';
import membershipCompletenessStore, {
  MembershipProfileAttribute,
} from './state/membershipCompletenessStore';

interface CriteriaItem {
  key: MembershipProfileAttribute;
  label: string;
  actionUrl: string;
  status: MetricStatus | null;
  description?: string;
}

type Criteria = Partial<Record<MembershipProfileAttribute, CriteriaItem>>;

export interface MembershipProfileRequirements {
  stageOne: MembershipProfileAttribute[];
  stageTwo: MembershipProfileAttribute[];
  stageThree: MembershipProfileAttribute[];
}

const steps: Criteria = {
  logoURL: {
    key: 'logoURL',
    label: 'Logo',
    actionUrl: '#/edit-page/basic',
    status: null,
    description: 'Show off your company logo',
  },
  summary: {
    key: 'summary',
    label: 'Intro',
    actionUrl: '#/edit-page/about',
    status: null,
    description: 'Describe your company in one sentence',
  },
  about: {
    key: 'about',
    label: 'About',
    actionUrl: '#/edit-page/about',
    status: null,
    description: 'Tell us about your company in detail',
  },
  socialLinks: {
    key: 'socialLinks',
    label: 'Links',
    actionUrl: '#/edit-page/links',
    status: null,
    description: 'Give at least one website or social link',
  },
  coverPhotoURL: {
    key: 'coverPhotoURL',
    label: 'Cover image',
    actionUrl: '#/edit-page/basic',
    status: null,
    description: 'We all judge a book by its cover',
  },
  skills: {
    key: 'skills',
    label: 'Skills',
    actionUrl: '#/edit-page/roles',
    status: null,
    description: 'What specific skills does your company have?',
  },
  industries: {
    key: 'industries',
    label: 'Industry',
    actionUrl: '#/edit-page/roles',
    status: null,
    description: 'Which industries does your company work in?',
  },
  portfolioItems: {
    key: 'portfolioItems',
    label: 'Portfolio',
    actionUrl: '#/edit-page/portfolio',
    status: null,
    description: 'Min. 3 projects',
  },
  tagline: {
    key: 'tagline',
    label: 'Tagline',
    actionUrl: '#/edit-page/basic',
    status: null,
    description: 'Quickly show people what you’re about',
  },
  imageURLs: {
    key: 'imageURLs',
    label: 'Company photos',
    actionUrl: '#/edit-page/images',
    status: null,
    description: 'Show off your company culture',
  },
  videoUrl: {
    key: 'videoUrl',
    label: 'Video',
    actionUrl: '#/edit-page/about',
    status: null,
    description: 'If a picture’s worth a 1,000 words…',
  },
};

export const agencyRequirements: MembershipProfileRequirements = {
  stageOne: ['logoURL', 'summary', 'about', 'socialLinks'],
  stageTwo: ['coverPhotoURL', 'skills', 'industries', 'portfolioItems'],
  stageThree: ['tagline', 'imageURLs', 'videoUrl'],
};

export const businessRequirements: MembershipProfileRequirements = {
  stageOne: ['logoURL', 'summary', 'about', 'socialLinks'],
  stageTwo: ['coverPhotoURL', 'skills', 'industries', 'imageURLs'],
  stageThree: ['tagline', 'videoUrl'],
};

function evaluateCriteria(
  requirements: MembershipProfileAttribute[]
): Array<CriteriaItem | null> {
  const [completenessStore] = membershipCompletenessStore;
  const [getStatusFor] = completenessStore(state => [state.getStatusFor]);

  return requirements.map(requirement => {
    const step = steps[requirement];

    if (step) {
      const status = getStatusFor(requirement);

      return {
        ...step,
        status,
      };
    }

    return null;
  });
}

const ProfileCompletion = () => {
  const { membership } = useCurrentMembership();
  const navigate = useNavigate();
  const requirements = getMembershipProfileRequirements(membership?.plan.type);
  const currentStage = getMembershipCompletionStage(requirements);
  const criteria = evaluateCriteria(requirements[currentStage]);

  return (
    <div className="-ml-8 -mr-8 md:-ml-4">
      <MembershipProfileCompletionHeader requirements={requirements} />

      {currentStage === 'stageThree' ? (
        <div className="pt-4 pb-1 px-5">
          You can make your profile even better by adding the following:
        </div>
      ) : null}

      <div className="px-5 py-xxs">
        {criteria.map(item => {
          if (item === null) {
            return null;
          }

          return (
            <div className="py-xxs" key={item.key}>
              <Metric
                label={item.label}
                status={item.status}
                description={item.description}
                onClick={() => {
                  navigate(item.actionUrl);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ProfileCompletion;
