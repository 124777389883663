import { useQuery } from '@tanstack/react-query';
import Api from '../components/membership/Api';

export default function(
  membershipId: string
): {
  membershipUsers: MembershipUserEntity[] | null;
  isLoading: boolean;
  isFetching: boolean;
  refetch: Function;
} {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['membership-users', membershipId],
    () => Api.users(membershipId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    membershipUsers: data,
    isLoading,
    isFetching,
    refetch,
  };
}
