import { IonSlides } from '@ionic/react';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Step, { StepType } from './Step';
import Breadcrumbs from '../core/Breadcrumb';

export type OnSlideChangeType = {
  index: number;
  isFirstStep: boolean;
  isLastStep: boolean;
};

type Props = {
  steps: StepType[];
  heading?: string;
  onSlideChange?: (args: OnSlideChangeType) => void;
  onFinish?: () => void;
  hideDivider?: boolean;
  sliderClassName?: string;
};
const Stepper = React.forwardRef(
  (
    {
      steps,
      heading,
      onSlideChange,
      onFinish,
      hideDivider = false,
      sliderClassName = '',
    }: Props,
    scrollingRef: any
  ) => {
    let stepHeading;

    if (heading) {
      stepHeading = heading;
    }

    const ionSlidesRef: any = useRef();

    const [state, setState] = useState({
      isFirstStep: true,
      isLastStep: steps.length === 1,
      activeIndex: 0,
    });

    const updateActiveIndex = useCallback(
      (index: number) => {
        const step = steps[index];
        if (!step) {
          return;
        }

        const isFirstStep = index === 0;
        const isLastStep = index + 1 === steps.length;

        setState({
          isFirstStep,
          isLastStep,
          activeIndex: index,
        });

        if (typeof onSlideChange === 'function') {
          onSlideChange({
            index,
            isFirstStep,
            isLastStep,
          });
        }
      },
      [onSlideChange, setState, steps]
    );

    const onIonSlideWillChange = useCallback(async () => {
      const index = await ionSlidesRef?.current.getActiveIndex();
      updateActiveIndex(index);
    }, [updateActiveIndex]);

    const goToStep = useCallback(
      index => {
        const nextIndex = Math.min(steps.length, Math.max(0, index));

        if (ionSlidesRef?.current) {
          ionSlidesRef.current.slideTo(nextIndex);
        }
      },
      [steps.length]
    );

    const goToPrevStep = useCallback(() => {
      goToStep(state.activeIndex - 1);
    }, [goToStep, state.activeIndex]);

    const goToNextStep = useCallback(() => {
      if (state.isLastStep && typeof onFinish === 'function') {
        onFinish();
        return;
      }

      goToStep(state.activeIndex + 1);
    }, [goToStep, onFinish, state.activeIndex, state.isLastStep]);

    useLayoutEffect(() => {
      if (scrollingRef?.current) {
        scrollingRef.current.scrollTop = 0;
      }
    }, [scrollingRef, state.activeIndex]);

    return (
      <div>
        <p className="text-center font-bold">
          {stepHeading || `${steps[state.activeIndex].header} Profile`}
        </p>

        <div className="mt-2">
          <Breadcrumbs
            color="primary"
            current={state.activeIndex + 1} /* Breadcrumbs is 1-based */
            total={steps.length}
          />
        </div>

        <IonSlides
          ref={ionSlidesRef}
          pager
          options={{
            parallax: true,
            pagination: false,
          }}
          onIonSlideWillChange={onIonSlideWillChange}
          className={`mt-6 3xl:mt-8 pb-1 ${sliderClassName}`}
        >
          {steps.map((step, i) => {
            const isActive = i === state.activeIndex;
            return (
              <Step
                // eslint-disable-next-line react/no-array-index-key
                key={`${step.title}-${i}`}
                isActive={isActive}
                step={step}
                isFirstStep={state.isFirstStep}
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
                hideDivider={hideDivider}
              />
            );
          })}
        </IonSlides>
      </div>
    );
  }
);
export default Stepper;
