import React from 'react';
import ChatIcon from '../icons/ChatIcon';

type ChannelParticipantCountProps = {
  participantCount: number;
};

/**
 * The participant counter component representing each channel membership
 *
 * @param param0.participantCount - represents the number of participants in the channel
 */
const ChannelParticipantCount = ({
  participantCount,
}: ChannelParticipantCountProps) => {
  return (
    <div className="flex justify-between flex-row items-center">
      <div className="flex items-center text-grey-900 my-5">
        <div className="relative">
          <ChatIcon className="w-10 h-10 text-xl" />
          <span className="channel-card__offers ml-1">{participantCount}</span>
        </div>
        <div className="font-semibold text-sm pl-4 text-black">
          {participantCount ? '' : 'Participants'}
        </div>
      </div>
    </div>
  );
};

export default ChannelParticipantCount;
