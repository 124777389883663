import React, { useState } from 'react';
import { addResponseError, addSuccess } from '../../services/Messaging';
import Button from '../core/Button';
import Api from '../work/Api';
import OneClickApplyIcon from './OneClickApplyIcon';

type Levels = 'ONE' | 'TWO' | 'THREE' | 'FOUR' | 'FIVE';
const levels = {
  ONE: {
    instructions:
      'You appear to be missing information - improve your chances by completing your profile.',
    background: 'red-900',
  },
  TWO: {
    instructions:
      'You appear to be missing information - improve your chances by completing your profile.',
    background: 'gold-900',
  },
  THREE: {
    instructions: 'Profile complete!',
    background: 'green-900',
  },
  FOUR: {
    instructions: 'Complete your profile to get more job matches.',
    background: 'red-900',
  },
  FIVE: {
    instructions: 'Complete your profile to get more job matches.',
    background: 'gold-900',
  },
};

function getLevel(score: number, isExternalJob: boolean): Levels {
  if (score >= 100) {
    return 'THREE';
  }
  if (score >= 34) {
    return isExternalJob ? 'FIVE' : 'TWO';
  }
  return isExternalJob ? 'FOUR' : 'ONE';
}

type Props = {
  score: number;
  work: WorkEntity;
  refresh: Function;
};
const OneClickApplyCTA = ({ score, work, refresh }: Props) => {
  const [loading, setLoading] = useState(false);
  const isExternalJob = work.isExternal || false;
  const { background, instructions } = levels[getLevel(score, isExternalJob)];

  const onApply = () => {
    if (loading) return;
    setLoading(true);

    Api.apply(work.id, { body: '' })
      .then(() => {
        refresh();
        addSuccess('Your application has been successfully submitted!');
      })
      .catch(addResponseError);
  };

  const CTAHeading = work.isExternal ? 'Work Alert' : '1-Click Apply';
  const CTAparagraph = work.isExternal
    ? "Here's an opportunity that might interest you."
    : 'Apply for these external jobs with your Communo profile.';

  const redirectToExternalJobPost = () => {
    if (work.externalURL) {
      window.open(work.externalURL, '_blank');
    }
  };

  return (
    <div
      className={`flex-1 flex flex-col justify-center items-center p-6 text-center bg-${background}`}
    >
      <OneClickApplyIcon className="text-black h-12 inline" />

      <h2 className="mt-1 text-white h2">{CTAHeading}</h2>
      <p className="mt-1 text-white text-lg">{CTAparagraph}</p>
      <p className="mt-4 text-black font-semibold">{instructions}</p>

      <Button
        onClick={work.isExternal ? redirectToExternalJobPost : onApply}
        color="tertiary"
        fill="solid"
        className="uppercase mt-4"
        reverse
        loading={loading}
        icon="link-external"
        text="Take me there"
      />
    </div>
  );
};
export default OneClickApplyCTA;
