// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';
import sortBy from 'lodash/sortBy';

import {
  LocationFilterOption,
  RadioFilterOption,
  BooleanFilterOption,
} from '../../filter/options';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';

function getEventFilterOptions(results: { [key: string]: SearchResponse }) {
  const eventFilters = [];

  const freeLabel = `Free`;
  eventFilters.push(
    new BooleanFilterOption({
      name: 'isFree',
      toggleLabel: freeLabel,
      activeLabel: freeLabel,
      options: ['All Events', freeLabel],
      toFacetFilter: (filters: any): string[] => {
        return filters.isFree ? [`isFree:${true}`] : [];
      },
    })
  );

  const onlineLabel = `Online`;
  eventFilters.push(
    new BooleanFilterOption({
      name: 'isOnline',
      toggleLabel: onlineLabel,
      activeLabel: onlineLabel,
      options: ['All Events', onlineLabel],
      toFacetFilter: (filters: any): string[] => {
        return filters.isOnline ? [`isOnline:${true}`] : [];
      },
    })
  );

  const categoryFacetOptions = getFacetValuesFromResponse(
    results?.category || results?.search,
    'category.name'
  );
  eventFilters.push(
    new RadioFilterOption({
      multiple: false,
      name: 'category',
      toggleLabel: 'Category',
      sectionTitle: 'Category',
      options: sortBy(
        Object.keys(categoryFacetOptions).map(facet => {
          return {
            label: `${facet} (${categoryFacetOptions[facet]})`,
            activeLabel: facet,
            value: facet,
          };
        }),
        [category => category.label.toLowerCase()]
      ),
      toFacetFilter: (filters: any): string[] => {
        return filters.category ? [`category.name:${filters.category}`] : [];
      },
    })
  );

  const typeFacetOptions = getFacetValuesFromResponse(
    results?.type || results?.search,
    'type'
  );
  eventFilters.push(
    new RadioFilterOption({
      multiple: false,
      name: 'type',
      toggleLabel: 'Type',
      sectionTitle: 'Type of Event',
      options: sortBy(
        Object.keys(typeFacetOptions).map(facet => {
          return {
            label: `${facet} (${typeFacetOptions[facet]})`,
            activeLabel: facet,
            value: facet,
          };
        }),
        [type => type.label.toLowerCase()]
      ),
      toFacetFilter: (filters: any): string[] => {
        return filters.type ? [`type:${filters.type}`] : [];
      },
    })
  );

  eventFilters.push(
    new LocationFilterOption({
      name: 'location',
      toggleLabel: 'Location',
      sectionTitle: 'Location',
      resultsCount: results?.search?.nbHits,
      addAlgoliaParam: (algoliaParams: any, filters: any) => {
        if (filters.location) {
          const { location, distance = 20 } = filters.location;

          if (location) {
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundLatLng = `${location.coordinates.lat},${location.coordinates.lng}`;
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundRadius = parseInt(distance, 10) * 1000;
          }
        }
      },
    })
  );

  return eventFilters;
}

export default getEventFilterOptions;
