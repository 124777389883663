import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import useMyWork from '../../hooks/useMyWork';
import { fromNow, isDatePassed } from '../../utilities/dateTime';
import { isMobile } from '../../utilities/MatchMedia';
import { currency } from '../../utilities/Number';
import Avatar from '../avatar/Avatar';
import { LoadingBalls } from '../core/Loading';
import { Link } from '../core/router';

const ListItem = ({ myWork, index }: { myWork: MyWork; index: number }) => {
  const { push } = useHistory();
  const { work, applicationCounts: counts, pageViews: views } = myWork;
  const { user } = work;
  const isClosed = isDatePassed(work.expiresAt);

  const handleClick = (e: any) => {
    // if a link was clicked, no need to handle anything more
    if (e.target.tagName === 'A') return;

    // only mobile should expect the full item to be clickable
    if (isMobile) push(`/work/${myWork.work.id}`);
  };

  const avatar = useMemo(() => {
    if (work.company) {
      return {
        url: work.company.logoURL,
        name: work.company.name,
      };
    }
    return {
      url: user.avatarURL,
      name: `${user.firstName} ${user.lastName}`,
    };
  }, [user, work]);

  return (
    <div
      onClick={handleClick}
      role="none"
      className={`flex ${
        index > 0 ? 'border-t border-grey-100 mt-4 pt-4' : ''
      }`}
    >
      <div className="mr-4 jerrish">
        <Link to={`/work/${myWork.work.id}`}>
          <Avatar avatarUrl={avatar.url} avatarName={avatar.name} size="lg" />
        </Link>
      </div>
      <div className="truncate md:mr-4">
        {work.role && (
          <div className="font-medium text-grey-500 text-sm">
            {work.role?.name}
          </div>
        )}
        <div className="font-bold truncate">
          <Link to={`/work/${myWork.work.id}`}>{myWork.work.title}</Link>
        </div>
        <div className="flex text-xs whitespace-nowrap">
          <div>{work.isRemote ? 'Remote OK' : work.location.friendlyName}</div>
          {work.type ? (
            <>
              <div className="mx-2">|</div>
              <div>{work.type}</div>
            </>
          ) : null}
          {work.price ? (
            <>
              <div className="mx-2">|</div>
              <div>{currency(work.price)}</div>
            </>
          ) : null}
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-end ml-auto whitespace-nowrap">
        <div className="flex justify-end text-xs">
          <div>
            <div className="font-medium">
              <Link to={`/work/${myWork.work.id}/candidates/list`}>
                {counts.total} {pluralize('Candidate', counts.total)}
              </Link>
            </div>
          </div>
          <div className="mx-2">|</div>
          <div className="font-medium">{counts.shortlisted} Shortlisted</div>
        </div>
        <div className="flex justify-end text-xs">
          <div>
            {views.total} {pluralize('View', views.total)}
          </div>
          <div className="mx-2">|</div>
          <div className="font-medium">
            {isClosed ? 'Closed' : 'Closes'} {fromNow(work.expiresAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

const List = ({ work }: { work: MyWork[] | null }) => {
  if (!work || work.length < 1)
    return (
      <div>
        Any jobs you post will appear here. Check out the current{' '}
        <Link to="/work" className="text-link font-semibold">
          open work
        </Link>{' '}
        or{' '}
        <Link to="/work/create" className="text-link font-semibold">
          create a new post
        </Link>
        .
      </div>
    );
  return (
    <div>
      {work.map((item, i) => (
        <ListItem key={item.work.id} index={i} myWork={item} />
      ))}
    </div>
  );
};

const MyWork = () => {
  const { data, loading } = useMyWork();
  return (
    <div className="min-h-screen50">
      {loading && <LoadingBalls isActive />}
      {!loading && <List work={data} />}
    </div>
  );
};

export default React.memo(MyWork);
