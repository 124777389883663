import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OAuthUserDetails } from '@communo-corp/shared-types';
import useNavigate from '../../../hooks/useNavigate';
import { addResponseError } from '../../../services/Messaging';
import { SubmitButton } from '../../core/Button';
import { Link } from '../../core/router';
import UserContext from '../Context';
import { AuthEmail, Password } from '../formElements';

type Props = {
  profile: OAuthUserDetails;
  oauthProvider: {
    id: string;
  };
};

const SocialAuthLoginForm = ({ profile, oauthProvider }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const { authenticate } = useContext(UserContext);
  const navigate = useNavigate();

  const { handleSubmit, errors, register, formState } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
      oauthProviderId: oauthProvider.id,
    },
  });
  const { isValid, isSubmitted } = formState;

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) return;
      setLoading(true);

      authenticate(values)
        .then(() => {
          navigate('/feed', 'none', 'replace');
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          if (error?.response?.data?.message === 'Wrong password.') {
            navigate('/auth/wrong-password', 'none', 'replace');
          } else {
            addResponseError(error);
          }
        });
    },
    [authenticate, isLoading, navigate]
  );

  useEffect(() => {
    register({ name: 'oauthProviderId' });
  }, [register]);

  return (
    <div>
      <h3>We can’t seem to find you…</h3>

      <div className="mt-8 flex items-center space-x-4">
        {profile.profilePhotoUrl ? (
          <div>
            <img
              src={profile.profilePhotoUrl}
              className="h-24 w-24"
              alt={`${profile.firstName} ${profile.lastName}`}
            />
          </div>
        ) : null}

        <div>
          <h5>
            {profile.firstName} {profile.lastName}
          </h5>
          <p>{profile.email}</p>
        </div>
      </div>

      <div className="text-center text-lg mt-8">
        <p>This email isn&apos;t in our system.</p>
        <p className="mt-2">
          Please log in to your Communo account and we’ll link it to this
          LinkedIn profile (and next time you log in you can use either one).
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="mt-8 flex flex-col grow w-full max-w-xl mx-auto"
      >
        <AuthEmail
          labelVisible
          errors={isSubmitted ? errors : {}}
          register={register}
        />

        <Password
          errors={isSubmitted ? errors : {}}
          register={register}
          skipSecurityCheck
          forgotLink
          labelVisible
        />

        <SubmitButton
          text="Log in via email"
          loading={isLoading}
          disabled={!isValid}
          fullWidth
        />

        <Link to="/signup" className="font-semibold text-center block mt-8">
          Not a Communo member yet? Sign up!
        </Link>
      </form>
    </div>
  );
};
export default SocialAuthLoginForm;
