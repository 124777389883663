import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import useNavigate from '../../../../hooks/useNavigate';
import { addResponseError } from '../../../../services/Messaging';
import { FormInput } from '../../../core/form';
import { Link } from '../../../core/router';
import useStep from '../../../stepper/hooks/useStep';
import Api from '../../Api';
import UserContext from '../../Context';

interface Inputs {
  email: string;
}

const LocomotusEmailStep = () => {
  const navigate = useNavigate();
  const { handleChangeAuthDetails, authDetails } = useContext(UserContext);
  const {
    setCanAdvance,
    setOnNext,
    goToNextStep,
    setLoading,
    isLoading,
  } = useStep();
  const form = useForm<Inputs>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: authDetails.email,
    },
  });
  const { handleSubmit, register, errors, formState } = form;
  const { isValid, isDirty } = formState;
  const [existingEmail, setExistingEmail] = useState(false);

  const advanceSlide = useCallback(() => {
    goToNextStep();
  }, [goToNextStep]);

  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (isLoading) {
        return;
      }

      const { email } = data;

      setLoading(true);

      try {
        const { status } = await Api.checkEmail({ email });

        switch (status) {
          case 'invited':
            navigate('/auth/invited', 'forward', 'push');
            break;
          case 'active':
            setExistingEmail(true);
            break;
          case 'no-password':
            navigate('/auth/no-password', 'forward', 'push');
            break;
          default:
            handleChangeAuthDetails({ email }, () => {
              advanceSlide();
            });
            break;
        }
      } catch (error) {
        addResponseError(error);
      } finally {
        setLoading(false);
      }
    },
    [advanceSlide, handleChangeAuthDetails, isLoading, navigate, setLoading]
  );

  useEffect(() => {
    setOnNext(() => {
      if (isDirty && !existingEmail) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [existingEmail, handleSubmit, isDirty, onSubmit, setOnNext]);

  useEffect(() => {
    setCanAdvance(isValid && !existingEmail);
  }, [setCanAdvance, isValid, existingEmail]);

  if (existingEmail) {
    return (
      <div className="content text-center">
        <h5 className="text-red-900">You have an existing account</h5>
        <p>
          Please visit the <Link to="/login">Login</Link> page to access
          Communo.
        </p>
        <p>
          If you cannot access the Locomotus program please contact{' '}
          <a href="mailto:support@communo.com">support@communo.com</a> to have
          your account type switched.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        name="email"
        label="Email"
        type="email"
        placeholder="name@email.com"
        required
        ref={register({
          required: 'Email is required',
          validate: (value: any) => validator.isEmail(value) || 'Invalid email',
        })}
        onChange={(e: any) => {
          // keep in sync with user context authEmail
          handleChangeAuthDetails({
            email: e.target.value,
          });
        }}
        errors={errors}
      />
    </form>
  );
};
export default LocomotusEmailStep;
