import React, { useContext, useState } from 'react';
// import { caretDownOutline } from 'ionicons/icons';
// import { IonIcon } from '@ionic/react';
import { Link } from '../core/router';
import UserContext from '../user/Context';
// import Badge from '../badge/Badge';
import Avatar from '../avatar/Avatar';
import AccountMenu from './Account';
// import PlusMenu from './Plus';
import ChatNotifications from '../chat/components/Notifications';
import SideBar from './SideBar';

const DefaultMenu = () => (
  <>
    <Link to="/login">Login</Link> / <Link to="/signup">Sign Up</Link>
  </>
);

const AuthenticatedMenu = () => {
  const [open, setOpen] = useState(false);
  // const [newMenu, setNewMenu] = useState(false);
  const { name, avatarURL, hasMembership } = useContext(UserContext);

  return (
    <>
      <div className="flex items-center">
        {hasMembership && (
          <Link
            to="/chat"
            className="flex justify-center items-center px-3 h-10 text-lg"
          >
            <ChatNotifications />
          </Link>
        )}
        {/* {hasMembership && (
          <button
            type="button"
            className="focus:outline-none relative flex justify-center items-center px-3 h-10 text-lg"
            onClick={() => setNewMenu(true)}
          >
            <i className="i-add" />
            <span
              style={{
                fontSize: 10,
                marginLeft: 4,
                marginTop: 6,
              }}
            >
              <IonIcon icon={caretDownOutline} />
            </span>
          </button>
        )} */}
        {/* {hasMembership && (
          <span className="relative flex text-lg md:text-base mr-6">
            <i className="i-notification" />
            <Badge className="absolute top-0 right-0 -mt-2 -mr-3 w-4 h-4">
              2
            </Badge>
          </span>
        )} */}
        <span className="relative ml-2">
          <button
            type="button"
            className="focus:outline-none block"
            onClick={() => setOpen(true)}
          >
            <Avatar size="md" avatarName={name} avatarUrl={avatarURL} />
          </button>
        </span>
      </div>

      {/* <SideBar open={newMenu} setOpen={setNewMenu}>
        <PlusMenu handleClose={() => setNewMenu(false)} />
      </SideBar> */}

      <SideBar open={open} setOpen={setOpen}>
        <AccountMenu handleClose={() => setOpen(false)} />
      </SideBar>
    </>
  );
};

export default () => {
  const { isAuthenticated } = useContext(UserContext);
  return isAuthenticated ? <AuthenticatedMenu /> : <DefaultMenu />;
};
