import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../core/Button';
import { getNylasAuthUrl } from './Util';

const SchedulePermissionButton: React.FC = () => {
  const webLocation = useLocation();

  const handleSchedule = async () => {
    const redirectURl = `${webLocation.pathname}${webLocation.search}`;
    redirectURl.replace('/', '_');
    const authUrl = await getNylasAuthUrl(redirectURl);
    window.location.href = authUrl;
  };

  return (
    <Button
      type="button"
      onClick={handleSchedule}
      color="primary"
      className="flex-1"
    >
      <i className="i-calendar btn__icon" />
      <span className="btn__text">Grant Permission</span>
    </Button>
  );
};

export default SchedulePermissionButton;
