import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  id: string;
  children: any; // JSX.Element | JSX.Element[] | string;
};

export default ({ id, children }: Props) => {
  const parentElem = document.getElementById(id);
  const { current: elem } = useRef(document.createElement('div'));

  useEffect(() => {
    if (parentElem) parentElem.appendChild(elem);
    return () => {
      if (parentElem) parentElem.removeChild(elem);
    };
  }, [elem, parentElem]);

  return createPortal(children, elem);
};
