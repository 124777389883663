import React, { useState, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useNavigate from '../../../hooks/useNavigate';
import UserContext from '../Context';
import { addResponseError } from '../../../services/Messaging';
import { Button, SubmitButton } from '../../core/Button';
import { Password, AuthEmail } from '../formElements';
import { Link } from '../../core/router';
import useMagicLinkLogin from '../../../hooks/useMagicLinkLogin';

const AuthForm = () => {
  const navigate = useNavigate();
  const { authDetails, authenticate, redirectUrl } = useContext(UserContext);
  const { sendMagicLink, sendingMagicLink } = useMagicLinkLogin();

  const [loading, setLoading] = useState(false);
  // const inputRef = useRef(null);
  const { errors, formState, getValues, register, handleSubmit } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: authDetails.email ?? '',
    },
  });
  const { isValid, isSubmitted } = formState;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const { email, password } = values;

    authenticate({ email, password })
      .then(() => {
        // setLoading(false);
        // navigate first to the feed, just in case the redirectUrl
        // is a sub-page of a tab, and their back button takes them
        // back to login, rather than somewhere useful.
        navigate('/feed', 'none', 'replace');
        if (redirectUrl) navigate(redirectUrl, 'none', 'push');
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        if (error?.response?.data?.message === 'Wrong password.')
          navigate('/auth/wrong-password', 'none', 'replace');
        else addResponseError(error);
      });
  };

  const handleSendMagicLink = useCallback(() => {
    const { email }: any = getValues();
    sendMagicLink(email);
  }, [getValues, sendMagicLink]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col grow"
    >
      <div className={`${authDetails.email ? 'hidden' : ''}`}>
        <AuthEmail errors={isSubmitted ? errors : {}} register={register} />
      </div>

      <Password
        errors={isSubmitted ? errors : {}}
        register={register}
        skipSecurityCheck
        forgotLink
      />

      {/* <FormGroup label="Email" errors={isSubmitted ? errors : {}} name="email">
        <InputGroup icon="mail">
          <Input
            name="email"
            type="email"
            defaultValue={authEmail}
            placeholder="name@email.com"
            onChange={(e: any) => {
              // keep in sync with user context authEmail
              handleChangeAuthEmail(e.target.value);
            }}
            ref={register({
              required: 'Email is required',
              validate: (value: any) =>
                validator.isEmail(value) || 'Invalid email',
            })}
          />
        </InputGroup>
      </FormGroup> */}

      {/* <FormGroup
        label="Password"
        errors={isSubmitted ? errors : {}}
        name="password"
      >
        <InputGroup icon="lock">
          <ControllerInput
            control={control}
            rules={{
              required: 'Password is required',
            }}
            name="password"
            type="password"
            placeholder="password"
            // ref={inputRef}
          />
        </InputGroup>
      </FormGroup> */}

      <div className="mt-auto md:mt-4 xl:mt-10 text-center">
        <SubmitButton
          id="sign-in-password-btn"
          loading={loading}
          className={`btn--full mb-4 ${!isValid ? 'disabled' : ''}`}
          text="Sign in"
          size="sm"
        />
        <Button
          loading={sendingMagicLink}
          color="primary"
          fill="outline"
          fullWidth
          text="Send a magic link"
          onClick={handleSendMagicLink}
          size="sm"
        />

        <Link
          to="/forgot-password"
          className="btn btn--primary btn--rev w-full mt-4 btn--sm"
          // tabIndex={-1}
        >
          Forgot password?
        </Link>
      </div>
    </form>
  );
};

export default AuthForm;
