import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import View from '../components/perk/Perks';

const Perks = () => {
  useAnalytics('Perks');

  return (
    <Page
      authGuard
      pageTitle="Perks"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      noScroll
    >
      <View />
    </Page>
  );
};
export default Perks;
