import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';

type Props = {
  search: any;
  setSearch: Function;
  debouncedSearch: Function;
};

const FilterSearch = ({ search, setSearch, debouncedSearch }: Props) => {
  const ref = useRef<any>();
  const [isFocused, setFocused] = useState(false);

  const clearInput = useCallback(() => {
    setSearch('');
    debouncedSearch('');
    if (ref.current) ref.current.focus();
  }, [debouncedSearch, setSearch]);

  const isExpanded = isFocused || !!search;

  return (
    <div className="mr-4 flex-s input-group rounded-md">
      <input
        ref={ref}
        className={cn(
          'md:w-76 form-input rounded-md border-grey-200 py-2 px-0 transition-all duration-150 ease-in',
          {
            'w-64 pr-8': isExpanded,
            'w-0': !isExpanded,
          }
        )}
        type="text"
        name="search"
        placeholder="Search"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={e => {
          setSearch(e.target.value);
          debouncedSearch(e.target.value);
        }}
        value={search}
      />
      {isExpanded ? (
        <button
          onClick={clearInput}
          type="button"
          className="input-group__icon left-auto right-0 pointer-events-auto text-xs text-grey-300"
        >
          <i className="i-close" />
        </button>
      ) : null}
      <div className="input-group__icon">
        <i className="i-search text-grey-400" />
      </div>
    </div>
  );
};
export default FilterSearch;
