/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import { LinkProps } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { ensureHttp } from '../../../utilities/Url';

type Props = LinkProps & {
  to: string;
};

const ExternalLink = ({
  to,
  onClick,
  className = '',
  children,
  ...rest
}: Props) => {
  const handleClick = useCallback(
    (e: any) => {
      Browser.open({ url: ensureHttp(e.currentTarget.href) });
      e.preventDefault();
      return onClick ? onClick(e) : false;
    },
    [onClick]
  );

  return (
    <a
      onClick={handleClick}
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
};

export default React.memo(ExternalLink);
