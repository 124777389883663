import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { track } from '../../../analytics';
import { addResponseError } from '../../../services/Messaging';
import { SubmitButton } from '../../core/Button';
import { FormActions, FormRadio } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import Api from '../../user/Api';
import UserContext from '../../user/Context';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';

const Experience = () => {
  const { authDetails } = useContext(UserContext);
  const { oAuthProvider } = useContext(SubmitReferenceContext);
  const { setCanAdvance, goToNextStep, isLoading, setLoading } = useStep();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      hiringSource: oAuthProvider?.hiringSource,
    },
  });
  const { handleSubmit, errors, register, formState } = form;
  const { isValid } = formState;
  const { oauthProviderId } = authDetails;

  const onSubmit = useCallback(
    async (values: any) => {
      if (isLoading) {
        return;
      }
      setLoading(true);

      try {
        await Api.updateAuthProvider({
          hiringSource: values.hiringSource,
          oauthProviderId,
        });
        setLoading(false);
        goToNextStep();
      } catch (error) {
        addResponseError(error);
        setLoading(false);
      }
    },
    [goToNextStep, isLoading, oauthProviderId, setLoading]
  );

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Hiring Source',
    });
  }, []);

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRadio
        name="hiringSource"
        value="Job Boards (LinkedIn, Indeed, Glassdoor, etc.)"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Job Boards{' '}
        <span className="font-normal">(LinkedIn, Indeed, Glassdoor, etc.)</span>
      </FormRadio>
      <FormRadio
        name="hiringSource"
        value="Events (Conferences, Online Events, Job Fairs, etc.)"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Events{' '}
        <span className="font-normal">
          (Conferences, Online Events, Job Fairs, etc.)
        </span>
      </FormRadio>
      <FormRadio
        name="hiringSource"
        value="Social Media (LinkedIn, Twitter, Facebook, etc.)"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Social Media{' '}
        <span className="font-normal">(LinkedIn, Twitter, Facebook, etc.)</span>
      </FormRadio>
      <FormRadio
        name="hiringSource"
        value="Talent Communities / Marketplaces"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Talent Communities / Marketplaces
      </FormRadio>
      <FormRadio
        name="hiringSource"
        value="Personal Network / Word of Mouth"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Personal Network / Word of Mouth
      </FormRadio>
      <FormRadio
        name="hiringSource"
        value="Other"
        errors={errors}
        ref={register({ required: 'Your experience is required' })}
      >
        Other
      </FormRadio>

      <FormActions className="mt-16">
        <SubmitButton
          disabled={!isValid}
          text="Continue"
          className="w-full"
          loading={isLoading}
        />
      </FormActions>
    </form>
  );
};
export default Experience;
