import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Form from './Form';
import Api from './Api';
import { addResponseError } from '../../services/Messaging';
import { LoadingBalls } from '../core/Loading';

function transformEntity<T>(items: T[] | null) {
  if (items == null) {
    return null;
  }

  return items.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
}

const EditForm = ({ id }: { id: string }) => {
  const [item, setItem] = useState<WorkEntity>();
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      industries: [],
      skills: [],
      collectionIds: [],
    },
  });
  const { reset } = form;

  const loadItem = useCallback(
    (workId: string) => {
      Api.retrieve(workId)
        .then((response: WorkEntity) => {
          const defaultValues: any = { ...response };

          defaultValues.location = {
            latitude: response.location?.latitude || null,
            longitude: response.location?.longitude || null,
            friendlyName: response.location?.friendlyName || null,
            rawJSON: JSON.stringify(response.location?.rawJSON) || null,
          };
          defaultValues.remote = response.isRemote ? '1' : '0';
          defaultValues.projectOption = response.projectOption
            ? {
                value: response.projectOption.id,
                label: response.projectOption.name,
                projectType: response.projectOption.projectType,
              }
            : null;
          defaultValues.role = response.role
            ? {
                value: response.role.id,
                label: response.role.name,
              }
            : null;
          defaultValues.skills = transformEntity(response.skills || null);
          defaultValues.industries = transformEntity(
            response.industries || null
          );
          defaultValues.redactionLevel = response.redactionLevel.toString();
          defaultValues.isOneClickApply = response.isOneClickApply ? '1' : '0';
          defaultValues.isCampaign = response.isCampaign ? '1' : '0';

          reset(defaultValues);
          setItem(defaultValues);
          return response;
        })
        .catch((error: any) => {
          addResponseError(error);
          return error;
        });
    },
    [reset]
  );

  useEffect(() => {
    loadItem(id);
  }, [id, loadItem]);

  const saveForm = useCallback(
    (data: any) => {
      if (!item) {
        return Promise.reject(new Error('No work to save'));
      }
      return Api.update(item.id, data);
    },
    [item]
  );

  if (!item) {
    return <LoadingBalls isActive fullscreen />;
  }

  return (
    <Form
      saveForm={saveForm}
      isEdit
      isExternal={!!item.isExternal}
      disableAudienceEditing={!!item.notificationSentAt}
      title={!item.isExternal ? 'Edit Work' : null}
      form={form}
      defaultValues={item}
    />
  );
};

export default EditForm;
