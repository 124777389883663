import { translateRate } from '@communo-corp/shared-types';
import React, { useMemo, useEffect } from 'react';

import { UseFormMethods } from 'react-hook-form';
import { FormGroup, Input } from '../../core/form';
import RateDisplay from '../../core/RateDisplay';

const MIN_RATE = 20;

interface Props {
  form: UseFormMethods<any>;
  defaultValue: string | null;
  name?: string;
}

const Rate = ({ form, defaultValue, name = 'rate' }: Props) => {
  const { errors, register, watch, setValue } = form;
  const rate: string | null = watch('rate');

  useEffect(() => {
    // set the default values (only called once)
    setValue(name, defaultValue, {
      // shouldValidate: true,
      shouldDirty: true,
    });
  }, [defaultValue, name, setValue]);

  const rateLevel = useMemo(() => {
    if (rate === null) {
      return 0;
    }

    return translateRate(parseFloat(rate));
  }, [rate]);

  return (
    <FormGroup
      name="rate"
      label="Hourly rate"
      labelSubtext="(in USD)"
      errors={errors}
    >
      <div className="flex space-x-4 items-center">
        <div className="flex-auto">
          <Input
            name={name}
            placeholder="Enter hourly rate"
            type="number"
            step="5"
            min={MIN_RATE}
            ref={register({
              validate: {
                minRate: value => {
                  // Empty input
                  if (value.length === 0) {
                    return true;
                  }

                  return (
                    parseFloat(value) >= MIN_RATE ||
                    `Should be greater than ${MIN_RATE}`
                  );
                },
                decimalPlaces: value => {
                  // Empty input
                  if (value.length === 0) {
                    return true;
                  }

                  return (
                    /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ||
                    'Limit of two decimal places'
                  );
                },
              },
            })}
          />
        </div>
        <div>
          <RateDisplay rateLevel={rateLevel} />
        </div>
      </div>
    </FormGroup>
  );
};
export default Rate;
