import React, { useCallback, useMemo, useState } from 'react';
import MetricStatusIcon, { MetricStatus } from '../candidateScore/MetricStatus';

type Props = {
  metrics: ScoreMetricsCriteria;
  work: WorkEntity;
};

const ApplicationMetrics = ({ metrics, work }: Props) => {
  const [expandSkills, setExpandSkills] = useState(false);

  const industries = useMemo(() => {
    return work.industries?.map(i => i.name).join(', ') || null;
  }, [work.industries]);

  const industryStatus = useMemo(
    () => (metrics.hasIndustry ? 'complete' : 'incomplete'),
    [metrics.hasIndustry]
  );

  const getHasSkill = useCallback(
    (id: string) => !!(metrics[`hasSkill-${id}`] || false),
    [metrics]
  );

  const matchedSkills = useMemo(() => {
    return work.skills?.filter(skill => getHasSkill(skill.id));
  }, [getHasSkill, work.skills]);

  const matchedSkillPercent = useMemo(
    () => matchedSkills.length / (work.skills.length || 1),
    [matchedSkills.length, work.skills.length]
  );

  const matchedSkillColor = useMemo(() => {
    if (matchedSkillPercent >= 0.5) return 'green';
    return matchedSkillPercent > 0 ? 'gold' : 'red';
  }, [matchedSkillPercent]);

  const recommendationsStatus: MetricStatus = useMemo(() => {
    if (metrics.multipleRecommendations) return 'complete';
    if (metrics.oneRecommendation) return 'partial';
    return 'incomplete';
  }, [metrics.multipleRecommendations, metrics.oneRecommendation]);

  return (
    <div className="px-5">
      {work.industries.length > 0 && (
        <div className="flex mb-2">
          <div className="mr-8">
            <div className="w-12 font-medium">Industry</div>
          </div>
          <div className="grow">
            <div className="flex justify-between">
              <div className="font-extralight">{industries}</div>
              <div>
                <MetricStatusIcon status={industryStatus} />
              </div>
            </div>
          </div>
        </div>
      )}

      {work.skills.length > 0 && (
        <div className="flex mb-2">
          <div className="mr-8">
            <div className="w-12 font-medium">Skills</div>
          </div>
          <div className="grow">
            <div
              className="flex justify-between items-center cursor-pointer"
              role="none"
              onClick={() => setExpandSkills(!expandSkills)}
            >
              <div
                className={`font-medium ${`text-${matchedSkillColor}-1100`}`}
              >
                {matchedSkills.length || 0} of {work.skills.length || 0} match
              </div>
              <div>
                <div className="w-5 flex justify-center items-center text-grey-500 text-xs">
                  {expandSkills ? (
                    <i className="i-caret-up" />
                  ) : (
                    <i className="i-caret-down" />
                  )}
                </div>
              </div>
            </div>
            {expandSkills &&
              work.skills &&
              work.skills.map(skill => (
                <div
                  key={skill.id}
                  className="flex justify-between items-center h-6 mt-1 leading-snug"
                >
                  <div className="font-extralight">{skill.name}</div>
                  <div>
                    <MetricStatusIcon
                      status={getHasSkill(skill.id) ? 'complete' : 'incomplete'}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      {work.type === 'Student' ? (
        <div className="flex mb-2">
          <div className="mr-8">
            <div className="font-medium">Recommendations</div>
          </div>
          <div className="grow">
            <div className="flex justify-end">
              <MetricStatusIcon status={recommendationsStatus} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ApplicationMetrics;
