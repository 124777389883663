import moment from 'moment';
import React, { useState } from 'react';
import { ENABLE_PROJECT_TYPES, SHOW_COMPANY_ON_JOB_POST } from '../../config';
import useCurrentUser from '../../hooks/useCurrentUser';
import { currency } from '../../utilities/Number';
import CardLogo from '../card/CardLogo';
import Button from '../core/Button';
import Divider from '../core/Divider';
import Markdown from '../core/Markdown';
import { Link } from '../core/router';
import ProfileSocialLinks from '../profile/ProfileSocialLinks';
import { getWorkTypeLabel } from './search/getTypeOptions';
import { getBudgetTypeLabel, getWorkStartLabel } from './view/Helpers';
import { Body, CoverPhoto } from './view/index';

interface WorkPreview
  extends Pick<
    WorkEntity,
    'title' | 'description' | 'audience' | 'requirements' | 'type' | 'isRemote'
  > {
  projectType?: string | null;
  skills: string[] | null;
  industries?: string[];
  role: string;
  price: string | null;
  location: string;
  company: string;
}

interface Props {
  work: WorkPreview;
  coverPhotoURL: string;
  returnUrl: string;
  onSubmit: Function;
  isEdit: boolean;
  intro?: string | null;
  logoUrl: string | null;
  socialLinks?: SocialLinksEntity;
  displayTitle?: string;
  canEdit: boolean;
  editUrl: string;
}

function transformFormData(formData: any) {
  return {
    ...formData,
    description: formData.description?.html,
    projectType: formData.projectTypes?.projectType?.name,
    projectOption: formData.projectTypes?.label,
    role: formData.roles?.label,
    price: formData.price ? currency(formData.price) : null,
    hourlyRate: formData.hourlyRate ? currency(formData.hourlyRate) : null,
    workStart: getWorkStartLabel(formData.workStart),
    budgetType: getBudgetTypeLabel(formData.budgetType, formData.type),
    isRemote: formData.remote === '1',
    location: formData.location?.friendlyName,
    company: formData.workCompanies?.label,
  };
}

const Preview = ({
  work: workEntity,
  coverPhotoURL,
  returnUrl,
  onSubmit,
  isEdit,
  intro,
  logoUrl,
  socialLinks,
  displayTitle,
  canEdit,
  editUrl,
}: Props) => {
  const work = transformFormData(workEntity);
  const { audience } = work;
  const [loading, setLoading] = useState(false);
  const { id: authUserId } = useCurrentUser();
  const now = moment().utc();

  // let audienceType = '';
  // if (audience === 'collection') {
  //   audienceType =
  //     !!collectionIds && collectionIds.length > 0
  //       ? collectionIds[0].label
  //       : 'a List';
  // } else if (audience === 'freelancers') {
  //   audienceType = 'Freelancers';
  // } else if (audience === 'agencies') {
  //   audienceType = 'Agencies';
  // } else if (audience === 'both') {
  //   audienceType = 'Everyone';
  // } else {
  //   audienceType = 'Undefined';
  // }

  const isPublishing = audience === 'both';
  const publishCtaLabel = isPublishing ? 'Publish' : 'Get Applicants';
  const companyLogoURL =
    SHOW_COMPANY_ON_JOB_POST && work.workCompanies?.logoURL
      ? work.workCompanies?.logoURL
      : logoUrl;
  const companyName =
    SHOW_COMPANY_ON_JOB_POST && work.workCompanies?.label
      ? work.workCompanies?.label
      : displayTitle;
  return (
    <div className="work-view px-4 md:px-0">
      <div className="work-view__header bg-white p-0 md:p-8 pb-0 mt-4 md:mt-0 rounded-t-2xl md:rounded-none">
        <CoverPhoto imageUrl={coverPhotoURL} alt={work.title || ''} />
      </div>
      <div className="work-view__sidebar">
        <div className="bg-white md:mt-8 px-6 md:p-8 md:rounded-xl">
          <div className="relative pb-16 md:pb-0">
            <div className="absolute top-0 transform -translate-y-1/2 md:flex md:static md:transform-none">
              <CardLogo
                imageUrl={companyLogoURL}
                altText={companyName || ''}
                logoSize="xl"
                borderSize="none"
              />
            </div>
          </div>

          <p className="mt-2 text-lg">{companyName}</p>

          {intro && (
            <>
              <div className="my-4">
                <Markdown>{intro}</Markdown>
              </div>

              {canEdit ? (
                <div className="notice notice--info">
                  <div className="flex">
                    <i className="i-alert-warning text-2xl mr-4" />
                    <p className="text-black text-small">
                      This content is pulled from the About &gt; Intro of your
                      profile. To make any changes please{' '}
                      <Link
                        className="text-link font-semibold whitespace-nowrap"
                        to={editUrl}
                      >
                        Edit Intro
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              ) : null}
            </>
          )}

          {authUserId && socialLinks && (
            <ProfileSocialLinks
              socialLinks={socialLinks}
              profileUserId={authUserId}
            />
          )}

          <hr className="mt-8" />

          {/* <div className="mt-2 -mx-4">
            <div className="notice notice--info my-8">
              <h6 className="text-center">
                {isEdit ? 'Posted to' : 'Will be posted to'} {audienceType}
              </h6>
            </div>
          </div> */}

          <h5 className="my-8">{work.title}</h5>

          {['freelancers', 'agencies'].includes(work.audience) ? (
            <div className="mt-2 -mx-4">
              <div className="notice notice--warning">
                <p className="text-black text-lg">
                  {work.audience === 'freelancers' ? (
                    <>
                      <span className="font-semibold">Talent members only</span>{' '}
                      Agencies please)
                    </>
                  ) : (
                    <>
                      <span className="font-semibold">Agency only</span> (no
                      Talent members please)
                    </>
                  )}
                </p>
              </div>
            </div>
          ) : null}

          {work.requirements ? (
            <div className="mt-2 -mx-4">
              <div className="notice notice--warning">
                <p className="text-gold-1100 font-medium">Requirements</p>
                <p className="text-black text-lg">{work.requirements}</p>
              </div>
            </div>
          ) : null}

          <div className={work.isRemote ? 'mt-8' : 'mt-2 -mx-4'}>
            <div className={work.isRemote ? '' : 'notice notice--warning'}>
              <p
                className={`font-medium ${
                  work.isRemote ? 'text-grey-400' : 'text-gold-1100'
                }`}
              >
                Location
              </p>
              <p className="text-black mt-1 text-lg">{work.location ?? '—'}</p>
              {work.isRemote && (
                <p className="text-black font-medium text-lg">Remote OK</p>
              )}
            </div>
          </div>

          {!SHOW_COMPANY_ON_JOB_POST && work.company ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Company</p>
              <p className="mt-1 text-lg">{getWorkTypeLabel(work.company)}</p>
            </div>
          ) : null}

          {work.type ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Type</p>
              <p className="mt-1 text-lg">{getWorkTypeLabel(work.type)}</p>
            </div>
          ) : null}

          {ENABLE_PROJECT_TYPES && work.projectType ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Project Type</p>
              <p className="mt-1 text-lg">{work.projectType}</p>
            </div>
          ) : null}
          {ENABLE_PROJECT_TYPES && work.projectOption ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Project Option</p>
              <p className="mt-1 text-lg">{work.projectOption}</p>
            </div>
          ) : null}
          {work.role ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Role</p>
              <p className="mt-1 text-lg">{work.role}</p>
            </div>
          ) : null}
          {work.skills?.length ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Skills</p>
              <p className="mt-1 text-lg">
                {work.skills.map((skill: any) => skill.label).join(', ')}
              </p>
            </div>
          ) : null}
          {work.industries?.length ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Industry</p>
              <p className="mt-1 text-lg">
                {work.industries
                  .map((industry: any) => industry.label)
                  .join(', ')}
              </p>
            </div>
          ) : null}
          {work.workStart ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Work Start</p>
              <p className="mt-1 text-lg">{work.workStart}</p>
            </div>
          ) : null}
          {work.budgetType ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Budget Type</p>
              <p className="mt-1 text-lg">{work.budgetType}</p>
            </div>
          ) : null}
          {work.hoursPerWeek ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Hours per Week</p>
              <p className="mt-1 text-lg">{work.hoursPerWeek}</p>
            </div>
          ) : null}
          {work.price ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Budget (EST)</p>
              <p className="mt-1 text-lg">{work.price}</p>
            </div>
          ) : null}
          {work.hourlyRate ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">Hourly Rate</p>
              <p className="mt-1 text-lg">{work.hourlyRate}</p>
            </div>
          ) : null}
          {work.duration ? (
            <div className="mt-8">
              <p className="text-grey-400 font-medium">
                How long do you need them for?
              </p>
              <p className="mt-1 text-lg">{work.duration} weeks</p>
            </div>
          ) : null}
          <div className="mt-8">
            <p className="text-grey-400 font-medium">Posted</p>
            <p className="mt-1 text-lg">{now.format('MMMM, DD, YYYY')}</p>
          </div>
        </div>
      </div>
      <div className="bg-white md:bg-transparent rounded-b-2xl">
        <Divider className="mx-6 md:hidden" />
        <Body title={work.title} description={work.description} />
        <div className="bg-white px-6 md:px-8 py-8 rounded-b-2xl">
          <div className="flex justify-end border-t border-grey-100 pt-8">
            <Link to={returnUrl} className="btn btn--primary btn--outline">
              Edit
            </Link>
            <Button
              color="primary"
              className="ml-4"
              onClick={() => {
                setLoading(true);
                onSubmit();
                setLoading(false);
              }}
              loading={loading}
            >
              {isEdit ? 'Update Work' : publishCtaLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Preview;
