import { useMemo, useEffect } from 'react';
import { sortBy } from 'lodash';
import shallow from 'zustand/shallow';
import messageStoreCreator, {
  ChatChannelMessagesState,
} from '../state/ChatChannelMessagesState';
import { IMessage } from '../../../@types/chat.d';

/**
 * useChatMessages
 *
 * Hook that returns the Messages for a given Channel.
 * Accepts a `perPage` argument to return, and an optional `anchor`
 * which will be used when loading the Messages for the Channel.
 */
const useChatMessages = (channelSid: string) => {
  const [messageStore] = messageStoreCreator(channelSid);
  const [
    messages,
    unreadCount,
    anchor,
    hasMoreMessages,
    loaded,
    loading,
    loadingMessages,
    loadMessages,
    loadMessagesToSid,
  ] = messageStore(
    (state: ChatChannelMessagesState) => [
      state.messages,
      state.unreadCount,
      state.anchor,
      state.hasMoreMessages,
      state.loaded,
      state.loading,
      state.loadingMessages,
      state.loadMessages,
      state.loadMessagesToSid,
    ],
    shallow
  );

  const sortedMessages = useMemo(
    () => sortBy(messages, (message: IMessage) => message.dateCreated),
    [messages]
  );

  useEffect(() => {
    if (loading || loaded) return;

    loadingMessages();
    loadMessages(true);
  }, [loaded, loading, loadingMessages, loadMessages]);

  const lastReadMessage = useMemo(
    () => sortedMessages[sortedMessages.length - unreadCount - 1],
    [sortedMessages, unreadCount]
  );

  return {
    messages: sortedMessages,
    lastReadMessage,
    unreadCount,
    anchor,
    hasMoreMessages,
    loading,
    loaded,
    loadMessages,
    loadMessagesToSid,
  };
};

export default useChatMessages;
