/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { ChatUserProfile } from '../components/ChannelProfile';
import useChatServices from '../hooks/useChatServices';
import { IChannel } from '../../../@types/chat.d';
import ComposeMessage from '../components/Compose';
import { ViewHeader, ViewFooter, ViewContent } from '../../core/View';
import { addError } from '../../../services/Messaging';

const BlankConversation = ({
  identity,
  onChannelCreated,
}: {
  identity: string;
  onChannelCreated: (channel: IChannel) => void;
}) => {
  const { createChannel, loaded } = useChatServices();

  const handleSendMessage = useCallback(
    async (message: string) => {
      try {
        const channel = await createChannel({
          identities: [identity],
          message,
        });
        onChannelCreated(channel);
      } catch (error) {
        if (error instanceof Error) addError(error.message);
      }
    },
    [createChannel, identity, onChannelCreated]
  );

  if (!loaded) return null;

  return (
    <>
      <ViewHeader defaultBackHref="/chat">
        <ChatUserProfile identity={identity} format="compact" />
      </ViewHeader>

      <ViewContent className="chat-window">
        <ChatUserProfile identity={identity} />
      </ViewContent>

      <ViewFooter className="chat-footer bg-white pb-safe">
        <ComposeMessage to={identity} onSendMessage={handleSendMessage} />
      </ViewFooter>
    </>
  );
};
export default BlankConversation;
