import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register, required }: any) => (
  <FormInput
    name="name"
    label="Company name"
    placeholder="Enter company name"
    required={required}
    errors={errors}
    ref={register({
      required: 'Company name is required',
    })}
  />
);
