import company from './company';
import logo from './logo';
import location from './location';
import about from './about';
import skills from './skills';
import industries from './industries';
import social from './social';
import cover from './cover';
import photos from './photos';
import phone from './phone';

const url = 'edit-page';

export default [
  company(url),
  logo(url),
  location(url),
  about(url),
  skills(url),
  industries(url),
  social(url),
  cover(url),
  photos(url),
  phone(url),
];
