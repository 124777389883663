import React from 'react';
import Avatar from '../avatar/Avatar';

export type CardLogoProps = {
  imageUrl?: string | null;
  altText?: string;
  shape?: 'square' | 'circle';
  borderSize?: 'none' | 'sm' | 'md' | 'lg';
  logoSize?: 'sm' | 'md' | 'lg' | 'xl';
};

const CardLogo = ({
  imageUrl,
  altText = '',
  shape = 'circle',
  borderSize = 'sm',
  logoSize = 'sm',
}: CardLogoProps) => {
  const borderWidths: any = {
    none: 'border-0',
    sm: 'border-2',
    md: 'border-4',
    lg: 'border-5 shadow-soft-border',
  };

  const borderRadius: any = {
    square: 'rounded-lg+ overflow-hidden',
    circle: 'rounded-full',
  };
  // purgecss: rounded rounded-none rounded-sm rounded-lg rounded-lg+ rounded-xl rounded-2xl rounded-3xl rounded-full

  return (
    <div
      className={`${borderWidths[borderSize]} border-white ${borderRadius[shape]}`}
    >
      <Avatar
        size={logoSize}
        square={shape === 'square'}
        avatarName={altText}
        avatarUrl={imageUrl}
      />
    </div>
  );
};
export default React.memo(CardLogo);
