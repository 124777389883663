import React from 'react';
import { ReactComponent as Visa } from '../svg/visa.svg';
import { ReactComponent as MasterCard } from '../svg/mastercard.svg';
import { ReactComponent as Amex } from '../svg/amex.svg';
import { isoCountries } from '../../../helpers/countries';

// cards keys must match to recurly's cardType response
const cards: any = {
  Visa,
  MasterCard,
  'American Express': Amex,
};

type Props = {
  billingInfo: any;
  setShowPaymentForm: (show: boolean) => void;
};

const Metric = ({ label, value }: { label: string; value: any }) => (
  <div className="flex">
    <div className="w-24 font-semibold">{label}</div>
    <div className="flex-1">{value}</div>
  </div>
);

const BillingInfoSummary = ({ billingInfo, setShowPaymentForm }: Props) => {
  const { address, paymentMethod } = billingInfo;
  const Card = cards[paymentMethod.cardType];
  const country = isoCountries.find(({ code }) => code === address.country);

  return (
    <div className="space-y-2">
      <Metric
        label="Full Name"
        value={`${billingInfo.firstName} ${billingInfo.lastName}`}
      />
      <Metric
        label="Credit Card"
        value={
          <>
            <div className="flex justify-between">
              <p>
                {Card ? (
                  <Card className="inline-block" />
                ) : (
                  paymentMethod.cardType
                )}
                <span className="pl-2 pr-1">&#8943;</span>
                {paymentMethod.lastFour}
              </p>
              <button
                type="button"
                className="font-semibold"
                onClick={() => setShowPaymentForm(true)}
              >
                Change credit card
              </button>
            </div>
          </>
        }
      />
      <Metric
        label="Expiration"
        value={`${paymentMethod.expMonth}/${paymentMethod.expYear}`}
      />
      <Metric
        label="Address"
        value={
          <>
            <p>
              {address.region} {address.postalCode?.toUpperCase()}
            </p>
            <p>{country?.name ?? address.country}</p>
          </>
        }
      />
    </div>
  );
};
export default BillingInfoSummary;
