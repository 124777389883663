import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { About, Intro } from '../formElements';
import { SubmitButton } from '../../core/Button';
import UserContext from '../Context';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Video from '../formElements/Video';
import { canPlayVideo } from '../../video/VideoPlayer';

interface Props {
  user: UserEntity;
}

const AboutForm = ({ user }: Props) => {
  const [loading, setLoading] = useState(false);
  const { update, refresh } = useContext(UserContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      summary: user.summary || '',
      about: user.about || '',
      videoUrl: user.videoUrl || null,
    },
  });
  const { handleSubmit, register, errors } = form;

  // const [item, setItem] = useState<UserEntity>();

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = { ...values, about: values.about?.html ?? null };

    if (!canPlayVideo(data.videoUrl)) {
      data.videoUrl = null;
    }

    update(data)
      .then((response: UserEntity) => {
        addSuccess('Profile updated!');
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Intro errors={errors} register={register} form={form} />
        <Video form={form} />
        <About
          errors={errors}
          register={register}
          form={form}
          defaultValue={user.about}
        />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default AboutForm;
