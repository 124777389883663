import { SearchResponse } from '@algolia/client-search';
import orderBy from 'lodash/orderBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';
import { thousands } from '../../../utilities/Number';

export default function getSkillOptions(
  results: { [key: string]: SearchResponse },
  queryFilters: { [key: string]: {} | string }
) {
  const skillFacetOptions: any = {};
  const userSkillsFacetOptions = getFacetValuesFromResponse(
    results['user-skills'] || results['user-search'],
    'skills.skill.name'
  );
  const membershipSkillsFacetOptions = getFacetValuesFromResponse(
    results['membership-skills'] || results['membership-search'],
    'skills.skill.name'
  );
  Object.keys(userSkillsFacetOptions).forEach(key => {
    const count = skillFacetOptions[key] || 0;
    skillFacetOptions[key] = count + userSkillsFacetOptions[key];
  });
  Object.keys(membershipSkillsFacetOptions).forEach(key => {
    const count = skillFacetOptions[key] || 0;
    skillFacetOptions[key] = count + membershipSkillsFacetOptions[key];
  });

  let opts = skillFacetOptions;
  if (queryFilters.type === 'people') {
    opts = userSkillsFacetOptions;
  } else if (queryFilters.type === 'agency') {
    opts = membershipSkillsFacetOptions;
  }

  const skillOptions = Object.keys(opts).map((skillName: string) => {
    const count = opts[skillName] ?? 0;

    return {
      value: skillName,
      activeLabel: skillName,
      label: `${skillName} (${thousands(count)})`,
      count,
    };
  });

  return orderBy(skillOptions, ['count'], ['desc']);
}
