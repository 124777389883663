import React from 'react';
import StaffCard from './StaffCard';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import useForceMobile from '../../hooks/useForceMobile';

type Props = {
  membershipUsers: MembershipUserEntity[];
};

const ProfileStaff = ({ membershipUsers }: Props) => {
  const forceMobile = useForceMobile();

  const visibleEmployees = membershipUsers.filter(mu => mu.user.isSearchable);

  if (visibleEmployees.length <= 4) {
    return null;
  }

  return (
    <div id="staff-section" className="mt-12">
      <h4>Staff</h4>
      <div className={mc('mt-4 grid grid-cols-1 md:grid-cols-2', forceMobile)}>
        {visibleEmployees.map(employee => (
          <StaffCard key={employee.user.id} user={employee.user} />
        ))}
      </div>
    </div>
  );
};
export default ProfileStaff;
