import React, { useEffect, useState } from 'react';
import FreelancerSteps from './steps/FreelancerSteps';
import ProfileMeter from './ProfileMeter';
import ProfileMeterCircle from './ProfileMeterCircle';

declare type Props = {
  user: UserEntity;
  variation?: MeterVariation;
};

const FreelancerProgress = ({ user, variation }: Props) => {
  const [steps, setSteps] = useState<ProfileStep[]>([]);

  useEffect(() => {
    const newArray = Array.from(FreelancerSteps).map(step => {
      if (step.id === 'profile') {
        const complete = !!user.avatarURL;
        return { ...step, complete };
      }

      if (step.id === 'role') {
        const complete = !!user.roles.length;
        return { ...step, complete };
      }

      if (step.id === 'location') {
        const complete = user.location != null;
        return { ...step, complete };
      }

      if (step.id === 'about') {
        const complete = !!user.about;
        return { ...step, complete };
      }

      if (step.id === 'skills') {
        const complete = !!user.skills.length;
        return { ...step, complete };
      }

      if (step.id === 'industries') {
        const complete = !!user.industries.length;
        return { ...step, complete };
      }

      if (step.id === 'title') {
        const complete = !!user.title;
        return { ...step, complete };
      }

      if (step.id === 'social') {
        const links = Object.values(user.socialLinks).filter(link => {
          // Results in false if null or empty string
          return link;
        });

        const complete = !!links.length;
        return { ...step, complete };
      }

      if (step.id === 'portfolio') {
        const items = user.portfolioItems;
        const complete = !!items?.length;
        return { ...step, complete };
      }

      if (step.id === 'cover') {
        const complete = !!user.primaryMembershipUser?.membership.coverPhotoURL;
        return { ...step, complete };
      }

      return step;
    });

    setSteps(newArray);
  }, [user]);

  if (variation === 'circle') {
    return (
      <ProfileMeterCircle
        actionUrl="#/edit-profile/basic"
        title="Personal Profile"
        avatarAlt={`${user.firstName} ${user.lastName}`}
        avatarUrl={user.avatarURL || ''}
        steps={steps}
      />
    );
  }

  return <ProfileMeter title="Personal Profile" steps={steps} />;
};
export default FreelancerProgress;
