import React, { useState } from 'react';
import { addInfo } from '../../services/Messaging';
import Button from '../core/Button';
import Modal from '../core/modal/Modal';

type Props = {
  doDeletePublicUrl: () => void;
};

const ShortlistDeleteButton = ({ doDeletePublicUrl }: Props) => {
  const deletePublicUrl = () => {
    doDeletePublicUrl();
    addInfo('Public URL deleted!');
  };
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClick = (): void => {
    setConfirmDelete(true);
  };

  const handleConfirmDelete = (): void => {
    deletePublicUrl();
    setConfirmDelete(false);
  };

  const ConfirmDeleteModal = () => {
    return (
      <Modal
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        title="Warning"
        width="xl"
      >
        <div className="text-center">
          <h6>
            Deleting a shortlist link will disable it for anyone who received
            it.
          </h6>
          <br />

          <Button type="button" onClick={handleConfirmDelete} color="primary">
            <i className="i-trash btn__icon" />
            <span className="btn__text">Delete Shortlist</span>
          </Button>
        </div>
      </Modal>
    );
  };
  return (
    <>
      <Button color="tertiary" onClick={() => handleDeleteClick()}>
        <span>
          <i className="i-trash btn__icon" />
        </span>
      </Button>
      {confirmDelete && <ConfirmDeleteModal />}
    </>
  );
};

export default ShortlistDeleteButton;
