export default (url: string) => {
  return {
    id: 'location',
    label: 'Location, Location, Location',
    description:
      'We can better match you with work, events, and other members when we know where you are.',
    icon: 'marker',
    action: `#/${url}/basic`,
    actionLabel: 'Add Location',
    complete: false,
  };
};
