import React, { useEffect } from 'react';
import useFeatureGate from '../../../hooks/useFeatureGate';
import { currency } from '../../../utilities/Number';
import useRecurlyCoupon from '../../plan/hooks/useRecurlyCoupon';
import PlanFeatureList from '../../plan/PlanFeatureList';
import { getCouponDiscount } from '../../plan/util';
import useStep from '../../stepper/hooks/useStep';
import { UpgradeStepProps } from '../flows';

interface PricingPreviewProps {
  recurlyPlan: RecurlyPlanEntity;
  couponData?: RecurlyCoupon;
}

const PricingPreview = ({ recurlyPlan, couponData }: PricingPreviewProps) => {
  const discount = getCouponDiscount(recurlyPlan, couponData);
  const monthlyCharge = discount
    ? recurlyPlan.unitAmount - discount
    : recurlyPlan.unitAmount;

  return (
    <div className="text-lg mb-4">
      {discount ? (
        <span className="text-2xl font-light line-through mr-2">
          {currency(recurlyPlan.unitAmount)}
        </span>
      ) : null}
      <span className="text-2xl font-semibold">{currency(monthlyCharge)}</span>{' '}
      / month
    </div>
  );
};

const View = ({
  destPlan,
  coupon,
}: {
  destPlan: PlanEntity;
  coupon?: string;
}) => {
  const { name: planName } = destPlan;
  const recurlyPlan = destPlan?.recurlyPlans[0];
  const recurlyPlanCode = recurlyPlan?.recurlyCode ?? '';
  const { data: couponData } = useRecurlyCoupon(recurlyPlanCode, coupon);

  return (
    <div className="content">
      <h3>{planName}</h3>
      <PricingPreview recurlyPlan={recurlyPlan} couponData={couponData} />
      <div className="text-lg mb-4">
        <p>You are signing up for the {planName} plan which will give you:</p>
      </div>
      <div className="max-w-72 mx-auto text-left">
        <PlanFeatureList plan={destPlan} />
      </div>
    </div>
  );
};

const PlanScreen = ({ goToPrev, coupon }: UpgradeStepProps) => {
  const { destinationPlan } = useFeatureGate();
  const { setCanGoBack, setOnPrev, setCanAdvance, isFirstStep } = useStep();

  useEffect(() => {
    setCanGoBack(true);
  }, [setCanGoBack]);

  useEffect(() => {
    setCanAdvance(destinationPlan !== null);
  }, [destinationPlan, setCanAdvance]);

  useEffect(() => {
    if (isFirstStep) {
      setOnPrev(() => {
        goToPrev();
        return false;
      });
    }
  }, [goToPrev, isFirstStep, setOnPrev]);

  return (
    <>
      {destinationPlan ? (
        <View destPlan={destinationPlan} coupon={coupon} />
      ) : (
        <div>
          <p>We are having trouble finding a suitable plan.</p>
          <p>
            Please contact{' '}
            <a
              href="mailto:concierge@communo.com"
              className="text-link font-semibold"
            >
              concierge@communo.com
            </a>{' '}
            for help upgrading your plan.
          </p>
        </div>
      )}
    </>
  );
};
export default PlanScreen;
