import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import useAsyncEffect from 'use-async-effect';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Button from '../core/Button';
import { LoadingRing } from '../core/Loading';
import ModalCloseButton from '../core/modal/components/ModalCloseButton';
import Modal from '../core/modal/Modal';
import BackgroundColor from '../onboarding/Background';
import { Flow } from './Context';
import {
  ACTIONS,
  Screen as ScreenType,
  SetFlowScreensType,
  UpgradeStep,
} from './flows';
import Screen from './Screen';
import unknownFlowScreens from './flows/unknownFlow';
import useModal from '../core/modal/hooks/useModal';
import usePlans from '../plan/hooks/usePlans';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import { planCanAccess } from '../../utilities/permissionUtil';
import { APP_ENVIRONMENT, HBCU_PLAN_ID } from '../../config';
import useCurrentUser from '../../hooks/useCurrentUser';
import { track } from '../../analytics';
import useFeatureGate from '../../hooks/useFeatureGate';
import { addResponseError } from '../../services/Messaging';

const PLANS_BLOCKED_FROM_UPGRADE = [HBCU_PLAN_ID];

interface State {
  currentIndex: number;
}

function determineDestinationPlan(
  currentPlan: PlanEntity | null,
  availablePlans: PlanEntity[] | null,
  flow?: Flow
): PlanEntity | null {
  if (currentPlan == null || availablePlans == null) {
    return null;
  }

  if (!flow) {
    return availablePlans[0] ?? null;
  }

  // if (flow.name === 'directUpgrade') {
  //   return availablePlans[0] ?? null;
  // }

  const foundPlan = availablePlans.find(plan => {
    // Seems to be the only way to make typescript happy. not sure why returning early on line 52 isn't enough
    if (flow.name === 'directUpgrade') {
      return false;
    }

    return planCanAccess({
      plan,
      permission: flow.name,
      qualifier: flow.qualifier,
    });
  });

  if (foundPlan) {
    return foundPlan;
  }

  // If we couldn't find a plan, just give them the next plan in the track
  return availablePlans[0] ?? null;
}

function reducer(state: State, action: { type: ACTIONS }) {
  switch (action.type) {
    case 'NEXT':
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    case 'PREV':
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
      };
    default:
      return state;
  }
}

interface Props {
  slug?: string;
  coupon?: string;
  flowName?: string;
  contextualFlow?: string;
  flowScreens: ScreenType[];
  setFlowScreens: SetFlowScreensType;
  availablePlans: PlanEntity[];
  user?: UserEntity | null;
}

const UpgradeFlow = ({
  flowName,
  contextualFlow,
  slug,
  coupon,
  flowScreens,
  setFlowScreens,
  availablePlans,
  user,
}: Props) => {
  const [{ currentIndex }, dispatch] = useReducer(reducer, {
    currentIndex: 0,
  });
  const { destinationPlan } = useFeatureGate();
  const [stepperSteps, setStepperSteps] = useState<UpgradeStep[] | null>(null);
  const [purchasableItem, setPurchasableItem] = useState<string>();
  const content = flowScreens[currentIndex];
  const destinationPlanName = destinationPlan?.name;
  const isLastStep =
    flowScreens.length > 1 && currentIndex === flowScreens.length - 1;

  const {
    btnAction,
    btnLabel,
    title,
    icon,
    bgColor,
    Component,
    eventTrackingLabel,
    shouldTrackScreenEvent,
  } = content;

  const { closeModal } = useModal();

  const trackPurchasedItem = useCallback(
    async (purchasedItem: string) => {
      track('Purchased Item', { item: purchasedItem });

      if (APP_ENVIRONMENT === 'production') {
        try {
          // Zapier hook to alert the CX team
          await fetch('https://hooks.zapier.com/hooks/catch/1581683/boosx84', {
            method: 'POST',
            body: JSON.stringify({
              first_name: user?.firstName,
              last_name: user?.lastName,
              user_id: user?.id,
              item: purchasedItem,
            }),
          });
        } catch (error) {
          addResponseError(error);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    if (!shouldTrackScreenEvent) {
      return;
    }

    track(eventTrackingLabel, {
      permission: flowName,
      coupon,
      slug,
      contextualModal: contextualFlow,
      destinationPlan: destinationPlanName,
      items: purchasableItem,
    });

    // To prevent duplicate tracking calls, just fire when index changes (user goes forward or backward)
    // eslint-disable-next-line
  }, [currentIndex]);

  useEffect(() => {
    // TODO: When purchasableItem becomes an array of purchasableItems then fire tracking event for each item seperately
    if (isLastStep && purchasableItem) {
      trackPurchasedItem(purchasableItem);
    }
  }, [isLastStep, purchasableItem, trackPurchasedItem]);

  const goToNext = useCallback(() => {
    // Need to remove this check for now because of stale state when using useStateWithCallbackLazy
    // TODO: Figure out a way to get this check working again.
    // if (currentIndex === flowScreens.length - 1) {
    //   return;
    // }

    dispatch({ type: 'NEXT' });
  }, []);

  const goToPrev = useCallback(() => {
    if (currentIndex === 0) {
      return;
    }

    dispatch({ type: 'PREV' });
  }, [currentIndex]);

  return (
    <div className="w-full h-full relative">
      <div
        className="relative z-10 flex flex-col"
        style={{ minHeight: '600px' }}
      >
        <Screen title={title} icon={icon}>
          <div className="flex-1">
            {/* passing all this is gross.  A symptom of changing requirements. TODO: Refactor this. Maybe create a context? useFeatureGateContext? */}
            <Component
              goToNext={goToNext}
              goToPrev={goToPrev}
              flowName={flowName}
              contextualFlow={contextualFlow}
              slug={slug}
              coupon={coupon}
              availablePlans={availablePlans}
              setFlowScreens={setFlowScreens}
              stepperSteps={stepperSteps}
              setStepperSteps={setStepperSteps}
              purchasableItem={purchasableItem}
              setPurchasableItem={setPurchasableItem}
              eventTrackingLabel={eventTrackingLabel}
            />
          </div>

          {btnAction && (
            <Button
              reverse
              color="primary"
              className="w-full max-w-72 mt-8 mx-auto"
              onClick={() => {
                if (btnAction === 'CLOSE') {
                  closeModal();
                  return;
                }
                dispatch({ type: btnAction });
              }}
            >
              {btnLabel ?? 'Continue'}
            </Button>
          )}
        </Screen>
      </div>
      <BackgroundColor color={bgColor} />
      <div className="absolute top-0 right-0 py-4 z-20">
        <ModalCloseButton />
      </div>
    </div>
  );
};

interface WrappedProps {
  slug?: string;
  flow?: Flow;
  coupon?: string;
  onClose?(): void;
}

const WrappedUpgradeFlow = ({ slug, coupon, flow, onClose }: WrappedProps) => {
  const { destinationPlan, setDestinationPlan } = useFeatureGate();
  const [isOpen, setOpen] = useState(true);
  const [flowScreens, setFlowScreens] = useStateWithCallbackLazy<
    ScreenType[] | null
  >(null);
  const {
    plan: membershipPlan,
    membership,
    isIndividualPlan,
  } = useCurrentMembership();
  const { refresh, user } = useCurrentUser();
  const { plans, isLoading } = usePlans();
  const membershipType = membershipPlan?.type;
  const currentMembershipOrder = membershipPlan?.order ?? 0;
  const flowName = flow?.name;

  const onTrial = !!membership?.trialExpiresAt;

  /**
   * Needed to handle race condition where flow screens are dynamically imported and initially throws an error,
   * but due to a state change the component is re-rendered and then goes to fetch another flow set of flow screens
   * and the initial error ones are returned.
   */
  const handlingUnknownFlow = useRef(false);

  const availablePlans = useMemo(() => {
    if (!plans?.length) {
      return [];
    }

    return plans
      .filter(
        plan =>
          plan.status === 'active' && // must be an active plan
          plan.type === membershipType && // must be on the same track
          (plan.order > currentMembershipOrder ||
            (onTrial && plan.id === membershipPlan?.id)) && // if on trial, include current plan, else only above plans
          plan.isPaid // must be a paid plan (cannot upgrade to free plans)
      )
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [currentMembershipOrder, membershipPlan, membershipType, onTrial, plans]);

  const hasAvailablePlans = availablePlans?.length > 0 ?? false;

  useEffect(() => {
    if (!availablePlans?.length) {
      return;
    }

    if (slug) {
      const plan = availablePlans.find(p => p.slug === slug);
      if (plan) {
        setDestinationPlan(plan);
      }
      return;
    }

    const destPlan = determineDestinationPlan(
      membershipPlan,
      availablePlans,
      flow
    );

    setDestinationPlan(destPlan);
  }, [availablePlans, flow, membershipPlan, setDestinationPlan, slug]);

  const flowToLoad = useMemo(() => {
    let contextualFlow: any = flowName ?? 'unknownFlow';

    // Different flow for Agencies and Businesses that includes pay-to-post
    if (contextualFlow === 'canPostWorkToCommuno' && !isIndividualPlan) {
      contextualFlow = 'canPostWorkToCommunoCompany';
    }

    if (['numUserRoles', 'numMembershipRoles'].includes(contextualFlow)) {
      contextualFlow = 'numberOfRoles';
    }
    if (
      ['numUserIndustries', 'numMembershipIndustries'].includes(contextualFlow)
    ) {
      contextualFlow = 'numberOfIndustries';
    }

    if (!hasAvailablePlans) {
      contextualFlow = 'noUpgradeAvailable';
    } else if (
      destinationPlan &&
      !onTrial &&
      currentMembershipOrder > destinationPlan.order
    ) {
      contextualFlow = 'noUpgradeRequired';
    } else if (
      membershipPlan?.id &&
      PLANS_BLOCKED_FROM_UPGRADE.includes(membershipPlan.id)
    ) {
      contextualFlow = 'noUpgradeAvailable';
    }

    return contextualFlow;
  }, [
    flowName,
    isIndividualPlan,
    hasAvailablePlans,
    destinationPlan,
    onTrial,
    currentMembershipOrder,
    membershipPlan,
  ]);

  useAsyncEffect(async () => {
    if (isLoading) {
      return;
    }

    try {
      const screens = await import(`./flows/${flowToLoad}`);

      if (screens && handlingUnknownFlow.current === false) {
        setFlowScreens(screens.default, () => {});
      }
    } catch (e) {
      handlingUnknownFlow.current = true;
      // eslint-disable-next-line
      // console.error('Unable to load upgrade flow', e);
      setFlowScreens(unknownFlowScreens, () => {});
    }
  }, [flowToLoad, isLoading]);

  const closeModal = useCallback(() => {
    refresh();
    setOpen(false);

    if (onClose) {
      onClose();
    }
  }, [onClose, refresh]);

  return (
    <Modal
      isOpen={isOpen}
      onWillClose={closeModal}
      showHeader={false}
      paddingX="0"
      paddingY="0"
      width="lg"
    >
      {flowScreens && !isLoading ? (
        <UpgradeFlow
          slug={slug}
          coupon={coupon}
          flowName={flow?.name}
          contextualFlow={flowToLoad}
          flowScreens={flowScreens}
          setFlowScreens={setFlowScreens}
          availablePlans={availablePlans}
          user={user}
        />
      ) : (
        <div className="text-center py-20">
          <LoadingRing isActive color="primary" size="lg" />
        </div>
      )}
    </Modal>
  );
};

export default WrappedUpgradeFlow;
