import { select } from '../instances/MessageInstances';

/**
 * useChatMessageInstance
 *
 * Hook that returns a Message instance given a message sid.
 * The message instance should only ever be used to access instance methods
 */
const useChatMessageInstance = (sid: string) => {
  return {
    messageInstance: select(sid),
  };
};

export default useChatMessageInstance;
