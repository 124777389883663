import React from 'react';
import Panels from '../core/panels/panels';
import PerkMenu from './PerkMenu';
import { isMobile } from '../../utilities/Device';
import PageHeaderMobile from '../core/PageHeaderMobile';
import BundleForm from './forms/BundleForm';

const ContextPanel = () => {
  return (
    <>
      <div className="content">Context</div>
    </>
  );
};

const BundlesCreate = () => {
  return (
    <Panels leftPanel={<PerkMenu />} contextPanel={<ContextPanel />}>
      {toggleLeftPanel => (
        <div>
          {isMobile && (
            <PageHeaderMobile
              title="Perks"
              action={
                // eslint-disable-next-line
                <button
                  type="button"
                  onClick={() => toggleLeftPanel()}
                  className="btn btn--sm"
                >
                  Menu <i className="i-hamburger text-lg ml-xs" />
                </button>
              }
            />
          )}
          <div className="px-4 py-6 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
            <h1>New Bundle</h1>
            <BundleForm />
          </div>
        </div>
      )}
    </Panels>
  );
};
export default BundlesCreate;
