import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Preferences } from '@capacitor/preferences';
import { Deploy } from 'cordova-plugin-ionic';
import React from 'react';
import ReactDOM from 'react-dom';
import { setupConfig } from '@ionic/core';
import { arrowBackOutline } from 'ionicons/icons';
import App from './App';
import { DO_DEPLOY_UPDATE } from './config';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
const fakeSplash = document.getElementById('pseudoSplashscreen');

setupConfig({
  rippleEffect: false,
  // animated: false,
  // mode: 'md'
  statusTap: true,
  backButtonIcon: arrowBackOutline,
  backButtonText: '',
  swipeBackEnabled: true,
  experimentalTransitionShadow: false,
  inputBlurring: false, // fixes content selectable keyboard / input jumping on iOS
});

function hideLoadingScreen() {
  setTimeout(() => {
    // eslint-disable-next-line no-unused-expressions
    fakeSplash?.classList.add('pseudoSplash--hide');
    // eslint-disable-next-line no-unused-expressions
    fakeSplash?.classList.remove('splash-animating');
  }, 2000);
}

const mountApp = async () => {
  await SplashScreen.hide();
  const { value: launchUrl } = await Preferences.get({
    key: 'launchUrl',
  });

  if (launchUrl) {
    window.location.replace(launchUrl);
    await Preferences.remove({ key: 'launchUrl' });
  }

  ReactDOM.render(<App />, root);
};

async function bootLogic() {
  try {
    // Checking for update. Always check on boot.
    const update = await Deploy.checkForUpdate();

    Preferences.set({
      key: 'lastCheckedForUpdate',
      value: JSON.stringify({
        timestamp: new Date().getTime() + 60000,
      }),
    });

    if (update.available && DO_DEPLOY_UPDATE) {
      await Deploy.sync({ updateMethod: 'auto' }, percentDone => {
        if (percentDone === 100) {
          // console.log('mount: deploy is finished downloading');
        }
      });
    } else {
      mountApp();
    }
  } catch (err) {
    mountApp();
  }
}

if (Capacitor.isNativePlatform()) {
  bootLogic();
} else {
  mountApp();
  hideLoadingScreen();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
