import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { PerkBundle } from '@communo-corp/shared-types';
import Panels from '../core/panels/panels';
import PerkMenu from './PerkMenu';
import { isMobile } from '../../utilities/Device';
import PageHeaderMobile from '../core/PageHeaderMobile';
import BundleForm from './forms/BundleForm';
import perksApi from './Api';
import { addResponseError } from '../../services/Messaging';
import { Link } from '../core/router';
import AdminSidebar from './AdminSidebar';

const BundlesEdit = () => {
  const [bundle, setBundle] = useState<PerkBundle>();
  const { id: bundleId } = useParams();

  const getBundle = useCallback((id: string) => {
    perksApi
      .retrieveBundle(id)
      .then((response: PerkBundle) => {
        setBundle(response);
      })
      .catch(addResponseError)
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (bundleId) {
      getBundle(bundleId);
    }
  }, [getBundle, bundleId]);

  return (
    <Panels leftPanel={<PerkMenu />} contextPanel={<AdminSidebar />}>
      {toggleLeftPanel => (
        <div>
          {isMobile && (
            <PageHeaderMobile
              title="Perks"
              action={
                // eslint-disable-next-line
                <button
                  type="button"
                  onClick={() => toggleLeftPanel()}
                  className="btn btn--sm"
                >
                  Menu <i className="i-hamburger text-lg ml-xs" />
                </button>
              }
            />
          )}
          <div className="px-4 py-6 pt-20 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
            <div className="flex justify-between items-center">
              <h1>Edit Bundle</h1>
              <Link
                to={`/perks/bundles/${bundle?.id}`}
                className="btn btn--outline btn--primary btn--sm"
              >
                View
              </Link>
            </div>
            {bundle && <BundleForm bundle={bundle} isEdit />}
          </div>
        </div>
      )}
    </Panels>
  );
};
export default BundlesEdit;
