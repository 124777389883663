import React from 'react';
import { LoadingRing } from '../../core/Loading';
import OnboardingLayout from '../../onboarding/OnboardingLayout';
import { SearchProvider } from '../../search/Context';
import { SubmitRecommendationContextProvider } from '../context/SubmitRecommendationContext';
import useRecommendationByToken from '../hooks/useRecommendationByToken';
import SubmitRecommendationStepper from './SubmitRecommendationStepper';

type Props = {
  submissionToken: string;
};
const SubmitRecommendationForm = ({ submissionToken }: Props) => {
  const { isFetching, recommendation, searchToken } = useRecommendationByToken(
    submissionToken
  );

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  const layoutBrand = recommendation.user?.plan?.subType ?? 'communo';

  return (
    <SearchProvider searchTokenOverride={searchToken}>
      <OnboardingLayout organization={layoutBrand}>
        <SubmitRecommendationContextProvider
          recommendation={recommendation}
          submissionToken={submissionToken}
        >
          <SubmitRecommendationStepper />
        </SubmitRecommendationContextProvider>
      </OnboardingLayout>
    </SearchProvider>
  );
};
export default SubmitRecommendationForm;
