import React from 'react';
import Card from './Card';
import CardImage from './CardImage';
import VettedBadge from '../badge/VettedBadge';

type Props = {
  logoURL: string;
  orgName: string;
  label: string;
  vetted?: boolean;
};
const OrganizationCard = ({
  logoURL,
  orgName,
  label,
  vetted = false,
}: Props) => {
  const image = (
    <CardImage
      imageUrl={logoURL}
      altText={orgName}
      borderRadius="large"
      roundedBottom
      aspectRatio="square"
    />
  );

  return (
    <Card
      imageComponent={image}
      borderRadius="small"
      className="card--member-small"
    >
      <div className="card__body text-center relative">
        {vetted && (
          <div className="card__inset">
            <VettedBadge />
          </div>
        )}
        <h2 className="font-sans font-medium text-base">{orgName}</h2>
        {label && <p className="text-caption text-subdued mt-1">{label}</p>}
      </div>
    </Card>
  );
};
export default OrganizationCard;
