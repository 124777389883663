import { Api, BaseAPI } from '../../Api';

const customApi = {
  newPage: async () => Api.post('/scheduler/newPage'),
  fetchSchedulerPages: async () => Api.get('/scheduler/pages'),
  deleteSchedulerPage: async (pageId: string) =>
    Api.delete(`/scheduler/page/${pageId}`),
};

const schedulerApi = { ...BaseAPI('scheduler'), ...customApi };

export default schedulerApi;
