import React from 'react';
import { HashRoute } from '../Routes';
import CreateEducation from '../../education/form/CreateEducation';
import ListEducation from '../../education/form/ListEducation';
import EditEducation from '../../education/form/EditEducation';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { LoadingRing } from '../../core/Loading';

const EducationForm = () => {
  const { id: authUserId } = useCurrentUser();

  if (!authUserId) {
    return <LoadingRing isActive />;
  }

  return (
    <>
      <HashRoute
        hash="#/edit-profile/education/create"
        component={CreateEducation}
      />
      <HashRoute
        userId={authUserId}
        hash="#/edit-profile/education/edit/:educationId"
        component={EditEducation}
      />
      <HashRoute
        userId={authUserId}
        hash="#/edit-profile/education"
        component={ListEducation}
      />
    </>
  );
};
export default EducationForm;
