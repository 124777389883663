import React, { useContext, useRef } from 'react';
import { Redirect } from 'react-router';
import useAnalytics from '../hooks/useAnalytics';
import NewGroupConversationSettings from '../components/chat/views/NewGroupConversationSettings';
import NewGroupChatContext from '../components/chat/context/ChatNewGroupContext';
import View, {
  ViewContent,
  ViewFooter,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import BackButton from '../components/core/BackButton';
import { ImperativeHandleRef } from '../@types/conversation.d';

export default () => {
  useAnalytics('New Group Message Info');

  const childMethods = useRef<ImperativeHandleRef>(null);
  const { members } = useContext(NewGroupChatContext);

  if (!members.length) {
    return <Redirect to="/chat" />;
  }

  const handleSubmit = () => {
    if (childMethods?.current) childMethods.current.submit();
  };

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New Group Settings</h6>
          <NewGroupConversationSettings ref={childMethods} />
        </ViewContent>
        <ViewFooter className="actions">
          <BackButton
            className="btn btn--primary btn--sm btn--outline"
            defaultHref="/chat"
          />
          <button
            type="button"
            className="btn btn--sm btn--primary ml-4"
            onClick={handleSubmit}
          >
            Create
          </button>
        </ViewFooter>
      </>
    );

  const SaveButton = () => (
    <button
      type="button"
      className="btn btn--sm btn--primary btn--rev"
      onClick={handleSubmit}
    >
      Create
    </button>
  );

  return (
    <View authGuard darkStatusBar pageTitle="New Group Settings">
      <ViewHeader defaultBackHref="/chat" end={<SaveButton />}>
        <ViewHeaderTitle>New Group Settings</ViewHeaderTitle>
      </ViewHeader>
      <ViewContent layout="chat">
        <NewGroupConversationSettings ref={childMethods} />
      </ViewContent>
    </View>
  );
};
