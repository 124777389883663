import React from 'react';
import useNavigate from '../../../hooks/useNavigate';
import Metric from '../../candidateScore/Metric';
import { MetricStatus } from '../../candidateScore/MetricStatus';
import ProfileCompletionFooter from '../../profileCompleteness/ProfileCompletionFooter';
import ProfileCompletionHeader from '../../profileCompleteness/ProfileCompletionHeader';
import {
  getUserCompletionStage,
  getUserProfileRequirements,
} from '../../profileCompleteness/utils/profile';
import userCompletenessStore, {
  UserProfileAttribute,
} from '../state/userCompletenessStore';

interface CriteriaItem {
  key: UserProfileAttribute;
  label: string;
  actionUrl: string;
  status: MetricStatus | null;
  description?: string;
}

type Criteria = Partial<Record<UserProfileAttribute, CriteriaItem>>;

export interface UserProfileRequirements {
  stageOne: UserProfileAttribute[];
  stageTwo: UserProfileAttribute[];
  stageThree: UserProfileAttribute[];
}

export type CompletionStage = keyof UserProfileRequirements;

const steps: Criteria = {
  avatarURL: {
    key: 'avatarURL',
    label: 'Basic info',
    actionUrl: '#/edit-profile/basic',
    status: null,
    description:
      'Complete your basic info by uploading your profile picture (or avatar), and provide your name, title and location.',
  },
  summary: {
    key: 'summary',
    label: 'Intro',
    actionUrl: '#/edit-profile/about',
    status: null,
    description: 'Describe yourself in one sentence.',
  },
  about: {
    key: 'about',
    label: 'About',
    actionUrl: '#/edit-profile/about',
    status: null,
    description: 'Describe your professional self in detail.',
  },
  socialLinks: {
    key: 'socialLinks',
    label: 'Links',
    actionUrl: '#/edit-profile/links',
    status: null,
    description: 'Provide at least one website or social link.',
  },
  title: {
    key: 'title',
    label: 'Title',
    actionUrl: '#/edit-profile/basic',
    status: null,
    description: 'Add your job title to your profile.',
  },
  skills: {
    key: 'skills',
    label: 'Skills',
    actionUrl: '#/edit-profile/roles',
    status: null,
    description: 'Add your relevant skills to your profile.',
  },
  industries: {
    key: 'industries',
    label: 'Industries',
    actionUrl: '#/edit-profile/roles',
    status: null,
    description: 'Add your industry experience to your profile.',
  },
  education: {
    key: 'education',
    label: 'Education',
    actionUrl: '#/edit-profile/education',
    status: null,
    description: 'Tell us where you went to school.',
  },
  rate: {
    key: 'rate',
    label: 'Rate',
    actionUrl: '#/edit-profile/rate',
    status: null,
    description: 'Add your hourly rate to your profile in U.S. dollars.',
  },
  portfolioItems: {
    key: 'portfolioItems',
    label: 'Portfolio',
    actionUrl: '#/edit-profile/portfolio',
    status: null,
    description:
      'Add your projects to your profile (at least 3 portfolio items are required to earn your verified badge).',
  },
  videoUrl: {
    key: 'videoUrl',
    label: 'Video',
    actionUrl: '#/edit-profile/about',
    status: null,
    description: 'If a picture’s worth a 1,000 words…',
  },
  references: {
    key: 'references',
    label: 'References',
    actionUrl: '#/edit-profile/references-vetting',
    status: null,
    description:
      'Add references to your profile (at least 3 references are required to earn your verified badge).',
  },
  coverPhotoURL: {
    key: 'coverPhotoURL',
    label: 'Cover Photo',
    actionUrl: '#/edit-profile/basic',
    status: null,
    description: 'We all judge a book by its cover',
  },
};

export const freelancerRequirements: UserProfileRequirements = {
  stageOne: ['avatarURL', 'summary', 'about', 'socialLinks'],
  stageTwo: [
    'title',
    'skills',
    'industries',
    'rate',
    'portfolioItems',
    'references',
  ],
  stageThree: ['education', 'coverPhotoURL', 'videoUrl'],
};

export const employeeRequirements: UserProfileRequirements = {
  stageOne: ['avatarURL', 'summary', 'about'],
  stageTwo: ['title', 'skills', 'industries', 'socialLinks'],
  stageThree: ['education', 'portfolioItems', 'videoUrl'],
};

function evaluateCriteria(
  requirements: UserProfileAttribute[]
): Array<CriteriaItem | null> {
  const [completenessStore] = userCompletenessStore;
  const [getStatusFor] = completenessStore(state => [state.getStatusFor]);

  return requirements.map(requirement => {
    const step = steps[requirement];

    if (step) {
      const status = getStatusFor(requirement);

      return {
        ...step,
        status,
      };
    }

    return null;
  });
}

interface Props {
  isIndividualPlan: boolean;
  membership: MembershipEntity;
}

const ProfileCompletion = ({ isIndividualPlan, membership }: Props) => {
  const navigate = useNavigate();
  const requirements = getUserProfileRequirements(isIndividualPlan);
  const currentStage = getUserCompletionStage(requirements);
  const criteria = evaluateCriteria(requirements[currentStage]);
  // const isVerified = membership?.vettingStatus === 'pending';
  // const isVetted = membership?.vettingStatus === 'vetted';

  return (
    <div className="-ml-8 -mr-8 md:-ml-4">
      <ProfileCompletionHeader requirements={requirements} />

      {currentStage === 'stageThree' ? (
        <div className="pt-4 pb-1 px-5">
          You can make your profile even better by adding the following:
        </div>
      ) : null}

      <div className="px-5 py-xxs">
        {criteria.map(item => {
          if (item === null) {
            return null;
          }

          return (
            <div className="py-xxs" key={item.key}>
              <Metric
                label={item.label}
                status={item.status}
                description={item.description}
                onClick={() => {
                  navigate(item.actionUrl);
                }}
              />
            </div>
          );
        })}
      </div>

      <ProfileCompletionFooter
        completionStage={currentStage}
        // isVetted={isVetted}
        // isVerified={isVerified}
        vettingStatus={membership?.vettingStatus}
        isIndividualPlan={isIndividualPlan}
      />
    </div>
  );
};
export default ProfileCompletion;
