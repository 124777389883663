import React from 'react';
import { DiscoverListItem, DiscoverListProps } from '..';
import cloudinary from '../../../utilities/Cloudinary';
import { Link } from '../../core/router';
import { Avatar } from '../slides/index';

const DiscoverListJobMatch = ({
  items,
  heading,
  // subHeading,
  imageUrl,
  actionUrl,
  actionText,
}: DiscoverListProps) => {
  return (
    <div className="discover-job-match">
      <div className="w-9/10 mx-auto">
        <div className="row-flex">
          <div className="col 2xl:w-7/12 py-16">
            <div className="mb-16">
              <h2 className="discover-job-match__title">
                <span className="font-light">Job</span> Match
              </h2>
              {heading && <h3>{heading}</h3>}
            </div>
            <div className="2xl:pr-20 4xl:pr-40">
              <div className="row-flex">
                {items.map((item: DiscoverListItem) => {
                  return (
                    <div
                      key={item.id}
                      className="col w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 2xl:w-1/4 3xl:w-1/4 4xl:w-1/5 5xl:w-1/6 mb-12"
                    >
                      <Avatar
                        item={item}
                        headingClassName="text-white"
                        subHeadingClassName="text-black"
                      />
                    </div>
                  );
                })}
              </div>
              {actionUrl ? (
                <div className="mt-4 text-center xl:text-right">
                  <Link to={actionUrl} className="btn btn--tertiary">
                    {actionText ?? 'See all matches'}
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {imageUrl ? (
        <img
          className="hidden 2xl:block absolute top-0 right-0 bottom-0 w-5/12 h-full object-cover z-0"
          alt="company"
          src={cloudinary(imageUrl, ['w_800', 'h_800'])}
        />
      ) : null}
    </div>
  );
};
export default DiscoverListJobMatch;
