import React from 'react';
import ResetPassword from '../components/user/views/ResetPassword';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';
import { DEFAULT_RESET_COVER_PHOTO_URL } from '../asset-config';

export default ({ match }: any) => {
  const { resetCode } = match.params;

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Reset Password"
      mobileHeader={() => null}
    >
      <SignupLayout headerImg={DEFAULT_RESET_COVER_PHOTO_URL}>
        <>
          <ResetPassword resetCode={resetCode} />
        </>
      </SignupLayout>
    </Page>
  );
};
