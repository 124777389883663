/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import { PerkBundle, PerkCategory } from '@communo-corp/shared-types';
import { useHistory } from 'react-router';
import Panels from '../core/panels/panels';
import PerkMenu from './PerkMenu';
import PageHeaderMobile from '../core/PageHeaderMobile';
import { isMobile } from '../../utilities/Device';
import { LoadingBalls } from '../core/Loading';
import { addResponseError } from '../../services/Messaging';
import perksApi from './Api';
import PerkBundleTable from './PerkBundleTable';
import AdminSidebar from './AdminSidebar';

const BundlesManage = () => {
  const [bundles, setBundles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();

  const getBundles = useCallback(() => {
    perksApi
      .listAllBundles()
      .then((response: any) => {
        setBundles(response);
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getBundles();
  }, [getBundles]);

  const refreshList = () => {
    getBundles();
  };

  const data = React.useMemo(() => {
    return bundles.map((bundle: PerkBundle) => {
      const {
        id,
        membership,
        name: bundleName,
        tagline,
        categories,
        isFeatured,
        order,
        isActive,
      } = bundle;

      let isPartnerBundle;
      if (membership) {
        isPartnerBundle = membership.name || false; // Collections sometimes have a membership and membership ID but null name for some reason
      } else {
        isPartnerBundle = false;
      }

      return {
        id,
        name: bundleName || membership?.name,
        type: isPartnerBundle ? 'Partner' : 'Collection',
        tag: tagline,
        category: categories?.map((category: PerkCategory) => {
          return category.name;
        }),
        featured: isFeatured ? 'true' : 'false',
        order,
        active: isActive ? 'true' : 'false',
      };
    });
  }, [bundles]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Tag',
        accessor: 'tag',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Featured',
        accessor: 'featured',
      },
      {
        Header: 'Order',
        accessor: 'order',
      },
      {
        Header: 'Active',
        accessor: 'active',
      },
    ];
  }, []);

  const handleClick = (row: any) => {
    const { id } = row.original;
    push(`/perks/bundles/${id}/edit`);
  };

  return (
    <Panels leftPanel={<PerkMenu />} contextPanel={<AdminSidebar />}>
      {toggleLeftPanel => (
        <div>
          {isMobile && (
            <PageHeaderMobile
              title="Perks"
              action={
                // eslint-disable-next-line
                <button
                  type="button"
                  onClick={() => toggleLeftPanel()}
                  className="btn btn--sm"
                >
                  Menu <i className="i-hamburger text-lg ml-xs" />
                </button>
              }
            />
          )}
          <div className="px-4 py-6 pt-20 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16">
            <LoadingBalls isActive={loading} fullscreen />
            <div className="flex justify-between mb-4">
              <h1>Manage Bundles</h1>
              <button
                type="button"
                className="btn btn--outline btn--primary btn--sm"
                onClick={refreshList}
              >
                Refresh List
              </button>
            </div>

            <PerkBundleTable
              columns={columns}
              data={data}
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </Panels>
  );
};
export default BundlesManage;
