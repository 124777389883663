import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Education } from '@communo-corp/shared-types';
import UserContext from '../user/Context';
import EducationApi from './Api';

interface SchoolValue {
  value: string;
  label: string;
}

type Entity = {
  id?: string | undefined;
  userId?: string | undefined;
  country?: string | null;
  graduationDate: string;
  major: string | null;
  minor: string | null;
  school: SchoolValue | null;
  schoolId: string | undefined;
  createdAt?: Date;
  updatedAt?: Date;
};

interface EducationContextInterface {
  user: UserEntity | null;
  education: Education[];
  isFetching: Boolean;
  addEducation(data: Entity): Promise<Education[]>;
  refreshEducation(): void;
  removeEducation(id: String): void;
  editEducation(id: String, education: Entity): void;
}

type stateType = {
  education: Education[];
  isFetching: Boolean;
};

const EducationContext = React.createContext<EducationContextInterface>({
  user: {} as UserEntity,
  education: [],
  isFetching: false,
  addEducation: () => Promise.resolve({} as Education[]),
  refreshEducation: () => {},
  removeEducation: () => {},
  editEducation: () => {},
});

const EducationProviderC = ({ children }: { children: React.ReactNode }) => {
  const { user: authUser, isPublic } = useContext(UserContext);
  const [educationData, setData] = useState<stateType>({
    education: [],
    isFetching: true,
  });

  const updateState = useCallback((key, value) => {
    setData(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    updateState('isFetching', true);
    EducationApi.listEducationForUser(authUser.id, isPublic)
      .then(edu => {
        updateState('education', edu);
      })
      .catch(() => {
        updateState('education', []);
      })
      .finally(() => {
        updateState('isFetching', false);
      });
  }, [authUser, isPublic, updateState]);

  const addEducation = async (data: Entity) => {
    updateState('isFetching', true);
    const edu = await EducationApi.createEducation(data);
    updateState('education', [...educationData.education, edu]);
    updateState('isFetching', false);
    return educationData.education;
  };

  const refreshEducation = async () => {
    if (!authUser) {
      return;
    }
    updateState('isFetching', true);
    const edu = await EducationApi.listEducationForUser(authUser.id, isPublic);
    updateState('education', edu);
    updateState('isFetching', false);
  };

  const removeEducation = async (id: string) => {
    updateState('isFetching', true);
    await EducationApi.deleteEducation(id);
    const education = educationData.education.filter((edu: Education) => {
      return edu.id !== id;
    });
    updateState('education', education);
    updateState('isFetching', false);
  };

  const editEducation = async (id: string, data: Entity) => {
    updateState('isFetching', true);
    const result = await EducationApi.editEducation(id, data);
    const eduIndex = educationData.education.findIndex(
      (e: Education) => e.id === id
    );
    educationData.education[eduIndex] = result;
    updateState('education', educationData.education);
    updateState('isFetching', false);
  };

  return (
    <EducationContext.Provider
      value={{
        user: authUser,
        education: educationData.education,
        isFetching: educationData.isFetching,
        addEducation,
        refreshEducation,
        removeEducation,
        editEducation,
      }}
    >
      {children}
    </EducationContext.Provider>
  );
};

const MemoProvider = React.memo(EducationProviderC);

export const EducationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <MemoProvider>{children}</MemoProvider>;
};

export default EducationContext;
