import { useEffect, useState, useCallback, useContext } from 'react';
import keyBy from 'lodash/keyBy';
import { keys } from 'lodash';
import Api from '../components/work/Api';
import { sortApplications } from '../utilities/ApplicationStatus';
import useUserAllowList from '../components/user/hooks/useUserAllowList';
import UserContext from '../components/user/Context';

type State = {
  workId: string;
  data: KeyedApplications;
  loading: boolean;
};

type WorkApplications = {
  state: State;
  setState: Function;
  refresh: (id: string) => Promise<any>;
  patch: (data: ApplicationEntity | ApplicationEntity[]) => Promise<any>;
  createPublicUrl: () => Promise<any>;
  deletePublicUrl: () => Promise<any>;
  hire: (applicationId: string) => Promise<any>;
  reject: (applicationId: string) => Promise<any>;
};

const useWorkApplications = (workId: string): WorkApplications => {
  const { isPublic } = useContext(UserContext);
  const { refetchAllowList } = useUserAllowList();
  const [state, setState] = useState<State>({
    workId,
    data: {},
    loading: true,
  });

  const updateState = useCallback((key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const refresh = useCallback(
    id => {
      if (id) {
        updateState('loading', true);
        return (isPublic ? Api.publicApplications(id) : Api.applications(id))
          .then(response => {
            const sorted = sortApplications(response);
            updateState('data', keyBy(sorted, 'id'));

            // Refetch our allow list to ensure its up to date with the applications we just retrieved
            refetchAllowList();
          })
          .catch(() => {
            updateState('data', {});
          })
          .finally(() => {
            updateState('loading', false);
          });
      }
      return Promise.resolve();
    },
    [refetchAllowList, updateState, isPublic]
  );

  const patch = useCallback(
    async (data: any, updateApi: boolean = true) => {
      // combine the changes with the original data
      const merged = { ...state.data };
      keys(data).forEach((id: string) => {
        merged[id] = { ...merged[id], ...data[id] };
      });

      // optimistically update state
      updateState('data', merged);

      if (!updateApi) return;

      // update the API
      // eslint-disable-next-line consistent-return
      return Api.patchApplications(workId, data);
    },
    [state.data, updateState, workId]
  );

  const createPublicUrl = useCallback(async () => {
    const result = await Api.createPublicUrl(workId);
    return result;
  }, [workId]);

  const deletePublicUrl = useCallback(async () => {
    const result = await Api.deletePublicUrl(workId);
    return result;
  }, [workId]);

  const hire = useCallback(
    async (applicationId: string, sendEmail = false) => {
      // get application data
      const application = state.data[applicationId];
      if (!application) return;

      const isCurrentAwarded = application.status === 'awarded';
      const status = isCurrentAwarded ? 'shortlisted' : 'awarded';

      // patch the application to update the status to 'awarded'
      let result = await patch({
        [applicationId]: {
          status,
          awardedAt: isCurrentAwarded ? null : new Date(),
        },
      });

      if (sendEmail && !isCurrentAwarded) {
        result = await Api.hire(workId, applicationId);
      }

      return result;
    },
    [workId, state.data, patch]
  );

  const reject = useCallback(
    async (applicationId: string) => {
      // get application data
      const application = state.data[applicationId];
      if (!application) return;

      const isCurrentClientRejected = application.status === 'client_rejected';
      const status = isCurrentClientRejected
        ? 'shortlisted'
        : 'client_rejected';

      // patch the application to update the status to 'awarded'
      const result = await patch({
        [applicationId]: { status },
      });

      return result;
    },
    [state.data, patch]
  );

  useEffect(() => {
    refresh(workId);
  }, [refresh, workId]);

  return {
    state,
    setState,
    refresh,
    patch,
    createPublicUrl,
    deletePublicUrl,
    hire,
    reject,
  };
};

export default useWorkApplications;
