import React, { useEffect, useState } from 'react';
import PartnerSteps from './steps/PartnerSteps';
import ProfileMeter from './ProfileMeter';
import ProfileMeterCircle from './ProfileMeterCircle';

declare type Props = {
  membership: MembershipEntity;
  variation?: MeterVariation;
};

const PartnerProgress = ({ membership, variation }: Props) => {
  const [steps, setSteps] = useState<ProfileStep[]>([]);

  useEffect(() => {
    const newArray = Array.from(PartnerSteps).map(step => {
      if (step.id === 'company') {
        const complete = !!membership.name;
        return { ...step, complete };
      }

      if (step.id === 'logo') {
        const complete = !!membership.logoURL;
        return { ...step, complete };
      }

      if (step.id === 'location') {
        const complete = membership.location != null;
        return { ...step, complete };
      }

      if (step.id === 'about') {
        const complete = !!membership.about;
        return { ...step, complete };
      }

      if (step.id === 'skills') {
        const complete = !!membership.skills.length;
        return { ...step, complete };
      }

      if (step.id === 'industries') {
        const complete = !!membership.industries.length;
        return { ...step, complete };
      }

      if (step.id === 'social') {
        const links = Object.values(membership.socialLinks).filter(link => {
          // Results in false if null or empty string
          return link;
        });

        const complete = !!links.length;
        return { ...step, complete };
      }

      if (step.id === 'cover') {
        const complete = !!membership.coverPhotoURL;
        return { ...step, complete };
      }

      if (step.id === 'photos') {
        const complete = !!membership.imageURLs;
        return { ...step, complete };
      }

      if (step.id === 'phone') {
        const complete = !!membership.phoneNumber;
        return { ...step, complete };
      }

      return step;
    });

    setSteps(newArray);
  }, [membership]);

  if (variation === 'circle') {
    return (
      <ProfileMeterCircle
        actionUrl="#/edit-page/basic"
        title="Partner Profile"
        avatarAlt={membership.name}
        avatarUrl={membership.logoURL}
        steps={steps}
      />
    );
  }

  return <ProfileMeter title="Partner Profile" steps={steps} />;
};
export default PartnerProgress;
