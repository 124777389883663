import React from 'react';
import EventCard from '../../card/EventCard';

// interface Props extends ListItemComponentProps {}
interface Props {
  item: EventEntity;
}

const Event = ({ item }: Props) => {
  // console.log(item);

  return <EventCard event={item} cardStyle="limited" className="shadow-lg" />;
};
export default Event;
