import React, { useCallback, useEffect, useState } from 'react';
import useProjectTypes from '../../../hooks/useProjectTypes';
import RecommendedSkills, { PickerSkill, SkillType } from './RecommendedSkills';
import Skills from './Skills';

type Props = {
  form: any;
  isMulti: boolean;
  defaultValues: any;
  showRecommended: boolean;
  projectTypes: any;
};

const SkillsWithRecommended = ({
  form,
  isMulti,
  defaultValues,
  showRecommended,
  projectTypes,
}: Props) => {
  const { projectOptions } = useProjectTypes();
  const { setValue, watch } = form;
  const name = 'skills';

  const values = watch(name);

  const [internalDefaultValues, setInternalDefaultValues] = useState(
    defaultValues
  );

  const [recommendedSkills, setRecommendedSkills] = useState(
    [] as PickerSkill[]
  );

  useEffect(() => {
    if (projectTypes && projectOptions) {
      const filteredOptions = projectOptions.filter(
        projectOption => projectOption.id === projectTypes.value
      );

      if (projectOptions.length > 0) {
        const { skills } = filteredOptions[0];
        if (skills) {
          setRecommendedSkills(
            skills.map(skill => ({
              value: skill.id,
              label: skill.name,
              description: skill.name,
              categories: [projectTypes.name, filteredOptions[0].name],
            }))
          );
        } else {
          setRecommendedSkills([]);
        }
      } else {
        setRecommendedSkills([]);
      }
    } else {
      setRecommendedSkills([]);
    }
  }, [values, projectTypes, projectOptions, setRecommendedSkills]);

  const removeRecommendedSkill = useCallback(
    (skillId: string) => {
      const newSkills = recommendedSkills.filter(
        skill => skill.value !== skillId
      );
      setRecommendedSkills(newSkills);
    },
    [recommendedSkills, setRecommendedSkills]
  );

  useEffect(() => {
    setInternalDefaultValues(values);
  }, [values, setInternalDefaultValues]);

  useEffect(() => {
    // check if recommendedSkills contain any of the values
    let recommended = recommendedSkills;
    recommendedSkills.forEach(skill => {
      if (values?.some((value: any) => skill.value === value.value)) {
        recommended = recommended.filter(
          (value: any) => value.value !== skill.value
        );
      }
    });
    if (recommended.length !== recommendedSkills.length) {
      setRecommendedSkills(recommended);
    }
  }, [values, recommendedSkills, setRecommendedSkills]);

  return (
    <>
      <Skills
        form={form}
        max={5}
        isMulti={isMulti}
        defaultValues={internalDefaultValues}
        value={internalDefaultValues}
        name={name}
      />
      {showRecommended && (
        <RecommendedSkills
          selectedSkills={recommendedSkills}
          selectSkill={(skill: SkillType) => {
            removeRecommendedSkill(skill.value);

            if (values?.some((s: any) => s.value === skill.value)) {
              return;
            }

            const newValues = values ? [...values, skill] : [skill];

            setValue(name, newValues, {
              shouldValidate: true,
              shouldDirty: true,
            });
            setInternalDefaultValues(newValues);
          }}
          maxSkills={4}
          displayStyle="chip"
        />
      )}
    </>
  );
};
export default SkillsWithRecommended;
