import React from 'react';
import { FormImage } from '../../core/form';

const Logo = ({
  errors,
  register,
  defaultValue = '',
  form,
  square = false,
}: {
  errors: any;
  register: any;
  form: any;
  defaultValue?: string | null;
  square?: boolean;
}) => {
  const handleChangeImageUrl = (url: string) => {
    return url;
  };

  return (
    <FormImage
      name="logoURL"
      label="company logo"
      aspectRatio={[1, 1]}
      onChange={handleChangeImageUrl}
      errors={errors}
      form={form}
      ref={register({ required: 'Logo is required' })}
      defaultValue={defaultValue}
      squarePreview={square}
    />
  );
};

export default Logo;
