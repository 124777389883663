import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useReferenceRequestByToken(
  referenceToken: string
): {
  isFetching: boolean;
  request?: ReferenceRequest;
  // searchToken: string;
  refetch: Function;
  error: any;
} {
  const { data, isFetching, refetch, error } = useQuery(
    ['reference-by-token', referenceToken],
    () => Api.getReferenceRequest(referenceToken),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    isFetching,
    request: data,
    // searchToken: data?.searchToken ?? null,
    refetch,
    error,
  };
}
