import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { track } from '../../../analytics';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Avatar from '../../avatar/Avatar';
import Button, { SubmitButton } from '../../core/Button';
import { FormInput } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import UserContext from '../../user/Context';
import { Password } from '../../user/formElements';

const Welcome = () => {
  const {
    setCanAdvance,
    goToNextStep,
    goToPrevStep,
    setDescription,
    setTitle,
  } = useStep();
  const { authDetails, authenticate, isAuthenticated, user } = useContext(
    UserContext
  );
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { oauthProviderId } = authDetails;

  const firstName = user?.firstName || authDetails.firstName;
  const lastName = user?.lastName || authDetails.lastName;
  const title = user?.title || authDetails.email;
  const avatarUrl = user?.avatarURL || authDetails.avatarURL;

  const { handleSubmit, errors, register, formState } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
      oauthProviderId,
    },
  });
  const { isValid, isSubmitted } = formState;

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) return;
      setLoading(true);

      authenticate(values)
        .then(() => {
          setLoading(false);
          addSuccess('Account successfully connected.');

          track('Manually connected LinkedIn to Communo Account', {
            step: 'Welcome',
          });

          goToNextStep();
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          addResponseError(error);
        });
    },
    [authenticate, goToNextStep, isLoading]
  );

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Welcome',
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setTitle('Welcome Back');
      setDescription('We have found the following information for you.');
    }
  }, [isAuthenticated, setDescription, setTitle]);

  useEffect(() => {
    register({ name: 'oauthProviderId' });
  }, [register]);

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  return (
    <div>
      <div className="flex items-center justify-center">
        <div className="pr-4">
          <Avatar square size="xl" avatarUrl={avatarUrl} />
        </div>
        <div>
          <h5>
            {firstName} {lastName}
          </h5>
          <div className="text-large">{title}</div>
        </div>
      </div>
      {!isAuthenticated ? (
        <div className="mt-8 text-center">
          <Button
            onClick={() => {
              setShowLogin(true);
            }}
          >
            Already have a Communo account?
          </Button>
        </div>
      ) : null}
      {!isAuthenticated && showLogin ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="mt-8">
          <p className="mb-8">
            Please log in to your Communo account and we’ll link it to this
            LinkedIn profile (and next time you log in you can use either one).
          </p>

          <FormInput
            name="email"
            type="email"
            label="Email"
            labelVisible
            // defaultValue={email}
            placeholder="name@email.com"
            errors={isSubmitted ? errors : {}}
            ref={register({
              required: 'Email is required',
              validate: (value: any) =>
                validator.isEmail(value) || 'Invalid email',
            })}
          />

          <Password
            errors={isSubmitted ? errors : {}}
            register={register}
            skipSecurityCheck
            forgotLink
            labelVisible
          />

          <div className="flex flex-row -mx-2">
            <div className="px-2 w-1/2">
              <Button
                onClick={() => {
                  setShowLogin(false);
                }}
                fill="outline"
                className="w-full"
              >
                Cancel
              </Button>
            </div>
            <div className="px-2 w-1/2">
              <SubmitButton
                text="Log in via email"
                loading={isLoading}
                disabled={!isValid}
                fullWidth
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="flex flex-row -mx-2 mt-16">
          <div className="px-2 w-1/2">
            <Button
              onClick={() => {
                goToPrevStep();
              }}
              fill="outline"
              className="w-full"
            >
              Back
            </Button>
          </div>
          <div className="px-2 w-1/2">
            <Button
              onClick={() => {
                goToNextStep();
              }}
              color="primary"
              className="w-full"
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Welcome;
