import React from 'react';

const RibbonIcon = React.memo(({ className = '' }: any) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 2.66667C11.5819 2.66667 8.00016 6.24839 8.00016 10.6667C8.00016 15.0849 11.5819 18.6667 16.0002 18.6667C20.4184 18.6667 24.0002 15.0849 24.0002 10.6667C24.0002 6.24839 20.4184 2.66667 16.0002 2.66667ZM5.3335 10.6667C5.3335 4.77563 10.1091 0 16.0002 0C21.8912 0 26.6668 4.77563 26.6668 10.6667C26.6668 16.5577 21.8912 21.3333 16.0002 21.3333C10.1091 21.3333 5.3335 16.5577 5.3335 10.6667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.878 17.1849C21.608 17.0881 22.2783 17.6013 22.3751 18.3313L23.9884 30.4913C24.0558 30.9988 23.8266 31.5001 23.3987 31.7811C22.9708 32.0622 22.4197 32.0734 21.9807 31.81L16 28.2216L10.0194 31.81C9.58033 32.0734 9.02924 32.0622 8.60132 31.7811C8.17339 31.5 7.94423 30.9987 8.01164 30.4911L9.62497 18.3445C9.72193 17.6145 10.3923 17.1013 11.1223 17.1983C11.8522 17.2952 12.3654 17.9656 12.2684 18.6956L11.0193 28.1002L15.314 25.5233C15.7363 25.27 16.2638 25.27 16.686 25.5233L20.9812 28.1005L19.7316 18.682C19.6348 17.9521 20.148 17.2818 20.878 17.1849Z"
      />
    </svg>
  );
});
export default RibbonIcon;
