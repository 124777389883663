/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback } from 'react';
import cn from 'classnames';
import { isMobile } from '../../../../utilities/Device';

type Props = {
  fullHeight: boolean;
  width:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
  height: string;
  children: any;
};
const ModalContent = ({ fullHeight, width, height, children }: Props) => {
  const handleContentClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const modalHeight = fullHeight ? '100vh' : height;

  return (
    <div
      onClick={handleContentClick}
      className={cn(
        'bg-white shadow relative flex flex-col w-full',
        [`max-w-${width}`],
        {
          'pt-safe max-h-full': fullHeight,
          'max-safe-height': !fullHeight,
          'pb-safe': isMobile,
        }
      )}
      style={{ height: modalHeight }}
    >
      {children}
    </div>
  );
};
export default ModalContent;
