import React, { useContext, useEffect } from 'react';
import { Link, NavLink } from '../core/router';
import { HashRoute } from '../user/Routes';
import ErrorBoundary from '../core/ErrorBoundary';
import MembershipContext from './Context';
import Edit from '../portfolio/Edit';
import Create from '../portfolio/Create';
import List from '../portfolio/List';
import BasicForm from './forms/BasicForm';
import RolesForm from './forms/RolesForm';
import LinksForm from './forms/LinksForm';
import AboutForm from './forms/AboutForm';
import TeamForm from './forms/TeamForm';
import ImagesForm from './forms/ImagesForm';
import membershipCompletenessStore, {
  MembershipProfileAttribute,
} from './state/membershipCompletenessStore';
import MetricStatusIcon from '../candidateScore/MetricStatus';
import ProfileCompletion, {
  agencyRequirements,
  businessRequirements,
} from './ProfileCompletion';

type Link = {
  hash: string;
  label?: string;
  component: any;
  completionRequirements?: MembershipProfileAttribute[];
};

type Props = {
  match: {
    params: {
      form:
        | 'completion'
        | 'basic'
        | 'roles'
        | 'images'
        | 'about'
        | 'team'
        | 'portfolio'
        | 'portfolio/create'
        | 'portfolio/edit/:projectId'
        | 'links';
    };
  };
  setModalTitle: (title: string | undefined) => void;
  title: string;
};

function getAllRequirements(isAgency: boolean): MembershipProfileAttribute[] {
  const requirements = isAgency ? agencyRequirements : businessRequirements;

  return Object.values(requirements)
    .flat()
    .filter(requirement => {
      // Dont include stage three requirements because they are "nice to have"
      return !requirements.stageThree.includes(requirement);
    });
}

// Only HashRoutes with a `label` will be added to the Edit menu
function getMenuItems(isAgency: boolean): Link[] {
  return [
    {
      hash: '#/edit-page/completion',
      label: 'Profile completion',
      component: ProfileCompletion,
      completionRequirements: getAllRequirements(isAgency),
    },
    {
      hash: '#/edit-page/basic',
      label: 'Basic info',
      component: BasicForm,
      completionRequirements: ['logoURL', 'coverPhotoURL'],
    },
    {
      hash: '#/edit-page/roles',
      label: 'Roles & skills',
      component: RolesForm,
      completionRequirements: ['skills', 'industries'],
    },
    {
      hash: '#/edit-page/images',
      label: 'Company photos',
      component: ImagesForm,
      completionRequirements: isAgency ? [] : ['imageURLs'],
    },
    {
      hash: '#/edit-page/about',
      label: 'About',
      component: AboutForm,
      completionRequirements: ['summary', 'about'],
    },
    { hash: '#/edit-page/team', label: 'Team', component: TeamForm },
    {
      hash: '#/edit-page/portfolio',
      label: 'Portfolio',
      component: List,
      completionRequirements: isAgency ? ['portfolioItems'] : [],
    },
    { hash: '#/edit-page/portfolio/create', component: Create },
    { hash: '#/edit-page/portfolio/edit/:projectId', component: Edit },
    {
      hash: '#/edit-page/links',
      label: 'Links',
      component: LinksForm,
      completionRequirements: ['socialLinks'],
    },
  ];
}

const EditForm = ({
  match: {
    params: { form },
  },
  setModalTitle,
  title,
}: Props) => {
  const { membership, refresh, update } = useContext(MembershipContext);
  const [completenessStore] = membershipCompletenessStore;
  const [getStatusForAll] = completenessStore(state => [state.getStatusForAll]);
  const isAgency = membership?.plan.type === 'agency';
  const routes = getMenuItems(isAgency);

  useEffect(() => {
    const modalTitle = routes.find(link => {
      return link.hash.includes(form);
    });
    if (modalTitle) {
      setModalTitle(modalTitle.label);
    } else {
      setModalTitle(title);
    }
  }, [form, title, setModalTitle, routes]);

  return (
    <ErrorBoundary>
      <div className="">
        <div className="row flex">
          <div
            className={`${
              form ? 'hidden' : ''
            } md:block col w-full md:w-1/3 self-start sticky top-0`}
          >
            <ul className="nav-links">
              {routes
                .filter(({ label }: Link) => !!label)
                .map(({ hash, label, completionRequirements }: Link) => {
                  const status = completionRequirements
                    ? getStatusForAll(completionRequirements)
                    : null;

                  return (
                    <li className="nav-links__item" key={hash}>
                      <NavLink className="nav-links__link" to={hash}>
                        <div className="flex">
                          <MetricStatusIcon status={status} />
                          <div className="pl-2">{label}</div>
                        </div>
                        <i className="i-caret-right md:hidden" />
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div
            className={`${form ? '' : 'hidden'} md:block col w-full md:w-2/3`}
          >
            {routes.map(({ hash, component }: Link) => (
              <HashRoute
                key={hash}
                hash={hash}
                component={component}
                membership={membership}
                update={update}
                refresh={refresh}
              />
            ))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default EditForm;
