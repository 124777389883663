import React, { CSSProperties, useRef } from 'react';
import cn from 'classnames';

import cloudinary from '../../../utilities/Cloudinary';

interface Props {
  headerImg: string;
  mobileImageHeightClass?: string;
  logoImg?: string;
  children: any;
  backgroundClass?: string;
  bodyClass?: string;
  containerStyle?: CSSProperties;
  contentContainerStyle?: CSSProperties; // This was added because content would scroll over header image with a transparent background
  spacingX?: string;
  spacingY?: string;
}

const SplitPanels = ({
  headerImg,
  logoImg,
  children,
  backgroundClass,
  bodyClass = '',
  spacingX = 'px-8',
  spacingY = 'py-4 xl:py-16 3xl:py-20',
  mobileImageHeightClass = 'h-screen25 md:h-screen40',
  containerStyle,
  contentContainerStyle,
}: Props) => {
  const scrollerRef = useRef<any>();

  return (
    <div
      className={cn('fixed inset-0 z-50 pb-safe', [backgroundClass])}
      style={containerStyle}
    >
      <div
        ref={scrollerRef}
        className="relative h-full xl:grid xl:grid-cols-2 overflow-y-auto overflow-x-hidden"
      >
        <div
          className={`bg-grey-300 z-10 sticky top-0 xl:h-full ${mobileImageHeightClass}`}
        >
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={cloudinary(headerImg, ['h_1440'])}
            alt="Header"
          />
          {logoImg ? (
            <div className="absolute top-0 left-0 pt-safe xl:pt-16 pl-8 xl:pl-16">
              <div className="pt-4">
                <img
                  className="h-16 xl:h-24 w-auto rounded-md overflow-hidden"
                  src={cloudinary(logoImg, ['h_96'])}
                  alt="Logo"
                />
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={cn('z-10 xl:z-0 relative xl:min-h-full', bodyClass)}
          style={contentContainerStyle}
        >
          <div className={cn('max-w-lg mx-auto', [spacingX, spacingY])}>
            {React.cloneElement(children, {
              ref: scrollerRef,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SplitPanels;
