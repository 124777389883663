import shallow from 'zustand/shallow';
import channelMembersStoreCreator, {
  ChatChannelMembersState,
} from '../state/ChatChannelMembersState';

/**
 * useChatChannelMembersTyping
 *
 * Hook that returns the Member objects for a given Channel.
 * This hook will load the Members for the Channel if they haven't been loaded yet.
 */
const useChatChannelMembersTyping = (channelSid: string) => {
  const [channelMembersStore] = channelMembersStoreCreator(channelSid);
  const [membersIdsTyping] = channelMembersStore(
    (state: ChatChannelMembersState) => [state.membersIdsTyping],
    shallow
  );

  return {
    membersIdsTyping,
  };
};
export default useChatChannelMembersTyping;
