import React, { useCallback, useContext, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import pluralize from 'pluralize';
import { CollectionSummary } from '@communo-corp/shared-types';
import Button from '../../core/Button';
import useAccessibleCollections from '../hooks/useAccessibleCollections';
import {
  BenchCollectionItem,
  ListCollectionItem,
  GroupCollectionItem,
} from './AddToCollectionItem';
import Api from '../Api';
import CreateCollectionModal from './CreateCollectionModal';
import { CollectionsContext } from '../context/CollectionsContext';
import { LoadingBalls } from '../../core/Loading';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Modal from '../../core/modal/Modal';
import useUserAllowList from '../../user/hooks/useUserAllowList';

type Props = {
  userIds: string[];
  onClose: () => void;
};
function CollectionItems(type: string) {
  switch (type) {
    case 'bench':
      return BenchCollectionItem;
    case 'group':
      return GroupCollectionItem;
    default:
      return ListCollectionItem;
  }
}
const Content = ({
  collections,
  isLoadingCollections,
  onCreateNewList,
  onToggle,
  userIds,
}: any): any => {
  if (!collections || isLoadingCollections) {
    return <LoadingBalls isActive />;
  }

  if (collections.length === 0) {
    return (
      <>
        <p className="text-lg">
          It looks like you haven&apos;t created a list yet!
        </p>
        <Button
          text="Create one now"
          fill="solid"
          color="primary"
          icon="add"
          fullWidth
          className="mt-4"
          onClick={onCreateNewList}
        />
      </>
    );
  }

  return sortBy(collections, ['type', 'name']).map(collection => {
    const Component = CollectionItems(collection.type);

    return (
      <Component
        key={collection.id}
        id={collection.id}
        name={collection.name}
        description={collection.description}
        count={collection.userCount}
        isSelected={false}
        onToggle={onToggle}
        userIds={userIds}
      />
    );
  });
};

const Footer = ({ onCreateNewList, onClose }: any) => {
  return (
    <>
      <Button
        text="Create a new list"
        fill="outline"
        icon="add"
        size="sm"
        onClick={() => onCreateNewList()}
      />
      <Button
        text="Done"
        fill="solid"
        color="primary"
        size="sm"
        onClick={() => onClose()}
      />
    </>
  );
};

const AddUsersToCollectionModal = ({ userIds = [], onClose }: Props) => {
  const {
    collections: accessibleCollections,
    isLoading: isLoadingCollections,
  } = useAccessibleCollections();
  const { invalidateAllowList } = useUserAllowList();

  const { updateUserIdsCollectionCache } = useContext(CollectionsContext);

  const closeModal = useCallback(
    (collection: CollectionSummary | null) => {
      if (collection) {
        addSuccess(
          `Added ${userIds.length} ${pluralize('member', userIds.length)} to ${
            collection.name
          }.`
        );
      }

      onClose();
    },
    [onClose, userIds.length]
  );

  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const closeCreateCollectionModal = useCallback(
    (collection: CollectionSummary | null) => {
      if (collection) {
        // If a collection is passed, a new collection was created
        // so we can attach the users to the collection
        // and close the parent modal
        closeModal(collection);
        return;
      }

      setCreateCollectionOpen(false);
    },
    [closeModal]
  );

  const onToggle = useCallback(
    (collectionId: string) => {
      const collection = accessibleCollections.find(
        col => col.id === collectionId
      );

      if (!collection) {
        return;
      }

      Api.addUsersToCollection(collectionId, userIds)
        .then(() => {
          updateUserIdsCollectionCache(userIds, [collection.type]);
          invalidateAllowList();
          closeModal(collection);
        })
        .catch(err => {
          addResponseError(err);
        });
    },
    [
      accessibleCollections,
      closeModal,
      invalidateAllowList,
      updateUserIdsCollectionCache,
      userIds,
    ]
  );

  const content = useMemo(
    () => (
      <div className="min-h-screen50">
        <div className="text-grey-700 mb-4">
          Adding users will not replace existing users, and no duplicates will
          be added.
        </div>
        <Content
          collections={accessibleCollections}
          isLoadingCollections={isLoadingCollections}
          onCreateNewList={() => setCreateCollectionOpen(true)}
          onToggle={onToggle}
          userIds={userIds}
        />
      </div>
    ),
    [accessibleCollections, isLoadingCollections, onToggle, userIds]
  );
  const footer = useMemo(
    () => (
      <Footer
        onCreateNewList={() => setCreateCollectionOpen(true)}
        onClose={onClose}
      />
    ),
    [onClose]
  );

  const title = `Add ${userIds.length} ${pluralize(
    'user',
    userIds.length
  )} to a List`;

  return (
    <>
      <Modal isOpen title={title} width="md" onClose={onClose} footer={footer}>
        {content}
      </Modal>

      <CreateCollectionModal
        isOpen={createCollectionOpen}
        userIds={userIds}
        onClose={closeCreateCollectionModal}
      />
    </>
  );
};
export default AddUsersToCollectionModal;
