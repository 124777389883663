import React from 'react';
import LoginSignupForm from '../forms/LoginSignupForm';
import useAnalytics from '../../../hooks/useAnalytics';

type Props = {
  redirectUrl?: string;
  type: string;
};

const Signup = ({ redirectUrl, type }: Props) => {
  useAnalytics('Join Communo');

  return (
    <LoginSignupForm action="signup" redirectUrl={redirectUrl} type={type} />
  );
};

export default Signup;
