import React, { useState } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';

interface Props {
  children: JSX.Element;
  request?: ReferenceRequest;
  referenceToken?: string;
  leaveFor?: string;
}

interface Inputs {
  LinkedInURL: string;
  title: string;
  phoneNumber: string;
  quality: number;
  speed: number;
  value: number;
  communication: number;
  collaboration: number;
  endorsement: string;
  role?:
    | { value: string; label: string }
    | Array<{ value: string; label: string }>;
}

// NOTE: These are just the oAuthProvider details we care about right now. There are others.
interface ReferenceOAuthProvider {
  title: string | null;
  phone: string | null;
  externalProfileUrl: string | null;
  hiringSource: string | null;
  optIn: boolean | null;
}

type SubmitReferenceContext = {
  form: UseFormMethods<Inputs>;
  user: ReferenceRequestuser | null;
  referenceRequest: ReferenceRequestDetails | null;
  referenceToken?: string;
  oAuthProvider: ReferenceOAuthProvider | null;
  setOAuthProvider: React.Dispatch<React.SetStateAction<null>>;
  leaveFor?: string;
};

export const SubmitReferenceContext = React.createContext<
  SubmitReferenceContext
>({} as any);

export const SubmitReferenceContextProvider = ({
  children,
  request,
  referenceToken,
  leaveFor,
}: Props) => {
  const [oAuthProvider, setOAuthProvider] = useState(null);
  const form = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      LinkedInURL: '',
      title: '',
      phoneNumber: '',
      quality: 2,
      speed: 2,
      value: 2,
      communication: 2,
      collaboration: 2,
      endorsement: '',
      role: undefined,
    },
  });

  return (
    <SubmitReferenceContext.Provider
      value={{
        form,
        user: request?.user ?? null,
        referenceRequest: request?.referenceRequest ?? null,
        referenceToken,
        oAuthProvider,
        setOAuthProvider,
        leaveFor,
      }}
    >
      {children}
    </SubmitReferenceContext.Provider>
  );
};
