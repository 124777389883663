import React, { useCallback } from 'react';
import { FormGroup, Autocomplete } from '../form';

interface Props
  extends Pick<
    AutocompleteProps,
    'form' | 'min' | 'defaultValues' | 'isMulti'
  > {
  max?: number | null;
}

export enum Experience {
  Entry = 'Entry',
  Intermediate = 'Intermediate',
  Senior = 'Senior',
  Executive = 'Executive',
}

export default ({ form, defaultValues }: Props) => {
  const getExperiences = Object.values(Experience).map(hit => {
    return { value: hit, label: hit };
  });

  const { errors } = form;
  const name = 'experience';
  const label = 'What level of work opportunity are you looking for?';

  const getResults = useCallback(() => {
    return getExperiences;
  }, [getExperiences]);

  return (
    <FormGroup name={name} label={label} errors={errors}>
      <Autocomplete
        name={name}
        form={form}
        min={1}
        max={1}
        defaultValues={defaultValues}
        defaultOptions={getExperiences}
        callback={getResults}
        isMulti={false}
        placeholder="Type to search Experience..."
      />
    </FormGroup>
  );
};
