import React from 'react';
import MemberCard from './MemberCard';

type Props = {
  membership: MembershipEntity;
};
const BrandEntityCard = ({ membership }: Props) => {
  const id = membership.id || membership.objectID;
  const { logoURL, name } = membership;

  if (!id) {
    return null;
  }

  return <MemberCard membershipId={id} logoUrl={logoURL} name={name} />;
};
export default React.memo(BrandEntityCard);
