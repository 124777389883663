import React from 'react';

type Props = {
  active: boolean;
  onToggle: () => void;
};

const Toggle: React.FC<Props> = ({
  active,
  children,
  onToggle,
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      type="button"
      className={`mr-4 border-0 text-caption filter whitespace-nowrap ${
        active ? 'filter--active' : ''
      }`}
      onClick={onToggle}
    >
      {children}
    </button>
  );
};

export default Toggle;
