export default (url: string) => {
  return {
    id: 'skills',
    label: 'What’s your skill set?',
    description:
      'What skills do you bring to to the table? What tools do you use in your craft?',
    icon: 'settings',
    action: `#/${url}/roles`,
    actionLabel: 'Add Skills',
    complete: false,
  };
};
