import React, { useContext, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Avatar from '../../avatar/Avatar';
import { FormGroup } from '../../core/form';
import SearchContext from '../../search/Context';
import { isMobile } from '../../../utilities/Device';
import {
  Input,
  DropdownIndicator,
  ClearIndicator,
  Styles,
} from '../../select/Components';

interface OptionValue {
  membershipId: string;
  name: string;
  logoURL: string;
}
interface LabelProps {
  label: string;
  value: OptionValue;
}

interface Props {
  name: string;
  form: any;
  errors: any;
  defaultValue?: LabelProps;
  required?: boolean | string;
}

const Membership = ({
  name,
  form,
  errors,
  defaultValue,
  required = false,
}: Props) => {
  const { getMemberships } = useContext(SearchContext);
  const { setValue, register } = form;

  function handleChange(selected: any) {
    if (selected == null) {
      setValue(name, '');
      return;
    }
    setValue(name, selected.value.membershipId);
  }

  useEffect(() => {
    register({ name }, { required });
  }, [register, name, required]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue.value.membershipId);
    }
  }, [defaultValue, name, setValue]);

  const searchMemberships = (q: string) =>
    getMemberships({ query: q }, false).then(({ hits }: any) => {
      return hits.map((hit: any) => {
        const { objectID: membershipId, name: membershipName, logoURL } = hit;
        return {
          label: membershipName,
          value: {
            membershipId,
            membershipName,
            logoURL,
          },
        };
      });
    });

  const formatLabel = ({ label, value }: LabelProps) => {
    const { logoURL } = value;
    return (
      <div className="flex items-center">
        <Avatar avatarName={label} avatarUrl={logoURL} />
        <span className="ml-2">{label}</span>
      </div>
    );
  };

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (!inputValue) return null; // removes "No Options" empty dropdown
    return 'No results found. Try a new search.';
  };

  return (
    <FormGroup name={name} label="Membership" errors={errors}>
      <AsyncSelect
        isClearable
        cacheOptions
        className="multiselect"
        classNamePrefix="multi"
        loadOptions={searchMemberships}
        isRequired
        placeholder="Search memberships..."
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatLabel}
        onChange={handleChange}
        defaultValue={defaultValue}
        maxMenuHeight={isMobile ? 100 : 300}
        components={{
          Input,
          DropdownIndicator,
          ClearIndicator,
        }}
        style={Styles}
      />
    </FormGroup>
  );
};
export default Membership;
