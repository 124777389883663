import React from 'react';
import { beginningDefault, paymentDefault, endDefault, Screen } from '.';
import ScreenContent from '../ScreenContent';
import SuccessScreenContent from '../SuccessScreenContent';

const screens: Screen[] = [
  {
    ...beginningDefault,
    title: 'Upgrade to access this feature',
    btnAction: 'NEXT',
    btnLabel: 'Continue',
    Component: () => (
      <ScreenContent>
        <p>Your current plan has limited functionality.</p>
        <p>By upgrading you will gain access to our pro features.</p>
      </ScreenContent>
    ),
  },
  {
    ...paymentDefault,
  },
  {
    ...endDefault,
    Component: () => (
      <SuccessScreenContent>
        <p>You can now access our pro features.</p>
      </SuccessScreenContent>
    ),
  },
];

export default screens;
