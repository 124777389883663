import React from 'react';
import CardLogo from '../../card/CardLogo';
import { Link } from '../../core/router';
import { SHOW_COMPANY_ON_JOB_POST } from '../../../config';

type Props = {
  work: WorkEntity;
  altText: string;
};

const Logo = ({ work, altText }: Props) => {
  const to = work.membership?.plan?.isIndividualPlan
    ? `/members/user/${work.user.id}`
    : `/members/membership/${work.membership?.id}`;

  const imageUrl =
    (SHOW_COMPANY_ON_JOB_POST && work.company?.logoURL) ||
    work.logoURL ||
    (work.membership?.plan?.type === 'individual' && work.user?.avatarURL) ||
    work.membership?.logoURL ||
    null;

  return (
    <>
      <Link to={to}>
        <CardLogo
          imageUrl={imageUrl}
          altText={altText}
          logoSize="xl"
          borderSize="none"
        />
      </Link>
    </>
  );
};

export default Logo;
