import React, { useMemo, useContext, useCallback } from 'react';

import getEventFilterOptions from './EventSearchFilters';
import SearchContext from '../../search/Context';
import EventSearchList from './EventSearchList';
import Loading from '../../core/Loading';
import useSearchProvider from '../../search/useSearchProvider';

const now = Math.round(new Date().getTime() / 1000);

const EventSearchProvider = () => {
  const searchContext = useContext(SearchContext);
  const { getEvents } = searchContext;

  const getFilterOptions = useCallback((results: any) => {
    return getEventFilterOptions(results);
  }, []);

  const facetQueryOverrides = useMemo(() => {
    return {
      defaultFilters: [`expiresAtTimestamp > ${now}`],
      facets: ['isFree', 'isOnline', 'isFeatured', 'type', 'category.name'],
    };
  }, []);

  const extraQueries = useMemo(() => {
    return {
      featuredEvents: {
        defaultFilters: [`expiresAtTimestamp > ${now}`, 'isFeatured = 1'],
        facets: [],
      },
    };
  }, []);

  const {
    rawResults,
    allResults,
    searchResults,
    onLoadMore,
    readyToSearch,
    filterContext,
  } = useSearchProvider({
    getFilterOptions,
    searchFunction: getEvents,
    facetQueryOverrides,
    extraQueries,
  });

  if (!readyToSearch) {
    return <Loading isActive />;
  }

  return (
    <EventSearchList
      filterContext={filterContext}
      events={allResults}
      featuredEvents={rawResults.featuredEvents?.hits}
      searchResults={searchResults}
      onLoadMore={onLoadMore}
    />
  );
};

export default EventSearchProvider;
