/* eslint-disable no-console */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';
import EventCard from '../../card/EventCard';
import Swiper from '../../swiper/Swiper';
import Paginate from '../../core/Paginate';
import Filter from '../../filter/Filter';
import useNavigate from '../../../hooks/useNavigate';
import useFeatureGate from '../../../hooks/useFeatureGate';
import Button from '../../core/Button';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import isTalentPlan from '../../plan/utilities/isTalentPlan';

type Props = {
  filterContext: any;
  searchResults: SearchResponse;
  featuredEvents: any[];
  events: any[];
  onLoadMore: Function;
};

const EventSearchList = React.memo(
  ({
    filterContext,
    searchResults,
    featuredEvents,
    events,
    onLoadMore,
  }: Props) => {
    const navigate = useNavigate();
    const { needsUpgrade } = useFeatureGate();

    if (!searchResults) {
      return null;
    }

    const showNewEventButtonToBusinessMembers = !isTalentPlan(useCurrentPlan());

    const showingCount =
      searchResults.hitsPerPage * searchResults.page +
      searchResults.hits.length;

    const featuredCount = searchResults.facets?.isFeatured?.true || 0;

    const featuredEventComponents = featuredEvents.map((event: EventEntity) => (
      <div key={event.objectID} className="w-full">
        <EventCard
          event={event}
          cardStyle="full"
          className="h-full mb-4 md:my-0 w-full"
        />
      </div>
    ));

    return (
      <div className="py-12 flex flex-col gap-8">
        <div className="mx-auto w-9/10">
          <div className="flex justify-between">
            <h2>Events</h2>
            {showNewEventButtonToBusinessMembers && (
              <div>
                <Button
                  color="primary"
                  size="sm"
                  text="Create new event post"
                  onClick={() => {
                    if (!needsUpgrade('canPostEvents')) {
                      navigate('#/event/create');
                    }
                  }}
                  icon="add"
                />
              </div>
            )}
          </div>
        </div>

        <Filter
          actions={filterContext.actions}
          filteredOptions={filterContext.filteredOptions}
          options={filterContext.options}
          values={filterContext.values}
        />

        <div className="mx-auto w-9/10">
          {searchResults.hits.length ? (
            <>
              {featuredEventComponents.length ? (
                <div className="mt-6">
                  <div className="flex items-center justify-between pr-4">
                    <h5 className="mb-4">Featured Events ({featuredCount})</h5>
                  </div>

                  <div className="hidden md:block">
                    <Swiper
                      items={featuredEventComponents}
                      swiperConfig={{
                        freeMode: true,
                        watchSlidesVisibility: true,
                        slidesPerView: 1.125,
                        spaceBetween: 16,
                        breakpoints: {
                          540: {
                            slidesPerView: 2,
                            spaceBetween: 32,
                          },
                          1025: {
                            slidesPerView: 3,
                          },
                          1440: {
                            slidesPerView: 4,
                          },
                          1920: {
                            slidesPerView: 5,
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-8 md:hidden">
                    {featuredEventComponents}
                  </div>
                </div>
              ) : null}

              {events.length ? (
                <div className="mt-6">
                  <div className="flex items-center justify-between pr-4">
                    <h5 className="mb-4">Events ({searchResults.nbHits})</h5>
                  </div>

                  <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 sm:gap-8 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5">
                    {events.map((event: EventEntity) => (
                      <EventCard
                        key={event.objectID}
                        event={event}
                        cardStyle="limited"
                      />
                    ))}
                  </div>

                  <Paginate
                    showing={showingCount}
                    total={searchResults.nbHits}
                    onLoadMore={onLoadMore}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <p>No results found!</p>
          )}
        </div>
      </div>
    );
  }
);
export default EventSearchList;
