import React from 'react';
import Checkbox from './Checkbox';

const FormCheckbox = React.forwardRef(
  ({ name, label, value, id, onChange, children, disabled }: any, ref) => (
    <label htmlFor={label} className="checkbox checkbox-stylized">
      <Checkbox
        name={name}
        id={id || label}
        value={value}
        ref={ref}
        onChange={onChange}
        className={disabled ? 'disabled' : ''}
        disabled={!!disabled}
      />
      <span>{children || label}</span>
    </label>
  )
);

export default FormCheckbox;
