import React, { useEffect, useState } from 'react';

declare type Props = {
  title: string;
  icon: string;
  stats: StatEntity[];
  className?: string;
};

const StatTile = ({ title, icon, stats, className = '' }: Props) => {
  const [primary, setPrimary] = useState<StatEntity>();
  const [secondary, setSecondary] = useState<StatEntity[]>();

  useEffect(() => {
    const allStats = Array.from(stats);

    if (allStats.length) {
      setPrimary(allStats.shift());
    }

    if (allStats.length) {
      setSecondary(allStats);
    }
  }, [stats]);

  return (
    // <div className={`${className} rounded-2xl p-4 md:px-8 md:py-6 text-white leading-none h-full`} >
    <div
      className={`${className} bg-white rounded-md p-4 md:px-8 md:py-6 leading-none h-full`}
    >
      <div className="flex items-baseline justify-between">
        <div className="font-medium md:text-lg uppercase">{title}</div>
        <div>
          <i className={`i-${icon} text-xl text-link`} />
        </div>
      </div>
      {primary && (
        <div className={`${primary?.className} mt-2`}>
          <div className="text-3xl font-semibold">{primary?.value}</div>
          <div className="text-sm mt-1">{primary?.label}</div>
        </div>
      )}
      {secondary?.map(stat => (
        <div key={stat.label} className={`${stat.className} mt-2`}>
          <div className="text-3xl font-semibold">{stat.value}</div>
          <div className="text-sm mt-1">{stat.label}</div>
        </div>
      ))}
    </div>
  );
};
export default StatTile;
