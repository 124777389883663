import React, {
  useMemo,
  useContext,
  useCallback,
  useReducer,
  useEffect,
  useState,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Breakpoints } from '../../swiper/Swiper';
import getMemberFilterOptions from './MemberSearchFilters';
import SearchContext from '../../search/Context';
import MemberSearchList, { Labels } from './MemberSearchList';
import Loading from '../../core/Loading';
import useSearch from '../../search/useSearch';
import useFilter from '../../filter/useFilter';
import { usePullToRefreshContext } from '../../../hooks/usePullToRefresh';
import useRoles from '../../../hooks/useRoles';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import {
  addBrandQueries,
  addMembershipQueries,
  addUserQueries,
} from './membershipQueries';

function getNextResults(state: any, results: any, key: string) {
  const hits = results?.hits || [];
  return results?.page === 0 ? hits : [...state[key], ...hits];
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        page: 0,
        filters: action.payload.filters,
        filtersLoaded: true,
      };
    case 'INCREMENT_PAGE':
      return {
        ...state,
        page: state.page + 1,
      };
    case 'RESET_PAGE':
      return {
        ...state,
        page: 0,
      };
    case 'UPDATE_RESULTS':
      return {
        ...state,
        rawResults: action.payload.rawResults,
        userAllResults: getNextResults(
          state,
          action.payload.rawResults['user-search'],
          'userAllResults'
        ),
        membershipAllResults: getNextResults(
          state,
          action.payload.rawResults['membership-search'],
          'membershipAllResults'
        ),
        brandAllResults: getNextResults(
          state,
          action.payload.rawResults['brand-search'],
          'brandAllResults'
        ),
      };
    default:
      return state;
  }
}

type Props = {
  showFilter?: boolean;
  showUserResults?: boolean;
  showAgencyResults?: boolean;
  showBrandResults?: boolean;
  showOverflow?: boolean;
  labels?: Labels;
  breakpoints: Breakpoints;
};
const MemberSearchProvider = ({
  showFilter = true,
  showUserResults = true,
  showAgencyResults = true,
  showBrandResults = true,
  showOverflow = true,
  labels,
  breakpoints,
}: Props) => {
  const plan = useCurrentPlan();
  const searchContext = useContext(SearchContext);
  const { getMembersForSearch } = searchContext;
  const {
    setRefreshHandler,
    onRefreshFinish,
    pullToRefreshToken,
    resetPullToRefreshToken,
  } = usePullToRefreshContext();

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    filters: {},
    filtersLoaded: false,
    rawResults: {},
    userAllResults: [],
    membershipAllResults: [],
    brandAllResults: [],
  });

  const roles = useRoles();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [rawResults, handleSearch] = useSearch(getMembersForSearch);

  const onSearchFinish = useCallback(() => {
    onRefreshFinish();
    setLoadingSearch(false);
  }, [onRefreshFinish]);

  const filterOptions = useMemo(() => {
    return getMemberFilterOptions(plan, state.rawResults, state.filters, roles);
  }, [plan, state.rawResults, state.filters, roles]);
  const filterContext = useFilter({ options: filterOptions });

  const readyToSearch = state.filtersLoaded;

  const searchQueries = useMemo(() => {
    if (!state.filtersLoaded) {
      return null;
    }

    const queries: any = {};

    addUserQueries({
      filterOptions,
      state,
      queries,
      plan,
    });

    addMembershipQueries({
      filterOptions,
      state,
      queries,
      plan,
    });

    addBrandQueries({
      filterOptions,
      state,
      queries,
      plan,
    });

    return queries;
  }, [state, plan, filterOptions]);

  const onLoadMore = useCallback(() => {
    dispatch({
      type: 'INCREMENT_PAGE',
    });
  }, []);

  useEffect(() => {
    if (rawResults) {
      dispatch({
        type: 'UPDATE_RESULTS',
        payload: {
          rawResults,
        },
      });
    }
  }, [rawResults]);

  useDeepCompareEffect(() => {
    if (filterContext.paramsLoaded) {
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: {
          filters: filterContext.values,
        },
      });
    }
  }, [filterContext.values, filterContext.paramsLoaded]);

  useDeepCompareEffect(() => {
    if (readyToSearch) {
      setLoadingSearch(true);
      handleSearch(searchQueries, onSearchFinish);
    }
  }, [
    handleSearch,
    searchQueries,
    readyToSearch,
    onSearchFinish,
    pullToRefreshToken,
  ]);

  useEffect(() => {
    setRefreshHandler(() => {
      dispatch({
        type: 'RESET_PAGE',
      });
      resetPullToRefreshToken();
    });
    // eslint-disable-next-line
  }, []);

  if (!readyToSearch) {
    return <Loading isActive />;
  }

  return (
    <MemberSearchList
      filterContext={filterContext}
      showFilter={showFilter}
      showUserResults={showUserResults}
      showAgencyResults={showAgencyResults}
      showBrandResults={showBrandResults}
      showOverflow={showOverflow}
      userAllResults={state.userAllResults}
      membershipAllResults={state.membershipAllResults}
      brandAllResults={state.brandAllResults}
      rawResults={state.rawResults}
      onLoadMore={onLoadMore}
      loading={loadingSearch}
      labels={labels}
      breakpoints={breakpoints}
    />
  );
};

export default MemberSearchProvider;
