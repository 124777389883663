import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import { PerkCategory } from '@communo-corp/shared-types';
import { FormGroup } from '../../core/form';
import {
  Input,
  DropdownIndicator,
  ClearIndicator,
  Styles,
} from '../../select/Components';
import perksApi from '../Api';
import { addResponseError } from '../../../services/Messaging';

interface Option {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label: string;
  errors: any;
  isMulti?: boolean;
  defaultValue?: Option | Option[] | null;
  form: any;
}

const PerkCategories = ({
  name,
  label,
  errors,
  isMulti = false,
  defaultValue,
  form,
}: Props) => {
  const [categories, setCategories] = useState<PerkCategory[] | null>(null);
  const { register, setValue } = form;

  // console.log('defaultValue', defaultValue);

  const getCategories = useCallback(() => {
    perksApi
      .categories()
      .then((response: PerkCategory[]) => {
        setCategories(response);
      })
      .catch(addResponseError);
  }, []);

  function handleChange(data: any) {
    if (data == null) {
      setValue(name, null);
      return;
    }

    let selectedCategories = data;

    if (!isMulti) {
      selectedCategories = [data];
    }

    const perkIds = selectedCategories.map((category: any) => {
      return category.value;
    });

    setValue(name, isMulti ? perkIds : perkIds[0]);
  }

  useEffect(() => {
    getCategories();
    register({ name }, { required: 'This field is required' });
  }, [getCategories, name, register]);

  useEffect(() => {
    if (defaultValue) {
      const defaultOptions = Array.isArray(defaultValue)
        ? defaultValue
        : [defaultValue];

      const ids = defaultOptions.map(option => option.value);

      setValue(name, ids);
    }
  }, [defaultValue, name, setValue]);

  return (
    <FormGroup name={name} label={label} errors={errors}>
      <Select
        isMulti={isMulti}
        options={categories?.map((category: PerkCategory) => {
          return {
            value: category.id,
            label: category.name,
          };
        })}
        className="multiselect"
        classNamePrefix="multi"
        menuPlacement="top"
        defaultValue={defaultValue}
        components={{
          Input,
          DropdownIndicator,
          ClearIndicator,
        }}
        styles={Styles}
        onChange={handleChange}
      />
    </FormGroup>
  );
};
export default PerkCategories;
