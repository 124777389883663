import React, { useContext } from 'react';
import ContentHeader from '../../core/ContentHeader';
import useAnalytics from '../../../hooks/useAnalytics';
import UserContext from '../Context';
import { Link } from '../../core/router';
import SignupLayout from '../signup/SignupLayout';
import { DEFAULT_SENT_COVER_PHOTO_URL } from '../../../asset-config';

export default () => {
  const { authDetails } = useContext(UserContext);
  useAnalytics('Forgot Password Sent');

  return (
    <SignupLayout headerImg={DEFAULT_SENT_COVER_PHOTO_URL}>
      <>
        <ContentHeader />
        <div className="text-center">
          <h3 className="mb-4">Presto!</h3>
          <p className="mb-4">
            We have just sent an email to{' '}
            <span className="font-medium">{authDetails.email}</span>, click on
            the link to reset your password.
          </p>
          <p>
            Want to{' '}
            <Link to="/login" className="text-link font-medium">
              try another email
            </Link>
            ?
          </p>
        </div>
      </>
    </SignupLayout>
  );
};
