import React, { useCallback, useMemo, useState } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUser from '../../hooks/useUser';
import UserAvatar from '../avatar/UserAvatar';
import { LoadingRing } from '../core/Loading';
import Modal from '../core/modal/Modal';
import DeleteRecommendation from './DeleteRecommendation';
import useUserRecommendations from './hooks/useUserRecommendations';
import RecommendationDetail from './RecommendationDetail';
import RequestRecommendationButton from './RequestRecommendationButton';

type Props = {
  userId: string;
  onClose: Function;
};
const ViewRecommendationsModal = ({ userId, onClose }: Props) => {
  const { user, isFetching: isFetchingUser } = useUser(userId);
  const { id } = useCurrentUser();
  const {
    isFetching: isFetchingRecommendations,
    recommendations,
    refetch,
  } = useUserRecommendations(userId);
  const [deletingRecommendationId, setDeletingRecommendationId] = useState<
    string | null
  >(null);

  const onClickDelete = useCallback((recommendationId: string) => {
    setDeletingRecommendationId(recommendationId);
  }, []);

  const onDeleteRecommendation = useCallback(() => {
    setDeletingRecommendationId(null);
    refetch();
  }, [refetch]);

  const header = useMemo(() => {
    if (!user) {
      return null;
    }

    return (
      <div className="pl-8 h-12 flex items-center">
        <UserAvatar user={user} size="sm" className="inline-block mr-3" />
        <div className="flex-1 font-semibold">
          {user.firstName} {user.lastName}
        </div>
      </div>
    );
  }, [user]);

  return (
    <>
      <Modal
        isOpen
        header={header}
        showBackButton={false}
        onClose={() => onClose()}
        width="3xl"
      >
        <div className="min-h-screen50">
          {isFetchingUser || isFetchingRecommendations ? (
            <div className="py-20 text-center">
              <LoadingRing isActive color="primary" />
            </div>
          ) : (
            <>
              <div className="mt-4 flex justify-between items-start sm:items-center flex-col sm:flex-row">
                <div className="flex justify-between items-center mb-3 sm:mb-0">
                  <h3>Recommendations</h3>
                  <span className="ml-5 px-2 bg-grey-500 text-white rounded text-base font-medium">
                    {recommendations.length}
                  </span>
                </div>

                {id === user.id ? <RequestRecommendationButton /> : null}
              </div>
              {recommendations.length > 0 ? (
                recommendations.map(recommendation => (
                  <RecommendationDetail
                    key={recommendation.id}
                    recommendation={recommendation}
                    onClickDelete={onClickDelete}
                    className="mt-8"
                  />
                ))
              ) : (
                <p className="mt-8">
                  You do not have any submitted recommendations.
                </p>
              )}
            </>
          )}
        </div>
      </Modal>

      {deletingRecommendationId ? (
        <DeleteRecommendation
          isOpen
          onClose={() => setDeletingRecommendationId(null)}
          onDelete={onDeleteRecommendation}
          recommendationId={deletingRecommendationId}
        />
      ) : null}
    </>
  );
};
export default ViewRecommendationsModal;
