import React from 'react';
import { Link } from '../core/router';

interface Props {
  title: string;
  route: string;
  children: React.ReactNode;
}

const FeedSection = ({ title, route, children }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <h4 className="flex-1">{title}</h4>
        <Link className="text-link font-semibold" to={route}>
          View more
        </Link>
      </div>
      {children}
    </div>
  );
};

export default React.memo(FeedSection);
