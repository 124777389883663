import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import moment from 'moment';

import { SubmitButton } from '../../core/Button';
import { FormActions, FormGroup } from '../../core/form';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import School from '../formElements/School';
import Major from '../formElements/Major';
import Minor from '../formElements/Minor';
import GraduationDate from '../formElements/GraduationDate';
import useNavigate from '../../../hooks/useNavigate';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import { HBCU_PLAN_ID } from '../../../config';
import { Country } from '../../plan/formElements';
import EducationApi from '../Api';
import UserContext from '../../user/Context';
import EducationContext from '../Context';

interface SchoolValue {
  value: string;
  label: string;
}

interface Inputs {
  school: SchoolValue | null;
  country?: string | null;
  graduationDate: string | null;
  major: string | null;
  minor: string | null;
}

const now = new Date();

const CreateEducation = () => {
  const { refresh } = useContext(UserContext);
  const { addEducation } = useContext(EducationContext);
  const plan = useCurrentPlan();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const form = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      school: null,
      country: null,
      major: null,
      minor: null,
      graduationDate: null,
    },
  });
  const { errors, register, handleSubmit, watch } = form;
  const isHbcu = plan?.id === HBCU_PLAN_ID;
  const school = watch('school');
  const isNewSchool = school?.value === 'create';

  const onSubmit = useCallback(
    async (values: Inputs) => {
      if (loading) return;
      setLoading(true);

      try {
        let schoolId = values.school?.value;

        if (values.school?.value === 'create' && values.country) {
          const newSchool = await EducationApi.createSchool({
            name: values.school.label,
            countryIsoCode: values.country,
          });

          schoolId = newSchool.id;
        }

        // graduationDate should never be null because we have validation to prevent it. 'now' is just to make typescript happy.
        const data = {
          ...values,
          graduationDate: moment(values.graduationDate ?? now).format(
            'YYYY-MM-DD'
          ),
          schoolId,
        };

        await addEducation(data);
        await refresh();
        setLoading(false);
        addSuccess('Education Added!');
        navigate('#/edit-profile/education');
      } catch (error) {
        setLoading(false);
        addResponseError(error);
      }
    },
    [loading, navigate, addEducation, refresh]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <School form={form} onlyHbcuSchools={isHbcu} />
      {isNewSchool && (
        <FormGroup name="country" label="Country" errors={errors}>
          <Country
            name="country"
            // errors={isSubmitted ? errors : {}}
            errors={errors}
            register={register}
          />
        </FormGroup>
      )}

      {school && (
        <>
          <Major errors={errors} register={register} />
          {isHbcu ? <Minor errors={errors} register={register} /> : null}
          <GraduationDate
            label={isHbcu ? 'Graduation date' : 'Year of graduation'}
            format={isHbcu ? 'Month' : 'Year'}
            form={form}
          />
        </>
      )}

      {/* <div className="mt-2 mb-6">
        <div className="help-text">
          Can’t find your school? Contact us and let us know.
        </div>
        <a
          className="btn btn--sm btn--outline w-full mt-4"
          href="mailto:support@communo.com?subject=Cant find my school"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email support@communo.com
        </a>
      </div> */}

      <FormActions className="mt-auto justify-center">
        <div className="flex justify-end w-full">
          <SubmitButton text="Save" loading={loading} />
        </div>
      </FormActions>
    </form>
  );
};

export default CreateEducation;
