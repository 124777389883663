import React, { useCallback } from 'react';
import { CollectionSummary } from '@communo-corp/shared-types';
import { capitalize } from 'lodash';
import useCollectionInviteLink from '../hooks/useCollectionInviteLink';
import { appUrl } from '../../../utilities/Url';
import { addError, addSuccess } from '../../../services/Messaging';
import Button from '../../core/Button';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import useFeatureGate from '../../../hooks/useFeatureGate';

type Props = {
  collection: CollectionSummary;
};

const LinkInviteToCollection = ({ collection }: Props) => {
  const { openUpgradeFlowFor } = useFeatureGate();
  const { checkAccess } = useCurrentMembership();
  const { invite, isLoading } = useCollectionInviteLink(collection.id);
  const inviteId = invite?.id ?? null;

  const permission =
    collection.type === 'bench'
      ? 'canInviteToBenchViaLink'
      : 'canInviteToListViaLink';
  const hasAccess = checkAccess(permission);

  const collectionType = capitalize(collection.type);

  const onCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        appUrl(`/i/${collection.type}/${inviteId}`)
      );
      addSuccess(`Link copied to clipboard.`);
    } catch (err) {
      addError('Failed to copy: ', err);
    }
  }, [collection.type, inviteId]);

  return (
    <div className="p-4 md:p-8 md:pb-4 border-t-2 border-grey-500">
      <div className="flex items-center space-x-4">
        <div className="w-12">
          <div className="icon-circle bg-blue-1100 text-white text-2xl">
            <i className="i-link" />
          </div>
        </div>

        <h4 className="h4">Get a link</h4>
      </div>

      <div className="md:-mt-4 flex flex-col sm:flex-row sm:items-end sm:space-x-4 flex-1 pl-0 md:pl-16">
        <div className="flex-1">
          <p className="mt-2">
            Copy and send this link to anyone you wish to invite to join your{' '}
            {collectionType}.
          </p>
          {!hasAccess ? (
            <p className="font-bold">
              To access this feature you must upgrade your plan.
            </p>
          ) : null}
        </div>

        <div className="mt-4 sm:mt-0">
          {hasAccess ? (
            <Button
              type="submit"
              text="Copy Link"
              fill="outline"
              color="primary"
              size="sm"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => onCopy()}
            />
          ) : (
            <Button
              type="submit"
              text="Upgrade"
              fill="solid"
              color="primary"
              size="sm"
              onClick={() => openUpgradeFlowFor('benchSizeLimit')}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LinkInviteToCollection;
