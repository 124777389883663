/* eslint-disable no-unused-vars */
import { IonIcon } from '@ionic/react';
import { barChart } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityStats,
  Participant,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Track,
} from 'twilio-video';
import ParticipantAudio from './ParticipantAudio';
import ParticipantVideo from './ParticipantVideo';

const networkColors: { [key: number]: string } = {
  0: 'text-danger-900',
  1: 'text-danger-900',
  2: 'text-warning-900',
  3: 'text-warning-900',
  4: 'text-white',
  5: 'text-white',
};

const ParticipantCard = ({
  participant,
  user = null,
  isDominant = false,
  isLocal = false,
  hideInfo = false,
  hideMobile = false,
  hideDesktop = false,
}: {
  participant: Participant;
  user?: UserEntity | null;
  isDominant?: boolean;
  isLocal?: boolean;
  hideInfo?: boolean;
  hideMobile?: boolean;
  hideDesktop?: boolean;
}) => {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [networkQuality, setNetworkQuality] = useState(0);

  const networkQualityChanged = (level: number, stats: NetworkQualityStats) => {
    setNetworkQuality(level);
  };

  const trackToggled = useCallback(
    (targetTrack: RemoteTrack) => {
      setTracks((prevTracks: Track[]) =>
        prevTracks.map((t: Track) =>
          t.name === targetTrack.name ? targetTrack : t
        )
      );
    },
    [setTracks]
  );

  const trackStart = useCallback(
    (track: RemoteTrack) => {
      if (track) {
        setTracks(prevTracks => [...prevTracks, track]);

        track.on('enabled', trackToggled);
        track.on('disabled', trackToggled);
      }
    },
    [setTracks, trackToggled]
  );

  const trackStop = useCallback(
    (track: RemoteTrack) => {
      if (track) {
        track.off('enabled', trackToggled);
        track.off('disabled', trackToggled);

        setTracks(prevTracks =>
          prevTracks.filter((t: Track) => t.name !== track.name)
        );
      }
    },
    [setTracks, trackToggled]
  );

  useEffect(() => {
    participant.on('trackSubscribed', trackStart);
    participant.on('trackUnsubscribed', trackStop);
    participant.on('networkQualityLevelChanged', networkQualityChanged);

    participant.tracks.forEach((pub: any) => {
      if (isLocal || pub.isSubscribed) {
        trackStart(pub.track);
      }
    });

    return () => {
      participant.tracks.forEach((pub: any) => {
        trackStop(pub.track);
      });

      participant.off('trackSubscribed', trackStart);
      participant.off('trackUnsubscribed', trackStop);
      participant.off('networkQualityLevelChanged', networkQualityChanged);
    };
  }, [isLocal, participant, trackStart, trackStop]);

  return participant ? (
    <div
      className={`video-participant ${isDominant ? 'video-dominant' : ''} ${
        isLocal ? 'video-local' : ''
      } ${hideMobile ? 'video-hide-mobile' : ''} ${
        hideDesktop ? 'video-hide-desktop' : ''
      }`}
    >
      <div className="video-container">
        {tracks
          .filter((track: Track) => track.kind === 'video')
          .map((track: Track, index: number, arr: Track[]) => {
            let flip = isLocal;

            if (arr.length > 1) {
              if (index !== arr.length - 1) {
                return null;
              }

              flip = false;
            }

            return (
              <ParticipantVideo
                key={track.name}
                isLocal={flip}
                user={user!}
                track={track as LocalVideoTrack | RemoteVideoTrack}
              />
            );
          })}
        {tracks
          .filter((track: Track) => track.kind === 'audio')
          .map((track: Track) => (
            <ParticipantAudio
              key={track.name}
              track={track as LocalAudioTrack | RemoteAudioTrack}
            />
          ))}
        {!hideInfo && (
          <p className="absolute bottom-0 text-center p-2">
            <IonIcon
              className={`mr-2 ${networkColors[networkQuality]}`}
              src={barChart}
            />
            {user ? `${user.firstName} ${user.lastName}` : 'Connecting...'}
          </p>
        )}
      </div>
    </div>
  ) : null;
};

export default ParticipantCard;
