import React, { useMemo } from 'react';
import moment from 'moment';
import { timeAgo } from '../../../utilities/dateTime';
import { currency } from '../../../utilities/Number';

import {
  ENABLE_PROJECT_TYPES,
  SHOW_COMPANY_ON_JOB_POST,
} from '../../../config';
import RedactedPrice from './RedactedPrice';
import { getBudgetTypeLabel, getWorkStartLabel } from './Helpers';
import { getWorkTypeLabel } from '../search/getTypeOptions';

type Props = {
  canManage: boolean;
  userId: string;
  work: WorkEntity;
};

const MetaWorkDetails = ({ canManage, userId, work }: Props) => {
  const skills = work.skills.map(skill => skill.name);
  const industries = work.industries.map(industry => industry.name);
  const { audience, requirements, isRemote, company } = work;

  const hourlyRate = work.hourlyRate ? currency(work.hourlyRate) : null;
  const workStart = getWorkStartLabel(work.workStart);
  const budgetType = getBudgetTypeLabel(work.budgetType, work.type);

  const price = useMemo(() => {
    if (!userId && work.price) return <RedactedPrice />;
    return work.price ? currency(work.price) : null;
  }, [userId, work.price]);

  const date = timeAgo(
    new Date(
      moment(work.expiresAt)
        .subtract(30, 'day')
        .toISOString()
    )
  );

  return (
    <>
      <h5 className="my-8">{work.title}</h5>

      {['freelancers', 'agencies'].includes(audience as string) ? (
        <div className="mt-2 -mx-4">
          <div className="notice notice--warning">
            <p className="text-black text-lg">
              {audience === 'freelancers' ? (
                <>
                  <span className="font-semibold">Talent members only</span> (no
                  Agencies please)
                </>
              ) : (
                <>
                  <span className="font-semibold">Agency only</span> (no Talent
                  members please)
                </>
              )}
            </p>
          </div>
        </div>
      ) : null}

      {SHOW_COMPANY_ON_JOB_POST && company && canManage ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Company</p>
          <p className="mt-1 text-lg">{company.name}</p>
        </div>
      ) : null}

      {requirements ? (
        <div className="mt-2 -mx-4">
          <div className="notice notice--warning">
            <p className="text-gold-1100 font-medium">Requirements</p>
            <p className="text-black text-lg">{requirements}</p>
          </div>
        </div>
      ) : null}

      <div className={isRemote ? 'mt-8' : 'mt-2 -mx-4'}>
        <div className={isRemote ? '' : 'notice notice--warning'}>
          <p
            className={`font-medium ${
              isRemote ? 'text-grey-400' : 'text-gold-1100'
            }`}
          >
            Location
          </p>
          <p className="text-black mt-1 text-lg">
            {work.location?.friendlyName ?? '—'}
          </p>
          {isRemote && (
            <p className="text-black font-medium text-lg">Remote OK</p>
          )}
        </div>
      </div>

      <div className="mt-8">
        <p className="text-grey-400 font-medium">Type</p>
        <p className="mt-1 text-lg">{getWorkTypeLabel(work.type) ?? '—'}</p>
      </div>
      {ENABLE_PROJECT_TYPES && work.projectOption?.projectType?.name && (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Project Type</p>
          <p className="mt-1 text-lg">
            {work.projectOption?.projectType?.name}
          </p>
        </div>
      )}
      {ENABLE_PROJECT_TYPES && work.projectOption?.name && (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Project Option</p>
          <p className="mt-1 text-lg">{work.projectOption?.name}</p>
        </div>
      )}
      {work.type !== 'Student' && work.role ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Role</p>
          <p className="mt-1 text-lg">{work.role?.name ?? '—'}</p>
        </div>
      ) : null}
      {skills.length ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Skills</p>
          <p className="mt-1 text-lg">{skills.join(', ')}</p>
        </div>
      ) : null}
      {industries.length ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Industry</p>
          <p className="mt-1 text-lg">{industries.join(', ')}</p>
        </div>
      ) : null}
      {workStart ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Work Start</p>
          <p className="mt-1 text-lg">{workStart}</p>
        </div>
      ) : null}
      {budgetType ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Budget Type</p>
          <p className="mt-1 text-lg">{budgetType}</p>
        </div>
      ) : null}
      {work.hoursPerWeek ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Hours per Week</p>
          <p className="mt-1 text-lg">{work.hoursPerWeek}</p>
        </div>
      ) : null}
      {price ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Budget (EST)</p>
          <div className="mt-1 text-lg">{price}</div>
        </div>
      ) : null}
      {hourlyRate ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Hourly Rate</p>
          <p className="mt-1 text-lg">{hourlyRate}</p>
        </div>
      ) : null}
      {work.duration ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">
            How long do you need them for?
          </p>
          <p className="mt-1 text-lg">{work.duration} weeks</p>
        </div>
      ) : null}
      <div className="mt-8">
        <p className="text-grey-400 font-medium">Posted</p>
        <p className="mt-1 text-lg">{date}</p>
      </div>
    </>
  );
};

export default MetaWorkDetails;
