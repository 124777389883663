import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import moment, { Moment } from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { FormGroup } from '.';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  name: string;
  label: string;
  hint?: string;
  errors?: any;
  startDate?: Date;
  onChange(date: Moment | null): any;
  showTime?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  required?: boolean;
}

const usersTimezone = moment.tz.guess();

const FormDateTime = React.forwardRef(
  (
    {
      name,
      label,
      hint,
      errors,
      startDate,
      onChange,
      showTime = true,
      dateFormat = 'MMMM d, yyyy',
      timeFormat = 'h:mm aa',
      showMonthYearPicker = false,
      showYearPicker = false,
      required = false,
    }: Props,
    ref: any
  ) => {
    const firstUpdate = useRef(true);
    const [date, setDate] = useState<Date | null>(() => {
      if (startDate === undefined) {
        return null;
      }

      return moment
        .utc(startDate)
        .tz(usersTimezone)
        .toDate();
    });
    const [time, setTime] = useState<Date | null>(() => {
      if (startDate === undefined) {
        return null;
      }

      return moment
        .utc(startDate)
        .tz(usersTimezone)
        .toDate();
    });

    useEffect(() => {
      // We dont want to emit a value on mount because that will trigger validation in some cases.
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }

      if (date === null) {
        onChange(null);
        return;
      }

      const newDate = new Date(date.getTime());

      if (time !== null && showTime) {
        const hrs = time.getHours();
        const mins = time.getMinutes();
        const sec = time.getSeconds();
        newDate.setHours(hrs, mins, sec);
      }

      onChange(moment.tz(newDate, usersTimezone));
    }, [date, time, onChange, showTime]);

    return (
      <FormGroup
        name={name}
        label={label}
        hint={hint ? `${hint} (${usersTimezone})` : undefined}
        errors={errors}
        required={required}
      >
        <div className="row flex">
          <div className="col">
            <DatePicker
              className="form-input"
              selected={date}
              showMonthYearPicker={showMonthYearPicker}
              showYearPicker={showYearPicker}
              inline={showMonthYearPicker}
              onChange={(choosenDate: Date) => {
                // if user clears the input
                if (choosenDate === null) {
                  setDate(null);
                  return;
                }

                // End of the day on the selected date. Useful if not showing time.
                const endOfDay = moment(choosenDate)
                  .endOf('day')
                  .toDate();

                setDate(endOfDay);
              }}
              placeholderText="Date"
              dateFormat={dateFormat}
            />
          </div>
          {showTime && (
            <div className="col">
              <DatePicker
                className="form-input"
                selected={time}
                onChange={(choosenTime: Date) => setTime(choosenTime)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat={timeFormat}
                placeholderText="Time"
              />
            </div>
          )}
        </div>
        <input id={name} name={name} type="hidden" ref={ref} />
      </FormGroup>
    );
  }
);
export default FormDateTime;
