import development from './development';
import { brandStageFeatureFlags } from './brandFeatureFlags';
import { Environment } from '.';

// DO NOT UPDATE THE VALUE OF THE BRAND
// FEATURE FLAGS ON THIS REPO.
// UPDATE THEM THROUGH THE ASSOCIATED BRAND PACKAGE.
const brandEnvironment: Environment = {
  ...development,
  ...brandStageFeatureFlags,
  doDeployUpdate: true,
};

export default brandEnvironment;
