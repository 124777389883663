import { useContext } from 'react';
import MembershipContext from '../components/membership/Context';
import UserContext from '../components/user/Context';

type Result = {
  userId: string | null;
  membershipId: string | null;
  portfolioItems: PortfolioEntity[];
  refresh: Function;
};

const usePortfolioItems = (forUser: boolean = false): Result => {
  const membershipContext = useContext(MembershipContext);
  const userContext = useContext(UserContext);

  if (forUser) {
    return {
      userId: userContext?.id ?? null,
      membershipId: null,
      portfolioItems: userContext?.user?.portfolioItems ?? [],
      refresh: userContext?.refresh,
    };
  }

  return {
    userId: null,
    membershipId: membershipContext?.membership?.id ?? null,
    portfolioItems: membershipContext?.membership?.portfolioItems ?? [],
    refresh: membershipContext?.refresh,
  };
};

export default usePortfolioItems;
