import React, {
  forwardRef,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { CollectionSummary } from '@communo-corp/shared-types';
import { capitalize } from 'lodash';
import Button from '../../core/Button';
import { FormGroup, FormImage, FormInput, FormTextarea } from '../../core/form';
import Api from '../Api';
import { addResponseError } from '../../../services/Messaging';
import FormToggle from '../../core/form/FormToggle';
import UserContext from '../../user/Context';
import Modal from '../../core/modal/Modal';
import FormColorPicker from '../../core/form/FormColorPicker';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../../../asset-config';

type Props = {
  collection: CollectionSummary;
  onSave: Function;
  onClose: Function;
};

type ContentProps = {
  membership: MembershipEntity;
  form: UseFormMethods<any>;
  onSubmit(values: any): void;
  showSharing: boolean;
  collection: CollectionSummary;
};

const Content = forwardRef(
  (
    { form, onSubmit, showSharing, membership, collection }: ContentProps,
    ref: any
  ) => {
    const nameMaxLength = 30;
    const descriptionMaxLength = 60;
    const isBench = collection.type === 'bench';

    const defaultBrandLogoURL = collection?.logoURL || membership.logoURL;
    const defaultBrandCoverPhotoURL =
      collection?.coverPhotoURL || membership.coverPhotoURL;
    const defaultBrandColor = collection?.brandColor ?? '#1F2326';

    return (
      <form
        id="editCollection"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
      >
        {!isBench ? (
          <>
            <FormInput
              name="name"
              label="Name"
              placeholder=""
              errors={form.errors}
              maxLength={nameMaxLength}
              ref={(e: any) => {
                // eslint-disable-next-line
                ref.current = e;
                form.register(e, {
                  required: 'Name is required',
                  maxLength: {
                    value: nameMaxLength,
                    message: `Max ${nameMaxLength} characters`,
                  },
                });
              }}
            />

            <FormTextarea
              name="description"
              label="Description (optional)"
              placeholder=""
              maxLength={descriptionMaxLength}
              errors={form.errors}
              ref={form.register({
                maxLength: {
                  value: descriptionMaxLength,
                  message: `Max ${descriptionMaxLength} characters`,
                },
              })}
              richText={false}
              rows={3}
              characterCount={descriptionMaxLength}
              form={form}
            />
          </>
        ) : null}

        {showSharing ? (
          <FormToggle
            control={form.control}
            label="Sharing"
            name="attachToMembership"
            hint="Set to Company to share this list with members of your team."
            leftValue="company"
            leftLabel="Company"
            rightValue="private"
            rightLabel="Private"
          />
        ) : null}

        <FormImage
          name="brandLogoURL"
          label="Brand Logo"
          // hideAvatar
          onChange={() => {}}
          errors={form.errors}
          form={form}
          ref={form.register}
          aspectRatio={[1, 1]}
          defaultValue={defaultBrandLogoURL ?? DEFAULT_ONBOARDING_LOGO_URL}
        />
        <FormImage
          name="brandCoverPhotoURL"
          label="Cover Photo"
          onChange={() => {}}
          errors={form.errors}
          form={form}
          ref={form.register}
          defaultValue={
            defaultBrandCoverPhotoURL ?? DEFAULT_ONBOARDING_COVER_PHOTO_URL
          }
        />
        <FormGroup label="Brand Color" name="brandColor" errors={form.errors}>
          <FormColorPicker
            form={form}
            name="brandColor"
            defaultValue={defaultBrandColor}
          />
        </FormGroup>
      </form>
    );
  }
);

const Footer = ({ isLoading, onClose }: any) => {
  return (
    <>
      <Button
        text="Cancel"
        fill="outline"
        size="sm"
        onClick={() => onClose()}
      />
      <Button
        type="submit"
        form="editCollection"
        text="Save List"
        fill="solid"
        color="primary"
        size="sm"
        loading={isLoading}
      />
    </>
  );
};

const EditCollectionModal = ({ collection, onSave, onClose }: Props) => {
  const nameInputRef = useRef<HTMLInputElement>();
  const [isLoading, setLoading] = useState(false);
  const { id } = useContext(UserContext);
  const { membership } = useCurrentMembership();
  const isBench = collection.type === 'bench';

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: collection.name,
      description: collection.description,
      attachToMembership: collection.membershipId ? 'company' : 'private',
      brandColor: collection?.brandColor,
      brandCoverPhotoURL: collection?.coverPhotoURL,
      brandLogoURL: collection?.logoURL,
    },
  });

  const onSubmit = useCallback(
    (values: any) => {
      setLoading(true);

      const data = {
        name: values.name,
        description: values.description || null,
        attachToMembership: isBench
          ? true
          : values.attachToMembership === 'company',
        brandColor: values.brandColor || null,
        coverPhotoURL: values.brandCoverPhotoURL || null,
        logoURL: values.brandLogoURL || null,
      };

      Api.updateCollection(collection.id, data)
        .then(() => {
          setLoading(false);
          onSave();
        })
        .catch(err => {
          setLoading(false);
          addResponseError(err);
        });
    },
    [collection.id, isBench, onSave]
  );

  const displayType = capitalize(collection.type);
  const showSharing = collection.userId === id;

  if (!membership) {
    return null;
  }

  return (
    <Modal
      isOpen
      title={`Edit ${displayType}`}
      width="xl"
      onClose={onClose}
      onOpen={() => {
        if (nameInputRef.current) nameInputRef.current.focus();
      }}
      footer={<Footer isLoading={isLoading} onClose={onClose} />}
    >
      <Content
        form={form}
        onSubmit={onSubmit}
        showSharing={showSharing}
        ref={nameInputRef}
        collection={collection}
        membership={membership}
      />
    </Modal>
  );
};

export default EditCollectionModal;
