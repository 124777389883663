import React, { useContext } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import UserContext from '../components/user/Context';
import PlanPayment from '../components/plan/Payment';
import Page from '../components/core/Page';
import useNavigate from '../hooks/useNavigate';

export default ({ match }: any) => {
  const { authDetails, isAuthenticated } = useContext(UserContext);
  const { slug, recurlyId, coupon } = match.params;
  const navigate = useNavigate();

  useIonViewWillEnter(() => {
    // redirect to login / sign up
    const { pathname } = window.location;
    const path = `/signup?redirectUrl=${pathname}`;
    if (!authDetails.email && !isAuthenticated)
      navigate(path, 'none', 'replace');
  }, [authDetails.email]);

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Payment Info"
      mobileHeader={() => null}
      hideHeader
    >
      {(authDetails.email || isAuthenticated) && (
        <PlanPayment slug={slug} recurlyId={recurlyId} coupon={coupon} />
      )}
    </Page>
  );
};
