import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
// import EditorToolbar from './EditorToolbar';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

type Props = {
  defaultValue?: string;
  placeholder?: string;
  onChange?: (content: string, plainText: string) => void;
};

/*
 * Simple editor component that takes placeholder text as a prop
 */
const Editor = ({ defaultValue, placeholder, onChange }: Props) => {
  const ref = useRef<any>(null);
  // const index = 0; // TODO make this a unique key per editor

  // See https://quilljs.com/docs/formats/
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
  ];

  // See https://quilljs.com/docs/modules/toolbar/
  const toolbarOptions = [
    [
      'bold',
      'italic',
      'underline',
      'clean',
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    // [{ header: '5' }], // needs the H5 icon (only H1, H2 icons are available)
    [{ header: [5, false] }],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  const handleChange = (content: string, _d: any, _s: any, editor: any) => {
    // when the editor is blank, by default it sets the content to <p><br></p>
    // eslint-disable-next-line no-param-reassign
    if (content === '<p><br></p>') content = '';
    if (onChange) onChange(content, editor.getText().trim());
  };

  return (
    <div>
      <ReactQuill
        ref={ref}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
