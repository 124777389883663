import { keyBy, pick } from 'lodash';
import createStore from '../../../utilities/createStore';
import { IChannel } from '../../../@types/chat.d';
import ChannelInstances from '../instances/ChannelInstances';

const keysToPick = [
  'attributes',
  'createdBy',
  'dateCreated',
  'dateUpdated',
  'friendlyName',
  'isPrivate',
  'lastConsumedMessageIndex',
  'lastMessage',
  'notificationLevel',
  'sid',
  'state',
  'status',
  'type',
  'uniqueName',
];

/**
 * ChatChannelsState
 * Stores the list of Channels the user has access to.
 * Exposes a function to reload the chat channels (pull to refresh).
 */

export type ChatChannelsState = {
  channels: {
    [sid: string]: IChannel;
  };
  loading: boolean;
  loaded: boolean;
  reloadChannels: () => void;
  loadingChannels: () => void;
  addChannels: (channels: IChannel[]) => void;
  addChannel: (channel: IChannel) => void;
  updateChannel: (channel: IChannel) => void;
  removeChannel: (channel: IChannel) => void;
};

const chatChannelsStore = createStore<ChatChannelsState>(
  (set: any) => ({
    channels: {},
    loading: false,
    loaded: false,
    reloadChannels: () =>
      set({ loading: false, loaded: false }, 'reloadChannels'),
    loadingChannels: () =>
      set({ loading: true, loaded: false }, 'loadingChannels'),
    addChannels: channels => {
      const rawChannelsData = channels.map(channel =>
        pick(channel, keysToPick)
      );

      ChannelInstances.add(channels);

      set(
        (state: ChatChannelsState) => ({
          channels: { ...state.channels, ...keyBy(rawChannelsData, 'sid') },
          loading: false,
          loaded: true,
        }),
        'addChannels'
      );
    },
    addChannel: channel => {
      ChannelInstances.add([channel]);
      set(
        (state: ChatChannelsState) => ({
          ...state,
          channels: {
            ...state.channels,
            [channel.sid]: pick(channel, keysToPick),
          },
        }),
        'addChannel'
      );
    },
    updateChannel: channel => {
      ChannelInstances.update([channel]);

      set(
        (state: ChatChannelsState) => ({
          ...state,
          channels: {
            ...state.channels,
            [channel.sid]: pick(channel, keysToPick),
          },
        }),
        'updateChannel'
      );
    },
    removeChannel: channel =>
      set((state: ChatChannelsState) => {
        const channels = { ...state.channels };

        delete channels[channel.sid];
        ChannelInstances.remove([channel]);

        return {
          ...state,
          channels: {
            ...channels,
          },
        };
      }, 'removeChannel'),
  }),
  'ChatChannelsState'
);

export default chatChannelsStore;
