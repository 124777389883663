import React, { useState } from 'react';
import { CollectionSummary } from '@communo-corp/shared-types';
import validator from 'validator';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { FormGroup } from '../../core/form';
import Api from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Button from '../../core/Button';
import useFeatureGate from '../../../hooks/useFeatureGate';

type Props = {
  collection: CollectionSummary;
  inviteCount: number;
  refetchInvites: Function;
};

const EmailCollectionInviteForm = ({
  collection,
  inviteCount,
  refetchInvites,
}: Props) => {
  const { needsUpgrade } = useFeatureGate();
  const [state, setState] = useState({
    loading: false,
    errors: null,
  });

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      email: null,
    },
  });

  const onSubmit = (values: any) => {
    const permission =
      collection.type === 'bench' ? 'benchSizeLimit' : 'listSizeLimit';
    if (needsUpgrade(permission, inviteCount + 1)) {
      return;
    }

    setState({
      errors: null,
      loading: true,
    });

    Api.sendEmailInvite(collection.id, values.email)
      .then(() => {
        refetchInvites();
        addSuccess(`Invite sent to ${values.email}!`);
        reset();

        setState({
          errors: null,
          loading: false,
        });
      })
      .catch(err => {
        addResponseError(err);

        setState({
          errors: err?.response?.data?.message || err?.message || null,
          loading: false,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
      <p className="font-semibold">Send an invitation</p>

      <div className="flex flex-col sm:flex-row">
        <FormGroup errors={errors} name="email" className="flex-1 mr-4 mb-0">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Enter an email address"
            className={cn('form-input p-2', {
              'border-red-700': !!state.errors,
            })}
            ref={register({
              required: 'Email address is required',
              validate: value => validator.isEmail(value) || 'Invalid email',
            })}
          />
        </FormGroup>
        <div>
          <Button
            loading={state.loading}
            type="submit"
            color="primary"
            fill="solid"
            text="Send Invite"
            className="w-32 mt-4 sm:mt-0"
          />
        </div>
      </div>
    </form>
  );
};
export default EmailCollectionInviteForm;
