import React from 'react';
import Page from '../components/core/Page';
import SignupLocomotus from '../components/user/views/SignupLocomotus';

const SignupLocomotusPage = () => {
  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--white"
      hideHeader
      unMountOnLeave
    >
      <SignupLocomotus />
    </Page>
  );
};
export default SignupLocomotusPage;
