import React, { useContext, useEffect } from 'react';
import { track } from '../../../analytics';
import { FormTextarea } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';

const Endorsement = () => {
  const { setCanAdvance } = useStep();
  const { form } = useContext(SubmitReferenceContext);
  const { errors, register, formState } = form;
  const { isValid } = formState;

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Endorsement',
    });
  }, []);

  return (
    <div>
      <FormTextarea
        required
        name="endorsement"
        label="Message"
        labelSubtext="(Max 500 characters)"
        placeholder=""
        errors={errors}
        rows={5}
        ref={register({
          required: 'Message is required',
        })}
        characterCount={500}
        form={form}
      />
    </div>
  );
};
export default Endorsement;
