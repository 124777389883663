import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { PlanType } from '@communo-corp/shared-types';
import useUser from '../../hooks/useUser';
import Loading from '../core/Loading';
import FreelancerProfile from './FreelancerProfile';
import EmployeeProfile from './EmployeeProfile';
import { Error404 } from '../error';
import useAnalytics from '../../hooks/useAnalytics';
import usePullToRefresh from '../../hooks/usePullToRefresh';
import PageView from '../../analytics/PageView';
import useForceMobile from '../../hooks/useForceMobile';
import StudentProfile from './StudentProfile';
import UserContext from '../user/Context';

const profileComponents: any = {
  individual: FreelancerProfile,
  agency: EmployeeProfile,
  partner: EmployeeProfile,
  brand: EmployeeProfile,
  student: StudentProfile,
};

type Props = {
  id: string;
  setPageTitle?: Function;
};
const UserProfile = ({ id, setPageTitle }: Props) => {
  const { user: authUser } = useContext(UserContext);
  const { user, isLoading, isFetching, refetch } = useUser(id);
  const forceMobile = useForceMobile();

  usePullToRefresh(refetch, !isFetching);

  useEffect(() => {
    refetch();
  }, [authUser, refetch]);

  useEffect(() => {
    let pageTitle = 'Loading';

    if (!isLoading) {
      pageTitle = user
        ? `${user.firstName} ${user.lastName}`
        : 'User not found';
    }

    if (setPageTitle) setPageTitle(pageTitle);
  }, [user, setPageTitle, isLoading]);

  const { pageIsReady } = useAnalytics();
  useEffect(() => {
    if (!isLoading) {
      pageIsReady({
        pageType: PageView.USER,
        pageId: user?.id,
        pageName: 'Member',
        userId: user?.id,
        userName: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
        company: user?.company,
        location: user?.location?.friendlyName,
        role: user?.roles.map(el => el.role.name),
        roleCategory: user?.roles.map(el => el.role.category.name),
        skills: user?.skills.map(el => el.skill.name),
        industry: user?.industries.map(el => el.industry.name),
        vettingStatus: user?.primaryMembershipUser?.membership.vettingStatus,
      });
    }
  }, [isLoading, pageIsReady, user]);

  if (isLoading) {
    return <Loading isActive />;
  }

  const planType =
    user?.primaryMembershipUser?.membership.plan.type ?? PlanType.INDIVIDUAL;
  const ProfileComponent = profileComponents[planType] || null;

  return ProfileComponent ? (
    <div
      className={cn({
        'allow-responsive-styles': !forceMobile,
      })}
    >
      <ProfileComponent user={user} forceMobile={forceMobile} />
    </div>
  ) : (
    <Error404 title="Member 404" message="We couldn't find this user..." />
  );
};
export default UserProfile;
