import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
  ListEntityType,
  ListItemComponent,
  ListStyleType,
  SearchIndexes,
} from '@communo-corp/shared-types';
import { PageSize } from '@communo-corp/shared-types/dist/PageSize';
import useSearch from '../search/useSearch';
import SearchContext from '../search/Context';
// import testLists from './lists';
// import List from './List';
import { getSearchIndex } from '../../utilities/Algolia';
import Api from './Api';
import { addResponseError } from '../../services/Messaging';
import {
  Carousel,
  Category,
  Grouped,
  Inset,
  Feature,
  JobMatch,
} from './lists/index';
import { DISCOVER_LIST_OPTIONS } from '../../config';
import {
  DiscoverList,
  DiscoverListItem,
  DiscoverListSwiperOptions,
} from './index';
import Header from './Header';

function transformBreakpoint(breakpoint: string) {
  switch (breakpoint) {
    case 'xs':
      return 375;
    case 'sm':
      return 540;
    case 'md':
      return 768;
    case 'lg':
      return 992;
    case 'xl':
      return 1025;
    case '2xl':
      return 1280;
    case '3xl':
      return 1440;
    case '4xl':
      return 1920;
    case '5xl':
      return 2560;
    case '6xl':
      return 2560;
    default:
      return 375;
  }
}

function transformBreakpoints(breakpoints: { [key in PageSize]?: any }) {
  return Object.entries(breakpoints).reduce((carry, [key, value]) => {
    const size = transformBreakpoint(key);
    const obj = { ...carry, [size]: value };

    return obj;
  }, {});
}

function transformSwiperOptions(
  options: DiscoverListSwiperOptions | undefined
) {
  // Guard
  if (typeof options === 'undefined') {
    return DISCOVER_LIST_OPTIONS;
  }

  return Object.entries(options).reduce((carry, [key, value]) => {
    let obj = { ...carry };

    if (key === 'breakpoints') {
      const breakpoints = transformBreakpoints(value);
      obj = { ...obj, breakpoints };
    }

    // ...etc

    return obj;
  }, options);
}

const ListComponents: { [key in ListStyleType]: any } = {
  Carousel,
  Category,
  Feature,
  Grouped,
  Inset,
  JobMatch,
};

const Content = () => {
  const { multipleQueries } = useContext(SearchContext);
  const [results, handleSearch] = useSearch(multipleQueries);
  const [nearby] = useState(false);
  const [lists, setLists] = useState<DiscoverList[] | null>(null);
  const load = useCallback(async () => {
    const baseQuery = {
      query: '',
      params: {
        filters: 'isVisible=1',
        aroundLatLngViaIP: nearby,
        hitsPerPage: 6,
        page: 0,
      },
    };

    const queries: any = [];

    if (lists !== null) {
      lists.forEach(list => {
        queries[list.name] = {
          indexName: getSearchIndex(list.searchIndex || SearchIndexes.User),
          query: list.search?.query || '',
          params: { ...baseQuery.params, ...list.search?.params },
        };
      });

      return handleSearch(queries);
    }

    return null;
  }, [handleSearch, nearby, lists]);

  useEffect(() => {
    if (lists !== null) {
      load();
    }
  }, [lists, load]);

  const userTransformer = (user: UserEntity) => {
    return {
      id: user.id,
      heading: `${user.firstName} ${user.lastName}`,
      subHeading: user.roles[0].role.name,
      tertiaryHeading: user.company,
      actionUrl: `/members/user/${user.objectID}`,
      imageUrl: user.avatarURL,
      backgroundUrl: user.avatarURL,
      location: user.location?.friendlyName,
    };
  };

  const membershipTransformer = (membership: MembershipEntity) => {
    return {
      id: membership.id,
      heading: membership.name,
      subHeading: membership.roles[0].role.name,
      tertiaryHeading: undefined, // Can replace this with whatever when we have a need
      actionUrl: `/members/membership/${membership.objectID}`,
      imageUrl: membership.logoURL,
      backgroundUrl: membership.coverPhotoURL,
      location: membership.location?.friendlyName,
    };
  };

  const normalizeItems = (
    items: undefined | (UserEntity | MembershipEntity)[]
  ) => {
    // Guard if undefined
    if (typeof items === 'undefined') {
      return [];
    }

    return items?.map<DiscoverListItem>(item => {
      // Type Guard
      if ('firstName' in item) {
        return userTransformer(item);
      }

      return membershipTransformer(item);
    });
  };

  const getLists = useCallback(() => {
    Api.list()
      .then((discoverLists: DiscoverList[]) => {
        // TODO: Better way to set the 'name' of a list?
        const items = discoverLists.map((item: any, index: number) => {
          return { ...item, name: index.toString() };
        });

        setLists(items);
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  }, []);

  useEffect(() => {
    getLists();
  }, [getLists]);

  return (
    <>
      {lists &&
        results &&
        lists.map(list => {
          const ListComponent = ListComponents[list.listStyle.type] || null;

          const {
            name,
            heading,
            subHeading,
            imageUrl,
            actionText,
            actionUrl,
            items,
            listStyle,
            entityType,
          } = list;

          let resultItems = [];

          if (items?.length) {
            // If backend supplying the items
            resultItems = items;
          } else if (
            entityType === ListEntityType.Work ||
            entityType === ListEntityType.Event
          ) {
            // These just get passed through to the card components
            resultItems = results[name]?.hits;
          } else {
            // Make the items into a structure we expect
            resultItems = normalizeItems(results[name]?.hits);
          }

          const swiperOptions = transformSwiperOptions(listStyle.swiperOptions);
          // const swiperOptions = transformSwiperOptions({
          //   slidesPerView: 1.25,
          //   spaceBetween: 16,
          //   breakpoints: {
          //     sm: {
          //       slidesPerView: 3,
          //     },
          //     md: {
          //       slidesPerView: 4,
          //     },
          //     lg: {
          //       slidesPerView: 5,
          //     },
          //     '3xl': {
          //       slidesPerView: 6,
          //     },
          //   },
          // });

          // TODO: Return null if number of items less than our min threshold for a list's items?
          if (resultItems === undefined || resultItems.length <= 0) {
            return null;
          }

          return (
            <ListComponent
              key={name}
              entityType={entityType}
              actionText={actionText}
              listItemComponent={
                listStyle.listItemComponent || ListItemComponent.Default
              }
              showNavigation={resultItems.length > 5}
              actionUrl={actionUrl}
              items={resultItems}
              heading={heading}
              subHeading={subHeading}
              imageUrl={imageUrl}
              swiperOptions={swiperOptions}
            />
          );
        })}
    </>
  );
};

const View = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default View;
