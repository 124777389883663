import React, { useEffect, useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { translateCompanySize } from '@communo-corp/shared-types';
import SteppedRangeSlider, {
  SteppedRangeValue,
} from '../../core/SteppedRangeSlider';
import { FormGroup } from '../../core/form';

interface Props {
  form: UseFormMethods<any>;
  name?: string;
  onChange?(selected: SteppedRangeValue): void;
  defaultValue?: number | null;
  required?: boolean;
}

function getOptions(): SteppedRangeValue[] {
  const options = [];

  for (let index = 1; index <= 7; index += 1) {
    options.push({
      label: translateCompanySize(index),
      value: index,
    });
  }

  return options;
}

const CompanySize = ({
  form,
  onChange,
  name = 'size',
  defaultValue = 4,
  required = false,
}: Props) => {
  const { setValue, register, errors } = form;
  const options = getOptions();

  const defaultIndex = useMemo(() => {
    const foundIndex = options.findIndex(value => {
      return value.value === defaultValue;
    });
    return foundIndex >= 0 ? foundIndex : 4;
  }, [defaultValue, options]);

  const handleOnChange = (selected: SteppedRangeValue) => {
    setValue(name, selected, {
      shouldValidate: true,
      shouldDirty: true,
    });

    if (onChange) {
      onChange(selected);
    }
  };

  useEffect(() => {
    register(
      { name, type: 'custom' },
      {
        required: 'Company size is required',
      }
    );
  }, [name, register]);

  useEffect(() => {
    // set the default values (only called once)
    setValue(name, options[defaultIndex]);
    // eslint-disable-next-line
  }, []);

  return (
    <FormGroup name={name} errors={errors}>
      <SteppedRangeSlider
        options={options}
        defaultIndex={defaultIndex}
        label="Company size"
        onChange={handleOnChange}
        required={required}
      />
    </FormGroup>
  );
};
export default CompanySize;
