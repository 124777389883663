import { useEffect } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import { setStyle } from '../services/StatusBar';

type Props = {
  isDark?: boolean;
};

const useStatusBar = ({ isDark = false }: Props) => {
  useEffect(() => {
    setStyle(isDark);
    // eslint-disable-next-line
  }, []);

  useIonViewWillEnter(() => {
    setStyle(isDark);
  }, []);

  return {
    setStyle,
  };
};

export default useStatusBar;
