import membershipCompletenessStore from '../state/membershipCompletenessStore';

const initMembershipCompletenessStore = async (
  membership: MembershipEntity
) => {
  const [, storeApi] = membershipCompletenessStore;
  const { evaluateMembership } = storeApi.getState();

  evaluateMembership(membership);
};

export default initMembershipCompletenessStore;
