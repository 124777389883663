import React from 'react';
import { Route } from 'react-router';
import Page from '../components/core/Page';
import EditorDemo from '../demos/EditorDemo';
import { Link } from '../components/core/router';

const DemosPage = () => {
  return (
    <Page>
      <div className="md:flex gap-4 pt-8">
        <div className="md:w-64">
          <h4 className="mb-4">Demos</h4>
          <Link to="/demos/editor">Editor Demo</Link>
        </div>
        <div className="w-full">
          <Route path="/demos/editor" component={EditorDemo} />
        </div>
      </div>
    </Page>
  );
};

export default DemosPage;
