import React from 'react';
import validator from 'validator';
import { FormInput } from '../../core/form';

export type SocialLink = {
  key: string;
  label: string;
  optional?: boolean;
};

interface Props {
  errors: any;
  register: any;
  links: SocialLink[];
}

const SocialLinks = ({ errors, register, links }: Props) => (
  <div>
    {links.map(({ key, label, optional }: SocialLink) => (
      <FormInput
        name={`${key}`}
        label={label}
        errors={errors}
        key={key}
        placeholder="Enter url"
        labelSubtext={optional === true ? null : null}
        ref={register({
          validate: (value: string) =>
            validator.isEmpty(value) || validator.isURL(value) || 'Invalid url',
        })}
      />
    ))}
  </div>
);
export default SocialLinks;
