import React from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import RequestRecommendationButton from '../../recommendations/RequestRecommendationButton';
import ViewRecommendationsButton from '../../recommendations/ViewRecommendationsButton';

const RecommendationsForm = () => {
  const user = useCurrentUser();

  if (!user?.id) {
    return null;
  }

  return (
    <div>
      <div>
        <RequestRecommendationButton
          fill="solid"
          className="w-full md:w-auto"
        />
      </div>
      <div className="mt-8">
        <ViewRecommendationsButton
          userId={user.id}
          className="w-full md:w-auto"
        />
      </div>
    </div>
  );
};
export default RecommendationsForm;
