import React, { useCallback, useContext, useEffect } from 'react';
import {
  OAuthResponseError,
  OAuthResponseType,
} from '@communo-corp/shared-types';
import UserContext from '../../user/Context';
import LinkedInLogin from '../../auth/LinkedInLogin';
import useStep from '../../stepper/hooks/useStep';
import { addError } from '../../../services/Messaging';
import { track } from '../../../analytics';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';

const SignIn = () => {
  const { handleChangeAuthDetails, authenticateJwt } = useContext(UserContext);
  const { goToNextStep } = useStep();
  const { setOAuthProvider } = useContext(SubmitReferenceContext);

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Sign In',
    });
  }, []);

  const linkedInCallback = useCallback(
    async (data: any) => {
      if (data.type === OAuthResponseType.AUTH_RESPONSE) {
        const { payload } = data;
        await authenticateJwt(payload);

        goToNextStep();
      }

      // user is signing in with an unrecognized LinkedIn account
      if (data.type === OAuthResponseType.PROFILE) {
        const { profile, oauthProvider } = data.payload;

        track('Signed in via LinkedIn', {
          step: 'Sign In',
        });

        setOAuthProvider(oauthProvider);

        handleChangeAuthDetails(
          {
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            avatarURL: profile.profilePhotoUrl,
            oauthProviderId: oauthProvider.id,
          },
          () => {
            goToNextStep();
          }
        );
      }

      if (data.type === OAuthResponseType.ERROR) {
        const { error, message } = data.payload;

        if (error === OAuthResponseError.INACTIVE_USER) {
          const { email } = data.payload.profile;

          // Able to find an account but it is inactive
          addError(
            `We're unable to log you in because the account found (${email}) is inactive.`,
            {},
            'alert'
          );
        } else {
          // unknown error
          addError(message);
        }
      }
    },
    [authenticateJwt, goToNextStep, handleChangeAuthDetails, setOAuthProvider]
  );

  return (
    <div>
      <div className="max-w-72 mx-auto">
        <LinkedInLogin action="signup" callback={linkedInCallback} />
      </div>
    </div>
  );
};
export default SignIn;
