import React from 'react';
import WorkRequirementMetrics from './metrics/WorkRequirementMetrics';
import ProfileMetrics from './metrics/Profile';
import { ScoreCardProps } from './CandidateScore';

interface Props extends Omit<ScoreCardProps, 'refresh'> {
  requirements: ApplicationRequirements;
}

const MissingRequirementsCard = ({
  isUser,
  requirements,
  work,
  isHidden,
  scoreMetrics,
}: Props) => {
  return (
    <>
      <div>
        <h6 className="mb-2">Profile Completion</h6>
        <ProfileMetrics
          isUser={isUser}
          scoreMetrics={scoreMetrics}
          isHidden={isHidden}
        />
      </div>

      {isHidden ? (
        <div className="sm:col-span-2 md:col-span-1 lg:col-span-2">
          <div className="flex flex-col flex-1 min-h-full bg-red-900 rounded-md p-6 text-center">
            <h3 className="text-white">Missing Requirements</h3>
            <p className="mt-1 text-white text-lg leading-snug">
              The poster has asked for these fields to be completed before you
              can apply.
            </p>
            <p className="mt-4 text-black font-semibold text-sm">
              Click on the ‘Add’ link to complete the required fields.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <WorkRequirementMetrics
            isUser={isUser}
            requirements={requirements}
            work={work}
          />
        </div>
      )}
    </>
  );
};
export default MissingRequirementsCard;
