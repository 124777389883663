import React, { useEffect } from 'react';
import { useCookie } from 'react-use';
import useAnalytics from '../../../../hooks/useAnalytics';
import { addResponseError } from '../../../../services/Messaging';
import Api from '../../../collections/Api';
import useCollectionInvite from '../../../collections/hooks/useCollectionInvite';
import { LoadingBalls } from '../../../core/Loading';
import { INVITE_ID_COMMUNO_COOKIE } from '../../../landing/CollectionInviteLanding';
import useStep from '../../../stepper/hooks/useStep';

const AcceptInvite = ({ inviteId }: { inviteId: string }) => {
  const [, , deleteCookie] = useCookie(INVITE_ID_COMMUNO_COOKIE);
  const { invite, isLoading } = useCollectionInvite(inviteId);

  useEffect(() => {
    if (invite) {
      Api.acceptInvite(invite.collectionId, invite.id)
        .then(() => {
          deleteCookie();
        })
        .catch(err => {
          addResponseError(err);
        });
    }
  }, [deleteCookie, invite]);

  if (isLoading) {
    return <LoadingBalls isActive />;
  }

  return (
    <div className="text-center">
      <h3 className="mt-6 md:mt-0" data-swiper-parallax="-300">
        You&apos;ve been added to {invite.ownerName}&apos;s{' '}
        {invite.collectionType}!
      </h3>
    </div>
  );
};

const CollectionInviteStep = () => {
  const [inviteId] = useCookie(INVITE_ID_COMMUNO_COOKIE);
  const { goToNextStep, setCanAdvance } = useStep();

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - CollectionInvite Page');
  }, [track]);

  useEffect(() => {
    setCanAdvance(true);
  }, [setCanAdvance]);

  useEffect(() => {
    if (!inviteId) {
      goToNextStep();
    }
  }, [goToNextStep, inviteId]);

  if (!inviteId) {
    return <div>Sorry, we are unable to find that invite!</div>;
  }

  return <AcceptInvite inviteId={inviteId} />;
};

export default CollectionInviteStep;
