import React, { useMemo } from 'react';
import { Browser } from '@capacitor/browser';
import useCurrentUser from '../../hooks/useCurrentUser';
import useNavigate from '../../hooks/useNavigate';
import { forceHttps } from '../../utilities/Url';
import UserAvatar from '../avatar/UserAvatar';
import Modal from '../core/modal/Modal';
import useReferencesForUser from './hooks/useReferencesForUser';
import ReferenceAvatar from './ReferenceAvatar';
import ReferenceEndorsement from './ReferenceEndorsement';
import ReferenceRatings from './ReferenceRatings';
import ReferenceVisibilityToggle from './ReferenceVisibilityToggle';
import RequestReferenceButton from './RequestReferenceButton';

interface Props {
  references: Reference[];
  isOpen: boolean;
  user: UserEntity;
  onClose(): void;
}

const ViewReferencesModal = ({ references, isOpen, user, onClose }: Props) => {
  const { id: authUserId } = useCurrentUser();
  const { refetch } = useReferencesForUser(user.id);
  const navigate = useNavigate();
  const isOwnProfile = authUserId === user.id;
  const visibleReferences = useMemo(() => {
    if (isOwnProfile) {
      return references;
    }

    return references.filter(reference => reference.visibility);
  }, [isOwnProfile, references]);

  const header = useMemo(() => {
    return (
      <div className="pl-8 h-12 flex items-center">
        <UserAvatar user={user} size="sm" className="inline-block mr-3" />
        <div className="flex-1 font-semibold">
          {user.firstName} {user.lastName}
        </div>
      </div>
    );
  }, [user]);

  return (
    <Modal
      header={header}
      paddingX="8"
      width="3xl"
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
    >
      <>
        <div className="sm:flex justify-between items-center mb-8">
          <div className="flex items-center mb-4 sm:mb-0">
            <h3>References</h3>
            <span className="badge ml-2 bg-grey-300">
              {visibleReferences.length}
            </span>
          </div>
          {isOwnProfile ? (
            <div>
              <RequestReferenceButton />
            </div>
          ) : null}
        </div>
        {visibleReferences.map((reference: Reference) => {
          const avatarUrl = reference.avatarURL ?? reference.oAuthAvatarURL;
          const fullName = reference?.fullname || reference.oAuthFullname;
          const title = reference?.userTitle ?? reference?.oAuthTitle;
          const isCommunoUser = !!reference.userId;

          return (
            <div className="sm:flex my-8" key={reference.referenceId}>
              <div className="sm:w-2/5">
                <ReferenceAvatar
                  title={title ?? ''}
                  name={fullName || ''}
                  avatarUrl={avatarUrl}
                  isCommunoUser={isCommunoUser}
                  clickable
                  onCLick={e => {
                    e.preventDefault();

                    if (isCommunoUser) {
                      onClose();
                      navigate(
                        `/members/user/${reference.userId}`,
                        'forward',
                        'push'
                      );
                    } else if (!isCommunoUser && reference.externalProfileUrl) {
                      Browser.open({
                        url: forceHttps(reference.externalProfileUrl),
                      });
                    }
                  }}
                />
                {isOwnProfile ? (
                  <ReferenceVisibilityToggle
                    id={reference.referenceId}
                    visibility={reference.visibility}
                    onToggle={() => {
                      refetch();
                    }}
                  />
                ) : null}
              </div>
              <div className="sm:w-3/5 pt-4 sm:pt-0 sm:pl-8">
                <div className="content">
                  <ReferenceEndorsement
                    isOwnProfile={isOwnProfile}
                    message={reference.message}
                  />
                </div>
                <ReferenceRatings
                  ratings={reference?.ratings ?? []}
                  className="mt-8"
                />
              </div>
            </div>
          );
        })}
      </>
    </Modal>
  );
};
export default ViewReferencesModal;
