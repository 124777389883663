import React from 'react';
import Button from '../core/Button';
import Modal from '../core/modal/Modal';

type Props = {
  applicantFirstName: string;
  hasStartedConversation: boolean;
  showSendModal: boolean;
  closeSendModal: () => void;
  closeModalAndSend: () => void;
  meetingInfo: Meeting;
};

const SendMeetingLinkModal = ({
  applicantFirstName,
  hasStartedConversation,
  showSendModal,
  closeSendModal,
  closeModalAndSend,
  meetingInfo,
}: Props) => {
  const sendModalTitle = hasStartedConversation
    ? `Send ${meetingInfo.config.event.title} link to ${applicantFirstName}?`
    : `You haven't talked to ${applicantFirstName} yet.`;

  const sendModalButtonClass = hasStartedConversation
    ? 'i-pencil btn__icon'
    : 'i-chat btn__icon';

  const sendModalMessage = hasStartedConversation
    ? 'Compose a message with the meeting link'
    : "Let's start the conversation!";

  return (
    <Modal
      isOpen={showSendModal}
      onClose={closeSendModal}
      title={sendModalTitle}
      width="xl"
    >
      <div className="text-center">
        <Button
          id="schedule-meeting-event"
          type="button"
          onClick={closeModalAndSend}
          color="primary"
          className="flex-1"
        >
          <i className={sendModalButtonClass} />
          <span className="btn__text">{sendModalMessage}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default SendMeetingLinkModal;
