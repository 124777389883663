import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormGroup, FormRadio, FormCheckbox } from '../../core/form';
import UserContext from '../Context';
import { addSuccess, addResponseError } from '../../../services/Messaging';
import MembershipContext from '../../membership/Context';
import SearchContext from '../../search/Context';
import RoleUtil from '../../roles/Util';
import InfoModal from '../../core/modal/InfoModal';

interface UserOrMembershipRole {
  role: RoleEntity;
}

interface RolesByCategory {
  [category: string]: string[];
}
interface GroupedRoles {
  count: number;
  categories: RolesByCategory;
}

const RoleList = ({
  category,
  roles,
}: {
  category: string;
  roles: string[];
}) => {
  return (
    <div className="mt-6">
      <div className="text-grey-500 font-semibold">{category}</div>
      {roles.map((name: string) => (
        <div key={name}>{name}</div>
      ))}
    </div>
  );
};

function groupRolesByCategory(
  userOrMemberRoles: Array<UserOrMembershipRole>
): any {
  return userOrMemberRoles.reduce<GroupedRoles>(
    (acc: GroupedRoles, roleEntity: UserOrMembershipRole) => {
      const { role } = roleEntity;
      const { category } = role;

      if (category.name in acc.categories) {
        // Avoid duplicates - probably a more elegant way to do this
        if (!acc.categories[category.name].includes(role.name)) {
          acc.categories[category.name] = [
            ...acc.categories[category.name],
            role.name,
          ];

          acc.count += 1;
        }
      } else {
        acc.categories[category.name] = [role.name];
        acc.count += 1;
      }

      return acc;
    },
    { count: 0, categories: {} }
  );
}

const NotificationSettingsForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [userAndMemberRoles, setUserAndMemberRoles] = useState<
    UserOrMembershipRole[]
  >([]);
  const [notifyByRole, setNotifyByRole] = useState<GroupedRoles>();
  const [notifyByCategory, setNotifyByCategory] = useState<GroupedRoles>();
  const { update, user } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const { checkAccess } = useContext(MembershipContext);

  const form = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, errors, register, reset, watch, getValues } = form;
  // Need to register the field because its not in the DOM
  register({ name: 'NOTIFICATIONS_CHAT_ENABLED' });

  const canPostWork = checkAccess('canPostWork');
  const { getRoles } = useContext(SearchContext);
  const workNotifyFields = watch([
    'NOTIFICATIONS_WORK_POSTS_PUSH_ENABLED',
    'NOTIFICATIONS_WORK_POSTS_EMAIL_ENABLED',
  ]);
  const chatNotifyFields = watch([
    'NOTIFICATIONS_CHAT_PUSH_ENABLED',
    'NOTIFICATIONS_CHAT_EMAIL_ENABLED',
  ]);
  const applicantsNotifyFields = watch([
    'NOTIFICATIONS_WORK_APPLICANTS_PUSH_ENABLED',
    'NOTIFICATIONS_WORK_APPLICANTS_EMAIL_ENABLED',
  ]);
  const collectionNotifyFields = watch([
    'NOTIFICATIONS_COLLECTION_INVITES_PUSH_ENABLED',
    'NOTIFICATIONS_COLLECTION_INVITES_EMAIL_ENABLED',
  ]);

  const showWorkNotifyOptions =
    workNotifyFields.NOTIFICATIONS_WORK_POSTS_PUSH_ENABLED ||
    workNotifyFields.NOTIFICATIONS_WORK_POSTS_EMAIL_ENABLED;

  const showChatNotifyOptions =
    chatNotifyFields.NOTIFICATIONS_CHAT_PUSH_ENABLED ||
    chatNotifyFields.NOTIFICATIONS_CHAT_EMAIL_ENABLED;

  const showApplicantsNotifyFields =
    applicantsNotifyFields.NOTIFICATIONS_WORK_APPLICANTS_PUSH_ENABLED ||
    applicantsNotifyFields.NOTIFICATIONS_WORK_APPLICANTS_EMAIL_ENABLED;

  const showCollectionNotifyFields =
    collectionNotifyFields.NOTIFICATIONS_COLLECTION_INVITES_PUSH_ENABLED ||
    collectionNotifyFields.NOTIFICATIONS_COLLECTION_INVITES_EMAIL_ENABLED;

  const getAllAvailableRoles = useCallback(async () => {
    const { hits } = await getRoles({});
    const results = RoleUtil.groupRoles(hits);
    return results.reduce(
      (
        acc: { [category: string]: string[] },
        item: { label: string; options: any }
      ) => {
        const roles = item.options.map((role: { label: string }) => {
          return role.label;
        });

        acc[item.label] = roles;

        return acc;
      },
      {}
    );
  }, [getRoles]);

  const userAndMembershipCategories = useCallback(() => {
    let combinedRoles: string[] = [];

    if (userAndMemberRoles.length) {
      const noDuplicates = userAndMemberRoles.reduce(
        (acc: string[], userOrMemberRole: UserOrMembershipRole) => {
          const {
            role: { category },
          } = userOrMemberRole;

          if (!acc.includes(category.name)) {
            acc.push(category.name);
          }

          return acc;
        },
        []
      );

      combinedRoles = [...noDuplicates];
    }

    return combinedRoles;
  }, [userAndMemberRoles]);

  useEffect(() => {
    const rolesByCategory = groupRolesByCategory(userAndMemberRoles);
    setNotifyByRole(rolesByCategory);
  }, [userAndMemberRoles]);

  useEffect(() => {
    getAllAvailableRoles().then(results => {
      const allRolesByCategory: RolesByCategory = {};
      let totalCount = 0;

      if (userAndMemberRoles.length) {
        userAndMembershipCategories().forEach((category: string) => {
          if (category in results) {
            allRolesByCategory[category] = results[category];
            totalCount += results[category].length;
          }
        });

        setNotifyByCategory({
          count: totalCount,
          categories: { ...allRolesByCategory },
        });
      }
    });
  }, [getAllAvailableRoles, userAndMemberRoles, userAndMembershipCategories]);

  useEffect(() => {
    if (membership?.roles.length) {
      setUserAndMemberRoles((prev: UserOrMembershipRole[]) => [
        ...prev,
        ...membership.roles,
      ]);
    }
  }, [membership]);

  useEffect(() => {
    if (user !== null) {
      setUserAndMemberRoles((prev: UserOrMembershipRole[]) => [
        ...prev,
        ...user.roles,
      ]);
    }

    if (user?.userSettings !== null) {
      reset(user?.userSettings);
    }
  }, [user, reset]);

  // This was used when using submit button, keeping here in case we revert
  const onSubmit = (userSettings: any) => {
    if (submitting) return;
    setSubmitting(true);

    update({ userSettings })
      .then(() => {
        addSuccess('Updated notification settings');
      })
      .catch(addResponseError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const autoSave = () => {
    const formValues = getValues();
    onSubmit(formValues);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <h4 className="font-normal mb-4">Chats</h4>

        <FormGroup name="chat" label="Notification Type" errors={errors}>
          <FormCheckbox
            name="NOTIFICATIONS_CHAT_PUSH_ENABLED"
            label="Push Notifications"
            id="chatPushNotifications"
            ref={register}
            onChange={autoSave}
          />

          <FormCheckbox
            name="NOTIFICATIONS_CHAT_EMAIL_ENABLED"
            label="Emails"
            id="chatEmailNotifications"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>

        <div
          className={`${
            showChatNotifyOptions ? 'hidden' : 'block'
          } notice mb-6`}
        >
          Select a Type in order to be notified of new chat messages.
        </div>

        <FormGroup
          name="chat"
          label="Notify me of:"
          errors={errors}
          className={showChatNotifyOptions ? 'block' : 'hidden'}
        >
          <FormCheckbox
            name="NOTIFICATIONS_CHANNEL_ENABLED"
            label="Channel Messages"
            id="chatNotificationsChannel"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>

        <h4 className="font-normal mb-4 pt-4 border-t border-grey-100">
          Work Opportunities
        </h4>

        <FormGroup name="work" label="Notification Type" errors={errors}>
          <FormCheckbox
            name="NOTIFICATIONS_WORK_POSTS_PUSH_ENABLED"
            label="Push Notifications"
            id="workPushNotifications"
            ref={register}
            onChange={autoSave}
          />
          <FormCheckbox
            name="NOTIFICATIONS_WORK_POSTS_EMAIL_ENABLED"
            label="Emails"
            id="workEmailNotifications"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>

        <div
          className={`${
            showWorkNotifyOptions ? 'hidden' : 'block'
          } notice mb-6`}
        >
          Select a Type in order to be notified of new work opportunities.
        </div>

        <FormGroup
          name="work"
          label="Notify me of:"
          errors={errors}
          className={showWorkNotifyOptions ? 'block' : 'hidden'}
        >
          <FormRadio
            name="NOTIFICATIONS_WORK_POSTS_TYPE"
            label="All eligible opportunities"
            value="ALL"
            ref={register}
            onChange={autoSave}
          />
          <div className="flex items-start">
            <FormRadio
              name="NOTIFICATIONS_WORK_POSTS_TYPE"
              label="My Role Category(s)"
              value="ROLE_CATEGORIES"
              ref={register}
              onChange={autoSave}
            />
            {notifyByCategory?.count ? (
              <button
                type="button"
                className="text-red-1100 font-semibold ml-3"
                onClick={() => {
                  setShowCategoryModal(true);
                }}
              >
                ({notifyByCategory.count} Roles)
              </button>
            ) : null}
          </div>
          <div className="flex items-start">
            <FormRadio
              name="NOTIFICATIONS_WORK_POSTS_TYPE"
              label="My Role(s)"
              value="ROLES"
              ref={register}
              onChange={autoSave}
            />
            {notifyByRole?.count ? (
              <button
                type="button"
                className="text-red-1100 font-semibold ml-3"
                onClick={() => {
                  setShowRoleModal(true);
                }}
              >
                ({notifyByRole?.count} Roles)
              </button>
            ) : null}
          </div>
        </FormGroup>

        {canPostWork ? (
          <>
            <h4 className="font-normal mb-4 pt-4 border-t border-grey-100">
              Applicants
            </h4>
            <FormGroup name="work" label="Notification Type" errors={errors}>
              <FormCheckbox
                name="NOTIFICATIONS_WORK_APPLICANTS_PUSH_ENABLED"
                label="Push Notifications"
                id="applicantPushNotifications"
                ref={register}
                onChange={autoSave}
              />

              <FormCheckbox
                name="NOTIFICATIONS_WORK_APPLICANTS_EMAIL_ENABLED"
                label="Emails"
                id="applicantEmailNotifications"
                ref={register}
                onChange={autoSave}
              />
            </FormGroup>

            <div
              className={`${
                showApplicantsNotifyFields ? 'hidden' : 'block'
              } notice mb-6`}
            >
              Select a Type in order to be notified of new applicants.
            </div>

            <FormGroup
              name="work"
              label="Notify me:"
              errors={errors}
              className={showApplicantsNotifyFields ? 'block' : 'hidden'}
            >
              <FormRadio
                name="NOTIFICATIONS_WORK_APPLICANTS_TYPE"
                label="Immediately (for every applicant)"
                value="IMMEDIATELY"
                ref={register}
                onChange={autoSave}
              />
              <FormRadio
                name="NOTIFICATIONS_WORK_APPLICANTS_TYPE"
                label="Once an hour (summary)"
                value="HOURLY"
                ref={register}
                onChange={autoSave}
              />
              <FormRadio
                name="NOTIFICATIONS_WORK_APPLICANTS_TYPE"
                label="Daily (summary)"
                value="DAILY"
                ref={register}
                onChange={autoSave}
              />
            </FormGroup>
          </>
        ) : null}

        <h4 className="font-normal mb-4 pt-4 border-t border-grey-100">
          Lists/Bench
        </h4>
        <FormGroup name="collection" label="Notification Type" errors={errors}>
          <FormCheckbox
            name="NOTIFICATIONS_COLLECTION_INVITES_PUSH_ENABLED"
            label="Push Notifications"
            id="collectionPushNotifications"
            ref={register}
            onChange={autoSave}
          />

          <FormCheckbox
            name="NOTIFICATIONS_COLLECTION_INVITES_EMAIL_ENABLED"
            label="Emails"
            id="collectionEmailNotifications"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>

        <div
          className={`${
            showCollectionNotifyFields ? 'hidden' : 'block'
          } notice mb-6`}
        >
          Select a Type in order to be notified of new bench/list invitations.
        </div>

        <FormGroup
          name="collection"
          label="Notify me:"
          errors={errors}
          className={showCollectionNotifyFields ? 'block' : 'hidden'}
        >
          <FormCheckbox
            name="NOTIFICATIONS_BENCH_INVITES_ENABLED"
            label="Bench invitations"
            id="benchNotifications"
            ref={register}
            onChange={autoSave}
          />
          <FormCheckbox
            name="NOTIFICATIONS_LIST_INVITES_ENABLED"
            label="List invitations"
            id="listNotifications"
            ref={register}
            onChange={autoSave}
          />
        </FormGroup>
      </form>

      {showRoleModal ? (
        <InfoModal
          title="By Role"
          onClose={() => {
            setShowRoleModal(false);
          }}
        >
          <>
            <p>
              With this selection you will receive notifications of work in the
              following disciplines:
            </p>
            <div className="sm:grid sm:grid-cols-2 sm:gap-4">
              {notifyByRole &&
                Object.entries(notifyByRole.categories).map(
                  ([category, roles]) => {
                    return (
                      <RoleList
                        key={category}
                        category={category}
                        roles={roles}
                      />
                    );
                  }
                )}
            </div>
          </>
        </InfoModal>
      ) : null}

      {showCategoryModal ? (
        <InfoModal
          title="By Role Category"
          onClose={() => {
            setShowCategoryModal(false);
          }}
        >
          <>
            <p>
              With this selection you will receive notifications of work in the
              following disciplines:
            </p>

            <div className="sm:grid sm:grid-cols-2 sm:gap-4">
              {notifyByCategory &&
                Object.entries(notifyByCategory.categories).map(
                  ([category, roles]) => {
                    return (
                      <RoleList
                        key={category}
                        category={category}
                        roles={roles}
                      />
                    );
                  }
                )}
            </div>
          </>
        </InfoModal>
      ) : null}
    </>
  );
};

export default NotificationSettingsForm;
