import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useCookie } from 'react-use';
import { useLocation } from 'react-router';
// import ProfileProgress from '../profileCompleteness/ProfileProgress';
import useSearch from '../search/useSearch';
import SearchContext from '../search/Context';
import WorkCard from '../card/WorkCard';
import EventCard from '../card/EventCard';
import FeedStats from './FeedStats';
import MembershipContext from '../membership/Context';
import { feedApi } from '../../Api';
import { addResponseError } from '../../services/Messaging';
import {
  usePullToRefresh,
  usePullToRefreshContext,
} from '../../hooks/usePullToRefresh';
import UserContext from '../user/Context';
import useCurrentPlan from '../../hooks/useCurrentPlan';
import VideoPlayer from '../video/VideoPlayer';
import ProfileCompletion from './ProfileCompletion';
import { PlanTypes } from '../../utilities/constants';
import FeedSection from './FeedSection';
import MyApplications from './MyApplications';
import MyWork from './MyWork';
import LatestChannels from './LatestChannels';
import MemberSearchProvider from '../membership/search/MemberSearchProvider';
import { SOURCE_COOKIE } from '../cookie/SourceCookie';
import useNavigate from '../../hooks/useNavigate';
import useBrandConfig from '../../hooks/useBrandConfig';

const FeedView = () => {
  const { name, availablePages, availablePlans, styles } = useBrandConfig();
  const { user } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const plan = useCurrentPlan();
  const [isLoaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [sourceCookie, , deleteCookie] = useCookie(SOURCE_COOKIE);
  const source = sourceCookie?.split(':') || [];
  const { hash } = useLocation();

  if (source[0] === 'Work-Apply' && !hash.includes('edit-profile')) {
    deleteCookie();
    navigate(`/work/${source[1]}`);
  }

  const {
    pullToRefreshToken,
    resetPullToRefreshToken,
  } = usePullToRefreshContext();
  usePullToRefresh(resetPullToRefreshToken, isLoaded);

  const [feedData, setFeedData] = useState<FeedData>();

  const stats = feedData?.stats || null;

  // Get latest 2 Work/Event items
  const { getWork, getEvents } = useContext(SearchContext);
  const [workResults, workHandleSearch] = useSearch(getWork);
  const [eventsResults, eventsHandleSearch] = useSearch(getEvents);
  const ts = useMemo(() => Math.round(new Date().getTime() / 1000), []);

  const collectionFilters = [
    `(NOT audience:collection)`,
    `membership.id:${membership?.id}`,
  ];

  const collections = user?.collections ?? [];
  const collectionIdsQuery = collections
    .map(collection => `collectionIds:${collection.collectionId}`)
    .join(' OR ');
  if (collections.length > 0) {
    collectionFilters.push(`(${collectionIdsQuery})`);
  }

  const isBrand = plan?.type === PlanTypes.BRAND;

  const studentTypeFilter = `${
    plan?.type === PlanTypes.STUDENT ? '' : 'NOT '
  }type:${PlanTypes.STUDENT}`;

  const workFilters = [
    `(expiresAtTimestamp > ${ts})`,
    collectionFilters.join(' OR '),
    studentTypeFilter,
  ];

  const loadFeed = useCallback(() => {
    return feedApi
      .stats()
      .then((response: FeedData) => {
        setFeedData(response);
      })
      .catch(error => {
        addResponseError(error);
      });
  }, []);

  const loadWork = useCallback(() => {
    return workHandleSearch({
      search: {
        query: '',
        filters: workFilters.join(' AND '),
        hitsPerPage: 2,
        page: 0,
      },
    });
  }, [workHandleSearch, workFilters]);

  const loadEvents = useCallback(() => {
    return eventsHandleSearch({
      search: {
        query: '',
        filters: `expiresAtTimestamp > ${ts}`,
        hitsPerPage: 2,
        page: 0,
      },
    });
  }, [eventsHandleSearch, ts]);

  useEffect(() => {
    setLoaded(false);
    Promise.all([loadEvents(), loadWork(), loadFeed()]).then(() => {
      setLoaded(true);
    });
    // eslint-disable-next-line
  }, [pullToRefreshToken]);

  return (
    <div className="page-container pb-6">
      <div className="py-8">
        <div className="container">
          <div className="row lg:flex">
            <div className="col lg:w-2/3 mb-12 flex flex-col gap-12">
              {/* {membership && <ProfileProgress membership={membership} />} */}

              {/* <div className="mt-8 mb-4">
                <h4 className="mb-4">Channels</h4>
                <FeedChannels />
              </div> */}

              {isBrand && (
                <>
                  <MyWork />

                  <MemberSearchProvider
                    showFilter={false}
                    showBrandResults={false}
                    showAgencyResults={availablePlans.agencies}
                    showOverflow={false}
                    labels={{
                      people: 'Browse Talent',
                      agencies: 'Browse Agencies',
                    }}
                    breakpoints={{
                      540: {
                        slidesPerView: 2,
                      },
                      1280: {
                        slidesPerView: 3,
                      },
                      1440: {
                        slidesPerView: 4,
                      },
                    }}
                  />
                </>
              )}

              {(styles.feed.promoVideo.showForPlanTypes as string[]).includes(
                plan?.type ?? ''
              ) && (
                <div className="flex flex-col gap-4">
                  <h4>{styles.feed.promoVideo.title}</h4>
                  <VideoPlayer videoUrl={styles.feed.promoVideo.url} />
                </div>
              )}

              {!isBrand && (
                <FeedSection
                  title="My Applications"
                  route="#/my-stuff/applications"
                >
                  <MyApplications />
                </FeedSection>
              )}

              {!isBrand && membership && workResults && (
                <FeedSection title="Latest Work" route="/work">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    {workResults?.search?.hits.map((work: WorkEntity) => (
                      <WorkCard
                        key={work.objectID}
                        work={work}
                        cardStyle="full"
                      />
                    ))}
                  </div>
                </FeedSection>
              )}

              {availablePages.channels && !isBrand && <LatestChannels />}

              {eventsResults && availablePages.events && (
                <FeedSection title="Upcoming Events" route="/events">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    {eventsResults?.search?.hits.map((event: EventEntity) => (
                      <EventCard
                        key={event.objectID}
                        event={event}
                        cardStyle="limited"
                      />
                    ))}
                  </div>
                </FeedSection>
              )}
            </div>

            <div className="col lg:w-1/3 flex flex-col gap-8">
              <ProfileCompletion />

              {stats && (
                <div className="flex flex-col gap-4">
                  <h4>{name} Dashboard</h4>
                  <FeedStats stats={stats} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedView;
