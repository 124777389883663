import React from 'react';
import ProfileSocialLinks from '../../profile/ProfileSocialLinks';
import Markdown from '../../core/Markdown';
import redactedIntroImage from '../../../assets/work-redacted-intro.png';
import Logo from './Logo';
import CardLogo from '../../card/CardLogo';
import { SHOW_COMPANY_ON_JOB_POST } from '../../../config';

function getDisplayTitle(work: WorkEntity) {
  if (SHOW_COMPANY_ON_JOB_POST && work.company?.name) return work.company.name;
  if (work.companyName) return work.companyName;
  if (work.membership?.plan?.type === 'individual' && work.user?.firstName)
    return `${work.user?.firstName} ${work.user?.lastName}`;
  return work.membership?.name || '';
}

type Props = {
  work: WorkEntity;
  isAuthenticated: boolean;
};

const RedactedWorkIntro = ({ logo }: { logo?: any }) => (
  <div>
    {logo ?? null}
    <img
      src={redactedIntroImage}
      className="max-w-full mt-4"
      alt="Redacted work details"
    />
  </div>
);

const ExternalWorkIntro = ({ work, isAuthenticated }: Props) => {
  const redactedLogo = (
    <CardLogo
      imageUrl={work.logoURL}
      altText="Redacted company logo"
      logoSize="xl"
      borderSize="none"
    />
  );

  return (
    <div className="pt-6 md:pt-0">
      {isAuthenticated || work.redactionLevel === 0 ? (
        <>
          <CardLogo
            imageUrl={work.logoURL}
            altText={work.companyName ?? ''}
            logoSize="xl"
            borderSize="none"
          />
          <p className="mt-4 text-lg">{work.companyName}</p>
        </>
      ) : (
        <RedactedWorkIntro logo={redactedLogo} />
      )}
    </div>
  );
};

const InternalWorkIntro = ({ work, isAuthenticated }: Props) => {
  if (isAuthenticated || work.redactionLevel === 0) {
    const { membership, user } = work;

    const displayTitle = getDisplayTitle(work);
    const intro = !membership?.plan.isIndividualPlan
      ? membership?.summary
      : user?.summary;

    const socialLinks = !membership?.plan.isIndividualPlan
      ? membership?.socialLinks
      : user?.socialLinks;

    return (
      <>
        <div className="relative pb-16 md:pb-0">
          <div className="absolute top-0 transform -translate-y-1/2 md:flex md:static md:transform-none">
            <Logo work={work} altText={displayTitle} />
          </div>
        </div>
        <p className="mt-2 text-lg">{displayTitle}</p>
        {intro && (
          <>
            <div className="my-4">
              <Markdown>{intro}</Markdown>
            </div>
          </>
        )}
        <ProfileSocialLinks socialLinks={socialLinks} profileUserId={user.id} />
      </>
    );
  }

  return (
    <RedactedWorkIntro
      logo={<Logo work={work} altText="Redacted company logo" />}
    />
  );
};

const WorkIntro = ({ work, isAuthenticated }: Props) => {
  if (work.isExternal) {
    return <ExternalWorkIntro work={work} isAuthenticated={isAuthenticated} />;
  }

  return <InternalWorkIntro work={work} isAuthenticated={isAuthenticated} />;
};

export default WorkIntro;
