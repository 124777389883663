import React from 'react';
import { FormImage } from '../../core/form';

export default ({ errors, register, defaultValue = '', form }: any) => {
  const handleChangeImageUrl = (url: string) => {
    return url;
  };

  return (
    <FormImage
      name="imageURL"
      label="cover image"
      onChange={handleChangeImageUrl}
      errors={errors}
      form={form}
      ref={register}
      defaultValue={defaultValue}
      aspectRatio={[2, 1]}
    />
  );
};
