import React, { useCallback } from 'react';
import useModal from '../hooks/useModal';

const ModalCloseButton = React.memo(() => {
  const { closeModal } = useModal();

  const handleClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <button type="button" className="h-full px-4" onClick={handleClick}>
      <i className="i-close" />
    </button>
  );
});
export default ModalCloseButton;
