import React from 'react';
import AvatarPlaceholder from './AvatarPlaceholder';

const ChannelPlaceholder = () => (
  <div className="channel-placeholder">
    <div className="flex items-center">
      <AvatarPlaceholder size="lg" />

      <div className="ml-2 w-full">
        <div className="h-3 mb-2 placeholder--animate" />
        <div className="h-3 w-8/12 placeholder--animate" />
      </div>
    </div>
  </div>
);

export default ChannelPlaceholder;
