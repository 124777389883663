import React, { useContext } from 'react';
import AppContext from '../../AppContext';

import StaffCard from './StaffCard';

type Props = {
  membershipUsers: MembershipUserEntity[];
};

const MembershipStaffSummary = ({ membershipUsers }: Props) => {
  const { ionContentRef } = useContext(AppContext);

  const visibleEmployees = membershipUsers.filter(mu => mu.user.isSearchable);

  const employeesToShow = visibleEmployees.length === 4 ? 4 : 3;
  const employees = visibleEmployees.slice(
    0,
    Math.min(employeesToShow, visibleEmployees.length)
  );

  const skippedCount = visibleEmployees.length - employees.length;

  const handleClickMore = () => {
    const staffEl = document.getElementById('staff-section');
    if (staffEl && ionContentRef?.current) {
      const parent: any = staffEl.offsetParent;
      const parentOffset = parent?.offsetTop ?? 0;
      const offset = parentOffset + staffEl.offsetTop;
      ionContentRef.current.scrollToPoint(0, offset, 250);
    }
  };

  if (employees.length === 0) {
    return null;
  }

  return (
    <>
      <p className="profile__section-title">Staff</p>
      {employees.map(employee => (
        <StaffCard key={employee.user.id} user={employee.user} />
      ))}

      {skippedCount > 0 ? (
        <button type="button" onClick={handleClickMore}>
          <div className="avatar avatar--lg avatar--circle bg-grey-500">
            <span className="avatar__initials">+{skippedCount}</span>
          </div>
        </button>
      ) : null}
    </>
  );
};
export default MembershipStaffSummary;
