import React, { useState } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';

import useCurrentPlan from '../../hooks/useCurrentPlan';
import useNavigate from '../../hooks/useNavigate';
import { addResponseError } from '../../services/Messaging';
import Alert from '../alert/Alert';
import Button from '../core/Button';
import BadgePro from '../icons/BadgePro';
import BadgeVerified from '../icons/BadgeVerified';
import { CompletionStage } from '../user/views/ProfileCompletion';

interface Props {
  completionStage: CompletionStage;
  // isVerified: boolean;
  // isVetted: boolean;
  vettingStatus?: string;
  isIndividualPlan: boolean;
}

const StageOne = () => (
  <div className="bg-red-900 p-5 flex">
    <div>
      <svg
        width="15"
        height="19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.406 2.031a4.375 4.375 0 017.469 3.094V7.75a2.625 2.625 0 012.625 2.625v5.25a2.625 2.625 0 01-2.625 2.625h-8.75A2.625 2.625 0 01.5 15.625v-5.25A2.625 2.625 0 013.125 7.75V5.125c0-1.16.46-2.273 1.281-3.094zm.469 5.719h5.25V5.125a2.625 2.625 0 00-5.25 0V7.75zM3.125 9.5a.875.875 0 00-.875.875v5.25a.875.875 0 00.875.875h8.75a.875.875 0 00.875-.875v-5.25a.875.875 0 00-.875-.875h-8.75zM7.5 11.25c.483 0 .875.392.875.875v1.75a.875.875 0 01-1.75 0v-1.75c0-.483.392-.875.875-.875z"
          fill="#1F2326"
        />
      </svg>
    </div>
    <div className="pl-3 text-white">
      <div className="text-large font-semibold">
        Your profile needs attention.
      </div>
    </div>
  </div>
);

const StageTwo = () => (
  <div className="bg-gold-1100 p-5 flex">
    <div>
      <svg
        width="15"
        height="19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.406 2.031a4.375 4.375 0 017.469 3.094V7.75a2.625 2.625 0 012.625 2.625v5.25a2.625 2.625 0 01-2.625 2.625h-8.75A2.625 2.625 0 01.5 15.625v-5.25A2.625 2.625 0 013.125 7.75V5.125c0-1.16.46-2.273 1.281-3.094zm.469 5.719h5.25V5.125a2.625 2.625 0 00-5.25 0V7.75zM3.125 9.5a.875.875 0 00-.875.875v5.25a.875.875 0 00.875.875h8.75a.875.875 0 00.875-.875v-5.25a.875.875 0 00-.875-.875h-8.75zM7.5 11.25c.483 0 .875.392.875.875v1.75a.875.875 0 01-1.75 0v-1.75c0-.483.392-.875.875-.875z"
          fill="#1F2326"
        />
      </svg>
    </div>
    <div className="pl-3 text-white">
      <div className="text-large font-semibold">Earn your verified badge!</div>
      <div className="text-sm">
        Complete your profile and add at least three references and portfolio
        items to become verified.
      </div>
    </div>
  </div>
);

const StageThree = () => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const plan = useCurrentPlan();
  const { update } = useCurrentMembership();
  const planSlug = plan?.slug ?? '';

  const handleClick = async () => {
    if (['freelance-pro', 'freelance-pro-plus'].includes(planSlug)) {
      try {
        // Change vetting status
        await update({ vettingStatus: 'pending' });
        // refresh();
        window.location.reload();
      } catch (error) {
        addResponseError(error);
      }
    } else {
      setShowAlert(true);
    }
  };

  const handleUpgrade = () => {
    setShowAlert(false);
    navigate('#/upgrade', 'forward', 'push');
  };

  return (
    <>
      <div className="py-3">
        <div className="bg-green-900 p-5 flex">
          <div>
            <svg
              width="20"
              height="19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.625 7.75V5.125a4.375 4.375 0 00-8.75 0v1.75a.875.875 0 101.75 0v-1.75a2.625 2.625 0 015.25 0V7.75a2.625 2.625 0 00-2.625 2.625v5.25a2.625 2.625 0 002.625 2.625h8.75a2.625 2.625 0 002.625-2.625v-5.25a2.625 2.625 0 00-2.625-2.625h-7zM7.256 9.756a.875.875 0 01.619-.256h8.75a.875.875 0 01.875.875v5.25a.875.875 0 01-.875.875h-8.75A.875.875 0 017 15.625v-5.25c0-.232.092-.455.256-.619zm5.869 2.369a.875.875 0 00-1.75 0v1.75a.875.875 0 001.75 0v-1.75z"
                fill="#1F2326"
              />
            </svg>
          </div>
          <div className="pl-3 text-white">
            <div className="text-large font-semibold">
              Verified Badge unlocked!
            </div>
            <div className="text-sm">
              Click the button to start the verification process and get your
              badge.
            </div>
          </div>
        </div>
        <div className="mt-4 px-5 ">
          <Button
            color="tertiary"
            className="w-full"
            text="Earn your verified badge"
            onClick={handleClick}
          />
        </div>
      </div>
      {showAlert ? (
        <Alert
          isOpen={showAlert}
          header="Get Verified"
          message="To receive the Verified Badge, you must have completed your profile AND upgrade your plan."
          onDidDismiss={() => {}}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'alert-secondary-btn mr-auto',
              handler: () => setShowAlert(false),
            },
            {
              text: 'Upgrade to Pro',
              cssClass: 'alert-primary-btn',
              handler: handleUpgrade,
            },
          ]}
        />
      ) : null}
    </>
  );
};

const Verified = () => (
  <div className="mt-4 pt-4 px-5 border-t border-grey-200">
    <div>You are now verified, and the badge will show on your profile.</div>
    <div className="bg-black text-white p-3 mt-4 w-full flex items-center justify-center font-semibold">
      <BadgeVerified className="mr-1 text-white" />
      VERIFIED
    </div>
  </div>
);

const Vetted = () => (
  <div className="mt-4 pt-4 px-5 border-t border-grey-200">
    <div className="bg-black text-white p-3 mt-4 w-full flex items-center justify-center font-semibold">
      <BadgePro className="mr-1 text-white" />
      {/* <svg
        width="20"
        height="25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-1"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.36 24.34h.2a1.12 1.12 0 001 0h.09l.29-.16c.11-.076.257-.164.43-.268.168-.101.362-.218.57-.352a23.008 23.008 0 003.14-2.32c2.25-1.96 4.74-4.97 4.74-8.73V4.88a1.09 1.09 0 00-.71-1L10.38.61a1.1 1.1 0 00-.76 0L.89 3.88a1.11 1.11 0 00-.71 1v7.63c0 3.73 2.49 6.73 4.74 8.73a24.245 24.245 0 003.14 2.32c.375.26.713.454.97.602l.03.018.3.16zm1.5-2.69l-.79.47-.77-.47a21.38 21.38 0 01-2.86-2.11c-2.12-1.85-4-4.3-4-7.09V5.57l7.64-2.86 7.64 2.86v6.88c0 2.79-1.89 5.24-4 7.09a22.733 22.733 0 01-2.86 2.11zm-1.19-.82l.26.16V3.82L3.29 6.3v6.19c0 2.6 1.99 4.88 3.65 6.34.858.736 1.77 1.404 2.73 2z"
          fill="#7C9ABF"
        />
      </svg> */}
      EXPERT-VETTED
    </div>
  </div>
);

const ProfileCompletionFooter = ({
  completionStage,
  // isVerified,
  // isVetted,
  vettingStatus,
  isIndividualPlan,
}: Props) => {
  // If profile is hidden, everyone should see a warning
  if (completionStage === 'stageOne') {
    return <StageOne />;
  }

  // Dont show anything if staff
  if (!isIndividualPlan) {
    return null;
  }

  // If verified, then just show Verified badge
  if (vettingStatus === 'pending' || vettingStatus === 'verified') {
    return <Verified />;
  }

  // If vetted, then just show Vetted badge
  if (vettingStatus === 'vetted') {
    return <Vetted />;
  }

  // Get your Pro Badge!
  if (completionStage === 'stageTwo') {
    return <StageTwo />;
  }

  // Vetted Badge unlocked!
  if (completionStage === 'stageThree') {
    return <StageThree />;
  }

  return null;
};
export default ProfileCompletionFooter;
