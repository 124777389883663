import React, { useCallback } from 'react';

import Button from '../core/Button';
import { StepContextProvider } from './context/StepContext';
import useStep from './hooks/useStep';

export type StepType = {
  title?: string;
  description?: string;
  component: () => JSX.Element;
  prevButtonLabel?: string; // Defaults to 'Back'
  nextButtonLabel?: string; // Defaults to 'Continue'
  hideButtons?: boolean;
  canAdvance?: boolean;
  canGoBack?: boolean;
  header?: string;
};

type Props = {
  isActive: boolean;
  step: StepType;
  isFirstStep: boolean;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  hideDivider?: boolean;
};

const Step = ({
  step,
  goToPrevStep,
  goToNextStep,
  isFirstStep = false,
  hideDivider = false,
}: Props) => {
  const {
    prevButtonLabel,
    nextButtonLabel,
    canAdvance,
    canGoBack,
    onNext,
    onPrev,
    isLoading,
    title,
    description,
  } = useStep();

  const Component = step.component;

  const shouldShowButtons = !step.hideButtons;

  const handlePrevClick = useCallback(() => {
    if (onPrev()) {
      goToPrevStep();
    }
  }, [goToPrevStep, onPrev]);

  const handleNextClick = useCallback(() => {
    if (onNext()) {
      goToNextStep();
    }
  }, [goToNextStep, onNext]);

  return (
    <div>
      {title ? <h3 className="text-center">{title}</h3> : null}

      {description ? (
        <p className="mt-2 text-lg text-center">{description}</p>
      ) : null}

      <div className="mt-6 3xl:mt-8">
        <Component />
      </div>

      {shouldShowButtons ? (
        <div
          className={`mt-16 ${hideDivider ? '' : 'border-t border-grey-200'}`}
        >
          <div className="flex flex-row -mx-2">
            <div className="px-2 w-1/2">
              <Button
                disabled={isFirstStep && !canGoBack}
                type="button"
                fill="outline"
                text={prevButtonLabel}
                onClick={handlePrevClick}
                fullWidth
              />
            </div>
            <div className="px-2 w-1/2">
              <Button
                type="button"
                color="primary"
                fill="solid"
                text={nextButtonLabel}
                disabled={!canAdvance}
                onClick={handleNextClick}
                loading={isLoading}
                fullWidth
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const WrappedStep = (props: Props) => {
  const { isActive, isFirstStep, step, goToPrevStep, goToNextStep } = props;

  return (
    <div className="swiper-slide swiper-no-swiping">
      {isActive ? (
        <StepContextProvider
          isFirstStep={isFirstStep}
          step={step}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
        >
          <Step {...props} />
        </StepContextProvider>
      ) : null}
    </div>
  );
};
export default WrappedStep;
