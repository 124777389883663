import React, { useState, useEffect, useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormGroup, Autocomplete } from '../form';
import useProjectTypes from '../../../hooks/useProjectTypes';

interface Props
  extends Pick<
    AutocompleteProps,
    | 'form'
    | 'min'
    | 'max'
    | 'defaultValues'
    | 'isMulti'
    | 'isRequired'
    | 'isDisabled'
  > {
  labelOverride?: string | null;
  name?: string;
}

const ProjectTypes = ({
  form,
  min,
  max,
  defaultValues,
  isMulti = true,
  isRequired = false,
  isDisabled = false,
  labelOverride = null,
  name = 'projectTypes',
}: Props) => {
  const { data: projectTypes } = useProjectTypes();
  const { errors }: UseFormMethods = form;
  const label =
    labelOverride ||
    `${isMulti ? 'Project Types' : 'Project Type'}${
      max && max > 0 ? ` (max ${max})` : ''
    }`;
  const [defaultOptions, setDefaultOptions] = useState<any>([]);

  const getResults = useCallback(() => {
    if (!projectTypes) {
      return [];
    }

    return projectTypes.map((projectType: ProjectTypeEntity) => {
      return {
        label: projectType.name,
        options: projectType.projectOptions.map(
          (projectOption: ProjectOptionEntity) => {
            return {
              label: projectOption.name,
              value: projectOption.id,
              projectType,
            };
          }
        ),
      };
    });
  }, [projectTypes]);

  useEffect(() => {
    setDefaultOptions(getResults());
  }, [projectTypes, getResults]);

  return (
    <FormGroup required={isRequired} name={name} label={label} errors={errors}>
      <Autocomplete
        name={name}
        form={form}
        min={min}
        max={max}
        defaultValues={defaultValues}
        defaultOptions={defaultOptions}
        callback={getResults}
        isMulti={isMulti}
        isRequired={isRequired}
        isCreatable={false}
        isDisabled={isDisabled}
        placeholder={
          isMulti ? 'Type to search Project Types...' : 'Select a Project Type'
        }
      />
    </FormGroup>
  );
};

export default ProjectTypes;
