import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from './Form';
import MembershipContext from '../membership/Context';
import Api from './Api';
import uuid from '../../utilities/uuid';
import useFeatureGate from '../../hooks/useFeatureGate';
import Modal from '../core/modal/Modal';
import useModal from '../core/modal/hooks/useModal';
import Button from '../core/Button';
import BackButton from '../core/BackButton';
import UserContext from '../user/Context';

type Props = {
  title?: string | null;
  collectionId?: string | null;
  isExternal: boolean;
};

const ModalFooter = () => {
  const { closeModal } = useModal();

  const handleClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <div className="w-full flex -px-2">
      <div className="w-1/2 px-2">
        <BackButton
          defaultHref="/work"
          className="btn btn--outline btn--full btn--sm"
        />
      </div>
      <div className="w-1/2 px-2">
        <Button
          className="w-full"
          color="primary"
          size="sm"
          onClick={handleClick}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

const ExistingConciergePost = () => {
  return (
    <div className="content text-center pb-8">
      <h3>Concierge Post</h3>
      <p className="text-large">
        You have purchased a concierge post, which includes us writing the job
        post for you. We’ll connect with you within one business day to review
        your needs and get the process started.
      </p>
      <p className="text-large">
        If you would like to proceed with posting on your own job,{' '}
        <strong>
          we will not be able to assist you with writing your job post
        </strong>
        , but we can still help you with shortlisting the applicants and
        recommending the best match.
      </p>
    </div>
  );
};

const ProtectedCreateForm = (props: Props) => {
  const { checkAccess } = useContext(MembershipContext);
  const { openUpgradeFlowFor } = useFeatureGate();

  const { user } = useContext(UserContext);
  const planType = user?.primaryMembershipUser?.membership.plan.type;

  const canPostWork =
    checkAccess('canPostWorkToCommuno') ||
    checkAccess('canPostWorkToBench') ||
    planType !== 'individual';

  useEffect(() => {
    if (planType === 'individual') return;
    if (!canPostWork) {
      openUpgradeFlowFor('canPostWorkToCommuno');
    }
  }, [canPostWork, openUpgradeFlowFor, planType]);

  return canPostWork ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CreateForm {...props} />
  ) : null;
};

const CreateForm = ({ title, isExternal, collectionId = null }: Props) => {
  const [formId, setFormId] = useState(uuid());
  const { checkAccess, refresh, membership } = useContext(MembershipContext);
  const [showConciergeWarning, openConciergeWarning] = useState<boolean>(false);
  const canPostWorkToCommuno = checkAccess('canPostWorkToCommuno');
  let audience = collectionId ? 'collection' : 'both';
  const hasConciergePosts = membership?.numConciergePosts;

  useEffect(() => {
    if (hasConciergePosts) {
      openConciergeWarning(true);
    }
  }, [hasConciergePosts]);

  if (audience === 'both' && !canPostWorkToCommuno) {
    audience = 'collection';
  }

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      audience,
      collectionIds: collectionId ? [collectionId] : [],
      industries: [],
      skills: [],
      redactionLevel: '0',
      isOneClickApply: isExternal ? '1' : '0',
    },
  });

  const saveForm = useCallback(
    (data: any) => {
      return Api.create(data).then(response => {
        setFormId(uuid());
        refresh();
        return response;
      });
    },
    [refresh]
  );

  return (
    <>
      <Form
        key={formId}
        saveForm={saveForm}
        isExternal={isExternal}
        title={title}
        form={form}
        defaultValues={{
          collections: collectionId ? [{ id: collectionId }] : ([] as any),
        }}
      />
      <Modal
        width="lg"
        isOpen={showConciergeWarning}
        onClose={() => {
          openConciergeWarning(false);
        }}
        footer={<ModalFooter />}
      >
        <ExistingConciergePost />
      </Modal>
    </>
  );
};

export default ProtectedCreateForm;
