import React, { useContext } from 'react';
import AddMembers from '../components/AddMembers';
import NewOneToManyChatContext from '../context/ChatNewOneToManyContext';
import SelectedMembers from '../components/SelectedMembers';

const NewOneToManyConversation = () => {
  const { members, updateMembers } = useContext(NewOneToManyChatContext);
  const membersCount = members.length;

  return (
    <AddMembers multiSelect members={members} onAddMember={updateMembers}>
      {membersCount > 0 ? (
        <div className="pb-2">
          <SelectedMembers members={members} onMemberClick={updateMembers} />
        </div>
      ) : null}
    </AddMembers>
  );
};

export default NewOneToManyConversation;
