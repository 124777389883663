import React from 'react';
import Page from '../components/core/Page';
import MagicLinkSent from '../components/user/views/MagicLinkSent';

export default () => (
  <Page
    layout="basic"
    className="page-bg--white"
    pageTitle="Magic Link Sent"
    mobileHeader={() => null}
    hideHeader
  >
    <MagicLinkSent />
  </Page>
);
