import React from 'react';

type Props = {
  layout: Layouts;
  className?: string;
  children: JSX.Element[] | JSX.Element;
};

const layouts = {
  none: '',
  shell: 'p-0',
  noPadding: 'page-container p-0',
  default: 'page-container pb-6',
  fullHeight: 'page-container h-full',
  basic: 'page-container p-8 md:max-w-xl min-h-full flex flex-col',
  landing: 'page-container p-8 md:max-w-md min-h-full flex flex-col',
  onboarding: 'onboarding-layout',
  channel: 'channel-container',
  modal: 'flex flex-col grow p-8',
  videochat: 'video-page',
  panels: 'panels-container',
  chat: 'page-container p-4 md:p-0 min-h-full bg-white',
  chatlist: 'page-container min-h-full bg-white',
};

const Layout = ({ layout = 'none', className = '', children }: Props) => (
  <div className={`${layouts[layout]} ${className}`}>{children}</div>
);

export default React.memo(Layout);
