import React, { useState } from 'react';

import AssignWorkButton from './AssignWorkButton';
import AssignWorkModal from './AssignWorkModal';

type Props = {
  membershipId: string | undefined;
  refreshWork: Function;
  work: WorkEntity;
};

const AssignWork = ({ membershipId, refreshWork, work }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleAssignModal = (bool: boolean) => setShowModal(bool);

  const { recruiter } = work;
  return (
    <>
      <AssignWorkButton
        recruiter={recruiter}
        toggleAssignModal={toggleAssignModal}
      />
      {showModal && (
        <AssignWorkModal
          membershipId={membershipId}
          refreshWork={refreshWork}
          work={work}
          showModal={showModal}
          toggleAssignModal={toggleAssignModal}
        />
      )}
    </>
  );
};

export default AssignWork;
