import React, { useLayoutEffect, useRef, useState } from 'react';
import { Circle } from 'progressbar.js';
import { randomHash } from '../../utilities/String';

export interface ColorChart {
  percent: number;
  color: string;
  trackColor?: string;
  trackWidth?: number;
}

const defaultChart = {
  percent: 0,
  color: '#FFFFFF',
  trackColor: '#e9edf2',
  trackWidth: 4.5,
};

const getColorChart = (
  percent: number,
  colorChart: ColorChart[] = [defaultChart]
): Required<Omit<ColorChart, 'percent'>> => {
  const match = [...colorChart].reverse().find(item => {
    return percent >= item.percent;
  });

  // defaults + found
  return { ...defaultChart, ...match };
};

type Props = {
  percent: number;
  colorChart?: ColorChart[];
  selectedChart?: Omit<ColorChart, 'percent'>;
};

const ProgressBar = ({ percent, colorChart, selectedChart }: Props) => {
  const [uniqeId] = useState(randomHash(5));
  const progressBar: any = useRef(null);

  const chart = selectedChart || getColorChart(percent, colorChart);
  const { color, trackColor, trackWidth } = chart;

  useLayoutEffect(() => {
    if (!progressBar.current) {
      const options = {
        strokeWidth: trackWidth,
        easing: 'easeInOut',
        duration: 1400,
        color,
        trailColor: trackColor,
        trailWidth: trackWidth,
      };
      progressBar.current = new Circle(`.progress-bar.${uniqeId}`, options);
    }
    progressBar.current.animate(percent / 100);
    progressBar.current.path.setAttribute('stroke', color);
  }, [color, percent, trackColor, trackWidth, uniqeId]);

  return <div className={`progress-bar ${uniqeId}`} />;
};

export default React.memo(ProgressBar);
