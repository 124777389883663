import React, { useContext } from 'react';
import UserContext from '../../user/Context';
import ConnectLinkedin from './ConnectLinkedin';
import SignIn from './SignIn';

const Authenticate = () => {
  const { isAuthenticated } = useContext(UserContext);

  return <div>{isAuthenticated ? <ConnectLinkedin /> : <SignIn />}</div>;
};
export default Authenticate;
