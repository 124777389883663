import React from 'react';
import UserAvatar from '../avatar/UserAvatar';
import { Link } from '../core/router';

type Props = {
  user: UserEntity;
};

const StaffCard = ({ user }: Props) => {
  const userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;

  return (
    <Link
      to={`/members/user/${user.id}`}
      className="flex my-4 text-base items-center"
    >
      <div className="mr-4">
        <UserAvatar user={user} size="lg" />
      </div>
      <div>
        <h6>{userName}</h6>
        {user.title ? <div className="text-sm">{user.title}</div> : null}
      </div>
    </Link>
  );
};
export default StaffCard;
