import React from 'react';

type DisplayStyle = 'chip' | 'listItem';

export type SkillType = { label: string; value: string };

export interface PickerSkill {
  value: string;
  label: string;
  description?: string;
  categories: string[];
}

interface Props {
  selectedSkills: PickerSkill[];
  selectSkill(skill: SkillType): void;
  maxSkills: number;
  displayStyle: DisplayStyle;
}

interface DisplayItemProps {
  skillId: string;
  label: string;
  selectSkill(skill: SkillType): void;
  description?: string;
}

const Chip = ({ label, skillId, selectSkill }: DisplayItemProps) => {
  return (
    <button
      className="px-3 py-3 mr-2 my-1 bg-grey-100 rounded-md flex justify-between items-center whitespace-nowrap text-sm leading-none"
      type="button"
      onClick={() => {
        selectSkill({ value: skillId, label });
      }}
    >
      {label}
    </button>
  );
};

const ListItem = ({
  label,
  description,
  selectSkill,
  skillId,
}: DisplayItemProps) => {
  return (
    <div className="flex justify-between w-full my-4">
      <div>
        <div className="text-lg font-semibold">{label}</div>
        {description ? <div className="text-base">{description}</div> : null}
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            selectSkill({ value: skillId, label });
          }}
        >
          {label}
        </button>
      </div>
    </div>
  );
};

const DisplayComponents: Record<
  DisplayStyle,
  (props: DisplayItemProps) => JSX.Element
> = {
  chip: Chip,
  listItem: ListItem,
};

const RecommendedSkills = ({
  selectedSkills,
  selectSkill,
  displayStyle,
}: Props) => {
  const Component = DisplayComponents[displayStyle];

  return selectedSkills.length > 0 ? (
    <>
      <div className="text-lg mb-6">Suggested skills to review</div>
      <div className="flex flex-wrap mb-6">
        {selectedSkills.map(skill => (
          <Component
            key={skill.value}
            label={skill.label}
            skillId={skill.value}
            selectSkill={selectSkill}
            description={skill.description}
          />
        ))}
      </div>
    </>
  ) : null;
};
export default RecommendedSkills;
