import React from 'react';

type Props = {
  tagline: string | null;
  graphicTagline: string | null;
  altText?: string;
};

const ProfileTagline = ({ tagline, graphicTagline, altText }: Props) => {
  if (!tagline && !graphicTagline) {
    return null;
  }

  return (
    <>
      {graphicTagline ? (
        <img src={graphicTagline} alt={altText} className="max-h-full m-auto" />
      ) : (
        <h1 className="-mt-2">{tagline}</h1>
      )}
    </>
  );
};
export default ProfileTagline;
