import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { colorWandOutline } from 'ionicons/icons';
import { Link } from '../../core/router';
import useAnalytics from '../../../hooks/useAnalytics';
import Api from '../Api';
import { LoadingBalls } from '../../core/Loading';

export default ({ action, code }: MagicOp) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  useAnalytics('Magic Op', { action, code });

  useEffect(() => {
    Api.setMagicOp({ action, code })
      .then((response: any) => {
        setMessage(response.message);
      })
      .catch((error: any) => {
        setMessage(error?.response?.data?.message || error?.message || '');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [action, code]);

  return (
    <div className="text-center">
      <div className="mb-4">
        <IonIcon icon={colorWandOutline} className="text-5xl" />
      </div>
      <h3 className="mb-4">Magic Settings</h3>
      <LoadingBalls isActive={loading} message="updating settings..." />
      {message && <p className="text-sm">{message}</p>}

      {!loading && (
        <div className="mt-8">
          <Link to="/" className="text-sm">
            Home <i className="i-arrow-right" />
          </Link>
        </div>
      )}
    </div>
  );
};
