import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { planCanAccess } from '../../utilities/permissionUtil';
import UserContext from '../user/Context';
import Api from './Api';

// Memberships that we allow one-to-many-chat for in addition to isRoot
// 8dd051d9303443c8bc0aa82b41e49fa5 = Gathering
const ALLOW_OTM_CHAT = ['8dd051d9303443c8bc0aa82b41e49fa5'];

// context variables and functions
interface MembershipInterface {
  membership: MembershipEntity | null;
  users: MembershipUserEntity[];
  plan: PlanEntity | null;
  planType: string;
  isIndividualPlan: boolean; // Indicates if the user is on a user plan vs a membership plan (agency, partner, brand)
  refresh(): void;
  canEdit(membershipId: string): boolean;
  checkAccess(
    permission: Permission | Permission[],
    qualifier?: number
  ): boolean;
  update(membership: Partial<MembershipEntity>): Promise<MembershipEntity>;
}

const membershipInterface: MembershipInterface = {
  membership: null,
  users: [],
  plan: null,
  planType: '',
  isIndividualPlan: false,
  refresh: () => {},
  canEdit: () => false,
  checkAccess: () => false,
  update: () => Promise.resolve({} as MembershipEntity),
};

const MembershipContext = React.createContext<MembershipInterface>(
  membershipInterface
);

const MembershipProviderC = ({
  membership,
  updateMembership,
  refreshMembership,
  children,
}: {
  membership: MembershipEntity | null;
  updateMembership(data: Partial<MembershipEntity>): Promise<MembershipEntity>;
  refreshMembership(): Promise<MembershipEntity | null>;
  children: any;
}) => {
  const { isRoot } = useContext(UserContext);
  const [users, setUsers] = useState<MembershipUserEntity[] | []>([]);
  const membershipId = useMemo(() => membership?.id || null, [membership]);

  const refreshUsers = useCallback(() => {
    if (!membershipId) {
      setUsers([]);
      return Promise.resolve<any>(null);
    }

    return Api.users(membershipId).then((response: MembershipUserEntity[]) => {
      const sorted = response.sort(
        (a: MembershipUserEntity, b: MembershipUserEntity) => {
          if (a.membershipAccess === 'admin') return -1;
          if (b.membershipAccess === 'admin') return 1;
          return 0;
        }
      );

      setUsers(sorted);
    });
  }, [membershipId]);

  const refresh = useCallback(() => {
    const rM = refreshMembership();
    const rU = refreshUsers();
    return Promise.all([rM, rU]);
  }, [refreshMembership, refreshUsers]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  const plan = membership?.plan;

  const canEdit = (id: string) => {
    return id === membershipId;
  };

  const canChatOneToMany = useMemo(() => {
    if (isRoot) {
      return true;
    }

    return ALLOW_OTM_CHAT.includes(membershipId || '');
  }, [isRoot, membershipId]);

  const hasP2PWorkPosts = useMemo(() => {
    if (membership?.numConciergePosts || membership?.numSinglePosts) {
      return true;
    }

    return false;
  }, [membership]);

  // TODO: Refactor so src/utilities/permissionUtil.ts can handle 'canBatchSendMessages' as well
  // NOTE: DO NOT pass an array of permissions with a qualifier. If thats what you need,
  // then call checkAccess twice with a different permission and qualifier
  // e.g. checkAccess(['numSeats', 'numRoles'], 3) === Can I have 3 seats OR 3 roles?
  const checkAccess = useCallback(
    (permissions: Permission | Permission[], qualifier?: number): boolean => {
      if (!plan) {
        return false;
      }

      if (!Array.isArray(permissions)) {
        permissions = [permissions];
      }

      return permissions.some(permission => {
        if (permission === 'canBatchSendMessages') {
          return canChatOneToMany;
        }

        // Only for plans that can pay to post. If can't pay to post, let permission go through to check access on plan.
        if (permission === 'canPostWorkToCommuno' && plan.canPayToPost) {
          return hasP2PWorkPosts;
        }

        return planCanAccess({ plan, permission, qualifier });
      });
    },
    [canChatOneToMany, hasP2PWorkPosts, plan]
  );

  const update = useCallback(
    async (data: Partial<MembershipEntity>) => {
      const response = await updateMembership(data);
      return response;
    },
    [updateMembership]
  );

  return (
    <MembershipContext.Provider
      value={{
        membership,
        users,
        plan: plan ?? null,
        planType: plan?.type ?? '',
        isIndividualPlan: plan?.isIndividualPlan ?? false,
        refresh,
        canEdit,
        checkAccess,
        update,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

const MemoProvider = React.memo(MembershipProviderC);

export const MembershipProvider = ({ children }: any) => {
  const { membership, updateMembership, refreshMembership } = useContext(
    UserContext
  );
  return (
    <MemoProvider
      membership={membership}
      updateMembership={updateMembership}
      refreshMembership={refreshMembership}
    >
      {children}
    </MemoProvider>
  );
};

export const MembershipConsumer = React.memo(MembershipContext.Consumer);

export default MembershipContext;
