type Data = {
  [key: string]: string;
};

const replaceBrackets = (template: string, data: Data | null = null) => {
  if (!data) return template;

  return template.replace(/\{\{([^}]+)\}\}/g, (match: string) => {
    const extracted: string = match.slice(2, -2);

    // return data[extracted] ? data[extracted] : '';
    return data[extracted] || '';
  });
};

export default replaceBrackets;
