import React from 'react';

const Welcome = () => (
  <>
    {/* <div className="text-center">
      <h3 className="mb-4" data-swiper-parallax="-300">
        Welcome to Communo.
      </h3>
      <p className="text-lg font-light text-white" data-swiper-parallax="-200">
        LocalSolo has merged with Communo – a community that lets you do what
        you love.
      </p>
    </div>

    <div className="mt-auto md:mt-8">
      <div className="flex justify-center">
        <Button
          className="btn--primary btn--rev"
          isValid
          handleSubmit={() => goTo('next')}
        >
          Continue
        </Button>
      </div>
    </div> */}
  </>
);

export default Welcome;
