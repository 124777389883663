import moment from 'moment';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import { addResponseError } from '../../../../services/Messaging';
import { FormGroup } from '../../../core/form';
import { LoadingRing } from '../../../core/Loading';
import EducationApi from '../../../education/Api';
import GraduationDate from '../../../education/formElements/GraduationDate';
import Major from '../../../education/formElements/Major';
import School from '../../../education/formElements/School';
import { Country } from '../../../plan/formElements';
import useStep from '../../../stepper/hooks/useStep';
import UserContext from '../../../user/Context';
import EducationContext from '../../../education/Context';

interface Props {
  user: UserEntity;
}

interface SchoolValue {
  value: string;
  label: string;
}

interface Inputs {
  school: SchoolValue | null;
  country?: string | null;
  major: string | null;
  minor: string | null;
  graduationDate: string | null;
}

const now = new Date();

const EducationStep = () => {
  const {
    setOnNext,
    goToNextStep,
    setCanAdvance,
    setLoading,
    isLoading,
    setNextButtonLabel,
  } = useStep();
  const { education, isFetching } = useContext(EducationContext);

  const isEdit = education && education?.length > 0;
  const firstEducationItem = education?.length ? education[0] : null;
  const educationId = firstEducationItem?.id;
  const defaultSchool = {
    label: firstEducationItem
      ? `${firstEducationItem?.school.name} - ${firstEducationItem?.school.country.name}`
      : '',
    value: firstEducationItem ? firstEducationItem?.school.id : '',
  };

  const form = useForm<Inputs>({
    mode: 'onChange',
  });
  const { handleSubmit, formState, errors, register, reset, watch } = form;
  const { isValid, isDirty } = formState;
  const school = watch('school');
  const isNewSchool = school?.value === 'create';

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - Education Page');
  }, [track]);

  useEffect(() => {
    const item = education?.length ? education[0] : null;

    if (item) {
      reset({
        school: { label: item.school.name, value: item.school.id },
        major: item.major,
        minor: item.minor,
        graduationDate: item.graduationDate,
      });
    } else {
      reset({
        school: undefined,
        major: '',
        minor: '',
        graduationDate: undefined,
      });
    }
  }, [education, reset]);

  const onSubmit = useCallback(
    async (values: any) => {
      if (isLoading) return;
      setLoading(true);

      try {
        let schoolId = values.school?.value;

        if (values.school?.value === 'create' && values.country) {
          const newSchool = await EducationApi.createSchool({
            name: values.school.label,
            countryIsoCode: values.country,
          });

          schoolId = newSchool.id;
        }

        // graduationDate should never be null because we have validation to prevent it. 'now' is just to make typescript happy.
        const data = {
          ...values,
          graduationDate: moment(values.graduationDate ?? now).format(
            'YYYY-MM-DD'
          ),
          schoolId,
        };

        const apiCall =
          isEdit && educationId
            ? EducationApi.editEducation(educationId, data)
            : EducationApi.createEducation(data);

        await apiCall;
        setLoading(false);
        goToNextStep();
      } catch (error) {
        setLoading(false);
        addResponseError(error);
      }
    },
    [educationId, goToNextStep, isEdit, isLoading, setLoading]
  );

  useEffect(() => {
    setNextButtonLabel(isDirty ? 'Continue' : 'Skip');
  }, [isDirty, setNextButtonLabel]);

  useEffect(() => {
    setCanAdvance(!isDirty || isValid);
  }, [isDirty, isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  if (isFetching) {
    return (
      <div className="text-center py-20">
        <LoadingRing isActive color="primary" size="lg" />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <School form={form} defaultValue={defaultSchool} />
      {isNewSchool && (
        <FormGroup name="country" label="Country" errors={errors}>
          <Country
            name="country"
            // errors={isSubmitted ? errors : {}}
            errors={errors}
            register={register}
          />
        </FormGroup>
      )}

      {school && (
        <>
          <Major errors={errors} register={register} />
          <GraduationDate
            label="Year of graduation"
            format="Year"
            form={form}
            defaultValue={firstEducationItem?.graduationDate}
          />
        </>
      )}

      {/* <div className="mt-2">
        <div className="help-text">
          Can’t find your school? Contact us and let us know.
        </div>
        <a
          className="btn btn--sm btn--outline w-full mt-4"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:support@communo.com?subject=Cant find my school"
        >
          Email support@communo.com
        </a>
      </div> */}
    </form>
  );
};

const WrappedEducationStep = () => {
  const { user } = useContext(UserContext);

  if (user == null) {
    return <LoadingRing isActive />;
  }

  return <EducationStep />;
};

export default WrappedEducationStep;
