import React from 'react';

type Props = {
  className?: string;
};

const Divider = ({ className }: Props) => (
  <hr className={`mt-12 ${className || ''}`} />
);

export default Divider;
