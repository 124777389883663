import React from 'react';
import { ListItemComponentProps } from '..';
import AvatarBase from './AvatarBase';

interface Props extends ListItemComponentProps {}

const AvatarWithoutName = ({ item }: ListItemComponentProps) => {
  const { actionUrl, imageUrl, heading } = item;

  return (
    <AvatarBase
      to={actionUrl || ''}
      imageUrl={imageUrl || ''}
      heading={heading || ''}
    />
  );
};
export default AvatarWithoutName;
