import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import SearchContext from '../search/Context';
import useSearch from '../search/useSearch';
import CollectionBundleCard from '../card/CollectionBundleCard';

const Collections = () => {
  const { getPerks } = useContext(SearchContext);
  const [results, handleSearch] = useSearch(getPerks);
  const [collections, setCollections] = useState<PerkBundleSearchResponse[]>(
    []
  );

  const loadPerks = useCallback(() => {
    return handleSearch({
      allBundles: {
        filters: ['isActive=1'].join(' AND '),
        facetFilters: [['type:bundle']],
        hitsPerPage: 200,
      },
    });
  }, [handleSearch]);

  useEffect(() => {
    const { allBundles } = results;
    setCollections(
      allBundles?.hits.filter(
        (item: PerkBundleSearchResponse) => item.bundleType === 'collection'
      ) || []
    );
  }, [results]);

  useEffect(() => {
    loadPerks();
  }, [loadPerks]);

  return (
    <>
      {collections.map((perk: PerkBundleSearchResponse) => {
        const {
          objectID,
          name,
          tagline,
          squareImageUrl,
          usesLightTheme,
        } = perk;

        return (
          <CollectionBundleCard
            key={objectID}
            id={objectID}
            backgroundImage={squareImageUrl}
            name={name}
            tagline={tagline}
            className="my-8"
            lightTheme={usesLightTheme}
          />
        );
      })}
    </>
  );
};

const CollectionsSidebar = () => {
  return (
    <>
      <div className="content">
        <h4>Collections</h4>
        <p className="text-large">
          We’ve assembled collections of Perks to get you started.
        </p>
      </div>
      <Collections />
    </>
  );
};

export default CollectionsSidebar;
