// Takes a string and splits it into easily consumable parts
const splitName = (str: string) => {
  if (!str || typeof str !== 'string') return null;

  const arr = str.split(' ');
  const arrEnd = arr.length - 1;

  const first = arr[0];
  const middle = arr.slice(1, arrEnd).join(' ');
  const last = arr[arrEnd];

  return {
    first,
    middle,
    last,
  };
};

export default splitName;
