import React, { useContext } from 'react';
import Avatar from '../avatar/Avatar';
import UserContext from '../user/Context';
import { relativeTime } from '../../utilities/dateTime';
import RichText from '../core/RichText';

const MyExternalApplication = ({
  application,
}: {
  application: ApplicationEntity;
}) => {
  const { name, avatarURL } = useContext(UserContext);

  return (
    <>
      <h5 className="mb-4">Work Application</h5>
      <div>
        <div className="flex items-center mb-4">
          <div className="mr-6">
            <Avatar size="md" avatarName={name} avatarUrl={avatarURL} />
          </div>
          <div>
            <p className="font-medium text-lg">{name}</p>
            <p className="mt-1 flex items-center text-sm">
              <svg
                className="inline-block h-4 text-grey-600 mr-2"
                viewBox="0 0 16 18"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.72639 0.812024C9.0318 0.9446 9.21325 1.26265 9.17195 1.59303L8.52733 6.75H14.4277C14.7188 6.75 14.9835 6.91835 15.1069 7.18188C15.2304 7.44542 15.1902 7.75658 15.0039 7.98014L7.50391 16.9801C7.29076 17.2359 6.93451 17.3206 6.6291 17.188C6.32369 17.0554 6.14224 16.7373 6.18354 16.407L6.82816 11.25H0.927743C0.63673 11.25 0.371986 11.0817 0.248551 10.8181C0.125117 10.5546 0.165275 10.2434 0.351578 10.0199L7.85158 1.01986C8.06472 0.764086 8.42098 0.679447 8.72639 0.812024ZM2.52902 9.75H7.67774C7.89287 9.75 8.09763 9.84237 8.24 10.0036C8.38237 10.1649 8.44864 10.3796 8.42195 10.593L7.98925 14.0547L12.8265 8.25H7.67774C7.46262 8.25 7.25785 8.15763 7.11548 7.99635C6.97312 7.83508 6.90685 7.62044 6.93354 7.40697L7.36624 3.94534L2.52902 9.75Z"
                />
              </svg>
              <span className="font-medium">One-Click Applied</span>
              <span className="text-subdued">
                {' '}
                – {relativeTime(application.createdAt)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const MyInternalApplication = ({
  application,
}: {
  application: ApplicationEntity;
}) => {
  const { name, avatarURL } = useContext(UserContext);

  return (
    <>
      <h5 className="mb-8">Work Application</h5>
      <div>
        <div className="flex items-center mb-4">
          <div className="mr-6">
            <Avatar size="md" avatarName={name} avatarUrl={avatarURL} />
          </div>
          <div>
            <span className="font-medium text-lg">{name}</span>{' '}
            <span className="text-subdued">
              – {relativeTime(application.createdAt)}
            </span>
          </div>
        </div>
      </div>
      <div className="ml-16">
        <RichText html={application.body} classVariant="ql-editor" />
      </div>
    </>
  );
};

const MyApplication = ({
  application,
  isOneClickApply = false,
}: {
  application: ApplicationEntity;
  isOneClickApply?: boolean;
}) => {
  if (isOneClickApply) {
    return <MyExternalApplication application={application} />;
  }

  return <MyInternalApplication application={application} />;
};

export default MyApplication;
