import React from 'react';
import Image from '../core/Image';
import { getBorderRadiusSize } from './CardSizes';

export type CardImageProps = {
  imageUrl?: string | null;
  srcSet?: string;
  altText: string;
  borderRadius?: 'none' | 'small' | 'medium' | 'large';
  roundedBottom?: boolean;
  aspectRatio?: 'square' | '2/1';
  children?: string | JSX.Element[] | JSX.Element | null;
};
const CardImage = ({
  imageUrl,
  srcSet,
  altText,
  borderRadius = 'medium',
  roundedBottom = false,
  aspectRatio = 'square',
  children,
}: CardImageProps) => {
  const ratio = aspectRatio === 'square' ? 1 : 0.5;
  const roundedClasses = [getBorderRadiusSize(borderRadius)];
  if (!roundedBottom) roundedClasses.push(`rounded-b-none`);
  const bgColor = imageUrl ? 'bg-white' : 'bg-grey-300';

  return (
    <div
      className={`relative overflow-hidden ${bgColor} z-0 ${roundedClasses.join(
        ' '
      )}`}
      style={{
        paddingTop: `${ratio * 100}%`,
      }}
    >
      {imageUrl && (
        <div className="absolute top-0 left-0 h-full w-full">
          <Image
            src={imageUrl}
            srcSet={srcSet}
            alt={altText}
            // className="card__card-img absolute top-0 h-full w-full object-cover"
            className="card__card-img"
          />
        </div>
      )}
      {children ? (
        <div className="absolute top-0 left-0 h-full w-full z-10">
          {children}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(CardImage);
