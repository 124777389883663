import React, { useMemo } from 'react';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

const getSkillMatchStatus = (
  criteria: ScoreMetricsCriteria,
  skillId: string
): MetricStatus => (criteria[`hasSkill-${skillId}`] ? 'complete' : null);

type Props = {
  skill: SkillEntity;
  addSkill: Function;
  scoreMetrics: ScoreMetrics;
};

const SkillMetric = ({ skill, addSkill, scoreMetrics }: Props) => {
  const { criteria } = scoreMetrics;
  const status = useMemo(() => getSkillMatchStatus(criteria, skill.id), [
    criteria,
    skill.id,
  ]);

  return (
    <Metric
      label={skill.name}
      status={status}
      onClick={() => addSkill(skill.id)}
    />
  );
};

export default React.memo(SkillMetric);
