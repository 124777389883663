import { memoize, pick } from 'lodash';
import createStore from '../../../utilities/createStore';
import { IChannel } from '../../../@types/chat.d';
import chatChannelsStore from './ChatChannelsState';
import messageStoreCreator from './ChatChannelMessagesState';

const keysToPick = [
  'attributes',
  'createdBy',
  'dateCreated',
  'dateUpdated',
  'friendlyName',
  'lastConsumedMessageIndex',
  'lastMessage',
  'notificationLevel',
  'sid',
  'state',
  'status',
  'type',
  'uniqueName',
];

/**
 * ChatChannelState
 * Stores the IDs of the members in a given channel.
 * A new store is created for each Channel accessed.
 */

export type ChatChannelState = {
  channelId: string;
  channel: IChannel | null;
  loading: boolean;
  loaded: boolean;
  loadingChannel: () => void;
  setChannel: (channel: IChannel) => void;
  updateChannel: (channel: IChannel) => void;
};

const channelStoreCreator = (channelId: string) => {
  return createStore<ChatChannelState>(
    (set: any) => ({
      channelId,
      channel: null,
      loading: false,
      loaded: false,
      loadingChannel: () =>
        set({ loading: true, loaded: false }, 'loadingChannel'),
      setChannel: channel =>
        set(
          {
            channel: pick(channel, keysToPick),
            loading: false,
            loaded: true,
          },
          'setChannel'
        ),
      updateChannel: channel => {
        const [, channelsApi] = chatChannelsStore;
        channelsApi.getState().updateChannel(channel);

        const [, api] = messageStoreCreator(channel.sid);
        api.getState().updateUnreadCount();

        set(
          {
            channel: pick(channel, keysToPick),
          },
          'updateChannel'
        );
      },
    }),
    `ChatChannelState: ${channelId}`
  );
};

export default memoize(channelStoreCreator);
