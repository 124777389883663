import production from './production';
import { Environment } from '.';

const stageEnvironment: Environment = {
  appEnvironment: 'development',
  apiUrl: 'https://stage-api.communo.com',
  headerColor: 'blue-1100',
  algoliaAppId: 'K9ZSMXRI9J',
  algoliaPrefix: 'qa-tmp',
  cloudinaryApiKey: '291367397962851',
  cloudinaryCloudName: 'communo',
  logRocketAppId: 'ccigen/communo-dev',
  googleMapsApiKey: 'AIzaSyC6UagLxZPRazqe4xBiv5WfDnzMmL3-9bc',
  recurlyPublicKey: 'ewr1-RttXvi9Q6bDyPUh4ffdUoT',
  firebaseConfig: {
    apiKey: 'AIzaSyBiNFwrtsV9tI3jjBq_MOhAEXyNfiHgWvA',
    authDomain: 'communo-qa.firebaseapp.com',
    databaseUrl: 'https://communo-qa.firebaseio.com',
    projectId: 'communo-qa',
    storageBucket: 'communo-qa.appspot.com',
    messagingSenderId: '107222342272',
    appId: '1:107222342272:web:16c84a657470d84710a465',
  },
  segmentKey: 'ELwMdDEAmWR8E4V39l6caR9gVx7Ju0v3',
  sentryDsn:
    'https://c4323a25a295445a8f2b9cdf7af2e1d9@o348555.ingest.sentry.io/2222907',
  youtubeChannelId: 'UClLwdCQX_-Fjlat9dqrYEng',
  feedVideoPlaylistId: 'PLBe3PLrvzMA-sMa_xUB0MjovPo82kqeKi',
  chatMessagesGroupingInterval: 1000 * 60 * 15, // 15 minutes,
  chatMessagesPerPage: 20,
  chatChannelsPerPage: 10,
  chatRoles: {
    RLe63677c87e624c8d932de28234471c89: {
      type: 'channelAdmin',
      label: 'Admin',
    },
    RLbe0d17bd1a3a489cb0c6ce8828d09610: {
      type: 'channelMember',
      label: 'Member',
    },
    RLa148785278a14b239cbf0a77a9a5fde1: {
      type: 'serviceAdmin',
      label: 'Service Admin',
    },
    RL2c43b9f564a840278ff899b647e56de6: {
      type: 'serviceUser',
      label: 'User',
    },
  },
  opengraphKey: '42c1823a-f6b5-4f07-b687-53c65b389b51',
  beatsMembershipId: 'fcd14520ab854dbd86bc9bc6ff82a574',
  doDeployUpdate: true,
  zendeskKey: 'f88b0d88-1969-4b4e-928f-af41e8d948cd',
  featureFlags: {
    ...production.featureFlags,
  },
};

export default stageEnvironment;
