import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SortableImage from './SortableImage';
import reorder from '../../../utilities/Array';

interface Props {
  urls: string[];
  onImagesChanged: Function;
}

const SortableImages = ({ urls, onImagesChanged }: Props) => {
  const [imageURLs, setImageURLs] = useState<string[]>(() => {
    if (urls.length) {
      return urls;
    }

    return [];
  });

  // const setInitialState = useCallback(
  //   (imageArray: string[]) => {
  //     setImageURLs(imageArray);
  //   },
  //   [setImageURLs]
  // );

  useEffect(() => {
    setImageURLs(urls);
  }, [urls]);

  const updateImages = (imageArray: string[]) => {
    setImageURLs(imageArray);
    onImagesChanged(imageArray);
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    // Did the user drop outside?
    if (!destination) {
      return;
    }

    // The user dropped the item back where it came from
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Create the new reordered array
    const newOrder = reorder(imageURLs, source.index, destination.index);

    updateImages(newOrder as string[]);
  };

  const handleRemoveImage = (index: number) => {
    if (index >= 0) {
      const newArray = Array.from(imageURLs);
      newArray.splice(index, 1);
      updateImages(newArray);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="items"
        renderClone={(provided, snapshot, rubric) => (
          <div>
            <div
              // eslint-disable-next-line
              {...provided.draggableProps}
              // eslint-disable-next-line
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className="relative aspect-21/9 bg-grey-500 rounded-lg+ overflow-hidden my-4">
                <img
                  className="absolute bottom-0 right-0 w-full h-full object-cover"
                  // @ts-ignore
                  src={imageURLs[rubric.source.index]}
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
          >
            {imageURLs.map((url, index) => (
              <SortableImage
                id={url}
                key={url}
                index={index}
                url={url}
                removeImage={handleRemoveImage}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default SortableImages;
