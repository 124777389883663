import React, { useContext } from 'react';
import useClientBrandingStore from '../../../hooks/stores/useClientBrandingStore';
import Button from '../../core/Button';
import MembershipContext from '../../membership/Context';
import OnboardingLayout from '../../onboarding/OnboardingLayout';
import UserContext from '../Context';

function determineBranding(memberOf: string | null) {
  switch (memberOf) {
    case 'hbcu':
      return 'hbcu';
    case 'locomotus':
      return 'locomotus';
    default:
      return 'communo';
  }
}

const GetToKnowYou = () => {
  const { user, setShowOnboarding } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const memberOf =
    user?.source === 'locomotus'
      ? 'locomotus'
      : membership?.plan?.subType ?? null;
  const branding = determineBranding(memberOf);
  const brandColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );

  return (
    <OnboardingLayout organization={branding} theme="dark">
      <div>
        <h1 className="h2 mt-24 text-white text-center">Getting to know you</h1>
        <div className="content text-white text-center my-8">
          <p className="text-large">
            We need to get to know you better. Just the basics, we promise.
          </p>
        </div>
        <Button
          color={
            branding === 'hbcu' || branding === 'locomotus'
              ? 'tertiary'
              : 'primary'
          }
          reverse
          fullWidth
          text="Set up profile"
          className="mt-8 block max-w-xs mx-auto"
          onClick={() => {
            setShowOnboarding(true);
          }}
          style={{
            color: brandColor ?? undefined,
          }}
        />
      </div>
    </OnboardingLayout>
  );
};
export default GetToKnowYou;
