/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import { useHistory } from 'react-router';
import { LoadingBalls } from '../../core/Loading';
import membershipApi from '../../membership/Api';
import { addResponseError } from '../../../services/Messaging';

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}) => {
  const count = preGlobalFilteredRows.length;

  return (
    <div className="form-group">
      <label htmlFor="memberSearch" className="sr-only">
        Search
      </label>
      <div className="input-group">
        <input
          id="memberSearch"
          className="form-input"
          type="search"
          placeholder={`Search ${count} records...`}
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
        />
        <div className="input-group__icon">
          <i className="i-search text-xs" />
        </div>
      </div>
    </div>
  );
};

const Table = ({ columns, data }: { columns: any; data: any }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { push } = useHistory();

  const handleClick = (row: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const id: string = row.original.membershipId;
    push(`/members/membership/${id}`);
  };

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  className="text-white bg-grey-500 p-3 text-left"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={e => handleClick(row, e)}
                className="hover:cursor-pointer group"
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-3 bg-white border-b border-grey-200 cursor-pointer group-hover:bg-grey-100"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const DeactivatedList = () => {
  const [members, setMembers] = useState<MemberDeactivated[]>([]);
  const [loading, setLoading] = useState(true);

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Membership Id',
      //   accessor: 'membershipId',
      // },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Company',
        accessor: 'membershipName',
      },
    ],
    []
  );

  useEffect(() => {
    membershipApi
      .deactivated()
      .then((response: MemberDeactivated[]) => {
        setMembers(response);
      })
      .catch(error => {
        addResponseError(error);
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sortedMembers = useMemo(() => {
    // TODO: Do some sorting
    return members;
  }, [members]);

  return (
    <>
      <LoadingBalls isActive={loading} />
      <Table columns={columns} data={sortedMembers} />
    </>
  );
};
export default DeactivatedList;
