import React from 'react';
import Page from '../components/core/Page';
import WelcomeCompany from '../components/membership/WelcomeCompany';

export default function WelcomeCompanyPage() {
  return (
    <Page
      layout="none"
      className="page-bg--white page--no-padding"
      pageTitle="Welcome"
      hideHeader
      authGuard
    >
      <WelcomeCompany />
    </Page>
  );
}
