export default (url: string) => {
  return {
    id: 'phone',
    label: 'Contact phone number',
    description:
      'Sometimes the best way to start a conversation is to pick up the phone.',
    icon: 'speaker',
    action: `#/${url}/basic`,
    actionLabel: 'Add Phone',
    complete: false,
  };
};
