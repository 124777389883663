import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="title"
    label="Title"
    placeholder="e.g. Creative Director"
    errors={errors}
    ref={register({
      // required: 'Title is required',
    })}
  />
);
