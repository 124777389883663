import React, { useContext, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup } from '../../core/form';
import SearchContext from '../../search/Context';

type Hit = {
  label: string;
  value: string;
} | null;

export default ({ errors, register, defaultValue, form }: any) => {
  const { getCategories } = useContext(SearchContext);
  const ref = register({ required: 'Category is required' });
  const { setValue } = form;
  const [display, setDisplay] = useState(
    defaultValue ? { value: defaultValue.id, label: defaultValue.name } : null
  );

  const handleChange = (hit: any) => {
    setDisplay(hit);
    setValue('categoryId', hit.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <FormGroup name="categoryId" label="Select a category" errors={errors}>
      <AsyncSelect
        defaultOptions
        cacheOptions
        className="multiselect"
        classNamePrefix="multi"
        loadOptions={(inputValue: string, callback: Function) => {
          getCategories(inputValue).then(({ hits }) => {
            callback(
              hits.map((hit: any) => ({
                value: hit.objectID,
                label: hit.name,
              }))
            );
          });
        }}
        onChange={handleChange}
        value={display}
        // components={{
        //   Input,
        //   DropdownIndicator,
        //   ClearIndicator,
        // }}
      />
      <input
        name="categoryId"
        type="hidden"
        ref={ref}
        className="form-input"
        placeholder="Searh or select a category"
      />
    </FormGroup>
  );
};
