import React from 'react';

const AvatarPlaceholder = ({
  size = 'sm',
}: {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';
}) => (
  <div>
    <div
      className={`avatar--${size} avatar-placeholder placeholder--animate`}
    />
  </div>
);

export default AvatarPlaceholder;
