import React from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import cloudinary from '../../utilities/Cloudinary';
import { Link } from '../core/router';

interface Props {
  bundle: PerkBundleSearchResponse;
}

const CollectionFeatureSlideDesktop = ({ bundle }: Props) => {
  const {
    objectID,
    horizontalHeaderImageUrl,
    name,
    tagline,
    descriptionSummary,
    usesLightTheme,
  } = bundle;

  return (
    <div className="relative aspect-3/1 bg-grey-300 w-full">
      <img
        src={cloudinary(horizontalHeaderImageUrl, ['w_1300', 'h_650'])}
        alt={name}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0">
        <div
          className={`text-left py-8 px-10 md:px-0 md:ml-16 4xl:ml-20 5xl:ml-24 max-w-screen-xs ${
            usesLightTheme ? 'text-white' : 'text-black'
          }`}
        >
          <div className="text-2xl font-semibold md:h5 opacity-50">
            {tagline}
          </div>
          <h3 className="h4 md:h3 mb-4">{name}</h3>
          <p className="text-lg hidden md:block">{descriptionSummary}</p>
          <Link
            to={`/perks/bundles/${objectID}`}
            className={`btn btn--outline btn--sm md:mt-8 ${
              usesLightTheme ? 'btn--rev' : 'btn--tertiary'
            }`}
          >
            Discover more
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CollectionFeatureSlideDesktop;
