import React from 'react';
import { IonToolbar, IonTitle, IonButtons, IonBackButton } from '@ionic/react';

import EventView from '../components/event/View';
import Page from '../components/core/Page';

export default ({ match }: any) => {
  const { id } = match.params;

  return (
    <Page
      authGuard
      pageTitle="Event"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/events" className="pushable" />
          </IonButtons>
          <IonTitle slot="start">Event Details</IonTitle>
        </IonToolbar>
      )}
    >
      <EventView id={id} />
    </Page>
  );
};
