/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ImgHTMLAttributes,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import { LoadingRing } from './Loading';

type Props = ImgHTMLAttributes<Partial<HTMLImageElement>> & {
  src: string;
  stretch?: boolean;
  loadingPlaceholderHeight?: string;
  className?: string;
  lazy?: boolean;
};

const ImageComponent = ({
  src,
  srcSet,
  sizes,
  alt,
  stretch = true,
  lazy = false,
  loadingPlaceholderHeight,
  className = '',
  ...rest
}: Props) => {
  const isMounted = useRef(false);

  const [state, setState] = useState<{
    isLoaded: boolean;
    isError: boolean;
  }>({
    isLoaded: false,
    isError: false,
  });

  const handleImageLoaded = useCallback(() => {
    if (!isMounted.current) {
      return;
    }

    setState({
      isLoaded: true,
      isError: false,
    });
  }, []);

  const handleImageError = useCallback(() => {
    if (!isMounted.current) {
      return;
    }

    setState({
      isLoaded: false,
      isError: true,
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isMounted.current) {
        return;
      }

      setState(prev => ({
        ...prev,
        isLoaded: true,
      }));
    }, 3000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (state.isError) {
    return null;
  }

  const loadStrategy = lazy ? 'lazy' : 'eager';

  return (
    <div
      className={cn(`w-full h-full relative z-0`, className)}
      style={{
        maxHeight: state.isLoaded ? 'none' : '18rem',
      }}
    >
      {loadingPlaceholderHeight && !state.isLoaded ? (
        <div
          className={cn(
            `bg-grey-200 flex items-center justify-center`,
            loadingPlaceholderHeight
          )}
        >
          <LoadingRing isActive size="2xl" />
        </div>
      ) : null}

      <img
        srcSet={srcSet}
        src={src}
        sizes={sizes}
        alt={alt}
        loading={loadStrategy}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        className={cn('transition-opacity ease-in duration-300', {
          'object-cover w-full h-full': stretch,
          'opacity-100': state.isLoaded,
          'opacity-0': !state.isLoaded,
        })}
        {...rest}
      />
    </div>
  );
};

export default React.memo(ImageComponent);
