import React from 'react';
import InviteMemberForm from './forms/InviteMemberForm';

export default () => {
  return (
    <div>
      <h3 className="text-center mb-8">Team</h3>
      <InviteMemberForm />
    </div>
  );
};
