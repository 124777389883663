/* eslint-disable no-console */
import React, { useContext, useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import NewOneToManyChatContext from '../context/ChatNewOneToManyContext';
import { FormTextarea } from '../../core/form';
import { SubmitButton } from '../../core/Button';
import replaceBrackets from '../../../utilities/replaceBrackets';
import useNavigate from '../../../hooks/useNavigate';
import useChatServices from '../hooks/useChatServices';
import { IChannel } from '../../../@types/chat.d';
import { addSuccess, addInfo } from '../../../services/Messaging';
import { NewConversationUser } from '../../../@types/conversation.d';

const splitName = (fullName: string) => {
  const names = fullName.split(' ');
  const firstName = names[0];
  const lastName = names[1];

  return { firstName, lastName };
};

const copyToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str);
    addInfo(`${str} copied to clipboard.`);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

const NewOneToManyConversationSettings = () => {
  useAnalytics('New One-to-Many Message');

  const navigate = useNavigate();
  const { members, clearMembers } = useContext(NewOneToManyChatContext);
  const { createChannel, checkForExistingChannel } = useChatServices();
  const { handleSubmit, errors, register, watch } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const [loading, setLoading] = useState(false);

  const sampleMember = members[0] || null;

  const template = watch('message');
  const processedMessage = useMemo(() => {
    if (template?.length && sampleMember) {
      const { firstName, lastName } = splitName(sampleMember.fullName);

      return replaceBrackets(template, {
        firstName,
        lastName,
      });
    }

    return '';
  }, [template, sampleMember]);

  const processMember = useCallback(
    async (member: NewConversationUser) => {
      const { identity, fullName } = member;
      const { firstName, lastName } = splitName(fullName);
      const message = replaceBrackets(template, {
        firstName,
        lastName,
      });

      const existingChannel = await checkForExistingChannel(identity);

      if (existingChannel) {
        const messageExisting = async (channel: IChannel, msg: string) => {
          await channel.sendMessage(msg);
          await channel.setAllMessagesRead();
        };

        return messageExisting(existingChannel, message);
      }
      return createChannel({
        identities: [identity],
        message,
        awaitConsumed: true,
      });
    },
    [template, createChannel, checkForExistingChannel]
  );

  const onSubmit = useCallback(async () => {
    setLoading(true);

    await Promise.allSettled(members.map(member => processMember(member)));

    setLoading(false);

    addSuccess('Messages successfully sent.');
    clearMembers();

    navigate('/chat');
  }, [members, processMember, navigate, clearMembers]);

  return (
    <>
      <form
        className="flex flex-col grow"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="text-xl mb-2">Personalize</div>

        <small className="mb-8">
          You may use the following dynamic variables in your message.{' '}
          <strong>Be sure to check the preview below.</strong>
        </small>

        <div className="flex items-center justify-between mb-2">
          <strong>{'{{firstName}}'}</strong>
          <button
            type="button"
            className="btn btn--sm btn--outline"
            onClick={() => copyToClipboard('{{firstName}}')}
          >
            Copy to clipboard
          </button>
        </div>

        <div className="flex items-center justify-between mb-4">
          <strong>{'{{lastName}}'}</strong>
          <button
            type="button"
            className="btn btn--sm btn--outline"
            onClick={() => copyToClipboard('{{lastName}}')}
          >
            Copy to clipboard
          </button>
        </div>

        <div className="text-xl mb-2">Compose Message</div>

        <FormTextarea
          className="mb-2"
          name="message"
          label=""
          placeholder="Type your message here"
          ref={register({
            required: {
              value: true,
              message: 'A message is required',
            },
          })}
          errors={errors}
        />

        <span className="label">Chat Preview</span>
        <div className="chat-message chat-message--me chat-message--muted chat-message--full mb-8">
          <div className="chat-bubble">
            <div className="chat-bubble__body">
              {processedMessage?.length
                ? processedMessage
                : 'Preview will appear here as you type.'}
            </div>
          </div>
        </div>

        <SubmitButton
          className="btn--sm btn--full"
          text="Send"
          disabled={!template}
          loading={loading}
        />
      </form>
    </>
  );
};

export default NewOneToManyConversationSettings;
