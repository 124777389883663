import cn from 'classnames';
import React from 'react';

const BadgeVerified = React.memo(({ className = '' }: any) => {
  return (
    <svg
      width="20"
      height="25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('fill-current', className)}
    >
      <path
        d="M703.28 401.37h.2a1.17 1.17 0 0 0 1 0h.09l.29-.16c.11-.08.26-.17.43-.27l.57-.35a22.54 22.54 0 0 0 3.14-2.32c2.25-2 4.74-5 4.74-8.73v-7.63a1.07 1.07 0 0 0-.71-1l-8.73-3.27a1.1 1.1 0 0 0-.76 0l-8.73 3.27a1.12 1.12 0 0 0-.71 1v7.63c0 3.73 2.49 6.73 4.74 8.73a26.22 26.22 0 0 0 3.14 2.32c.38.26.72.45 1 .6Zm1.5-2.69-.79.47-.77-.47a22.67 22.67 0 0 1-2.86-2.11c-2.12-1.85-4-4.3-4-7.09v-6.88l7.64-2.86 7.64 2.86v6.88c0 2.79-1.89 5.24-4 7.09a22.93 22.93 0 0 1-2.86 2.11Zm-1.19-.82.26.16v-17.17l-6.64 2.48v6.19c0 2.6 2 4.88 3.65 6.34a23.31 23.31 0 0 0 2.73 2Z"
        transform="translate(-694.1 -377.57)"
      />
    </svg>
  );
});
export default BadgeVerified;
