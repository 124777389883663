import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import MembershipContext from '../Context';
import { LoadingBalls } from '../../core/Loading';
import { FormImage } from '../../core/form';
import { addResponseError } from '../../../services/Messaging';

type Props = {
  name: string;
  label?: string;
  id: string;
  defaultValue?: string | null;
  aspectRatio?: [number, number] | null;
  hideAvatar?: boolean;
  onChange?: Function;
  btnClassName?: string;
  roundCrop?: boolean;
};

export default ({
  name,
  label,
  defaultValue,
  aspectRatio,
  hideAvatar = false,
  onChange,
  btnClassName,
  roundCrop = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { update, refresh } = useContext(MembershipContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { register, errors } = form;

  const handleChange = (url: string) => {
    if (loading) return;
    setLoading(true);

    update({ [name]: url })
      .then((response: any) => {
        // addSuccess('Image updated!');
        if (onChange) onChange(url);
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <LoadingBalls isActive={loading} fullscreen />
      <FormImage
        name={name}
        aspectRatio={aspectRatio}
        label={label}
        onChange={handleChange}
        errors={errors}
        form={form}
        ref={register}
        hideAvatar={hideAvatar}
        defaultValue={defaultValue || ''}
        btnClassName={btnClassName}
        roundCrop={roundCrop}
        refresh={refresh}
      />
    </div>
  );
};
