import React from 'react';
import Avatar from '../../avatar/Avatar';
import { NewConversationUser } from '../../../@types/conversation.d';

const SelectedMembers = ({
  members,
  onMemberClick,
}: {
  members: NewConversationUser[];
  onMemberClick: Function;
}) => {
  return (
    <div className="overflow-x-auto">
      <div className="flex">
        {members.map(member => (
          <div className="px-3" key={member.identity}>
            <Avatar
              avatarName={`${member.fullName}`}
              avatarUrl={member.avatarURL}
              size="lg"
              isCentered
              onClose={() => onMemberClick(member)}
            />
            <p className="text-center text-sm">
              {member.fullName.split(' ')[0]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedMembers;
