import React from 'react';
import Button from '../core/Button';

type Props = {
  handleRejectButton: () => void;
  rejectedButtonText: string;
};
const ApplicationRejectButton = ({
  handleRejectButton,
  rejectedButtonText,
}: Props) => {
  return (
    <Button
      fill="solid"
      type="button"
      onClick={handleRejectButton}
      color="primary"
      className="w-full"
    >
      <span className="btn__text">{rejectedButtonText}</span>
    </Button>
  );
};

export default ApplicationRejectButton;
