import shallow from 'zustand/shallow';
import chatUnreadMessagesState from '../state/ChatUnreadMessagesState';

/**
 * useChatTotalUnreadCount
 *
 * Returns the total unread messages count.
 */
const useChatTotalUnreadCount = () => {
  const [unreadMessagesStore] = chatUnreadMessagesState;
  const [channels] = unreadMessagesStore(state => [state.channels], shallow);

  const arr = Object.values(channels);
  const totalUnread = arr.reduce((acc, cur) => acc + cur, 0);

  return totalUnread;
};

export default useChatTotalUnreadCount;
