import React, { useState } from 'react';
import Editor from '../components/core/Editor/Editor';
import RichText from '../components/core/RichText';

const EditorDemo = () => {
  const defaultValue = '<p>Hello <strong>world</strong>!</p>';
  const [html, setHtml] = useState(defaultValue);
  const handleChange = (content: string) => {
    setHtml(content);
  };

  return (
    <div>
      <h1 className="mb-8">Editor Demo</h1>
      <div className="mb-8">
        <h4 className="mb-4">Editor</h4>
        <Editor
          defaultValue={defaultValue}
          placeholder="Type something..."
          onChange={handleChange}
        />
      </div>

      <div className="mb-8">
        <h4 className="mb-4">Rendered HTML</h4>
        <RichText html={html} linkify />
      </div>

      <div className="mb-8">
        <h4 className="mb-4">HTML Output</h4>
        <div className="bg-black p-4">
          <code>{html}</code>
        </div>
      </div>
    </div>
  );
};

export default EditorDemo;
