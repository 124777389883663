import React, { useEffect } from 'react';
import useStep from '../stepper/hooks/useStep';
import ThankYouMessage from './ThankYouMessage';

const ThankYou = () => {
  const { setTitle, setDescription } = useStep();

  // NOTE: Tracked in the ThankYouMessage component
  // useEffect(() => {
  //   track('Reference: Viewed Step', {
  //     step: 'Thank You',
  //   });
  // });

  useEffect(() => {
    setTitle('Thank you for your time');
  }, [setTitle]);

  useEffect(() => {
    setDescription('We appreciate you leaving a reference.');
  }, [setDescription]);

  return <ThankYouMessage />;
};
export default ThankYou;
