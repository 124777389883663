import React, { useContext } from 'react';
import { FormGroup, Autocomplete } from '../form';
import SearchContext from '../../search/Context';

export default ({
  form,
  min,
  max,
  defaultValues,
  value,
  isMulti = true,
  name = 'skills',
}: Pick<
  AutocompleteProps,
  'form' | 'min' | 'max' | 'defaultValues' | 'isMulti'
> & { name?: string; value?: any }) => {
  const { getSkills } = useContext(SearchContext);
  const { errors } = form;
  const label = `${isMulti ? 'Skills' : 'Skill'}${
    max && max > 0 ? ` (max ${max})` : ''
  }`;

  const getResults = (q: string) =>
    getSkills(q).then(({ hits }: any) => {
      return hits.map((hit: any) => {
        return { value: hit.objectID, label: hit.name };
      });
    });

  return (
    <FormGroup name={name} label={label} errors={errors}>
      <Autocomplete
        name={name}
        form={form}
        min={min}
        max={max}
        defaultValues={defaultValues}
        formValue={value}
        callback={getResults}
        isMulti={isMulti}
        isCreatable={false}
        placeholder="Type to search skills..."
      />
    </FormGroup>
  );
};
