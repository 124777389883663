import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import useAnalytics from '../../hooks/useAnalytics';
import useMyWork from '../../hooks/useMyWork';
import useNavigate from '../../hooks/useNavigate';
import {
  formatDateDate,
  fromNow,
  isDatePassed,
} from '../../utilities/dateTime';
import { isMobile } from '../../utilities/MatchMedia';
import { currency } from '../../utilities/Number';
import Button from '../core/Button';
import { LoadingBalls } from '../core/Loading';
import { Link } from '../core/router';
import useWork from '../../hooks/useWork';
import UpgradeJobButton from '../work/UpgradeJobButton';
import useWorkIsPosted from '../../hooks/useWorkIsPosted';
import ViewApplicantsButton from '../work/ViewApplicantsButton';
import useFeatureGate from '../../hooks/useFeatureGate';

const CountItem = ({
  count,
  topRow,
  bottomRow,
}: {
  count: number;
  topRow: string;
  bottomRow: string;
}) => {
  return (
    <div className="flex-col text-center mx-5">
      <div className="h4 font-bold">{`${count}`.padStart(2, '0')}</div>
      <div className="font-bold text-grey-500 text-sm">
        <div>{topRow}</div>
        <div>{bottomRow}</div>
      </div>
    </div>
  );
};

const ListItem = ({ myWork, index }: { myWork: MyWork; index: number }) => {
  const { push } = useHistory();
  const { track } = useAnalytics();
  const {
    work: workObject,
    applicationCounts: counts,
    pageViews: views,
  } = myWork;
  const workId = workObject.id;

  // Get the full work object from the API but if it doesn't exist then use the incomplete one
  const { data: work, loading, refresh: refreshWork } = useWork(workId);
  const { isPosted } = useWorkIsPosted(work);
  const isClosed = isDatePassed(work?.expiresAt);
  const isExternalJob = work?.isExternal || false;

  const handleClick = (e: any) => {
    // if a link was clicked, no need to handle anything more
    if (e.target.tagName === 'A' || e.target.tagName === 'BUTTON') return;

    track('Feed - MyWork Clicked');

    // only mobile should expect the full item to be clickable
    if (isMobile) push(`/work/${workId}`);
  };

  return (
    <div
      onClick={handleClick}
      role="none"
      className={`flex justify-center md:justify-start text-center md:text-left bg-white rounded-xl p-4 md:px-8 flex-wrap md:flex-nowrap ${
        index > 0 ? 'mt-2 pt-4' : ''
      }`}
    >
      {loading ? (
        <LoadingBalls isActive />
      ) : (
        <>
          <div className="truncate mb-2 md:mr-4">
            <div className="whitespace-nowrap my-1 truncate font-bold text-grey-500 text-sm">
              <div>
                {`${work?.role ? work.role?.name : ''}${
                  work?.role && work.price ? ' - ' : ''
                }${work?.price && currency(work.price)}`}
              </div>
            </div>

            <div className="font-bold truncate h5">
              <Link
                to={`/work/${workId}`}
                onClick={() => track('Feed - MyWork Clicked')}
              >
                {work?.title}
              </Link>
            </div>
            <div className="text-xs whitespace-nowrap mt-1 truncate">
              <div className="font-bold text-grey-500 text-sm">
                {`Posted: ${work &&
                  formatDateDate(
                    new Date(
                      moment(work.expiresAt)
                        .subtract(30, 'day')
                        .toISOString()
                    )
                  )} - ${
                  isClosed
                    ? `Closed ${fromNow(work?.expiresAt)}`
                    : `${fromNow(work?.expiresAt, true)} left`
                }`}
              </div>
              {work?.user && (
                <div className="text-xs whitespace-nowrap mt-1 truncate">
                  <div className="font-bold text-grey-500 text-sm">
                    Posted By: {work.user?.firstName} {work.user?.lastName}
                  </div>
                </div>
              )}
              {work?.company && (
                <div className="text-xs whitespace-nowrap mt-1 truncate">
                  <div className="font-bold text-grey-500 text-sm">
                    {work.company.name}
                  </div>
                </div>
              )}
            </div>
          </div>
          {!isPosted ? (
            <div className="flex flex-row grow md:grow-0 pl-auto pr-auto md:ml-auto border-t md:border-t-0 border-grey-100 mt-2 pt-3 md:mt-0 md:pt-0 justify-center items-center">
              <UpgradeJobButton
                work={work}
                loading={loading}
                refreshWork={refreshWork}
                trackingEventPrefix="Feed"
                width="md:w-32"
              />
            </div>
          ) : (
            <div className="flex flex-row md:ml-auto border-t md:border-t-0 border-grey-100 mt-2 pt-2 md:mt-0 items-center">
              <CountItem
                count={views.total}
                topRow="Post"
                bottomRow={pluralize('View', views.total)}
              />
              <CountItem
                count={counts.shortlisted}
                topRow="Shortlisted"
                bottomRow="Candidates"
              />
              <ViewApplicantsButton
                workId={workId}
                trackingEventName="Feed - Work Applicants Clicked"
                applicantCount={counts.total - counts.invited}
                isExternalJob={isExternalJob}
                width="md:w-32"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const List = ({ work }: { work: MyWork[] | null }) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { needsUpgrade } = useFeatureGate();
  const onClick = () => {
    if (!needsUpgrade('canPostWorkToCommuno')) {
      track('Feed - Post New Work Clicked');
      navigate('/work/create');
    }
  };
  return (
    <div className="leading-none h-full flex flex-col gap-4">
      <div className="flex justify-between">
        <h4 className="self-center">My Work</h4>
        <Button
          size="sm"
          color="primary"
          onClick={onClick}
          icon="add"
          text="Create new work post"
        />
      </div>
      {!work || work.length < 1 ? (
        <div className="text-center bg-white rounded-md p-4 md:px-8">
          You haven&apos;t posted any Work yet
        </div>
      ) : (
        work.map((item, i) => {
          // Small hack so we only list 5 work items at a time.
          if (i > 4) {
            return null;
          }
          return <ListItem key={item.work.id} index={i} myWork={item} />;
        })
      )}
    </div>
  );
};

const MyWork = () => {
  const { data, loading, refresh } = useMyWork();

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div>{loading ? <LoadingBalls isActive /> : <List work={data} />}</div>
  );
};

export default React.memo(MyWork);
