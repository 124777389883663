import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import Edit from '../components/perk/PerksEdit';

const PerksEdit = () => {
  useAnalytics('Perks Edit');

  return (
    <Page
      authGuard
      pageTitle="Edit Perk"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
    >
      <Edit />
    </Page>
  );
};
export default PerksEdit;
