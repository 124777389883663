import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import MemberSearchProvider from '../components/membership/search/MemberSearchProvider';

const Members = () => {
  useAnalytics('Members');

  return (
    <Page
      authGuard
      className="bg-grey-100 page--no-padding"
      layout="none"
      pullToRefresh
      permissions="canViewMembershipList"
      pageTitle="Members"
    >
      <div className="py-12">
        <MemberSearchProvider
          breakpoints={{
            540: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 5,
            },
            1920: {
              slidesPerView: 6,
            },
            2240: {
              slidesPerView: 7,
            },
            2560: {
              slidesPerView: 8,
            },
          }}
        />
      </div>
    </Page>
  );
};

export default React.memo(Members);
