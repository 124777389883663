import { IonContent } from '@ionic/react';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { ChatConversationsList } from '.';
import useChatClient from '../components/chat/hooks/useChatClient';
import routes from '../components/chat/routes';
import Header from '../components/core/Header';
import { LoadingBalls } from '../components/core/Loading';
import View from '../components/core/View';

const ChatDesktop = () => {
  const { loaded } = useChatClient();

  return (
    <View className="bg-grey-100" authGuard darkStatusBar pageTitle="Chat">
      {!loaded ? (
        <LoadingBalls isActive fullscreen />
      ) : (
        <>
          <Header />
          <IonContent scrollY={false}>
            <div className="chat-panels page-container flex flex-auto h-full">
              <div className="chat-panels__side w-full max-w-sm">
                <ChatConversationsList />
              </div>
              <div className="chat-panels__main w-full">
                <Route
                  path="/chat"
                  exact
                  render={() => <Redirect to="/chat/new-message" />}
                />
                {routes.map(({ path, component }) => (
                  <Route key={path} path={path} component={component} exact />
                ))}
              </div>
            </div>
          </IonContent>
        </>
      )}
    </View>
  );
};

export default ChatDesktop;
