import React from 'react';
import validator from 'validator';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="phoneNumber"
    label="Enter phone number"
    placeholder="Phone number"
    errors={errors}
    ref={register({
      // required: 'Phone Number is required',
      validate: (value: string) =>
        validator.isEmpty(value) ||
        validator.isMobilePhone(value) ||
        'Invalid Phone Number',
    })}
  />
);
