import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.min.css';

FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginFileEncode,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize
);

const defaultConfig = {
  maxFiles: 1,
  maxFileSize: '20MB',
  allowFileEncode: true,
  allowImageTransform: true,
  imageCropAspectRatio: '1:1',
  allowImageResize: true,
  imageEditInstantEdit: true,
  imageTransformImageFilter: (file: any) => {
    // @src: https://stackoverflow.com/questions/58874156/make-filepond-image-transform-plugin-ignore-animated-gifs
    // eslint-disable-next-line consistent-return
    return new Promise(resolve => {
      // no gif mimetype, do transform
      if (!/image\/gif/.test(file.type)) return resolve(true);

      const reader = new FileReader();
      reader.onload = () => {
        // @ts-ignore
        const arr = new Uint8Array(reader.result);
        const length = arr.length - 9;
        let frames = 0;

        // make sure it's a gif (GIF8)
        if (
          arr[0] !== 0x47 ||
          arr[1] !== 0x49 ||
          arr[2] !== 0x46 ||
          arr[3] !== 0x38
        ) {
          // it's not a gif, we can safely transform it
          resolve(true);
          return;
        }

        for (let i = 0; i < length && frames < 2; i += 1) {
          if (
            arr[i] === 0x00 &&
            arr[i + 1] === 0x21 &&
            arr[i + 2] === 0xf9 &&
            arr[i + 3] === 0x04 &&
            arr[i + 8] === 0x00 &&
            (arr[i + 9] === 0x2c || arr[i + 9] === 0x21)
          ) {
            frames += 1;
          }
        }

        // if frame count > 1, it's animated, don't transform
        if (frames > 1) {
          resolve(false);
          return;
        }

        // do transform
        resolve(true);
      };
      reader.readAsArrayBuffer(file);
    });
  },
};

function createFilePond(ref: HTMLElement, config = {}) {
  return FilePond.create(ref, {
    ...defaultConfig,
    ...config,
  });
}

export default {
  createFilePond,
};
