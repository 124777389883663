import React from 'react';
import { FormGroup } from '../../core/form';
import { vettingStatusOptions } from '../../../helpers/vettingStatuses';

export default ({ errors, register }: any) => (
  <FormGroup label="Vetting Status" name="vettingStatus" errors={errors}>
    <select
      id="vettingStatus"
      name="vettingStatus"
      className="form-input"
      ref={register}
    >
      {vettingStatusOptions.map((item: any) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  </FormGroup>
);
