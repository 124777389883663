import { PlanType, SearchIndexes } from '@communo-corp/shared-types';
import { getSearchIndex, buildAlgoliaParams } from '../../../utilities/Algolia';
import { isMobile } from '../../../utilities/Device';

const resultsPerPage = isMobile ? 24 : 96;

export function addUserQueries({ filterOptions, state, queries, plan }: any) {
  const defaultFilters = ['isVisible=1'];

  if (plan?.type === 'student') {
    // Current user is a student, can only see other students
    defaultFilters.push('primaryMembershipUser.membership.plan.type:student');
  } else {
    // Not a student, can see everyone but students
    defaultFilters.push(
      'primaryMembershipUser.membership.plan.type:individual OR primaryMembershipUser.membership.plan.type:agency'
    );
  }

  // User searches
  function buildUserQuery(
    excludeFilter: string | null,
    restrictFacets: string[] = []
  ) {
    return {
      indexName: getSearchIndex(SearchIndexes.User),
      query: state.filters?.query || '',
      params: buildAlgoliaParams({
        defaultFilters,
        filterOptions,
        filterValues: state.filters,
        hitsPerPage: state.filters?.type === 'people' ? resultsPerPage : 24,
        page: state.page,
        excludeFilter,
        facets: restrictFacets.length
          ? restrictFacets
          : [
              'roles.role.name',
              'skills.skill.name',
              'primaryMembershipUser.membership.plan.type',
              'primaryMembershipUser.membership.plan.isIndividualPlan',
              'industries.industry.name',
            ],
      }),
    };
  }

  const searchingUsers =
    state.filters?.type === 'people' || !state.filters?.type;

  queries['user-search'] = buildUserQuery(null);

  if (searchingUsers && state.filters.freelancer) {
    queries['user-freelancer'] = buildUserQuery('freelancer', [
      'primaryMembershipUser.membership.plan.type',
    ]);
  }
  if (searchingUsers && state.filters.role) {
    queries['user-role'] = buildUserQuery('role', ['roles.role.name']);
  }
  if (searchingUsers && state.filters.skills) {
    queries['user-skills'] = buildUserQuery(null, ['skills.skill.name']);
  }
  if (searchingUsers && state.filters.isIndividualPlan) {
    queries['user-isIndividualPlan'] = buildUserQuery('isIndividualPlan', [
      'primaryMembershipUser.membership.plan.isIndividualPlan',
    ]);
  }
  if (searchingUsers && state.filters.industry) {
    queries['user-industry'] = buildUserQuery('industry', [
      'industries.industry.name',
    ]);
  }
}

export function addMembershipQueries({
  filterOptions,
  state,
  queries,
  plan,
}: any) {
  if (plan?.type === PlanType.STUDENT) {
    return;
  }

  const membershipQueryParams = {
    filterOptions,
    filterValues: state.filters,
    hitsPerPage: state.filters?.type === 'agency' ? resultsPerPage : 24,
    page: state.page,
    facets: [
      'roles.role.name',
      'skills.skill.name',
      'plan.type',
      'industries.industry.name',
    ],
  };

  function buildMembershipQuery(
    excludeFilter: string | null,
    restrictFacets: string[] = []
  ) {
    return {
      indexName: getSearchIndex(SearchIndexes.Membership),
      query: state.filters?.query || '',
      params: buildAlgoliaParams({
        ...membershipQueryParams,
        excludeFilter,
        defaultFilters: ['plan.type:agency', 'isVisible=1'],
        facets: restrictFacets.length
          ? restrictFacets
          : membershipQueryParams.facets,
      }),
    };
  }

  const searchingMembers =
    state.filters?.type === 'agency' || !state.filters?.type;

  queries['membership-search'] = buildMembershipQuery(null);

  if (searchingMembers && state.filters.role) {
    queries['membership-role'] = buildMembershipQuery('role', [
      'roles.role.name',
    ]);
  }
  if (searchingMembers && state.filters.skills) {
    queries['membership-skills'] = buildMembershipQuery('skills', [
      'skills.skill.name',
    ]);
  }
  if (searchingMembers && state.filters.industry) {
    queries['membership-industry'] = buildMembershipQuery('industry', [
      'industries.industry.name',
    ]);
  }
}

export function addBrandQueries({ filterOptions, state, queries, plan }: any) {
  if (plan?.type === PlanType.STUDENT) {
    return;
  }

  const brandQueryParams = {
    filterOptions,
    filterValues: state.filters,
    hitsPerPage: state.filters?.type === 'brand' ? resultsPerPage : 24,
    page: state.page,
    facets: [
      'roles.role.name',
      'skills.skill.name',
      'primaryMembershipUser.membership.plan.type',
      'industries.industry.name',
    ],
  };

  function buildBrandQuery(
    excludeFilter: string | null,
    restrictFacets: string[] = []
  ) {
    return {
      indexName: getSearchIndex(SearchIndexes.Membership),
      query: state.filters?.query || '',
      params: buildAlgoliaParams({
        ...brandQueryParams,
        excludeFilter,
        defaultFilters: ['plan.type:brand', 'isVisible=1'],
        facets: restrictFacets.length
          ? restrictFacets
          : brandQueryParams.facets,
      }),
    };
  }

  queries['brand-search'] = buildBrandQuery(null);
}
