import React, { useEffect, useRef } from 'react';
import {
  /* eslint-disable-next-line no-unused-vars */
  Props as ReactSelectProps,
  /* eslint-disable-next-line no-unused-vars */
  OptionsType,
} from 'react-select';

import { FilterOptionComponentProps } from '../index.d';
import URLFilterOption from './URLFilterOption';
import Select from '../../select/Select';

type FilterValueType = string | string[] | null;

type SelectFilterComponentProps = FilterOptionComponentProps<FilterValueType> &
  ReactSelectProps;

const SelectFilterComponent: React.FC<SelectFilterComponentProps> = ({
  name,
  sectionTitle,
  showTitle,
  onChange,
  options = [],
  value,
  placeholder,
  valueFormatter,
  defaultMenuIsOpen = true,
  isMulti = false,
  loading = false,
  ...rest
}: SelectFilterComponentProps) => {
  const ref = useRef<any>();

  const handleChange = (val: any) => {
    if (isMulti) {
      const result = Array.isArray(val) ? val.map((opt: any) => opt.value) : [];
      onChange(name, result?.length > 0 ? result : null);
      return;
    }

    const result = val?.value;
    onChange(name, result ?? null);
  };

  let selectedValue = null;
  if (value) {
    selectedValue =
      isMulti && Array.isArray(value)
        ? value.map(valueFormatter)
        : valueFormatter(value);
  }

  useEffect(() => {
    if (defaultMenuIsOpen) {
      setTimeout(() => {
        if (ref.current) ref.current.focus();
      }, 500);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section className="filter-group">
      {showTitle ? (
        <h5 className="filter-group__title">{sectionTitle}</h5>
      ) : null}
      <div className="filter-group__item">
        <Select
          ref={ref}
          closeMenuOnSelect
          defaultOptions
          isLoading={loading}
          isMulti={isMulti}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          value={selectedValue as any}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </div>
    </section>
  );
};

class SelectFilterOption extends URLFilterOption<FilterValueType>
  implements ReactSelectProps {
  options?: OptionsType<any>;

  isMulti?: boolean;

  valueFormatter?: (
    val: string
  ) => {
    value: string;
    label: string;
  };

  constructor(
    props: Omit<
      URLFilterOption<FilterValueType>,
      'component' | 'fromURL' | 'toURL'
    > &
      ReactSelectProps
  ) {
    super({ ...props, component: SelectFilterComponent });

    this.options = props.options;
    this.isMulti = props.isMulti ?? false;

    function defaultFormatter(val: string) {
      return {
        value: val,
        label: val,
      };
    }
    this.valueFormatter = props.valueFormatter ?? defaultFormatter;

    Object.assign(this, props);
  }

  /**
   * Convert URL params to value
   * @param params URL params
   */
  fromURL(params: {
    [key: string]: any;
  }): { [key: string]: FilterValueType | undefined } {
    const { name } = this;

    const val = params[name];

    if (val && this.isMulti) {
      return {
        [name]: String(val).split(','),
      };
    }

    return {
      [name]: val,
    };
  }

  /**
   * Convert value to URL params
   * @param value
   */
  // eslint-disable-next-line class-methods-use-this
  toURL(filterValue?: FilterValueType): { [key: string]: string | undefined } {
    if (Array.isArray(filterValue)) {
      return {
        [this.name]: filterValue.join(','),
      };
    }
    return {
      [this.name]: filterValue ?? undefined,
    };
  }
}

export default SelectFilterOption;
