import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput, FormTextarea } from '../core/form';
import { SubmitButton, Button } from '../core/Button';
import channelApi from './Api';
import { TwilioConversationChannelAttributes } from '../../@types/chat.d';

type CreateChannelFields = Omit<
  TwilioConversationChannelAttributes,
  'senderUserId' | 'visibility' | 'id'
>;

type CreateChannelProps = { onComplete: Function; onCancel: () => void };

const CreateChannelForm = ({ onComplete, onCancel }: CreateChannelProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<CreateChannelFields>({
    defaultValues: {
      description: '',
      title: '',
    },
  });
  const { handleSubmit, register, errors, reset } = form;

  const maxLength = 140;

  const onSubmit = async (values: CreateChannelFields) => {
    setIsLoading(true);

    await channelApi.create(values);

    reset();
    onComplete();
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2 className="mb-6">I would like to start a conversation about...</h2>
      <p className="mb-4">
        Channels work best when organized around a topic. Give your channel a
        clear name so members can easily understand the purpose of the chat.
      </p>
      <FormInput
        className="mb-8"
        name="title"
        label="Name your channel (max of 30 characters)"
        placeholder="e.g. Lets talk Freelancing"
        maxLength="30"
        errors={errors}
        ref={register({
          required: 'This is required',
          maxLength: { value: 30, message: '30 characters max' },
        })}
      />
      <FormTextarea
        className="mb-8"
        name="description"
        label="Send your first message to the channel"
        placeholder="Get the conversation started."
        maxLength={maxLength}
        errors={errors}
        ref={register({
          required: 'Description is required',
          maxLength: {
            value: maxLength,
            message: `Max ${maxLength} characters`,
          },
        })}
        rows={5}
        characterCount={maxLength}
        form={form}
      />

      <div className="flex">
        <SubmitButton text="Create channel" loading={isLoading} />
        <Button
          fill="outline"
          className="ml-2"
          color="primary"
          onClick={onCancel}
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default CreateChannelForm;
