import React, { useCallback, useContext, useEffect } from 'react';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import RichText from '../../core/RichText';
import useStep from '../../stepper/hooks/useStep';
import Api from '../Api';
import { SubmitRecommendationContext } from '../context/SubmitRecommendationContext';

const RecommendationPreviewStep = () => {
  const { form, recommendation, submissionToken } = useContext(
    SubmitRecommendationContext
  );
  const { setOnNext, goToNextStep, setLoading } = useStep();

  const {
    firstName,
    lastName,
    email,
    school,
    organization,
    department,
    title,
    body,
  } = form.watch();

  const submitForm = useCallback(() => {
    setLoading(true);

    Api.submitRecommendation(recommendation.id, {
      ...form.getValues(),
      submissionToken,
    })
      .then(() => {
        addSuccess('Recommendation submitted!');
        goToNextStep();
      })
      .catch(err => {
        addResponseError(err);
        setLoading(false);
      });

    return false;
  }, [form, goToNextStep, recommendation.id, setLoading, submissionToken]);

  useEffect(() => {
    setOnNext(submitForm);
  }, [setOnNext, submitForm]);

  return (
    <div>
      <h6>
        {firstName} {lastName}
      </h6>
      <p className="font-bold text-red-1100 mt-1">{email}</p>
      <p className="font-bold mt-1">{school?.label ?? organization}</p>
      <p className="mt-1">
        {title}
        {department ? `, ${department}` : null}
      </p>

      <div className="mt-8">{body ? <RichText html={body} /> : null}</div>
    </div>
  );
};
export default RecommendationPreviewStep;
