import { memoize, sortBy } from 'lodash';
import { IChannel } from '../../../@types/chat.d';

type KeyedChannels = {
  [sid: string]: IChannel;
};

const sortChannels = (channels: IChannel[] | KeyedChannels): IChannel[] =>
  // @ts-ignore
  sortBy(
    channels,
    (channel: IChannel) =>
      channel.lastMessage?.dateCreated ?? channel.dateCreated
  ).reverse();

export default memoize(sortChannels);
