import classnames from 'classnames';
import React, {
  // eslint-disable-next-line no-unused-vars
  ReactChild,
  useEffect,
} from 'react';
import { animated, useTransition } from 'react-spring';
import { page } from '../../analytics';

const Pane = ({
  isActive,
  name,
  transition = 'slideFromLeft',
  className,
  config = {
    mass: 1,
    tension: 450,
    friction: 40,
  },
  children,
}: {
  isActive: boolean;
  name: string;
  transition?: string;
  config?: object;
  children: ReactChild;
  mobileClass?: string;
  className?: string;
}) => {
  const isDesktop = window.matchMedia('(min-width: 992px)').matches;

  const transitionTypes = {
    slideFromLeft: useTransition(isActive, null, {
      from: isDesktop
        ? { transform: `translateX(-100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateX(-100%)` },
      enter: isDesktop
        ? { transform: `translateX(0) scale(1)`, opacity: 1 }
        : { transform: `translateX(0)` },
      leave: isDesktop
        ? { transform: `translateX(-100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateX(-100%)` },
      config,
    }),
    slideFromRight: useTransition(isActive, null, {
      from: isDesktop
        ? { transform: `translateX(100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateX(100%)` },
      enter: isDesktop
        ? { transform: `translateX(0) scale(1)`, opacity: 1 }
        : { transform: `translateX(0)` },
      leave: isDesktop
        ? { transform: `translateX(100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateX(100%)` },
      config,
    }),
    slideFromBottom: useTransition(isActive, null, {
      from: isDesktop
        ? { transform: `translateY(100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateY(100%)` },
      enter: isDesktop
        ? { transform: `translateY(0) scale(1)`, opacity: 1 }
        : { transform: `translateY(0)` },
      leave: isDesktop
        ? { transform: `translateY(100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateY(100%)` },
      config,
    }),
    slideFromTop: useTransition(isActive, null, {
      from: isDesktop
        ? { transform: `translateY(-100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateY(-100%)` },
      enter: isDesktop
        ? { transform: `translateY(0) scale(1)`, opacity: 1 }
        : { transform: `translateY(0)` },
      leave: isDesktop
        ? { transform: `translateY(-100%) scale(0.75)`, opacity: 0 }
        : { transform: `translateY(-100%)` },
      config,
    }),
  };

  // TODO: Fix typescript once React Spring has proper support
  // @ts-ignore
  const effect = transitionTypes[transition];

  useEffect(() => {
    if (isActive) page('Channels', { view: name });
  }, [isActive, name]);

  return (
    <>
      {effect.map(
        // TODO: Fix typescript once React Spring has proper support
        // @ts-ignore
        ({ item, key, props }) =>
          item && (
            <animated.div
              className={classnames(
                className,
                'fixed bg-white z-40 px-2 md:px-8 w-full h-full left-0 top-0 pointer-events-none',
                {
                  'pointer-events-auto overflow-auto': isActive,
                }
              )}
              key={key}
              style={props}
            >
              <div className="mx-auto lg:px-4 max-w-xl h-full">{children}</div>
            </animated.div>
          )
      )}
    </>
  );
};

export default Pane;
