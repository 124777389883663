import React from 'react';
import { FormTextarea } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormTextarea
    name="description"
    label="Project description"
    placeholder="Enter a brief project description."
    errors={errors}
    ref={register({
      required: 'Description is required',
    })}
  />
);
