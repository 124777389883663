import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormActions, FormTextarea } from '../../core/form';
import Button from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Api from '../Api';
import RichText from '../../core/RichText';
import ApplicationLimitNotice from '../ApplicationLimitNotice';
import Alert from '../../alert/Alert';
import RequestReferenceModal from '../../reference/RequestReferenceModal';
import useReferencesForUser from '../../reference/hooks/useReferencesForUser';
import useCurrentUser from '../../../hooks/useCurrentUser';

interface Props {
  work: WorkEntity;
  qualification: QualificationEntity;
  refreshQualification: Function;
  isIndividualPlan: boolean;
  isStudent: boolean;
}

const ApplicationForm = ({
  work,
  qualification,
  refreshQualification,
  isIndividualPlan,
  isStudent,
  user,
}: Props & { user: UserEntity }) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [showReferencesModal, setShowReferencesModal] = useState(false);
  const [showReferencesWarning, setShowReferencesWarning] = useState(false);
  const placeholderText = isStudent
    ? 'Your profile is your application, so please be sure to complete it in full. Use this area as your personal space to express your creativity, showcase your personality and why you are uniquely made for this opportunity.'
    : 'Your profile is your application, ensure it is completed. Use this area as an opportunity to showcase your personality and unique fit for this opportunity.';

  // TODO: This is just a Bandaid for now until the backend can include it in the score
  const { response } = useReferencesForUser(user.id);
  const references = response?.references ?? [];
  const hasReferences = useMemo(() => {
    const visibleReferences = references.filter(
      reference => reference.visibility
    );
    return visibleReferences.length > 0;
  }, [references]);

  // const { recommendations } = useUserRecommendations(user.id);
  // const hasRecommendations = recommendations && recommendations.length > 0;
  // const studentRecommendations = hasRecommendations ? recommendations : [];
  // =======

  const form = useForm({
    shouldFocusError: true,
  });
  const { handleSubmit, register, errors, watch, trigger } = form;
  const maxLength = 700;
  const name = 'body';
  const body = watch(name);
  const label = `Let us know why you're a good fit for this opportunity (max ${maxLength} characters)`;

  const hasApplicationLimit = qualification.applicationCreditBalance !== null;
  const hasApplicationCredit =
    qualification.applicationCreditBalance === null ||
    qualification.applicationCreditBalance > 0;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = {
      body: values.body?.html || values.body || '',
    };

    Api.apply(work.id, data)
      .then(() => {
        refreshQualification();
        addSuccess('Your application has been successfully submitted!');
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreview = (show: boolean) => {
    trigger('body[text]').then(() => {
      setPreview(show ? !errors?.[name] : false);
    });
  };

  useEffect(() => {
    if (errors?.[name]?.text) setPreview(false);
  }, [errors]);

  return (
    <div>
      {hasApplicationLimit ? (
        <ApplicationLimitNotice qualification={qualification} />
      ) : null}

      {hasApplicationCredit ? (
        <>
          <h5 className="mb-6">Apply Now</h5>

          {isStudent ? (
            <div className="content mb-4">
              <p>
                Please use the space below to tell us what “Black Joy” means to
                you and how you imagine it coming to life in the next great
                film. Feel free to augment this overview with any relevant
                creative work in your portfolio.
              </p>
            </div>
          ) : null}

          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="flex flex-col grow w-full"
          >
            <div className={preview ? 'hidden' : ''}>
              <FormTextarea
                name={name}
                label={isStudent ? undefined : label}
                placeholder={placeholderText}
                errors={errors}
                ref={register({
                  required: 'This is required',
                  maxLength: {
                    value: maxLength,
                    message: `Max ${maxLength} characters`,
                  },
                })}
                maxLength={maxLength}
                characterCount={maxLength}
                richText
                form={form}
                // className="flex flex-col grow"
                // inputClasses="flex flex-col grow"
              />
            </div>
            {preview && (
              <div className="mb-8">
                <div className="label">Application Preview</div>
                <RichText
                  html={body?.html || body || ''}
                  classVariant="ql-editor"
                />
              </div>
            )}
            <FormActions className="">
              <div className="ml-auto">
                <Button
                  text={preview ? 'Edit' : 'Preview'}
                  type="button"
                  className="btn--outline btn--primary"
                  disabled={!body}
                  onClick={() => handlePreview(!preview)}
                />
                {preview && (
                  <Button
                    text="Apply Now"
                    color="primary"
                    fill="solid"
                    className="ml-2 whitespace-nowrap"
                    disabled={!body}
                    loading={loading}
                    onClick={() => {
                      if (hasReferences || !isIndividualPlan || isStudent) {
                        handleSubmit(onSubmit)();
                      } else {
                        setShowReferencesWarning(true);
                      }
                    }}
                  />
                )}
              </div>
            </FormActions>
          </form>
        </>
      ) : null}

      <RequestReferenceModal
        isOpen={showReferencesModal}
        onClose={() => {
          setShowReferencesModal(false);
        }}
      />

      <Alert
        isOpen={showReferencesWarning && !isStudent}
        header="You are missing references."
        message="Talent with a minimum of three references on their profile are more likely to get shortlisted. Add references to your profile to increase your chances and receive your Verified badge."
        onDidDismiss={() => {}}
        buttons={[
          {
            text: 'Get References',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: () => {
              setShowReferencesWarning(false);
              setShowReferencesModal(true);
            },
          },
          {
            text: 'Apply',
            cssClass: 'alert-primary-btn',
            handler: () => {
              setShowReferencesWarning(false);
              handleSubmit(onSubmit)();
            },
          },
        ]}
      />
    </div>
  );
};

const WrappedApplicationForm = (props: Props) => {
  const { user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return <ApplicationForm user={user} {...props} />;
};

export default WrappedApplicationForm;
