import React, { useCallback, useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import { addResponseError } from '../../../../services/Messaging';
import useStep from '../../../stepper/hooks/useStep';
import UserContext from '../../../user/Context';
import Video from '../../../user/formElements/Video';
import { canPlayVideo } from '../../../video/VideoPlayer';

const VideoStep = () => {
  const { user, update: updateuser } = useContext(UserContext);
  const {
    setNextButtonLabel,
    setCanAdvance,
    isLoading,
    setLoading,
    setOnNext,
    goToNextStep,
  } = useStep();
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      videoUrl: user?.videoUrl || null,
    },
  });
  const { handleSubmit, formState, watch } = form;
  const videoUrl: string | null = watch('videoUrl');
  const { isDirty } = formState;
  // This is needed because for some reason if the user clears the input, isDirty still remains true.
  const inputHasValue = !!(videoUrl && videoUrl.length > 0);
  const isValidVideoUrl = videoUrl && canPlayVideo(videoUrl);
  const buttonLabel = isDirty && inputHasValue ? 'Continue' : 'Skip';
  const canAdvance = !!(
    !isDirty ||
    !inputHasValue ||
    (inputHasValue && isValidVideoUrl)
  );

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - Video Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) return;

      setLoading(true);

      updateuser(values)
        .then(() => {
          // reset({

          // })

          setLoading(false);
          goToNextStep();
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          addResponseError(error);
        });
    },
    [goToNextStep, isLoading, setLoading, updateuser]
  );

  useEffect(() => {
    setCanAdvance(canAdvance);
  }, [canAdvance, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (inputHasValue && isValidVideoUrl) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, inputHasValue, isValidVideoUrl, onSubmit, setOnNext]);

  useEffect(() => {
    setNextButtonLabel(buttonLabel);
  }, [buttonLabel, setNextButtonLabel]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Video form={form} />
    </form>
  );
};
export default VideoStep;
