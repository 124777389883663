import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

const RedactionLevel = ({ errors, register }: any) => {
  const ref = register({ required: 'Sharing is required' });
  return (
    <FormGroup
      required
      name="redactionLevel"
      label="Should this post be redacted when shared outside of the platform?"
      errors={errors}
    >
      <div className="radio-inline">
        <FormRadio name="redactionLevel" label="No" value="0" ref={ref} />
        <FormRadio name="redactionLevel" label="Yes" value="1" ref={ref} />
      </div>
    </FormGroup>
  );
};
export default RedactionLevel;
