import { Elements, RecurlyProvider, useRecurly } from '@recurly/react-recurly';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingRing } from '../../core/Loading';
import useBillingInfo from '../hooks/useBillingInfo';
import usePlanBySlug from '../hooks/usePlanBySlug';
import UpgradePaymentForm from './UpgradePaymentForm';
import environment from '../../../environment';
import Api from '../Api';
import { parseResponseError, addSuccess } from '../../../services/Messaging';
import BillingInfoSummary from './BillingInfoSummary';
import UpgradePricingPreview from './UpgradePricingPreview';
import useRecurlyCoupon from '../hooks/useRecurlyCoupon';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import useStep from '../../stepper/hooks/useStep';
import ErrorBanner from '../../banner/ErrorBanner';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import useRecurlyItem from '../hooks/useRecurlyItem';
import UpgradePayToPostPreview from './UpgradePayToPostPreview';

const { recurlyPublicKey } = environment;
type Props = {
  planSlug: string;
  coupon?: string;
  purchasableItem?: string;
};

const UpgradePlan = ({ planSlug, coupon, purchasableItem }: Props) => {
  const {
    setOnNext,
    goToNextStep,
    setCanAdvance,
    setLoading,
    setNextButtonLabel,
  } = useStep();

  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [state, setState] = useState<{
    recurlyError: string | null;
    error: string | null;
  }>({
    recurlyError: null,
    error: null,
  });
  const formRef = useRef<any>();
  const recurly = useRecurly();

  const currentPlan = useCurrentPlan();
  const { membership } = useCurrentMembership();

  const {
    data: billingInfo,
    isFetching: loadingBillingInfo,
  } = useBillingInfo();

  useEffect(() => {
    if (billingInfo) {
      setShowPaymentForm(false);
    }
  }, [billingInfo]);

  const { plan, isFetching: loadingPlan } = usePlanBySlug(planSlug);
  const recurlyPlan = plan?.recurlyPlans[0];
  const recurlyPlanCode = recurlyPlan?.recurlyCode;
  // If no recurly plan code, then use the communo plan slug
  const planCode = (recurlyPlanCode || plan?.slug) ?? '';

  const { data: couponData, isLoading: couponLoading } = useRecurlyCoupon(
    planCode,
    coupon
  );
  const loadedCoupon = !coupon || !couponLoading;

  const { data: purchaseableItemData } = useRecurlyItem(
    planCode,
    purchasableItem
  );
  const purchaseableItemCode = purchaseableItemData?.code;

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { trigger } = form;

  const createPaymentToken = useCallback(() => {
    return new Promise((res, rej) => {
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          rej(err);
        } else {
          res(token.id);
        }
      });
    });
    // the useRecurly hook returns a new instance of the recurly object each render
    // which means we can't declare it as a dependency
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(async () => {
    // if (!recurlyPlan) {
    //   return;
    // }

    setState({
      recurlyError: null,
      error: null,
    });

    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    setLoading(true);

    let tokenId: any;

    if (showPaymentForm) {
      try {
        tokenId = await createPaymentToken();
        if (!tokenId) {
          return;
        }
      } catch (err) {
        setLoading(false);
        setState({
          recurlyError: err.message,
          error:
            parseResponseError(err) ??
            'An error occurred while validating your billing info.',
        });
        return;
      }
    }

    Api.upgradePlan({
      planCode,
      paymentTokenId: tokenId,
      coupon: couponData ? coupon : undefined,
      items: purchaseableItemCode ? [purchaseableItemCode] : undefined,
    })
      .then(() => {
        addSuccess('Plan upgraded!');
        goToNextStep();
      })
      .catch((err: any) => {
        setLoading(false);
        setState({
          error:
            parseResponseError(err) ??
            'An error occurred while processing the payment.',
          recurlyError: null,
        });
      });
  }, [
    trigger,
    setLoading,
    showPaymentForm,
    planCode,
    couponData,
    coupon,
    purchaseableItemCode,
    createPaymentToken,
    goToNextStep,
  ]);

  useEffect(() => {
    setOnNext(() => {
      onSubmit();
      return false;
    });
  }, [setOnNext, onSubmit]);

  useEffect(() => {
    setCanAdvance(true);
  }, [setCanAdvance]);

  useEffect(() => {
    setNextButtonLabel(purchasableItem ? 'Purchase' : 'Upgrade');
  }, [purchasableItem, setNextButtonLabel]);

  if (loadingBillingInfo || loadingPlan) {
    return (
      <div className="py-8 text-center">
        <LoadingRing isActive color="primary" size="xl" />
      </div>
    );
  }

  // if (!recurlyPlan) {
  //   return null;
  // }

  if (
    currentPlan?.id === plan?.id &&
    !membership?.trialExpiresAt &&
    !purchasableItem
  ) {
    return (
      <div className="text-left">
        You are already on the <strong>{plan?.name}</strong> plan. No need to
        upgrade.
      </div>
    );
  }

  return (
    <form ref={formRef} className="text-left">
      {showPaymentForm ? (
        <UpgradePaymentForm form={form} recurlyError={state.recurlyError} />
      ) : (
        <BillingInfoSummary
          billingInfo={billingInfo}
          setShowPaymentForm={setShowPaymentForm}
        />
      )}

      {state.error ? (
        <ErrorBanner message={state.error} title="Error processing payment" />
      ) : null}

      <hr className="border-t-2 border-grey-200 mt-8" />

      {/* We are trying to purchase a pay-to-post item */}
      {purchaseableItemData ? (
        <UpgradePayToPostPreview item={purchaseableItemData} />
      ) : null}

      {recurlyPlan && loadedCoupon && !purchasableItem ? (
        <UpgradePricingPreview
          planName={plan?.name}
          recurlyPlan={recurlyPlan}
          coupon={couponData}
        />
      ) : null}

      <hr className="border-t-2 border-grey-200 mt-1" />

      <p className="text-xs text-subdued text-center mt-6">
        All prices shown in USD
      </p>
    </form>
  );
};

const WrappedUpgradePlan = (props: Props) => {
  return (
    <RecurlyProvider publicKey={recurlyPublicKey}>
      <Elements>
        <UpgradePlan {...props} />
      </Elements>
    </RecurlyProvider>
  );
};

export default WrappedUpgradePlan;
