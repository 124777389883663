import React from 'react';
import moment from 'moment';
import useCurrentUser from '../../hooks/useCurrentUser';

interface Props {
  user: UserEntity;
}

interface ViewProps {
  label?: string;
  children: JSX.Element;
}

const now = new Date().getTime();

const View = ({ children, label = 'FROM YOUR TIME' }: ViewProps) => {
  return (
    <div className="text-center">
      <div className="text-xl font-semibold">{children}</div>
      <div className="font-medium text-[0.625rem] whitespace-nowrap">
        {label}
      </div>
    </div>
  );
};

const OffsetDisplay = ({ offset }: { offset: number }) => {
  const displayText = offset < 0 ? offset : `+${offset}`;

  if (offset === 0) {
    return (
      <View label="SAME TIME ZONE">
        <div>=</div>
      </View>
    );
  }

  return (
    <View>
      <div>
        {displayText} <span className="text-base">hrs</span>
      </div>
    </View>
  );
};

const ProfileTimeDifference = ({ user }: Props) => {
  const { user: authUser } = useCurrentUser();
  let tzOffset: number | null = null;

  if (user.timezone && authUser?.timezone) {
    const userOffset = moment.tz.zone(authUser.timezone)?.utcOffset(now);
    const profileOffset = moment.tz.zone(user.timezone)?.utcOffset(now);

    tzOffset =
      userOffset && profileOffset ? (userOffset - profileOffset) / 60 : null;
  }

  return (
    <>
      {tzOffset !== null ? (
        <OffsetDisplay offset={tzOffset} />
      ) : (
        <View>
          <div>&mdash;</div>
        </View>
      )}
    </>
  );
};
export default ProfileTimeDifference;
