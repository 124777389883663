import React from 'react';

const OneClickApplyIcon = React.memo(
  ({ className }: { className?: string }) => {
    return (
      <svg
        viewBox="0 0 48 49"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={className ?? ''}
      >
        <g clipPath="url(#clip0)">
          <path d="M40.5009 26.4274V25.9571C40.5009 23.5552 38.5469 21.6011 36.145 21.6011H29.8166C28.4486 21.6011 27.3357 20.4882 27.3357 19.1202V16.625C27.3357 16.1073 26.9159 15.6875 26.3982 15.6875C25.8805 15.6875 25.4607 16.1073 25.4607 16.625V19.1202C25.4607 21.5221 27.4148 23.4761 29.8166 23.4761H36.145C37.513 23.4761 38.6259 24.5891 38.6259 25.9571V26.4274C34.4131 26.8953 31.1259 30.477 31.1259 34.8124V40.0624C31.1259 40.2515 31.1328 40.4389 31.1452 40.6249H10.126V35.8906C10.126 33.952 8.54892 32.3749 6.61036 32.3749H1.876V3.31249C1.876 2.79556 2.29656 2.375 2.81349 2.375H31.3134C31.8304 2.375 32.2509 2.79556 32.2509 3.31249V18.0389C32.2509 18.5566 32.6707 18.9764 33.1884 18.9764C33.7061 18.9764 34.1259 18.5566 34.1259 18.0389V3.31249C34.1259 1.76169 32.8643 0.5 31.3134 0.5H2.81349C1.26268 0.5 0.000999451 1.76169 0.000999451 3.31249V33.3124C0.00174945 33.3121 0.0025932 33.3119 0.00324945 33.3115C0.00306195 33.5557 0.0960618 33.7959 0.275499 33.9754L8.52548 42.2254C8.70483 42.4048 8.94445 42.4999 9.18839 42.5H31.4853C32.5337 45.9677 35.7581 48.5 39.5633 48.5C44.2158 48.5 48.0008 44.7149 48.0008 40.0625V34.8125C48.0009 30.4769 44.7138 26.8952 40.5009 26.4274ZM8.25098 39.299L3.2019 34.2499H6.61036C7.51495 34.2499 8.25098 34.986 8.25098 35.8906V39.299ZM46.1259 40.0624C46.1259 43.681 43.182 46.6249 39.5634 46.6249C35.9449 46.6249 33.0009 43.681 33.0009 40.0624V38.3749H46.1259V40.0624ZM46.1259 36.4999H40.5009V34.5312C40.5009 34.0135 40.0811 33.5937 39.5634 33.5937C39.0457 33.5937 38.6259 34.0135 38.6259 34.5312V36.4999H33.0009V34.8124C33.0009 31.1939 35.9449 28.2499 39.5634 28.2499C43.182 28.2499 46.1259 31.1939 46.1259 34.8124V36.4999Z" />
          <path d="M40.2253 30.6397C40.051 30.4653 39.8091 30.3649 39.5626 30.3649C39.316 30.3649 39.0741 30.4652 38.8997 30.6397C38.7254 30.814 38.6251 31.0559 38.6251 31.3024C38.6251 31.549 38.7253 31.7909 38.8997 31.9652C39.0742 32.1396 39.316 32.2399 39.5626 32.2399C39.8091 32.2399 40.051 32.1397 40.2253 31.9652C40.3996 31.7909 40.5 31.549 40.5 31.3024C40.5 31.0559 40.3997 30.814 40.2253 30.6397Z" />
          <path d="M18.375 5.5744H6.00007C5.48238 5.5744 5.06257 5.99421 5.06257 6.5119V24.5119C5.06257 25.0295 5.48238 25.4494 6.00007 25.4494H18.375C18.8927 25.4494 19.3125 25.0295 19.3125 24.5119V6.5119C19.3125 5.99421 18.8927 5.5744 18.375 5.5744ZM17.4375 13.5178L15.8661 11.9464C15.5 11.5805 14.9066 11.5803 14.5402 11.9464L11.9933 14.4932C11.6272 14.8593 11.6272 15.4529 11.9933 15.8191C12.3595 16.1851 12.9529 16.1852 13.3192 15.8191L15.2032 13.9351L17.3997 16.1317C17.4119 16.1438 17.4249 16.1545 17.4375 16.1659V23.5743H6.93757V19.5415L8.95169 17.5274L12.9933 21.569C13.1764 21.7521 13.4163 21.8436 13.6562 21.8436C13.8962 21.8436 14.1361 21.7521 14.3191 21.569C14.6852 21.203 14.6852 20.6093 14.3191 20.2431L9.6145 15.5385C9.24831 15.1725 8.65488 15.1725 8.2886 15.5385L6.93757 16.8899V7.4494H17.4375V13.5178Z" />
          <path d="M26.3979 28.0743H6.00007C5.48238 28.0743 5.06257 28.4942 5.06257 29.0118C5.06257 29.5295 5.48238 29.9493 6.00007 29.9493H26.3979C26.9156 29.9493 27.3354 29.5295 27.3354 29.0118C27.3354 28.4942 26.9157 28.0743 26.3979 28.0743Z" />
          <path d="M26.3979 32.5743H14.2501C13.7324 32.5743 13.3126 32.9942 13.3126 33.5118C13.3126 34.0295 13.7324 34.4493 14.2501 34.4493H26.3979C26.9156 34.4493 27.3354 34.0295 27.3354 33.5118C27.3354 32.9942 26.9157 32.5743 26.3979 32.5743Z" />
          <path d="M26.3973 5.46094C25.8796 5.46094 25.4598 5.88075 25.4598 6.39844V9.21243C25.4598 9.73012 25.8796 10.1499 26.3973 10.1499C26.915 10.1499 27.3348 9.73012 27.3348 9.21243V6.39844C27.3348 5.88075 26.915 5.46094 26.3973 5.46094Z" />
          <path d="M27.0599 11.8559C26.8857 11.6816 26.6438 11.5812 26.3971 11.5812C26.1506 11.5812 25.9087 11.6815 25.7344 11.8559C25.56 12.0303 25.4596 12.2721 25.4596 12.5187C25.4596 12.7653 25.5599 13.0072 25.7344 13.1815C25.9087 13.3568 26.1506 13.4562 26.3971 13.4562C26.6438 13.4562 26.8857 13.3568 27.0599 13.1815C27.2343 13.0072 27.3346 12.7653 27.3346 12.5187C27.3346 12.2721 27.2344 12.0303 27.0599 11.8559Z" />
          <path d="M24.0455 11.0141L22.9138 9.88343C22.5475 9.51734 21.954 9.51771 21.5879 9.88399C21.222 10.2503 21.2223 10.8439 21.5885 11.2099L22.7201 12.3406C22.9033 12.5235 23.143 12.6149 23.3829 12.6149C23.623 12.6149 23.863 12.5232 24.046 12.34C24.412 11.9738 24.4117 11.3801 24.0455 11.0141Z" />
          <path d="M31.2071 9.88464C30.8409 9.51873 30.2473 9.51892 29.8813 9.8852L28.6782 11.0892C28.3123 11.4554 28.3125 12.049 28.6788 12.4151C28.8618 12.598 29.1017 12.6895 29.3414 12.6895C29.5815 12.6895 29.8215 12.5978 30.0046 12.4146L31.2077 11.2105C31.5736 10.8444 31.5734 10.2507 31.2071 9.88464Z" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="48"
              height="48"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
export default OneClickApplyIcon;
