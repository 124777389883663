/* eslint-disable no-console */
import React, { useRef, useCallback, useContext, useEffect } from 'react';
import { Textarea } from '../../core/form';
import uuid from '../../../utilities/uuid';
import MessagingDisabled from './MessagingDisabled';
import Button from '../../core/Button';
import useCanRecipientChat from '../hooks/useCanRecipientChat';
import { appUrl } from '../../../utilities/Url';
import ChatMessageContext from '../context/ChatMessageContext';

type ComposeMessageProps = {
  to: string;
  onSendMessage: (message: string) => Promise<void>;
  onKeyDown?: () => void;
  conversationType?: string;
  isDisabledTextArea?: boolean;
  enableVideoCall?: boolean;
};

/**
 * Wrap the compose component
 *
 * @param param0.to - the callback handler triggered when a message is sent
 * @param param0.onSendMessage - the callback handler triggered when a message is sent
 * @param param0.onKeyDown - the callback handler triggered when a key is pressed within the component
 * @param param0.isDisabledTextArea - a boolean that dictates whether the textbox is disabled or not
 * @param param0.enableVideoCall - a boolean that enables whether the web RTC video call is enabled or not
 */
const ComposeMessage = ({
  to,
  onSendMessage,
  onKeyDown,
  conversationType,
  isDisabledTextArea = false,
  enableVideoCall = false,
}: ComposeMessageProps) => {
  const initialMessage = useContext(ChatMessageContext);

  const isOneOnOneChat =
    conversationType === 'direct' || conversationType === '';

  return useCanRecipientChat(to) || isOneOnOneChat ? (
    <Compose
      isDisabledTextArea={isDisabledTextArea}
      onSendMessage={onSendMessage}
      onKeyDown={onKeyDown}
      enableVideoCall={enableVideoCall}
      initialMessage={initialMessage}
    />
  ) : (
    <MessagingDisabled />
  );
};

type ComposeProps = {
  onSendMessage: (message: string) => Promise<void>;
  onKeyDown?: () => void;
  initialMessage: string;
  isDisabledTextArea?: boolean;
  enableVideoCall?: boolean;
};

/**
 * Renders a component that is in charge of the text block where the user will enter their message to send
 *
 * @param param0.onSendMessage - the callback handler triggered when a message is sent
 * @param param0.onKeyDown - the callback handler triggered when a key is pressed within the component
 * @param param0.initialMessage - a string that contains the initial message to populate the text composer with
 * @param param0.isDisabledTextArea - a boolean that dictates whether the textbox is disabled or not
 * @param param0.enableVideoCall - a boolean that enables whether the web RTC video call is enabled or not
 */
const Compose = ({
  onSendMessage,
  onKeyDown,
  initialMessage,
  isDisabledTextArea = false,
  enableVideoCall = true,
}: ComposeProps) => {
  const textAreaRef = useRef(null as any);

  useEffect(() => {
    if (initialMessage) {
      textAreaRef.current.value = initialMessage;
    } else {
      textAreaRef.current.value = '';
    }
  }, [initialMessage]);

  const defaultPlaceholder = isDisabledTextArea
    ? 'This is a one way channel'
    : 'Send a message';

  const videoUrl = appUrl(`/video-chat/private-${uuid()}`);

  const sendMessage = useCallback(async () => {
    if (textAreaRef.current === null || textAreaRef.current.value === '')
      return;

    let messageToSend = '';

    // Create a new video chat room link if the user types `/video`
    if (textAreaRef.current.value === '/video') {
      messageToSend = videoUrl;
    } else {
      messageToSend = encodeURIComponent(textAreaRef.current.value);
    }

    onSendMessage(messageToSend);

    textAreaRef.current.value = '';
  }, [onSendMessage, videoUrl]);

  const sendVideoURL = useCallback(async () => {
    onSendMessage(videoUrl);
  }, [onSendMessage, videoUrl]);

  const handleKeyDown = useCallback(
    ({ ctrlKey, metaKey, key }: { ctrlKey: any; metaKey: any; key: any }) => {
      if ((ctrlKey || metaKey) && key === 'Enter') {
        sendMessage();
        return;
      }

      if (key === 'Escape') {
        textAreaRef.current.blur();
        return;
      }

      if (onKeyDown) onKeyDown();
    },
    [sendMessage, onKeyDown]
  );

  return (
    <div className="chat-compose">
      <div className="flex-1">
        <Textarea
          disabled={isDisabledTextArea}
          ref={textAreaRef}
          name="message"
          placeholder={defaultPlaceholder}
          rows={1}
          autoGrow
          autoCapitalize="sentences"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex items-center">
        {enableVideoCall && (
          <Button className="chat-submit" onClick={sendVideoURL}>
            <i className="i-video-camera" />
          </Button>
        )}
        <button type="button" className="chat-submit" onClick={sendMessage}>
          <i className="i-arrow-right" />
        </button>
      </div>
    </div>
  );
};

export default ComposeMessage;
