import React, { useMemo } from 'react';
import SteppedRangeSlider, {
  SteppedRangeValue,
} from '../core/SteppedRangeSlider';

interface Props {
  defaultValue?: number;
  label: string;
  className?: string;
  onChange?(value: SteppedRangeValue): void;
}

interface StepValue {
  value: number;
  label: string;
}

const valueMap: StepValue[] = [
  {
    value: 0,
    label: 'Much less than',
  },
  {
    value: 1,
    label: 'Less than',
  },
  {
    value: 2,
    label: 'Met',
  },
  {
    value: 3,
    label: 'Exceeded',
  },
  {
    value: 4,
    label: 'Greatly exceeded',
  },
];

const ReferenceRangeSlider = ({
  defaultValue = 2,
  label,
  className,
  onChange,
}: Props) => {
  const defaultIndex = useMemo(() => {
    const foundIndex = valueMap.findIndex(
      value => value.value === defaultValue
    );

    if (foundIndex < 0) {
      return 2;
    }

    return foundIndex;
  }, [defaultValue]);

  return (
    <div className={className}>
      <SteppedRangeSlider
        defaultIndex={defaultIndex}
        label={label}
        onChange={onChange}
        options={valueMap}
      />
    </div>
  );
};
export default ReferenceRangeSlider;
