import React from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import { Link } from '../core/router';
import Avatar from '../avatar/Avatar';

interface Props {
  item: PerkBundleSearchResponse;
}

const PerkFeatureSlideMobile = ({ item }: Props) => {
  const {
    objectID,
    name,
    descriptionSummary,
    offerSummary,
    type,
    company,
    tagline,
    logoUrl,
  } = item;

  return (
    <div className="swiper-slide__content text-left relative">
      <Avatar
        size="xl"
        avatarUrl={logoUrl}
        avatarName={company}
        className="absolute top-0 right-0 transform -translate-y-1/2 mr-6"
        borderWidth={4}
      />

      <div className="text-2xl">{type === 'perk' ? company : name}</div>
      <h5 className="mt-2">{type === 'perk' ? name : tagline}</h5>
      <div className="mt-2 text-large">{descriptionSummary}</div>
      <div className="font-bold text-large text-red-900 mt-2">
        {offerSummary}
      </div>
      <Link
        to={
          type === 'bundle'
            ? `/perks/bundles/${objectID}`
            : `/perks/${objectID}`
        }
        className="btn btn--primary btn--outline btn--sm w-full mt-6"
      >
        Discover More
      </Link>
    </div>
  );
};
export default PerkFeatureSlideMobile;
