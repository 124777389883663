import { useEffect, useState, useCallback } from 'react';
import Api from '../components/work/Api';
import { timeDiff } from '../utilities/dateTime';

export default function(): {
  data: ApplicationEntity[] | null;
  loading: boolean;
  refresh: Function;
} {
  const [data, setData] = useState({
    data: null,
    loading: true,
  });

  const updateState = useCallback((key, value) => {
    setData(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const refresh = useCallback(() => {
    updateState('loading', true);
    Api.myApplications()
      .then(response => {
        const sorted = response.sort((a: any, b: any) =>
          timeDiff(a.work.expiresAt, b.work.expiresAt)
        );
        updateState('data', sorted);
      })
      .catch(() => {
        updateState('data', null);
      })
      .finally(() => {
        updateState('loading', false);
      });
  }, [updateState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data: data.data,
    loading: data.loading,
    refresh,
  };
}
