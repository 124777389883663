import { memoize, pick } from 'lodash';
import createStore from '../../../utilities/createStore';
import { IUser } from '../../../@types/chat.d';
import UserInstances from '../instances/UserInstances';

const keysToPick = [
  'identity',
  'friendlyName',
  'attributes',
  'online',
  'notifiable',
];

export type ChatUsersState = {
  identity: string;
  user: IUser | null;
  loading: boolean;
  loaded: boolean;
  loadingUser: () => void;
  addUser: (user: IUser) => void;
  updateUser: (user: IUser) => void;
};

const userStoreCreator = (identity: string) =>
  createStore<ChatUsersState>(
    (set: any) => ({
      identity,
      user: null,
      loading: false,
      loaded: false,
      loadingUser: () => set({ loading: true, loaded: false }, 'loadingUser'),
      addUser: user => {
        const rawUserData = { ...pick(user, keysToPick) };
        rawUserData.friendlyName = rawUserData.friendlyName || 'Unknown User';

        UserInstances.add([user]);

        set(
          {
            user: rawUserData,
            loading: false,
            loaded: true,
          },
          'addUser'
        );
      },
      updateUser: user => {
        UserInstances.update([user]);

        set(
          {
            user: pick(user, keysToPick),
          },
          'updateUser'
        );
      },
    }),
    `ChatUserState: ${identity}`
  );

export default memoize(userStoreCreator);
