import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  name: string;
  errors: any;
};

export default ({ name, errors }: Props) => {
  return (
    <>
      {errors?.[name] && (
        <div className="form-error">
          <i className="i-alert-warning" />
          <ErrorMessage name={name} errors={errors} />
        </div>
      )}
    </>
  );
};
