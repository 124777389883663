import React, { useMemo } from 'react';
import ErrorMessage from './ErrorMessage';

type Props = {
  name: string;
  label?: string;
  labelSubtext?: string;
  required?: boolean;
  hint?: string;
  errors?: any;
  className?: string;
  children: any; // JSX.Element | JSX.Element[] | string;
  labelVisible?: boolean;
};

export default ({
  name,
  label,
  labelSubtext,
  required,
  hint,
  errors,
  className = '',
  children,
  labelVisible = true,
}: Props) => {
  const error = errors?.[name];
  const classNames = useMemo((): string => {
    let classes = ['form-group'];

    if (className.length) {
      const names = className.split(' ');
      classes = [...classes, ...names];
    }

    if (error) classes.push('form-group--invalid');

    return classes.join(' ');
  }, [className, error]);

  return (
    <div className={classNames}>
      {label && (
        <label className={labelVisible ? '' : 'sr-only'} htmlFor={name}>
          {label}{' '}
          {labelSubtext ? (
            <span className="font-normal">{labelSubtext}</span>
          ) : null}
          {required ? <span className="text-red-900 font-bold">*</span> : null}
        </label>
      )}
      {children}
      {hint && <div className="help-text">{hint}</div>}
      <ErrorMessage name={name} errors={errors} />
    </div>
  );
};
