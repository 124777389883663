import { body } from 'ionicons/icons';
import ReactStars from 'react-stars';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../core/Button';
import { FormActions, FormTextarea } from '../../core/form';
import UserContext from '../../user/Context';
import { NoteFormValues } from '../view/ApplicationNotes';

type Props = {
  onComplete: () => void;
  onSubmit: (values: NoteFormValues) => Promise<void>;
};

const ApplicationNoteForm = ({ onSubmit, onComplete }: Props) => {
  const { isPublic, name } = useContext(UserContext);
  const [rating, setRating] = useState(0);

  const form = useForm({
    shouldFocusError: true,
  });
  const { handleSubmit, register, errors, setValue, clearErrors } = form;
  const maxLength = 700;

  const ratingChanged = (newRating: number) => {
    clearErrors('rating');
    if (newRating > 5) {
      newRating = 5;
    }
    if (newRating < 0.5) {
      newRating = 0.5;
    }
    setRating(newRating);
    setValue('rating', `${newRating}`);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col flex-grow w-full"
    >
      <div>
        <div className="form-group">
          <label htmlFor="rating" className="mb-0">
            Rating
          </label>
          <ReactStars
            count={5}
            value={rating}
            onChange={ratingChanged}
            size={24}
            color2="#E8A318"
            className="mb-1"
          />
          <input
            id="rating"
            name="rating"
            className="form-input"
            type="hidden"
            placeholder="5"
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              if (value > 5) {
                e.target.value = '5';
              }
              if (value < 0.5) {
                e.target.value = '0.5';
              }
            }}
            ref={register({
              required: 'Rating is required',
              min: { value: 0.5, message: 'Rating must be at least 0.5' },
              max: { value: 5, message: 'Rating must be at most 5' },
            })}
          />
          {errors.rating && (
            <div className="form-error">
              <i className="i-alert-warning" />
              {errors.rating.message}
            </div>
          )}

          <label htmlFor="name">Name</label>
          {isPublic ? (
            <>
              <input
                id="name"
                name="name"
                className="form-input"
                type="text"
                placeholder="Jane Doe"
                ref={register({
                  required: 'Name is required',
                })}
              />
              {errors.name && (
                <div className="form-error">
                  <i className="i-alert-warning" />
                  {errors.name.message}
                </div>
              )}
            </>
          ) : (
            <div id="name" className="mb-2">
              {name}
            </div>
          )}

          <FormTextarea
            name="note"
            label="Note"
            labelSubtext="(limit 700 characters)"
            placeholder="Enter note about Applicant..."
            errors={errors}
            ref={register({
              required: 'This is required',
              maxLength: {
                value: maxLength,
                message: `Max ${maxLength} characters`,
              },
            })}
            maxLength={maxLength}
            characterCount={maxLength}
            form={form}
            className="flex flex-col flex-grow"
            inputClasses="flex flex-col flex-grow"
          />
        </div>
      </div>
      <FormActions className="">
        <div className="ml-auto">
          <Button
            text="Cancel"
            type="button"
            className="btn--outline btn--primary"
            disabled={!body}
            onClick={() => onComplete()}
          />
          <Button
            text="Submit"
            color="primary"
            type="submit"
            fill="solid"
            className="ml-2 whitespace-no-wrap mb-16"
          />
        </div>
      </FormActions>
    </form>
  );
};

export default ApplicationNoteForm;
