import React from 'react';
import { formatDate } from '../../utilities/dateTime';
import ScoreAvatar from '../candidateScore/ScoreAvatar';

type Props = {
  application: ApplicationEntity;
  membership: MembershipEntity | undefined;
  planType: PlanType | undefined;
  role: string | undefined;
  showProfile: () => void;
  user: UserEntity;
};
const ApplicationHeader = ({
  application,
  membership,
  planType,
  role,
  showProfile,
  user,
}: Props) => {
  const applicationDate = formatDate(application.createdAt);
  const { firstName, lastName, avatarURL } = user;
  const name = `${firstName} ${lastName}`;

  return (
    <div className="flex">
      <div className="mr-4 cursor-pointer" onClick={showProfile} role="none">
        <ScoreAvatar
          name={name}
          imageUrl={avatarURL}
          percentage={application.score}
          size={20}
        />
      </div>
      <div className="truncate">
        <div>Applied {applicationDate}</div>
        <div
          className="font-bold text-lg cursor-pointer"
          onClick={showProfile}
          role="none"
        >
          {name}
        </div>
        {planType === 'agency' && membership && <div>{membership.name}</div>}
        <div className="font-light text-sm truncate">{role}</div>
      </div>
    </div>
  );
};

export default ApplicationHeader;
