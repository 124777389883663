import { Recommendation } from '@communo-corp/shared-types';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useRecommendationByToken(
  submissionToken: string
): {
  isFetching: boolean;
  recommendation: Recommendation;
  searchToken: string;
  refetch: Function;
} {
  const { data, isFetching, refetch } = useQuery(
    ['recommendation-by-token', submissionToken],
    () => Api.getRecommendationByToken(submissionToken),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isFetching,
    recommendation: data?.recommendation ?? null,
    searchToken: data?.searchToken ?? null,
    refetch,
  };
}
