import { useEffect, useState } from 'react';

/**
 * Inspiration taken from - https://usehooks.com/useScript/
 */

function useScript(src: string) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(
    () => {
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(
        `script[src="${src}"]`
      ) as HTMLScriptElement | null;

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');

        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        script.addEventListener('load', () => {
          if (script) {
            script.setAttribute('data-status', 'ready');
          }
        });

        script.addEventListener('error', () => {
          if (script) {
            script.setAttribute('data-status', 'error');
          }
        });
      } else {
        // Grab existing script status from attribute and set to state.
        const scriptStatus = script.getAttribute('data-status');
        setStatus(scriptStatus ?? 'idle');
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
          // document.body.removeChild(script);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  return status;
}

export default useScript;
