import React from 'react';
import cn from 'classnames';

type Props = {
  label: string;
  value: any;
  className?: string;
};

const LineItem = ({ label, value, className }: Props) => (
  <div className={cn('flex justify-between', className)}>
    <p>{label}</p>
    <p>{value}</p>
  </div>
);
export default LineItem;
