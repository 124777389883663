import React, { useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import { FilterOptionComponentProps } from '../index.d';
import BaseFilterOption from './BaseFilterOption';
import { filterDependencies } from '../useFilter';
import { isMobile } from '../../../utilities/Device';

const AllFiltersComponent: React.FC<FilterOptionComponentProps<null>> = ({
  actions,
  onChange,
  filterOptions = [],
  values = {},
}: FilterOptionComponentProps<null>) => {
  const filteredOptions = useMemo(
    () =>
      filterOptions.filter(
        option =>
          !option.dependsOn ||
          filterDependencies(
            values,
            option.dependsOn.targetName,
            option.dependsOn.operator,
            option.dependsOn.value
          )
      ),
    [values, filterOptions]
  );

  return (
    <div className="all-filters">
      <div className="filter-group">
        <h4 className="mt-4 flex items-center">
          {actions?.clearFilters ? (
            <span className="text-sm">
              <button
                type="button"
                className="text-link text-sm text-caption whitespace-nowrap font-medium"
                onClick={actions.clearFilters}
              >
                Reset Filters
              </button>
            </span>
          ) : null}
        </h4>
      </div>
      {filteredOptions.map(option => (
        <option.component
          key={option.name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...option}
          onChange={onChange}
          value={values[option.name]}
          defaultMenuIsOpen={false}
          showTitle
        />
      ))}
    </div>
  );
};

class AllFiltersOption extends BaseFilterOption<null> {
  constructor() {
    super({
      name: 'all',
      toggleLabel: '', // won't compile if <i className="i-filters"/> assigned here
      component: AllFiltersComponent,
      size: 'large',
      sectionTitle: 'All Filters',
      fullHeight: isMobile,
    });

    this.toggleLabel = (
      <i className="i-filters font-medium text-base text-black" />
    );
  }
}

export default AllFiltersOption;
