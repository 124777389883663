import React from 'react';
import { FormGroup } from '../form';
import Location from '../Location';

type Props = {
  label?: string;
  form: any;
  errors: any;
  register: any;
  defaultValue?: string;
  required?: boolean;
};

export default ({
  label = 'Location',
  form,
  errors,
  register,
  defaultValue = '',
  required = false,
}: Props) => {
  const { setValue } = form;
  const handleChangeLocation = (location: any) => {
    setValue('location.latitude', location?.coordinates?.lat || null);
    setValue('location.longitude', location?.coordinates?.lng || null);
    setValue('location.friendlyName', location?.address || null, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(
      'location.rawJSON',
      location ? JSON.stringify(location.raw) : null
    );
  };

  return (
    <FormGroup
      required={required}
      name="friendlyName"
      label={label}
      errors={errors?.location}
    >
      <Location
        placeholder="e.g. Calgary, AB, Canada"
        value={{ address: defaultValue }}
        onChange={handleChangeLocation}
      />
      <input type="hidden" name="location.latitude" ref={register} />
      <input type="hidden" name="location.longitude" ref={register} />
      <input
        type="hidden"
        name="location.friendlyName"
        ref={register({ required: `${label} is required` })}
      />
      <input type="hidden" name="location.rawJSON" ref={register} />
    </FormGroup>
  );
};
