import {
  ChatNewConversation,
  ChatNewDirectConversation,
  ChatBlankConversation,
  ChatNewGroupConversation,
  ChatNewGroupConversationSettings,
  ChatNewOneToManyConversation,
  ChatNewOneToManyConversationSettings,
  ChatConversationSettings,
  ChatMemberSettings,
  ChatConversation,
} from '../../pages';

const routes = [
  { path: '/chat/new-message', component: ChatNewConversation },
  { path: '/chat/new-direct-message', component: ChatNewDirectConversation },
  {
    path: '/chat/new-direct-message/:identity',
    component: ChatBlankConversation,
  },
  { path: '/chat/new-group-message', component: ChatNewGroupConversation },
  {
    path: '/chat/new-group-message/settings',
    component: ChatNewGroupConversationSettings,
  },
  {
    path: '/chat/new-one-to-many-message',
    component: ChatNewOneToManyConversation,
  },
  {
    path: '/chat/new-one-to-many-message/settings',
    component: ChatNewOneToManyConversationSettings,
  },
  {
    path: '/chat/conversation/:sid/settings',
    component: ChatConversationSettings,
  },
  {
    path: '/chat/conversation/:sid/member/:identity',
    component: ChatMemberSettings,
  },
  { path: '/chat/conversation/:sid', component: ChatConversation },
];

export default routes;
