const chapters: { [key: string]: string[] } = {
  'District 1': ['AAF New England'],
  'District 2': [
    'AAF Baltimore',
    'AAF Buffalo',
    'AAF DC',
    'AAF Greater Frederick',
    'AAF Greater Lehigh Valley',
    'AAF Northeast PA',
    'Ad 2 DC',
    'Ad 2 Pittsburgh',
    'Capital Region Advertising Federation',
    'AAF Pittsburgh',
    'Rochester Ad Fed ',
  ],
  'District 3': [
    'AAF Charlotte',
    'AAF Coastal Carolinas',
    'AAF Greenville',
    'AAF Hampton Roads',
    'AAF of the Midlands',
    'AAF Roanoke',
    'AAF Southwest Virginia',
    'Triangle AdFed',
  ],
  'District 4': [
    'AAF Daytona Beach',
    'AAF Emerald Coast',
    'AAF Gainesville',
    'AAF Greater Ft Lauderdale & The Palm Beaches',
    'AAF Jacksonville',
    'AAF Miami',
    'AAF Orlando',
    'AAF Panama City',
    'AAF Pensacola ',
    'AAF Polk County',
    'AAF Space Coast',
    'AAF Southwest Florida',
    'AAF Tallahassee',
    'AAF Tampa Bay',
    'AAF Treasure Coast',
    'Ad 2 Orlando',
    'Ad 2 Tampa Bay',
    'AdFed Suncoast ',
    'Caribbean AdFed',
  ],
  'District 5': [
    'AAF Akron',
    'AAF Cincinnati',
    'AAF Cleveland ',
    'AAF Columbus',
    'AAF Dayton',
    'AAF Lexington',
    'AAF Louisville',
    'AAF Toledo',
    'AAF West Virginia ',
    'Ad 2 Cincinnati',
    'Ad 2 Louisville',
  ],
  'District 6': [
    'AAF East Central Indiana',
    'AAF Fort Wayne',
    'AAF Great Lakes Bay Region',
    'AAF Greater Evansville',
    'AAF Greater Flint',
    'AAF Indianapolis',
    'AAF North Central Indiana',
    'AAF Northern Illinois',
    'AdFed West Michigan ',
    'Chicago AdFed',
    'Mid-Michigan Creative Alliance',
    'Peoria Ad Club',
  ],
  'District 7': [
    'Atlanta Ad Club',
    'AAF Augusta',
    'AAF Baton Rouge',
    'AAF Birmingham',
    'AAF Chattanooga',
    'AAF Dothan',
    '"AAF Jackson, MS"',
    'AAF Knoxville',
    'AAF Memphis',
    'AAF Mississippi Gulf Coast',
    'AAF Mobile Bay',
    'AAF Montgomery',
    'AAF Nashville',
    'AAF New Orleans',
    'AAF North Alabama',
    'AAF Northeast Tennessee',
    'AAF Tuscaloosa',
  ],
  'District 8': [
    'AAF Black Hills',
    'AAF Central Minnesota',
    'AdFed Minnesota ',
    'AAF Madison ',
    'AAF North Dakota',
    'Ad 2 Madison',
    'Ad 2 Milwaukee',
    'Ad 2 Minnesota',
    'Fox River Ad Club',
    'South Dakota AdFed',
  ],
  'District 9': [
    'AAF Cedar Valley',
    'AAF Des Moines',
    'AAF Dubuque ',
    'AAF Heartland',
    'AAF Kansas City',
    'Ad 2 Kansas City ',
    'AAF Lincoln',
    'AAF Omaha',
    'Ad Club St Louis',
    'AAF Topeka',
    'AAF Cape Girardeau',
  ],
  'District 10': [
    'AAF Little Rock',
    'AAF N.E. Arkansas',
    'AAF N.E. Louisiana',
    'AAF Shreveport/Bossier',
    'AAF Oklahoma City',
    'Ad 2 Oklahoma City',
    'AAF Amarillo',
    'Ad 2 Amarillo',
    'AAF Austin',
    'Ad 2 Austin',
    'AAF Corpus Christi',
    'AAF Dallas',
    'Ad 2 Dallas',
    'AAF Fort Worth',
    'AAF Houston',
    'Ad 2 Houston',
    'AAF Lubbock',
    'AAF Rio Grande Valley',
    'AAF San Antonio ',
  ],
  'District 11': [
    'Boise Ad Fed',
    'Idaho Falls AdFed',
    'Lewis-Clark Valley AdFed',
    'Magic Valley AdFed',
    'Pocatello AdFed',
    'AAF Seattle',
    'AAF Spokane ',
  ],
  'District 12': [
    'AAF Colorado Springs',
    'AAF New Mexico',
    'AAF Tucson',
    'AAF Utah ',
    'Ad 2 Colorado',
    'Ad 2 Tucson',
    'Ad Club Colorado',
    'Phoenix Ad Club',
  ],
  'District 13': ['AAF Hawaii', 'Ad 2 Hawaii'],
  'District 14': [
    'AAF Reno',
    'AAF Silicon Valley ',
    'Greater San Francisco Ad Club',
    'Sacramento Ad Club',
  ],
  'District 15': [
    'AAF Inland Empire',
    'AAF Las Vegas',
    'AAF Orange County',
    'Ad 2 Las Vegas',
    'Desert AdFed',
  ],
};

export default chapters;
