import React, { useState } from 'react';
import { addSuccess } from '../../services/Messaging';
import useSchedulerPages from './hooks/useSchedulerPages';
import { LoadingBalls } from '../core/Loading';
import SendMeetingLinkModal from './SendMeetingLinkModal';
import MeetingSettingsButton from './MeetingSettingsButton';
import Button from '../core/Button';

type SchedulerProps = {
  application: ApplicationEntity;
  work: WorkEntity;
  patchApplications: Function;
  editMeetingUrl: string;
  hasStartedConversation: boolean;
  sendMeetingLink: (link: string) => void;
};

const SchedulerApplication = ({
  application,
  editMeetingUrl,
  hasStartedConversation,
  sendMeetingLink,
}: SchedulerProps) => {
  const { data, isLoading } = useSchedulerPages();
  const showSendLink = !!editMeetingUrl;
  const [showSendModal, setShowSendModal] = useState(false);
  const [selectedMeetingIndex, setMeetingIndex] = useState(0);

  const copyToClipboard = (textToCopy: string) => {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy || '');
      addSuccess('Meeting link copied to clipboard!');
    }
  };

  const openSendModal = () => {
    setShowSendModal(true);
  };

  const closeSendModal = () => {
    setShowSendModal(false);
  };

  const closeModalAndSend = () => {
    const { slug } = data.schedulerPages[selectedMeetingIndex];
    const meetingLink = `https://schedule.nylas.com/${slug}`;
    closeSendModal();
    copyToClipboard(meetingLink);
    sendMeetingLink(meetingLink);
  };

  return (
    <div>
      <div className="flex justify-between mb-1">
        <div />
        <h4>Meetings</h4>
        <div>
          <MeetingSettingsButton editMeetingUrl={editMeetingUrl} />
        </div>
      </div>
      <div>
        {isLoading ? (
          <LoadingBalls isActive />
        ) : (
          <>
            {data?.schedulerPages.length > 0 ? (
              <div className="flex">
                <select
                  className="form-input"
                  onChange={e => setMeetingIndex(parseInt(e.target.value, 10))}
                >
                  {data.schedulerPages.map(
                    (meeting: Meeting, index: number) => {
                      return (
                        <option key={meeting.id} value={index}>
                          {meeting.config.event.title}
                        </option>
                      );
                    }
                  )}
                </select>
                <Button
                  onClick={openSendModal}
                  color="primary"
                  className="flex-1"
                  size="md"
                >
                  <i className="i-plane btn__icon" />
                </Button>
              </div>
            ) : (
              <p>There are currently no created meetings</p>
            )}
          </>
        )}
      </div>
      <div>
        {showSendModal && showSendLink && (
          <SendMeetingLinkModal
            applicantFirstName={application.user.firstName}
            hasStartedConversation={hasStartedConversation}
            showSendModal={showSendModal}
            meetingInfo={data?.schedulerPages[selectedMeetingIndex]}
            closeSendModal={closeSendModal}
            closeModalAndSend={closeModalAndSend}
          />
        )}
      </div>
    </div>
  );
};

export default SchedulerApplication;
