import React from 'react';

type Props = { isActive: boolean; fullscreen?: boolean; message?: string };
type RingProps = {
  isActive: boolean;
  type?: '' | 'primary' | 'secondary' | 'tertiary';
  size?: '' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  color?: '' | 'primary' | 'secondary' | 'tertiary';
};

export const Loading = ({ isActive }: Props) =>
  isActive ? <LoadingBar /> : null;

export const LoadingBar = () => (
  <div className="loading-bar">
    <div className="progress" />
  </div>
);

export const LoadingBalls = ({
  isActive,
  fullscreen = false,
  message,
}: Props) => {
  const classNames =
    'h-full w-full fixed top-0 left-0 flex justify-center items-center';
  return isActive ? (
    <div className={`loading-balls ${fullscreen ? classNames : null}`}>
      <div>
        <div className="cp-balls-container">
          <div className="cp-balls" />
        </div>
        {message && <div className="loading-balls--message">{message}</div>}
      </div>
    </div>
  ) : null;
};

export const LoadingRing = ({
  isActive,
  type = '',
  size = '',
  color = '',
}: RingProps) => {
  const classes = ['loading-ring'];

  if (type) classes.push(`loading-ring--${type}`);
  if (size) classes.push(`loading-ring--${size}`);
  if (color) classes.push(`loading-ring--${color}`);

  return isActive ? (
    <div className={classes.join(' ')}>
      <div />
      <div />
      <div />
      <div />
    </div>
  ) : null;
};

export default Loading;
