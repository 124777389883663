/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormGroup from './FormGroup';
import Input from './Input';

export default React.forwardRef(
  (
    {
      name,
      label,
      errors,
      placeholder = '',
      type = 'search',
      className = '',
      hint = '',
      children,
      ...rest
    }: any,
    ref
  ) => {
    return (
      <FormGroup
        className={className}
        hint={hint}
        name={name}
        label={label}
        errors={errors}
      >
        <div className="input-group">
          <Input
            {...rest}
            name={name}
            placeholder={placeholder}
            type={type}
            ref={ref}
          />
          {children}
          <div className="input-group__icon">
            <i className="i-search text-xs" />
          </div>
        </div>
      </FormGroup>
    );
  }
);
