import React from 'react';
import Page from '../components/core/Page';
import DeactivatedList from '../components/root/membership/DeactivatedList';

const RootMembershipDeactivated = () => {
  return (
    <Page authGuard pageTitle="Deactivated Members">
      <div className="py-4 md:py-8 px-4">
        <h2 className="mb-4">Deactivated Members List</h2>
        <DeactivatedList />
      </div>
    </Page>
  );
};
export default RootMembershipDeactivated;
