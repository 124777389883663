import { BaseAPI, Api } from '../../Api';

const customApi = {
  categories: () => Api.get(`/perk-category`),
  updatePerk: (id: string, data: any) => Api.put(`/perk/${id}`, data), // Needed because the update method changed
  listAllBundles: () => Api.get(`/perk-bundle`),
  createBundle: (data: any) => Api.post('/perk-bundle', data),
  updateBundle: (id: string, data: any) => Api.put(`/perk-bundle/${id}`, data),
  retrieveBundle: (id: string) => Api.get(`/perk-bundle/${id}`),
  deleteBundle: (id: string) => Api.delete(`perk-bundle/${id}`),
};

export default { ...BaseAPI('perk'), ...customApi };
