type Props = {
  titleForID?: string | null;
  lengthLimit?: number;
};

type Breadcrumb = {
  title: string;
  linkTo: string;
};

const useBreadcrumbs = ({ titleForID, lengthLimit }: Props): Breadcrumb[] => {
  const url = new URL(window.location.href);
  const urlPathnameArr = url.pathname.split('/').slice(0, lengthLimit);
  const breadcrumbs: Breadcrumb[] = [];

  urlPathnameArr.forEach((pathElement, i) => {
    let skipCrumb = false;
    const linkTo = urlPathnameArr.slice(0, i + 1).join('/');
    const crumb: Breadcrumb = {
      title: pathElement,
      linkTo,
    };
    if (pathElement === '') {
      if (i === 0) crumb.title = 'HomePage';
      // If a path element is empty, then it must be a typo or the trailing slash
      if (i !== 0) skipCrumb = true;
    }
    // if it includes a number, we know it's the ID of the work
    const matches = pathElement.match(/\d+/g);
    if (matches) {
      crumb.title = titleForID || 'Details';
    }
    if (!skipCrumb) breadcrumbs.push(crumb);
  });
  return breadcrumbs;
};

export default useBreadcrumbs;
