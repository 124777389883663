import React from 'react';
import { provinces } from '../../../helpers/regions';

export default ({ name, register }: any) => (
  <select
    id={name}
    name={name}
    className="form-input w-16"
    data-recurly="state"
    ref={register({
      required: 'Region is required',
    })}
  >
    <option disabled>Region</option>
    {provinces.map((v: Region) => (
      <option key={v.code} value={v.code}>
        {v.code}
      </option>
    ))}
  </select>
);
