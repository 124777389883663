import React, { useState } from 'react';
import Button, { ButtonType } from '../core/Button';
import RequestReferenceModal from './RequestReferenceModal';

type Props = Pick<ButtonType, 'fill'>;

const RequestReferenceButton = ({ fill = 'outline' }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        text="Request a reference"
        onClick={() => {
          setModalOpen(true);
        }}
        className="w-full"
        color="primary"
        fill={fill}
      />

      <RequestReferenceModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default RequestReferenceButton;
