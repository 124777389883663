import React from 'react';

const CaretIcon = () => <i className="i-caret-right text-grey-600 text-lg" />;

const ListActionsItemBase = ({
  start,
  end,
  children,
}: ListActionsItemProps) => {
  return (
    <>
      {start ? <div className="list-actions__item-start">{start}</div> : null}
      <div className="list-actions__item-body">
        <div className="list-actions__item-body-start">{children}</div>
        {end ? (
          <div className="list-actions__item-body-end">{end}</div>
        ) : (
          <CaretIcon />
        )}
      </div>
    </>
  );
};

export default ListActionsItemBase;
