import { CollectionInvite } from '@communo-corp/shared-types';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useCollectionEmailInvites(
  collectionId: string,
  disableQuery: boolean = false
): {
  isLoading: boolean;
  invites: CollectionInvite[];
  refetch: Function;
} {
  const { data, isLoading, refetch } = useQuery(
    ['collection-email-invites', collectionId],
    () => Api.listCollectionEmailInvites(collectionId),
    {
      enabled: !disableQuery,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const refetchInvites = useCallback(() => {
    if (disableQuery) {
      return;
    }

    refetch();
  }, [disableQuery, refetch]);

  return {
    isLoading,
    invites: data ?? [],
    refetch: refetchInvites,
  };
}
