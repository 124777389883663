import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode | ReactNode[];
  title?: string;
  icon?: string;
}

const Screen = ({ children, title, icon }: Props) => {
  return (
    <div className="text-center p-8 flex flex-col flex-1">
      {icon && (
        <div>
          <i className={`${icon} text-5xl text-red-500`} />
        </div>
      )}
      {title && <h3 className="text-white mt-4 mb-8 px-4">{title}</h3>}
      {children}
    </div>
  );
};
export default Screen;
