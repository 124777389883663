import React, { useEffect, useState } from 'react';
import InviteSignupForm from '../../forms/InviteSignupForm';
import Api from '../../Api';
import { LoadingBalls } from '../../../core/Loading';
import useAnalytics from '../../../../hooks/useAnalytics';
import { addResponseError } from '../../../../services/Messaging';
import ContentHeader from '../../../core/ContentHeader';
import { Link } from '../../../core/router';
import SignupLayout from '../../signup/SignupLayout';
import {
  DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL,
  DEFAULT_TEAM_LOGIN_COVER_PHOTO_URL,
} from '../../../../asset-config';

type InviteStatusData = {
  email: string;
  membershipName: string;
};

// probably want to redirect..
const InviteNotFound = () => (
  <div className="text-center">
    <h2 className="mb-4">Oops.</h2>
    <p className="text-lg font-light mb-8">
      Your invite code is no longer valid. Please contact your team
      administrator to have them send a new one.
    </p>
    <p className="text-lg font-light mb-8">
      <Link to="/" className="text-link font-medium">
        Start over
      </Link>
    </p>
  </div>
);

export default ({ code }: { code: string }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<InviteStatusData>({} as InviteStatusData);
  const { email, membershipName } = data;

  const { pageIsReady } = useAnalytics();

  useEffect(() => {
    Api.inviteStatus({ code })
      .then((response: InviteStatusData) => {
        setData(response);
        setLoading(false);
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  }, [code]);

  useEffect(() => {
    if (email) {
      pageIsReady({
        pageName: 'Invite Set Up',
        email,
        membershipName,
      });
    }
  }, [email, membershipName, pageIsReady]);

  const imageSrc =
    loading || email
      ? DEFAULT_TEAM_LOGIN_COVER_PHOTO_URL
      : DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL;

  return (
    <SignupLayout headerImg={imageSrc}>
      <>
        <LoadingBalls isActive={loading} fullscreen />
        <ContentHeader />
        {!loading &&
          (email ? (
            <>
              <div className="text-center">
                <h3 className="mb-4">Your team is waiting.</h3>
                {!loading && (
                  <div className="text-lg font-light mb-4">
                    You&apos;ve been invited to join{' '}
                    {membershipName ? (
                      <span className="font-medium">{membershipName}</span>
                    ) : (
                      'a team'
                    )}
                    ! Let&apos;s get your account set up.
                  </div>
                )}
                {!loading && email && (
                  <p className="font-light mb-4">
                    You&apos;re joining as <br />
                    <span className="font-medium">{email}</span>.
                  </p>
                )}
              </div>
              <InviteSignupForm code={code} email={email} />
            </>
          ) : (
            <InviteNotFound />
          ))}
      </>
    </SignupLayout>
  );
};
