import React, { useState, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import UserContext from '../Context';
import MembershipContext from '../../membership/Context';
import { FormActions, FormGroup } from '../../core/form';
import { VettingStatus } from '../formElements';
import { SubmitButton } from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import {
  vettingStatuses,
  vettingStatusOptions,
} from '../../../helpers/vettingStatuses';

interface Props {
  membership: MembershipEntity;
  update: (data: any) => Promise<MembershipEntity>;
}

const Summary = ({ membership }: Props) => {
  const { vettingStatus } = membership;
  const label = useMemo(() => {
    const item = vettingStatusOptions.find(
      (option: any) => option.value === vettingStatus
    );
    return item ? item.label : '-';
  }, [vettingStatus]);
  return (
    <FormGroup label="Vetting Status" name="vettingStatus">
      {label}
    </FormGroup>
  );
};

const Form = ({ membership, update }: Props) => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      vettingStatus: membership.vettingStatus,
    },
  });
  const { handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = values;
    data.vettingStatus = vettingStatuses.includes(values.vettingStatus)
      ? values.vettingStatus
      : null;

    update(data)
      .then((response: MembershipEntity) => {
        addSuccess('Membership updated!');
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <VettingStatus errors={errors} register={register} />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save Changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

const VerificationsForm = () => {
  const { membership, update } = useContext(MembershipContext);
  const { isRoot } = useContext(UserContext);
  if (!membership) return <div>No Membership</div>;
  if (isRoot) return <Form membership={membership} update={update} />;
  return <Summary membership={membership} update={update} />;
};

export default VerificationsForm;
