import React from 'react';
import Page from '../components/core/Page';
import BundlesDetail from '../components/perk/BundlesDetail';

const PerksBundlesDetail = () => {
  return (
    <Page
      authGuard
      pageTitle="Bundle Detail"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
      noScroll
    >
      <BundlesDetail />
    </Page>
  );
};
export default PerksBundlesDetail;
