import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import CollectionInviteLanding from '../components/landing/CollectionInviteLanding';

const CollectionInviteLandingPage = ({ match }: any) => {
  const { inviteId } = match.params;

  useAnalytics('Invite to bench landing');

  return (
    <Page layout="landing" className="page-bg--white" hideHeader>
      <CollectionInviteLanding inviteId={inviteId} />
    </Page>
  );
};

export default React.memo(CollectionInviteLandingPage);
