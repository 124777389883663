import React from 'react';

const LeftArrowCircleIcon = React.memo(({ className = '' }: any) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 4.00004C9.87256 4.00004 4.49998 9.37262 4.49998 16C4.49998 22.6275 9.87256 28 16.5 28C23.1274 28 28.5 22.6275 28.5 16C28.5 9.37262 23.1274 4.00004 16.5 4.00004ZM1.83331 16C1.83331 7.89986 8.3998 1.33337 16.5 1.33337C24.6002 1.33337 31.1666 7.89986 31.1666 16C31.1666 24.1002 24.6002 30.6667 16.5 30.6667C8.3998 30.6667 1.83331 24.1002 1.83331 16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4428 9.7239C17.9635 10.2446 17.9635 11.0888 17.4428 11.6095L13.0523 16L17.4428 20.3906C17.9635 20.9113 17.9635 21.7555 17.4428 22.2762C16.9221 22.7969 16.0779 22.7969 15.5572 22.2762L10.2238 16.9428C9.70314 16.4222 9.70314 15.5779 10.2238 15.0572L15.5572 9.7239C16.0779 9.2032 16.9221 9.2032 17.4428 9.7239Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83331 16C9.83331 15.2636 10.4303 14.6666 11.1666 14.6666H21.8333C22.5697 14.6666 23.1666 15.2636 23.1666 16C23.1666 16.7363 22.5697 17.3333 21.8333 17.3333H11.1666C10.4303 17.3333 9.83331 16.7363 9.83331 16Z"
      />
    </svg>
  );
});
export default LeftArrowCircleIcon;
