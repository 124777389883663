import { BudgetTypeEnum } from '../formElements/BudgetType';

const getBudgetTypeLabel = (budgetType: string | null, type: string | null) => {
  switch (budgetType) {
    case BudgetTypeEnum.Fixed:
      return type === 'Full-time' ? 'Annual salary' : 'Fixed amount';
    case BudgetTypeEnum.Hourly:
      return 'Hourly rate';
    default:
      return null;
  }
};

const getWorkStartLabel = (workStart: string | null) => {
  switch (workStart) {
    case '0':
      return 'Immediately';
    case '1':
      return '2 to 4 weeks from now';
    case '2':
      return '4 to 6 weeks from now';
    default:
      return null;
  }
};

export { getBudgetTypeLabel, getWorkStartLabel };
