export const DEFAULT_COVER_PHOTO_URL = '/img/cover-photo.png';
export const DEFAULT_WORK_COVER_PHOTO_URL = '/img/work-cover-photo.png';
export const DEFAULT_ONBOARDING_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_ONBOARDING_LOGO_URL = '/img/logo.png';
export const DEFAULT_LOGIN_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_RESET_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_FORGOT_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_SENT_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_LOGIN_REQUIRED_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_UPGRADE_REQUIRED_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_ENTER_PASSWORD_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_WELCOME_BACK_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_NOT_FOUND_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_TEAM_INVITE_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
export const DEFAULT_TEAM_LOGIN_COVER_PHOTO_URL = '/img/onboarding-cover-photo.png';
