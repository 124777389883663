import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Location } from '../../../membership/formElements';
import Messaging from '../../../../services/Messaging';
import MembershipContext from '../../../membership/Context';
import useStep from '../../../stepper/hooks/useStep';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

const Form = () => {
  const { membership, update: updateMembership } = useContext(
    MembershipContext
  );
  const {
    goToNextStep,
    setOnNext,
    setCanAdvance,
    isLoading,
    setLoading,
  } = useStep();
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      location: {
        latitude: membership?.location?.latitude || '',
        longitude: membership?.location?.longitude || '',
        friendlyName: membership?.location?.friendlyName || '',
      },
    },
  });
  const { handleSubmit, register, errors, reset, formState } = form;
  const { isValid, isDirty } = formState;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AgencyLocation Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) return;

      // if (!isDirty) {
      //   goTo('next');
      //   return;
      // }

      setLoading(true);
      updateMembership(values)
        .then(() => {
          reset({
            location: values.location,
          });
          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, isLoading, reset, setLoading, updateMembership]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Location
        errors={errors}
        register={register}
        defaultValue={membership?.location?.friendlyName || ''}
        form={form}
        label="Agency location"
      />
    </form>
  );
};

export default Form;
