import React from 'react';
import Page from '../components/core/Page';
import Detail from '../components/perk/PerksDetail';

const PerksDetail = () => {
  return (
    <Page
      authGuard
      pageTitle="Perks Detail"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
      noScroll
    >
      <Detail />
    </Page>
  );
};
export default PerksDetail;
