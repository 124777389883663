import React from 'react';
import querystringify from 'querystringify';
import Signup from '../components/user/views/Signup';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';

const SignupPage = () => {
  const { redirectUrl, type } = querystringify.parse(
    window.location.search
  ) as any;

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Sign Up"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      <SignupLayout>
        <>
          <Signup redirectUrl={redirectUrl} type={type} />
        </>
      </SignupLayout>
    </Page>
  );
};

export default SignupPage;
