import React, { useState } from 'react';
import Button from '../core/Button';
import Modal from '../core/modal/Modal';

type Props = {
  page: string;
};

const CandidateDateButton = ({ page }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        type="button"
        color="primary"
        text="Book Meeting Time"
        className="whitespace-nowrap"
        onClick={() => setModalOpen(true)}
      />
      {modalOpen ? (
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title="Time Select"
          width="3xl"
        >
          <div className="grow overflow-x-hidden">
            <iframe
              className="w-full"
              src={page}
              title="Schedule Date Selector"
              height="650"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default CandidateDateButton;
