import React from 'react';
import { ListItemComponentProps } from '..';
import OverlayBase from './OverlayBase';

interface Props extends ListItemComponentProps {}

const Overlay = ({ item }: Props) => {
  const {
    actionUrl,
    heading,
    subHeading,
    tertiaryHeading,
    backgroundUrl,
  } = item;

  return (
    <OverlayBase
      link={actionUrl || ''}
      firstLine={heading || ''}
      secondLine={subHeading || ''}
      thirdLine={tertiaryHeading || ''}
      backgroundUrl={backgroundUrl || ''}
    />
  );
};
export default Overlay;
