import React, { useContext } from 'react';
import { translateCompanySize } from '@communo-corp/shared-types';

import ButtonLink from '../core/ButtonLink';
import ProfileCoverPhoto from '../profile/ProfileCoverPhoto';
import MembershipProfilePhoto from '../profile/MembershipProfilePhoto';
import { LoadingBalls } from '../core/Loading';
import ApplicationMetrics from './ApplicationMetrics';
import UserContext from '../user/Context';

type Props = {
  membership: MembershipEntity;
  application: ApplicationEntity;
  work: WorkEntity;
  role: string | undefined;
};

const CandidatesAgencyProfile = ({
  membership,
  application,
  work,
  role,
}: Props) => {
  const { isPublic } = useContext(UserContext);
  const size = membership?.size ? translateCompanySize(membership.size) : null;
  const { scoreMetrics } = application;

  if (!membership) {
    return <LoadingBalls isActive={!membership} />;
  }

  const userName = membership?.name;
  const showViewProfileButton = !isPublic;

  return (
    <div className="profile profile-company pb-0">
      <ProfileCoverPhoto url={membership.coverPhotoURL} altText={userName} />

      <MembershipProfilePhoto url={membership.logoURL} altText={userName} />

      <div className="p-4 flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-px items-center">
          <h4>{userName}</h4>
          <p>{role}</p>
          {membership.location?.friendlyName ? (
            <p>{membership.location?.friendlyName}</p>
          ) : null}
        </div>

        {size ? (
          <div className="flex flex-col items-center">
            <div className="profile__section-title mt-0">Company Size</div>
            <p className="-mt-1">{size}</p>
          </div>
        ) : null}

        {showViewProfileButton && (
          <ButtonLink
            color="primary"
            size="sm"
            href={`/members/membership/${membership.id}`}
            text="View Full Profile"
            className="w-full"
          />
        )}
      </div>
      {membership ? (
        <ApplicationMetrics metrics={scoreMetrics.criteria} work={work} />
      ) : null}
    </div>
  );
};
export default CandidatesAgencyProfile;
