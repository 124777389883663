import moment from 'moment';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import { VideoCallStats } from './Context';

const Avatar = ({ user }: { user: UserEntity }) => {
  if (!user) return null;

  if (user?.avatarURL) {
    return (
      <img
        className="mr-4 w-16 h-16"
        src={user.avatarURL}
        alt={user.firstName}
      />
    );
  }

  const initials = `${user.firstName[0]}${user.lastName[0]}`;
  return (
    <div className="avatar avatar--square mr-4 w-16 h-16">
      <p className="avatar__initials text-lg">{initials}</p>
    </div>
  );
};

const CallStats = ({ stats }: { stats: VideoCallStats }) => {
  const duration =
    stats.callStart && stats.callEnd
      ? moment(moment(stats.callEnd).diff(stats.callStart))
      : moment();

  const totalSpoken = Object.keys(stats.speakerStats)
    .map(key => stats.speakerStats[key]?.time || 0)
    .reduce((prev, cur) => prev + cur);

  return (
    <div className="text-white text-center m-auto">
      <p className="text-xl">Last call stats</p>
      <p className="mt-2">Call duration: {duration.format('m[m] s[s]')}</p>
      {stats.totalParticipants.length >= 2 && (
        <p>Total time spoken: {moment(totalSpoken).format('m[m] s[s]')}</p>
      )}
      <div className="mt-6">
        <p className="text-lg">Room:</p>
        <Link to={`/video-chat/${stats.room}`}>{stats.room}</Link>
      </div>
      <div className="mt-6">
        <p className="text-lg">Call participants:</p>

        <div className="mt-2">
          {stats.totalParticipants
            .sort(
              (a: UserEntity, b: UserEntity) =>
                stats.speakerStats[b.id].time - stats.speakerStats[a.id].time
            )
            .map((user: UserEntity) => {
              const speakTime = stats.speakerStats[user.id]?.time || 0;
              const speakFrac = (speakTime / totalSpoken) * 100;

              return (
                <div
                  className="flex content-center mb-3 mx-auto w-64"
                  key={user.id}
                >
                  <Avatar user={user} />
                  <div className="flex flex-col text-left">
                    <p>{`${user.firstName} ${user.lastName}`}</p>
                    {stats.totalParticipants.length >= 2 && (
                      <p>
                        {`Spoke for ${moment(speakTime).format(
                          'm[m] s[s]'
                        )} (${speakFrac.toFixed(0)}%)`}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CallStats;
