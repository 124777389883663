import React, { forwardRef, useCallback, useRef } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { CollectionSummary } from '@communo-corp/shared-types';
import Button from '../../core/Button';
import { FormGroup, FormImage, FormInput, FormTextarea } from '../../core/form';
import Api from '../Api';
import FormToggle from '../../core/form/FormToggle';
import Modal from '../../core/modal/Modal';
import useModal from '../../core/modal/hooks/useModal';
import FormColorPicker from '../../core/form/FormColorPicker';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../../../asset-config';
import useCurrentMembership from '../../../hooks/useCurrentMembership';

type CreatedCollectionSummary = Omit<
  CollectionSummary,
  'id' | 'userCount' | 'userId' | 'type' | 'membershipId' | 'recentUsers'
> & {
  attachToMembership: string | boolean;
  brandCoverPhotoURL?: string | null;
  brandLogoURL?: string | null;
};

type Props = {
  userIds?: string[];
  onClose: (collection: CollectionSummary | null) => void;
  isOpen: boolean;
};

interface ContentProps {
  form: UseFormMethods<any>;
  onSubmit(values: any): void;
  membership: MembershipEntity;
}

const Content = forwardRef(
  ({ form, onSubmit, membership }: ContentProps, ref: any) => {
    const nameMaxLength = 50;
    const descriptionMaxLength = 60;

    const defaultBrandLogoURL =
      membership.logoURL || DEFAULT_ONBOARDING_LOGO_URL;
    const defaultBrandCoverPhotoURL =
      membership.coverPhotoURL || DEFAULT_ONBOARDING_COVER_PHOTO_URL;

    return (
      <form
        id="createCollection"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
      >
        <FormInput
          name="name"
          label="Name"
          errors={form.errors}
          placeholder={`${nameMaxLength} characters max`}
          maxLength={nameMaxLength}
          ref={(e: any) => {
            // eslint-disable-next-line
            ref.current = e;
            form.register(e, {
              required: 'Name is required',
              maxLength: {
                value: nameMaxLength,
                message: `Max ${nameMaxLength} characters`,
              },
            });
          }}
        />
        <FormTextarea
          name="description"
          label="Description (optional)"
          placeholder=""
          maxLength={descriptionMaxLength}
          errors={form.errors}
          ref={form.register({
            maxLength: {
              value: descriptionMaxLength,
              message: `Max ${descriptionMaxLength} characters`,
            },
          })}
          richText={false}
          rows={3}
          characterCount={descriptionMaxLength}
          form={form}
        />
        <FormToggle
          control={form.control}
          label="Sharing"
          name="attachToMembership"
          hint="Set to Company to share this list with members of your team."
          leftValue="company"
          leftLabel="Company"
          rightValue="private"
          rightLabel="Private"
        />

        <FormImage
          name="brandLogoURL"
          label="Brand Logo"
          onChange={() => {}}
          errors={form.errors}
          form={form}
          ref={form.register}
          aspectRatio={[1, 1]}
          defaultValue={defaultBrandLogoURL}
        />
        <FormImage
          name="brandCoverPhotoURL"
          label="Cover Photo"
          aspectRatio={[2, 1]}
          onChange={() => {}}
          errors={form.errors}
          form={form}
          ref={form.register}
          defaultValue={defaultBrandCoverPhotoURL}
        />
        <FormGroup label="Brand Color" name="brandColor" errors={form.errors}>
          <FormColorPicker
            form={form}
            name="brandColor"
            defaultValue="#1F2326"
          />
        </FormGroup>
      </form>
    );
  }
);

const Footer = () => {
  const { closeModal } = useModal();

  const handleClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <>
      <Button text="Cancel" fill="outline" size="sm" onClick={handleClick} />
      <Button
        type="submit"
        form="createCollection"
        text="Create List"
        fill="solid"
        color="primary"
        size="sm"
      />
    </>
  );
};

const CreateCollectionModal = ({
  userIds = [],
  onClose,
  isOpen = false,
}: Props) => {
  const nameInputRef = useRef<HTMLInputElement>();
  const { membership } = useCurrentMembership();

  const form = useForm<CreatedCollectionSummary>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: '',
      description: '',
      attachToMembership: 'private',
      brandColor: '#1F2326',
      brandCoverPhotoURL: null,
      brandLogoURL: null,
    },
  });

  const onSubmit = useCallback(
    async (values: any) => {
      const data = {
        name: values.name,
        description: values.description || null,
        attachToMembership: values.attachToMembership === 'company',
        userIds,
        brandColor: values.brandColor || null,
        coverPhotoURL: values.brandCoverPhotoURL || null,
        logoURL: values.brandLogoURL || null,
      };

      const collection = await Api.create<CreatedCollectionSummary>(data);

      onClose(collection);
    },
    [onClose, userIds]
  );

  if (!membership) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Create a new list"
      width="md"
      onClose={onClose}
      onOpen={() => {
        if (nameInputRef.current) nameInputRef.current.focus();
      }}
      footer={<Footer />}
    >
      <Content
        form={form}
        onSubmit={onSubmit}
        ref={nameInputRef}
        membership={membership}
      />
    </Modal>
  );
};
export default CreateCollectionModal;
