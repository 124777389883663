import { useContext, useEffect } from 'react';
import PullToRefreshContext from '../context/PullToRefreshContext';

export const usePullToRefreshContext = () => {
  return useContext(PullToRefreshContext);
};

export const usePullToRefresh = (
  refreshFunction: Function,
  finishedRefreshing: boolean
) => {
  const { setRefreshHandler, onRefreshFinish } = useContext(
    PullToRefreshContext
  );

  useEffect(() => {
    setRefreshHandler(() => {
      refreshFunction();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (finishedRefreshing) {
      onRefreshFinish();
    }
    // eslint-disable-next-line
  }, [finishedRefreshing]);
};

export default usePullToRefresh;
