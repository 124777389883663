import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import ImageUploader from '../../image/ImageUploader';
import FormGroup from './FormGroup';
import cloudinary from '../../../utilities/Cloudinary';
import Button from '../Button';

type Props = {
  name: string;
  label?: string;
  errors?: any;
  hideAvatar?: boolean;
  onChange(url: string | null): any;
  form: any;
  aspectRatio?: [number, number] | null;
  defaultValue?: string | null;
  btnClassName?: string;
  squarePreview?: boolean;
  roundCrop?: boolean;
  refresh?: Function;
};

export default React.forwardRef(
  (
    {
      onChange,
      name,
      label,
      errors,
      form,
      hideAvatar = false,
      aspectRatio = [1, 1],
      defaultValue = null,
      btnClassName = 'btn btn--sm btn--primary',
      squarePreview = false,
      roundCrop = false,
      refresh,
    }: Props,
    ref: any
  ) => {
    const imageRef = useRef();
    const { setValue } = form;
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(
      defaultValue || null
    );

    useEffect(() => {
      setImageUrl(defaultValue);
    }, [defaultValue]);

    // Because null == undefined is true, will catch both null and undefined.
    let calculatedRatio = 1;
    if (aspectRatio != null) {
      calculatedRatio = aspectRatio[1] / aspectRatio[0];
    }

    function browseFiles(pondRef: any) {
      pondRef.current.removeFile();
      pondRef.current.browse();
    }

    function getCloudinaryCrop(ratio: number) {
      switch (ratio) {
        case 1:
          // 1:1 - square/avatar
          return ['w_300', 'h_300'];

        case 1.7777777777777777:
          // 9:16
          return ['w_800', 'h_1600'];

        case 2:
          // 9:16
          return ['w_800', 'h_1600'];

        default:
          // 16:9
          return ['w_1600', 'h_800'];
      }
    }

    function getPaddingBottom(ratio: number) {
      if (ratio === 1) {
        return 0;
      }

      const percentage = ratio * 100;

      return `${percentage}%`;
    }

    function getClassNames(ratio: number) {
      switch (ratio) {
        case 1:
          return 'avatar avatar--2xl border-4 border-white';

        default:
          return 'rounded-lg+';
      }
    }

    const handleOnChange = (imageURL: string) => {
      setImageUrl(imageURL);
      onChange(imageURL);
      setValue(name, imageURL, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (refresh) refresh();
      setLoading(false);
    };

    function removeImage() {
      setImageUrl(null);
      onChange('');
      setValue(name, '', {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (refresh) refresh();
      setLoading(false);
    }

    let classNames = getClassNames(calculatedRatio);

    if (squarePreview) {
      classNames += ' rounded-lg+  avatar--square';
    }

    const buttonLabelAction = imageUrl ? 'Change' : 'Upload';
    const buttonLabelSubject = // making label lowercase since the action will be uppercase, and we use sentence case on the site
      label?.toLowerCase() || (calculatedRatio === 1 ? 'avatar' : 'photo');
    const buttonLabel = `${buttonLabelAction} ${buttonLabelSubject}`;

    return (
      <FormGroup name={name} errors={errors}>
        <ImageUploader
          dokaConfig={roundCrop ? {} : { cropMask: null }}
          aspectRatio={calculatedRatio}
          ref={imageRef}
          onChange={handleOnChange}
          setLoading={setLoading}
        >
          <>
            <div>
              {!hideAvatar && (
                <div className="w-full mb-4">
                  <div
                    className={`${classNames} relative overflow-hidden bg-grey-300`}
                    style={{
                      paddingBottom: getPaddingBottom(calculatedRatio),
                    }}
                  >
                    {imageUrl && (
                      <img
                        src={cloudinary(
                          imageUrl,
                          getCloudinaryCrop(calculatedRatio)
                        )}
                        alt="base64"
                        role="none"
                        className="absolute top-0 w-full h-full object-cover cursor-pointer rounded-xl"
                        onClick={() => browseFiles(imageRef)}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="flex justify-between flex-wrap gap-4">
                <Button
                  onClick={() => browseFiles(imageRef)}
                  loading={loading}
                  className={cn(btnClassName, 'btn--outline')}
                >
                  {buttonLabel}
                </Button>
                {imageUrl ? (
                  <Button
                    onClick={removeImage}
                    loading={loading}
                    className={btnClassName}
                  >
                    Remove {buttonLabelSubject}
                  </Button>
                ) : null}
              </div>
            </div>
          </>
        </ImageUploader>
        <input name={name} type="hidden" ref={ref} />
      </FormGroup>
    );
  }
);
