import React, { useCallback } from 'react';
import useNavigate from '../../hooks/useNavigate';
import UpgradeFlow from './UpgradeFlow';

export const DirectUpgradeFlow = ({ slug, coupon }: any) => {
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate('/feed');
  }, [navigate]);

  return (
    <UpgradeFlow
      slug={slug}
      coupon={coupon}
      flow={{ name: 'directUpgrade' }}
      onClose={onClose}
    />
  );
};

export default DirectUpgradeFlow;
