import { Api } from '../../Api';

export default {
  createProject: (data: PortfolioEntity) => Api.post(`/portfolio`, data),
  updateProject: (portfolioId: string, data: any) =>
    Api.patch(`/portfolio/${portfolioId}`, data),
  getProject: (portfolioId: string) => Api.get(`/portfolio/${portfolioId}`),
  deleteProject: (portfolioId: string) =>
    Api.delete(`/portfolio/${portfolioId}`),
};
