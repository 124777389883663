import React from 'react';
import SkillsMetric from './metrics/Skills';
import IndustryMetric from './metrics/Industry';
import RoleMetric from './metrics/Role';

type Props = {
  isUser: boolean;
  work: WorkEntity;
  scoreMetrics: ScoreMetrics;
  showRoleMetric?: boolean;
};

const ScoreMetrics = ({
  isUser,
  work,
  scoreMetrics,
  showRoleMetric = true,
}: Props) => {
  return (
    <>
      {work.skills.length > 0 ? (
        <>
          <div className="mt-2 font-normal">Skills</div>
          <SkillsMetric
            isUser={isUser}
            skills={work.skills}
            scoreMetrics={scoreMetrics}
          />
        </>
      ) : null}

      {work.industries.length > 0 && (
        <>
          <div className="mt-2 font-normal">Industry</div>
          <IndustryMetric
            industries={work.industries}
            scoreMetrics={scoreMetrics}
          />
        </>
      )}

      {showRoleMetric ? (
        <>
          <div className="mt-2 font-normal">Role Match</div>
          <RoleMetric role={work.role} scoreMetrics={scoreMetrics} />
        </>
      ) : null}
    </>
  );
};

export default React.memo(ScoreMetrics);
