import React, { useState } from 'react';
import { IonHeader } from '@ionic/react';
import { isMobile } from '../../utilities/MatchMedia';

const UpdateAvailable = () => {
  const [reloading, setReloading] = useState(false);
  const handleClick = () => {
    setReloading(true);
    window.location.reload();
  };

  return (
    <IonHeader className="bg-red-900 text-white pt-safe">
      <div
        role="none"
        onClick={() => isMobile && handleClick()}
        className="flex justify-center items-stretch h-10 md:h-auto md:py-1 text-center font-medium container text-sm md:text-base"
      >
        <div className="flex items-center">
          Psst… a newer version is available!{' '}
          <div
            role="none"
            onClick={() => !isMobile && handleClick()}
            className="font-medium underline cursor-pointer uppercase ml-4 flex items-center self-stretch"
          >
            {reloading ? 'Reloading…' : 'Reload'}
          </div>
        </div>
      </div>
    </IonHeader>
  );
};

export default React.memo(UpdateAvailable);
