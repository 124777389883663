import { useCallback, useContext, useState } from 'react';
import UserContext from '../components/user/Context';
import { addResponseError } from '../services/Messaging';
import useNavigate from './useNavigate';

export default function useMagicLinkLogin() {
  const { sendMagicLink: sendMagicLinkApi } = useContext(UserContext);
  const navigate = useNavigate();
  const [sendingMagicLink, setSendingMagicLink] = useState(false);

  const sendMagicLink = useCallback(
    (email: string) => {
      if (sendingMagicLink) return;
      setSendingMagicLink(true);

      sendMagicLinkApi({ email })
        .then(() => {
          setSendingMagicLink(false);
          navigate('/auth/sent-magic', 'forward', 'push');
        })
        .catch((error: ResponseError) => {
          setSendingMagicLink(false);
          addResponseError(error);
        });
    },
    [navigate, sendMagicLinkApi, sendingMagicLink]
  );

  return {
    sendMagicLink,
    sendingMagicLink,
  };
}
