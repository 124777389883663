/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  autoGrow?: boolean;
};

const Textarea = (
  {
    name,
    placeholder = '',
    rows = 10,
    autoGrow = false,
    className = '',
    ...rest
  }: TextAreaProps,
  ref: any
) => {
  const target = ref?.current;
  const initialHeight = useRef();

  const classes = ['form-input', 'resize-none'];

  if (autoGrow) classes.push('autogrow');
  if (className) classes.push(className);

  const handleShrink = useCallback(() => {
    if (!autoGrow || !target) return;
    // Requires a 100ms timeout so the blur event
    // doesn't cause layout shift which can cause missed click events
    // See: Composer.tsx
    setTimeout(() => {
      target.style.height = `${initialHeight.current}px`;
    }, 100);
  }, [autoGrow, target]);

  const handleGrow = useCallback(() => {
    if (!autoGrow || !target) return;
    if (!initialHeight.current) initialHeight.current = target?.clientHeight;
    // eslint-disable-next-line consistent-return
    if (target.value.length === 0) return handleShrink();
    // auto ensures the textarea shrinks if line height changes
    // however, it messes up the grow transition
    // target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  }, [autoGrow, target, handleShrink]);

  return (
    <textarea
      id={name}
      name={name}
      placeholder={placeholder}
      className={classes.join(' ')}
      rows={rows}
      ref={ref}
      onKeyUp={handleGrow}
      onBlur={handleShrink}
      onFocus={handleGrow}
      {...rest}
    />
  );
};

export default React.forwardRef(Textarea);
