import React from 'react';
import OneClickApplyCTA from './OneClickApplyCTA';
import ProfileMetrics, { getProfileScorePercentage } from './metrics/Profile';
import { ScoreCardProps } from './CandidateScore';

const ExternalCandidateScoreCard = ({
  isUser,
  work,
  scoreMetrics,
  refresh,
  isHidden,
}: ScoreCardProps) => {
  const profileScorePercentage = getProfileScorePercentage(
    isUser,
    scoreMetrics
  );

  return (
    <>
      <div>
        <h6 className="mb-2">Profile Completion</h6>
        <ProfileMetrics
          isUser={isUser}
          scoreMetrics={scoreMetrics}
          isHidden={isHidden}
        />
      </div>

      <div className="sm:col-span-2 md:col-span-1 lg:col-span-2">
        <OneClickApplyCTA
          score={profileScorePercentage}
          work={work}
          refresh={refresh}
        />
      </div>
    </>
  );
};
export default ExternalCandidateScoreCard;
