import numeral from 'numeral';

const formatCurrency = (n: number, optionalDecimals = true) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: optionalDecimals ? 0 : 2,
  }).format(n);

const formatDecimal = (n: number) =>
  Intl.NumberFormat('en-US', { style: 'decimal' }).format(n);

const formatPercent = (n: number) =>
  Intl.NumberFormat('en-US', { style: 'percent' }).format(n);

const limitMax = 5000;

export const number = (n: any) => numeral(n).value();
export const decimal = (n: any) => formatDecimal(number(n));
export const percent = (n: any) => formatPercent(number(n));
export const thousands = (n: any) => numeral(n).format('0,0');
export const limit = (n: number, max: number = limitMax) =>
  n > max ? `over ${thousands(max)}` : thousands(n);
export const currency = (n: any, optionalDecimals = true) =>
  formatCurrency(number(n), optionalDecimals);
