import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import UserContext from '../Context';
import Api from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import { Password } from '../formElements';
import { SubmitButton } from '../../core/Button';

interface Props {
  resetCode: string;
}

export default ({ resetCode }: Props) => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { isSubmitted, isValid } = formState;

  const doLogin = (data: LoginUser) => {
    userContext
      .authenticate(data)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error);
      });
  };

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const { password } = values;

    Api.resetPassword({ password, passwordResetCode: resetCode })
      .then(({ email }: { email: string }) => {
        setLoading(false);
        addSuccess('Password has been reset!');
        // login user
        doLogin({ email, password });
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col grow"
    >
      <Password errors={isSubmitted ? errors : {}} register={register} />

      <div className="mt-auto md:mt-8 text-center">
        <SubmitButton
          loading={loading}
          className={`btn--full ${!isValid ? 'disabled' : ''}`}
          text="Update Password"
        />
      </div>
    </form>
  );
};
