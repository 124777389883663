import React from 'react';
import cn from 'classnames';
import { CollectionSummary } from '@communo-corp/shared-types';
import Button from '../../core/Button';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import useFeatureGate from '../../../hooks/useFeatureGate';

type Props = {
  collection: CollectionSummary;
  inviteCount: number;
  limit: number | null;
  allowUpgrade?: boolean;
  children: (props: { inviteCount: number; limit: number }) => JSX.Element;
};

const EmailInviteLimit = ({
  collection,
  inviteCount,
  limit,
  allowUpgrade = true,
  children,
}: Props) => {
  const { checkAccess } = useCurrentMembership();
  const { openUpgradeFlowFor } = useFeatureGate();

  if (limit === null) {
    return null;
  }

  const underLimit = inviteCount < limit;

  const permission =
    collection.type === 'bench' ? 'benchSizeLimit' : 'listSizeLimit';
  const allowedToInvite = checkAccess(permission, inviteCount + 1);

  return (
    <div
      className={cn(
        'flex items-center justify-between mt-4 p-4 border-t-2 rounded-sm shadow',
        {
          'border-green-500': underLimit,
          'border-red-500': !underLimit,
        }
      )}
    >
      <p className="m-0">{children({ inviteCount, limit })}</p>

      {allowUpgrade && !allowedToInvite ? (
        <Button
          type="button"
          fill={underLimit ? 'outline' : 'solid'}
          color={underLimit ? undefined : 'primary'}
          text="Upgrade"
          // eslint-disable-next-line no-alert
          onClick={() => openUpgradeFlowFor(permission, inviteCount + 1)}
        />
      ) : null}
    </div>
  );
};
export default EmailInviteLimit;
