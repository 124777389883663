import React, { useContext, useState } from 'react';
import cn from 'classnames';
import MembershipContext from '../membership/Context';
import AddUsersToCollectionModal from '../collections/components/AddUsersToCollectionModal';
import DownloadIcon from '../icons/DownloadIcon';

type ModalStates = 'closed' | 'confirming' | 'collection';

type Props = {
  userIds: string[];
};
const ExportCandidatesToList = ({ userIds = [] }: Props) => {
  const { checkAccess } = useContext(MembershipContext);
  const [isAddingToCollection, setAddingToCollection] = useState<boolean>(
    false
  );

  if (!checkAccess('canAccessCollections')) {
    return null;
  }

  const disabled = userIds.length === 0;

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={cn('mr-2 text-lg', {
          'text-grey-200 cursor-not-allowed': disabled,
          'text-grey-800 hover:text-grey-1000': !disabled,
        })}
        onClick={() => setAddingToCollection(true)}
      >
        <DownloadIcon className="w-6 h-6 inline" />
      </button>

      {isAddingToCollection ? (
        <AddUsersToCollectionModal
          userIds={userIds}
          onClose={() => setAddingToCollection(false)}
        />
      ) : null}
    </>
  );
};
export default ExportCandidatesToList;
