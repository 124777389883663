import React from 'react';

type ForceMobileContextState = {
  forceMobile: boolean;
  showFullProfile: boolean;
};

const ForceMobileContext = React.createContext<ForceMobileContextState>({
  forceMobile: false,
  showFullProfile: false,
});

export const ForceMobileContextProvider = ({
  children,
  value = {
    showFullProfile: true,
  },
}: {
  children: any;
  value?: { showFullProfile: boolean };
}) => {
  return (
    <ForceMobileContext.Provider value={{ forceMobile: true, ...value }}>
      {children}
    </ForceMobileContext.Provider>
  );
};

export const ForceMobileContextConsumer = ForceMobileContext.Consumer;

export default ForceMobileContext;
