import React from 'react';
import FormDateTime from '../../core/form/FormDateTime';

interface Props {
  errors?: any;
  defaultValue?: number | null;
  onChange(milliseconds: number | null): any;
}

const Expiry = React.forwardRef(
  ({ errors, defaultValue, onChange }: Props, ref: any) => {
    let startDate;

    if (defaultValue) {
      startDate = new Date(defaultValue);
    }

    return (
      <FormDateTime
        name="expiresAt"
        label="Expiry"
        hint="Time will be set to the end of the day at 11:59PM"
        errors={errors}
        ref={ref}
        onChange={date => {
          // Time in milliseconds. Epoch.
          onChange(date ? date.valueOf() : null);
        }}
        showTime={false}
        startDate={startDate}
      />
    );
  }
);
export default Expiry;
