import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { About, Intro } from '../formElements';
import { SubmitButton } from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Video from '../../user/formElements/Video';
import { canPlayVideo } from '../../video/VideoPlayer';

interface Props {
  membership: MembershipEntity;
  update: (data: any) => Promise<MembershipEntity>;
  refresh: () => void;
}

const AboutForm = ({ membership, update, refresh }: Props) => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      summary: membership.summary || '',
      about: membership.about || '',
      videoUrl: membership.videoUrl || null,
    },
  });
  const { setValue, handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = { ...values, about: values.about?.html ?? null };

    if (!canPlayVideo(data.videoUrl)) {
      data.videoUrl = null;
    }

    update(data)
      .then((response: MembershipEntity) => {
        addSuccess('Membership updated!');
        setValue('videoUrl', response?.videoUrl);
        refresh();
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {membership && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Intro errors={errors} register={register} form={form} />
          <About
            errors={errors}
            register={register}
            form={form}
            defaultValue={membership.about}
          />
          <Video form={form} />
          <FormActions className="mt-auto justify-center">
            <div className="md:ml-auto">
              <SubmitButton text="Save Changes" />
            </div>
          </FormActions>
        </form>
      )}
    </>
  );
};

export default AboutForm;
