/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import ScoreAvatar from '../../candidateScore/ScoreAvatar';
import { ScoredCandidate } from './SuggestedCandidatesProvider';

type SuggestedCandidateProps = {
  candidate: ScoredCandidate;
  onSelect: (candidate: ScoredCandidate) => void;
};

/**
 * Renders a card component for the passed candidate information
 *
 * @param param0.candidate - the candidate rendered for the card
 * @param param0.onSelect - callback handler for when the card is clicked
 */
function SuggestedCandidate({ candidate, onSelect }: SuggestedCandidateProps) {
  const [candidateScore, setCandidateScore] = useState(0);

  const { firstName, lastName, roles } = candidate;

  const planType = candidate.primaryMembershipUser?.membership.plan.type;

  const membership = candidate.primaryMembershipUser?.membership;

  const name =
    planType === 'agency' && membership
      ? membership.name
      : `${firstName} ${lastName}`;
  const role =
    planType === 'agency' && membership?.roles
      ? membership.roles.map(r => r.role.nameForMembership).join(', ') ||
        'Unknown role'
      : roles?.map(r => r.role.name).join(', ') || 'Unknown role';

  const handleCandidateClick = () => {
    onSelect(candidate);
  };

  useEffect(() => {
    if (candidate?.candidateScore) {
      setCandidateScore(candidate.candidateScore?.percentage);
    }
  }, [candidate]);

  return (
    <div className="col-span-1 w-56 p-4" key={candidate.id}>
      <div
        role="button"
        onClick={handleCandidateClick}
        className="active:rotate-12 transition-transform origin-center"
      >
        <div className="shadow-lg hover:shadow-xl bg-white relative ">
          <div className="p-4">
            <div className="relative flex flex-col justify-center mb-2 w-full">
              <ScoreAvatar
                name={name}
                imageUrl={candidate.avatarURL}
                percentage={candidateScore}
                size="40"
              />
              <div className="truncate">
                <div className="font-medium truncate mb-1">{name}</div>
                <div className="text-sm font-light truncate">{role}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuggestedCandidate;
