import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

const types = ['Conference', 'Networking', 'Speaker', 'Webinar', 'Workshop'];

export default ({ errors, register }: any) => {
  const ref = register({ required: 'Event type is required' });
  return (
    <FormGroup name="type" label="Choose event type" errors={errors}>
      {types.map((t: string) => (
        <FormRadio key={t} name="type" value={t} label={t} ref={ref} />
      ))}
    </FormGroup>
  );
};
