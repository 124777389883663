import React, { useContext, useState } from 'react';
import UserContext from '../user/Context';
import SchedulePermissionButton from './SchedulePermissionButton';
import useImportScript from '../../hooks/useImportScript';
import useSchedulerPages from './hooks/useSchedulerPages';
import { LoadingBalls, LoadingRing } from '../core/Loading';
import Modal from '../core/modal/Modal';
import SchedulerAdd from './SchedulerAdd';

import Button from '../core/Button';
import { addInfo } from '../../services/Messaging';

declare type DeleteStateProp = {
  modalOpen: boolean;
  pageId: string | null;
};

const defaultDeleteState: DeleteStateProp = { modalOpen: false, pageId: null };

const SchedulerEditor = () => {
  useImportScript(
    'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js'
  );

  const { user } = useContext(UserContext);
  const {
    data,
    isLoading,
    refetch,
    schedulerDelete,
    scheduleDeleteStatus,
  } = useSchedulerPages();
  const [deleteState, setDeleteState] = useState(defaultDeleteState);

  const handleEditClick = (editToken: string): void => {
    (window as any).nylas.scheduler
      .show({
        auth: {
          pageEditToken: editToken,
        },
        style: {
          tintColor: '#1F2326',
          backgroundColor: 'white',
        },
      }) // refetches meetings on modal Close.
      .addEventListener('close', refetch);
  };

  const handleDeleteClick = (meetingId: string): void => {
    setDeleteState({ modalOpen: true, pageId: meetingId });
  };

  const handleConfirmDelete = () => {
    schedulerDelete(deleteState!.pageId as string);
    setDeleteState(defaultDeleteState);
  };

  const copyToClipboard = (meetingSlug: string) => {
    navigator.clipboard.writeText(`https://schedule.nylas.com/${meetingSlug}`);
    addInfo('Meeting link copied to clipboard.');
  };

  if (!user?.nylasAccessTokenExists) {
    return (
      <div>
        <h5>Grant Scheduling Permission?</h5>
        <br />
        <SchedulePermissionButton />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingBalls isActive />;
  }

  return (
    <div>
      {data?.schedulerPages.length > 0 &&
        data.schedulerPages.map((meeting: any) => {
          return (
            <div key={meeting.id} className="flex">
              <Button
                id="schedule-meeting-event"
                type="button"
                // eslint-disable-next-line camelcase
                onClick={() => handleEditClick(meeting?.edit_token)}
                color="primary"
                className="m-1"
              >
                <i className="i-edit btn__icon" />
                <span className="btn__text">{meeting.config.event.title}</span>
              </Button>
              <Button
                id="copy-meeting-event"
                type="button"
                onClick={() => copyToClipboard(meeting.slug)}
                color="primary"
                className="m-1"
              >
                <i className="i-link btn__icon" />
                <span className="btn__text">Copy</span>
              </Button>
              <Button
                id="delete-meeting-event"
                type="button"
                onClick={() => handleDeleteClick(meeting.id)}
                color="primary"
                className="m-1"
              >
                <i className="i-trash btn__icon" />
                <span className="btn__text">Delete</span>
              </Button>
            </div>
          );
        })}
      {deleteState.modalOpen || scheduleDeleteStatus === 'loading' ? (
        <Modal
          isOpen={deleteState.modalOpen || scheduleDeleteStatus === 'loading'}
          onClose={() => setDeleteState(defaultDeleteState)}
          title="Warning"
          width="xl"
        >
          <div className="text-center">
            <h6>Deleting meeting will delete meeting for all invited users</h6>
            <br />

            {scheduleDeleteStatus !== 'loading' ? (
              <Button
                id="schedule-meeting-delete"
                type="button"
                onClick={handleConfirmDelete}
                color="primary"
                className="flex-1"
              >
                <i className="i-trash btn__icon" />
                <span className="btn__text">Delete Meeting</span>
              </Button>
            ) : (
              <LoadingRing isActive color="primary" size="sm" />
            )}
          </div>
        </Modal>
      ) : null}
      <SchedulerAdd />
    </div>
  );
};

export default SchedulerEditor;
