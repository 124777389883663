import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { IonList } from '@ionic/react';
import Channel from './Channel';
import useChatChannels from '../hooks/useChatChannels';
import useChatChannel from '../hooks/useChatChannel';
import ChannelPlaceholder from '../../core/placeholder/ChannelPlaceholder';
import { CHAT_CHANNELS_PER_PAGE } from '../../../config';
import { IChannel } from '../../../@types/chat.d';
import IntersectionLoader from '../../core/IntersectionLoader';
import { Link } from '../../core/router';

type ChannelPresentProps = {
  sid: string;
  isActive: boolean;
};

const ChannelPresenter = ({ sid, isActive }: ChannelPresentProps) => {
  const { channel, loaded } = useChatChannel(sid);

  if (!loaded || !channel) {
    return <ChannelPlaceholder />;
  }

  return <Channel channel={channel} isActive={isActive} />;
};

const Channels = () => {
  const { sid: activeChannelSid } = useParams<{ sid: string }>();
  const [channelsToShow, setChannelsToShow] = useState(CHAT_CHANNELS_PER_PAGE);
  const { activeChannels, hasMoreChannels, loadMoreChannels } = useChatChannels(
    channelsToShow
  );

  const handleLoadMore = useCallback(async () => {
    if (hasMoreChannels) {
      await loadMoreChannels(channelsToShow);
      setChannelsToShow(prev => prev + CHAT_CHANNELS_PER_PAGE);
    }
  }, [loadMoreChannels, channelsToShow, hasMoreChannels]);

  if (activeChannels.length) {
    return (
      <div className="chat-existing">
        <IonList lines="none">
          {activeChannels.map((channel: IChannel) => (
            <ChannelPresenter
              key={channel.sid}
              sid={channel.sid}
              isActive={activeChannelSid === channel.sid}
            />
          ))}

          {hasMoreChannels ? (
            <div className="py-4">
              <IntersectionLoader onIntersect={handleLoadMore} />
            </div>
          ) : null}
        </IonList>
      </div>
    );
  }

  return (
    <div className="text-center pt-8">
      <p className="mb-2">You currently have no messages.</p>
      <Link to="/chat/new-message">
        <button type="button" className="btn btn--primary">
          Start a new conversation
        </button>
      </Link>
    </div>
  );
};

export default Channels;
