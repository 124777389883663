export default (url: string) => {
  return {
    id: 'about',
    label: 'Tell us about yourself',
    description:
      'Everyone has a story to tell, and telling yours will help others understand what you can do for them.',
    icon: 'alert-warning',
    action: `#/${url}/about`,
    actionLabel: 'Add About',
    complete: false,
  };
};
