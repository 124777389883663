import React from 'react';

type Props = {
  background?: string;
  color?: string;
  children?: any;
  className?: string;
};

const Badge = ({
  color = 'white',
  background = 'red-900',
  children = null,
  className = '',
}: Props) => {
  const classes = [
    className,
    `text-${color}`,
    `bg-${background}`,
    'rounded py-2 px-2 leading-none font-bold text-xs flex items-center justify-center border-2 border-black',
  ];

  return <span className={classes.join(' ')}>{children}</span>;
};
export default Badge;
