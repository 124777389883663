import React from 'react';
import { percent } from '../../utilities/Number';

const Crumb = ({ className = '' }: { className?: string }) => {
  return <div className={`breadcrumb__crumb ${className}`} />;
};

const Breadcrumbs = ({
  total,
  current,
  color = 'primary',
}: BreadcrumbProps) => {
  const crumbs = [];
  const progress = Math.min((current - 1) / Math.max(total - 1, 1), 1);

  for (let i = 1; i <= total; i += 1) {
    const classes = [];

    if (i === current) {
      classes.push('breadcrumb__crumb--current');
    }

    if (i < current) {
      classes.push('breadcrumb__crumb--complete');
    }

    // center the only breadcrumb
    if (total === 1) classes.push('mx-auto');

    crumbs.push(<Crumb key={i} className={classes.join(' ')} />);
  }

  return (
    <div className={`breadcrumb w-56 mx-auto ${color}`}>
      {total > 1 && (
        <>
          <div className="breadcrumb__bar" />
          <div
            className="breadcrumb__progress"
            style={{
              width: percent(progress),
            }}
          />
          <div className="breadcrumb__trail">{crumbs}</div>
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;
