import React from 'react';
import { animated, useSpring } from 'react-spring';
import cn from 'classnames';

interface Props {
  label: string;
  percentage: number;
  className?: string;
}

const ReferenceAverage = ({ label, percentage, className }: Props) => {
  const spring = useSpring({
    from: { width: '0%' },
    to: { width: `${percentage}%` },
    config: {
      duration: 700,
    },
  });

  return (
    <div className={cn('flex items-center', className)}>
      <div className="w-2/5 text-sm font-semibold">{label}</div>
      <div className="flex-auto bg-grey-100 relative h-3">
        <animated.div
          className="top-0 left-0 h-full bg-green-900"
          style={spring}
        />
      </div>
    </div>
  );
};
export default ReferenceAverage;
