import Markdown from 'markdown-it';

// render straight up markdown, allow html and add line breaks
const render = (s: string) =>
  new Markdown({ html: true, breaks: true }).render(s);

// markdown inside of html can also be rendered :)
const renderInline = (s: string) =>
  new Markdown({ html: true, linkify: true }).renderInline(s);

// Rendering this way will allow <p>this **bold** text</p> to output <strong> html tags
export const markdown = (s: string | null | undefined) =>
  s ? renderInline(render(s)) : '';

export default markdown;
