import React, { useState, useEffect, useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import { FormGroup, Autocomplete } from '../../core/form';
import useWorkCompanies from '../../../hooks/useWorkCompanies';
import { Link } from '../../core/router';

interface Props
  extends Pick<
    AutocompleteProps,
    | 'form'
    | 'min'
    | 'max'
    | 'defaultValues'
    | 'isMulti'
    | 'isRequired'
    | 'isDisabled'
  > {
  labelOverride?: string | null;
  name?: string;
}

const WorkCompanies = ({
  form,
  defaultValues,
  isRequired = false,
  isDisabled = false,
  labelOverride = null,
  name = 'workCompanies',
}: Props) => {
  const { data: workCompanies } = useWorkCompanies();
  const { errors }: UseFormMethods = form;
  const label = labelOverride || 'Company';
  const [defaultOptions, setDefaultOptions] = useState<any>([]);

  const navClass =
    'hover:bg-teal-300 block px-3 h-10 flex justify-end items-center md:text-base';
  const iconClass = 'w-10 h-10 flex items-center justify-center mr-4';
  const handleClose = () => {};

  const getResults = useCallback(() => {
    if (!workCompanies) {
      return [];
    }

    return workCompanies.map((company: WorkCompany) => {
      return {
        label: company.name,
        value: company.id,
        logoURL: company.logoURL,
      };
    });
  }, [workCompanies]);

  useEffect(() => {
    setDefaultOptions(getResults());
  }, [workCompanies, getResults]);

  return (
    <>
      <FormGroup
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
      >
        <Autocomplete
          name={name}
          form={form}
          min={1}
          max={1}
          defaultValues={defaultValues}
          defaultOptions={defaultOptions}
          callback={getResults}
          isMulti={false}
          isRequired={isRequired}
          isCreatable={false}
          isDisabled={isDisabled}
          placeholder="Select a company"
        />
        <div className="grid grid-cols-3">
          <div className="col-start-3 col-end-3">
            <Link
              to="#/company/create"
              onClick={handleClose}
              className={navClass}
            >
              <div className={`${iconClass} text-xl`}>
                <IonIcon icon={addCircleOutline} />
              </div>
              Create a Company?
            </Link>
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default WorkCompanies;
