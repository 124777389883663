import { keyBy } from 'lodash';
import { IChannel } from '../../../@types/chat.d';

type Props = {
  [sid: string]: IChannel;
};

let Channels: Props = {};

export const add = (channels: IChannel[]) => {
  Channels = {
    ...Channels,
    ...keyBy(channels, 'sid'),
  };
};

export const remove = (channels: IChannel[]) =>
  channels.forEach(channel => delete Channels[channel.sid]);

export const update = (channels: IChannel[]) =>
  channels.forEach(channel => {
    Channels[channel.sid] = channel;
  });

export const select = (sid: string) => Channels[sid];
export const selectMany = (sids: string[]) => sids.map(sid => Channels[sid]);

const ChannelInstances = {
  add,
  remove,
  update,
  select,
  selectMany,
};

export default ChannelInstances;
