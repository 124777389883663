import shallow from 'zustand/shallow';
import chatUnreadMessagesState from '../state/ChatUnreadMessagesState';

/**
 * useChatChannelUnreadCount
 *
 * Returns the total unread messages count for a given channel.
 */
const useChatChannelUnreadCount = (sid: string) => {
  const [unreadMessagesStore] = chatUnreadMessagesState;
  const [channels] = unreadMessagesStore(state => [state.channels], shallow);

  return channels[sid];
};

export default useChatChannelUnreadCount;
