import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addResponseError, addSuccess } from '../../services/Messaging';
import Button from '../core/Button';
import { FormInput, FormTextarea } from '../core/form';
import Modal from '../core/modal/Modal';
import Api from './Api';

type Props = {
  onClose: Function;
};
const RequestRecommendationModal = ({ onClose }: Props) => {
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      intro: '',
    },
  });
  const { register, handleSubmit, errors } = form;

  const onSubmit = (values: any) => {
    setLoading(true);

    Api.requestRecommendation({
      ...values,
      intro: values.intro?.html ?? null,
    })
      .then(() => {
        addSuccess('Recommendation request has been sent.');
        onClose();
      })
      .catch(err => {
        setLoading(false);
        addResponseError(err);
      });
  };

  return (
    <Modal
      title="Request a Recommendation"
      isOpen
      onClose={() => onClose()}
      width="2xl"
    >
      <div>
        <p className="mb-8 text-center">
          Tell us who you would like to ask for a recommendation from and we’ll
          send them an email asking them to endorse you.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            name="firstName"
            label="First Name"
            errors={errors}
            ref={register({
              required: 'First name is required.',
            })}
          />
          <FormInput
            name="lastName"
            label="Last Name"
            errors={errors}
            ref={register({
              required: 'Last name is required.',
            })}
          />
          <FormInput
            name="email"
            label="Email Address"
            errors={errors}
            ref={register({
              required: 'Email address is required.',
            })}
          />
          <FormTextarea
            name="intro"
            label="Message"
            form={form}
            errors={errors}
            placeholder="Let them know who you are and what you are applying for."
            characterCount={700}
            richText
            ref={register({
              required: 'Message is required.',
            })}
          />

          <div className="flex justify-end space-x-3">
            <Button fill="outline" text="Cancel" onClick={() => onClose()} />
            <Button
              type="submit"
              color="primary"
              text="Send"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default RequestRecommendationModal;
