import React from 'react';
import { IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import useAnalytics from '../hooks/useAnalytics';
import Page from '../components/core/Page';
import WorkEdit from '../components/work/Edit';

const WorkInternalEdit = ({ match }: any) => {
  const { id: workId, mode } = match.params;

  useAnalytics('Edit Work', { workId });

  return (
    <Page
      authGuard
      pageTitle="Edit Work"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/work/${workId}`} />
          </IonButtons>
          <IonTitle slot="start">
            {mode === 'preview' ? 'Preview' : 'Edit Work'}
          </IonTitle>
        </IonToolbar>
      )}
    >
      <WorkEdit key={workId} id={workId} />
    </Page>
  );
};
export default WorkInternalEdit;
