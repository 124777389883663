import React from 'react';
import { Recommendation } from '@communo-corp/shared-types';

type Props = {
  recommendation: Recommendation;
};
const RecommendationSummary = ({ recommendation }: Props) => {
  const { firstName, lastName, title, department } = recommendation;

  return (
    <div className="mb-4">
      <p className="font-bold">
        {firstName} {lastName}
      </p>
      <p className="text-sm">
        {title || null}
        {department ? `, ${department}` : null}
      </p>
    </div>
  );
};
export default RecommendationSummary;
