/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import UserContext from './Context';
import AppContext from '../../AppContext';
import Modal from '../core/modal/Modal';
import useNavigate from '../../hooks/useNavigate';

export const PublicHashRoute = ({
  hash,
  component: Component,
  ...rest
}: any) => (
  <Route
    render={({ location }) =>
      location.hash === hash && <Route {...rest} component={Component} />
    }
  />
);

export const HashRoute = ({
  hash,
  exact = true,
  component: Component,
  ...rest
}: any) => (
  <Route
    render={props => {
      const match = matchPath(props.history.location.hash, hash);
      return exact
        ? match?.isExact && <Component {...props} {...match.params} {...rest} />
        : match && <Component {...props} {...match.params} {...rest} />;
    }}
  />
);

// @todo: rewrite so modal does not re-render when switching from one
// modal route to another modal route. (nicer route switching)
// TODO check if the modal is 'dirty', if so reload on dismiss automatically

export type ModalRouteProps = {
  hash: string;
  component: any;
  title?: string;
  exact?: boolean;
  reloadOnDismiss?: boolean;
  height?: string;
  fullHeight?: boolean;
  paddingX?: string;
};

export const ModalRoute = ({
  hash,
  component: Component,
  title,
  reloadOnDismiss = false,
  height,
  fullHeight = false,
}: ModalRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath(location.hash, hash);
  const { refresh } = useContext(AppContext);
  const [modalTitle, setModalTitle] = useState<string | undefined>();

  useEffect(() => {
    if (title) setModalTitle(title);
  }, [title]);

  const onDidDismiss = () => {
    if (match?.url === window.location.hash) {
      // Remove the hash after a modal is closed (if it has not changed to another hash).
      const path = window.location.pathname || '/';
      const params = window.location.search;

      // Note: Use NavContext.navigate to change the path so we don't animate the page behind.
      navigate(`${path}${params}`, 'none', 'pop');
    }

    if (reloadOnDismiss) {
      refresh();
    }
  };

  return match?.isExact ? (
    <Modal
      isOpen
      onClose={onDidDismiss}
      width="3xl"
      title={modalTitle || title || null}
      height={height}
      fullHeight={fullHeight}
      paddingX="8"
    >
      <Component
        match={match}
        title={title}
        {...match?.params}
        setModalTitle={setModalTitle}
      />
    </Modal>
  ) : null;
};

export const AnonymousRoute = ({ component: Component, ...rest }: any) => (
  <UserContext.Consumer>
    {context => (
      <Route
        {...rest}
        render={props =>
          // @ts-ignore
          context.state.isAuthenticated !== true ? (
            <Component {...props} {...rest} userContext={context} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    )}
  </UserContext.Consumer>
);
