import React from 'react';
import { useCookie } from 'react-use';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import SplitPanels from '../components/core/panels/SplitPanels';
import { TALENT_PLAN_SLUG } from '../config';
import {
  DEFAULT_ONBOARDING_COVER_PHOTO_URL,
  DEFAULT_ONBOARDING_LOGO_URL,
} from '../asset-config';
import useNavigate from '../hooks/useNavigate';
import { SOURCE_COOKIE } from '../components/cookie/SourceCookie';
import Button from '../components/core/Button';

const LandingAAF = () => {
  useAnalytics('AAF Landing');
  const navigate = useNavigate();
  const [, setSourceCookie] = useCookie(SOURCE_COOKIE);

  const handleClick = () => {
    setSourceCookie('aaf', { expires: 86400 * 30, path: '/' });
    navigate(`signup?redirectUrl=/plans/${TALENT_PLAN_SLUG}`);
  };

  return (
    <Page layout="basic" className="page-bg--red" hideHeader>
      <SplitPanels
        headerImg={DEFAULT_ONBOARDING_COVER_PHOTO_URL}
        logoImg={DEFAULT_ONBOARDING_LOGO_URL}
      >
        <div>
          <div className="mb-8 w-56 mx-auto">
            <img
              src="https://res.cloudinary.com/communo/image/upload/v1614291545/brands/aaf-logo-2021.png"
              alt="AAF Logo"
            />
          </div>
          <h3 className="text-center text-white mb-4">Welcome to Communo.</h3>
          <div className="text-white text-center text-lg mb-8">
            Communo is a home to thousands of experts who are looking to
            connect, collaborate, create and succeed. Experts like you.
          </div>
          <div className="mt-auto md:mt-8 text-center">
            <Button
              onClick={handleClick}
              reverse
              color="primary"
              fill="solid"
              className="mb-4 w-full md:w-full max-w-xs"
            >
              Let&apos;s get started!
            </Button>
          </div>
        </div>
      </SplitPanels>
    </Page>
  );
};

export default React.memo(LandingAAF);
