import React from 'react';
import Avatar from '../../avatar/Avatar';
import { Link } from '../../core/router';

interface Props {
  to: string;
  imageUrl: string;
  heading: string;
  subHeading?: string;
  showName?: boolean;
}

const SquareBase = ({
  to,
  imageUrl,
  heading,
  subHeading,
  showName = false,
}: Props) => {
  return (
    <Link to={to}>
      <div className="relative aspect-square w-full">
        <Avatar
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          square
          avatarUrl={imageUrl}
          avatarName={heading}
          size="3xl"
        />
      </div>
      {showName ? (
        <div className="text-center mt-4 px-4">
          {heading && <h5 className="leading-tight">{heading}</h5>}
          {subHeading && <div className="text-base">{subHeading}</div>}
        </div>
      ) : null}
    </Link>
  );
};
export default SquareBase;
