import React from 'react';
import Button from '../../core/Button';

type Props = {
  recruiter: UserEntity | null | undefined;
  toggleAssignModal: (bool: boolean) => void;
};

const AssignWorkButton = ({ recruiter, toggleAssignModal }: Props) => {
  return (
    <>
      {recruiter ? (
        <>
          <p className="text-grey-400 font-medium">Recruiter Assigned</p>
          <p className="mt-1 text-lg">
            {recruiter.firstName} {recruiter.lastName}
          </p>
        </>
      ) : null}
      <>
        <Button
          fill="solid"
          color="primary"
          fullWidth
          onClick={() => toggleAssignModal(true)}
        >
          Assign
        </Button>
      </>
    </>
  );
};

export default AssignWorkButton;
