import React from 'react';
import { IconProps } from './types';

const FolderIcon = ({ className = '' }: IconProps) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99902 1.99976C3.20337 1.99976 2.44031 2.31583 1.8777 2.87844C1.31509 3.44104 0.999023 4.20411 0.999023 4.99976V18.9998C0.999023 19.7954 1.31509 20.5585 1.8777 21.1211C2.44031 21.6837 3.20337 21.9998 3.99902 21.9998H19.999C20.7947 21.9998 21.5577 21.6837 22.1203 21.1211C22.683 20.5585 22.999 19.7954 22.999 18.9998V7.99976C22.999 7.20411 22.683 6.44104 22.1203 5.87844C21.5577 5.31583 20.7947 4.99976 19.999 4.99976H11.5342L9.83107 2.44506C9.64561 2.16686 9.33338 1.99976 8.99902 1.99976H3.99902ZM11.9967 9.9986C12.549 9.9986 12.9967 10.4463 12.9967 10.9986V12.9986H14.9967C15.549 12.9986 15.9967 13.4463 15.9967 13.9986C15.9967 14.5509 15.549 14.9986 14.9967 14.9986H12.9967V16.9986C12.9967 17.5509 12.549 17.9986 11.9967 17.9986C11.4444 17.9986 10.9967 17.5509 10.9967 16.9986V14.9986H8.99672C8.44443 14.9986 7.99672 14.5509 7.99672 13.9986C7.99672 13.4463 8.44443 12.9986 8.99672 12.9986H10.9967V10.9986C10.9967 10.4463 11.4444 9.9986 11.9967 9.9986Z"
    />
  </svg>
);
export default FolderIcon;
