import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import Create from '../components/perk/PerksCreate';

const PerksCreate = () => {
  useAnalytics('Perks Create');

  return (
    <Page
      authGuard
      pageTitle="New Perk"
      layout="panels"
      className="panels"
      mobileHeader={() => null}
      unMountOnLeave
    >
      <Create />
    </Page>
  );
};
export default PerksCreate;
