/* eslint-disable no-nested-ternary */
import React from 'react';
import { IonHeader } from '@ionic/react';
import Header from './Header';
import { isMobile } from '../../utilities/Device';
import View, { ViewContent } from './View';

type Props = {
  mobileHeader?: Function;
  layout?: Layouts;
  children: any; // JSX.Element[] | JSX.Element;
  className?: string;
  pageTitle?: string;
  hideHeader?: boolean;
  authGuard?: boolean;
  publicAuthGuard?: boolean;
  pullToRefresh?: boolean;
  permissions?: Permission; // todo allow multiple permissions | Permission[];
  unMountOnLeave?: boolean; // allow components to be unmounted, instead of staying hydrated
  noScroll?: boolean; // allow IonContent scroll to be disabled
  darkStatusBar?: boolean;
  showHeaderAuthLinks?: boolean;
};

const Page = ({
  mobileHeader,
  children,
  className = 'page-bg--grey',
  pageTitle,
  layout = 'default',
  hideHeader = false,
  authGuard = false,
  publicAuthGuard = false,
  pullToRefresh = false,
  permissions,
  unMountOnLeave = false,
  noScroll = false,
  darkStatusBar = false,
  showHeaderAuthLinks = true,
}: Props) => {
  function renderHeader() {
    if (hideHeader) return null;
    if (!isMobile) return <Header showAuthLinks={showHeaderAuthLinks} />;
    return mobileHeader ? (
      <IonHeader mode="ios" className="page-header">
        {mobileHeader()}
      </IonHeader>
    ) : (
      <Header showAuthLinks={showHeaderAuthLinks} />
    );
  }

  return (
    <View
      className={className}
      pageTitle={pageTitle}
      authGuard={authGuard}
      publicAuthGuard={publicAuthGuard}
      permissions={permissions}
      unMountOnLeave={unMountOnLeave}
      darkStatusBar={darkStatusBar}
    >
      {renderHeader()}
      <ViewContent
        layout={layout}
        pullToRefresh={pullToRefresh}
        noScroll={noScroll}
      >
        {children}
      </ViewContent>
    </View>
  );
};

export default Page;
