import React, { useContext, useState } from 'react';
import useApplicationNotes from '../../../hooks/useApplicationNotes';
import Button from '../../core/Button';
import { LoadingBalls } from '../../core/Loading';
import UserContext from '../../user/Context';
import ApplicationNoteForm from '../forms/ApplicationNoteForm';
import ApplicationNote from './ApplicationNote';

type Props = {
  work: WorkEntity;
  application: ApplicationEntity;
};

export type NoteFormValues = {
  note: string;
  rating: string;
  name: string;
};

const ApplicationNotes = ({ work, application }: Props) => {
  const [showForm, setShowForm] = useState(false);
  const { id: currentUserId, isPublic, name } = useContext(UserContext);

  const { data, loading, notesMutation } = useApplicationNotes({
    workId: work.id,
    applicationId: application.id,
  });

  const onComplete = () => setShowForm(false);

  const onSubmit = async (values: NoteFormValues) => {
    onComplete();
    const parsedFormData = {
      userId: application.user.id,
      note: values.note,
      rating: parseFloat(values.rating),
      name: isPublic ? values.name : name,
      commenterUserId: currentUserId || null,
    };
    notesMutation.mutate(parsedFormData);
  };

  return (
    <>
      {showForm ? (
        <ApplicationNoteForm onComplete={onComplete} onSubmit={onSubmit} />
      ) : (
        <div className="flex flex-col">
          <div className="text-xs text-subdued flex w-full mb-4">
            <Button
              type="button"
              onClick={() => {
                setShowForm(true);
              }}
              color="primary"
              icon="add"
              text="New note"
              className="w-full"
            />
          </div>
          <hr />
          {loading ? (
            <LoadingBalls isActive />
          ) : (
            data?.map(note => (
              <div key={note.id} className="flex flex-col gap-2">
                <ApplicationNote applicationNote={note} />
                <hr />
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default ApplicationNotes;
