import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import UserContext from '../../user/Context';
import WorkIntro from './WorkIntro';
import ApplyNowButton from './ApplyNowButton';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import UpgradeJobButton from '../UpgradeJobButton';
import useWorkIsPosted from '../../../hooks/useWorkIsPosted';
import ViewApplicantsButton from '../ViewApplicantsButton';
import {
  ENABLE_INVITE_TO_APPLY,
  ENABLE_RECRUITER_ASSIGNMENT,
  ENABLE_SUGGESTED_CANDIDATES,
} from '../../../config';
import useWorkApplications from '../../../hooks/useWorkApplications';
import SuggestedCandidatesButton from './SuggestedCandidatesButton';
import MetaWorkDetails from './MetaWorkDetails';
import ShareWork from './ShareWork';
import AssignWork from './AssignWork';

const Meta = ({
  work,
  qualification,
  loading,
  refreshWork,
  canManage,
}: {
  work: WorkEntity;
  qualification: QualificationEntity | null;
  loading: boolean;
  refreshWork: Function;
  canManage: boolean;
}) => {
  const { workId } = useParams<{ workId: string }>();
  const { id: userId, membershipId } = useContext(UserContext);
  const workApplications = useWorkApplications(work.id).state;
  const { isPosted: workIsPosted } = useWorkIsPosted(work);

  const applicationCountTotal = useMemo(() => {
    if (!workApplications.data) {
      return 0;
    }
    // workApplications applications statuses are nested under the data values
    return Object.values(workApplications.data).filter(
      app => app.status !== 'invited'
    ).length;
  }, [workApplications]);

  const showShareButton = work.collections.length === 0;
  const showInviteButton = ENABLE_INVITE_TO_APPLY && canManage;
  const { checkAccess } = useCurrentMembership();
  const showApplyButton = checkAccess('canApplyToCommunoWork');
  const showAssignButton = ENABLE_RECRUITER_ASSIGNMENT && canManage;

  // const audienceStr =
  //   audience === WorkAudience.BOTH ? 'Everyone' : (audience as string);

  // const audienceType = capitalize(
  //   audience === 'collection' && collections && collections.length > 0
  //     ? collections[0].name
  //     : audienceStr
  // );

  return (
    <>
      <WorkIntro work={work} isAuthenticated={!!userId} />

      {(showShareButton || showInviteButton || showApplyButton) && (
        <>
          <hr className="mt-4 mb-4" />
          <div className="flex justify-between gap-2">
            {showShareButton && (
              <ShareWork
                buttonFill="outline"
                buttonText="Share"
                modalTitle="Social Sharing"
                modalFormLabel="Share with a member"
                userId={userId}
                work={work}
              />
            )}
            {showApplyButton && (
              <ApplyNowButton work={work} qualification={qualification} />
            )}
          </div>

          {showAssignButton && (
            <>
              <hr className="mt-4 mb-4" />
              <AssignWork
                membershipId={membershipId}
                refreshWork={refreshWork}
                work={work}
              />
            </>
          )}
        </>
      )}

      {canManage ? (
        <>
          <hr className="mt-4 mb-4" />
          {!workIsPosted ? (
            <UpgradeJobButton
              work={work}
              loading={loading}
              refreshWork={refreshWork}
              trackingEventPrefix="Work Page"
            />
          ) : (
            <div className="space-y-3">
              {ENABLE_SUGGESTED_CANDIDATES && (
                <SuggestedCandidatesButton
                  workId={workId}
                  trackingEventName="Work Page - Suggested Candidates Clicked"
                />
              )}
              <ViewApplicantsButton
                workId={work.id}
                trackingEventName="Work Page - Work Applicants Clicked"
                applicantCount={applicationCountTotal}
                isExternalJob={work.isExternal || false}
              />
            </div>
          )}

          {/* <div className="mt-2 -mx-4">
            <div className="notice notice--info my-8">
              <h6 className="text-center">Posted to {audienceType}</h6>
            </div>
          </div> */}
        </>
      ) : null}

      <hr className="mt-4 mb-4" />

      <MetaWorkDetails canManage={canManage} userId={userId} work={work} />
    </>
  );
};

export default Meta;
