import React from 'react';
import cn from 'classnames';

import useModal from '../hooks/useModal';

type Props = {
  paddingX: string;
  paddingY: string;
  enableScroll: boolean;
  children: any;
};
const ModalBody = ({ paddingX, paddingY, enableScroll, children }: Props) => {
  const { isVisible, isPresented } = useModal();

  return (
    <div
      className={cn('flex-1 max-h-full', [`px-${paddingX}`, `py-${paddingY}`], {
        'overflow-y-auto': enableScroll,
      })}
    >
      {isPresented || isVisible ? children : null}
    </div>
  );
};
export default ModalBody;
