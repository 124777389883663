import React from 'react';
import Page from '../components/core/Page';
import { Error404 } from '../components/error';
import OnboardingLayout from '../components/onboarding/OnboardingLayout';
import LeaveReferenceView from '../components/reference/LeaveReferenceView';
import SubmitReferenceView from '../components/reference/SubmitReferenceView';

const SubmitReference = ({ match }: any) => {
  const { referenceToken, userId } = match.params;

  return (
    <Page
      // layout="basic"
      // className="page-bg--white"
      hideHeader
      pageTitle="Submit Reference"
      mobileHeader={() => null}
    >
      <OnboardingLayout organization="communo">
        <>
          {referenceToken ? (
            <SubmitReferenceView referenceToken={referenceToken} />
          ) : null}

          {userId ? <LeaveReferenceView leaveFor={userId} /> : null}

          {!referenceToken && !userId ? <Error404 /> : null}
        </>
      </OnboardingLayout>
    </Page>
  );
};

export default SubmitReference;
