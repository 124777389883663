import React from 'react';
import WorkApplyNowCTA from './WorkApplyNowCTA';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Button from '../../core/Button';
import { LoadingBalls } from '../../core/Loading';

const AuthenticatedApplyNowButton = ({
  work,
  qualification,
}: {
  work: WorkEntity;
  qualification: QualificationEntity | null;
}) => {
  const applyButtonText = work.isExternal ? 'Apply' : 'Apply Now';
  if (!qualification) {
    return <LoadingBalls isActive />;
  }

  if (
    qualification.application &&
    qualification.application.status !== 'invited'
  ) {
    // Already applied
    return (
      <Button
        text="Applied"
        className="flex-1 pointer-events-none"
        fill="solid"
        color="primary"
        disabled
      />
    );
  }

  return (
    <Button
      onClick={() => {
        const el = document.getElementById('workFooter');
        if (el) el.scrollIntoView({ behavior: 'smooth' });
      }}
      className="flex-1"
      text={applyButtonText}
      fill="solid"
      color="primary"
    />
  );
};

const WrappedApplyNowButton = ({
  work,
  qualification,
}: {
  work: WorkEntity;
  qualification: QualificationEntity | null;
}) => {
  const { id } = useCurrentUser();

  if (id) {
    return (
      <AuthenticatedApplyNowButton work={work} qualification={qualification} />
    );
  }

  return <WorkApplyNowCTA work={work} />;
};

export default WrappedApplyNowButton;
