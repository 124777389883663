/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export default React.forwardRef(
  ({ name, value, onChange, ...rest }: Props, ref?: any) => (
    <input
      type="checkbox"
      id={name}
      name={name}
      value={value}
      {...rest}
      ref={ref || null}
      onFocus={(e: any) => e.target.blur()} // prevent mobile keyboard
      onChange={onChange}
    />
  )
);
