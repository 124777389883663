import React from 'react';
import validator from 'validator';
import { FormInput } from '../../core/form';

type Link = {
  key: string;
  label: string;
};

const links: Link[] = [
  { key: 'WebsiteURL', label: 'Website' },
  { key: 'InstagramURL', label: 'Instagram' },
  { key: 'TwitterURL', label: 'Twitter' },
  { key: 'FacebookURL', label: 'Facebook' },
  { key: 'LinkedInURL', label: 'LinkedIn' },
  { key: 'BehanceURL', label: 'Behance' },
  { key: 'VimeoURL', label: 'Vimeo' },
  { key: 'YouTubeURL', label: 'YouTube' },
];

export default ({ errors, register }: any) => (
  <div>
    {links.map(({ key, label }: Link) => (
      <FormInput
        name={`socialLinks.${key}`}
        label={label}
        errors={errors}
        key={key}
        placeholder="Enter URL"
        ref={register({
          validate: (value: string) =>
            validator.isEmpty(value) || validator.isURL(value) || 'Invalid url',
        })}
      />
    ))}
  </div>
);
