import React from 'react';

import { Error404 } from '../error';
import CompanyPhotosSlider from './CompanyPhotosSlider';
import MembershipEntityColumn from './MembershipEntityColumn';
import MembershipSidebarColumn from './MembershipSidebarColumn';
import ProfilePortfolio from './portfolio/ProfilePortfolio';
import ProfileCoverPhoto from './ProfileCoverPhoto';
import ProfileDetailsColumn from './ProfileDetailsColumn';
import ProfileLayout from './ProfileLayout';
import ProfileStaff from './ProfileStaff';
import ProfileWork from './ProfileWork';

type Props = {
  membership: MembershipEntity;
  membershipUsers: MembershipUserEntity[];
};

const AgencyProfile = ({ membership, membershipUsers }: Props) => {
  if (!membership) {
    return (
      <Error404 title="Member 404" message="We couldn't find that member..." />
    );
  }

  return (
    <div className="profile profile-company">
      <ProfileCoverPhoto
        url={membership.coverPhotoURL}
        altText={membership.name}
      />

      <ProfileLayout
        entityColumn={
          <MembershipEntityColumn
            membership={membership}
            membershipUsers={membershipUsers}
          />
        }
        sidebarColumn={
          <MembershipSidebarColumn
            membership={membership}
            membershipUsers={membershipUsers}
          />
        }
        detailsColumn={
          <>
            <ProfileDetailsColumn
              name={membership.name}
              summary={membership.summary}
              about={membership.about}
              roles={membership.roles.map(assoc => assoc.role.name)}
              skills={membership.skills.map(assoc => assoc.skill.name)}
              industries={membership.industries.map(
                assoc => assoc.industry.name
              )}
              videoUrl={membership.videoUrl}
              tagline={membership.tagline}
              graphicTagline={membership.graphicTagline}
            />

            <ProfileWork membership={membership} />

            <ProfileStaff membershipUsers={membershipUsers} />
          </>
        }
        body={
          <>
            <CompanyPhotosSlider membership={membership} />
          </>
        }
      />

      <ProfilePortfolio membership={membership} />
    </div>
  );
};
export default AgencyProfile;
