export default function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts && parts.length === 2) {
    return (
      parts
        .pop()
        ?.split(';')
        ?.shift() ?? null
    );
  }

  return null;
}
