import React, { useState } from 'react';
import { IonToolbar, IonTitle, IonButtons, IonBackButton } from '@ionic/react';

import Page from '../components/core/Page';
import { Error404 } from '../components/error';
import UserProfile from '../components/profile/UserProfile';
import MembershipProfile from '../components/profile/MembershipProfile';
import useCurrentUser from '../hooks/useCurrentUser';
import { Link } from '../components/core/router';

const profileComponents: any = {
  user: UserProfile,
  membership: MembershipProfile,
};

const View = ({
  type,
  id,
  setPageTitle,
}: {
  type: string;
  id: string;
  setPageTitle: Function;
}) => {
  const ProfileComponent: any = profileComponents[type] || null;
  if (!ProfileComponent) {
    return (
      <Error404
        title="Member Type 404"
        message={`Invalid member type ${type}`}
      />
    );
  }

  return <ProfileComponent setPageTitle={setPageTitle} id={id} />;
};

export default ({ match }: any) => {
  const { type, id } = match.params;
  const [pageTitle, setPageTitle] = useState('Loading Member...');

  const user = useCurrentUser();

  const canEditProfile =
    type === 'user'
      ? id === user.id
      : id === user.membershipId && user.membershipAccess === 'admin';

  const editUrl = type === 'user' ? '#/edit-profile/' : '#/edit-page/';

  return (
    <Page
      authGuard
      pullToRefresh
      pageTitle={pageTitle}
      layout="none"
      className="page-bg--white member-profile"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/members" />
          </IonButtons>
          <IonTitle slot="start">{pageTitle}</IonTitle>
          <IonButtons slot="end">
            {canEditProfile ? (
              <Link className="text-link py-1 pl-1" to={editUrl}>
                Edit
              </Link>
            ) : null}
          </IonButtons>
        </IonToolbar>
      )}
    >
      <View setPageTitle={setPageTitle} type={type} id={id} />
    </Page>
  );
};
