import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import portfolioApi from './Api';
import Form from './Form';
import { LoadingBalls } from '../core/Loading';
import { addResponseError, addSuccess } from '../../services/Messaging';
import usePortfolioItems from '../../hooks/usePortfolioItems';
import { canPlayVideo } from '../video/VideoPlayer';

const Create = () => {
  const { push, location } = useHistory();

  // Hacky way to detect if we are creating a user project vs a membership project
  const isUserProject = location.hash.includes('#/edit-profile/');

  const { userId, membershipId, portfolioItems, refresh } = usePortfolioItems(
    isUserProject
  );

  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      title: '',
      description: '',
      headerPhotoURL: '',
      videoUrl: null,
      imageURLs: [],
    },
  });
  const { setError, errors, clearErrors } = form;
  const [imageURLs, setImageURLs] = useState<string[]>([]);

  // TODO: Hack for now until more elegant solution
  const backRoute = isUserProject
    ? '#/edit-profile/portfolio'
    : '#/edit-page/portfolio';

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (values: any) => {
    if (loading || hasErrors()) return;

    try {
      const data = {
        ...values,
        imageURLs,
        position: portfolioItems.length ? portfolioItems.length + 1 : 0,
        userId: isUserProject ? userId : null,
        membershipId: !isUserProject ? membershipId : null,
      };

      if (imageURLs.length > 12) {
        throw new Error('Max of 12 images. Please remove one or more.');
      }

      const hasImages = imageURLs.length > 0;
      const videoPlayable = canPlayVideo(data.videoUrl);
      const validVideoUrl = data.videoUrl && videoPlayable;

      if (!hasImages && !validVideoUrl) {
        throw new Error('Must have at least one image or a video.');
      }

      setLoading(true);

      if (!videoPlayable) {
        data.videoUrl = null;
      }

      portfolioApi
        .createProject(data)
        .then((response: PortfolioEntity) => {
          addSuccess('Profile updated!');
          refresh().then(() => {
            push(`${location.pathname}${backRoute}`);
          });
          return response;
        })
        .catch((error: ResponseError) => {
          addResponseError(error);
          return error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setError('imageURLs', {
        type: 'images',
        message: error.message,
      });
    }
  };

  const updateImages = useCallback(
    async (images: string[]) => {
      try {
        if (images.length > 12) {
          throw new Error('Max of 12 images. Please remove one or more.');
        }

        clearErrors('imageURLs');
        setImageURLs(images);
      } catch (error) {
        setError('imageURLs', {
          type: 'images',
          message: error.message,
        });
      }
    },
    [clearErrors, setError]
  );

  return (
    <>
      <LoadingBalls isActive={loading} />
      <Form
        form={form}
        onSubmit={onSubmit}
        imagesChanged={updateImages}
        backRoute={backRoute}
      />
    </>
  );
};

export default Create;
