import React, { MouseEvent } from 'react';
import Button from '../core/Button';
import useSchedulerPages from './hooks/useSchedulerPages';
import { LoadingRing } from '../core/Loading';

const SchedulerAdd = () => {
  const {
    data,
    schedulerMutation,
    scheduleMutationStatus,
  } = useSchedulerPages();
  const handleAdd = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    schedulerMutation();
  };

  const maxNumberOfMeetings = 4;
  const hasSetAllMeetings = data.schedulerPages.length >= maxNumberOfMeetings;

  return (
    <>
      {scheduleMutationStatus === 'loading' ? (
        <div className="p-1 pl-10 ">
          <LoadingRing isActive color="primary" size="sm" />
        </div>
      ) : null}
      <Button
        fill="outline"
        id="copy-meeting-event"
        type="button"
        onClick={handleAdd}
        color="primary"
        disabled={hasSetAllMeetings || scheduleMutationStatus === 'loading'}
        className="m-1"
      >
        <i className={hasSetAllMeetings ? '' : 'i-add btn__icon'} />
        <span className="btn__text">
          {hasSetAllMeetings
            ? `You can have up to ${maxNumberOfMeetings} meeting schedulers`
            : 'Add New Meeting'}
        </span>
      </Button>
    </>
  );
};

export default SchedulerAdd;
