import React, { useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';

import Filter from '../../filter/Filter';
// import MemberSearchButton from '../../search/SearchButton';
import MemberSearchResults from './MemberSearchResults';
import Loading from '../../core/Loading';
import UserEntityCard from '../../card/UserEntityCard';
import MembershipEntityCard from '../../card/MembershipEntityCard';
import BrandEntityCard from '../../card/BrandEntityCard';
import useNavigate from '../../../hooks/useNavigate';
import useAnalytics from '../../../hooks/useAnalytics';
import { Breakpoints } from '../../swiper/Swiper';

export type Labels = {
  people?: string;
  agencies?: string;
  brands?: string;
};

type Props = {
  filterContext: any;
  rawResults: {
    [key: string]: SearchResponse;
  };
  showFilter?: boolean;
  showUserResults?: boolean;
  showAgencyResults?: boolean;
  showBrandResults?: boolean;
  showOverflow?: boolean;
  userAllResults: any[];
  membershipAllResults: any[];
  brandAllResults: any[];
  onLoadMore: Function;
  loading: boolean;
  labels?: Labels;
  breakpoints: Breakpoints;
};
const MemberSearchList = React.memo(
  ({
    filterContext,
    rawResults,
    showFilter = true,
    showUserResults = true,
    showAgencyResults = true,
    showBrandResults = true,
    showOverflow = true,
    userAllResults,
    membershipAllResults,
    brandAllResults,
    onLoadMore,
    loading,
    labels = {
      people: 'People',
      agencies: 'Agency',
      brands: 'Business',
    },
    breakpoints,
  }: Props) => {
    const navigate = useNavigate();
    const { track } = useAnalytics();

    const activeType = filterContext.values.type;
    const showUsers =
      showUserResults &&
      (!activeType || activeType === 'people') &&
      rawResults['user-search'];
    const showMemberships =
      showAgencyResults &&
      (!activeType || activeType === 'agency') &&
      rawResults['membership-search'];
    const showBrands =
      showBrandResults &&
      (!activeType || activeType === 'brand') &&
      rawResults['brand-search'];

    const filterPeople = useCallback(() => {
      filterContext.actions.updateFilterValue('type', 'people');
    }, [filterContext.actions]);

    const filterAgencies = useCallback(() => {
      filterContext.actions.updateFilterValue('type', 'agency');
    }, [filterContext.actions]);

    const filterBrands = useCallback(() => {
      filterContext.actions.updateFilterValue('type', 'brand');
    }, [filterContext.actions]);

    const renderUser = useCallback(
      (result: UserEntity, onSelect?: (userId: string) => void) => {
        return (
          <UserEntityCard
            onSelect={onSelect}
            key={result.objectID}
            user={result}
          />
        );
      },
      []
    );

    const renderAgency = useCallback((result: MembershipEntity) => {
      return <MembershipEntityCard key={result.objectID} membership={result} />;
    }, []);

    const renderBrand = useCallback((result: MembershipEntity) => {
      return <BrandEntityCard key={result.objectID} membership={result} />;
    }, []);

    if (!rawResults) {
      return <Loading isActive />;
    }

    return (
      <div className="flex flex-col gap-8">
        {showOverflow && (
          <div className="mx-auto w-9/10">
            <h2>Members</h2>
          </div>
        )}
        {showFilter && (
          <Filter
            actions={filterContext.actions}
            filteredOptions={filterContext.filteredOptions}
            options={filterContext.options}
            values={filterContext.values}
            loading={loading}
          />
        )}

        {showUsers ? (
          <MemberSearchResults
            label={labels.people || 'People'}
            type="people"
            filterValues={filterContext.values}
            allResults={userAllResults}
            searchResults={rawResults['user-search']}
            seeAllFunction={filterPeople}
            onLoadMore={onLoadMore}
            renderResult={renderUser}
            showOverflow={showOverflow}
            breakpoints={breakpoints}
            viewMoreOptions={{
              label: 'View more talent',
              onClick: () => {
                track('Feed - View More Talent Clicked');
                navigate(
                  '/members?type=people&isIndividualPlan=true',
                  'root',
                  'push'
                );
              },
            }}
          />
        ) : null}

        {showMemberships ? (
          <MemberSearchResults
            label={labels.agencies || 'Agency'}
            type="agency"
            filterValues={filterContext.values}
            allResults={membershipAllResults}
            searchResults={rawResults['membership-search']}
            seeAllFunction={filterAgencies}
            onLoadMore={onLoadMore}
            renderResult={renderAgency}
            showOverflow={showOverflow}
            viewMoreOptions={{
              label: 'View more agencies',
              onClick: () => {
                track('Feed - View More Agencies Clicked');
                navigate('/members?type=agency', 'root', 'push');
              },
            }}
            breakpoints={breakpoints}
          />
        ) : null}

        {showBrands ? (
          <MemberSearchResults
            label={labels.brands || 'Business'}
            type="brand"
            filterValues={filterContext.values}
            allResults={brandAllResults}
            searchResults={rawResults['brand-search']}
            seeAllFunction={filterBrands}
            onLoadMore={onLoadMore}
            renderResult={renderBrand}
            showOverflow={showOverflow}
            breakpoints={breakpoints}
          />
        ) : null}
      </div>
    );
  }
);
export default MemberSearchList;
