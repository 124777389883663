import React from 'react';

import { mediaClasses as mc } from '../../utilities/MatchMedia';
import UserProfilePhoto from './UserProfilePhoto';
import useForceMobile from '../../hooks/useForceMobile';
import ButtonLink from '../core/ButtonLink';
import StudentBadge from './StudentBadge';
import useCurrentUser from '../../hooks/useCurrentUser';
import ChatButton from '../chat/components/ChatButton';
import useShowFullProfile from '../../hooks/useShowFullProfile';

type Props = {
  user: UserEntity;
};

const StudentEntityColumn = ({ user }: Props) => {
  const { id: authUserId } = useCurrentUser();
  const forceMobile = useForceMobile();
  const showFullProfile = useShowFullProfile();

  const membership = user.primaryMembershipUser?.membership;
  const userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;

  if (!user || !membership) {
    return null;
  }

  return (
    <>
      <UserProfilePhoto
        userId={user.id}
        url={user.avatarURL}
        altText={userName}
        badge={<StudentBadge />}
      />

      <h4 className="mt-8">{userName}</h4>

      <p className="mt-2 text-lg leading-snug">{user.title}</p>

      {user.location?.friendlyName ? (
        <p className="mt-2">{user.location?.friendlyName}</p>
      ) : null}

      {user.id !== authUserId && !forceMobile ? (
        <ChatButton
          className={mc('mt-8 btn--sm 2xl:btn--md', forceMobile)}
          identity={user.id}
          upgradeLink="#/upgrade"
        />
      ) : null}

      {forceMobile && showFullProfile ? (
        <ButtonLink
          color="primary"
          size="sm"
          href={`/members/user/${user.id}`}
          text="View Full Profile"
          className="mt-6 w-full"
        />
      ) : null}
    </>
  );
};
export default StudentEntityColumn;
