import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';

import Swiper, { Breakpoints } from '../../swiper/Swiper';
import Paginate from '../../core/Paginate';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import Button from '../../core/Button';

function renderResults(
  allResults: any,
  renderResult: Function,
  activeType: boolean,
  seeAllFunction: Function,
  showOverflow: boolean,
  breakpoints: Breakpoints
) {
  const elements = allResults.map((result: any) => (
    <div key={result.objectID} className="w-full">
      {renderResult(result)}
    </div>
  ));

  const viewAll = (
    <div className="w-full h-full relative overflow-hidden">
      <button
        type="button"
        className="focus:outline-none absolute inset-0 w-full h-full bg-white flex flex-col rounded-md justify-center items-center"
        onClick={() => seeAllFunction()}
      >
        <span className="h3">
          See
          <br />
          All
        </span>
      </button>
    </div>
  );

  if (!activeType) {
    elements.push(viewAll);
  }

  if (activeType) {
    return (
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7 6xl:grid-cols-8">
        {elements}
      </div>
    );
  }

  return (
    <div
      className={`full-width-slider-container ${
        showOverflow ? 'swiper-buttons-outside' : 'swiper-buttons-inside'
      }`}
    >
      <Swiper
        navigation
        className={`member-search-slider full-width-slider ${
          showOverflow ? 'overflow-initial' : ''
        }`}
        items={elements}
        swiperConfig={{
          freeMode: true,
          watchSlidesVisibility: true,
          slidesPerView: 1.125,
          spaceBetween: 8,
          breakpoints,
        }}
      />
    </div>
  );
}

type ViewMoreOptions = {
  label?: string;
  onClick?: Function;
};

type Props = {
  label: string;
  type: string;
  allResults: any[];
  searchResults: SearchResponse;
  filterValues: any;
  seeAllFunction: Function;
  renderResult: Function;
  onLoadMore: Function;
  showOverflow?: boolean;
  viewMoreOptions?: ViewMoreOptions;
  breakpoints: Breakpoints;
};

const MemberSearchResults = ({
  label,
  type,
  allResults,
  searchResults,
  filterValues,
  seeAllFunction,
  renderResult,
  onLoadMore,
  showOverflow = true,
  viewMoreOptions = {
    label: 'View more',
  },
  breakpoints,
}: Props) => {
  const plan = useCurrentPlan();

  const currentType = plan?.type === 'student' ? 'people' : filterValues.type;
  const activeType = currentType === type;
  const [containerRef, setContainerRef] = useState<any>();

  const showingCount =
    searchResults.hitsPerPage * searchResults.page + searchResults.hits.length;

  return (
    <div
      className={`flex flex-col gap-4 ${showOverflow ? 'mx-auto w-9/10' : ''}`}
      ref={ref => setContainerRef(ref)}
    >
      <div className="flex items-center justify-between">
        <h5>
          {label} <span className="font-normal">({searchResults.nbHits})</span>
        </h5>

        {!activeType && showOverflow ? (
          <Button
            color="primary"
            type="button"
            onClick={() => seeAllFunction()}
          >
            See all
          </Button>
        ) : (
          !showOverflow && (
            <Button
              color="primary"
              size="sm"
              role="none"
              className="hidden sm:block"
              onClick={() => {
                if (viewMoreOptions?.onClick) viewMoreOptions.onClick();
              }}
            >
              {viewMoreOptions?.label || 'View more'}
            </Button>
          )
        )}
      </div>

      {containerRef && allResults.length
        ? renderResults(
            allResults,
            renderResult,
            activeType,
            seeAllFunction,
            showOverflow,
            breakpoints
          )
        : 'No results found!'}

      {!activeType && !showOverflow ? (
        <Button
          color="primary"
          size="sm"
          role="none"
          className="block sm:hidden"
          onClick={() => {
            if (viewMoreOptions?.onClick) viewMoreOptions.onClick();
          }}
        >
          {viewMoreOptions?.label || 'View more'}
        </Button>
      ) : null}

      {activeType ? (
        <Paginate
          showing={showingCount}
          total={searchResults?.nbHits}
          onLoadMore={onLoadMore}
          displayShowingCount={filterValues.type === 'people'}
          max={2000}
        />
      ) : null}
    </div>
  );
};
export default React.memo(MemberSearchResults);
