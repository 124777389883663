import React, { useMemo } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import ApplicationForm from './forms/ApplicationForm';
import MyApplication from './MyApplication';
import WorkUpgradeInstructions from './WorkUpgradeInstructions';
import {
  isAgencyProfileHidden,
  isFreelancerProfileHidden,
} from '../profileCompleteness/utils/profile';

const Application = ({
  work,
  qualification,
  refreshQualification,
  isClosed,
}: {
  work: WorkEntity;
  qualification: QualificationEntity;
  refreshQualification: Function;
  isClosed: boolean;
}) => {
  const { checkAccess, isIndividualPlan, planType } = useCurrentMembership();
  const isStudent = planType === 'student';
  const canApplyToCommunoWork = useMemo(
    () => checkAccess('canApplyToCommunoWork'),
    [checkAccess]
  );
  const canApplyToStudentWork = useMemo(
    () => checkAccess('canApplyToStudentWork'),
    [checkAccess]
  );
  const isProfileHidden = isIndividualPlan
    ? isFreelancerProfileHidden()
    : isAgencyProfileHidden();

  const { application } = qualification;

  if (application && application.status !== 'invited') {
    return (
      <MyApplication
        application={application}
        isOneClickApply={work.isOneClickApply}
      />
    );
  }

  if (work.isExternal || isProfileHidden) {
    return null;
  }

  if (isClosed) {
    return <div>This opportunity is now closed for applications.</div>;
  }

  if (
    (!isStudent && !canApplyToCommunoWork) ||
    (isStudent && !canApplyToStudentWork)
  ) {
    return <WorkUpgradeInstructions />;
  }

  return (
    <ApplicationForm
      qualification={qualification}
      work={work}
      refreshQualification={refreshQualification}
      isIndividualPlan={isIndividualPlan}
      isStudent={isStudent}
    />
  );
};
export default Application;
