/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { IonRange } from '@ionic/react';
import debounce from 'lodash/debounce';

/* eslint-disable-next-line no-unused-vars */
import { FilterOptionComponentProps } from '../index.d';
import Location from '../../core/Location';
import URLFilterOption from './URLFilterOption';
import { limit } from '../../../utilities/Number';

type FilterValueType = {
  distance?: number;
  location?: {
    coordinates?: google.maps.LatLngLiteral;
    address: string;
  };
};

interface LocationFilterOptionProps {
  showRangeSlider?: boolean;
}

type LocationFilterComponentProps = FilterOptionComponentProps<
  FilterValueType
> &
  LocationFilterOptionProps;

const LocationFilterComponent: React.FC<LocationFilterComponentProps> = ({
  name,
  onChange,
  sectionTitle,
  showTitle,
  value,
  resultsCount = 0,
  showRangeSlider = true,
}: LocationFilterComponentProps) => {
  const [localLocation, setLocalLocation] = useState(value);

  const distanceValue = value?.distance ?? 10;
  const hasLocation = localLocation || !!value?.location;

  const handleChange = debounce(
    (key: string, val: any) => {
      if (key === 'location') {
        // if the location value is empty, value should be set to undefined since distance shouldn't be applied without location.
        if (!val) {
          setLocalLocation(undefined);
          onChange(name, undefined);
        } else {
          // ensure distance is always set when location is set
          setLocalLocation(val);
          onChange(name, {
            ...value,
            location: val,
            distance: distanceValue,
          });
        }
      } else {
        onChange(name, {
          ...value,
          [key]: val,
        });
      }
    },
    300,
    {
      leading: true,
      trailing: true,
    }
  );

  return (
    <section className="filter-group">
      {showTitle ? (
        <h5 className="filter-group__title">{sectionTitle}</h5>
      ) : null}
      <div className="filter-group__item location-autocomplete-filter">
        <Location
          onChange={location => handleChange('location', location)}
          value={value?.location}
          placeholder="Type to search for a Location..."
        />
      </div>
      {showRangeSlider && hasLocation && (
        <div className="filter-group__item">
          <div className="w-full">
            <div>
              {distanceValue}
              km
            </div>
            <div className="text-sm text-subdued">{sectionTitle}</div>
            <IonRange
              color="communo-red"
              max={100}
              min={10}
              step={10}
              mode="ios"
              onIonChange={e => {
                // on google location clear, distance should clear too.
                // but the on change will fire and set distance.
                // if distance is undefined (NaN), we shouldn't need to fire on change again.
                if (!Number.isNaN(Number(e.detail.value))) {
                  handleChange('distance', e.detail.value);
                }
              }}
              value={distanceValue}
            />
            <div className="text-sm text-subdued">
              Matching Results ({limit(resultsCount)})
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

class LocationFilterOption extends URLFilterOption<FilterValueType>
  implements LocationFilterOptionProps {
  showRangeSlider?: boolean;

  constructor(
    props: Omit<
      URLFilterOption<FilterValueType>,
      'component' | 'fromURL' | 'toURL'
    > &
      LocationFilterOptionProps
  ) {
    super({ ...props, component: LocationFilterComponent });

    this.showRangeSlider = props.showRangeSlider;
  }

  /**
   * Convert URL params to value
   * @param params URL params
   */
  fromURL(params: {
    [key: string]: any;
  }): { [key: string]: FilterValueType | undefined } {
    const { distance, address, latlng } = params;
    const { name } = this;

    const coordinates = String(latlng).split(',');
    const isCoordinates =
      coordinates.length === 2 &&
      coordinates.every(data => !Number.isNaN(Number(data)));

    if (distance && address && isCoordinates) {
      return {
        [name]: {
          distance,
          location: {
            address,
            coordinates: {
              lat: Number(coordinates[0]),
              lng: Number(coordinates[1]),
            },
          },
        },
      };
    }
    return {
      [name]: undefined,
    };
  }

  /**
   * Convert value to URL params
   * @param value
   */
  // eslint-disable-next-line class-methods-use-this
  toURL(value?: FilterValueType): { [key: string]: string | undefined } {
    const { location, distance } = value ?? {};

    const hasCoordinates =
      location?.coordinates?.lat && location.coordinates.lng;

    return {
      distance: distance ? String(distance) : undefined,
      address: location?.address ? String(location?.address) : undefined,
      latlng: hasCoordinates
        ? `${location?.coordinates?.lat},${location?.coordinates?.lng}`
        : undefined,
    };
  }
}

export default LocationFilterOption;
