import React, { useMemo } from 'react';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

const getRoleMatchStatus = (criteria: ScoreMetricsCriteria): MetricStatus =>
  (!!criteria.hasRole && 'complete') ||
  (!!criteria.hasRoleCategory && 'partial') ||
  'incomplete';

type Props = {
  role: RoleEntity | null;
  scoreMetrics: ScoreMetrics;
};

const RoleMetric = ({ role, scoreMetrics }: Props) => {
  const { criteria } = scoreMetrics;
  const label = role?.name || 'Role';
  const status = useMemo(() => getRoleMatchStatus(criteria), [criteria]);
  return <Metric label={label} status={status} />;
};

export default RoleMetric;
