import React, { useRef } from 'react';
import useChatChannel from '../components/chat/hooks/useChatChannel';
import { LoadingBalls } from '../components/core/Loading';
import ConversationSettings from '../components/chat/views/ConversationSettings';
import View, {
  ViewContent,
  ViewFooter,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import BackButton from '../components/core/BackButton';
import { ImperativeHandleRef } from '../@types/conversation.d';

const ChatConversationSettings = ({ match }: any) => {
  const { sid } = match.params;
  const { channel, loaded } = useChatChannel(sid);
  const childMethods = useRef<ImperativeHandleRef>(null);

  if (!channel || !loaded) return <LoadingBalls isActive fullscreen />;

  const handleSubmit = () => {
    if (childMethods?.current) childMethods.current.submit();
  };

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">Conversation Settings</h6>
          <ConversationSettings ref={childMethods} channel={channel} />
        </ViewContent>
        <ViewFooter className="actions">
          <BackButton
            className="btn btn--primary btn--sm btn--outline"
            defaultHref="/chat"
          />

          {childMethods.current?.currentUserRole?.isModerator ||
          childMethods.current?.currentUserRole?.isOwner ? (
            <button
              type="button"
              className="btn btn--sm btn--primary ml-4"
              onClick={handleSubmit}
            >
              Save
            </button>
          ) : null}
        </ViewFooter>
      </>
    );

  const SaveButton = () =>
    childMethods.current?.currentUserRole?.isModerator ||
    childMethods.current?.currentUserRole?.isOwner ? (
      <button
        type="button"
        className="btn btn--sm btn--primary btn--rev"
        onClick={handleSubmit}
      >
        Save
      </button>
    ) : null;

  return (
    <View authGuard darkStatusBar pageTitle="Chat Conversation Settings">
      <ViewHeader defaultBackHref="/chat" end={<SaveButton />}>
        <ViewHeaderTitle>Group Settings</ViewHeaderTitle>
      </ViewHeader>
      <ViewContent layout="chat">
        <ConversationSettings channel={channel} />
      </ViewContent>
    </View>
  );
};

export default ChatConversationSettings;
