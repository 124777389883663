import React from 'react';
import { FormGroup, Checkbox } from '../../core/form';
import ExternalLink from '../../core/router/ExternalLink';

export default ({ name, errors, register }: any) => (
  <FormGroup name={name} errors={errors}>
    <label htmlFor={name} className="checkbox-stylized flex">
      <Checkbox
        name={name}
        value="accepted"
        ref={register({
          required: 'Terms and Privacy are required',
        })}
      />
      <span className="text-subdued text-xs leading-normal ml-4">
        I accept the{' '}
        <ExternalLink to="https://communo.com/privacy" className="text-link">
          Privacy Policy
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink to="https://communo.com/terms" className="text-link">
          Terms and Conditions of Use
        </ExternalLink>
        .
      </span>
    </label>
  </FormGroup>
);
