/* eslint-disable import/prefer-default-export */

import cloudinary from "./Cloudinary";

export const srcSetFunction = (imageURL: string, widths: string[]) => {
  const array = widths.map((x: string) => {
    const source = cloudinary(imageURL, [`w_${x}`]);
    return `${source} ${x}w`;
  });
  return array.join(', ');
};

export const srcSetDpiFunction = (imageURL: string, size: string) => {
  const dpiValues = [1, 2, 3];
    const arrayDpi = dpiValues.map((dpi: number) => {
      const source = cloudinary(imageURL, [
        `w_${size}`,
        `h_${size}`,
        `dpr_${dpi}.0`,
      ]);
      return `${source} ${dpi}x`;
    });
    return arrayDpi.join(', ');
};