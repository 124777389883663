import React from 'react';
import Page from '../components/core/Page';
import Button from '../components/core/Button';
import useFeatureGate from '../hooks/useFeatureGate';

export default () => {
  const { openUpgradeFlowFor, needsUpgrade } = useFeatureGate();

  return (
    <Page layout="basic" pageTitle="Actions" mobileHeader={() => null}>
      <div className="flex flex-col">
        <h3>needsUpgrade()</h3>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            if (needsUpgrade('numUserRoles', 2)) {
              // Dont do the thing...
            }

            // Do the thing...
          }}
        >
          numUserRoles
        </Button>

        <h3>openUpgradeFlowFor()</h3>
        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canViewWorkList');
          }}
        >
          Fallback if we dont have a flow for the permission
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('numUserRoles', 2);
          }}
        >
          numUserRoles
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('numMembershipRoles', 2);
          }}
        >
          numMembershipRoles
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canPostWorkToCommuno');
          }}
        >
          canPostWorkToCommuno
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canApplyToCommunoWork');
          }}
        >
          canApplyToCommunoWork
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('benchSizeLimit', 11);
          }}
        >
          benchSizeLimit
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canAddToBench');
          }}
        >
          canAddToBench
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('numSeats', 2);
          }}
        >
          numSeats
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('numUserIndustries', 2);
          }}
        >
          numUserIndustries
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('numMembershipIndustries', 2);
          }}
        >
          numMembershipIndustries
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canChatToIndividuals');
          }}
        >
          canChatToIndividuals
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canChatToAgencies');
          }}
        >
          canChatToAgencies
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canChatToBusinesses');
          }}
        >
          canChatToBusinesses
        </Button>

        <Button
          className="btn btn--primary my-2"
          onClick={() => {
            openUpgradeFlowFor('canPostEvents');
          }}
        >
          canPostEvents
        </Button>
      </div>
    </Page>
  );
};
