import React from 'react';
import Portal from '../core/Portal';
import { isMobile } from '../../utilities/Device';

type Props = {
  open: boolean;
  children: JSX.Element | JSX.Element[] | string;
  setOpen: Function;
};

const WrapperComponent = isMobile ? Portal : 'div';

export default ({ open, setOpen, children }: Props) => {
  return (
    <WrapperComponent id={isMobile ? 'sidebar-menu-portal' : ''}>
      <div className={`sidebar-menu ${open ? 'open' : 'closed'}`}>
        <div className="backdrop" role="none" onClick={() => setOpen(false)} />
        <div className="menu-content rounded-l md:rounded-lg overflow-y-scroll md:overflow-auto">
          {children}
        </div>
      </div>
    </WrapperComponent>
  );
};
