import React, { useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { ChannelInteraction } from '../../@types/chat.d';
import {
  FormActions,
  FormTextarea,
  Autocomplete,
  FormGroup,
} from '../core/form';
import { SubmitButton } from '../core/Button';
import channelApi from './Api';
import SearchContext from '../search/Context';
import Avatar from '../avatar/Avatar';
import useChatServices from '../chat/hooks/useChatServices';
import { addError } from '../../services/Messaging';

function isResponseBodyError(
  error: any
): error is AxiosError<{ message: string }> {
  return (
    (error as AxiosError<{ message: string }>).response?.data.message !==
    undefined
  );
}

type LabelProps = {
  label: string;
  value: {
    identity: string;
    firstName: string;
    lastName: string;
    avatarURL: string;
  };
};

type ShareProps = {
  channel: ChannelInteraction;
  onComplete: () => void;
};

type ShareFormFields = {
  message: string;
  recipient: {
    label: string;
    value: {
      avatarUrl: string;
      firstName: string;
      identity: string;
      lastName: string;
    };
  };
};

const Share = ({ channel, onComplete }: ShareProps): JSX.Element => {
  const { checkForExistingChannel, createChannel } = useChatServices();
  const { getUsers } = useContext(SearchContext);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<ShareFormFields>({
    defaultValues: {
      message: 'Thought you might want to be part of this conversation.',
    },
  });

  const { handleSubmit, register, errors, reset } = form;

  async function onSubmit(values: ShareFormFields) {
    try {
      setIsLoading(true);
      const { recipient, message } = values;
      const { identity } = recipient.value;

      const fullMessage = `${message}<br/><br/><a id="shared-channel-link" class="linkified" href="${window.location.origin}/chat/conversation/${channel.conversationId}">${channel.title}</a>`;
      const existingChannel = await checkForExistingChannel(identity);

      if (existingChannel) {
        await existingChannel.sendMessage(fullMessage);
        await existingChannel.setAllMessagesRead();
      } else {
        await createChannel({
          identities: [identity],
          message: fullMessage,
        });
      }

      await channelApi.share(channel.id, [identity]);

      reset();
      onComplete();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        isResponseBodyError(error) &&
        error.response?.data.message !== undefined
      ) {
        Sentry.captureException(error);
        addError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const getResults = (q: string) =>
    getUsers({ query: q }).then(({ hits }: any) =>
      hits.map((hit: any) => {
        const { objectID: identity, firstName, lastName, avatarURL } = hit;
        return {
          value: { identity, firstName, lastName, avatarURL },
          label: `${firstName} ${lastName}`,
        };
      })
    );

  const formatLabel = ({ label, value }: LabelProps) => {
    const { firstName, lastName, avatarURL } = value;

    return (
      <div className="flex items-center">
        <Avatar avatarName={`${firstName} ${lastName}`} avatarUrl={avatarURL} />
        <span className="ml-2">{label}</span>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2 className="mb-4">Share conversation</h2>
      <p className="mb-8">
        Know someone who would be interested in this channel? Share to invite a
        member to join in the conversation.
      </p>
      <FormGroup
        className="mb-8"
        name="recipient"
        label="Search members"
        errors={errors}
      >
        <Autocomplete
          name="recipient"
          form={form}
          callback={getResults}
          isRequired
          placeholder="Search users..."
          formatOptionLabel={formatLabel}
        />
      </FormGroup>
      <FormTextarea
        className="mb-8"
        name="message"
        label="Your message here"
        placeholder="Compose your message..."
        ref={register({
          required: {
            value: true,
            message: 'A message is required',
          },
        })}
        errors={errors}
        hint="We’ll include the channel name and a link as part of you message."
      />

      <FormActions>
        <div className="md:ml-auto">
          <SubmitButton text="Share" loading={isLoading} />
        </div>
      </FormActions>
    </form>
  );
};

export default Share;
