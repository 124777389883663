import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Form from './Form';
import { LoadingBalls } from '../core/Loading';
import portfolioApi from './Api';
import { addResponseError, addSuccess } from '../../services/Messaging';
import usePortfolioItems from '../../hooks/usePortfolioItems';
import { canPlayVideo } from '../video/VideoPlayer';

interface Props {
  projectId: string;
}

const Edit = ({ projectId }: Props) => {
  const { location } = useHistory();

  // Hacky way to detect if we are creating a user project vs a membership project
  const isUserProject = location.hash.includes('#/edit-profile/');

  const { portfolioItems } = usePortfolioItems(isUserProject);

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<PortfolioEntity>();

  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      title: '',
      description: '',
      headerPhotoURL: '',
      videoUrl: '',
      imageURLs: [],
    },
  });
  const { setValue, reset, setError, clearErrors, errors } = form;
  const { id: portfolioId } = item || { id: '' };

  // TODO: Hack for now until more elegant solution
  const backRoute = isUserProject
    ? '#/edit-profile/portfolio'
    : '#/edit-page/portfolio';

  const loadItem = useCallback(
    // eslint-disable-next-line no-shadow
    (projectId: string) => {
      const foundItem = portfolioItems.find(
        (portfolioItem: PortfolioEntity) => portfolioItem.id === projectId
      );

      if (foundItem !== undefined) {
        const defaultValues = {
          title: foundItem.title || '',
          description: foundItem.description || '',
          headerPhotoURL: foundItem.headerPhotoURL || '',
          videoUrl: foundItem.videoUrl || '',
          imageURLs: [],
          position:
            foundItem.position ??
            (portfolioItems.length ? portfolioItems.length + 1 : 0),
        };

        reset(defaultValues);
        setItem(foundItem);
        setImageURLs(foundItem.imageURLs || []);
        setLoading(false);
      }
    },
    [portfolioItems, reset]
  );

  useEffect(() => {
    loadItem(projectId);
  }, [projectId, loadItem]);

  const patchItem = useCallback(
    (data: any, successMsg: string = 'Project Updated!') => {
      if (!portfolioId) {
        return;
      }

      portfolioApi
        .updateProject(portfolioId, data)
        .then((response: PortfolioEntity) => {
          setValue('videoUrl', response.videoUrl ?? '');
          clearErrors('imageURLs');
          addSuccess(successMsg);
          return response;
        })
        .catch((error: any) => {
          addResponseError(error);
          return error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [portfolioId, setValue, clearErrors]
  );

  const updateCover = (url: string) => {
    setLoading(true);

    // Just patch the headerPhotoURL
    patchItem({ headerPhotoURL: url }, 'Cover Updated!');
  };

  const updateImages = useCallback(
    async (images: string[]) => {
      try {
        if (images.length > 12) {
          throw new Error('Max of 12 images. Please remove one or more.');
        }

        setLoading(true);
        setImageURLs(images);
        clearErrors('imageURLs');

        // Just patch the imageURLs
        patchItem({ imageURLs: images }, 'Images Updated!');
      } catch (error) {
        setError('imageURLs', {
          type: 'images',
          message: error.message,
        });
      }
    },
    [clearErrors, patchItem, setError]
  );

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (values: any) => {
    if (loading || !item?.id || hasErrors()) return;

    try {
      const data = {
        ...values,
        imageURLs,
      };

      if (imageURLs.length > 12) {
        throw new Error('Max of 12 images. Please remove one or more.');
      }

      const hasImages = imageURLs.length > 0;
      const videoPlayable = canPlayVideo(data.videoUrl);
      const validVideoUrl = data.videoUrl && videoPlayable;

      if (!hasImages && !validVideoUrl) {
        throw new Error('Must have at least one image or a video.');
      }

      if (!videoPlayable) {
        data.videoUrl = null;
      }

      setLoading(true);
      patchItem(data);
    } catch (error) {
      setError('imageURLs', {
        type: 'images',
        message: error.message,
      });
    }
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {item && (
        <Form
          form={form}
          onSubmit={onSubmit}
          imagesChanged={updateImages}
          coverChanged={updateCover}
          defaultValues={item}
          backRoute={backRoute}
        />
      )}
    </>
  );
};

export default Edit;
