import { SetState, GetState } from 'zustand';
import createStore from '../../../utilities/createStore';
import { MetricStatus } from '../../candidateScore/MetricStatus';

export type MembershipProfileAttribute = keyof MembershipEntity;

interface MembershipCompletenessStore {
  loaded: boolean;
  evaluation: Record<string, MetricStatus>;
  evaluateMembership(membership: MembershipEntity): void;
  membershipCompleted(attribute: MembershipProfileAttribute): boolean;
  membershipCompletedAll(attribute: MembershipProfileAttribute[]): boolean;
  membershipCompletedAny(attributes: MembershipProfileAttribute[]): boolean;
  getStatusFor(attribute: MembershipProfileAttribute): MetricStatus;
  getStatusesFor(attributes: MembershipProfileAttribute[]): MetricStatus[];
  getStatusForAll(attributes: MembershipProfileAttribute[]): MetricStatus;
}

interface Criteria {
  key: string;
  getStatus(membership: MembershipEntity): MetricStatus;
}

const criteria: Criteria[] = [
  {
    key: 'logoURL',
    getStatus: ({ logoURL }) => (logoURL ? 'complete' : null),
  },
  {
    key: 'summary',
    getStatus: ({ summary }) => (summary ? 'complete' : null),
  },
  {
    key: 'about',
    getStatus: ({ about }) => (about ? 'complete' : null),
  },
  {
    key: 'socialLinks',
    getStatus: ({ socialLinks }) => {
      const links = Object.values(socialLinks).filter(link => {
        // Results in false if null or empty string
        return link;
      });

      return links.length > 0 ? 'complete' : null;
    },
  },
  {
    key: 'coverPhotoURL',
    getStatus: ({ coverPhotoURL }) => (coverPhotoURL ? 'complete' : null),
  },
  {
    key: 'skills',
    getStatus: ({ skills }) => (skills.length > 0 ? 'complete' : null),
  },
  {
    key: 'industries',
    getStatus: ({ industries }) => (industries.length > 0 ? 'complete' : null),
  },
  {
    key: 'portfolioItems',
    getStatus: ({ portfolioItems }) => {
      if (portfolioItems.length >= 3) {
        return 'complete';
      }

      if (portfolioItems.length > 0) {
        return 'partial';
      }

      return null;
    },
  },
  {
    key: 'tagline',
    getStatus: ({ tagline }) => {
      // Needed because tagline is sometimes an empty string
      return tagline && tagline.length > 0 ? 'complete' : null;
    },
  },
  {
    key: 'imageURLs',
    getStatus: ({ imageURLs }) =>
      imageURLs && imageURLs.length > 0 ? 'complete' : null,
  },
  {
    key: 'videoUrl',
    getStatus: ({ videoUrl }) => (videoUrl ? 'complete' : null),
  },
];

const membershipCompletenessStore = createStore<MembershipCompletenessStore>(
  (
    set: SetState<MembershipCompletenessStore>,
    get: GetState<MembershipCompletenessStore>
  ) => ({
    loaded: false,
    // An array of the results from evaluating the user properties
    evaluation: {},
    evaluateMembership: (membership: MembershipEntity) => {
      const results = criteria.reduce((acc, current) => {
        const { key, getStatus } = current;

        acc[key] = getStatus(membership);

        return acc;
      }, {} as any);

      // console.log('membership', membership);
      // console.log('evaluated membership', results);

      set(() => ({
        evaluation: results,
        loaded: true,
      }));
    },
    // Check if the membership has completed the given field
    membershipCompleted: (attribute: MembershipProfileAttribute): boolean => {
      const { evaluation } = get();

      if (Object.prototype.hasOwnProperty.call(evaluation, attribute)) {
        return evaluation[attribute] === 'complete';
      }

      return false;
    },
    membershipCompletedAll: (attributes: MembershipProfileAttribute[]) => {
      const { membershipCompleted } = get();

      return attributes.every(attribute => membershipCompleted(attribute));
    },
    membershipCompletedAny: (attributes: MembershipProfileAttribute[]) => {
      const { membershipCompleted } = get();
      const foundIndex = attributes.findIndex(attribute =>
        membershipCompleted(attribute)
      );

      return foundIndex >= 0;
    },
    getStatusFor: (attribute: MembershipProfileAttribute) => {
      const { evaluation } = get();

      if (Object.prototype.hasOwnProperty.call(evaluation, attribute)) {
        return evaluation[attribute];
      }

      return null;
    },
    getStatusesFor: (attributes: MembershipProfileAttribute[]) => {
      const { getStatusFor } = get();

      return attributes.map(attribute => {
        return getStatusFor(attribute);
      });
    },
    getStatusForAll: (attributes: MembershipProfileAttribute[]) => {
      const { getStatusFor } = get();
      // array of true/false
      const statuses = attributes.map(attribute => getStatusFor(attribute));

      // need statuses.length because .every() will return true for empty array
      if (statuses.length && statuses.every(status => status === 'complete')) {
        return 'complete';
      }

      if (
        statuses.some(status => status === 'partial' || status === 'complete')
      ) {
        return 'partial';
      }

      return null;
    },
  }),
  'membershipCompletenessStore'
);

export default membershipCompletenessStore;
