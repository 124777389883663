import shallow from 'zustand/shallow';
import { useQuery } from '@tanstack/react-query';
import channelStoreCreator, {
  ChatChannelState,
} from '../state/ChatChannelState';
import useChatClient from './useChatClient';
import { IChannel, IClient } from '../../../@types/chat.d';
import { addError } from '../../../services/Messaging';

type CustomTwilsockError = {
  body: {
    message: string;
  };
};

/**
 * useChatChannel
 *
 * Returns a channel that the current user has access to.
 */
const useChatChannel = (sid: string) => {
  const { clientInstance } = useChatClient();
  const [channelStore] = channelStoreCreator(sid);
  const [channel, setChannel] = channelStore(
    (state: ChatChannelState) => [state.channel, state.setChannel],
    shallow
  );

  const queryResult = useQuery(
    ['twilio', sid],
    () => (clientInstance as IClient).getConversationBySid(sid),
    {
      enabled: !!clientInstance && !!sid,
      onError: (error: CustomTwilsockError) => addError(error.body.message),
      onSuccess: conversation => setChannel(conversation as IChannel),
    }
  );

  return {
    channel,
    loading: queryResult.isLoading,
    loaded: !queryResult.isLoading,
  };
};

export default useChatChannel;
