import { useQuery } from '@tanstack/react-query';
import Api from '../components/work/Api';

export default function(
  workId: string
): {
  data: QualificationEntity | null;
  loading: boolean;
  refresh: Function;
} {
  const result = useQuery(
    ['myApplication', workId],
    () => Api.myApplication(workId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return {
    data: result.data,
    loading: result.isLoading,
    refresh: result.refetch,
  };
}
