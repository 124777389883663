import { SchoolSearchResponse } from '@communo-corp/shared-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Async from 'react-select/async';
import { FormGroup } from '../../core/form';
import { isMobile } from '../../../utilities/Device';
import SearchContext from '../../search/Context';

interface Value {
  value: string;
  label: string;
}

interface Props {
  form: any;
  onlyHbcuSchools?: boolean;
  defaultValue?: Value;
}

const SchoolInput = ({
  form,
  onlyHbcuSchools = false,
  defaultValue,
}: Props) => {
  const { getSchools } = useContext(SearchContext);
  const [value, setValue] = useState<Value | undefined>(defaultValue);
  const { errors, register, setValue: setInputValue } = form;
  const Component = onlyHbcuSchools ? Async : AsyncCreatableSelect;

  const updateInputValue = useCallback(
    (school: Value) => {
      setInputValue('school', school, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setInputValue]
  );

  const onChangeSchool = useCallback(
    (selected: any) => {
      setValue(selected);
      updateInputValue(selected);
    },
    [updateInputValue]
  );

  const handleCreateSchool = useCallback(
    (newSchoolName: string) => {
      const newSchool = {
        value: 'create',
        label: newSchoolName,
      };

      setValue(newSchool);
      updateInputValue(newSchool);
    },
    [updateInputValue]
  );

  const newCreateLabel = () => {
    return `This school is not currently in our list. Click here to add to it to your profile.`;
  };

  useEffect(() => {
    register('school', {
      required: 'A school is required.',
    });
  }, [register]);

  return (
    <FormGroup
      className="mt-2"
      name="school"
      label="School"
      errors={errors}
      required
    >
      <Component
        name="school"
        isClearable
        onChange={onChangeSchool}
        defaultValue={value}
        cacheOptions
        defaultOptions
        loadOptions={async input => {
          const { hits } = await getSchools(input, onlyHbcuSchools);

          // The following "?" types aren't necessary based on the School type but we have some bad data that got synced to algolia
          const schools = hits.map((school: SchoolSearchResponse) => ({
            value: school.objectID,
            label: `${school.name} - ${school?.country?.name}`,
          }));

          return schools;
        }}
        value={value}
        onCreateOption={handleCreateSchool}
        formatCreateLabel={newCreateLabel}
        placeholder="Type to search schools..."
        className="multiselect"
        classNamePrefix="multi"
        maxMenuHeight={isMobile ? 200 : undefined}
      />
    </FormGroup>
  );
};
export default SchoolInput;
