import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { track } from '../../../analytics';
import { addResponseError } from '../../../services/Messaging';
import { FormInput } from '../../core/form';
import useStep from '../../stepper/hooks/useStep';
import userApi from '../../user/Api';
import UserContext from '../../user/Context';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';

interface Inputs {
  LinkedInURL: string;
  title: string;
  phoneNumber: string;
}

const MoreInfo = () => {
  const {
    setCanAdvance,
    setOnNext,
    isLoading,
    setLoading,
    goToNextStep,
  } = useStep();
  const { user, update, isAuthenticated, authDetails } = useContext(
    UserContext
  );
  const { oauthProviderId } = authDetails;
  const { form, oAuthProvider } = useContext(SubmitReferenceContext);
  const { setValue } = form;

  const { formState, errors, register, handleSubmit, watch } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isValid, isDirty } = formState;
  const { title, LinkedInURL, phoneNumber } = watch();

  const defaultUrl =
    user?.socialLinks.LinkedInURL || oAuthProvider?.externalProfileUrl;
  const defaultTitle = user?.title || oAuthProvider?.title;
  const defaultPhone = user?.phoneNumber || oAuthProvider?.phone;

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'More Info',
    });
  }, []);

  useEffect(() => {
    setValue('title', title);
  }, [setValue, title]);

  useEffect(() => {
    setValue('LinkedInURL', LinkedInURL);
  }, [LinkedInURL, setValue, title]);

  useEffect(() => {
    setValue('phoneNumber', phoneNumber);
  }, [phoneNumber, setValue]);

  const updateOAuthProvider = useCallback(
    (providerId: string, values: Inputs) => {
      const data = {
        title: values.title,
        phone: values.phoneNumber,
        externalProfileUrl: values.LinkedInURL,
      };

      return userApi.updateAuthProvider({
        ...data,
        oauthProviderId: providerId,
      });
    },
    []
  );

  const updateUser = useCallback(
    async (values: Inputs) => {
      const data = {
        title: values.title,
        socialLinks: {
          LinkedInURL: values.LinkedInURL,
        },
        phoneNumber: values.phoneNumber,
      };

      return update(data);
    },
    [update]
  );

  const onSubmit = useCallback(
    async (values: Inputs) => {
      if (isLoading) {
        return;
      }

      setLoading(true);

      if (!isAuthenticated && oauthProviderId) {
        try {
          await updateOAuthProvider(oauthProviderId, values);
          goToNextStep();
          return;
        } catch (error) {
          addResponseError(error);
          setLoading(false);
          return;
        }
      }

      try {
        await updateUser(values);
        goToNextStep();
      } catch (error) {
        addResponseError(error);
        setLoading(false);
      }
    },
    [
      goToNextStep,
      isAuthenticated,
      isLoading,
      oauthProviderId,
      setLoading,
      updateOAuthProvider,
      updateUser,
    ]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        required
        name="LinkedInURL"
        label="LinkedIn Profile Url"
        placeholder="URL"
        errors={errors}
        defaultValue={defaultUrl}
        ref={register({
          validate: {
            required: (value: string) =>
              value.length > 0 || 'Your LinkedIn profile url is required',
            linkedinUrl: (value: string) =>
              value.includes('linkedin.com') || 'Not a valid LinkedIn url',
            validUrl: (value: string) =>
              validator.isURL(value) || 'Invalid url',
          },
        })}
      />

      <FormInput
        required
        name="title"
        label="Your title"
        placeholder="Position at Company"
        errors={errors}
        defaultValue={defaultTitle}
        ref={register({
          required: 'Your title is required',
        })}
      />

      <FormInput
        name="phoneNumber"
        label="Phone number"
        labelSubtext="(optional)"
        placeholder="000-000-0000"
        defaultValue={defaultPhone}
        errors={errors}
        ref={register}
      />
    </form>
  );
};
export default MoreInfo;
