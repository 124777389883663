import { keyBy } from 'lodash';
import { IMessage } from '../../../@types/chat.d';

type Props = {
  [sid: string]: IMessage;
};

let Messages: Props = {};

export const add = (messages: IMessage[]) => {
  Messages = {
    ...Messages,
    ...keyBy(messages, 'sid'),
  };
};

export const remove = (messages: IMessage[]) =>
  messages.forEach(message => delete Messages[message.sid]);

export const update = (messages: IMessage[]) =>
  messages.forEach(message => {
    Messages[message.sid] = message;
  });

export const select = (sid: string) => Messages[sid];
export const selectMany = (sids: string[]) => sids.map(sid => Messages[sid]);

const MessageInstances = {
  add,
  remove,
  update,
  select,
  selectMany,
};

export default MessageInstances;
