import { useContext } from 'react';
import { ChatClientContext } from '../context/ChatClientContext';

/**
 * useChatClient
 *
 * Hook to expose the ChatClientContext.
 * Only purpose is to ensure components don't
 * have to access context objects directly.
 */
const useChatClient = () => {
  return useContext(ChatClientContext);
};
export default useChatClient;
