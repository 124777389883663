import React from 'react';
import ReactStars from 'react-stars';

import useApplicationNotes from '../../hooks/useApplicationNotes';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import ApplicationNotes from './view/ApplicationNotes';

type Props = {
  setSegment: Function;
  application: ApplicationEntity;
  work: WorkEntity;
  role: string | undefined;
};

function CandidatesNotesTab({ setSegment, application, work, role }: Props) {
  const { aggregateRating } = useApplicationNotes({
    workId: work.id,
    applicationId: application.id,
  });
  const { user } = application;
  const membership = user.primaryMembershipUser?.membership;
  const planType = membership?.plan.type;

  const showProfileTab = () => setSegment('profile');
  const { firstName, lastName, avatarURL } = user;
  const name = `${firstName} ${lastName}`;

  return (
    <div className="flex flex-col p-4 pb-48 md:pb-4 gap-4">
      <div className="flex">
        <div
          className="mr-4 cursor-pointer"
          onClick={showProfileTab}
          role="none"
        >
          <ScoreAvatar
            name={name}
            imageUrl={avatarURL}
            percentage={application.score}
            size={20}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center">
            <ReactStars
              count={5}
              value={aggregateRating ? aggregateRating.average : 0}
              edit={false}
              size={20}
              color2="#E8A318"
            />
            <div className="text-sm ml-1 text-gold-900 font-bold">
              ({aggregateRating.count})
            </div>
          </div>
          <div className="truncate">
            <div
              className="font-bold text-lg cursor-pointer"
              onClick={showProfileTab}
              role="none"
            >
              {name}
            </div>
            {planType === 'agency' && membership && (
              <div>{membership.name}</div>
            )}
            <div className="font-light text-sm truncate">{role}</div>
          </div>
        </div>
      </div>

      <ApplicationNotes work={work} application={application} />
    </div>
  );
}

export default CandidatesNotesTab;
