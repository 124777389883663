import React from 'react';
import { BEATS_MEMBERSHIP_ID } from '../../../config';
import useBeatsWork from '../../../hooks/useBeatsWork';
import useMembership from '../../../hooks/useMembership';
import cloudinary from '../../../utilities/Cloudinary';
import WorkCard from '../../card/WorkCard';
import { LoadingRing } from '../../core/Loading';
import BeatsMacroLockup from '../../icons/BeatsMacroLockup';

const BeatsWork = () => {
  const { isFetching, membership } = useMembership(BEATS_MEMBERSHIP_ID);

  const workResults = useBeatsWork();
  const hits = workResults?.hits ?? [];

  if (isFetching || !membership) {
    return (
      <div className="py-8 text-center">
        <LoadingRing isActive />
      </div>
    );
  }

  const coverPhoto = membership?.coverPhotoURL;

  return (
    <div className="page-container py-4 md:py-8">
      <div className="flex justify-between mb-4">
        <h2>Program</h2>
      </div>

      {coverPhoto ? (
        <div className="relative">
          <div className="aspect-4/3 sm:aspect-21/9">
            <img
              src={cloudinary(coverPhoto, ['w_2680'])}
              alt={membership.name}
              className="absolute inset-0 w-full h-full object-cover z-10"
            />
          </div>

          <div className="absolute bottom-0 z-10 max-w-xl text-white p-8 md:p-12 2xl:p-16">
            <BeatsMacroLockup />
          </div>
        </div>
      ) : null}

      <div className="mt-8">
        {hits.length === 0 ? (
          <p>There are currently no Work Posts by Beats.</p>
        ) : (
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 mb-4">
            {hits.map((work: WorkEntity) => (
              <WorkCard
                key={work.objectID}
                work={work}
                cardStyle="full"
                className="shadow-xl"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default BeatsWork;
