import React from 'react';
import useNavigate from '../../../hooks/useNavigate';
import Metric from '../Metric';

type Metric = {
  key: WorkGatingRequirements;
  label: string;
  actionUrl?: string;
};

const userMetrics: Metric[] = [
  {
    key: 'avatar',
    label: 'Avatar',
    actionUrl: '#/edit-profile/basic',
  },
  {
    key: 'skills',
    label: 'Skills',
    actionUrl: '#/edit-profile/roles',
  },
  {
    key: 'industries',
    label: 'Industries',
    actionUrl: '#/edit-profile/roles',
  },
  {
    key: 'about',
    label: 'About',
    actionUrl: '#/edit-profile/about',
  },
  {
    key: 'socialLinks',
    label: 'Links',
    actionUrl: '#/edit-profile/links',
  },
  {
    key: 'portfolio',
    label: 'Portfolio',
    actionUrl: '#/edit-profile/portfolio',
  },
  {
    key: 'video',
    label: 'Video',
    actionUrl: '#/edit-profile/about',
  },
];

const agencyMetrics: Metric[] = [
  {
    key: 'avatar',
    label: 'Logo',
    actionUrl: '#/edit-page/basic',
  },
  {
    key: 'skills',
    label: 'Skills',
    actionUrl: '#/edit-page/roles',
  },
  {
    key: 'industries',
    label: 'Industries',
    actionUrl: '#/edit-page/roles',
  },
  {
    key: 'about',
    label: 'About',
    actionUrl: '#/edit-page/about',
  },
  {
    key: 'socialLinks',
    label: 'Links',
    actionUrl: '#/edit-page/links',
  },
  {
    key: 'portfolio',
    label: 'Portfolio',
    actionUrl: '#/edit-page/portfolio',
  },
  {
    key: 'video',
    label: 'Video',
    actionUrl: '#/edit-page/about',
  },
];

type Props = {
  isUser: boolean;
  requirements: ApplicationRequirements;
  work: WorkEntity;
};

const WorkRequirementMetrics = ({ isUser, requirements, work }: Props) => {
  const navigate = useNavigate();
  const metrics = isUser ? userMetrics : agencyMetrics;
  const workRequirements = work.gatingRequirements ?? [];

  const requiredMetrics = metrics.filter(metric =>
    workRequirements.includes(metric.key)
  );

  if (!requirements) {
    return null;
  }

  return (
    <>
      {requiredMetrics.map(metric => {
        const { key, label, actionUrl } = metric;
        const status = requirements[key] ? 'complete' : null;

        const handleClick = () => {
          if (actionUrl) {
            navigate(actionUrl, 'none', 'push');
          }
        };

        return (
          <Metric
            key={key}
            label={label}
            status={status}
            onClick={status === null ? handleClick : undefined}
          />
        );
      })}
    </>
  );
};
export default React.memo(WorkRequirementMetrics);
