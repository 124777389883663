import React from 'react';
import { CollectionSummary } from '@communo-corp/shared-types';
import EmailCollectionInviteForm from './EmailCollectionInviteForm';
import EmailInvitesList from './EmailInvitesList';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import useCollectionEmailInvites from '../hooks/useCollectionEmailInvites';
import EmailInviteLimit from './EmailInviteLimit';

type Props = {
  collection: CollectionSummary;
};

const EmailInviteToCollection = ({ collection }: Props) => {
  const plan = useCurrentPlan();

  const inviteLimit =
    collection.type === 'bench'
      ? plan?.benchSizeLimit ?? null
      : plan?.listSizeLimit ?? null;
  const hasInviteLimit = inviteLimit !== null;

  const {
    invites,
    isLoading,
    refetch: refetchInvites,
  } = useCollectionEmailInvites(collection.id, !hasInviteLimit);

  return (
    <div className="p-4 md:p-8 border-t-2 border-grey-500">
      <div className="flex items-center space-x-4">
        <div className="w-12">
          <div className="icon-circle bg-blue-1100 text-white text-2xl">
            <i className="i-mail" />
          </div>
        </div>

        <h4 className="h4">Invite via email</h4>
      </div>

      <div className="flex-1 pl-0 md:pl-16">
        {hasInviteLimit ? (
          <EmailInviteLimit
            collection={collection}
            inviteCount={invites.length}
            limit={inviteLimit}
          >
            {({ inviteCount, limit }) => (
              <>
                You have used <span className="font-bold">{inviteCount}</span>{' '}
                of the <span className="font-bold">{limit}</span> invitations
                allowed on your plan.
              </>
            )}
          </EmailInviteLimit>
        ) : null}

        <EmailCollectionInviteForm
          collection={collection}
          inviteCount={invites.length}
          refetchInvites={refetchInvites}
        />

        {hasInviteLimit ? (
          <EmailInvitesList
            collection={collection}
            invites={invites}
            isLoading={isLoading}
            refetchInvites={refetchInvites}
          />
        ) : null}
      </div>
    </div>
  );
};
export default EmailInviteToCollection;
