import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { LoadingBalls } from '../components/core/Loading';
import BlankConversation from '../components/chat/views/BlankConversation';
import useChatServices from '../components/chat/hooks/useChatServices';
import { IChannel } from '../@types/chat.d';
import View from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';

const ChatBlankConversation = ({ match }: any) => {
  const { identity } = match.params;

  const history = useHistory();
  const { loaded, checkForExistingChannel } = useChatServices();

  const checkForChannel = async () => {
    const existingChannel = await checkForExistingChannel(identity);

    if (existingChannel) {
      history.replace(`/chat/conversation/${existingChannel.sid}`);
    }
  };

  useEffect(() => {
    checkForChannel();
    // We only want to run this once
    // eslint-disable-next-line
  }, []);

  if (!loaded) return <LoadingBalls isActive fullscreen />;

  const handleChannelCreated = (channel: IChannel) =>
    history.replace(`/chat/conversation/${channel.sid}`);

  if (isDesktop)
    return (
      <BlankConversation
        identity={identity}
        onChannelCreated={handleChannelCreated}
      />
    );

  return (
    <View authGuard darkStatusBar pageTitle="New Conversation">
      <BlankConversation
        identity={identity}
        onChannelCreated={handleChannelCreated}
      />
    </View>
  );
};

export default ChatBlankConversation;
