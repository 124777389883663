import { select } from '../instances/ChannelInstances';

/**
 * useChatChannelInstance
 *
 * Hook that returns a Channel instance given a channel sid.
 * The channel instance should only ever be used to access instance methods
 */
const useChatChannelInstance = (sid: string) => {
  return {
    channelInstance: select(sid),
  };
};

export default useChatChannelInstance;
