import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListActionsItemLink } from '../../listActions';
import { ChatProfileAvatar } from './ChannelProfile';

type MemberListItemProps = {
  friendlyName: string;
  avatarUrl: string;
  id: string;
  end: JSX.Element;
  disabled: boolean;
};

/**
 * Renders a list item containing each member's passed information
 *
 * @param param0.friendlyName - the member's identifiable list label name
 * @param param0.avatarUrl - the member's url for their avatar picture
 * @param param0.id - the member's participant id
 * @param param0.end - the right most elements of the list item (buttons, member label, e.g "Channel Creator", etc)
 * @param param0.disabled - sets whether the link is disabled or not
 */
export default function MemberListItem({
  friendlyName,
  avatarUrl,
  id,
  end,
  disabled,
}: MemberListItemProps): JSX.Element {
  const { location } = useHistory();

  return (
    <ListActionsItemLink
      start={
        <ChatProfileAvatar
          avatarSize="md"
          friendlyName={friendlyName}
          avatarUrl={avatarUrl}
        />
      }
      disabled={disabled}
      end={end}
      linkTo={location.pathname.replace('settings', `member/${id}`)}
    >
      {friendlyName}
    </ListActionsItemLink>
  );
}
