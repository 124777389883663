import React from 'react';
import { Setup } from '../components/user/views/invite';
import Page from '../components/core/Page';

type Params = {
  code: string;
  step?: 'setup';
};

export default ({ match }: any) => {
  const { code }: Params = match.params; // "step" is also a param
  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Get Started"
      mobileHeader={() => null}
      hideHeader
    >
      {/* {step === 'setup' ? <Setup code={code} /> : <Welcome code={code} />} */}
      <Setup code={code} />
    </Page>
  );
};
