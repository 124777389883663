import { memoize, pick, keyBy } from 'lodash';
import createStore from '../../../utilities/createStore';
import { IMember } from '../../../@types/chat.d';
import MemberInstances from '../instances/MemberInstances';

const keysToPick = [
  'attributes',
  'dateCreated',
  'dateUpdated',
  'identity',
  'isTyping',
  'lastConsumedMessageIndex',
  'lastConsumptionTimestamp',
  'sid',
  'roleSid',
  'type',
];

/**
 * ChatChannelMembersState
 * Stores the IDs of the members in a given channel.
 * A new store is created for each Channel accessed.
 */

export type ChatChannelMembersState = {
  channelId: string;
  members: {
    [identity: string]: IMember;
  };
  membersIdsTyping: string[];
  loading: boolean;
  loaded: boolean;
  loadingMembers: () => void;
  addMembers: (members: IMember[]) => void;
  addMember: (members: IMember) => void;
  updateMember: (member: IMember) => void;
  removeMember: (member: IMember) => void;
  addMemberIsTyping: (identity: string) => void;
  removeMemberIsTyping: (identity: string) => void;
};

const channelMembersStoreCreator = (channelId: string) => {
  return createStore<ChatChannelMembersState>(
    (set: any) => ({
      channelId,
      members: {},
      membersIdsTyping: [],
      loading: false,
      loaded: false,
      loadingMembers: () =>
        set(
          {
            loading: true,
            loaded: false,
          },
          'loadingMembers'
        ),
      addMembers: members => {
        const rawMemberData = members.map(member => pick(member, keysToPick));

        MemberInstances.add(members);

        set(
          (state: ChatChannelMembersState) => ({
            members: {
              ...state.members,
              ...keyBy(rawMemberData, 'identity'),
            },
            loaded: true,
            loading: false,
          }),
          'addMembers'
        );
      },
      addMember: member => {
        MemberInstances.add([member]);

        set(
          (state: ChatChannelMembersState) => ({
            members: {
              ...state.members,
              [member.identity]: pick(member, keysToPick),
            },
          }),
          'addMember'
        );
      },
      updateMember: member => {
        MemberInstances.update([member]);

        set(
          (state: ChatChannelMembersState) => ({
            members: {
              ...state.members,
              [member.identity]: pick(member, keysToPick),
            },
          }),
          'updateMember'
        );
      },
      removeMember: member =>
        set((state: ChatChannelMembersState) => {
          const members = { ...state.members };

          delete members[member.identity];
          MemberInstances.remove([member]);

          return {
            members: {
              ...members,
            },
          };
        }, 'removeMember'),
      addMemberIsTyping: (identity: string) =>
        set((state: ChatChannelMembersState) => {
          if (state.membersIdsTyping.includes(identity))
            return { membersIdsTyping: [...state.membersIdsTyping] };

          return { membersIdsTyping: [...state.membersIdsTyping, identity] };
        }, 'addMemberIsTyping'),
      removeMemberIsTyping: (identity: string) =>
        set(
          (state: ChatChannelMembersState) => ({
            membersIdsTyping: state.membersIdsTyping.filter(
              id => id !== identity
            ),
          }),
          'removeMemberIsTyping'
        ),
    }),
    `ChatChannelMembersState: ${channelId}`
  );
};

export default memoize(channelMembersStoreCreator);
