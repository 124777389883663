import React, { useRef, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { RemoteVideoTrack, LocalVideoTrack } from 'twilio-video';

const Avatar = ({ user }: { user: UserEntity }) => {
  if (!user) return null;

  if (user?.avatarURL) {
    return <img src={user.avatarURL || ''} alt="" />;
  }

  const initials = `${user.firstName[0]}${user.lastName[0]}`;
  return (
    <div className="mx-auto avatar avatar--square">
      <p className="avatar__initials text-6xl">{initials}</p>
    </div>
  );
};

const ParticipantVideo = ({
  track,
  user,
  isLocal = false,
}: {
  track: LocalVideoTrack | RemoteVideoTrack;
  user: UserEntity;
  isLocal?: boolean;
}) => {
  const ref = useRef<HTMLVideoElement>(null as any);

  useEffect(() => {
    if (track?.isEnabled) {
      track.attach(ref.current);
    } else {
      track.detach(ref.current);
    }
  }, [track, track.isEnabled]);

  const isFrontFacing =
    track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const style =
    isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

  return track?.isEnabled ? (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video className="m-0" ref={ref} style={style} />
  ) : (
    <Avatar user={user} />
  );
};

export default ParticipantVideo;
