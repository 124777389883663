import { useCallback, useContext, useEffect, useState } from 'react';
import Api from '../components/membership/Api';
import UserContext from '../components/user/Context';

export default function(
  membershipId: string
): {
  data: WorkEntity[] | null;
  loading: boolean;
} {
  const { isPublic } = useContext(UserContext);
  const [work, setWork] = useState({
    data: null,
    loading: true,
  });

  const updateState = useCallback((key, value) => {
    setWork(workState => ({
      ...workState,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (membershipId) {
      updateState('loading', true);

      (isPublic ? Api.publicWork(membershipId) : Api.work(membershipId))
        .then(response => {
          updateState('data', response);
        })
        .catch(() => {
          updateState('data', null);
        })
        .finally(() => {
          updateState('loading', false);
        });
    }
  }, [membershipId, updateState, isPublic]);

  return work;
}
