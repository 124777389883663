/**
 * NetworkStatus component handle changes in client/browser
 * network status and connectivity, displaying notices to the
 * user
 *
 * TODO display notice when the backend API becomes
 * unresponsive / is unavailable.
 *
 */
import React, { useEffect, useState, useCallback } from 'react';
import { Network } from '@capacitor/network';
import { addError, removeToast } from '../services/Messaging';

let handler: any;

const NetworkStatus = () => {
  const [messageId, setMessageId] = useState<string | null>(null);

  const handleOnline = useCallback(() => {
    if (messageId) {
      removeToast(messageId);
      setMessageId(null);
    }
  }, [messageId]);

  const handleOffline = useCallback(() => {
    addError('No internet: please check your network and try again.', {
      autoDismiss: false,
      callback: id => {
        setMessageId(id);
      },
    });
  }, []);

  useEffect(() => {
    handler = Network.addListener('networkStatusChange', networkStatus => {
      if (networkStatus.connected) handleOnline();
      else handleOffline();
    });

    return () => {
      handler.remove();
    };
  }, [handleOffline, handleOnline]);

  return null;
};

export default React.memo(NetworkStatus);
