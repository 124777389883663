import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useAsyncEffect from 'use-async-effect';
import Api from './Api';
import { isDesktop } from '../../utilities/MatchMedia';
import InlineConversation from '../chat/components/InlineConversation';
import { LoadingBalls } from '../core/Loading';
import useChatUser from '../chat/hooks/useChatUser';
import Avatar from '../avatar/Avatar';
import { ListActions } from '../listActions';
import ListActionsItem from '../listActions/ListActionsItem';
import { addResponseError } from '../../services/Messaging';
import useChatChannels from '../chat/hooks/useChatChannels';
import Alert from '../alert/Alert';
import UserContext from '../user/Context';
import ButtonLink from '../core/ButtonLink';

type Props = {
  workId: string;
  application: ApplicationEntity;
  patchApplications: Function;
  isActive?: boolean;
};

const Loading = () => (
  <div className="py-4">
    <LoadingBalls isActive />
  </div>
);

const ChatOneOnOne = ({ userId }: { userId: string }) => {
  const { isRoot } = useContext(UserContext);
  if (!isRoot) {
    return null;
  }

  return (
    <ButtonLink
      className="mt-4"
      fill="outline"
      color="tertiary"
      href={`/chat/new-direct-message/${userId}`}
    >
      Chat one-on-one
    </ButtonLink>
  );
};

const ConfirmJoinAlert = ({
  isOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  onCancel: Function;
  onConfirm: Function;
}) => {
  return (
    <Alert
      isOpen={isOpen}
      header="Join closed conversation?"
      message="<p>As a Root user you can start or join this conversation, but please be
      aware that this is a closed conversation between the job poster and the
      applicants, NOT a simple link to chat one-on-one with the applicant.
      <br /><br />
      Anything said here will be visible to the job poster.</p>"
      onDidDismiss={onCancel}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-secondary-btn mr-auto',
          handler: onCancel,
        },
        {
          text: 'Continue',
          cssClass: 'alert-primary-btn',
          handler: onConfirm,
        },
      ]}
    />
  );
};

const InitConversation = ({
  channelSid,
  isActive = false,
}: {
  channelSid: string;
  isActive: boolean;
}) => {
  const history = useHistory();

  useEffect(() => {
    if (isActive && !isDesktop) {
      // Delay this slightly so mobile chat tab click doesn't trigger
      // tab click below as modal closes
      setTimeout(() => {
        history.push(`/chat/conversation/${channelSid}`);
      }, 100);
    }
  }, [channelSid, history, isActive]);

  if (!isDesktop) {
    return null;
  }

  return (
    <div className="h-full border-b-2 border-grey-300">
      <InlineConversation sid={channelSid} hideHeader />
    </div>
  );
};

const CreateNewConversation = ({
  workId,
  application,
  patchApplications,
}: Props) => {
  const { isRoot } = useContext(UserContext);
  const { id: applicationId } = application;
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const createWorkChannel = async () => {
    setLoading(true);

    try {
      const res = await Api.createChat(workId, applicationId);
      const data = {
        [applicationId]: {
          chatChannelSid: res.chatChannelSid,
        },
      };
      setLoading(false);
      patchApplications(data, false);
    } catch (error) {
      setLoading(false);
      addResponseError(error);
    }
  };

  const handleCreateWorkChannel = async () => {
    if (isRoot) {
      setShowConfirmModal(true);
    } else {
      await createWorkChannel();
    }
  };

  return (
    <>
      <ConfirmJoinAlert
        isOpen={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => createWorkChannel()}
      />
      <div className="flex flex-col p-4 border-b-2 border-grey-300">
        <p className="mb-4">
          There is currently no existing conversation with this applicant. Would
          you like to start one?
        </p>

        {loading ? (
          <Loading />
        ) : (
          <button
            type="button"
            className="btn btn--primary"
            onClick={handleCreateWorkChannel}
            disabled={loading}
          >
            Chat with applicant
          </button>
        )}

        <ChatOneOnOne userId={application.user.id} />
      </div>
    </>
  );
};

const ConversationMember = ({ identity }: { identity: string }) => {
  const { user } = useChatUser(identity);

  if (!user) return null;

  const {
    friendlyName,
    attributes: { avatarURL },
  } = user;

  return (
    <ListActionsItem
      start={
        <Avatar size="md" avatarName={friendlyName} avatarUrl={avatarURL} />
      }
      end={<></>}
    >
      {user.friendlyName}
    </ListActionsItem>
  );
};

const PromptToJoinExistingConversation = ({
  workId,
  application,
  isActive = false,
}: Exclude<Props, 'patchApplications'>) => {
  const isMounted = useRef(false);

  const { isRoot } = useContext(UserContext);
  const { id: applicationId, chatChannelSid } = application;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasJoined, setHasJoined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<any[] | null>(null);

  const joinWorkChannel = async () => {
    setLoading(true);
    try {
      await Api.joinChat(workId, applicationId);
      setHasJoined(true);
    } catch (error) {
      addResponseError(error);
    }
    setLoading(false);
  };

  const handleJoinConversation = async () => {
    if (isRoot) {
      setShowConfirmModal(true);
    } else {
      await joinWorkChannel();
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  useAsyncEffect(async () => {
    if (members) return;

    setLoading(true);

    const response = await Api.chatMembers(workId, applicationId);

    if (!isMounted.current) {
      return;
    }

    setMembers(response.members);
    setLoading(false);
  }, []);

  if (loading || !members) return <Loading />;

  if (hasJoined && chatChannelSid)
    return <InitConversation channelSid={chatChannelSid} isActive={isActive} />;

  return (
    <>
      <ConfirmJoinAlert
        isOpen={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => joinWorkChannel()}
      />
      <div className="flex flex-col p-4">
        <div>
          <p>
            There is an existing conversation with this applicant. Would you
            like to join it?
          </p>
          <div className="text-lg mt-4">Conversation Members:</div>
          {members.length ? (
            <ListActions className="mt-2">
              {members.map(({ identity }) => (
                <ConversationMember key={identity} identity={identity} />
              ))}
            </ListActions>
          ) : (
            <p className="mt-2">There are no members in this conversation.</p>
          )}
        </div>
        <button
          type="button"
          className="btn btn--primary mt-4"
          onClick={handleJoinConversation}
          disabled={loading}
        >
          Join conversation
        </button>

        <ChatOneOnOne userId={application.user.id} />
      </div>
    </>
  );
};

const ApplicationConversation = ({
  workId,
  application,
  patchApplications,
  isActive = false,
}: Props) => {
  const { chatChannelSid: channelSid } = application;
  const { channels, loaded } = useChatChannels();

  if (!loaded) return <Loading />;

  if (channelSid) {
    const channel = channels.find(el => el.sid === channelSid);

    if (channel)
      return <InitConversation channelSid={channelSid} isActive={isActive} />;

    return (
      <PromptToJoinExistingConversation
        workId={workId}
        application={application}
        patchApplications={patchApplications}
        isActive={isActive}
      />
    );
  }

  return (
    <CreateNewConversation
      workId={workId}
      application={application}
      patchApplications={patchApplications}
    />
  );
};

export default ApplicationConversation;
