import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../avatar/Avatar';
import useChatChannelUnreadCount from '../chat/hooks/useChatChannelUnreadCount';

const ApplicantUnread = ({
  application,
}: {
  application: ApplicationEntity;
}) => {
  const { chatChannelSid, user } = application;
  const unreadCount = useChatChannelUnreadCount(chatChannelSid || '');

  if (!chatChannelSid || !unreadCount) return null;

  return (
    <Link className="ml-3 mb-3" to={`?=applicationId=${application.id}`}>
      <Avatar
        size="lg"
        avatarUrl={user.avatarURL}
        avatarName={`${user.firstName} ${user.lastName}`}
      >
        <div className="badge">{unreadCount}</div>
      </Avatar>
    </Link>
  );
};

const UnreadCandidateMessages = ({
  applications,
}: {
  applications: KeyedApplications;
}) => {
  const applicationValues = Object.values(applications);

  return (
    <div className="flex">
      {applicationValues.map(el => (
        <ApplicantUnread key={el.id} application={el} />
      ))}
    </div>
  );
};

export default UnreadCandidateMessages;
