import React, { useContext } from 'react';
import { matchPath, useLocation } from 'react-router';
import UserContext from '../user/Context';
import Flow from './Flow';

const EXCLUDED_PATHS = ['/i/:collectionType(bench|list|group)/:inviteId'];

const Onboarding = () => {
  const {
    user,
    setShowOnboarding,
    update,
    membership,
    membershipAccess,
    updateMembership,
  } = useContext(UserContext);

  return user && membership ? (
    <Flow
      user={user}
      membership={membership}
      membershipAccess={membershipAccess}
      updateUser={update}
      updateMembership={updateMembership}
      setShowOnboarding={setShowOnboarding}
    />
  ) : null;
};

export const OnboardingOverlay = () => {
  const { user, showOnboarding } = useContext(UserContext);
  const location = useLocation();
  const match = matchPath(location.pathname, EXCLUDED_PATHS);

  if (match) {
    return null;
  }

  return user && showOnboarding ? <Onboarding /> : null;
};

export default React.memo(Onboarding);
