import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import WorkSearchProvider from '../components/work/search/WorkSearchProvider';
import useCurrentPlan from '../hooks/useCurrentPlan';
import BeatsWork from '../components/work/search/BeatsWork';
import { HBCU_PLAN_ID } from '../config';

const Work = () => {
  useAnalytics('Work');

  const plan = useCurrentPlan();

  // @TODO: BEATS TEMPORARY CODE
  const showBeatsWork = plan?.id === HBCU_PLAN_ID;
  const pageTitle = showBeatsWork ? 'Program' : 'Work';

  return (
    <Page
      authGuard
      pullToRefresh
      pageTitle={pageTitle}
      layout="none"
      className="page--no-padding"
    >
      {showBeatsWork ? <BeatsWork /> : <WorkSearchProvider />}
    </Page>
  );
};

export default React.memo(Work);
