import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router';
import { sortBy } from 'lodash';
import { LoadingBalls } from '../core/Loading';
import Link from '../core/router/Link';
import PortfolioCard from './PortfolioCard';
import reorder from '../../utilities/Array';
import portfolioApi from './Api';
import Alert from '../alert/Alert';
import { HashLink } from '../core/router';
import { addResponseError, addSuccess } from '../../services/Messaging';
import usePortfolioItems from '../../hooks/usePortfolioItems';

const SortableProject = ({
  project,
  index,
  handleDelete,
  hash,
}: {
  project: PortfolioEntity;
  index: number;
  handleDelete: Function;
  hash: string;
}) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setShowAlert(true);
  };

  return (
    <>
      <Draggable draggableId={project.id} index={index}>
        {provided => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Link
              to={`${hash}/edit/${project.id}`}
              key={project.id}
              className="block my-4 relative "
            >
              <PortfolioCard item={project} />
              <button
                type="button"
                className="rounded-full absolute top-0 right-0 p-3 mt-2 mr-2 bg-black text-white text-sm leading-none z-30 focus:outline-none"
                onClick={handleClick}
              >
                <i className="i-trash" />
              </button>
            </Link>
          </div>
        )}
      </Draggable>
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
        }}
        header="Are you sure you want to delete this project?"
        message="This action cannot be undone."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Delete',
            cssClass: 'alert-primary-btn',
            handler: () => {
              handleDelete(project);
            },
          },
        ]}
      />
    </>
  );
};

const List = () => {
  const { location } = useHistory();

  // Hacky way to detect if we are creating a user project vs a membership project
  const isUserProject = location.hash.includes('#/edit-profile/');

  const { portfolioItems, refresh } = usePortfolioItems(isUserProject);

  const sortedPortfolioItems = sortBy(portfolioItems, 'position');

  const [projects, setProjects] = useState<PortfolioEntity[]>(
    sortedPortfolioItems
  );
  const [loading, setLoading] = useState(false);
  const { hash } = useLocation();

  const updatePortfolioOrder = useCallback(
    async (updatingProjects: PortfolioEntity[]) => {
      const previousProjects = [...projects];

      setProjects(updatingProjects);
      setLoading(true);

      const results: (PortfolioEntity | any)[] = [];

      for (let i = 0; i <= updatingProjects.length - 1; i += 1) {
        results.push(
          portfolioApi.updateProject(updatingProjects[i].id, {
            position: i,
          })
        );

        // Need a slight delay to not clog the server
        // eslint-disable-next-line
        await new Promise(resolve => setTimeout(resolve, 50));
      }

      await Promise.all(results)
        .then(() => {
          addSuccess('Portfolio order updated!');
          refresh();
        })
        .catch(error => {
          setProjects(previousProjects);
          addResponseError(error);
          return error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [projects, refresh]
  );

  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    // Did the user drop outside?
    if (!destination) {
      return;
    }

    // The user dropped the item back where it came from
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Create the new reordered array
    const newOrder = reorder(projects, source.index, destination.index);

    updatePortfolioOrder(newOrder as PortfolioEntity[]);
  };

  const deleteProject = (project: PortfolioEntity) => {
    if (loading) return;
    setLoading(true);

    portfolioApi
      .deleteProject(project.id)
      .then(() => {
        const index = projects.findIndex(item => item.id === project.id);

        if (index >= 0) {
          const newArray = Array.from(projects);
          newArray.splice(index, 1);
          updatePortfolioOrder(newArray);
        }

        addSuccess('Project Deleted');
      })
      .catch((error: any) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {projects.length > 0 && (
        <>
          <p className="text-caption">
            Drag & Drop to reorder your projects - click to Edit
          </p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="items"
              renderClone={(provided, snapshot, rubric) => (
                <div>
                  <div
                    // eslint-disable-next-line
                    {...provided.draggableProps}
                    // eslint-disable-next-line
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <PortfolioCard
                      // @ts-ignore
                      item={projects[rubric.source.index]}
                    />
                  </div>
                </div>
              )}
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  {projects.map((item: PortfolioEntity, index: number) => (
                    <React.Fragment key={item.id}>
                      <SortableProject
                        project={item}
                        index={index}
                        handleDelete={deleteProject}
                        hash={hash}
                      />
                    </React.Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}

      <HashLink
        to={`${hash}/create`}
        className="btn btn--outline w-full mt-4 mb-8"
      >
        <i className="i-add mr-2" /> <span>Add project</span>
      </HashLink>

      {/* <Link to={`${hash}/create`} className="btn btn--outline w-full mt-4 mb-8">
        <i className="i-add mr-2" /> <span>Add Project</span>
      </Link> */}
    </>
  );
};

export default List;
