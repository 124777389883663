import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { SocialLinks } from '../formElements';
import { SubmitButton } from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';

interface Props {
  membership: MembershipEntity;
  update: (data: any) => Promise<MembershipEntity>;
}

const LinksForm = ({ membership, update }: Props) => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      socialLinks: {
        WebsiteURL: membership.socialLinks.WebsiteURL || '',
        InstagramURL: membership.socialLinks.InstagramURL || '',
        TwitterURL: membership.socialLinks.TwitterURL || '',
        FacebookURL: membership.socialLinks.FacebookURL || '',
        LinkedInURL: membership.socialLinks.LinkedInURL || '',
        BehanceURL: membership.socialLinks.BehanceURL || '',
        VimeoURL: membership.socialLinks.VimeoURL || '',
        YouTubeURL: membership.socialLinks.YouTubeURL || '',
      },
    },
  });
  const { handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = values;

    update(data)
      .then((response: MembershipEntity) => {
        addSuccess('Membership updated!');
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {membership && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SocialLinks errors={errors} register={register} />
          <FormActions className="mt-auto justify-center">
            <div className="md:ml-auto">
              <SubmitButton text="Save Changes" />
            </div>
          </FormActions>
        </form>
      )}
    </>
  );
};

export default LinksForm;
