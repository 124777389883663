import React, { useCallback, useMemo, useState } from 'react';
import { CollectionUser } from '@communo-corp/shared-types';
import { useIonViewWillLeave } from '@ionic/react';
import { capitalize } from 'lodash';
import { thousands } from '../../../utilities/Number';
import { LoadingBalls } from '../../core/Loading';
import { Error404 } from '../../error';
import useCollection from '../hooks/useCollection';
import useCollectionUsers from '../hooks/useCollectionUsers';
import ViewCollectionActions from './ViewCollectionActions';
import ScrollPaginate from '../../core/ScrollPaginate';
import BenchMembersIcon from '../../icons/BenchMembersIcon';
import { Link } from '../../core/router';
import CollectionUserCard from '../../card/CollectionUserCard';
import useCurrentMembership from '../../../hooks/useCurrentMembership';

type ViewCollectionUserProps = {
  user: CollectionUser;
  onUserUpdate: (userId: string, collectionIdsUserIsIn: string[]) => void;
};
const ViewCollectionUser = React.memo(
  ({ user, onUserUpdate }: ViewCollectionUserProps) => {
    return (
      <CollectionUserCard
        user={user}
        onUserCollectionUpdate={collectionIds => {
          onUserUpdate(user.id, collectionIds);
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.user.id !== nextProps.user.id;
  }
);

type Props = {
  collectionId: string;
  modal?: string;
};
const ViewCollection = ({ collectionId, modal }: Props) => {
  const { checkAccess } = useCurrentMembership();
  const [usersRemoved, setUsersRemoved] = useState<string[]>([]);

  const {
    collection,
    isLoading: loadingCollection,
    refetch: refetchCollection,
  } = useCollection(collectionId);

  const {
    users,
    isLoading: loadingUsers,
    fetchMoreUsers,
    clearUsers,
  } = useCollectionUsers(collectionId, 48);

  const onUserUpdate = useCallback(
    (userId: string, collectionIdsUserIsIn: string[]) => {
      setUsersRemoved(prev => {
        if (collectionIdsUserIsIn.includes(collectionId)) {
          // User is in current collection, remove them from usersRemoved array
          return prev.filter(id => id !== userId);
        }
        return [...prev, userId];
      });
      refetchCollection();
    },
    [collectionId, refetchCollection]
  );

  const filteredUsers = useMemo(() => {
    return users.reduce((carry, group) => {
      group.data.forEach(user => {
        if (!usersRemoved.includes(user.id)) {
          carry.push(user);
        }
      });

      return carry;
    }, [] as any[]);
  }, [users, usersRemoved]);

  useIonViewWillLeave(() => {
    clearUsers();
  }, [clearUsers]);

  const addToCollectionPermission =
    collection?.type === 'bench' ? 'canAddToBench' : 'canAddToList';
  const canAddToCollection = checkAccess(addToCollectionPermission);

  if (loadingCollection) {
    return <LoadingBalls isActive fullscreen />;
  }

  if (!collection) {
    return (
      <Error404 title="List 404" message="We couldn't find that list..." />
    );
  }

  const displayType = capitalize(collection.type);

  return (
    <>
      <div className="mt-8 px-4">
        <div className="flex flex-row justify-between items-start">
          <div className="flex-1">
            <h2>{collection.name}</h2>
            <p>{collection.description}</p>

            <div className="mt-2 flex flex-wrap items-center justify-between">
              <p>
                <strong>{thousands(collection.userCount)} members</strong>
              </p>

              <ViewCollectionActions collection={collection} modal={modal} />
            </div>
          </div>
        </div>
        {filteredUsers.length > 0 ? (
          <ScrollPaginate
            key={collection.id}
            loading={loadingUsers}
            onLoadMore={fetchMoreUsers}
            hasMoreResults={collection.userCount > filteredUsers.length}
            className="mt-8 grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
          >
            {filteredUsers.map(user => (
              <ViewCollectionUser
                key={user.id}
                user={user}
                onUserUpdate={onUserUpdate}
              />
            ))}
          </ScrollPaginate>
        ) : null}
        {filteredUsers.length === 0 && !loadingUsers ? (
          <div className="mt-8 text-center text-lg">
            <BenchMembersIcon className="inline-block h-20 w-20 text-black" />
            <h3 className="mt-4 font-black text-3xl">Empty {displayType}</h3>
            <p className="mt-2">
              Looks like you have an empty {collection.type}.
            </p>
            {canAddToCollection ? (
              <p className="mt-2">
                Head to the{' '}
                <Link to="/members" className="text-red-900 font-bold">
                  members page
                </Link>{' '}
                to find people to add to your {collection.type}.
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ViewCollection;
