import React from 'react';
import cn from 'classnames';
import Avatar from '../avatar/Avatar';

interface Props {
  className?: string;
  name: string;
  title: string;
  avatarUrl?: string | null;
  isCommunoUser?: boolean;
  clickable?: boolean;
  onCLick?(e: React.MouseEvent): void;
  isHidden?: boolean;
}

const ReferenceAvatar = ({
  className,
  name,
  title,
  avatarUrl,
  isCommunoUser = true,
  clickable = false,
  onCLick,
  isHidden,
}: Props) => {
  const Wrapper = clickable ? 'button' : 'div';

  return (
    <Wrapper
      type={clickable ? 'button' : undefined}
      onClick={(e: React.MouseEvent) => {
        if (onCLick) {
          onCLick(e);
        }
      }}
      className={cn('flex items-center text-left', className, {
        'cursor-pointer': clickable,
      })}
    >
      <div>
        <Avatar
          avatarName={name}
          avatarUrl={avatarUrl}
          logoUrl={
            isCommunoUser
              ? null
              : 'https://res.cloudinary.com/communo/image/upload/v1616535657/assets/linkedin-square-reversed-sm-x4.png'
          }
          size="lg"
        />
      </div>
      <div className="pl-4">
        <div className="text-large font-semibold flex items-center">
          {name}&nbsp;
          {isHidden ? (
            <span className="text-grey-700 text-xs uppercase">- hidden</span>
          ) : null}
        </div>
        <div className="text-sm mt-1">{title}</div>
      </div>
    </Wrapper>
  );
};
export default ReferenceAvatar;
