const linkify = (inputText: string) => {
  let replacedText;

  // URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" class="linkified" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.\S+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" class="linkified" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  const replacePattern3 = /(([a-zA-Z\d\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a class="linkified" href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export default linkify;
