import React from 'react';
import {
  TwilioConversationChannelAttributes,
  TwilioConversationAttributes,
  TwilioConversationWorkAttributes,
} from '../../../@types/chat.d';
import Avatar, { AvatarProps as AvatarPropsType } from '../../avatar/Avatar';
import useChatUser from '../hooks/useChatUser';
import { Link } from '../../core/router';
import { PublicChannelName } from './ChannelName';
import useChatOtherMember from '../hooks/useChatOtherMember';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useChatChannelMembers from '../hooks/useChatChannelMembers';
import channelMemberRole from '../utilities/channelMemberRole';
import useWork from '../../../hooks/useWork';
import { LoadingBalls } from '../../core/Loading';

type FormatOptions = 'compact' | 'standard' | 'avatar';
type AvatarUrl = AvatarPropsType['avatarUrl'];
type AvatarSizes = AvatarPropsType['size'];

type ProfileProps = {
  channelSid: string;
  friendlyName: string;
  avatarUrl?: AvatarUrl;
  avatarSize?: AvatarSizes;
  format?: FormatOptions;
  linkTo?: string;
  isInline?: boolean;
};

type AvatarProps = Omit<ProfileProps, 'channelSid'> & {
  online?: boolean;
};

type CompactProps = Omit<ProfileProps, 'channelSid'> & {
  online?: boolean;
  profileName: string | JSX.Element;
};

type StandardProps = Omit<ProfileProps, 'channelSid'> & {
  text?: string;
  subtext?: string;
  profileName: string | JSX.Element;
  standardChildren?: string | JSX.Element;
};

type UserProfilePresenterProps = Omit<
  ProfileProps,
  'friendlyName' | 'channelSid'
> & {
  channelSid?: string;
  identity: string;
  standardChildren?: string | JSX.Element;
};

type OtherMemberProfileProps = Omit<ProfileProps, 'friendlyName'> & {
  identity?: string;
  standardChildren?: string | JSX.Element;
};

type UserProfileProps = Omit<ProfileProps, 'friendlyName' | 'channelSid'> & {
  channelSid?: string;
  identity?: string;
  standardChildren?: string | JSX.Element;
};

type WorkProfileProps = ProfileProps & {
  work: TwilioConversationWorkAttributes;
};

type GroupProfileProps = ProfileProps;

type ChatChannelProfileProps = ProfileProps & {
  channel: TwilioConversationChannelAttributes;
};

type ChannelProfileProps = Omit<ProfileProps, 'profileName'> & {
  attributes: TwilioConversationAttributes;
};

export const ChatProfileAvatar = ({
  friendlyName,
  avatarUrl,
  avatarSize,
  online = false,
}: AvatarProps) => (
  <Avatar
    avatarName={friendlyName}
    avatarUrl={avatarUrl}
    size={avatarSize}
    isOnline={online}
  />
);

export const ChatProfileCompact = ({
  linkTo,
  friendlyName,
  profileName,
  avatarUrl,
  avatarSize,
  online = false,
}: CompactProps) =>
  linkTo ? (
    <Link to={linkTo} className="flex items-center text-left">
      <Avatar
        avatarName={friendlyName}
        avatarUrl={avatarUrl}
        size={avatarSize}
        isOnline={online}
      />
      <div className="flex-1 ml-2">
        <p className="font-semibold">{profileName}</p>
      </div>
    </Link>
  ) : (
    <div className="flex items-center text-left">
      <Avatar
        avatarName={friendlyName}
        avatarUrl={avatarUrl}
        size={avatarSize}
        isOnline={online}
      />
      <div className="flex-1 ml-2">
        <p className="font-semibold">{profileName}</p>
      </div>
    </div>
  );

export const ChatProfileStandard = ({
  friendlyName,
  profileName,
  avatarUrl,
  avatarSize = 'xl',
  text = '',
  subtext = '',
  standardChildren = '',
  linkTo,
}: StandardProps) => {
  const Wrapper = linkTo ? Link : 'div';

  return (
    <Wrapper
      to={linkTo ?? '#'}
      className="flex flex-col items-center text-center mb-4"
    >
      <Avatar
        size={avatarSize}
        avatarName={friendlyName}
        avatarUrl={avatarUrl}
      />
      <h6 className="mt-4 mb-1">{profileName}</h6>
      {text ? <p>{text}</p> : null}
      {subtext ? <p className="text-small">{subtext}</p> : null}
      {standardChildren ?? standardChildren}
    </Wrapper>
  );
};

const ChatUserProfilePresenter = ({
  channelSid,
  identity,
  avatarSize,
  format,
  linkTo = '',
  standardChildren,
}: UserProfilePresenterProps) => {
  const { user } = useChatUser(identity);

  if (!user) return null;

  const link = channelSid
    ? `/chat/conversation/${channelSid}/member/${identity}`
    : linkTo;

  const {
    friendlyName,
    isOnline: online,
    attributes: { avatarURL, title, location, isOblivious },
  } = user;

  let text = title;
  let subtext = location;

  if (isOblivious) {
    text = 'This user is not yet active in chat.';
    subtext =
      'This message will be emailed to them, and it may take them longer than normal to respond.';
  }

  if (format === 'avatar')
    return (
      <ChatProfileAvatar
        friendlyName={friendlyName}
        avatarUrl={avatarURL}
        avatarSize={avatarSize}
        online={online}
      />
    );

  if (format === 'compact')
    return (
      <ChatProfileCompact
        linkTo={link}
        friendlyName={friendlyName}
        profileName={friendlyName}
        avatarUrl={avatarURL}
        avatarSize={avatarSize}
        online={online}
      />
    );

  return (
    <ChatProfileStandard
      friendlyName={friendlyName}
      profileName={friendlyName}
      avatarUrl={avatarURL}
      avatarSize={avatarSize}
      text={text}
      subtext={subtext}
      standardChildren={standardChildren}
      linkTo={`/members/user/${identity}`}
    />
  );
};

const ChatOtherMemberProfile = ({
  channelSid,
  identity,
  avatarSize,
  format,
  linkTo,
  standardChildren,
}: OtherMemberProfileProps) => {
  const { member } = useChatOtherMember(channelSid, identity);

  if (!member) return null;

  return (
    <ChatUserProfilePresenter
      channelSid={channelSid}
      identity={member.identity}
      avatarSize={avatarSize}
      format={format}
      linkTo={linkTo}
      standardChildren={standardChildren}
    />
  );
};

export const ChatUserProfile = ({
  channelSid,
  identity,
  avatarSize,
  format,
  linkTo,
  standardChildren,
}: UserProfileProps) => {
  if (channelSid)
    return (
      <ChatOtherMemberProfile
        channelSid={channelSid}
        identity={identity}
        avatarSize={avatarSize}
        format={format}
        linkTo={linkTo}
        standardChildren={standardChildren}
      />
    );

  if (!identity) return null;

  return (
    <ChatUserProfilePresenter
      identity={identity}
      avatarSize={avatarSize}
      format={format}
      linkTo={linkTo}
      standardChildren={standardChildren}
    />
  );
};

export const ChatWorkProfile = ({
  channelSid,
  work,
  friendlyName,
  avatarUrl,
  avatarSize,
  format,
  isInline = false,
}: WorkProfileProps) => {
  const { channelMembers } = useChatChannelMembers(channelSid);
  const { id: userId } = useCurrentUser();

  const { id: workId, applicantUserId, applicationId } = work;
  const { loading: workLoading } = useWork(workId);

  const currentMember = channelMembers[userId || 0];
  const { isChannelAdmin } = channelMemberRole(currentMember?.roleSid);

  const linkTo = `/chat/conversation/${channelSid}/settings`;
  const ApplicationLink = () => {
    if (isInline) return null;

    if (isChannelAdmin) {
      return (
        <Link
          to={`/work/${workId}/candidates/list?=applicationId=${applicationId}`}
          className="btn btn--primary btn--sm  btn--rev"
        >
          View application
        </Link>
      );
    }

    return (
      <Link
        to={`/work/${workId}`}
        className="btn btn--primary btn--sm btn--rev"
      >
        View my application
      </Link>
    );
  };

  if (workLoading) {
    return <LoadingBalls isActive={workLoading} />;
  }

  if (isChannelAdmin)
    return (
      <ChatUserProfile
        identity={applicantUserId}
        format={format}
        avatarSize={avatarSize}
        linkTo={linkTo}
        standardChildren={<ApplicationLink />}
      />
    );

  if (format === 'avatar')
    return (
      <ChatProfileAvatar
        friendlyName={friendlyName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );

  if (format === 'compact')
    return (
      <ChatProfileCompact
        linkTo={linkTo}
        friendlyName={friendlyName}
        profileName={friendlyName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );

  return (
    <ChatProfileStandard
      friendlyName={friendlyName}
      profileName={friendlyName}
      avatarUrl={avatarUrl}
      avatarSize={avatarSize}
      standardChildren={<ApplicationLink />}
    />
  );
};

export const ChatGroupProfile = ({
  channelSid,
  friendlyName,
  avatarUrl,
  avatarSize,
  format,
}: GroupProfileProps) => {
  const linkTo = `/chat/conversation/${channelSid}/settings`;

  if (format === 'avatar')
    return (
      <ChatProfileAvatar
        friendlyName={friendlyName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );

  if (format === 'compact')
    return (
      <ChatProfileCompact
        linkTo={linkTo}
        friendlyName={friendlyName}
        profileName={friendlyName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );

  return (
    <ChatProfileStandard
      friendlyName={friendlyName}
      profileName={friendlyName}
      avatarUrl={avatarUrl}
      avatarSize={avatarSize}
      text="This is the start of your conversation."
    />
  );
};

export const ChatChannelProfile = ({
  channelSid,
  channel,
  avatarUrl,
  avatarSize,
  friendlyName,
  format,
}: ChatChannelProfileProps) => {
  const { description, visibility } = channel;
  const linkTo = `/chat/conversation/${channelSid}/settings`;

  const profileName = (
    <PublicChannelName channel={channel} friendlyName={friendlyName} />
  );

  if (format === 'avatar')
    return (
      <ChatProfileAvatar
        friendlyName={friendlyName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );

  if (format === 'compact') {
    if (visibility === 'private')
      return (
        <ChatUserProfile
          channelSid={channelSid}
          avatarSize={avatarSize}
          format={format}
        />
      );

    return (
      <ChatProfileCompact
        linkTo={linkTo}
        friendlyName={friendlyName}
        profileName={profileName}
        avatarUrl={avatarUrl}
        avatarSize={avatarSize}
      />
    );
  }

  return (
    <ChatProfileStandard
      friendlyName={friendlyName}
      profileName={profileName}
      avatarUrl={avatarUrl}
      avatarSize={avatarSize}
      text={description}
    />
  );
};

/**
 * ChannelProfile
 *
 * Automatically determines which type of profile and name to display
 */
const ChannelProfile = ({
  channelSid,
  friendlyName,
  attributes,
  avatarSize,
  format = 'standard',
}: ChannelProfileProps) => {
  const { type, avatarURL, favor, work } = attributes;

  // channel profile picture
  if (type === 'generosity') {
    if (!favor) return null;
    return (
      <ChatChannelProfile
        channelSid={channelSid}
        channel={favor}
        avatarUrl={avatarURL || ''}
        avatarSize={avatarSize}
        friendlyName={friendlyName}
        format={format}
      />
    );
  }

  if (type === 'work' && work) {
    return (
      <ChatWorkProfile
        channelSid={channelSid}
        avatarUrl={avatarURL || ''}
        avatarSize={avatarSize}
        friendlyName={friendlyName}
        format={format}
        work={work}
      />
    );
  }

  // group profile picture
  if (friendlyName)
    return (
      <ChatGroupProfile
        channelSid={channelSid}
        friendlyName={friendlyName}
        avatarUrl={avatarURL || ''}
        avatarSize={avatarSize}
        format={format}
      />
    );

  // individual user profile picture
  return (
    <ChatUserProfile
      channelSid={channelSid}
      avatarSize={avatarSize}
      format={format}
    />
  );
};

export default React.memo(ChannelProfile);
