import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';
import { BackgroundTask } from '@capawesome/capacitor-background-task';
import { Deploy } from 'cordova-plugin-ionic';
import React from 'react';
import { DO_DEPLOY_UPDATE } from '../config';

const DeployContext = React.createContext({} as DeployContextInterface);

class DeployProviderClass extends React.PureComponent<{}, DeployContextState> {
  private splashDiv: HTMLElement | null = null;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.splashDiv = document.getElementById('pseudoSplashscreen');

    if (Capacitor.isNative) {
      this.addListeners();
      this.checkForDeployUpdate();
    }
  }

  checkForDeployUpdate = async () => {
    const isUpdateAvailable = await this.checkForUpdate();

    if (isUpdateAvailable === true && DO_DEPLOY_UPDATE) {
      this.doDeployUpdate();
    } else {
      this.hideLoadingscreen();
      // SplashScreen.hide();
    }
  };

  showLoadingScreen = () => {
    // eslint-disable-next-line no-unused-expressions
    this.splashDiv?.classList.remove('pseudoSplash--hide');
    // eslint-disable-next-line no-unused-expressions
    this.splashDiv?.classList.add('splash-animating');
  };

  hideLoadingscreen = async () => {
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      this.splashDiv?.classList.add('pseudoSplash--hide');
      // eslint-disable-next-line no-unused-expressions
      this.splashDiv?.classList.remove('splash-animating');
    }, 3000);
  };

  addListeners = () => {
    // appStateChange fires when the tab becomes active, app is opened/brought to focus
    App.addListener('appStateChange', ({ isActive }: { isActive: boolean }) => {
      if (isActive) this.onWindowFocus();
      if (!isActive) this.onBecomeInactive();
    });
  };

  checkForUpdate = async () => {
    const { value } = await Preferences.get({
      key: 'lastCheckedForUpdate',
    });

    // Guard so we dont check too often
    if (value) {
      const now = new Date().getTime();
      const { timestamp }: { timestamp: number } = JSON.parse(value);

      if (now < timestamp) {
        return false;
      }
    }

    const update = await Deploy.checkForUpdate();

    Preferences.set({
      key: 'lastCheckedForUpdate',
      value: JSON.stringify({
        timestamp: new Date().getTime() + 60000,
      }),
    });

    if (update.available) {
      return true;
    }

    return false;
  };

  onBecomeInactive = async () => {
    // The app has become inactive. Check if a new deploy is available.
    const taskId = await BackgroundTask.beforeExit(async () => {
      const isUpdateAvailable = await this.checkForUpdate();

      await Preferences.set({
        key: 'isUpdateAvailable',
        value: isUpdateAvailable.toString(),
      });

      if (isUpdateAvailable) {
        await Preferences.set({
          key: 'launchUrl',
          value: window.location.pathname,
        });
      }

      // Must call in order to end our task otherwise
      // we risk our app being terminated, and possibly
      // being labeled as impacting battery life
      BackgroundTask.finish({
        taskId,
      });
    });
  };

  doDeployUpdate = async () => {
    this.showLoadingScreen();

    await Preferences.remove({ key: 'isUpdateAvailable' });

    await Deploy.sync({ updateMethod: 'auto' }, percentDone => {
      if (percentDone === 100) {
        // console.log('context: deploy finished downloading!');
      }
    });
  };

  onWindowFocus = async () => {
    const { value: isUpdateAvailable } = await Preferences.get({
      key: 'isUpdateAvailable',
    });

    if (isUpdateAvailable === 'true' && DO_DEPLOY_UPDATE) {
      this.doDeployUpdate();
    }
  };

  render() {
    const { children } = this.props;

    return (
      <DeployContext.Provider value={{}}>{children}</DeployContext.Provider>
    );
  }
}

export const DeployProvider = ({ children }: any) => (
  <DeployProviderClass>{children}</DeployProviderClass>
);

export const UserConsumer = React.memo(DeployContext.Consumer);

export default DeployContext;
