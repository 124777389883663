import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AGENCY_P2P_PLAN_SLUG, BUSINESS_P2P_PLAN_SLUG } from '../../../config';
import useFeatureGate from '../../../hooks/useFeatureGate';
import { FormRadio } from '../../core/form';
import useModal from '../../core/modal/hooks/useModal';
import useStep from '../../stepper/hooks/useStep';
import { UpgradeStepProps } from '../flows';

interface Props {
  goToPrev(): void;
  currentPlan: PlanEntity;
  availablePlans: PlanEntity[];
}

function isP2P(planSlug: string | null) {
  if (planSlug === null) {
    return false;
  }

  return [AGENCY_P2P_PLAN_SLUG, BUSINESS_P2P_PLAN_SLUG].includes(planSlug);
}

const View = ({ goToPrev, currentPlan, availablePlans }: Props) => {
  const { setDestinationPlan } = useFeatureGate();
  const { closeModal } = useModal();
  const {
    setCanAdvance,
    setOnNext,
    setOnPrev,
    setNextButtonLabel,
    goToNextStep,
    setCanGoBack,
    isFirstStep,
  } = useStep();
  const form = useForm({
    mode: 'onChange',
  });
  const { errors, register, handleSubmit, formState } = form;
  const { isValid } = formState;

  const currentNumSeats = currentPlan.numSeats;
  const currentPlanIsP2P = isP2P(currentPlan.slug);
  const values = availablePlans.reduce(
    (carry, plan) => {
      if (plan.numSeats !== null) {
        carry.push(plan.numSeats);
      }

      return carry;
    },
    [0]
  );
  const validOptions = values.filter((value, index, array) => {
    // null = unlimited so cant have option with more than that
    if (currentNumSeats === null) {
      return false;
    }

    // If their current plan is P2P then show them all the options minus enterprise.
    if (currentPlanIsP2P && value > 0 && value < 11) {
      return true;
    }

    // Unique and greater than values and more than 10 needs concierge
    return (
      array.indexOf(value) === index && value >= currentNumSeats && value < 11
    );
  });

  const onSubmit = useCallback(
    (formValues: any) => {
      const { neededSeats } = formValues;

      const desiredPlan = availablePlans.find(
        plan => plan.numSeats === parseInt(neededSeats, 10)
      );

      if (desiredPlan) {
        setDestinationPlan(desiredPlan);
        goToNextStep();
      }
    },
    [availablePlans, goToNextStep, setDestinationPlan]
  );

  const canAdvance = isValid && validOptions.length > 0;

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    setCanGoBack(true);
  }, [setCanGoBack]);

  useEffect(() => {
    if (isFirstStep) {
      setOnPrev(() => {
        goToPrev();
        return false;
      });
    }
  }, [goToPrev, isFirstStep, setOnPrev]);

  useEffect(() => {
    if (validOptions.length === 0) {
      setOnNext(() => {
        closeModal();
        return false;
      });
      setNextButtonLabel('Close');
      return;
    }

    setOnNext(() => {
      if (isValid) {
        handleSubmit(onSubmit)();
      }

      return false;
    });
  }, [
    canAdvance,
    closeModal,
    handleSubmit,
    isValid,
    onSubmit,
    setNextButtonLabel,
    setOnNext,
    validOptions.length,
  ]);

  return (
    <div className="content">
      <h3>Select your plan</h3>
      <p>How many seats do you need for your team?</p>

      <div className="text-left my-8 w-64 mx-auto">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {validOptions.map(value => (
            <FormRadio
              key={value}
              label={value}
              value={value}
              name="neededSeats"
              errors={errors}
              ref={register({
                required: 'Please select number of seats needed',
              })}
            />
          ))}
        </form>
      </div>

      <p>
        If you need more than 10 team seats, please contact{' '}
        <a
          href="mailto:concierge@communo.com"
          className="text-link font-semibold"
        >
          concierge@communo.com
        </a>
        .
      </p>
    </div>
  );
};

const NumSeatsStep = ({
  goToPrev,
  currentPlan,
  availablePlans,
}: UpgradeStepProps) => {
  return (
    <>
      {currentPlan && availablePlans ? (
        <View
          goToPrev={goToPrev}
          currentPlan={currentPlan}
          availablePlans={availablePlans}
        />
      ) : (
        <div>
          <p>
            We are having trouble locating your current plan. Please contact
            <a
              className="text-link font-medium"
              href="mailto:concierge@communo.com"
            >
              concierge@communo.com
            </a>{' '}
            for assistance.
          </p>
        </div>
      )}
    </>
  );
};
export default NumSeatsStep;
