import React from 'react';
import Radio from './Radio';

export default React.forwardRef(
  (
    { name, label, value, disabled, children, onChange, className = '' }: any,
    ref
  ) => (
    <label className={`radio radio-stylized ${className}`}>
      <Radio
        name={name}
        value={value}
        ref={ref}
        className={disabled ? 'disabled' : ''}
        disabled={!!disabled}
        onChange={onChange}
      />
      <span>{children || label}</span>
    </label>
  )
);
