import axios from 'axios';
import { API_ROOT } from './config';

// create an auth instance
const authAxios = axios.create();

// set the default base url
authAxios.defaults.baseURL = API_ROOT;

// TODO implement limits on / rejection / error checking on
// duplicate requests

const handleError = (path: string, error: any) => {
  // dispatch an event for the app or any component to listen to errors
  document.dispatchEvent(
    new CustomEvent('appError', {
      bubbles: true,
      detail: { error },
    })
  );
  throw error;
};

export const setAuthorizationToken = (token: string | undefined) => {
  if (token) {
    authAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete authAxios.defaults.headers.common.Authorization;
  }
};

// Generic API callbacks
export const Api = {
  get: (url: string, data: {} = {}) => {
    return authAxios
      .get(`${url}`, { params: data })
      .then(response => response.data)
      .catch(error => handleError(url, error));
  },
  put: (url: string, data?: {}, config?: {}) => {
    return authAxios
      .put(`${url}`, data, config)
      .then(response => response.data)
      .catch(error => handleError(url, error));
  },
  patch: (url: string, data?: {}, config?: {}) => {
    return authAxios
      .patch(`${url}`, data, config)
      .then(response => response.data)
      .catch(error => handleError(url, error));
  },
  post: (url: string, data?: {}, config?: {}) => {
    return authAxios
      .post(`${url}`, data, config)
      .then(response => response.data)
      .catch(error => handleError(url, error));
  },
  delete: (url: string) => {
    return authAxios
      .delete(`${url}`)
      .then(response => response.data)
      .catch(error => handleError(url, error));
  },
};

export const BaseAPI = (type: string) => {
  return {
    list: (data?: {}) => Api.get(`/${type}`, data),
    create: <T = any>(data: T) => Api.post(`/${type}`, data),
    retrieve: (id: string) => Api.get(`/${type}/${id}`),
    update: (id: string, data: {}) => Api.patch(`/${type}/${id}`, data),
    delete: (id: string) => Api.delete(`/${type}/${id}`),
  };
};

export const feedApi = {
  stats: () => Api.get(`/feed`),
};

export const utilApi = {
  meta: (url: string) => Api.get(`/util/meta?url=${url}`),
};

export const ethnicitiesApi = {
  ethnicities: () => Api.get(`/ethnicity`),
};
