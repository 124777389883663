import React, { useCallback, useMemo, useState } from 'react';
import {
  CollectionInvite,
  CollectionSummary,
} from '@communo-corp/shared-types';
import AcceptedEmailInvite from './AcceptedEmailInvite';
import Button from '../../core/Button';
import Alert from '../../alert/Alert';
import Api from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import { LoadingRing } from '../../core/Loading';

type Props = {
  collection: CollectionSummary;
  invites: CollectionInvite[];
  refetchInvites: Function;
  isLoading: boolean;
};

const EmailInvitesList = ({
  collection,
  invites,
  refetchInvites,
  isLoading,
}: Props) => {
  const [deletingInviteId, setDeletingInviteId] = useState<string | null>(null);

  const acceptedInvites = useMemo(() => {
    return invites.filter(invite => invite.status === 'accepted');
  }, [invites]);

  const pendingInvites = useMemo(() => {
    return invites.filter(invite => invite.status === 'pending');
  }, [invites]);

  const handleDeleteInvite = useCallback(() => {
    if (!deletingInviteId) {
      return;
    }

    Api.deleteCollectionEmailInvite(collection.id, deletingInviteId)
      .then(() => {
        refetchInvites();
        addSuccess('Invite has been cancelled.');
      })
      .catch(err => addResponseError(err));
  }, [collection.id, deletingInviteId, refetchInvites]);

  const onCancelDelete = useCallback(() => {
    setDeletingInviteId(null);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center pt-8">
        <LoadingRing color="primary" size="xl" isActive />
      </div>
    );
  }

  return (
    <div className="mt-10 space-y-8 md:space-y-0 md:grid md:grid-cols-2">
      <div className="md:pr-4 md:border-r md:border-grey-300">
        <p className="font-semibold">Accepted</p>

        <div>
          {acceptedInvites.length > 0 ? (
            acceptedInvites.map(invite => (
              <AcceptedEmailInvite key={invite.id} user={invite.invitedUser} />
            ))
          ) : (
            <p>There are no accepted invites yet.</p>
          )}
        </div>
      </div>

      <div className="md:pl-4">
        <p className="font-semibold">Pending</p>

        <div>
          {pendingInvites.length > 0 ? (
            pendingInvites.map(invite => (
              <div
                key={invite.id}
                className="flex justify-between items-center mb-1"
              >
                <p className="truncate">{invite.email}</p>
                <Button
                  type="button"
                  fill="solid"
                  color="primary"
                  reverse
                  text="Cancel"
                  onClick={() => setDeletingInviteId(invite.id)}
                />
              </div>
            ))
          ) : (
            <p>There are no pending invites yet.</p>
          )}
        </div>
      </div>

      <Alert
        isOpen={!!deletingInviteId}
        header="Cancel invite"
        message="<p>Are you sure you want to cancel this invite?</p>"
        onDidDismiss={onCancelDelete}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn mr-auto',
            handler: onCancelDelete,
          },
          {
            text: 'Cancel invite',
            cssClass: 'alert-primary-btn',
            handler: handleDeleteInvite,
          },
        ]}
      />
    </div>
  );
};
export default EmailInvitesList;
