import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

export enum BudgetTypeEnum {
  Fixed = 'Fixed',
  Hourly = 'Hourly',
}

export default ({
  name = 'budgetType',
  useFixedAmount = true,
  defaultValue,
  errors,
  register,
  isRequired = true,
}: any) => {
  const ref = register({ required: 'Please select a work start time frame' });

  return (
    <FormGroup
      required={isRequired}
      name={name}
      label="Budget type and amount"
      errors={errors}
    >
      <FormRadio
        name={name}
        value={BudgetTypeEnum.Fixed}
        defaultValue={defaultValue}
        label={useFixedAmount ? 'Fixed amount' : 'Annual salary'}
        ref={ref}
      />
      <FormRadio
        name={name}
        value={BudgetTypeEnum.Hourly}
        label="Hourly rate"
        ref={ref}
      />
      {!isRequired && (
        <FormRadio
          name={name}
          value={null}
          label="Information not available"
          ref={ref}
        />
      )}
    </FormGroup>
  );
};
