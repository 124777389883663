import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FirstName, LastName } from '../../../user/formElements';
import Messaging from '../../../../services/Messaging';
import UserContext from '../../../user/Context';
import useStep from '../../../stepper/hooks/useStep';
import useIsFormValid from '../../../../hooks/useIsFormValid';
import SocialLinks, {
  SocialLink,
} from '../../../user/formElements/SocialLinks';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

// type Props = {
//   user: UserEntity;
//   updateUser: (data: Partial<UserEntity>) => Promise<UserEntity>;
//   isActive: boolean;
//   goTo: Function;
// };

const links: SocialLink[] = [
  { key: 'LinkedInURL', label: 'LinkedIn', optional: true },
];
interface Inputs {
  firstName: string;
  lastName: string;
  LinkedInURL: string;
}

export default function Profile() {
  const { user, update: updateUser } = useContext(UserContext);
  const {
    setCanAdvance,
    setOnNext,
    goToNextStep,
    isLoading,
    setLoading,
  } = useStep();
  const form = useForm<Inputs>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      LinkedInURL: user?.socialLinks.LinkedInURL || '',
    },
  });
  const { handleSubmit, register, errors, reset, formState } = form;
  const { isDirty } = formState;
  const isValidForm = useIsFormValid(form, ['firstName', 'lastName']);

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - Profile Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) {
        return;
      }

      setLoading(true);

      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        socialLinks: {
          LinkedInURL: values.LinkedInURL,
        },
      };
      updateUser(data)
        .then(() => {
          // reset the form so it will not be 'dirty'
          reset({
            firstName: values.firstName,
            lastName: values.lastName,
            LinkedInURL: values.LinkedInURL,
          });

          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, isLoading, reset, setLoading, updateUser]
  );

  useEffect(() => {
    setCanAdvance(isValidForm);
  }, [setCanAdvance, isValidForm]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FirstName required errors={errors} register={register} />
      <LastName required errors={errors} register={register} />
      <SocialLinks errors={errors} register={register} links={links} />
    </form>
  );
}
