import { useCallback, useRef } from 'react';
import { useIonViewDidEnter } from '@ionic/react';
import record, { load, reset, identify, alias } from '../analytics';
import useCurrentUser from './useCurrentUser';

const useAnalytics = (payload?: string | Function, data?: any) => {
  const previousPageView = useRef(null);
  const { originalUserId } = useCurrentUser();
  const shouldSkipAnalytics = !!originalUserId;

  const page = useCallback(
    (name: string, ...params: any) => {
      if (shouldSkipAnalytics) {
        return;
      }

      record.page(name, ...params);
    },
    [shouldSkipAnalytics]
  );

  const track = useCallback(
    (event: string, ...params: any) => {
      if (shouldSkipAnalytics) {
        return;
      }

      record.track(event, ...params);
    },
    [shouldSkipAnalytics]
  );

  const pageIsReady = useCallback((properties: Record<string, any>) => {
    if (shouldSkipAnalytics) {
      return;
    }

    const pageViewProperties = { ...properties };
    const { pageName, pageType, pageId } = pageViewProperties;

    let pageIdentifier = pageName;
    if (pageType && pageId) {
      pageIdentifier = `${pageType}-${pageId}`;
    }

    if (previousPageView.current === pageIdentifier) {
      // Duplicate page view
      return;
    }

    delete pageViewProperties.pageName;
    previousPageView.current = pageIdentifier;
    record.page(pageName, pageViewProperties);
    // eslint-disable-next-line
  }, []);

  useIonViewDidEnter(() => {
    if (shouldSkipAnalytics) {
      return;
    }

    if (payload && typeof payload === 'string') {
      record.page(payload, data);
    }
  }, []);

  return { load, reset, identify, alias, track, page, pageIsReady };
};

export default useAnalytics;
