import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Button, { SubmitButton } from '../../core/Button';
import { FormActions, FormInput, FormTextarea } from '../../core/form';
import { Role } from '../../user/formElements';
import referenceApi from '../Api';

interface Props {
  onCancel(): void;
  onDone(): void;
}

interface Inputs {
  roles: { value: string; label: string };
  contactEmail: string;
  message: string;
}

const MESSAGE_MAX_LENGTH = 500;

const RequestReferenceForm = ({ onCancel, onDone }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<Inputs>({
    mode: 'onSubmit',
  });
  const { handleSubmit, errors, formState, register } = form;
  const { isSubmitted } = formState;

  const onSubmit = async (values: Inputs) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const data = {
      ...values,
      roleId: values.roles.value,
    };

    try {
      const resp = await referenceApi.requestReference(data);
      addSuccess(resp.message);
    } catch (error) {
      addResponseError(error);
    } finally {
      setIsLoading(false);
      onDone();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormInput
        name="contactEmail"
        type="email"
        label="Contact’s email"
        labelVisible
        placeholder="name@email.com"
        errors={isSubmitted ? errors : {}}
        ref={register({
          required: 'Email is required',
          validate: (value: any) => validator.isEmail(value) || 'Invalid email',
        })}
        required
      />
      <Role
        labelOverride="Your role when you worked with this contact"
        form={form}
        isMulti={false}
        isRequired
      />
      <FormTextarea
        label="Message"
        labelSubtext="(max 500 characters)"
        name="message"
        placeholder="Let them know who you are and what you are applying for."
        maxLength={MESSAGE_MAX_LENGTH}
        errors={errors}
        ref={register({
          required: 'Please supply a message',
          maxLength: {
            value: MESSAGE_MAX_LENGTH,
            message: `Max ${MESSAGE_MAX_LENGTH} characters`,
          },
        })}
        richText={false}
        rows={10}
        characterCount={MESSAGE_MAX_LENGTH}
        form={form}
      />

      <FormActions>
        <div className="flex justify-end w-full">
          <Button
            text="Cancel"
            fill="outline"
            className="w-40 mr-4"
            onClick={() => {
              onCancel();
            }}
          />
          <SubmitButton text="Send" className="w-40" loading={isLoading} />
        </div>
      </FormActions>
    </form>
  );
};
export default RequestReferenceForm;
