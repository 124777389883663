import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import useAnalytics from '../hooks/useAnalytics';
import NewOneToManyConversationSettings from '../components/chat/views/NewOneToManyConversationSettings';
import View, {
  ViewHeader,
  ViewHeaderTitle,
  ViewContent,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import NewOneToManyChatContext from '../components/chat/context/ChatNewOneToManyContext';

export default () => {
  useAnalytics('New One-to-Many Settings');

  const { members } = useContext(NewOneToManyChatContext);

  if (!members.length) {
    return <Redirect to="/chat" />;
  }

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New One To Many Settings</h6>
          <NewOneToManyConversationSettings />
        </ViewContent>
      </>
    );

  return (
    <View
      authGuard
      permissions="canViewMembershipList"
      pageTitle="New One to Many Message Settings"
    >
      <ViewHeader defaultBackHref="/chat">
        <ViewHeaderTitle>New One To Many Settings</ViewHeaderTitle>
      </ViewHeader>
      <ViewContent layout="chat">
        <NewOneToManyConversationSettings />
      </ViewContent>
    </View>
  );
};
