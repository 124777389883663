/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { isMobile } from '../../../../utilities/Device';
import useModal from '../hooks/useModal';

const ANIMATION_DURATION_MS = 300;

type Props = {
  onOpen?: Function;
  onClose?: Function;
  onWillClose?: Function;
  children: JSX.Element;
};

const ModalOverlay = ({
  onOpen = () => {},
  onWillClose = () => {},
  onClose = () => {},
  children,
}: Props) => {
  const { isVisible, closeModal, setPresented } = useModal();

  const handleOnEntered = useCallback(() => {
    setPresented(true);
    onOpen();
  }, [onOpen, setPresented]);

  const handleOnExit = useCallback(() => {
    onWillClose();
  }, [onWillClose]);

  const handleOnExited = useCallback(() => {
    setPresented(false);
    onClose();
  }, [onClose, setPresented]);

  const handleOverlayClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onKeyUp = useCallback(
    e => {
      if (e.which === 27) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keyup', onKeyUp);
    }

    return () => document.removeEventListener('keyup', onKeyUp);
  }, [isVisible, onKeyUp]);

  return (
    <CSSTransition
      in={isVisible}
      classNames="fade"
      timeout={ANIMATION_DURATION_MS}
      onEntered={handleOnEntered}
      onExit={handleOnExit}
      onExited={handleOnExited}
    >
      <div
        role="button"
        className="flex items-end md:items-center justify-center fixed inset-0 z-10 cursor-default md:py-8"
        style={{
          backgroundColor: 'rgba(0,0,0,0.9)',
        }}
        onClick={handleOverlayClick}
        onKeyUp={onKeyUp}
      >
        <CSSTransition
          in={isVisible}
          classNames={isMobile ? 'slide-up' : 'fade'}
          timeout={ANIMATION_DURATION_MS}
        >
          {children}
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};
export default ModalOverlay;
