import { useContext, useEffect, useState } from 'react';
import SearchContext from '../components/search/Context';

const useRoles = (): RoleEntity[] => {
  const searchContext = useContext(SearchContext);
  const { getRoles } = searchContext;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRoles().then(({ hits }: any) => {
      setRoles(hits);
    });
  }, [getRoles]);

  return roles;
};

export default useRoles;
