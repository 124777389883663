import React from 'react';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import StatTile from './StatTile';
import { currency } from '../../utilities/Number';
import useBrandConfig from '../../hooks/useBrandConfig';

declare type Props = {
  stats: StatsEntity;
};

const FeedStats = ({ stats }: Props) => {
  const { name, styles } = useBrandConfig();

  const formatNumber = (number: number) => {
    return numeral(number).format('0,0');
  };

  const averageValue = (total = 0, value = 0) => {
    return total / value;
  };

  const feedStatsClass = (statArray: string[], stat: string) => {
    return statArray.includes(stat) ? '' : 'hidden';
  };

  const { stats: feedStats } = styles.feed;

  return (
    <div className="md:grid md:grid-cols-2 md:gap-8 lg:grid-cols-1 lg:gap-4">
      <div className="w-full mb-2 md:mb-0 rounded-md">
        <Link to="/members">
          <StatTile
            title="People"
            icon="users"
            className=""
            stats={[
              {
                label: `Users in ${name}`,
                value: formatNumber(stats?.userCount || 0),
                className: feedStatsClass(feedStats, 'users'),
              },
              {
                label: 'Talent',
                value: formatNumber(stats?.freelancerCount || 0),
                className: feedStatsClass(feedStats, 'freelancers'),
              },
              {
                label: 'Agencies',
                value: formatNumber(stats?.agencyCount || 0),
                className: feedStatsClass(feedStats, 'agencies'),
              },
            ]}
          />
        </Link>
      </div>
      <div className="w-full mb-2 md:mb-0 rounded-md">
        <Link to="/work">
          <StatTile
            title="Work"
            icon="work"
            className=""
            stats={[
              {
                label: 'Open opportunities',
                value: formatNumber(stats?.workCount || 0),
                className: feedStatsClass(feedStats, 'open'),
              },
              {
                label: 'Total value',
                value: currency(stats?.workTotal || 0),
                className: feedStatsClass(feedStats, 'value'),
              },
              {
                label: 'Average work value',
                value: currency(
                  Math.round(averageValue(stats?.workTotal, stats?.workCount))
                ),
                className: feedStatsClass(feedStats, 'avg'),
              },
            ]}
          />
        </Link>
      </div>
      <div className="w-full mb-2 md:mb-0 rounded-md">
        <Link to="/events">
          <StatTile
            title="Events"
            icon="calendar"
            className={feedStatsClass(feedStats, 'events')}
            stats={[
              {
                label: 'Upcoming events',
                value: formatNumber(stats?.eventCount || 0),
              },
            ]}
          />
        </Link>
      </div>
      <div className="w-full mb-2 md:mb-0 rounded-md">
        <Link to="/perks">
          <StatTile
            title="Perks"
            icon="grid-view"
            className={feedStatsClass(feedStats, 'perks')}
            stats={[
              {
                label: 'Available discounts',
                value: currency(stats?.perkTotal || 0),
              },
            ]}
          />
        </Link>
      </div>
    </div>
  );
};
export default FeedStats;
