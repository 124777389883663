import React from 'react';
import ScreenContent from './ScreenContent';

interface Props {
  children: JSX.Element;
}

const SuccessScreenContent = ({ children }: Props) => {
  return (
    <ScreenContent>
      <p className="font-semibold">Your plan has been upgraded.</p>
      <p>
        To manage your subscription through our payment portal, visit the{' '}
        <span className="font-semibold">My Account</span> menu.
      </p>
      {children}
    </ScreenContent>
  );
};
export default SuccessScreenContent;
