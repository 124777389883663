import React, { useMemo } from 'react';
import { ListItemComponentProps } from '..';
import Avatar from '../../avatar/Avatar';
import OverlayBase from './OverlayBase';

interface Props extends ListItemComponentProps {}

const OverlayWithAvatar = ({ item }: ListItemComponentProps) => {
  // console.log('OverlayWithAvatar', item, entityType);

  const {
    actionUrl,
    heading,
    subHeading,
    tertiaryHeading,
    backgroundUrl,
    imageUrl,
  } = item;

  const avatar = useMemo(
    () => (
      <Avatar size="sm" avatarUrl={imageUrl || ''} avatarName={heading || ''} />
    ),
    [imageUrl, heading]
  );

  return (
    <OverlayBase
      avatar={avatar}
      link={actionUrl || ''}
      firstLine={heading || ''}
      secondLine={subHeading || ''}
      thirdLine={tertiaryHeading || ''}
      backgroundUrl={backgroundUrl || ''}
    />
  );
};
export default OverlayWithAvatar;
