/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import linkifyHtml from 'linkifyjs/html';
import { sanitize } from 'dompurify';

type Props = {
  html: string | null;
  linkify?: boolean;
  classVariant?: string;
};

const ALLOWED_TAGS = [
  'b',
  'i',
  'u',
  'em',
  'strong',
  'ul',
  'ol',
  'li',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'iframe',
  'div',
  'br',
];

const RichText = ({
  html,
  classVariant = 'richtext',
  linkify = true,
}: Props) => {
  const sanitized = useMemo(() => sanitize(html || '', { ALLOWED_TAGS }), [
    html,
  ]);
  const content = useMemo(
    () => (linkify && html ? linkifyHtml(sanitized) : sanitized),
    [sanitized, linkify, html]
  );

  return (
    <div
      className={classVariant}
      dangerouslySetInnerHTML={{ __html: content || '' }}
    />
  );
};

export default RichText;
