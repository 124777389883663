import { Api, BaseAPI } from '../../Api';

export const AuthAPI = {
  requestReference: async (data: {
    roleId: string;
    contactEmail: string;
    message: string;
    suppressEmail?: boolean;
    leaveFor?: string; // User ID
  }) => {
    const response = await Api.post('/reference/request', data);
    return response;
  },
  getReferenceRequest: async (token: string): Promise<ReferenceRequest> => {
    const response = await Api.get(`/reference/request?token=${token}`);
    return response;
  },
  getUsersReferences: async (userId: string, includeRatings: boolean) => {
    const response = await Api.get(
      `/reference?userId=${userId}&includeRatings=${includeRatings}`
    );
    return response;
  },
  toggleVisibility: async (referenceId: string, visible: boolean) => {
    const response = await Api.patch(`/reference/toggle/${referenceId}`, {
      visible,
    });
    return response;
  },
};

export default { ...BaseAPI('reference'), ...AuthAPI };
