import React, { useContext } from 'react';
import { isMobile } from '../../utilities/Device';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import ProfileAccreditation from './ProfileAccreditation';
import ProfileEducation from './ProfileEducation';
import ProfileReference from './ProfileReference';

import ProfileSocialLinks from './ProfileSocialLinks';
import ToggleMembershipButton from './ToggleMembershipButton';

type Props = {
  user: UserEntity;
  forceMobile?: boolean;
};

const FreelancerSidebarColumn = ({ user }: Props) => {
  const membership = user.primaryMembershipUser?.membership;
  const membershipId = membership?.id ?? null;

  const { id: currentUserId, isRoot } = useContext(UserContext);
  const canEditProfile = user.id === currentUserId;

  return (
    <>
      {canEditProfile && !isRoot && !isMobile ? (
        <div className="mb-4">
          <Link
            className="btn btn--full btn--primary btn--sm"
            to="#/edit-profile/basic"
          >
            Edit Profile
          </Link>
        </div>
      ) : (
        <ToggleMembershipButton
          membershipId={membershipId}
          isDeactivated={!!membership?.expiresAt}
        />
      )}
      <ProfileReference user={user} />
      <ProfileEducation user={user} />
      <ProfileAccreditation user={user} />
      <ProfileSocialLinks
        socialLinks={user.socialLinks}
        profileUserId={user.id}
      />
    </>
  );
};
export default FreelancerSidebarColumn;
