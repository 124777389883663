import React, { useContext } from 'react';
import { IonHeader } from '@ionic/react';
import cn from 'classnames';

import { Link } from './router';
import Menu from '../menu';
import UserContext from '../user/Context';
import Brand from './Brand';
import { HEADER_COLOR } from '../../config';
import useNavigate from '../../hooks/useNavigate';

// import headerBg from '../../assets/headerbg.png';

// purgecss bg-red-1100 bg-blue-1100

type Props = {
  showAuthLinks?: boolean;
};
const Header = ({ showAuthLinks = true }: Props) => {
  const navigate = useNavigate();
  const { isAuthenticated, hasMembership, isPublic } = useContext(UserContext);

  const bgClass = `bg-${HEADER_COLOR}`;

  return (
    <>
      <IonHeader className={cn('main-header text-white', bgClass)}>
        <div className="mx-auto w-9/10 h-16 flex items-center">
          {isAuthenticated && !isPublic ? (
            <>
              {hasMembership && (
                <button
                  className="opacity-100 pr-4 xl:pr-6"
                  type="button"
                  onClick={() => {
                    navigate('/feed', 'root', 'push');
                  }}
                >
                  <Brand variant="full" size="3xl" />
                </button>
              )}
              {!hasMembership && <Brand variant="full" size="3xl" />}
              <Menu name="primary" />
              <div className="ml-auto">
                <Menu name="secondary" />
              </div>
            </>
          ) : (
            <>
              <Link
                to={location => (isPublic ? location : '/')}
                className="opacity-100 pr-4 xl:pr-6"
              >
                <Brand variant="full" size="3xl" />
              </Link>
              {showAuthLinks && !isPublic ? (
                <div className="ml-auto">
                  <Menu name="secondary" />
                </div>
              ) : null}
            </>
          )}
        </div>
      </IonHeader>
    </>
  );
};

export default Header;
