import React from 'react';
import { Route, Switch } from 'react-router';
import Page from '../components/core/Page';
import { VideoProvider } from '../components/videoChat/Context';
import RoomView from '../components/videoChat/RoomView';
import View from '../components/videoChat/View';

const VideoChat = () => {
  return (
    <Page
      authGuard
      pageTitle="Video Chat"
      layout="videochat"
      className="page-bg--black"
      hideHeader
      noScroll
    >
      <VideoProvider>
        <Switch>
          <Route path="/video-chat/" component={View} exact />
          <Route path="/video-chat/:roomId" component={RoomView} />
        </Switch>
      </VideoProvider>
    </Page>
  );
};

export default React.memo(VideoChat);
