import React from 'react';
import { Link } from '../core/router';
import cloudinary from '../../utilities/Cloudinary';

interface Props {
  id: string;
  backgroundImage: string;
  logoUrl: string;
  name: string;
  lightTheme?: boolean;
  summary: string;
  offer: string;
  tagline: string;
}

const PartnerBundleCard = ({
  id,
  backgroundImage,
  logoUrl,
  name,
  lightTheme = true,
  summary,
  offer,
  tagline,
}: Props) => {
  return (
    <Link
      className={`bundle-card bundle-card--partner rounded-md bundle-card--${
        lightTheme ? 'light' : 'dark'
      }`}
      to={`/perks/bundles/${id}`}
    >
      <img
        src={cloudinary(backgroundImage, ['w_400', 'h_400'])}
        alt={name}
        className="absolute inset-0 w-full h-full object-cover rounded-md"
      />
      <div className="aspect-2/1 bg-transparent relative" />
      <div className="relative w-full z-30">
        {logoUrl && (
          <div className="avatar avatar--square avatar--xl absolute top-0 right-0 mr-4 2xl:mr-6 transform -translate-y-1/2 bg-transparent">
            <img src={cloudinary(logoUrl, ['w_140', 'h_140'])} alt="avatar" />
          </div>
        )}
        <div
          className={` p-4 2xl:p-6 ${lightTheme ? 'text-white' : 'text-black'}`}
        >
          <div className="text-xl leading-snug mb-0">{name}</div>
          <div className="text-large font-semibold mt-3">{tagline}</div>
          <p className="bundle-card__summary">{summary}</p>
          <div>{offer}</div>
        </div>
      </div>
    </Link>
  );
};

export default PartnerBundleCard;
