import React, { useEffect, useState } from 'react';

import { defaultStepper, membershipStepper, ScreenProps } from './flows';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import { StepType } from '../stepper/Step';
import Stepper from '../stepper/Stepper';
import { track } from '../../analytics';
import useFeatureGate from '../../hooks/useFeatureGate';

interface Props extends ScreenProps {}

const PaymentStepper = ({
  goToNext,
  goToPrev,
  flowName,
  contextualFlow,
  slug,
  coupon,
  availablePlans,
  stepperSteps,
  purchasableItem,
  eventTrackingLabel,
}: Props) => {
  const { destinationPlan } = useFeatureGate();
  const { plan: membershipPlan, isIndividualPlan } = useCurrentMembership();
  const [stepIndex, setStepIndex] = useState(0);
  let upgradeSteps = stepperSteps;

  if (upgradeSteps === null) {
    upgradeSteps = isIndividualPlan ? defaultStepper : membershipStepper;
  }

  const onSlideChange = ({ index }: any) => {
    setStepIndex(index);
  };

  useEffect(() => {
    const step = upgradeSteps ? upgradeSteps[stepIndex] : null;
    if (step) {
      track(eventTrackingLabel, {
        step: step.name,
        permission: flowName,
        coupon,
        slug,
        contextualModal: contextualFlow,
        destinationPlan: destinationPlan?.name,
        items: purchasableItem,
      });
    }
  }, [
    contextualFlow,
    coupon,
    destinationPlan,
    eventTrackingLabel,
    flowName,
    purchasableItem,
    slug,
    stepIndex,
    upgradeSteps,
  ]);

  const steps = upgradeSteps.map<StepType>(step => {
    const { StepComponent } = step;

    return {
      ...step,
      component: () => (
        <StepComponent
          goToPrev={goToPrev}
          currentPlan={membershipPlan}
          availablePlans={availablePlans}
          coupon={coupon}
          purchasableItem={purchasableItem}
        />
      ),
    };
  });

  return (
    <Stepper
      heading="Upgrade"
      steps={steps}
      onFinish={goToNext}
      onSlideChange={onSlideChange}
      hideDivider
    />
  );
};
export default PaymentStepper;
