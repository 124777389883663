import React, { useState } from 'react';
import { IonAlert, IonToggle } from '@ionic/react';
import ListActionsItemBase from '../../listActions/ListActionsItemBase';

type ToggleChannelStateProps = {
  channelState?: string;
  onToggle: () => void;
};

/**
 * Renders a list item containing a toggle for the channel's one way or two way state
 *
 * @param param0.channelState - the current channel's state (one way or two way)
 * @param param0.onToggle - the callback triggered when the user toggles the channel state
 */
export default function ToggleChannelState({
  channelState,
  onToggle,
}: ToggleChannelStateProps): JSX.Element {
  const [isDefaultChannelState, setIsDefaultChannelState] = useState<boolean>(
    channelState === 'two-way'
  );
  const [showToggleStatePrompt, setShowToggleStatePrompt] = useState<boolean>(
    false
  );

  function handleToggleChannelState() {
    setIsDefaultChannelState(val => !val);
    onToggle();
  }

  return (
    <>
      <ListActionsItemBase
        end={
          <IonToggle
            checked={isDefaultChannelState}
            color="primary"
            onIonChange={() => setShowToggleStatePrompt(true)}
          />
        }
      >
        {isDefaultChannelState ? 'Two Way' : 'One Way'}
      </ListActionsItemBase>
      <IonAlert
        isOpen={showToggleStatePrompt}
        onDidDismiss={() => {
          setShowToggleStatePrompt(false);
        }}
        header="Leave conversation?"
        message={`Are you sure you want to switch the channel to a ${
          isDefaultChannelState === true ? 'One-Way' : 'Two-Way'
        } channel?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Proceed',
            handler: handleToggleChannelState,
          },
        ]}
      />
    </>
  );
}
