import React from 'react';
import { ListItemComponentProps } from '..';
import cloudinary from '../../../utilities/Cloudinary';
import { Link } from '../../core/router';

interface Props extends ListItemComponentProps {}

const Default = ({ item }: ListItemComponentProps) => {
  const { actionUrl, heading, imageUrl } = item;

  return (
    <Link to={actionUrl || ''}>
      <div className="relative aspect-4/3 w-full">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          alt={heading || ''}
          src={cloudinary(imageUrl || '', ['w_507', 'h_380'])}
        />
      </div>
    </Link>
  );
};
export default Default;
