import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import cn from 'classnames';

const ModalBackButton = React.memo(() => {
  const { hash } = useLocation();
  const { goBack } = useHistory();

  const handleClick = useCallback(() => {
    goBack();
  }, [goBack]);

  const [initialHash] = useState<string>(hash);
  const canGoBack = useMemo(() => hash !== initialHash, [hash, initialHash]);

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn('h-full px-4 md:hidden', {
        hidden: !canGoBack,
      })}
    >
      <i className="i-arrow-left" />
    </button>
  );
});
export default ModalBackButton;
