import React from 'react';
import Avatar from '../avatar/Avatar';
import { Link } from '../core/router';

interface Props {
  user: UserEntity;
}

const ProfileAccreditation = ({ user }: Props) => {
  const badges = user.collectionSummary?.badges ?? [];

  if (badges.length <= 0) {
    return null;
  }

  return (
    <div className="mb-8">
      <p className="profile__section-title">ACCREDITATION </p>
      {badges.map(badge => {
        const {
          userId,
          fullName,
          membershipId,
          membershipName,
          badgeImageURL,
          badgeName,
        } = badge;

        // const entity = user ?? membership;
        const name = membershipName ?? fullName;
        // eslint-disable-next-line no-nested-ternary
        const url = membershipId
          ? `/members/membership/${membershipId}`
          : userId
          ? `/members/user/${userId}`
          : null;

        return (
          <Link
            to={url || '#'}
            key={badgeName}
            className="mb-4 flex items-center"
          >
            <div>
              <Avatar
                avatarName={badgeName}
                avatarUrl={badgeImageURL}
                size="lg"
              />
            </div>
            <div className="pl-4">
              <div className="text-large font-semibold">{name}</div>
              <div className="text-sm mt-1">{badgeName}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
export default ProfileAccreditation;
