import React from 'react';
import { components } from 'react-select';
import { LoadingRing } from '../core/Loading';

export const Input = ({
  onChange,
  onBlur,
  onFocus,
  value,
  innerProps,
  innerRef,
}: any) => {
  return (
    <input
      type="text"
      className="form-input border-none focus:outline-none flex-1"
      ref={innerRef}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    />
  );
};

export const DropdownIndicator = (props: any) => {
  const { onClick } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <button type="button" onClick={onClick}>
        <i className="i-caret-down" />
      </button>
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = ({ onClick, innerProps, innerRef }: any) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type="button" onClick={onClick} {...innerProps} ref={innerRef}>
      <div className="multi__clear">
        <i className="i-close" />
      </div>
    </button>
  );
};

export const Menu = (props: any) => {
  const { isLoading } = props;
  if (isLoading) {
    return (
      <div className="pt-8 pb-6 border border-grey-100 shadow text-center">
        <LoadingRing isActive size="sm" color="tertiary" />
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <components.Menu {...props} />;
};

export const Styles = {
  // This is needed because using the portal looses the styles
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#2E343A',
    padding: '0.5rem 0.6667rem 0.5rem 0.6667rem',
    backgroundColor: state.isSelected ? '#75C2D4' : '#fff',
    ':hover, :focus': {
      backgroundColor: '#75C2D4',
      cursor: 'pointer',
    },
  }),
};
