import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

const ApplicationMethod = ({ errors, register }: any) => {
  const ref = register({ required: 'Application method is required.' });
  return (
    <FormGroup
      required
      name="isExternal"
      label="Application method"
      errors={errors}
    >
      <FormRadio
        name="isExternal"
        value="0"
        label="1-Click"
        ref={ref}
        disabled
      />
      <FormRadio name="isExternal" value="1" label="External" ref={ref} />
    </FormGroup>
  );
};
export default ApplicationMethod;
