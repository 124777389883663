import React from 'react';
import Page from '../components/core/Page';
import BeatsFeed from '../components/feed/BeatsFeed';
import View from '../components/feed/View';
import { HBCU_PLAN_ID } from '../config';
import useAnalytics from '../hooks/useAnalytics';
import useCurrentPlan from '../hooks/useCurrentPlan';

const Feed: React.FC = () => {
  useAnalytics('The Feed');

  const plan = useCurrentPlan();

  return (
    <Page pullToRefresh authGuard pageTitle="Feed">
      {plan?.id === HBCU_PLAN_ID ? <BeatsFeed /> : <View />}
    </Page>
  );
};
export default React.memo(Feed);
