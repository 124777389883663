import React from 'react';
import BadgeVerified from '../icons/BadgeVerified';
import ProfileBadge from './ProfileBadge';

const VerifiedBadge = React.memo(() => {
  return (
    <ProfileBadge
      title="Verified"
      svg={<BadgeVerified className="w-5 mr-2 text-white" />}
    />
  );
});
export default VerifiedBadge;
