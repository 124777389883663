import React from 'react';
import Page from '../components/core/Page';
import MagicOp from '../components/user/views/MagicOp';

export default ({ match }: any) => {
  const { params } = match;
  const { action, code } = params;
  return (
    <Page layout="basic" pageTitle="Magic Settings" hideHeader>
      <MagicOp action={action} code={code} />
    </Page>
  );
};
