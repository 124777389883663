import { keyBy } from 'lodash';
import { IUser } from '../../../@types/chat.d';

type Props = {
  [identity: string]: IUser;
};

let Users: Props = {};

export const add = (users: IUser[]) => {
  Users = {
    ...Users,
    ...keyBy(users, 'identity'),
  };
};

export const remove = (users: IUser[]) =>
  users.forEach(user => delete Users[user.identity]);

export const update = (users: IUser[]) =>
  users.forEach(user => {
    Users[user.identity] = user;
  });

export const select = (identity: string) => Users[identity];
export const selectMany = (identities: string[]) =>
  identities.map(identity => Users[identity]);

const UserInstances = {
  add,
  remove,
  update,
  select,
  selectMany,
};

export default UserInstances;
