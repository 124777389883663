import React from 'react';
import useNavigate from '../../../hooks/useNavigate';

import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';
// import ScoreMetric from '../ScoreMetric';

export type Metric = {
  key: string;
  label: string;
  actionUrl?: string;
  subText?: string;
  status: (criteria: ScoreMetricsCriteria) => MetricStatus;
};

export const baseUserMetrics: Metric[] = [
  {
    key: 'userAvatar',
    label: 'Avatar',
    actionUrl: '#/edit-profile/basic',
    status: ({ avatarURL }) => (avatarURL ? 'complete' : null),
  },
  {
    key: 'userSummary',
    label: 'Intro',
    actionUrl: '#/edit-profile/about',
    status: ({ intro }) => (intro ? 'complete' : null), // key on the user object is summary, but on the criteria it's intro (SHRUG!)
    // isComplete: ({ summary }) => !!summary,
  },
  {
    key: 'userAbout',
    label: 'About',
    actionUrl: '#/edit-profile/about',
    status: ({ about }) => (about ? 'complete' : null),
  },
  {
    key: 'userLinks',
    label: 'Links',
    actionUrl: '#/edit-profile/links',
    status: ({ socialLinks }) => (socialLinks ? 'complete' : null),
  },
];

export const userMetrics: Metric[] = [
  {
    key: 'userTitle',
    label: 'Title',
    actionUrl: '#/edit-profile/basic',
    status: ({ title }) => (title ? 'complete' : null),
  },
  {
    key: 'userSkills',
    label: 'Skills',
    actionUrl: '#/edit-profile/roles',
    status: ({ skills }) => (skills ? 'complete' : null),
  },
  {
    key: 'userIndustries',
    label: 'Industries',
    actionUrl: '#/edit-profile/roles',
    status: ({ industries }) => (industries ? 'complete' : null),
  },
  {
    key: 'userRate',
    label: 'Rate',
    actionUrl: '#/edit-profile/rate',
    status: ({ rate }) => (rate ? 'complete' : null),
  },
  {
    key: 'userPortfolio',
    label: 'Portfolio',
    subText: '(Min. 3)',
    actionUrl: '#/edit-profile/portfolio',
    status: ({ portfolio, multiplePortfolio }) => {
      if (multiplePortfolio) {
        return 'complete';
      }

      if (portfolio) {
        return 'partial';
      }

      return null;
    },
  },
];

export const baseAgencyMetrics: Metric[] = [
  {
    key: 'agencyLogo',
    label: 'Logo',
    actionUrl: '#/edit-page/basic',
    status: ({ logoURL }) => (logoURL ? 'complete' : null),
  },
  {
    key: 'agencySummary',
    label: 'Intro',
    actionUrl: '#/edit-page/about',
    status: ({ intro }) => (intro ? 'complete' : null),
  },
  {
    key: 'agencyAbout',
    label: 'About',
    actionUrl: '#/edit-page/about',
    status: ({ about }) => (about ? 'complete' : null),
  },
  {
    key: 'agencyLinks',
    label: 'Links',
    actionUrl: '#/edit-page/links',
    status: ({ socialLinks }) => (socialLinks ? 'complete' : null),
  },
];

export const agencyMetrics: Metric[] = [
  {
    key: 'agencySkills',
    label: 'Skills',
    actionUrl: '#/edit-page/roles',
    status: ({ skills }) => (skills ? 'complete' : null),
  },
  {
    key: 'agencyIndustries',
    label: 'Industries',
    actionUrl: '#/edit-page/roles',
    status: ({ industries }) => (industries ? 'complete' : null),
  },
  {
    key: 'agencyPortfolio',
    label: 'Portfolio',
    subText: '(Min. 3)',
    actionUrl: '#/edit-page/portfolio',
    status: ({ portfolio, multiplePortfolio }) => {
      if (multiplePortfolio) {
        return 'complete';
      }

      if (portfolio) {
        return 'partial';
      }

      return null;
    },
  },
  {
    key: 'agencyCover',
    label: 'Cover Photo',
    actionUrl: '#/edit-page/basic',
    status: ({ coverPhotoURL }) => (coverPhotoURL ? 'complete' : null),
  },
];

type Props = {
  isUser: boolean;
  scoreMetrics: ScoreMetrics;
  isHidden: boolean;
};

export function getProfileScorePercentage(
  isUser: boolean,
  scoreMetrics: ScoreMetrics
): number {
  const metrics = isUser ? userMetrics : agencyMetrics;
  const score = metrics.reduce((carry, metric) => {
    if (metric.status(scoreMetrics.criteria) === 'complete') {
      return carry + 1;
    }
    return carry;
  }, 0);

  return Math.round((score / metrics.length) * 100);
}

const ProfileMetrics = ({ isUser, scoreMetrics, isHidden }: Props) => {
  const navigate = useNavigate();

  // TODO: Refactor this to use the userCompletionStore and membershipCompletionStore for consistency with "profile completion" in edit-profile? - might be out of sync with candidate score though...
  const baseMetrics = isUser ? baseUserMetrics : baseAgencyMetrics;
  const additionalMetrics = isUser ? userMetrics : agencyMetrics;
  const metrics = isHidden ? baseMetrics : additionalMetrics;

  return (
    <>
      {metrics.map(metric => {
        const { key, label, subText, actionUrl, status: getStatus } = metric;
        const status = getStatus(scoreMetrics.criteria);

        const handleClick = () => {
          if (actionUrl) {
            navigate(actionUrl, 'none', 'push');
          }
        };

        return (
          <Metric
            key={key}
            label={label}
            labelSubtext={subText}
            status={status}
            onClick={status !== 'complete' ? handleClick : undefined}
          />
        );
      })}
    </>
  );
};

export default React.memo(ProfileMetrics);
