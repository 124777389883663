import { Draggable } from 'react-beautiful-dnd';
import React, { useCallback, useState } from 'react';
import { LoadingRing } from '../Loading';
import Alert from '../../alert/Alert';

const ConfirmDeleteImage = ({
  isOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  onCancel: Function;
  onConfirm: Function;
}) => {
  return (
    <Alert
      isOpen={isOpen}
      header="Delete image"
      message="<p>Are you sure you want to delete this image?</p>"
      onDidDismiss={onCancel}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-secondary-btn mr-auto',
          handler: onCancel,
        },
        {
          text: 'Delete image',
          cssClass: 'alert-primary-btn',
          handler: onConfirm,
        },
      ]}
    />
  );
};

const SortableImage = ({
  id,
  index,
  url,
  removeImage,
}: {
  id: string;
  index: number;
  url: string | null;
  removeImage: Function;
}) => {
  const [deletingImage, setDeletingImage] = useState(false);

  const onConfirmDelete = useCallback(() => {
    setDeletingImage(true);
    removeImage(index);
  }, [removeImage, index]);

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {provided => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="relative aspect-21/9 bg-grey-500 rounded-lg+ overflow-hidden my-4">
              {url ? (
                <img
                  className="absolute bottom-0 right-0 w-full h-full object-cover"
                  src={url}
                  alt=""
                />
              ) : (
                <div className="absolute bottom-0 right-0 w-full h-full flex items-center justify-center">
                  <LoadingRing isActive />
                </div>
              )}
              <button
                type="button"
                className="rounded-full absolute top-0 right-0 p-3 mt-2 mr-2 bg-black text-white text-sm leading-none focus:outline-none"
                onClick={() => setDeletingImage(true)}
              >
                <i className="i-trash" />
              </button>
            </div>
          </div>
        )}
      </Draggable>

      <ConfirmDeleteImage
        isOpen={deletingImage}
        onCancel={() => setDeletingImage(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};
export default SortableImage;
