import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Api from '../components/work/Api';

export default function useWorkQuery<
  Entity extends WorkEntity,
  Error extends AxiosError
>(workId: string, options?: UseQueryOptions<Entity, Error>) {
  return useQuery<Entity, Error>(
    ['work', workId],
    () => Api.retrieve(workId),
    options
  );
}
