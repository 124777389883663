export default (url: string) => {
  return {
    id: 'photos',
    label: 'Company photos',
    description:
      'Show off your culture with photos of your view, your espresso machine, and your office dog.',
    icon: 'grid-view',
    action: `#/${url}/images`,
    actionLabel: 'Add Photos',
    complete: false,
  };
};
