import { CollectionType } from '@communo-corp/shared-types';
import React, { useCallback, useMemo, useState } from 'react';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import useFeatureGate from '../../../hooks/useFeatureGate';
import { LoadingRing } from '../../core/Loading';
import useUserAllowList from '../../user/hooks/useUserAllowList';
import Alert from '../../alert/Alert';

type CollectionItemProps = {
  id: string;
  name: string;
  type: CollectionType;
  description: string;
  count: number;
  onToggle: Function;
  isSelected?: boolean;
  isLocked?: boolean;
  userIds: string[];
};

const AddToCollectionItem = ({
  id,
  name,
  type,
  description,
  count,
  classes,
  onToggle,
  isSelected = false,
  loadingColor = '',
  userIds,
}: CollectionItemProps & {
  classes: {
    button: string;
    count: string;
    checkbox: string;
  };
  loadingColor?: 'primary' | '';
}) => {
  const { checkAccess } = useCurrentMembership();
  const { usersInAllowList } = useUserAllowList();
  const { openUpgradeFlowFor } = useFeatureGate();
  const [loading, setLoading] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const permission = type === 'bench' ? 'canAddToBench' : 'canAddToList';

  const allowedAccess = useMemo(() => {
    const hasPermission = checkAccess(permission, count + 1);
    const inAllowList = usersInAllowList(userIds);
    if (type === 'group') {
      return isSelected;
    }
    return hasPermission || inAllowList;
  }, [
    checkAccess,
    count,
    isSelected,
    permission,
    type,
    userIds,
    usersInAllowList,
  ]);

  const onClick = useCallback(() => {
    if (!allowedAccess && type === 'group') {
      // handle onClick event for group
      return;
    }
    if (!allowedAccess) {
      openUpgradeFlowFor(permission, count + 1);
      return;
    }

    if (allowedAccess && type === 'group') {
      setShowRemoveAlert(true);
      return;
    }

    setLoading(true);
    onToggle(id, isSelected, () => {
      setLoading(false);
    });
  }, [
    allowedAccess,
    count,
    id,
    isSelected,
    onToggle,
    openUpgradeFlowFor,
    permission,
    type,
  ]);

  const countDisplay = count >= 10000 ? '10k+' : count;

  const renderItemContent = () => {
    if (loading) {
      return <LoadingRing isActive size="xs" color={loadingColor} />;
    }

    if (allowedAccess) {
      return (
        <span className={`checkbox-stylized ${classes.checkbox}`}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              /* no-op */
            }}
            className="pointer-events-none"
          />
        </span>
      );
    }

    return (
      <span className="text-xl text-black">
        <i className="i-lock" />
      </span>
    );
  };

  return (
    <div>
      <button
        type="button"
        className={`${classes.button} rounded-md p-4 mb-2 space-x-4 flex items-center justify-between w-full`}
        onClick={onClick}
      >
        <div
          className={`${classes.count} rounded-md border w-10 h-10 flex items-center justify-center`}
        >
          {countDisplay}
        </div>
        <div className="flex-1 text-left">
          <p className="text-lg font-semibold">{name}</p>
          <p>{description}</p>
        </div>
        <div className="flex items-center">{renderItemContent()}</div>
      </button>
      <Alert
        isOpen={showRemoveAlert}
        onDidDismiss={() => {
          setShowRemoveAlert(false);
        }}
        header="Are sure you want to remove this user?"
        message="You cannot undo this action - to add them again you will need to send them another invitation."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Confirm',
            cssClass: 'alert-primary-btn',
            handler: () => {
              setLoading(true);
              onToggle(id, isSelected, () => {
                setLoading(false);
              });
            },
          },
        ]}
      />
    </div>
  );
};

export const BenchCollectionItem = ({
  id,
  name,
  description,
  count,
  onToggle,
  isSelected = false,
  userIds,
}: Omit<CollectionItemProps, 'type'>) => {
  const classes = {
    button: isSelected ? 'bg-red-900 text-white' : 'hover:bg-grey-100',
    count: isSelected ? 'border-white' : 'border-black',
    checkbox: isSelected ? 'text-white red-check' : '',
  };
  classes.button += ' border-4 border-red-900';

  return (
    <AddToCollectionItem
      id={id}
      name={name}
      type="bench"
      description={description}
      count={count}
      isSelected={isSelected}
      onToggle={onToggle}
      classes={classes}
      userIds={userIds}
    />
  );
};

export const ListCollectionItem = ({
  id,
  name,
  description,
  count,
  onToggle,
  isSelected = false,
  userIds,
}: Omit<CollectionItemProps, 'type'>) => {
  const classes = {
    button: isSelected ? 'bg-black text-white' : 'hover:bg-grey-100',
    count: isSelected ? 'border-white' : 'border-black',
    checkbox: isSelected ? 'text-white black-check' : '',
  };
  classes.button += ' border border-grey-300';

  return (
    <AddToCollectionItem
      id={id}
      name={name}
      type="list"
      description={description}
      count={count}
      isSelected={isSelected}
      classes={classes}
      onToggle={onToggle}
      loadingColor="primary"
      userIds={userIds}
    />
  );
};
export const GroupCollectionItem = ({
  id,
  name,
  description,
  count,
  onToggle,
  isSelected = false,
  userIds,
}: Omit<CollectionItemProps, 'type'>) => {
  const classes = {
    button: isSelected ? 'bg-black text-white' : 'hover:bg-grey-100',
    count: isSelected ? 'border-white' : 'border-black',
    checkbox: isSelected ? 'text-white black-check' : '',
  };
  classes.button += ' border border-grey-300';

  return (
    <AddToCollectionItem
      id={id}
      name={name}
      type="group"
      description={description}
      count={count}
      isSelected={isSelected}
      classes={classes}
      onToggle={onToggle}
      loadingColor="primary"
      userIds={userIds}
    />
  );
};
