export default (url: string) => {
  return {
    id: 'role',
    label: 'What role best describes what you do?',
    description:
      'Tell us what your superpower is, and we’ll match you with those in need.',
    icon: 'lightening',
    action: `#/${url}/roles`,
    actionLabel: 'Add Role',
    complete: false,
  };
};
