/* eslint-disable no-console */

import firebase from 'firebase/compat/app';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getMessaging, Messaging, getToken } from 'firebase/messaging';
import {
  PushNotificationToken,
  PushNotificationActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import 'firebase/compat/messaging';
import { useHistory } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { isPlatform } from '@ionic/core';
import Api from './components/user/Api';
import { FIREBASE_CONFIG } from './config';
import useCurrentUser from './hooks/useCurrentUser';

const isNative = isPlatform('capacitor');
const isDesktop =
  !isPlatform('mobileweb') && (!isPlatform('android') || !isPlatform('ios'));
// const isSupported =
//   !isPlatform('mobileweb') && (isPlatform('android') || isPlatform('ios'));

// console.log('isNative', isNative);
// console.log('isDesktop', isDesktop);
// console.log('android', isPlatform('android'));
// console.log('ios', isPlatform('ios'));
// console.log('mobileweb', isPlatform('mobileweb'));

let messaging: Messaging;
let app: FirebaseApp;

if (firebase.messaging.isSupported()) {
  app = initializeApp(FIREBASE_CONFIG);
  messaging = getMessaging(app);
}

export default function PushServices() {
  const history = useHistory();
  const { id: userId } = useCurrentUser();

  const setupDesktopPushNotifications = useCallback(async () => {
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    const token = await getToken(messaging);

    Api.registerPushToken({
      token,
      type: 'web',
      status: 'active',
      deviceUuid: deviceId.uuid,
      deviceInfo: {
        // name: "", // Web doesnt have a name
        model: deviceInfo.model,
        manufacturer: deviceInfo.manufacturer,
      },
    });
  }, []);

  const setupMobilePushNotifications = useCallback(async () => {
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();

    if (!userId) return;

    // Register with Apple / Google to receive push via APNS/FCM
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        console.log('There was a problem with registering for notifications');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        Api.registerPushToken({
          token: token.value,
          type: isPlatform('ios') ? 'ios' : 'android',
          status: 'active',
          deviceUuid: deviceId.uuid,
          deviceInfo: {
            name: deviceInfo.name,
            model: deviceInfo.model,
            manufacturer: deviceInfo.manufacturer,
          },
        });
      }
    );

    // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError', (error: any) => {
    //   // Fail silently?
    //   console.log('Error on registration:', JSON.stringify(error));
    // });

    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   (payload: PushNotification) => {
    //     // If, the user is currently viewing the related conversation, this should be ignored
    //     // Else, should trigger an unread message count indicator to update
    //     console.log('Push received:', JSON.stringify(payload));
    //   }
    // );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (payload: PushNotificationActionPerformed) => {
        // console.log('Push action performed:', JSON.stringify(payload));
        const pushData = JSON.parse(payload.notification.data.payloadJSON);
        const pushType = payload.notification.data.type;
        switch (pushType) {
          case 'chat':
            // https://www.twilio.com/docs/chat/webhook-events#onmessagesent
            history.push(`/chat/conversation/${pushData.ChannelSid}`);
            break;
          case 'work':
            history.push(`/work/${pushData.workId}`);
            break;
          case 'event':
            history.push(`/event/${pushData.eventId}`);
            break;
          default:
        }
      }
    );
  }, [history, userId]);

  useEffect(() => {
    if (!isDesktop || !userId || !messaging) return;

    (async () => {
      try {
        await setupDesktopPushNotifications();
      } catch (error) {
        console.log(error);
      }
    })();
  }, [userId, setupDesktopPushNotifications]);

  useEffect(() => {
    if (PushNotifications && isNative) {
      // console.log('setupMobilePushNotifications');
      setupMobilePushNotifications();
    }
  }, [setupMobilePushNotifications]);

  return null;
}
