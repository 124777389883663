import React, { useContext, useRef, useEffect, useCallback } from 'react';
import { IonIcon } from '@ionic/react';
import {
  alertCircleOutline,
  apertureOutline,
  arrowForwardCircleOutline,
  fileTrayFullOutline,
  logOutOutline,
  micOutline,
  settingsOutline,
} from 'ionicons/icons';
// eslint-disable-next-line no-unused-vars
import { ISnapshotInfo } from 'cordova-plugin-ionic/dist/IonicCordova';
import { Deploy } from 'cordova-plugin-ionic';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import MembershipContext from '../membership/Context';
import Avatar from '../avatar/Avatar';
import { isMobile } from '../../utilities/MatchMedia';
import BenchMembersIcon from '../icons/BenchMembersIcon';
import useFeatureGate from '../../hooks/useFeatureGate';
import ZendeskWidgetContext from '../../context/ZendeskWidgetContext';
import useCurrentPlan from '../../hooks/useCurrentPlan';
import isTalentPlan from '../plan/utilities/isTalentPlan';

const divider = <div className="border-t border-grey-200 mt-2 mb-2 mx-3" />;

type Props = { handleClose?: any };

const Account = ({ handleClose = () => {} }: Props) => {
  const { needsUpgrade } = useFeatureGate();
  const userContext = useContext(UserContext);
  const { membership, planType, checkAccess } = useContext(MembershipContext);
  const { toggleZendeskWidget } = useContext(ZendeskWidgetContext);
  const {
    id: userId,
    membershipId,
    hasMembership,
    membershipAccess,
    avatarURL,
    name,
    isRoot,
  } = userContext;
  const navClass =
    'hover:bg-teal-300 block px-3 h-10 flex items-center md:text-base';
  const iconClass = 'w-10 h-10 flex items-center justify-center mr-4';
  const appInfo = useRef<ISnapshotInfo | undefined>(undefined);

  const hasMembershipPage = ['partner', 'agency', 'brand'].includes(planType);

  const showListsToBusinessMembers = !isTalentPlan(useCurrentPlan());

  useEffect(() => {
    (async () => {
      if (!appInfo.current && isMobile) {
        const info = await Deploy.getCurrentVersion();
        appInfo.current = info;
      }
    })();
  }, []);

  const handleListsClick = useCallback(
    e => {
      if (handleClose) {
        handleClose();
      }

      if (needsUpgrade('canAccessCollections')) {
        e.preventDefault();
        return false;
      }
      return true;
    },
    [handleClose, needsUpgrade]
  );

  const handleZendeskToggle = useCallback(
    event => {
      event.preventDefault();
      toggleZendeskWidget();
      handleClose();
      return false;
    },
    [handleClose, toggleZendeskWidget]
  );

  return (
    <div className="py-3 min-h-full flex flex-col sm:w-64">
      <div className="pt-4 pb-4 px-3 flex items-center sm:hidden">
        <div className={iconClass} />
        <h4>Account Menu</h4>
      </div>
      {!hasMembership && (
        <Link to="/plans" onClick={handleClose} className={navClass}>
          <div className={iconClass}>
            <i className="i-user" />
          </div>
          Choose a Plan
        </Link>
      )}
      {hasMembership && (
        <Link
          to={`/members/user/${userId}`}
          onClick={handleClose}
          className={`${navClass} h-auto py-2`}
        >
          <div className={iconClass}>
            <Avatar size="md" avatarName={name} avatarUrl={avatarURL} />
          </div>
          <div className="truncate">
            <div className="truncate">
              {name ? (
                <span className="font-medium">{name}</span>
              ) : (
                'My Profile'
              )}
            </div>
            <div className="text-sm text-subdued">View Profile</div>
          </div>
        </Link>
      )}
      {hasMembership && (
        <>
          <Link
            to={isMobile ? `#/edit-profile` : `#/edit-profile/basic`}
            onClick={handleClose}
            className={navClass}
          >
            <div className={`${iconClass} text-lg`}>
              <i className="i-edit" />
            </div>
            Edit Profile
          </Link>
          {checkAccess('canPostWork') ? (
            <Link
              to="#/my-stuff/work"
              onClick={handleClose}
              className={navClass}
            >
              <div className={`${iconClass} text-xl`}>
                <IonIcon icon={fileTrayFullOutline} />
              </div>
              My Work
            </Link>
          ) : null}
          {checkAccess(['canApplyToCommunoWork', 'canApplyToStudentWork']) ? (
            <Link
              to="#/my-stuff/applications"
              onClick={handleClose}
              className={navClass}
            >
              <div className={`${iconClass} text-xl`}>
                <i className="i-pencil" />
              </div>
              My Applications
            </Link>
          ) : null}
          {showListsToBusinessMembers && checkAccess('canAccessCollections') && (
            <Link to="/lists" onClick={handleListsClick} className={navClass}>
              <div className={`${iconClass} text-xl`}>
                <BenchMembersIcon className="w-6 h-6" />
              </div>
              Lists
            </Link>
          )}
        </>
      )}
      <Link
        to={isMobile ? '#/settings' : '#/settings/plan'}
        onClick={handleClose}
        className={navClass}
      >
        <div className={`${iconClass} text-xl`}>
          <IonIcon icon={settingsOutline} />
        </div>
        My Account
      </Link>
      {hasMembershipPage && (
        <>
          {divider}
          <Link
            to={`/members/membership/${membershipId}`}
            onClick={handleClose}
            className={`${navClass} h-auto py-2`}
          >
            <div className="w-10 h-10 flex items-center justify-center mr-4">
              <Avatar
                size="md"
                avatarName={membership?.name}
                avatarUrl={membership?.logoURL}
              />
            </div>
            <div className="truncate">
              <div className="truncate font-medium">
                {membership?.name || 'Membership Page'}
              </div>
              <div className="text-sm text-subdued">View Page</div>
            </div>
          </Link>
          {membershipAccess === 'admin' ? (
            <>
              <Link
                to={isMobile ? '#/edit-page' : '#/edit-page/basic'}
                onClick={handleClose}
                className={navClass}
              >
                <div className={`${iconClass} text-lg`}>
                  <i className="i-edit" />
                </div>
                Edit Page
              </Link>
              <Link
                to="#/edit-meetings"
                onClick={handleClose}
                className={navClass}
              >
                <div className={`${iconClass} text-lg`}>
                  <i className="i-calendar" />
                </div>
                Edit Meetings
              </Link>
              <Link
                to="#/edit-page/team"
                onClick={handleClose}
                className={navClass}
              >
                <div className={`${iconClass} text-lg`}>
                  <i className="i-users" />
                </div>
                Team
              </Link>
            </>
          ) : null}
        </>
      )}

      {isRoot && (
        <>
          {divider}
          <Link to="#/auth/become" onClick={handleClose} className={navClass}>
            <div className={`${iconClass} text-2xl`}>
              <IonIcon icon={arrowForwardCircleOutline} />
            </div>
            Become a user
          </Link>
          <Link
            to="/root/membership/deactivated"
            onClick={handleClose}
            className={navClass}
          >
            <div className={`${iconClass} text-2xl`}>
              <IonIcon icon={alertCircleOutline} />
            </div>
            Deactivated List
          </Link>
          <Link
            to="/perks/manage"
            onClick={handleClose}
            className={`${navClass}`}
          >
            <div className={`${iconClass} text-lg`}>
              <i className="i-grid-view" />
            </div>
            Manage Perks
          </Link>
          <Link
            to="/work-external/create"
            onClick={handleClose}
            className={navClass}
          >
            <div className={`${iconClass} text-lg`}>
              <i className="i-work" />
            </div>
            Post External Work
          </Link>
        </>
      )}

      {divider}
      <div className="mt-auto">
        <a
          href="https://communo.com/resources/podcasts/"
          rel="noopener noreferrer"
          target="_blank"
          className={`${navClass}`}
        >
          <div className={`${iconClass} text-2xl`}>
            <IonIcon icon={micOutline} />
          </div>
          Podcasts
        </a>
        <a
          href="https://communo.com/resources/business-spotlight/"
          rel="noopener noreferrer"
          target="_blank"
          className={`${navClass}`}
        >
          <div className={`${iconClass} text-2xl`}>
            <IonIcon icon={apertureOutline} />
          </div>
          Spotlight
        </a>
        <button
          type="button"
          onClick={handleZendeskToggle}
          className={`${navClass} btn--full`}
        >
          <div className={`${iconClass} text-xl`}>
            <i className="i-info-circle" />
          </div>
          Support
        </button>
        <Link to="/logout" className={`${navClass}`}>
          <div className={`${iconClass} text-2xl`}>
            <IonIcon icon={logOutOutline} />
          </div>
          Log out
        </Link>

        {appInfo.current && (
          <div className="p-3 text-sm text-grey-600 text-center flex flex-wrap">
            <div className="px-3">
              <span className="font-semibold">Version:</span>{' '}
              {appInfo.current?.binaryVersion}
            </div>
            <div className="px-3">
              <span className="font-semibold">Build:</span>{' '}
              {appInfo.current?.binaryVersionCode}
            </div>
            <div className="px-3">
              <span className="font-semibold">ID:</span>{' '}
              {appInfo.current?.buildId}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
