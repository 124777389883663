import React from 'react';
import { Link } from '../../core/router';

interface Props {
  item: any;
}

const FullFlex = ({ item }: Props) => {
  const { heading, actionUrl, actionText, imageUrl, subHeading } = item;

  return (
    <div className="discover-static-cta">
      <img
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        alt="Background"
        src={imageUrl}
      />
      <div className="discover-static-cta__inset">
        <div className="content text-white text-center">
          {heading && <h2>{heading}</h2>}
          {subHeading && <p>{subHeading}</p>}
        </div>

        <Link to={actionUrl} className="btn btn--primary mt-4">
          {actionText}
        </Link>
      </div>
    </div>
  );
};
export default FullFlex;
