import React from 'react';
import { isoCountries } from '../../../helpers/countries';

export default ({ name, register }: any) => (
  <select
    id={name}
    name={name}
    className="form-input"
    data-recurly="country"
    ref={register({
      required: 'Country is required',
    })}
  >
    <option value="" disabled>
      Country
    </option>
    <option value="CA">Canada</option>
    <option value="US">United States</option>
    <option disabled>-</option>
    {isoCountries.map((v: Country) => (
      <option key={v.code} value={v.code}>
        {v.name}
      </option>
    ))}
  </select>
);
