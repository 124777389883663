// eslint-disable-next-line no-unused-vars
import algoliasearch, { SearchClient } from 'algoliasearch';
// eslint-disable-next-line no-unused-vars
import { SearchOptions, MultipleQueriesQuery } from '@algolia/client-search';
import { createNullCache } from '@algolia/cache-common';
import React, { useEffect, useContext, useState, useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import { SearchIndexes as Indexes, ContextInterface } from './index.d';
import { ALGOLIA_APP_ID } from '../../config';
import UserContext from '../user/Context';
import { getSearchIndex } from '../../utilities/Algolia';

type QueryObject = { [key: string]: SearchOptions };

const SearchContext = React.createContext<ContextInterface>(
  {} as ContextInterface
);

let client: SearchClient | null = null;

const MemoSearchProvider = ({
  isReady,
  userId,
  children,
}: {
  isReady: boolean;
  userId: string | null;
  children: any;
}) => {
  const getIndex = useCallback((indexName: Indexes): any => {
    return client?.initIndex(getSearchIndex(indexName));
  }, []);

  const search = useCallback(
    (indexName: Indexes, params: SearchOptions = {}) => {
      return (
        getIndex(indexName)?.search(params.query ?? '', params) ||
        Promise.reject(new Error('Search client unavailable.'))
      );
    },
    [getIndex]
  );

  const multipleQueries = useCallback((queries: MultipleQueriesQuery[]) => {
    if (!client) return Promise.reject(new Error('Search client unavailable.'));

    return client.search(Object.values(queries)).then(searchResults => {
      return Object.keys(queries).reduce(
        (carry: any, queryKey: string, queryIndex: number) => {
          // eslint-disable-next-line no-param-reassign
          carry[queryKey] = searchResults.results[queryIndex];
          return carry;
        },
        {}
      );
    });
  }, []);

  const multipleQueriesByIndex = useCallback(
    (indexName: Indexes, queries: QueryObject) => {
      // reformat for multipleQueries params
      const q: any = [];
      const keys = Object.keys(queries);
      Object.values(queries).forEach((query, i) => {
        q[keys[i]] = {
          indexName: getSearchIndex(indexName),
          ...query,
        };
      });

      return multipleQueries(q);
    },
    [multipleQueries]
  );

  const getObjects = useCallback(
    (indexName: Indexes, ids: string[]) => getIndex(indexName).getObjects(ids),
    [getIndex]
  );

  const getCategories = useCallback(
    query => search(Indexes.Category, { query }),
    [search]
  );

  const getIndustries = useCallback(
    query => search(Indexes.Industry, { query }),
    [search]
  );

  const getMembersForSearch = useCallback((queries: any) => {
    if (!client) {
      return Promise.reject(new Error('Search client unavailable.'));
    }

    return client?.search(Object.values(queries)).then(searchResults => {
      return Object.keys(queries).reduce(
        (carry: any, queryKey: string, queryIndex: number) => {
          // eslint-disable-next-line no-param-reassign
          carry[queryKey] = searchResults.results[queryIndex];
          return carry;
        },
        {}
      );
    });
  }, []);

  const getUsersForSearch = useCallback(params => getMembersForSearch(params), [
    getMembersForSearch,
  ]);

  const getRoles = useCallback(query => search(Indexes.Role, { query }), [
    search,
  ]);

  const getSkills = useCallback(query => search(Indexes.Skill, { query }), [
    search,
  ]);

  const getSchools = useCallback(
    (query: string, isHBCU: boolean = false) => {
      const params: any = { query };
      if (isHBCU) {
        params.filters = 'isHBCU=1';
      }

      return search(Indexes.School, params);
    },
    [search]
  );

  const getWork = useCallback(
    queries => multipleQueriesByIndex(Indexes.Work, queries),
    [multipleQueriesByIndex]
  );

  const getEvents = useCallback(
    queries => multipleQueriesByIndex(Indexes.Event, queries),
    [multipleQueriesByIndex]
  );

  const getUsers = useCallback(
    (params: any, visibleOnly: boolean = true) => {
      return search(Indexes.User, {
        filters: `NOT objectID:${userId} ${
          visibleOnly ? 'AND isVisible=1' : ''
        }`,
        ...params,
      });
    },
    [search, userId]
  );

  const getMemberships = useCallback(
    (params: any, visibleOnly: boolean = true) => {
      return search(Indexes.Membership, {
        filters: `NOT objectID:${userId} ${
          visibleOnly ? 'AND isVisible=1' : ''
        }`,
        ...params,
      });
    },
    [search, userId]
  );

  const getMembers = useCallback(
    queries => multipleQueriesByIndex(Indexes.Membership, queries),
    [multipleQueriesByIndex]
  );

  const getUserById = useCallback(
    id => {
      return (
        getIndex(Indexes.User)?.getObject(id) ||
        Promise.reject(new Error('Search client unavailable.'))
      );
    },
    [getIndex]
  );

  const getPerks = useCallback(
    queries => multipleQueriesByIndex(Indexes.Perk, queries),
    [multipleQueriesByIndex]
  );

  return (
    <SearchContext.Provider
      value={{
        // check if the search client is initialized and available
        isReady,
        client,
        // search any index (not: must be a [qa|prod]-SearchIndex format for index
        search,
        multipleQueries,
        getIndex,
        // return specific results by index and ids
        getObjects,
        // simpler callbacks with promises for all the indexes
        getCategories,
        getIndustries,
        getRoles,
        getSkills,
        getWork,
        getEvents,
        getUsers,
        getMembers, // multiquery
        getMembersForSearch,
        getUsersForSearch,
        getUserById,
        getMemberships,
        getPerks,
        getSchools,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const MemoProvider = React.memo(MemoSearchProvider);

export const SearchProvider = ({
  searchTokenOverride,
  children,
}: {
  searchTokenOverride?: string;
  children: any;
}) => {
  const { searchToken, id } = useContext(UserContext);
  const [isReady, setReady] = useState<boolean>(false);

  const searchTokenToUse = searchTokenOverride || searchToken;

  useEffect(() => {
    if (!searchTokenToUse && isReady) {
      // if we've lost the searchToken, we're no longer ready to search
      setReady(false);
      return;
    }

    if (searchTokenToUse) {
      client = algoliasearch(ALGOLIA_APP_ID, searchTokenToUse, {
        requestsCache: createNullCache(),
        responsesCache: createNullCache(),
      });

      if (!isReady) {
        setReady(true);
      }
    }
  }, [isReady, searchTokenToUse]);

  return (
    <MemoProvider userId={id || null} isReady={isReady}>
      {children}
    </MemoProvider>
  );
};

export default SearchContext;
