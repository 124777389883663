import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function usePreviewUpgradePlan(
  planCode: string | null,
  coupon?: string
): {
  isFetching: boolean;
  data: any;
} {
  const { data, isFetching } = useQuery(
    ['preview-upgrade-plan', planCode, coupon],
    () => Api.previewUpgradePlan({ planCode, coupon }).catch(() => null), // Allow react-query to cache non-200 responses
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!planCode,
      cacheTime: 20 * 1000,
      staleTime: 20 * 1000,
    }
  );

  return {
    isFetching,
    data,
  };
}
