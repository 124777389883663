import React from 'react';
import useFeatureGate from '../../hooks/useFeatureGate';

const WorkUpgradeInstructions = () => {
  const { openUpgradeFlowFor } = useFeatureGate();

  return (
    <>
      <h5 className="mt-12">How to Apply</h5>
      <div className="mt-4">
        Your current plan does not allow you to apply for this work. Please{' '}
        <button
          className="text-link font-medium"
          type="button"
          onClick={() => {
            openUpgradeFlowFor('canApplyToCommunoWork');
          }}
        >
          upgrade
        </button>{' '}
        to apply.
      </div>
    </>
  );
};
export default WorkUpgradeInstructions;
