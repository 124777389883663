import { useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

type Props = {
  videoUrl: string | null;
  errorContent?: JSX.Element | null;
};

export function canPlayVideo(videoUrl: string): boolean {
  return ReactPlayer.canPlay(videoUrl);
}

const VideoPlayer = ({ videoUrl = null, errorContent = null }: Props) => {
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [validatedUrl, setValidatedUrl] = useState<string | null>(null);

  useIonViewWillLeave(() => {
    setPlaying(false);
  }, []);

  useEffect(() => {
    if (videoUrl && ReactPlayer.canPlay(videoUrl)) {
      setValidatedUrl(videoUrl);
    }
  }, [videoUrl]);

  if (!videoUrl || !validatedUrl || error) {
    return errorContent;
  }

  return (
    <div className="aspect-video h-full">
      <ReactPlayer
        playing={playing}
        url={validatedUrl}
        controls
        width="100%"
        height="100%"
        onError={() => setError(true)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
      />
    </div>
  );
};
export default VideoPlayer;
