import { isPlatform } from '@ionic/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Participant } from 'twilio-video';
import Button from '../core/Button';
import { LoadingBalls } from '../core/Loading';
import VideoContext from './Context';
import MenuBar from './MenuBar';
import ParticipantCard from './ParticipantCard';
import ParticipantSlider from './ParticipantSlider';

const ErrorStatus = ({ statusText }: { statusText: string }) => (
  <p className="mb-4 text-center text-white">
    {statusText.split('\n').map((section: string, index: number) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${index}-${section}`}>
          {section}
          <br />
        </React.Fragment>
      );
    })}
  </p>
);

const RoomView = () => {
  const {
    room,
    connect,
    disconnect,
    isConnecting,
    participants,
    dominantSpeaker,
    callStats,
    statusText,
    getMagicLink,
    openBrowser,
  } = useContext(VideoContext);

  const location = useLocation();
  const history = useHistory();
  const disconnectCallback = useRef(disconnect);
  const { roomId } = useParams();
  const [iosLoading, setIosLoading] = useState(false);

  const getUser = useCallback(
    (participant: Participant) => {
      return callStats.totalParticipants.filter(
        (user: UserEntity) => user.id === participant.identity
      )[0];
    },
    [callStats.totalParticipants]
  );

  useEffect(() => {
    disconnectCallback.current = disconnect;
  }, [disconnect]);

  useEffect(() => {
    if (!room && roomId && !isConnecting) {
      // If ios/capacitor don't do anything
      if (isPlatform('ios') && isPlatform('capacitor')) {
        return;
      }

      connect(roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    const callback = disconnectCallback.current;
    return () => callback();
  }, [disconnectCallback, location]);

  useEffect(() => {
    const callback = disconnectCallback.current;
    window.addEventListener('beforeunload', callback);
    return () => window.removeEventListener('beforeunload', callback);
  }, [disconnectCallback]);

  let displayClass = 'video-display-dominant';
  const participantCount = participants.length;

  // 8 other participants, 9 total
  if (participantCount < 8) {
    displayClass = `video-display-${participantCount + 1}`;
  }

  if (isPlatform('cordova') && isPlatform('ios')) {
    return (
      <div className="my-16 flex flex-col content-center justify-center">
        <ErrorStatus statusText={statusText} />
        <Button
          className="mt-4 mx-auto w-64"
          loading={iosLoading}
          onClick={() => {
            if (!roomId) return;
            setIosLoading(true);
            getMagicLink(roomId).then((url: string) => {
              openBrowser(url).then(() => setIosLoading(false));
            });
          }}
        >
          Connect
        </Button>
        <Button
          className="mt-2 mx-auto w-64"
          onClick={() => history.push('/video-chat')}
        >
          Back
        </Button>
      </div>
    );
  }

  if (!roomId) {
    return (
      <Link className="text-white" to="/video-chat">
        Something went wrong, click to go back
      </Link>
    );
  }

  if (isConnecting) {
    return (
      <div className="my-16 flex flex-col content-center justify-center">
        <p className="mb-4 text-center text-white">
          Connecting to room &quot;{roomId}&quot;
        </p>
        <ErrorStatus statusText={statusText} />
        <LoadingBalls isActive />
      </div>
    );
  }

  return room ? (
    <div className="video-room">
      <div className={displayClass}>
        <ParticipantSlider
          localParticipant={room.localParticipant}
          participants={participants.slice(4, participantCount)}
          users={callStats.totalParticipants}
          dominantSpeaker={dominantSpeaker}
        />
        {displayClass !== 'video-display-dominant' && (
          <ParticipantCard
            key={room.localParticipant.identity}
            isLocal
            participant={room.localParticipant}
            user={getUser(room.localParticipant)}
            isDominant={room.localParticipant === dominantSpeaker}
          />
        )}
        {participants
          .slice(0, 8)
          .map((participant: Participant, index: number) => {
            return (
              <ParticipantCard
                key={`dominant-${participant.identity}`}
                participant={participant}
                user={getUser(participant)}
                isDominant={participant === dominantSpeaker}
                hideMobile={index >= 4}
              />
            );
          })}
      </div>
      <MenuBar />
    </div>
  ) : (
    <div className="my-16 flex flex-col content-center justify-center">
      <Link to="/video-chat" className="text-white text-center my-6">
        Something went wrong, click to go back
      </Link>
      <ErrorStatus statusText={statusText} />
    </div>
  );
};

export default RoomView;
