import React from 'react';
import useChatChannel from '../components/chat/hooks/useChatChannel';
import { LoadingBalls } from '../components/core/Loading';
import MemberSettings from '../components/chat/views/MemberSettings';
import View, {
  ViewContent,
  ViewFooter,
  ViewHeader,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import { ChatUserProfile } from '../components/chat/components/ChannelProfile';
import BackButton from '../components/core/BackButton';

const ChatMemberSettings = ({ match }: any) => {
  const { sid, identity } = match.params;
  const { channel, loaded } = useChatChannel(sid);

  if (!channel || !loaded) return <LoadingBalls isActive fullscreen />;

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">Member Settings</h6>
          <MemberSettings channel={channel} identity={identity} />
        </ViewContent>
        <ViewFooter className="actions">
          <BackButton
            className="btn btn--primary btn--sm btn--outline"
            defaultHref="/chat"
          />
        </ViewFooter>
      </>
    );

  return (
    <View authGuard darkStatusBar pageTitle="Chat Member Settings">
      <ViewHeader defaultBackHref="/chat">
        <ChatUserProfile
          channelSid={sid}
          identity={identity}
          format="compact"
        />
      </ViewHeader>
      <ViewContent layout="chat">
        <MemberSettings channel={channel} identity={identity} />
      </ViewContent>
    </View>
  );
};

export default ChatMemberSettings;
