import React from 'react';
import Page from '../components/core/Page';

const PageMockup = () => {
  return (
    <Page authGuard layout="none" className="bg-grey-100">
      <div className="page-container pb-6">
        <div className="work-view grid px-4 md:px-0">
          <div className="work-view__header bg-white p-0 md:p-8 pb-0 mt-4 md:mt-0 rounded-t-2xl md:rounded-none">
            <img
              src="https://res.cloudinary.com/communo/image/upload/c_fill,f_auto,q_auto,w_826,h_414/v1599758608/prod/user/3164edf242274ae0bbf96986614d2b8c/image/orsor94novdpyczr6nhn.webp"
              alt="Cover"
            />
          </div>
          <div className="work-view__sidebar">
            <div className="bg-white md:mt-8 px-6 md:p-8 md:rounded-xl">
              <div className="float-right relative z-10">
                <button
                  type="button"
                  className="mt-4 md:mt-0 flex items-center"
                >
                  <div className="text-sm uppercase mr-1">Menu</div>
                  <div className="text-lg">
                    <i className="i-more-vert" />
                  </div>
                </button>
                <div id="">
                  <div className="sidebar-menu closed">
                    <div className="backdrop" role="none" />
                    <div className="menu-content rounded-l md:rounded-lg overflow-y-scroll md:overflow-auto">
                      <div className="py-3 sm:w-48">
                        <div className="pt-8 pb-4 px-3 flex items-center sm:hidden">
                          <div className="w-10 h-10 flex items-center justify-center mr-4" />
                          <h4>Work Menu</h4>
                        </div>
                        <a
                          className="hover:bg-teal-300 block px-3 h-12 flex items-center md:text-base cursor-pointer"
                          href="/work/017ea89bfdaf44a0a5a245243e06556d/candidates/list"
                        >
                          <div className="w-10 h-10 flex items-center justify-center mr-4">
                            <i className="i-users" />
                          </div>
                          Candidates
                        </a>
                        <a
                          className="hover:bg-teal-300 block px-3 h-12 flex items-center md:text-base cursor-pointer"
                          href="/work/017ea89bfdaf44a0a5a245243e06556d/edit"
                        >
                          <div className="w-10 h-10 flex items-center justify-center mr-4">
                            <i className="i-edit" />
                          </div>
                          Edit Work
                        </a>
                        <div
                          role="none"
                          className="hover:bg-teal-300 block px-3 h-12 flex items-center md:text-base cursor-pointer"
                        >
                          <div className="w-10 h-10 flex items-center justify-center mr-4">
                            <i className="i-lock" />
                          </div>
                          Close Work
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative pb-16 md:pb-0">
                <div className="absolute top-0 transform -translate-y-1/2 md:flex md:static md:transform-none">
                  <a href="/members/membership/79e5607555624e759f526697b4acd8d2">
                    <div className="border-0 border-white rounded-full">
                      <div className="avatar avatar--xl">
                        <img
                          src="https://res.cloudinary.com/communo/image/upload/c_fill,f_auto,q_auto,w_200,h_200/v1599758491/prod/user/3164edf242274ae0bbf96986614d2b8c/image/aqkj0nz8ylvdn3fkvv77.png"
                          alt="Unified"
                          className=""
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <p className="mt-2 text-lg">Unified</p>
              <div className="my-4">
                <div className="markdown">
                  <div className="richtext">
                    <p>
                      We believe the best marketing insights power the best
                      performance. Unified delivers both.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8 lg:mt-10">
                <a
                  href="http://unified.com"
                  className="inline-block mr-5 text-lg"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="i-web" />
                </a>
                <a
                  href="https://www.facebook.com/unified"
                  className="inline-block mr-5 text-lg"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="i-facebook" />
                </a>
                <a
                  href="https://twitter.com/unified"
                  className="inline-block mr-5 text-lg"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="i-twitter" />
                </a>
                <a
                  href="https://www.instagram.com/unified/"
                  className="inline-block mr-5 text-lg"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="i-instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/2268557/admin/"
                  className="inline-block mr-5 text-lg"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="i-linkedin" />
                </a>
              </div>
              <hr className="mt-8" />
              <h5 className="my-8">
                Direct Response Specialist - Media Buyer (Campaign Manager)
              </h5>
              <div className="mt-2 -mx-4">
                <div className="notice notice--warning">
                  <p className="text-gold-1100 font-medium">Requirements</p>
                  <p className="text-black text-lg">
                    US Based, english speaking
                  </p>
                </div>
              </div>
              <div className="mt-8">
                <div className="">
                  <p className="font-medium text-grey-400">Location</p>
                  <p className="text-black mt-1 text-lg">
                    Los Angeles, CA, USA
                  </p>
                  <p className="text-black font-medium text-lg">Remote OK</p>
                </div>
              </div>
              <div className="mt-8">
                <p className="text-grey-400 font-medium">Role</p>
                <p className="mt-1 text-lg">Paid Media</p>
              </div>
              <div className="mt-8">
                <p className="text-grey-400 font-medium">Type</p>
                <p className="mt-1 text-lg">Ongoing Need</p>
              </div>
              <div className="mt-8">
                <p className="text-grey-400 font-medium">Skills</p>
                <p className="mt-1 text-lg">
                  Facebook Advertising, Facebook Pixel, Marketing Optimization,
                  Media Buying, Performance Measurement
                </p>
              </div>
              <div className="mt-8">
                <p className="text-grey-400 font-medium">Budget (EST)</p>
                <div className="mt-1 text-lg">$5,000</div>
              </div>
              <div className="mt-8">
                <p className="text-grey-400 font-medium">Posted</p>
                <p className="mt-1 text-lg">November 13, 2020</p>
              </div>
            </div>
          </div>
          <div className="bg-white md:bg-transparent rounded-b-2xl">
            <hr className="mt-12 mx-6 md:hidden" />
            <div className="px-6 md:px-8 bg-white">
              <h3 className="mt-12 md:mt-0">
                Direct Response Specialist - Media Buyer (Campaign Manager)
              </h3>
              <div className="mt-4">
                <div className="markdown">
                  <div className="richtext">
                    <p>
                      <strong>What it is:</strong>
                    </p>
                    <p>
                      Unified is looking for a Media Buyer to join our growing
                      Ad Ops team. This is an exciting opportunity for anyone
                      with paid advertising experience looking to work with
                      Fortune 500 clients at the highest level. This person will
                      be an integral member of a growing team who works closely
                      with Account Managers and publisher partners to meet
                      client KPIs and always exceed expectations.
                    </p>
                    <p>
                      <strong>What you’ll do:</strong>
                    </p>
                    <ul>
                      <li>
                        Create, launch, and optimize performance marketing
                        campaigns for paid social clients without oversight and
                        gain experience leading client partnerships under
                        manager oversight.
                      </li>
                      <li>
                        Analyze, recommend, and implement improvements
                        throughout campaign lifecycle to achieve client
                        marketing objectives
                      </li>
                      <li>
                        Actively monitor pacing and performance for multiple
                        lines of business through Unified Platform and Publisher
                        tools.
                      </li>
                      <li>
                        Share your knowledge with internal and external
                        team-members on best practices for social platforms and
                        lead instructive trainings.
                      </li>
                      <li>
                        Develop thought-out actionable insights on clients’
                        campaigns and give recommendations to boost performance.
                      </li>
                      <li>
                        Dig into mistakes on your accounts to identify a root
                        cause and proactively implement a fix to prevent
                        team-members from making it again.
                      </li>
                      <li>
                        Constantly improve and enhance our team’s workflow with
                        innovative ideas and guide our technology product team
                        on enhancing the Unified Platform for the Campaign
                        Management team and our customers.
                      </li>
                      <li>
                        Regularly take on large projects and presentations and
                        set an example for less-experienced members of the team.
                      </li>
                      <li>
                        Work closely with publisher partners (including
                        Facebook, Twitter, Snapchat, Reddit, YouTube) to
                        flawlessly collaborate on high profile campaigns and
                        gain inclusion on new product tests.
                      </li>
                    </ul>
                    <p>
                      <strong>Who you are:</strong>
                    </p>
                    <ul>
                      <li>
                        You have knowledge and experience running paid social
                        advertising campaigns.&nbsp;
                      </li>
                      <li>
                        You’re a
                        Facebook/Twitter/Snapchat/Pinterest/Reddit/YouTube
                        addict who understands and appreciates the value of
                        social.
                      </li>
                      <li>
                        You’re not afraid to find deep analytical, organized,
                        and results-driven solutions for complex problems.&nbsp;
                      </li>
                      <li>
                        You’re comfortable speaking in client-facing
                        presentations and internal meetings.
                      </li>
                      <li>
                        You’re startup-ready--not looking for a 9-to-5. You’re
                        flexible and can adjust to a rapidly changing business.
                      </li>
                      <li>
                        You’re proven to keep you your cool during high-pressure
                        moments and have a positive impact on the people working
                        around you.
                      </li>
                      <li>
                        You’re a life-hacker. You don’t need to be a coder, but
                        using tech to save time and improve workflow is a must.
                      </li>
                    </ul>
                    <p>
                      <strong>Need to have:</strong>
                    </p>
                    <ul>
                      <li>
                        Experience running performance (Direct Response)
                        campaigns on social platforms
                      </li>
                      <li>
                        Experience with Facebook ads, Twitter ads, YouTube ads,
                        or other RTB media buying and optimization tools and an
                        excitement to learn new publishers as they come to you
                      </li>
                      <li>
                        Thorough understanding of web technologies (pixels,
                        html, javascript)
                      </li>
                      <li>
                        Experience running at scale and optimizing to ROAS.
                      </li>
                      <li>Experience with Microsoft Excel and Google Docs</li>
                      <li>
                        Experience with website tracking tools such as Google
                        Analytics
                      </li>
                      <li>High level of accuracy and attention to detail</li>
                      <li>
                        Professional maturity, integrity, discipline, and a
                        positive attitude
                      </li>
                      <li>Strong public-speaking skills</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white px-6 md:px-8 py-8 rounded-b-2xl">
              <hr className="mt-12 mb-8" />
              <div className="mb-16" />
              <h5 className="mb-4">
                Your Candidate Score
                <span
                  className="ml-4 font-medium text-red-900 text-sm cursor-pointer leading-normal"
                  role="none"
                >
                  What is this?
                </span>
              </h5>

              <div className="md:flex border border-grey-200 rounded-md">
                <div className="grid grid-cols-3 gap-12">
                  <div className="col-span-2 py-6 pl-6">
                    <div className="grid grid-cols-2 gap-12">
                      <div>
                        <img
                          src="https://res.cloudinary.com/communo/image/upload/v1606172573/assets/meta/candidate-score-fpo.png"
                          alt="score"
                          className="max-w-"
                        />
                      </div>
                      <div>
                        <div className="flex md:block mt-2">
                          <h6 className="w-24 md:w-auto font-light mb-1">
                            Profile
                          </h6>
                          <div className="grow text-sm">
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">Logo</div>
                              <div>
                                <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                  <i className="i-check" />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">Skills</div>
                              <div>
                                <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                  <i className="i-check" />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">Industries</div>
                              <div>
                                <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                  <i className="i-check" />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">About</div>
                              <div>
                                <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                  <i className="i-check" />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">Links</div>
                              <div>
                                <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                  <i className="i-check" />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center h-8 leading-snug">
                              <div className="font-medium">Portfolio</div>
                              <div>
                                <a
                                  className="text-red-900 font-medium"
                                  href="/work/017ea89bfdaf44a0a5a245243e06556d#/edit-page/portfolio"
                                >
                                  Add
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-6 bg-red-900 text-center">
                    <h3 className="leading-snug text-white mb-1">
                      1-Click Apply
                    </h3>
                    <p className="my-4 text-white text-lg">
                      Apply for these external jobs with your Communo profile.
                    </p>
                    <p className="my-4 font-semibold text-sm">
                      You appear to be missing information - improve your
                      chances by completing your profile.
                    </p>
                  </div>
                </div>
              </div>

              <div className="md:flex border border-grey-200 rounded-md">
                <div className="flex md:block justify-end md:mr-16">
                  <div className="w-40">
                    <img
                      src="https://res.cloudinary.com/communo/image/upload/v1606172573/assets/meta/candidate-score-fpo.png"
                      alt="score"
                    />
                  </div>
                </div>
                <div className="w-full pt-4">
                  <div className="md:grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 md:gap-x-12">
                    <div className="flex md:block mt-2">
                      <h6 className="w-24 md:w-auto font-light mb-1">
                        Profile
                      </h6>
                      <div className="grow text-sm">
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Logo</div>
                          <div>
                            <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                              <i className="i-check" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Skills</div>
                          <div>
                            <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                              <i className="i-check" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Industries</div>
                          <div>
                            <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                              <i className="i-check" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">About</div>
                          <div>
                            <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                              <i className="i-check" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Links</div>
                          <div>
                            <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                              <i className="i-check" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Portfolio</div>
                          <div>
                            <a
                              className="text-red-900 font-medium"
                              href="/work/017ea89bfdaf44a0a5a245243e06556d#/edit-page/portfolio"
                            >
                              Add
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex md:block mt-2">
                      <h6 className="w-24 md:w-auto font-light mb-1">
                        Skills Match
                      </h6>
                      <div className="grow text-sm">
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">
                            Facebook Advertising
                          </div>
                          <div>
                            <div
                              className="h-5 w-5 text-grey-500 cursor-pointer"
                              role="none"
                            >
                              <i className="i-alert-add text-xl" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Facebook Pixel</div>
                          <div>
                            <div
                              className="h-5 w-5 text-grey-500 cursor-pointer"
                              role="none"
                            >
                              <i className="i-alert-add text-xl" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">
                            Marketing Optimization
                          </div>
                          <div>
                            <div
                              className="h-5 w-5 text-grey-500 cursor-pointer"
                              role="none"
                            >
                              <i className="i-alert-add text-xl" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">Media Buying</div>
                          <div>
                            <div
                              className="h-5 w-5 text-grey-500 cursor-pointer"
                              role="none"
                            >
                              <i className="i-alert-add text-xl" />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-8 leading-snug">
                          <div className="font-medium">
                            Performance Measurement
                          </div>
                          <div>
                            <div
                              className="h-5 w-5 text-grey-500 cursor-pointer"
                              role="none"
                            >
                              <i className="i-alert-add text-xl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex md:block mt-2 md:mt-4">
                        <h6 className="w-24 md:w-auto font-light mb-1">
                          Role Match
                        </h6>
                        <div className="grow text-sm">
                          <div className="flex justify-between items-center h-8 leading-snug">
                            <div className="font-medium">Paid Media</div>
                            <div>
                              <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-red-900">
                                <i className="i-close" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex md:block mt-2 md:mt-4">
                        <h6 className="w-24 md:w-auto font-light mb-1">
                          Vetting
                          <span className="hidden md:inline-block">Status</span>
                        </h6>
                        <div className="grow text-sm">
                          <div className="flex justify-between items-center h-8 leading-snug">
                            <div className="font-medium">Vetted</div>
                            <div>
                              <div className="w-5 h-5 rounded-full bg-grey-900 text-white flex justify-center items-center text-sm bg-green-900">
                                <i className="i-check" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PageMockup;
