import useCurrentMembership from './useCurrentMembership';

const useOwnsWorkPost = (work: WorkEntity | null | undefined) => {
  const { membership } = useCurrentMembership();
  const workPosterMembershipId = work?.membership?.id;
  const userMembershipId = membership?.id;
  return userMembershipId === workPosterMembershipId;
};

export default useOwnsWorkPost;
