import React from 'react';
import Page from '../components/core/Page';
import Welcome from '../components/user/views/Welcome';

export default function WelcomePage() {
  return (
    <Page
      layout="none"
      className="page-bg--white page--no-padding"
      pageTitle="Welcome"
      hideHeader
      authGuard
    >
      <Welcome />
    </Page>
  );
}
