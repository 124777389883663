import React, { useContext, useMemo } from 'react';
import { isMobile } from '../../utilities/Device';

import { mediaClasses as mc } from '../../utilities/MatchMedia';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import MembershipStaffSummary from './MembershipStaffSummary';
import ProfileSocialLinks from './ProfileSocialLinks';
import ToggleMembershipButton from './ToggleMembershipButton';

type Props = {
  membership: MembershipEntity;
  membershipUsers: MembershipUserEntity[];
};

const MembershipSidebarColumn = ({ membership, membershipUsers }: Props) => {
  const { isRoot, membershipId, membershipAccess } = useContext(UserContext);
  const canEditProfile =
    membership.id === membershipId && membershipAccess === 'admin';

  const membershipUsersIds = useMemo(() => {
    return membershipUsers.map(usmembershipUser => {
      return usmembershipUser.user.id;
    });
  }, [membershipUsers]);

  return (
    <>
      <hr className={mc('mb-8 lg:hidden')} />
      {canEditProfile && !isRoot && !isMobile ? (
        <div className="mb-4">
          <Link
            className="btn btn--full btn--primary btn--sm"
            to="#/edit-page/basic"
          >
            Edit Page
          </Link>
        </div>
      ) : (
        <ToggleMembershipButton
          membershipId={membership.id}
          isDeactivated={!!membership.expiresAt}
        />
      )}
      <MembershipStaffSummary membershipUsers={membershipUsers} />
      <ProfileSocialLinks
        socialLinks={membership.socialLinks}
        profileUserId={membershipUsersIds}
      />
    </>
  );
};
export default MembershipSidebarColumn;
