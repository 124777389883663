import React from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import {
  isAgencyProfileHidden,
  isFreelancerProfileHidden,
} from '../profileCompleteness/utils/profile';
import meetsWorkRequirements from '../work/utils/meetsWorkRequirements';
import ExternalCandidateScoreCard from './ExternalCandidateScoreCard';
import InternalCandidateScoreCard from './InternalCandidateScoreCard';
import MissingRequirementsCard from './MissingRequirementsCard';
import ScoreCardLayout from './ScoreCardLayout';
import ScoreHeading from './ScoreHeading';
import StudentCandidateScoreCard from './StudentCandidateScoreCard';

export type ScoreCardProps = {
  scoreMetrics: ScoreMetrics;
  isUser: boolean;
  work: WorkEntity;
  refresh: Function;
  isHidden: boolean;
};

type Props = CandidateScore & {
  scoreMetrics: ScoreMetrics;
  requirements: ApplicationRequirements;
  refresh: Function;
};

const CandidateScore = ({
  user,
  membership,
  work,
  scoreMetrics,
  requirements,
  refresh,
}: Props) => {
  const { isIndividualPlan } = useCurrentMembership();
  const name = isIndividualPlan
    ? `${user.firstName} ${user.lastName}`
    : membership.name;
  const imageUrl = isIndividualPlan ? user.avatarURL : membership.logoURL;
  const { percentage } = scoreMetrics;

  const isProfileHidden = isIndividualPlan
    ? isFreelancerProfileHidden
    : isAgencyProfileHidden;

  const isHidden = isProfileHidden();
  const meetsRequirements = meetsWorkRequirements(requirements);

  if (!meetsRequirements) {
    return (
      <ScoreCardLayout
        avatarName={name}
        avatarUrl={imageUrl}
        isIndividualPlan={isIndividualPlan}
      >
        <MissingRequirementsCard
          isUser={membership.plan.isIndividualPlan}
          requirements={requirements}
          work={work}
          scoreMetrics={scoreMetrics}
          isHidden={isHidden}
        />
      </ScoreCardLayout>
    );
  }

  if (work.type === 'Student') {
    return (
      <>
        <p className="mt-2">
          Completing your profile increases your Candidate Score, resulting in
          you appearing higher in the list of candidates applying for this
          opportunity.
        </p>
        <ScoreCardLayout
          avatarName={name}
          avatarUrl={imageUrl}
          isIndividualPlan={isIndividualPlan}
        >
          <StudentCandidateScoreCard
            isUser={isIndividualPlan}
            work={work}
            scoreMetrics={scoreMetrics}
            refresh={refresh}
            isHidden={isHidden}
          />
        </ScoreCardLayout>
      </>
    );
  }

  if (work.isExternal) {
    return (
      <>
        <ScoreCardLayout
          avatarName={name}
          avatarUrl={imageUrl}
          isIndividualPlan={isIndividualPlan}
        >
          <ExternalCandidateScoreCard
            isUser={isIndividualPlan}
            work={work}
            scoreMetrics={scoreMetrics}
            refresh={refresh}
            isHidden={isHidden}
          />
        </ScoreCardLayout>
      </>
    );
  }

  return (
    <>
      <ScoreHeading
        percentage={percentage}
        isUser={isIndividualPlan}
        isHidden={isHidden}
        showEyebrow
      />
      <ScoreCardLayout
        avatarName={name}
        avatarUrl={imageUrl}
        isIndividualPlan={isIndividualPlan}
      >
        <InternalCandidateScoreCard
          isUser={isIndividualPlan}
          work={work}
          scoreMetrics={scoreMetrics}
          refresh={refresh}
          isHidden={isHidden}
        />
      </ScoreCardLayout>
    </>
  );
};

export default CandidateScore;
