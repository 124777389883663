import React from 'react';
import NewDirectConversation from '../components/chat/views/NewDirectConversation';
import useAnalytics from '../hooks/useAnalytics';
import View, {
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';

const ChatNewDirectConversation = () => {
  useAnalytics('New Direct Message');

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New One-to-One Message</h6>
          <NewDirectConversation />
        </ViewContent>
      </>
    );

  return (
    <View authGuard darkStatusBar pageTitle="New One-to-One Message">
      <ViewHeader defaultBackHref="/chat">
        <ViewHeaderTitle>New One-to-One Message</ViewHeaderTitle>
      </ViewHeader>

      <ViewContent layout="chat">
        <NewDirectConversation />
      </ViewContent>
    </View>
  );
};

export default ChatNewDirectConversation;
