import { useEffect, useState } from 'react';
import WorkAudience from '../components/work/utils/workAudience';

const useWorkIsPosted = (work: WorkEntity | null): { isPosted: boolean } => {
  const [isPosted, setIsPosted] = useState(false);

  useEffect(() => {
    if (!work) {
      setIsPosted(false);
      return;
    }

    const { collections, audience } = work;
    setIsPosted(
      !(
        audience === WorkAudience.COLLECTION &&
        !!collections &&
        collections.length > 0 &&
        collections[0].type === 'bench'
      )
    );
  }, [work, setIsPosted]);

  return {
    isPosted,
  };
};

export default useWorkIsPosted;
