import React from 'react';
import validator from 'validator';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="externalURL"
    label="Event URL"
    placeholder="Enter URL"
    errors={errors}
    ref={register({
      required: 'Event URL is required',
      validate: (value: string) => validator.isURL(value) || 'Invalid url',
    })}
  />
);
