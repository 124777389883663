/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { MemoExoticComponent } from 'react';

import Portal from '../Portal';
import { ModalContextProvider } from './context/ModalContext';
import ModalOverlay from './components/ModalOverlay';
import ModalBody from './components/ModalBody';
import ModalContent from './components/ModalContent';
import ModalHeader from './components/ModalHeader';
import ModalFooter from './components/ModalFooter';

export type ModalProps = {
  isOpen: boolean;
  children: JSX.Element | null;
  title?: string | null;
  /** Provide a custom footer element */
  footer?: JSX.Element | JSX.Element[] | null;
  /** Provide a custom header element which will replace the default title header */
  header?: JSX.Element | null;
  /** Tailwind size for width of the modal */
  width?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
  /** Height (CSS) that the modal should be, defaults to 'auto' */
  height?: string;
  /** Indicates if the modal should be forced to full height */
  fullHeight?: boolean;
  /** Tailwind padding size for horizontal padding */
  paddingX?: string;
  /** Tailwind padding size for horizontal padding */
  paddingY?: string;
  /** Indicates if the modal component is responsible for scrolling modal content, defaults to true */
  enableScroll?: boolean;
  /** Indicates if the modal should display the close button in the header */
  showClose?: boolean;
  /** Indicates if the modal header should be displayed */
  showHeader?: boolean;
  /** Indicates if the modal back button should be shown */
  showBackButton?: boolean;
  /** Provide a custom back button */
  backButton?:
    | MemoExoticComponent<() => JSX.Element>
    | JSX.Element
    | JSX.Element[]
    | null;
  /** onOpen is fired after the modal has transitioned */
  onOpen?: Function;
  /** onWillClose is fired before the modal has transitioned */
  onWillClose?: Function;
  /** onClose is fired after the modal has transitioned */
  onClose?: Function;
};

const Modal = ({
  children,
  isOpen,
  title = null,
  footer = null,
  header = null,
  width = 'md',
  height = 'auto',
  fullHeight = false,
  paddingX = '4',
  paddingY = '4',
  enableScroll = true,
  showClose = true,
  showHeader = true,
  showBackButton = true,
  backButton,
  onOpen = () => {},
  onWillClose = () => {},
  onClose = () => {},
}: ModalProps) => {
  return (
    <Portal id="modal-portal">
      <ModalContextProvider isOpen={isOpen}>
        <ModalOverlay
          onOpen={onOpen}
          onClose={onClose}
          onWillClose={onWillClose}
        >
          <ModalContent fullHeight={fullHeight} width={width} height={height}>
            <ModalHeader
              title={title}
              showClose={showClose}
              showBackButton={showBackButton}
              showHeader={showHeader}
              customHeader={header}
              backButton={backButton}
            />

            <ModalBody
              enableScroll={enableScroll}
              paddingX={paddingX}
              paddingY={paddingY}
            >
              {children}
            </ModalBody>

            <ModalFooter>{footer}</ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </ModalContextProvider>
    </Portal>
  );
};
export default Modal;
