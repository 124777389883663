import { keyBy } from 'lodash';
import { IMember } from '../../../@types/chat.d';

type Props = {
  [sid: string]: IMember;
};

let Members: Props = {};

export const add = (members: IMember[]) => {
  Members = {
    ...Members,
    ...keyBy(members, 'sid'),
  };
};

export const remove = (members: IMember[]) =>
  members.forEach(member => delete Members[member.sid]);

export const update = (members: IMember[]) =>
  members.forEach(member => {
    Members[member.sid] = member;
  });

export const select = (sid: string) => Members[sid];
export const selectMany = (sids: string[]) => sids.map(sid => Members[sid]);

const MemberInstances = {
  add,
  remove,
  update,
  select,
  selectMany,
};

export default MemberInstances;
