import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from '../core/router';
import AppContext from '../../AppContext';

type Props = {
  title?: string;
  message?: string;
  btnLabel?: string;
};

export default ({
  title = '404',
  message = "We couldn't find that page...",
  btnLabel = 'Start over',
}: Props) => {
  const { pathname } = useLocation();
  const { setDocumentTitle } = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(title);
    return () => {
      setDocumentTitle(undefined);
    };
  }, [setDocumentTitle, title]);

  return (
    <div className="text-center mt-12">
      <h3 className="mb-8">Oops!</h3>
      <div className="mb-8">
        <p className="text-subdued mb-2">{message}</p>
        <p className="text-xs text-subdued">{pathname}</p>
      </div>
      <Link to="/">{btnLabel}</Link>
    </div>
  );
};
