import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    required
    name="title"
    label="Enter work title"
    placeholder="e.g. React specialist needed for e-commerce..."
    errors={errors}
    ref={register({
      required: 'Work title is required',
    })}
  />
);
