import React, { useCallback } from 'react';
import ProgressMeter from './ProgressMeter';
import Swiper from '../swiper/Swiper';
import ProgressStep from './ProgressStep';

const SLIDER_REFRESH_MS = 10;

type MeterStyle = 'circle' | 'slider';

declare type Props = {
  title: string;
  steps: ProfileStep[];
};

const ProfileMeter = ({ title, steps }: Props) => {
  const completedSteps = useCallback(() => {
    return steps.filter(step => {
      return step.complete;
    });
  }, [steps]);

  const remainingSteps = useCallback(() => {
    return steps.filter(step => {
      return !step.complete;
    });
  }, [steps]);

  const refreshSlider = (swiperInstance: any) => {
    setTimeout(() => {
      swiperInstance.update();
    }, SLIDER_REFRESH_MS);
  };

  return (
    <>
      {remainingSteps().length > 0 && (
        <div className="feed-profile-progress mb-8">
          <div className="px-8 py-6 pb-0 md:pb-6 bg-white rounded-md">
            <ProgressMeter
              title={title}
              total={steps.length}
              complete={completedSteps().length}
            />
            <Swiper
              navigation
              paginate
              className="pb-0 mt-4 relative profile-completeness-slider"
              items={remainingSteps().map(step => (
                <ProgressStep key={step.id} step={step} />
              ))}
              onReady={refreshSlider}
              swiperConfig={{
                slidesPerView: 1,
                freeMode: false,
                longSwipes: true,
                longSwipesRatio: 0.75,
                autoHeight: true,
                dynamicBullets: true,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileMeter;
