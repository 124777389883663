/**
 * Checks to see if there are updates available (browser only, app uses capacitor)
 *
 * - When app initially loads, get the latest build and store it
 * - Periodically ping to get the latest build, and if different:
 *    1. Trigger an "update available" event (TBD how to integrate message / notification / etc)
 *    2. Stop checking for updates
 *
 * This component should not disrupt the users' interaction with the app, but it should
 * be visible and obvious to the user that a newer version is available.
 *
 * The output MUST be wrapped in an IonHeader component
 *
 */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { isPlatform } from '@ionic/core';
import appInfo from './info';
import UpdateAvailable from './UpdateAvailable';

type AppInfo = {
  build: string;
  released: number; // epoch timestamp
};

type Response = {
  data: AppInfo;
};

const url = '/info.json';
let timer: NodeJS.Timer;
const interval = 60 * 1000;

const AppInfo = () => {
  const [update, setUpdate] = useState<AppInfo>();

  const handleError = () => {
    // ignore error for now. Reasons could be network error, netlify down, etc
    // console.log('error');
  };

  const handleRequest = useCallback(async () => {
    return axios
      .get(url)
      .then((response: Response) => response.data)
      .catch(handleError);
  }, []);

  const getLatest = useCallback(async () => {
    const info = await handleRequest();
    return info || null;
  }, [handleRequest]);

  const checkForUpdates = useCallback(async () => {
    const info = await getLatest();

    // Check if a newer (for now, different) version is available
    if (info && info.build !== appInfo.build) {
      // update available!
      setUpdate(info);

      // Trigger an "updateAvailable" event (AppContext listens for it so any component can be aware)
      document.dispatchEvent(
        new CustomEvent('updateAvailable', {
          bubbles: true,
          detail: {
            type: 'info',
            message: 'A newer version is available!',
          },
        })
      );

      // Stop checking for updates
      clearInterval(timer);
    }
  }, [getLatest]);

  const init = useCallback(async () => {
    // Capacitor has its own updater
    if (isPlatform('capacitor')) return;

    timer = setInterval(() => {
      checkForUpdates();
    }, interval);
  }, [checkForUpdates]);

  useEffect(() => {
    init();

    return () => {
      clearInterval(timer);
    };
  }, [init]);

  return update ? <UpdateAvailable /> : null;
};

export default React.memo(AppInfo);
