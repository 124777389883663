import React, { useEffect, useMemo } from 'react';
import Async from 'react-select/async';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import { isMobile } from '../../../utilities/Device';

const arrayMove = (array: Array<any>, from: number, to: number) => {
  // eslint-disable-next-line no-param-reassign
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

const DropdownIndicator = () => null;

const defaultOptionLabel = ({ label }: { label: string }) => label;

const Autocomplete = ({
  name,
  form,
  min = -1,
  max = -1,
  isMulti = false,
  isRequired = false,
  isCreatable = false,
  isDisabled = false,
  placeholder,
  defaultValues,
  formValue,
  defaultOptions = [],
  formatOptionLabel = defaultOptionLabel,
  callback,
  sortable = false,
}: AutocompleteProps & { formValue?: any }) => {
  const { setValue, getValues, register } = form;
  // const [values, setValues] = useState(defaultValues);

  const validate = useMemo(() => {
    return {
      min: (value: Array<{ value: string; label: string }> | undefined) => {
        if (min <= 0) {
          return true;
        }

        if (value != null && value?.length >= min) {
          return true;
        }

        if (min === 1) return `Add at least ${min} item`;

        return `Add at least ${min} items`;
      },
      max: (value: Array<{ value: string; label: string } | undefined>) => {
        // If max not set or we dont have any values yet
        if (max <= 0 || value == null) {
          return true;
        }

        if (value?.length <= max) {
          return true;
        }

        return `Max ${max} items allowed`;
      },
    };
  }, [min, max]);

  // register the form element
  useEffect(() => {
    // use type "custom" to make sure component re-renders
    register(
      { name, type: 'custom' },
      {
        required: isRequired && 'This item is required',
        validate: isMulti ? validate : true,
      }
    );
  }, [name, register, isRequired, isMulti, validate]);

  useEffect(() => {
    // set the default values (only called once)
    setValue(name, defaultValues);
    // eslint-disable-next-line
  }, []);

  const handleChange = (selected: any) => {
    setValue(name, selected || [], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const loadOptions = (inputValue: string) => callback(inputValue);

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (!inputValue) return null; // removes "No Options" empty dropdown
    return 'No results found. Try a new search.';
  };

  let Component: any = isCreatable ? AsyncCreatableSelect : Async;

  if (sortable) {
    Component = SortableContainer(Component);
  }

  const selectComponents: any = {};
  if (defaultOptions.length <= 0) {
    selectComponents.DropdownIndicator = DropdownIndicator;
  }

  if (sortable) {
    selectComponents.MultiValue = SortableElement((props: any) => {
      // this prevents the menu from being opened/closed when the user clicks
      // on a value to begin dragging it. ideally, detecting a click (instead of
      // a drag) would still focus the control and toggle the menu, but that
      // requires some magic with refs that are out of scope for this example
      const onMouseDown = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { onMouseDown };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <components.MultiValue {...props} innerProps={innerProps} />;
    });
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const sorted = arrayMove(
      (getValues()[name] as any[]) || [],
      oldIndex,
      newIndex
    );
    setValue(name, sorted, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // setValues(sorted);
  };

  const sortableProps = {
    axis: 'xy',
    distance: 4,
    onSortEnd,
    getHelperDimensions: ({ node }: { node: any }) =>
      node.getBoundingClientRect(),
  };

  return (
    <Component
      cacheOptions
      className="multiselect"
      classNamePrefix="multi"
      components={selectComponents}
      formatOptionLabel={formatOptionLabel}
      defaultOptions={defaultOptions}
      defaultValue={defaultValues}
      value={formValue}
      isDisabled={isDisabled || max === 0}
      isMulti={isMulti}
      loadOptions={loadOptions}
      menuShouldScrollIntoView
      menuPosition="absolute"
      noOptionsMessage={noOptionsMessage}
      openMenuOnFocus
      onChange={handleChange}
      placeholder={placeholder || (isMulti ? 'Type to search...' : 'Select...')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(sortable ? sortableProps : {})}
      maxMenuHeight={isMobile ? 200 : undefined}
    />
  );
};

export default Autocomplete;
