import React, { useMemo } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import ReferenceAverage from './ReferenceAverage';
import ReferenceRatingsNeedsUpgrade from './ReferenceRatingsNeedsUpgrade';

interface Props {
  ratings: Rating[];
  className?: string;
}

const ReferenceRatings = ({ ratings, className }: Props) => {
  const { checkAccess, isIndividualPlan, plan } = useCurrentMembership();
  const canAccess = checkAccess('canViewReferences');
  const planOrder = plan?.order ?? 0;
  const ratingValues = useMemo(() => {
    return ratings.reduce((accumulator, currentValue) => {
      const { attribute, value } = currentValue;

      if (Object.prototype.hasOwnProperty.call(accumulator, attribute)) {
        accumulator[attribute] += value;
      } else {
        accumulator[attribute] = value;
      }

      return accumulator;
    }, {} as any);
  }, [ratings]);

  if (isIndividualPlan && planOrder < 3) {
    return null;
  }

  if (!canAccess) {
    return (
      <div className={className}>
        <ReferenceRatingsNeedsUpgrade />
      </div>
    );
  }

  return (
    <div className={className}>
      {Object.entries(ratingValues).map((rating: any) => {
        const [key, value] = rating;
        // Values are based on an index 0-4 but we want to display scores as 1-5
        const displayValue = value + 1;

        return (
          <ReferenceAverage
            key={key}
            className="my-2"
            label={key}
            percentage={(displayValue / 5) * 100}
          />
        );
      })}
    </div>
  );
};
export default ReferenceRatings;
