import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import { SubmitButton } from '../../core/Button';
import { FormActions } from '../../core/form';
import Rate from '../formElements/Rate';

interface Inputs {
  rate: string | null;
}

const RateForm = () => {
  const { update, refresh, user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const form = useForm<Inputs>({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { handleSubmit } = form;
  const defaultRate = user?.rate ? `${user.rate}` : null;

  const onSubmit = useCallback(
    (values: Inputs) => {
      if (loading) return;
      setLoading(true);

      const userRate = values.rate ? parseFloat(values.rate) : null;

      update({ rate: userRate })
        .then(() => {
          addSuccess('Your rate has been updated!');
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
          refresh();
        });
    },
    [loading, refresh, update]
  );

  return (
    <div className="content">
      <p>
        We won’t publicly show this number - we’ll convert it to a range and
        number of $.
      </p>
      <p>
        All rates must be in USD. For assistance with conversion, use the{' '}
        <a
          href="https://www.google.com/search?q=google+currency+converter"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-link"
        >
          Google Currency Converter
        </a>
        .
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <Rate form={form} defaultValue={defaultRate} />
        </div>
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton loading={loading} text="Save Changes" />
          </div>
        </FormActions>
      </form>
    </div>
  );
};
export default RateForm;
