import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import UserContext from '../user/Context';
import Loading from '../core/Loading';
import { Error404 } from '../error';
import useWork from '../../hooks/useWork';
import useAnalytics from '../../hooks/useAnalytics';
import { Actions, Body, CoverPhoto, Meta } from './view/index';
import Footer from './view/Footer';
import PageView from '../../analytics/PageView';
import { TALENT_PLAN_SLUG } from '../../config';
import { DEFAULT_WORK_COVER_PHOTO_URL } from '../../asset-config';
import useNavigate from '../../hooks/useNavigate';
import WorkApplyNowCTA from './view/WorkApplyNowCTA';
import useMyApplication from '../../hooks/useMyApplication';
import useCurrentUser from '../../hooks/useCurrentUser';

type Props = {
  work: WorkEntity;
  loading: boolean;
  refreshWork: Function;
  qualification?: QualificationEntity | null;
  refreshQualification?: Function;
};

const Divider = ({ className }: { className?: string }) => (
  <hr className={`mt-12 ${className || ''}`} />
);

const WorkCoverPhoto = ({ work }: { work: WorkEntity }) => {
  const { id: userId } = useContext(UserContext);
  const navigate = useNavigate();

  const coverUrl: any =
    work.imageURL ||
    work.membership?.coverPhotoURL ||
    DEFAULT_WORK_COVER_PHOTO_URL;

  if (userId || work.redactionLevel === 0) {
    return <CoverPhoto imageUrl={coverUrl} alt={work.title || ''} />;
  }

  const handleWrapperClick = () => {
    navigate(`/signup?redirectUrl=/plans/${TALENT_PLAN_SLUG}`);
  };

  return (
    <div className="relative">
      <CoverPhoto imageUrl={coverUrl} alt={work.title || ''} />

      {/* eslint-disable-next-line */}
      <div
        onClick={handleWrapperClick}
        className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center cursor-pointer"
      >
        <WorkApplyNowCTA work={work} size="lg" />
      </div>
    </div>
  );
};

interface WorkInterface {
  updateApplications: boolean;
  setUpdateApplications(updateApplications: boolean): void;
}

const workInterface: WorkInterface = {
  updateApplications: false,
  setUpdateApplications() {},
};

export const WorkContext = React.createContext<WorkInterface>(workInterface);

const Work = ({
  work,
  loading,
  refreshWork,
  qualification = null,
  refreshQualification = () => {},
}: Props) => {
  const { id: userId, membershipId } = useCurrentUser();
  const { title, description } = work;
  const now = moment().utc();
  const expiry = moment.utc(work.expiresAt);
  const isClosed = now >= expiry;
  const [updateApplications, setUpdateApplications] = useState(false);

  const canManage: boolean = useMemo(
    () => userId === work.user?.id || work.membership?.id === membershipId,
    [userId, work, membershipId]
  );

  return (
    <WorkContext.Provider value={{ updateApplications, setUpdateApplications }}>
      <div className="work-view grid px-4 md:px-0">
        {!work.isExternal ? (
          <div className="work-view__header bg-white p-0 md:p-8 md:pb-0 mt-4 md:mt-0 rounded-t-2xl md:rounded-none">
            <WorkCoverPhoto work={work} />
          </div>
        ) : null}
        <div className="work-view__sidebar">
          <div className="relative bg-white md:mt-8 px-6 md:p-8 md:rounded-xl">
            <div className="z-10 absolute top-0 right-0 m-6 md:m-8">
              {canManage && <Actions work={work} isClosed={isClosed} />}
            </div>
            <Meta
              work={work}
              qualification={qualification}
              loading={loading}
              refreshWork={refreshWork}
              canManage={canManage}
            />
          </div>
        </div>
        <div className="md:pt-8 bg-white rounded-b-2xl">
          <Divider className="mx-6 md:hidden" />
          <Body title={title} description={description} />
          <div className="bg-white px-6 md:px-8 py-8 rounded-b-2xl">
            <Footer
              work={work}
              qualification={qualification}
              refreshQualification={refreshQualification}
              isClosed={isClosed}
            />
          </div>
        </div>
      </div>
    </WorkContext.Provider>
  );
};

const AuthWork = ({
  work,
  loading,
  refreshWork,
}: {
  work: WorkEntity;
  loading: boolean;
  refreshWork: Function;
}) => {
  const {
    data: qualification,
    refresh: refreshQualification,
  } = useMyApplication(work.id);

  return (
    <Work
      work={work}
      qualification={qualification}
      refreshQualification={refreshQualification}
      loading={loading}
      refreshWork={refreshWork}
    />
  );
};

const AuthCheckWork = ({
  work,
  loading,
  refreshWork,
}: {
  work: WorkEntity;
  loading: boolean;
  refreshWork: Function;
}) => {
  const { id } = useCurrentUser();

  if (id) {
    return <AuthWork work={work} loading={loading} refreshWork={refreshWork} />;
  }

  return <Work work={work} loading={loading} refreshWork={refreshWork} />;
};

const WorkView = ({ workId }: { workId: string }) => {
  const work = useWork(workId);
  const { pageIsReady } = useAnalytics();

  useEffect(() => {
    if (work.data) {
      const { data } = work;
      pageIsReady({
        pageType: PageView.WORK,
        pageId: data?.id,
        pageName: 'Work Posting',
        workId: data?.id,
        workTitle: data?.title,
        type: data?.type,
        description: data?.description,
        isRemote: data?.isRemote,
        budget: data?.price,
        location: data?.location.friendlyName,
        role: data?.role?.name,
        userId: data?.user.id,
        userName: `${data?.user.firstName} ${data?.user.firstName}`,
        userEmail: data?.user.email,
        membershipId: data?.membership?.id,
        membershipName: data?.membership?.name,
      });
    }
  }, [work.data, pageIsReady, work]);

  if (work.loading) return <Loading isActive />;

  if (!work.data)
    return (
      <Error404 title="Work 404" message="We couldn't find that work..." />
    );

  return (
    <AuthCheckWork
      work={work.data}
      loading={work.loading}
      refreshWork={work.refresh}
    />
  );
};

export default WorkView;
