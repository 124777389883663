import React from 'react';
import { FormInput } from '../../core/form';

export default ({ required, errors, register }: any) => (
  <FormInput
    required={required}
    name="lastName"
    label="Last name"
    placeholder="Enter last name"
    errors={errors}
    ref={register({
      required: 'Last name is required',
    })}
  />
);
