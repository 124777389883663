import React, { useState } from 'react';
import cloudinary from '../../utilities/Cloudinary';

type Props = {
  membership: MembershipEntity;
};

const CompanyPhoto = ({ imageURL, alt }: { imageURL: string; alt: string }) => {
  const [isError, setError] = useState(false);

  if (isError) {
    return null;
  }

  return (
    <img
      src={cloudinary(imageURL, ['w_500', 'q_70'])}
      alt={alt}
      className="rounded-md"
      onError={() => setError(true)}
    />
  );
};

const CompanyPhotosGrid = ({ membership }: Props) => {
  if (membership.imageURLs?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mt-12 flex justify-between items-baseline">
        <h4>This is {membership.name}</h4>
      </div>
      <section className="profile__photos mt-4">
        {membership.imageURLs?.map(imageURL => (
          <CompanyPhoto
            key={imageURL}
            imageURL={imageURL}
            alt={membership.name}
          />
        ))}
      </section>
    </>
  );
};
export default CompanyPhotosGrid;
