import React from 'react';
import { Link } from '../core/router';

declare type Props = {
  title: string;
};

export default ({ title }: Props) => (
  <div className="hero">
    <div
      className="hero__image"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/communo/image/upload/c_fill,f_auto,q_auto,w_2680/v1585184646/backgrounds/bg-gold.png)`,
      }}
    />

    <div className="p-sm md:p-md lg:p-lg">
      <Link to="/styleguide" className="text-link text-sm mb-4 block">
        <i className="i-arrow-left" />
        Styleguide
      </Link>

      <h1>Styleguide - {title}</h1>
      <p>This will be updated on an ongoing basis.</p>
    </div>
  </div>
);
