import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';
import FormDateTime from '../../core/form/FormDateTime';

interface Props {
  label?: string;
  format?: 'Year' | 'Month';
  form: any;
  defaultValue?: string; // YYY-MM-DD
}

const GraduationDate = ({
  label = 'Graduation Date',
  form,
  defaultValue,
  format = 'Month',
}: Props) => {
  const { errors, register, setValue } = form;
  const startDate = defaultValue ? moment(defaultValue).toDate() : undefined;

  const onChangeGraduationDate = useCallback(
    (value: Moment | null) => {
      let gradDate: string | null = null;

      if (value !== null) {
        gradDate = value.format('YYYY-MM-DD');
      }

      setValue('graduationDate', gradDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  return (
    <FormDateTime
      name="graduationDate"
      label={label}
      onChange={onChangeGraduationDate}
      showMonthYearPicker={format === 'Month'}
      showYearPicker={format === 'Year'}
      showTime={false}
      dateFormat={format === 'Month' ? 'MMMM yyyy' : 'yyyy'}
      errors={errors}
      required
      startDate={startDate}
      ref={register({
        required: 'A graduation date is required',
      })}
    />
  );
};
export default GraduationDate;
