import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { FormGroup } from '../../core/form';

import 'react-datepicker/dist/react-datepicker.css';

export default ({ errors, register, defaultValue, form }: any) => {
  const formatDate = (value: any) => moment(value).format('M/D/YYYY');
  const formatTime = (value: any) => moment(value).format('HH:mm:ss');
  const startDate = useMemo(
    () =>
      defaultValue
        ? moment(defaultValue).format()
        : moment()
            .add(1, 'day')
            .hour(9)
            .minute(0)
            .tz(moment.tz.guess())
            .format(),
    [defaultValue]
  );
  const { setValue } = form;
  const [date, setDate] = useState(formatDate(startDate));
  const [time, setTime] = useState(formatTime(startDate));
  const [dateTime, setDateTime] = useState(startDate);

  // TODO support changing timezone based on changing location and/or
  // manually selecting from a list of timezones

  useEffect(() => {
    setValue('expiresAt', startDate);
  }, [setValue, startDate]);

  useEffect(() => {
    const newDateTime = moment(new Date(`${date} ${time}`)).format();
    setValue('expiresAt', newDateTime);
    setDateTime(newDateTime);
  }, [date, time, setValue, setDateTime]);

  const handleChangeDate = (value: any) => {
    setDate(formatDate(value));
  };

  const handleChangeTime = (value: any) => {
    setTime(formatTime(value));
  };

  return (
    <FormGroup name="expiresAt" label="Event date / time" errors={errors}>
      <div className="row flex">
        <div className="col">
          <DatePicker
            id="expiresAt"
            className="form-input"
            selected={new Date(dateTime)}
            onChange={handleChangeDate}
            placeholderText="Date"
          />
        </div>
        <div className="col">
          <DatePicker
            className="form-input"
            selected={new Date(dateTime)}
            onChange={handleChangeTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            placeholderText="Time"
          />
        </div>
        {/* <div className="col self-center">{timezone}</div> */}
      </div>
      <input
        name="expiresAt"
        type="hidden"
        ref={register({
          required: 'Event date is required',
        })}
      />
    </FormGroup>
  );
};
