import React, { useState } from 'react';
import Modal from '../core/modal/Modal';
import { ColorChart } from '../core/ProgressBar';
import ScoreMeter from './ScoreMeter';

type Props = {
  showModal?: boolean;
  percentage: number;
  isHidden?: boolean;
  isUser: boolean;
  isStudent?: boolean;
  showSubText?: boolean;
  showEyebrow?: boolean;
  avatarComponent?: JSX.Element;
};

interface ProfileStatus extends ColorChart {
  message: JSX.Element;
  // subText: JSX.Element;
}

const hiddenAgencyProfileStatus: ProfileStatus = {
  message: (
    <h2>
      Your company page is currently{' '}
      <span className="text-red-900">hidden</span>
    </h2>
  ),
  // subText: (
  //   <div>
  //     Add your company information to make your page <span className="text-red-900">visible</span>.
  //   </div>
  // ),
  percent: 0,
  color: '#F05555',
  trackColor: '#4C4125',
  trackWidth: 12,
};

const agencyProfileStatus: ProfileStatus[] = [
  {
    message: (
      <h2>
        Your score is <span className="text-red-900">very low...</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     Complete your company page to <span className="text-red-900">improve</span> it.
    //   </div>
    // ),
    percent: 0,
    color: '#F05555',
    trackColor: '#4C4125',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score could be <span className="text-gold-900">better...</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     Add more information to <span className="text-red-900">improve</span> it.
    //   </div>
    // ),
    percent: 60,
    color: '#F4B831',
    trackColor: '#4C4125',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score is <span className="text-green-900">pretty good...</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     Add more info to make it even <span className="text-green-900">better</span>!
    //   </div>
    // ),
    percent: 80,
    color: '#74BA74',
    trackColor: '#2A4133',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score is <span className="text-green-900">excellent!</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     It look <span className="text-green-900">great</span>!
    //   </div>
    // ),
    percent: 100,
    color: '#74BA74',
    trackColor: '#2A4133',
    trackWidth: 12,
  },
];

const hiddenUserProfileStatus: ProfileStatus = {
  message: (
    <h2>
      Your profile is currently <span className="text-red-900">hidden</span>.
    </h2>
  ),
  // subText: (
  //   <div>
  //     Add more information to make it <span className="text-red-900">visible</span>.
  //   </div>
  // ),
  percent: 0,
  color: '#F05555',
  trackColor: '#4C4125',
  trackWidth: 12,
};

const userProfileStatus: ProfileStatus[] = [
  {
    message: (
      <h2>
        Your score is <span className="text-red-900">very low...</span>
      </h2>
    ),
    percent: 0,
    color: '#F05555',
    trackColor: '#4C4125',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score could be <span className="text-gold-900">better...</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     But you can <span className="text-gold-900">improve</span> it.
    //   </div>
    // ),
    percent: 60,
    color: '#F4B831',
    trackColor: '#4C4125',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score is <span className="text-green-900">pretty good...</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     We know you can <span className="text-green-900">finish</span> it!
    //   </div>
    // ),
    percent: 80,
    color: '#74BA74',
    trackColor: '#2A4133',
    trackWidth: 12,
  },
  {
    message: (
      <h2>
        Your score is <span className="text-green-900">excellent!</span>
      </h2>
    ),
    // subText: (
    //   <div>
    //     It looks <span className="text-green-900">great</span>!
    //   </div>
    // ),
    percent: 100,
    color: '#74BA74',
    trackColor: '#2A4133',
    trackWidth: 12,
  },
];

function getCurrentStatus(statuses: ProfileStatus[], percentage: number = 0) {
  return [...statuses].reverse().find(item => {
    return percentage >= item.percent;
  });
}

interface CompletionPercentageProps {
  percentage: number;
}

const CompletionPercentage = ({ percentage }: CompletionPercentageProps) => {
  return (
    <div className="w-full h-full flex flex-col justify-center text-center text-2xl">
      <div>
        {percentage}
        <span className="text-lg">%</span>
      </div>
    </div>
  );
};

const ScoreHeading = ({
  showModal = true,
  percentage,
  isHidden = true,
  isUser,
  showEyebrow = false,
  // showSubText = false,
  avatarComponent,
  isStudent = false,
}: Props) => {
  const [showHints, setShowHints] = useState(false);
  const toggleHints = (bool: boolean) => setShowHints(bool);
  const availableStatuses = isUser ? userProfileStatus : agencyProfileStatus;
  const hiddenStatus = isUser
    ? hiddenUserProfileStatus
    : hiddenAgencyProfileStatus;
  const profileStatus = isHidden
    ? hiddenStatus
    : getCurrentStatus(availableStatuses, percentage);

  if (!profileStatus) {
    return null;
  }

  return (
    <div className="bg-black text-white py-6 px-8">
      <div className="sm:flex">
        <div className="flex-auto pb-4 sm:pr-4 sm:pb-0">
          {showEyebrow ? (
            <div className="text-2xl">
              Candidate Score{' '}
              {showModal ? (
                <span
                  className="ml-4 text-grey-300 text-xl cursor-pointer leading-normal"
                  onClick={() => toggleHints(true)}
                  role="none"
                >
                  <i className="i-alert-warning" />
                </span>
              ) : null}
            </div>
          ) : null}

          <div>{profileStatus.message}</div>

          {/* {showSubText ? (
            <div className="mt-2">{profileStatus.subText}</div>
          ) : null} */}
        </div>
        {!isHidden ? (
          <div>
            <ScoreMeter
              percentage={percentage}
              size={20}
              colorChart={availableStatuses}
            >
              {avatarComponent || (
                <CompletionPercentage percentage={percentage} />
              )}
            </ScoreMeter>
          </div>
        ) : null}
      </div>
      {showModal ? (
        <Modal
          isOpen={showHints}
          title="What is your Candidate Score?"
          onClose={() => toggleHints(false)}
        >
          <>
            <div>
              <p className="mb-4">
                Completing your profile increases your Candidate Score,
                resulting in you appearing higher in the list of candidates
                applying for this job.
              </p>
              <p className="mb-4">
                Additional points are awarded to candidates matching the Role,
                or the Role Category, posted for the job.
              </p>
            </div>
            <div className="flex justify-end mt-6">
              <div
                className="btn btn--outline btn--tertiary btn--sm"
                role="none"
                onClick={() => toggleHints(false)}
              >
                Close
              </div>
            </div>
          </>
        </Modal>
      ) : (
        <p className="mt-2">
          {isStudent
            ? 'Completing your profile increases your Candidate Score and improves your listing position among the candidates applying for this opportunity.'
            : 'Completing your profile increases your Candidate Score, resulting in you appearing higher in the list of candidates applying for this opportunity.'}
        </p>
      )}
    </div>
  );
};
export default ScoreHeading;
