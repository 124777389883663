import React, { useEffect, useCallback, useState } from 'react';
import Select from 'react-select';
import { Perk } from '@communo-corp/shared-types';
import {
  Input,
  DropdownIndicator,
  ClearIndicator,
  Styles,
} from '../../select/Components';
import { FormGroup } from '../../core/form';
import perksApi from '../Api';
import { addResponseError } from '../../../services/Messaging';
import PerkCard from '../../card/PerkCard';

interface Option {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label: string;
  errors: any;
  defaultValues?: Option | Option[] | null;
  form: any;
}

const PerkPicker = ({ name, label, errors, defaultValues, form }: Props) => {
  const [perks, setPerks] = useState<Perk[]>([]);
  const [selected, setSelected] = useState<Perk[]>([]);
  const { register, setValue } = form;

  const getPerks = useCallback(() => {
    perksApi
      .list()
      .then((response: Perk[]) => {
        setPerks(response);
      })
      .catch(addResponseError);
  }, []);

  const setSelectedPerks = useCallback(
    (options: Option[]) => {
      const selectedPerkIds = options.map((perk: Option) => perk.value);
      const selectedPerks = perks.filter((perk: Perk) =>
        selectedPerkIds.includes(perk.id)
      );

      setSelected(selectedPerks);
      setValue(name, selectedPerkIds);
    },
    [name, perks, setValue]
  );

  useEffect(() => {
    getPerks();
    register({ name }, { required: 'This field is required' });
  }, [getPerks, name, register]);

  useEffect(() => {
    if (defaultValues && perks) {
      const defaults = Array.isArray(defaultValues)
        ? defaultValues
        : [defaultValues];

      setSelectedPerks(defaults);
    }
  }, [defaultValues, perks, setSelectedPerks]);

  function handleChange(data: any) {
    if (data == null) {
      setSelected([]);
      setValue(name, []);
      return;
    }

    setSelectedPerks(data);
  }

  return (
    <FormGroup name={name} label={label} errors={errors}>
      <Select
        isMulti
        options={perks.map((perk: Perk) => {
          const { id, descriptionSummary, membership } = perk;
          return {
            label: `${membership?.name || ''} - ${descriptionSummary}`,
            value: id,
          };
        })}
        defaultValue={defaultValues}
        onChange={handleChange}
        className="multiselect"
        classNamePrefix="multi"
        isSearchable
        components={{
          Input,
          DropdownIndicator,
          ClearIndicator,
        }}
        styles={Styles}
      />

      {selected && (
        <div className="grid lg:grid-cols-3 gap-4 mt-6">
          {selected.map(perk => (
            <PerkCard
              id={perk.id}
              key={perk.id}
              name={perk.name}
              company={perk.membership?.name}
              offer={perk.offerSummary}
              logoURL={perk.membership?.logoURL}
              imageURL={perk.horizontalHeaderImageUrl}
              description={perk.descriptionSummary}
            />
          ))}
        </div>
      )}
    </FormGroup>
  );
};
export default PerkPicker;
