export function defaultProjectOptionTransformer(projectOption: any) {
  return {
    value: projectOption.id,
    label: projectOption.name,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function groupProjectOptions(
  projectOptions: any[],
  projectOptionTransformer = defaultProjectOptionTransformer
) {
  if (!projectOptions.length) {
    return [];
  }

  const projectOptionsByCategory = projectOptions.reduce(
    (carry: any, projectOption: any) => {
      // If no category, set to uncategorized
      // eslint-disable-next-line no-param-reassign
      if (!projectOption.projectType)
        projectOption.projectType = { name: 'uncategorized' };

      const category = carry[projectOption.projectType.name] || [];
      category.push(projectOptionTransformer(projectOption));
      // eslint-disable-next-line no-param-reassign
      carry[projectOption.projectType.name] = category;
      return carry;
    },
    {}
  );

  return Object.keys(projectOptionsByCategory)
    .sort()
    .map(category => {
      return {
        label: category,
        options: projectOptionsByCategory[category],
      };
    });
}

export default {
  groupProjectOptions,
};
