import create, { SetState } from 'zustand';
import removeNullOrUndefined from '../../utilities/removeNullOrUndefined';

export interface ClientBranding {
  brandLogoURL: string | null;
  brandCoverPhotoURL: string | null;
  brandColor: string | null;
  ownerName: string | null;
  ownerImage: string | null;
}

interface ClientBrandingStore {
  clientBranding: ClientBranding | null;
  setClientBranding(partialClientBranding: Partial<ClientBranding>): void;
}

const [useClientBrandingStore] = create<ClientBrandingStore>(
  (set: SetState<ClientBrandingStore>) => ({
    clientBranding: {
      brandLogoURL: null,
      brandCoverPhotoURL: null,
      brandColor: null,
      ownerName: null,
      ownerImage: null,
    },
    setClientBranding: partialClientBranding => {
      // We want to keep our defaults if null passed
      const newBranding = removeNullOrUndefined(partialClientBranding);

      set(state => ({
        clientBranding: { ...state.clientBranding, ...newBranding },
      }));
    },
  })
);
export default useClientBrandingStore;
