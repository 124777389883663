import React from 'react';
import { animated, useSpring } from 'react-spring';

// const BackgroundGradient = ({ from, to }: { from: string; to: string }) => (
//   <Spring
//     config={{ delay: 0, duration: 300 }}
//     to={{
//       background: `linear-gradient(${from}, ${to})`,
//     }}
//   >
//     {(props: any) => (
//       <div className="fixed h-full w-full left-0 top-0 z-0" style={props} />
//     )}
//   </Spring>
// );

export const BackgroundColor = ({ color }: { color: string }) => {
  const style = useSpring({
    config: { delay: 0, duration: 300 },
    to: { backgroundColor: `${color}` },
  });

  return (
    <animated.div
      className="absolute h-full w-full left-0 top-0 z-0"
      style={style}
    />
  );
};

export default BackgroundColor;
