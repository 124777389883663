import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cloudinary from '../../utilities/Cloudinary';
import { srcSetDpiFunction } from '../../utilities/Image';
import Image from '../core/Image';

export type AvatarProps = {
  avatarName?: string | null;
  avatarUrl?: string | null;
  size?:
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | 'full'
    | 'fluid';
  isOnline?: boolean;
  isCentered?: boolean;
  logoUrl?: string | null;
  logoName?: string | null;
  borderWidth?: number;
  borderColor?: string;
  square?: boolean;
  circle?: boolean;
  onClose?: Function | null;
  className?: string | null;
  draggable?: boolean;
  children?: React.ReactNode;
};

const Avatar = ({
  avatarName = null,
  avatarUrl = null,
  size = 'sm',
  isOnline = false,
  isCentered = false,
  logoUrl = null,
  logoName = null,
  borderWidth = 0,
  borderColor = 'white',
  square = false,
  circle = true,
  onClose = null,
  className = null,
  draggable = true,
  children,
}: AvatarProps) => {
  const imageSizes = {
    xxs: '32',
    xs: '40',
    sm: '80',
    md: '100',
    lg: '140',
    xl: '200',
    '2xl': '320',
    '3xl': '400',
    full: '800',
    fluid: '800',
  };

  const avatarNameStr = avatarName || '';
  const nameParts = avatarNameStr.toUpperCase().split(' ');
  const initials =
    (nameParts[0] || '').charAt(0) + (nameParts[1] || '').charAt(0);

  const isMounted = useRef(false);
  const [attemptedUrl, setAttemptedUrl]: any = useState();
  const updateAttemptedUrl = useCallback(url => {
    if (!isMounted.current) {
      return;
    }

    setAttemptedUrl(url);
  }, []);

  useEffect(() => {
    isMounted.current = true;

    updateAttemptedUrl(avatarUrl);

    return () => {
      isMounted.current = false;
    };
  }, [avatarUrl, updateAttemptedUrl]);

  const classes = useMemo(() => {
    const classNames = ['avatar', `avatar--${size}`, 'h-full', 'w-full'];
    if (circle) {
      classNames.push('avatar--circle');
    }
    if (isOnline) {
      classNames.push('avatar--online');
    }
    if (borderWidth) {
      classNames.push(`border-${borderWidth} border-${borderColor}`);
      // purgecss: border border-0 border-2 border-4 border-5 border-6 border-7 border-8
    }
    if (square) {
      classNames.push('avatar--square');
    }
    if (isCentered) {
      classNames.push('mx-auto');
    }
    if (className) {
      classNames.push(className);
    }
    classNames.push('bg-grey-500');

    return classNames.join(' ');
  }, [
    borderColor,
    borderWidth,
    circle,
    className,
    isCentered,
    isOnline,
    size,
    square,
  ]);

  return (
    <div className={classes}>
      {attemptedUrl ? (
        <Image
          srcSet={srcSetDpiFunction(attemptedUrl, imageSizes[size])}
          sizes={size ? `${imageSizes[size]}px` : undefined}
          src={cloudinary(attemptedUrl, ['w_64'])}
          alt={avatarNameStr}
          onError={() => updateAttemptedUrl(null)}
          draggable={draggable}
          className="avatar-container min-w-full min-h-full"
        />
      ) : (
        <span className="avatar__initials">{initials}</span>
      )}
      {onClose ? (
        <button
          type="button"
          className="avatar__remove"
          onClick={() => onClose()}
        >
          <i className="i-close" />
        </button>
      ) : null}
      {logoUrl && (
        <div className="avatar__logo">
          <img src={logoUrl} alt={logoName || ''} />
        </div>
      )}
      {children}
    </div>
  );
};
export default React.memo(Avatar);
