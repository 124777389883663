import React, { useState } from 'react';
import SuggestedCandidateResults from './SuggestedCandidateResults';
import SuggestedCandidateSidebar from '../sidebar/SuggestedCandidateSidebar';
import { ScoredCandidate } from './SuggestedCandidatesProvider';

type SearchListProps = {
  suggestedCandidates: ScoredCandidate[];
  work: WorkEntity;
};

/**
 * Returns the list of filtered suggested candidates
 * @param param0.suggestedCandidates - the candidates to display
 */
export default function SuggestedCandidateSearchList({
  suggestedCandidates,
  work,
}: SearchListProps) {
  const [clickedCandidate, setClickedCandidate] = useState<ScoredCandidate>();

  function handleSelect(candidate: ScoredCandidate) {
    setClickedCandidate(candidate);
  }

  return (
    <section>
      <main className="flex mx-auto w-9/10">
        {suggestedCandidates.length > 0 ? (
          <>
            <SuggestedCandidateResults
              onSelect={handleSelect}
              candidates={suggestedCandidates}
            />
            {!!clickedCandidate?.id && (
              <SuggestedCandidateSidebar
                candidate={clickedCandidate}
                work={work}
              />
            )}
          </>
        ) : (
          <p className="justify-self-center">
            Sorry, we are unable to find candidates that are qualified at this
            point in time. Please try again later.
          </p>
        )}
      </main>
    </section>
  );
}
