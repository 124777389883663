import { CollectionSummary } from '@communo-corp/shared-types';
import { capitalize } from 'lodash';
import React, { useCallback } from 'react';
import useNavigate from '../../../hooks/useNavigate';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Alert from '../../alert/Alert';
import Api from '../Api';

type Props = {
  collection: CollectionSummary;
  isOpen: boolean;
  onClose: Function;
};
const DeleteCollection = ({ collection, isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const onConfirmDelete = useCallback(() => {
    Api.delete(collection.id)
      .then(() => {
        addSuccess('List has been deleted.');
        onClose();

        navigate('/lists');
      })
      .catch(err => {
        addResponseError(err);
      });
  }, [collection.id, navigate, onClose]);

  const displayType = capitalize(collection.type);

  return (
    <Alert
      isOpen={isOpen}
      header={`Delete ${displayType}?`}
      message={`<p>Deleting this ${collection.type} will affect all users who have access to the ${collection.type}.
      <br /><br />
      Are you sure you want to delete this ${collection.type}?</p>`}
      onDidDismiss={onClose}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-secondary-btn mr-auto',
          handler: onClose,
        },
        {
          text: `Delete ${displayType}`,
          cssClass: 'alert-primary-btn',
          handler: onConfirmDelete,
        },
      ]}
    />
  );
};
export default DeleteCollection;
