import React from 'react';

type Props = {
  text: string;
  max: number;
  warningCount?: number;
  onMaxCount?: Function;
  className?: string;
};

const CharacterCounter = ({
  text,
  max,
  warningCount = 15,
  onMaxCount = () => {},
  className = '',
}: Props) => {
  const count = text.length;
  const remaining = max - count;
  let classNames = className || '';

  if (remaining < 0) {
    if (onMaxCount) onMaxCount();
    classNames += ' text-danger-1100 font-bold text-base';
  } else if (remaining <= warningCount) {
    classNames += ' text-warning-1100 font-bold text-base';
  }

  // return <span className={classNames}>{`${count} / ${max}`}</span>;
  return (
    <div className={`text-sm font-bold leading-none ${classNames}`}>
      {remaining}
    </div>
  );
};

export default CharacterCounter;
