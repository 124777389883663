import { School } from '@communo-corp/shared-types';
import React, { useCallback, useContext, useEffect } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import useIsFormValid from '../../../hooks/useIsFormValid';
import { FormGroup, FormInput } from '../../core/form';
import SearchContext from '../../search/Context';
import useStep from '../../stepper/hooks/useStep';
import { SubmitRecommendationContext } from '../context/SubmitRecommendationContext';

const RecommendationOrganizationStep = () => {
  const { setCanAdvance } = useStep();
  const { getSchools } = useContext(SearchContext);
  const { form } = useContext(SubmitRecommendationContext);

  const isValidForm = useIsFormValid(form, ['organization', 'title']);

  useEffect(() => {
    setCanAdvance(isValidForm);
  }, [form, setCanAdvance, isValidForm]);

  useEffect(() => {
    form.register(
      { name: 'organization', type: 'custom' },
      {
        required: 'A School or Organization is required',
      }
    );
  }, [form]);

  const onChangeSchool = useCallback(
    (school: any) => {
      form.setValue('school', school);
      form.setValue('organization', school.value);
    },
    [form]
  );

  const school = form.watch('school');

  return (
    <div>
      <FormGroup
        className="mt-2"
        name="organization"
        label="School/Organization"
        errors={form.errors}
        required
      >
        <AsyncCreatable
          name="organization"
          allowCreateWhileLoading
          formatCreateLabel={input => input}
          createOptionPosition="last"
          onChange={onChangeSchool}
          getNewOptionData={input => ({
            value: input,
            label: input,
          })}
          defaultValue={
            school
              ? {
                  value: school.value,
                  label: school.label,
                }
              : null
          }
          defaultOptions
          loadOptions={async input => {
            const { hits } = await getSchools(input);

            return hits.map((schoolHit: School) => ({
              value: schoolHit.id,
              label: schoolHit.name,
            }));
          }}
          placeholder="Type to search..."
          className="multiselect"
          classNamePrefix="multi"
        />
      </FormGroup>
      <FormInput
        name="department"
        label="Department"
        labelSubtext="(optional)"
        className="mt-2"
        errors={form.errors}
        ref={form.register()}
      />
      <FormInput
        name="title"
        label="Title/Position"
        className="mt-2"
        errors={form.errors}
        required
        ref={form.register({
          validate: (val: any) => !!val,
          required: 'Title/Position is required',
        })}
      />
    </div>
  );
};
export default RecommendationOrganizationStep;
