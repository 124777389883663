import React, { useContext } from 'react';
import NewGroupConversation from '../components/chat/views/NewGroupConversation';
import useAnalytics from '../hooks/useAnalytics';
import NewGroupChatContext from '../components/chat/context/ChatNewGroupContext';
import { Link } from '../components/core/router';
import View, {
  ViewContent,
  ViewFooter,
  ViewHeader,
  ViewHeaderTitle,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import BackButton from '../components/core/BackButton';

export default () => {
  useAnalytics('New Group Message');
  const { members } = useContext(NewGroupChatContext);

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New Group</h6>
          <NewGroupConversation />
        </ViewContent>
        <ViewFooter className="actions">
          <BackButton
            className="btn btn--primary btn--sm btn--outline"
            defaultHref="/chat"
          />
          <Link
            className="btn btn--sm btn--primary ml-4"
            to="/chat/new-group-message/settings"
            disabled={members.length === 0}
          >
            Next
          </Link>
        </ViewFooter>
      </>
    );

  const NextLink = () => (
    <Link
      className="btn btn--sm btn--primary btn--rev"
      to="/chat/new-group-message/settings"
      disabled={members.length === 0}
    >
      Next
    </Link>
  );

  return (
    <View authGuard darkStatusBar pageTitle="New Group">
      <ViewHeader defaultBackHref="/chat" end={<NextLink />}>
        <ViewHeaderTitle>New Group</ViewHeaderTitle>
      </ViewHeader>
      <ViewContent layout="chat">
        <NewGroupConversation />
      </ViewContent>
    </View>
  );
};
