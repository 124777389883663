import React, { useContext, useEffect } from 'react';
import { useCookie } from 'react-use';
import useNavigate from '../../hooks/useNavigate';
import cloudinary from '../../utilities/Cloudinary';
import useCollectionInvite from '../collections/hooks/useCollectionInvite';
import Button from '../core/Button';
import { LoadingBalls } from '../core/Loading';
import Brand from '../core/Brand';
import UserContext from '../user/Context';
import { SOURCE_COOKIE } from '../cookie/SourceCookie';
import useClientBrandingStore from '../../hooks/stores/useClientBrandingStore';
import SignupLayout from '../user/signup/SignupLayout';

export const INVITE_ID_COMMUNO_COOKIE = 'communo-inviteId';

const CollectionInviteLanding = ({ inviteId }: { inviteId: string }) => {
  const { id: userId, setShowOnboarding } = useContext(UserContext);
  const navigate = useNavigate();
  const [, setInviteIdCookie] = useCookie(INVITE_ID_COMMUNO_COOKIE);
  const [, setSourceCookie] = useCookie(SOURCE_COOKIE);
  const { invite } = useCollectionInvite(inviteId);
  const clientBranding = useClientBrandingStore(state => state.clientBranding);
  const setClientBranding = useClientBrandingStore(
    state => state.setClientBranding
  );

  useEffect(() => {
    if (!invite) {
      return;
    }

    if (invite.status === 'accepted') {
      navigate('/feed');
    } else {
      setClientBranding({
        brandLogoURL: invite.brandLogoURL,
        brandCoverPhotoURL: invite.brandCoverPhotoURL,
        brandColor: invite.brandColor,
        ownerName: invite.ownerName,
        ownerImage: invite.ownerImage,
      });
    }
  }, [invite, navigate, setClientBranding]);

  if (!invite) {
    return <LoadingBalls isActive />;
  }

  const sourceCookieValue = `${invite.collectionType}:${invite.collectionId}`;

  const handleClick = () => {
    let path = '/feed';

    if (!userId) {
      path = `/get-started`;
      setSourceCookie(sourceCookieValue, { expires: 86400 * 30, path: '/' });
    }

    setInviteIdCookie(inviteId, { expires: 86400 * 30, path: '/' });
    setShowOnboarding(true);

    navigate(path);
  };

  const ownerName = clientBranding?.ownerName;
  const ownerImage = clientBranding?.ownerImage;

  const headingText =
    invite.collectionType === 'bench'
      ? `Join ${ownerName}’s list of trusted contractors`
      : `Join ${ownerName}’s list - ${invite.collectionName}`;

  return (
    <SignupLayout
      headerImg={clientBranding?.brandCoverPhotoURL}
      logoImg={clientBranding?.brandLogoURL}
      theme="light"
    >
      <div className="flex flex-col flex-1 justify-between md:justify-start text-center overflow-hidden">
        <div className="flex-1 md:flex-none">
          {ownerImage ? (
            <img
              src={cloudinary(ownerImage, ['w_250', 'h_250'])}
              className="mt-20 w-64 h-64 inline-block"
              alt={ownerName || ''}
            />
          ) : (
            <Brand variant="logo" size="6xl" />
          )}

          <h3 className="mt-8 font-bold">{headingText}</h3>

          <p className="mt-4 text-lg text-black">Powered by COMMUNO</p>
        </div>

        <div className="mt-auto md:mt-8 text-center">
          <Button
            onClick={handleClick}
            fullWidth
            color="primary"
            fill="solid"
            className="md:w-auto"
          >
            Join the {invite.collectionType}!
          </Button>
        </div>
      </div>
    </SignupLayout>
  );
};
export default CollectionInviteLanding;
