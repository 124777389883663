import React from 'react';
import { Company, Url } from '.';
import ApplicationMethod from './ApplicationMethod';
import Logo from './Logo';

type Props = {
  form: any;
  defaultValues?: Partial<WorkEntity> | undefined;
};

export default ({ form, defaultValues }: Props) => {
  const { errors, register } = form;

  return (
    <div className="form-section border-t-0">
      <h4 className="form-section__header">Company</h4>

      <Company errors={errors} register={register} />
      <Logo
        errors={errors}
        register={register}
        form={form}
        defaultValue={defaultValues?.logoURL}
        square
      />
      <Url errors={errors} register={register} />
      <ApplicationMethod errors={errors} register={register} />
    </div>
  );
};
