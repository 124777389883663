import React from 'react';
import Button from '../../core/Button';
import Modal from '../../core/modal/Modal';
import ShareableLinks from '../ShareableLinks';

type Props = {
  modalTitle: string;
  modalFormLabel: string;
  userId: string;
  work: WorkEntity;
  showModal: boolean;
  toggleModal: (bool: boolean) => void;
};

const ShareWorkModal = ({
  modalTitle,
  modalFormLabel,
  userId,
  work,
  showModal,
  toggleModal,
}: Props) => {
  return (
    <Modal
      isOpen={showModal}
      onClose={() => toggleModal(false)}
      title={modalTitle}
      width="xl"
    >
      <div className="min-h-screen50 justify-between flex flex-col">
        <ShareableLinks work={work} user={userId} formLabel={modalFormLabel} />
        <div className="flex justify-end mt-6">
          <Button
            fill="outline"
            color="tertiary"
            size="sm"
            onClick={() => toggleModal(false)}
            text="Close"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShareWorkModal;
