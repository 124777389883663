import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    required
    name="companyName"
    label="Company name"
    placeholder="Enter name"
    errors={errors}
    ref={register({
      required: 'Company name is required',
    })}
  />
);
