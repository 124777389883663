import React, { useContext } from 'react';
import { isMobile } from '../../utilities/Device';

import { mediaClasses as mc } from '../../utilities/MatchMedia';
import Avatar from '../avatar/Avatar';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import ProfileAccreditation from './ProfileAccreditation';
import ProfileEducation from './ProfileEducation';
import ProfileReference from './ProfileReference';
import ProfileSocialLinks from './ProfileSocialLinks';

type Props = {
  user: UserEntity;
  forceMobile?: boolean;
};

const EmployeeSidebarColumn = ({ user }: Props) => {
  const membership = user?.primaryMembershipUser?.membership;

  const { id: currentUserId, isRoot } = useContext(UserContext);
  const canEditProfile = user.id === currentUserId;

  return (
    <>
      <hr className={mc('mb-8 lg:hidden')} />
      {canEditProfile && !isRoot && !isMobile ? (
        <div className="mb-4">
          <Link
            className="btn btn--full btn--primary btn--sm"
            to="#/edit-profile/basic"
          >
            Edit Profile
          </Link>
        </div>
      ) : null}
      {membership ? (
        <>
          <Link to={`/members/membership/${membership.id}`}>
            <Avatar
              avatarUrl={membership?.logoURL}
              avatarName={membership.name}
              size="lg"
              className="w-32 h-32 rounded-md overflow-hidden"
              square
            />
          </Link>

          <p className="text-xl font-semibold mt-8">
            <Link to={`/members/membership/${membership.id}`}>
              {membership?.name}
            </Link>
          </p>
          <p className="mb-8">{membership?.location?.friendlyName}</p>
        </>
      ) : null}
      <ProfileReference user={user} />
      <ProfileEducation user={user} />
      <ProfileAccreditation user={user} />
      <ProfileSocialLinks
        socialLinks={user.socialLinks}
        profileUserId={user.id}
      />
    </>
  );
};
export default EmployeeSidebarColumn;
