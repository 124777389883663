import React, { useContext, useCallback, useMemo } from 'react';

import SearchContext from '../../search/Context';
import WorkSearchList from './WorkSearchList';
import MembershipContext from '../../membership/Context';
import getWorkFilterOptions from './WorkSearchFilters';
import Loading from '../../core/Loading';
import useSearchProvider from '../../search/useSearchProvider';
import UserContext from '../../user/Context';
import useRoles from '../../../hooks/useRoles';
import useProjectTypes from '../../../hooks/useProjectTypes';

const now = Math.round(new Date().getTime() / 1000);

export default () => {
  const { user } = useContext(UserContext);
  const { membership } = useContext(MembershipContext);
  const searchContext = useContext(SearchContext);
  const { getWork } = searchContext;

  const roles = useRoles();
  const { projectOptions } = useProjectTypes();

  const getFilterOptions = useCallback(
    (results: any) => {
      return getWorkFilterOptions(
        results,
        membership,
        roles,
        projectOptions || []
      );
    },
    [membership, roles, projectOptions]
  );

  const collectionFilters = [
    `(NOT audience:collection)`,
    `membership.id:${membership?.id}`,
  ];

  const collections = user?.collections ?? [];
  const collectionIdsQuery = collections
    .map(collection => `collectionIds:${collection.collectionId}`)
    .join(' OR ');
  if (collections.length > 0) {
    collectionFilters.push(`(${collectionIdsQuery})`);
  }

  const defaultFilters = [
    `(expiresAtTimestamp > ${now})`,
    collectionFilters.join(' OR '),
    'NOT type:student',
  ];

  const facetQueryOverrides = useMemo(() => {
    return {
      defaultFilters: [defaultFilters.join(' AND ')],
      facets: ['projectOption.name', 'isRemote', 'type', 'role.name'],
    };
  }, [defaultFilters]);

  const {
    allResults,
    searchResults,
    onLoadMore,
    readyToSearch,
    filterContext,
  } = useSearchProvider({
    getFilterOptions,
    searchFunction: getWork,
    facetQueryOverrides,
    shouldSearch: !!membership,
  });

  if (!readyToSearch) {
    return <Loading isActive />;
  }

  return (
    <WorkSearchList
      filterContext={filterContext}
      work={allResults}
      searchResults={searchResults}
      onLoadMore={onLoadMore}
    />
  );
};
