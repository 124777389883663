import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../Context';
import { LoadingBalls } from '../../core/Loading';
import { parseResponseError } from '../../../services/Messaging';
import useNavigate from '../../../hooks/useNavigate';
import SignupLayout from '../signup/SignupLayout';

type Props = {
  code: string;
  direct?: boolean;
};

export default ({ code, direct = false }: Props) => {
  const navigate = useNavigate();
  const { authenticate, redirectUrl } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const determineRedirect = useCallback(() => {
    if (direct && redirectUrl) {
      // For 'direct' navigation, take user directly to page and replace history
      navigate(redirectUrl, 'none', 'replace');
    } else if (redirectUrl) {
      // navigate first to the feed, just in case the redirectUrl
      // is a sub-page of a tab, and their back button takes them
      // back to login, rather than somewhere useful.
      navigate('/feed', 'none', 'replace');
      if (redirectUrl) navigate(redirectUrl, 'none', 'push');
    } else {
      navigate('/feed', 'none', 'replace');
    }
  }, [direct, navigate, redirectUrl]);

  const handleLogin = useCallback(async () => {
    try {
      await authenticate({ magicLinkCode: code });
      determineRedirect();
    } catch (error) {
      setErrorMessage(
        parseResponseError(error) ||
          "We could't log you in with this magic link."
      );
      /**
       * If we couldn't log them in, redirect to the desired page anyway so in the case that they are
       * trying to view a work post, they will just see a redacted version.
       */
      if (redirectUrl) {
        determineRedirect();
      } else {
        setLoading(false);
      }
    }
  }, [authenticate, code, determineRedirect, redirectUrl]);

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <LoadingBalls isActive={loading} fullscreen message="Authenticating..." />
    );
  }

  return (
    <SignupLayout>
      <>
        <div className="text-center">
          <h3 className="mb-4">Magic fail.</h3>
          <div className="text-lg font-light mb-8">Hmm.. {errorMessage}</div>
          <div>
            <Link to="/" className="text-red-900 font-medium">
              Try again
            </Link>
          </div>
        </div>
      </>
    </SignupLayout>
  );
};
