function truncateNoWordSplit(note: string, maxTruncationLength: number) {
  // No need to truncate if note length is less than maximum
  if (note.length <= maxTruncationLength) {
    return { truncatedNote: note, isTruncated: false };
  }

  // an array of the the note which first creates a substring
  // this substring can end up cutting off part of a word
  const indiscriminateNoteArr = note
    .substring(0, maxTruncationLength)
    .split(' ');

  // an array of the note which is the same length as previous array
  // however, this will only involve full words, since no substring is created
  const noteArr = note.split(' ').slice(0, indiscriminateNoteArr.length);

  // check to see if the word at the final index is a full word or not
  // if it isn't, then we need to remove that word to ensure only full words while respecting truncation length
  if (
    indiscriminateNoteArr[indiscriminateNoteArr.length - 1] !==
    noteArr[noteArr.length - 1]
  ) {
    noteArr.pop();
  }

  // combine back together and trim any whitespace
  const truncatedNote = `${noteArr.join(' ').trim()}...`;

  return { truncatedNote, isTruncated: true };
}

export default truncateNoWordSplit;
