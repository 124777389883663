import { CollectionSummary } from '@communo-corp/shared-types';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useCollection(
  collectionId: string
): {
  isLoading: boolean;
  collection: CollectionSummary;
  refetch: Function;
} {
  const { data, isLoading, refetch } = useQuery(
    ['collection', collectionId],
    () => Api.retrieve(collectionId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    collection: data,
    refetch,
  };
}
