import jwtDecode from 'jwt-decode';
import cn from 'classnames';
import { parse } from 'querystringify';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import useAnalytics from '../../hooks/useAnalytics';
import usePageViews from '../../hooks/usePageViews';
import useWork from '../../hooks/useWork';
import useWorkApplications from '../../hooks/useWorkApplications';
import { isDesktop, isMobile } from '../../utilities/MatchMedia';
import { appUrl } from '../../utilities/Url';
import { LoadingBalls } from '../core/Loading';
import ListWorkApplications from './ListWorkApplications';
import UnreadCandidateMessages from './UnreadCandidateMessages';
import { ENABLE_PUBLIC_CANDIDATE_PAGE } from '../../config';
import useOwnsWorkPost from '../../hooks/useOwnsWorkPost';
import CandidatesHeader from './CandidatesHeader';
import ShareShortlist from './ShareShortlist';

type Props = {
  workId: string;
  status: ApplicationStatus;
};

type WorkInfo = {
  work: WorkEntity | null;
  pageViews: PageView | null;
  applications: KeyedApplications;
  publicUrl: string | null;
  isPublicUrlExpired: boolean;
  doCreatePublicUrl: () => void;
  doDeletePublicUrl: () => void;
};

type Params = {
  applicationId?: string;
  status?: string;
};

const WorkInfo = ({
  work,
  pageViews,
  applications,
  publicUrl,
  isPublicUrlExpired,
  doCreatePublicUrl,
  doDeletePublicUrl,
}: WorkInfo) => {
  if (!work) return <LoadingBalls isActive fullscreen />;

  return (
    <div className="flex justify-between mt-8 mb-6">
      <CandidatesHeader work={work} pageViews={pageViews} />

      <div className="md:block ml-4">
        <div className="flex flex-col flex-1 items-end">
          <UnreadCandidateMessages applications={applications} />
        </div>
      </div>

      <div className={isMobile ? 'md:hidden' : ''}>
        {ENABLE_PUBLIC_CANDIDATE_PAGE && (
          <ShareShortlist
            publicUrl={publicUrl}
            isPublicUrlExpired={isPublicUrlExpired}
            doCreatePublicUrl={doCreatePublicUrl}
            doDeletePublicUrl={doDeletePublicUrl}
          />
        )}
      </div>
    </div>
  );
};

const Candidates = ({ workId, status }: Props) => {
  const { search, state: updateApplications } = useLocation();
  const { data, loading, refresh } = useWork(workId);
  const ownsWorkPost = useOwnsWorkPost(data);

  const {
    state,
    refresh: refreshApplications,
    patch,
    createPublicUrl,
    deletePublicUrl,
    hire,
    reject,
  } = useWorkApplications(workId);
  const applications = state.data;
  const pageViews = usePageViews('work', workId);
  const { applicationId } = parse(search) as Params;
  useAnalytics('Work Candidates', { workId });

  useEffect(() => {
    if (updateApplications === true) refreshApplications(workId);
  }, [updateApplications, refreshApplications, workId]);

  const selected = useMemo(() => state.data[applicationId || ''] || null, [
    applicationId,
    state.data,
  ]);

  const isPublicUrlExpired = useMemo(() => {
    if (!data?.magicPublicJwt) {
      return false;
    }

    const decodedAccessToken: DecodedAccessToken = jwtDecode(
      data.magicPublicJwt
    );

    // Get current time in epoch seconds.
    const now = Math.floor(Date.now() / 1000);
    return decodedAccessToken.exp < now;
  }, [data]);

  const publicUrl: string | null = useMemo(() => {
    if (!data?.magicPublicJwt) return null;

    return appUrl(
      `/work/${workId}/candidates/public/list/shortlisted?magicKey=${data.magicPublicJwt}`
    );
  }, [data, workId]);

  const doCreatePublicUrl = useCallback(async () => {
    await createPublicUrl();
    refresh();
  }, [createPublicUrl, refresh]);

  const doDeletePublicUrl = useCallback(async () => {
    await deletePublicUrl();
    refresh();
  }, [deletePublicUrl, refresh]);

  if (loading) {
    return <LoadingBalls isActive />;
  }
  if (!ownsWorkPost) {
    return (
      <div className="flex justify-center mx-auto w-9/10">
        <h3>Not Allowed</h3>
      </div>
    );
  }
  return (
    <main className={cn('h-full max-h-full', isMobile ? '' : 'pb-8')}>
      <LoadingBalls isActive={state.loading || loading} fullscreen />
      <div className="flex flex-col h-full">
        {isDesktop && (
          <WorkInfo
            work={data}
            pageViews={pageViews.data}
            applications={applications}
            publicUrl={publicUrl}
            isPublicUrlExpired={isPublicUrlExpired}
            doCreatePublicUrl={doCreatePublicUrl}
            doDeletePublicUrl={doDeletePublicUrl}
          />
        )}
        {!state.loading && data && (
          <ListWorkApplications
            patchApplications={patch}
            applications={applications}
            hire={hire}
            reject={reject}
            selected={selected}
            work={data}
            status={status}
          />
        )}
      </div>
      {/* Display Selected (modal / preview pane) */}
    </main>
  );
};

export default React.memo(Candidates);
