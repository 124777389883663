import { CollectionSummary } from '@communo-corp/shared-types';
import { keyBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useAccessibleCollections(): {
  isLoading: boolean;
  collections: CollectionSummary[];
  refetch: Function;
  incrementCount: (collectionId: string) => void;
  decrementCount: (collectionId: string) => void;
} {
  const [state, setState] = useState<{
    collections: {
      [id: string]: CollectionSummary;
    };
    isLoaded: boolean;
  }>({
    collections: {},
    isLoaded: false,
  });

  const { data, refetch } = useQuery(['collections'], () => Api.list(), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setState({
        collections: keyBy(data, 'id'),
        isLoaded: true,
      });
    }
  }, [data]);

  const incrementCount = useCallback(
    (collectionId: string) => {
      const collection = state.collections[collectionId];
      if (!collection) {
        return;
      }

      collection.userCount += 1;

      setState(prev => ({
        ...prev,
        collections: {
          ...prev.collections,
          [collectionId]: collection,
        },
      }));
    },
    [state.collections]
  );

  const decrementCount = useCallback(
    (collectionId: string) => {
      const collection = state.collections[collectionId];
      if (!collection) {
        return;
      }

      collection.userCount = Math.max(0, collection.userCount - 1);
      setState(prev => ({
        ...prev,
        collections: {
          ...prev.collections,
          [collectionId]: collection,
        },
      }));
    },
    [state.collections]
  );

  return {
    isLoading: !state.isLoaded,
    collections: Object.values(state.collections),
    refetch,
    incrementCount,
    decrementCount,
  };
}
