import React from 'react';
import CardImage from '../../card/CardImage';
import cloudinary from '../../../utilities/Cloudinary';
import { isDesktop } from '../../../utilities/MatchMedia';
import { srcSetFunction } from '../../../utilities/Image';

type Props = {
  imageUrl: string;
  alt: string;
};

const CoverPhoto = ({ imageUrl, alt }: Props) => {
  return (
    <CardImage
      imageUrl={cloudinary(imageUrl, ['w_686', 'h_344'])}
      srcSet={srcSetFunction(imageUrl, ['750', '1300', '1600'])}
      altText={alt}
      borderRadius="none"
      roundedBottom={isDesktop}
      aspectRatio="2/1"
    />
  );
};

export default CoverPhoto;
