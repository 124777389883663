import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import MembershipContext from '../../membership/Context';
import Api from '../../membership/Api';
import { LoadingBalls } from '../../core/Loading';
import { FormImage } from '../../core/form';
import { addResponseError, addSuccess } from '../../../services/Messaging';

type Props = {
  name: string;
  label?: string;
  id: string;
  defaultValue?: string | null;
  onChange?: Function;
};

const CoverPhotoForm = ({ name, label, id, defaultValue, onChange }: Props) => {
  const [loading, setLoading] = useState(false);
  const { refresh } = useContext(MembershipContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { register, errors } = form;

  const handleChange = (url: string) => {
    if (loading) return;
    setLoading(true);

    Api.update(id, { [name]: url })
      .then((response: any) => {
        refresh();
        addSuccess('Image updated!');
        if (onChange) onChange(url);
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <LoadingBalls isActive={loading} fullscreen />
      <FormImage
        name={name}
        label={label}
        aspectRatio={[2, 1]}
        onChange={handleChange}
        errors={errors}
        form={form}
        ref={register}
        defaultValue={defaultValue || ''}
      />
    </div>
  );
};

export default CoverPhotoForm;
