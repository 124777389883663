function circle(root: any, setInnerHTML: Function): void {
  setInnerHTML(
    root,
    `
        <mask id="my-mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white"/>
            <circle cx="50%" cy="50%" r="50%" fill="black"/>
        </mask>
        <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
        <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
    `
  );
}

export default {
  circle,
};
