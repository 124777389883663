import React from 'react';
import { ListItemComponentProps, OverlayStyle } from '..';
import OverlayBase from './OverlayBase';

interface Props extends ListItemComponentProps {}

const Portrait = ({ item }: Props) => {
  const {
    actionUrl,
    heading,
    backgroundUrl,
    subHeading,
    tertiaryHeading,
    location,
  } = item;

  return (
    <OverlayBase
      link={actionUrl || ''}
      firstLine={heading || ''}
      secondLine={subHeading || ''}
      thirdLine={tertiaryHeading || ''}
      fourthLine={location || ''}
      backgroundUrl={backgroundUrl || ''}
      aspect={[3, 4]}
      overlayStyle={OverlayStyle.Gradient}
      className="swiper-slide--portrait"
    />
  );
};
export default Portrait;
