import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import cn from 'classnames';

import Loading from '../core/Loading';
import useMembership from '../../hooks/useMembership';
import AgencyProfile from './AgencyProfile';
import useMembershipUsers from '../../hooks/useMembershipUsers';
import PartnerProfile from './PartnerProfile';
import { Error404 } from '../error';
import useAnalytics from '../../hooks/useAnalytics';
import Capitalize from '../../utilities/Capitalize';
import usePullToRefresh from '../../hooks/usePullToRefresh';
import BrandProfile from './BrandProfile';
import PageView from '../../analytics/PageView';
import useForceMobile from '../../hooks/useForceMobile';

const profileComponents: any = {
  agency: AgencyProfile,
  partner: PartnerProfile,
  brand: BrandProfile,
};

type Props = {
  id: string;
  setPageTitle?: Function;
};
const MembershipProfile = ({ id, setPageTitle }: Props) => {
  const {
    membership,
    isLoading: membershipLoading,
    isFetching: membershipFetching,
    refetch: refetchMembership,
  } = useMembership(id);
  const {
    membershipUsers,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: refetchUsers,
  } = useMembershipUsers(id);

  const forceMobile = useForceMobile();

  usePullToRefresh(() => {
    refetchMembership();
    refetchUsers();
  }, !membershipFetching && !usersFetching);

  useEffect(() => {
    let pageTitle = 'Loading';
    if (membership) {
      pageTitle = membership
        ? membership.name || 'Membership'
        : 'Membership not found';
    }

    if (setPageTitle) setPageTitle(pageTitle);
  }, [membership, membershipLoading, setPageTitle]);

  const { pageIsReady } = useAnalytics();
  useEffect(() => {
    if (!membershipLoading) {
      pageIsReady({
        pageType: PageView.MEMBERSHIP,
        pageId: membership?.id,
        pageName: Capitalize(membership?.plan.type),
        membershipId: membership?.id,
        membershipName: membership?.name,
        location: membership?.location?.friendlyName,
        role: membership?.roles.map(el => el.role.name),
        roleCategory: membership?.roles.map(el => el.role.category.name),
        skills: membership?.skills.map(el => el.skill.name),
        industry: membership?.industries.map(el => el.industry.name),
        vettingStatus: membership?.vettingStatus,
        planId: membership?.plan.id,
        planType: membership?.plan.type,
        planName: membership?.plan.name,
      });
    }
  }, [membership, membershipLoading, pageIsReady]);

  const loaded = !membershipLoading && !usersLoading;
  if (!loaded) {
    return <Loading isActive />;
  }

  if (!membership || !membershipUsers) {
    return <Error404 title="Member 404" message="Membership not found..." />;
  }

  // Redirect to the `/membership/user/:id` route for freelancers
  const planType = membership.plan.type;
  if (planType === 'individual') {
    const freelancerUser: any = membershipUsers[0];
    return <Redirect to={`/members/user/${freelancerUser.user.id}`} />;
  }

  const ProfileComponent = profileComponents[planType] || null;
  if (ProfileComponent) {
    return (
      <div
        className={cn({
          'allow-responsive-styles': !forceMobile,
        })}
      >
        <ProfileComponent
          membership={membership}
          membershipUsers={membershipUsers}
          forceMobile={forceMobile}
        />
      </div>
    );
  }

  return <p>Sorry, we couldn&apos;t find this membership!</p>;
};
export default MembershipProfile;
