import React, { useState, useEffect, useCallback } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import Form from './Form';
import Api from './Api';
import { addResponseError } from '../../services/Messaging';

const EditForm = ({ id }: { id: string }) => {
  const [item, setItem] = useState<EventEntity>();
  const form: UseFormMethods<any> = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {},
  });
  const { reset, setValue } = form;

  const loadItem = useCallback(
    (eventId: string) => {
      Api.retrieve(eventId)
        .then((response: EventEntity) => {
          const defaultValues: any = response;
          defaultValues.location = {
            latitude: response.location?.latitude || null,
            longitude: response.location?.longitude || null,
            friendlyName: response.location?.friendlyName || null,
            rawJSON: JSON.stringify(response.location?.rawJSON) || null,
          };
          defaultValues.categoryId = response.category?.id || '';
          reset(defaultValues);
          setItem(response);
          return response;
        })
        .catch((error: any) => {
          addResponseError(error);
          return error;
        });
    },
    [reset]
  );

  useEffect(() => {
    loadItem(id);
  }, [id, loadItem]);

  useEffect(() => {
    if (item) setValue('membershipId', item.membership?.id || '');
  }, [item, setValue]);

  return <Form isEdit form={form} defaultValues={item} />;
};

export default EditForm;
