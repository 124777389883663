// import Welcome from './default/Welcome';
import Profile from './default/Profile';
// import Photo from './default/Photo';
import Password from './default/Password';
import Location from './default/Location';
// import Role from './default/Role';
import Complete from './default/Complete';
import CulturalIdentity from './default/CulturalIdentity';

import AafWelcome from './aaf/Welcome';
import AafChapter from './aaf/Chapter';

import LocalsoloWelcome from './localsolo/Welcome';
import LocalsoloProfile from './localsolo/Profile';

// import AgencyWelcome from './agency/Welcome';
import AgencyName from './agency/Name';
import agencyLogo from './agency/Logo';
import AgencyLocation from './agency/Location';
import AgencyRoles from './agency/Roles';
// import AgencyComplete from './agency/Complete';
import CollectionInviteStep from './invite/CollectionInviteStep';
import getCookie from '../../../utilities/cookie';
import { INVITE_ID_COMMUNO_COOKIE } from '../../landing/CollectionInviteLanding';
import { StepType } from '../../stepper/Step';
// import AboutYourself from './hbcu/AboutYourself';
import Video from './hbcu/Video';
import HbcuEducationStep from './hbcu/HbcuEducation';
import RateStep from './default/Rate';
import EducationStep from './default/Education';
// import InviteFriends from './freelancer/InviteFriends';
import CompanySize from './agency/CompanySize';
import ChannelsStep from './default/Channels';
import AgencyAbout from './agency/About';
import { DISABLE_RATE_ON_SIGNUP } from '../../../config';

export interface OnboardingStepProp {
  user?: UserEntity;
  membership?: MembershipEntity;
}

export interface OnboardingStep extends StepType {
  isComplete?: (prop: OnboardingStepProp) => boolean;
  header: string;
}

export const culturalIdentityStep: OnboardingStep = {
  title: 'Select your cultural identity',
  description:
    'This information will not be published, shown on your profile, or used anywhere in the app.',
  component: CulturalIdentity,
  header: 'Your',
  isComplete: ({ user }): boolean => {
    if (user?.isEthnicitySet) {
      return true;
    }

    return false;
  },
};

export const rateStep: OnboardingStep = {
  title: 'Provide Your Hourly Rate',
  description:
    'Your rate will not be displayed publicly. It is indicated as a range shown by the number of $ signs.',
  component: RateStep,
  header: 'Your',
  canAdvance: true,
  nextButtonLabel: 'Skip',
  isComplete: ({ user }): boolean => !!user?.rate || DISABLE_RATE_ON_SIGNUP,
};

export const channelsStep: OnboardingStep = {
  title: 'Join the Conversation',
  description:
    'Have a topic you’d like to discuss with the Communo Community? Join a channel to start chatting with members.',
  header: 'Your',
  component: ChannelsStep,
  canAdvance: true,
  nextButtonLabel: 'Skip',
  // isComplete: ({ user }): boolean => !!user?.rate,
};

// export const InviteFriendsStep: OnboardingStep = {
//   title: 'Invite Your Friends',
//   description:
//     'Invite up to 10 friends to Communo and you will receive a free upgrade to a Pro Plan for 14 days for each individual who joins Communo.',
//   header: 'Your',
//   component: InviteFriends,
//   canAdvance: true,
//   nextButtonLabel: 'Skip',
//   // isComplete: ({ user }): boolean => !!user?.rate,
// };

export const genericEducationStep: OnboardingStep = {
  title: 'Where did you go to school?',
  description:
    'Let us know where and when you went to college or uni, and what you told your parents you studied.',
  header: 'Your',
  component: EducationStep,
  isComplete: ({ user }): boolean => {
    if (user?.education?.length === 0) {
      return false;
    }

    return true;
  },
  canAdvance: true,
  nextButtonLabel: 'Skip',
};

const companySizeStep: OnboardingStep = {
  title: 'Agency Size',
  description:
    'Drag the slider left or right to indicate the number of employees at your agency.',
  header: 'Agency',
  component: CompanySize,
  isComplete: ({ membership }): boolean => {
    if (membership?.size !== null) {
      return true;
    }

    return false;
  },
};

const userSteps: OnboardingStep[] = [
  {
    title: 'Tell Us About Yourself',
    description: 'Asterisk indicates a mandatory field',
    header: 'Your',
    component: Profile,
    isComplete: ({ user }): boolean =>
      !!user?.isPartialProfile || !!(user?.firstName && user.lastName),
  },
  // {
  //   title: 'Show us your face',
  //   description: 'It’s a community, we would love to see who we’re talking to.',
  //   component: Photo,
  //   canAdvance: true,
  //   nextButtonLabel: 'Skip',
  //   isComplete: ({ user }): boolean => !!user?.avatarURL,
  // },
  {
    title: 'Set a password',
    description: 'It must be at least 8 characters.',
    component: Password,
    header: 'Your',
    isComplete: ({ user }): boolean => {
      const oauthProviders = user?.oauthProviders?.length ?? 0;
      const isOAuthUser = oauthProviders > 0;
      return !user?.isPasswordEmpty || isOAuthUser;
    },
  },
  {
    title: 'Your Location',
    header: 'Your',
    component: Location,
    isComplete: ({ user }): boolean => !!user?.location?.latitude,
  },
];

export const defaultSteps: OnboardingStep[] = [...userSteps];

export const localsoloSteps: OnboardingStep[] = [
  {
    title: 'Welcome to Communo.',
    description:
      'LocalSolo has merged with Communo – a community that lets you do what you love.',
    header: 'LocalSolo',
    component: LocalsoloWelcome,
    canAdvance: true,
  },
  {
    title: 'We’ve added your profile.',
    description:
      'Nothing to set up - it’s been done for you. You can update anything you like.',
    header: 'LocalSolo',
    component: LocalsoloProfile,
    canAdvance: true,
  },
  ...userSteps,
  {
    component: Complete,
    header: 'LocalSolo',
    canAdvance: true,
  },
];

export const hbcuSteps: OnboardingStep[] = [
  // {
  //   title: 'Getting to know you',
  //   description:
  //     'We need to get to know you better. Just the basics, we promise.',
  //   component: HBCUWelcome,
  //   canAdvance: true,
  //   nextButtonLabel: 'Set up profile',
  // },
  ...userSteps,
  {
    title: 'Where do you go to school?',
    header: 'Your',
    description:
      'We also accept recent grads, so tell us where and when you attended.',
    component: HbcuEducationStep,
    isComplete: ({ user }): boolean => {
      if (user?.education?.length === 0) {
        return false;
      }

      return true;
    },
  },
  {
    title: 'Show us your best',
    header: 'Your',
    description:
      'Add the link to your demo reel if you have one - we’ll put it up front on your profile.',
    canAdvance: true,
    component: Video,
    nextButtonLabel: 'Skip',
    isComplete: ({ user }): boolean => !!user?.videoUrl,
  },
];

export const aafSteps: OnboardingStep[] = [
  {
    title: 'Getting to know you.',
    header: 'Your',
    description:
      'We need to get to know you better. Just the basics, we promise.',
    component: AafWelcome,
    canAdvance: true,
  },
  {
    title: 'Select your chapter.',
    header: 'Your',
    description: "Start typing and we'll find your home chapter.",
    component: AafChapter,
    isComplete: ({ user }): boolean => !!user?.jsonAttributes?.aafChapter,
    canAdvance: true,
  },
  ...userSteps,
  // {
  //   component: AafComplete,
  //   canAdvance: true,
  // },
];

export const agencySteps: OnboardingStep[] = [
  // {
  //   title: 'Set up your agency.',
  //   description: 'We need to get some information about your agency.',
  //   component: AgencyWelcome,
  //   canAdvance: true,
  // },
  {
    title: 'Agency Name',
    header: 'Agency',
    component: AgencyName,
    isComplete: ({ membership }): boolean => !!membership?.name,
    canGoBack: false,
  },
  {
    title: 'Agency Logo',
    component: agencyLogo,
    header: 'Agency',
    nextButtonLabel: 'Skip',
    isComplete: ({ membership }): boolean => !!membership?.logoURL,
    canAdvance: true,
    // canGoBack: true,
  },
  {
    title: 'Agency Location',
    header: 'Agency',
    component: AgencyLocation,
    isComplete: ({ membership }): boolean => !!membership?.location?.latitude,
  },
  {
    title: 'Agency Services',
    description: 'Enter the main service(s) your agency provides.',
    header: 'Agency',
    component: AgencyRoles,
    isComplete: ({ membership }): boolean => {
      if (membership && membership.roles.length > 0) {
        return true;
      }

      return false;
    },
  },
  companySizeStep,
];

export const brandSteps: OnboardingStep[] = [
  {
    title: 'Tell Us About Your Company',
    description: 'Asterisk indicates a mandatory field',
    header: 'Company',
    component: AgencyAbout,
    isComplete: ({ membership }): boolean =>
      !!membership?.name &&
      !!membership?.location?.friendlyName &&
      !!membership?.size,
  },
];

export const collectionInviteSteps: OnboardingStep[] = [
  {
    component: CollectionInviteStep,
    header: 'Your',
    isComplete: (): boolean => {
      const inviteIdCookie = getCookie(INVITE_ID_COMMUNO_COOKIE);
      return !inviteIdCookie;
    },
  },
];
