import { isPlatform } from '@ionic/core';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';

// Related documentation: https://capacitorjs.com/docs/apis/status-bar
// StatusBarStyle refers to the text color of the status bar. e.g. Light = dark text

const isNative = isPlatform('capacitor');

export const setStyle = (dark: boolean) => {
  if (isNative)
    StatusBar.setStyle({
      style: dark ? StatusBarStyle.Light : StatusBarStyle.Dark,
    });
};

export default StatusBar;
