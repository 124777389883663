export default (url: string) => {
  return {
    id: 'logo',
    label: 'Add your logo',
    description: 'You’ve invested in your brand - now’s the time to show off.',
    icon: 'b',
    action: `#/${url}/basic`,
    actionLabel: 'Add Logo',
    complete: false,
  };
};
