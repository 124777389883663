import React from 'react';
import redactedPriceImage from '../../../assets/work-redacted-price.png';

const RedactedPrice = () => (
  <img
    src={redactedPriceImage}
    className="max-w-full"
    alt="Redacted work price"
  />
);

export default RedactedPrice;
