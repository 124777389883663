import React, { useMemo } from 'react';
import moment from 'moment';
import { toNumber } from 'lodash';
import { CHAT_MESSAGES_GROUPING_INTERVAL } from '../../../config';
import Message from './Message';
import SystemMessage from './SystemMessage';
import {
  IChannel,
  IMessage,
  TwilioMessageReplyDataAttributes,
} from '../../../@types/chat.d';

type MessagesPresenterProps = {
  channel: IChannel;
  messages: IMessage[];
  onReply: (payload: TwilioMessageReplyDataAttributes) => void;
  onScrollToReply: (messageSid: string) => void;
};

const MessagesPresenter = ({
  channel,
  messages,
  onReply,
  onScrollToReply,
}: MessagesPresenterProps) => (
  <div className="chat-messages-group-by-author">
    {messages.map(message =>
      message.author === 'system' ? (
        <SystemMessage key={message.sid} message={message} />
      ) : (
        <Message
          key={message.sid}
          channel={channel}
          message={message}
          onReply={onReply}
          onScrollToReply={onScrollToReply}
        />
      )
    )}
  </div>
);

type datesProp = {
  [key: string]: IMessage[][];
};

type MessagesProps = {
  channel: IChannel;
  messages: IMessage[];
  onReply: (payload: TwilioMessageReplyDataAttributes) => void;
  onScrollToReply: (messageSid: string) => void;
  children: React.ReactNode;
};

const Messages = ({
  channel,
  messages,
  onReply,
  onScrollToReply,
  children,
}: MessagesProps) => {
  // Group messages by date
  const groupedByUnix = useMemo(() => {
    let lastTime: string = '';
    let lastAuthor: string = '';
    let lastIndex: number = 0;

    const grouped = messages.reduce((dates: datesProp, msg) => {
      const unix = moment(msg.dateCreated as Date).format('x');

      if (
        !lastTime ||
        toNumber(unix) - toNumber(lastTime) > CHAT_MESSAGES_GROUPING_INTERVAL
      )
        lastTime = unix;

      if (!dates[lastTime]) {
        // eslint-disable-next-line no-param-reassign
        dates[lastTime] = [];
        lastIndex = 0;
      } else if (lastAuthor !== msg.author) lastIndex += 1;

      // Group consecutive messages by author
      if (!dates[lastTime][lastIndex]) {
        // eslint-disable-next-line no-param-reassign
        dates[lastTime][lastIndex] = [];
      }

      dates[lastTime][lastIndex].push(msg);

      lastAuthor = msg.author;

      return dates;
    }, {});

    return grouped;
  }, [messages]);

  const groupedMessages = Object.entries(groupedByUnix);

  return (
    <>
      {groupedMessages.map(([date, messagesGroup]) => (
        <div className="chat-messages-group-by-date" key={date}>
          {messagesGroup.map((msgs: IMessage[], index) => (
            <MessagesPresenter
              // eslint-disable-next-line react/no-array-index-key
              key={`${date}-${index}`}
              channel={channel}
              messages={msgs}
              onReply={onReply}
              onScrollToReply={onScrollToReply}
            />
          ))}
        </div>
      ))}

      {children}
    </>
  );
};

export default Messages;
