import React, { useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { SubmitButton } from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Experiences from '../formElements/Experiences';

interface Inputs {
  experience: AutocompleteOption | null;
}

const ExperienceForm = () => {
  const { update, refresh, user } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const form = useForm<Inputs>({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { handleSubmit } = form;
  const defaultExperiences = useMemo(() => {
    return user?.experience
      ? [{ label: user.experience, value: user.experience }]
      : [];
  }, [user]);

  const onSubmit = useCallback(
    (values: Inputs) => {
      if (loading) return;
      setLoading(true);

      update({ experience: values.experience?.label })
        .then(() => {
          addSuccess('Your experience has been updated!');
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
          refresh();
        });
    },
    [loading, refresh, update]
  );

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Experiences form={form} defaultValues={defaultExperiences} />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save Changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default ExperienceForm;
