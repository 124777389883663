import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import InlineConversation from '../chat/components/InlineConversation';
import Header from '../core/Header';
import View from '../core/View';

export default function Channel() {
  const { sid } = useParams<{ sid: string }>();
  const history = useHistory();

  return (
    <View className="bg-grey-100" authGuard darkStatusBar pageTitle="Chat">
      <Header />
      <div className="p-3 h-full">
        <InlineConversation
          channelsLook
          sid={sid}
          onClosePane={() => history.goBack()}
        />
      </div>
    </View>
  );
}
