import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { isPlatform } from '@ionic/core';
import AppContext from '../../AppContext';
import { LoadingRing } from './Loading';

const isMobile = isPlatform('ios') || isPlatform('android');

type Props = {
  children: any;
  loading: boolean;
  hasMoreResults: boolean;
  onLoadMore: Function;
  threshold?: number;
  className?: string;
};

const ScrollPaginate = ({
  children,
  loading,
  onLoadMore,
  hasMoreResults,
  threshold = isMobile ? 350 : 550,
  className = '',
}: Props) => {
  const loadingRef = useRef(true);
  const { ionContentRef } = useContext(AppContext);
  const [scrollElement, setScrollElement] = useState(null);

  const loadMore = useCallback(() => {
    if (loading || loadingRef.current) return;

    onLoadMore();

    loadingRef.current = true;
  }, [loading, onLoadMore]);

  useEffect(() => {
    if (ionContentRef?.current) {
      ionContentRef.current.getScrollElement().then((el: any) => {
        if (!el) return;
        setScrollElement(el);
      });
    }
  }, [ionContentRef]);

  useEffect(() => {
    if (!loading) {
      loadingRef.current = false;
    }
  }, [loading]);

  return ionContentRef?.current ? (
    <>
      <InfiniteScroll
        className={className}
        threshold={threshold}
        loadMore={loadMore}
        hasMore={hasMoreResults}
        getScrollParent={() => scrollElement}
        useWindow={false}
      >
        {children}
      </InfiniteScroll>
      {loading ? (
        <div className="text-center mt-8">
          <LoadingRing isActive size="xl" color="primary" />
        </div>
      ) : null}
    </>
  ) : null;
};

export default ScrollPaginate;
