import React from 'react';
import Location from '../../core/formElements/Location';

export default ({
  errors,
  register,
  defaultValue = '',
  form,
  required,
  label = 'Location',
}: any) => (
  <Location
    label={label}
    form={form}
    errors={errors}
    register={register}
    defaultValue={defaultValue}
    required={required}
  />
);
