export const addMessage = (
  type: MessageType,
  message: string,
  data?: MessageEventData,
  eventType: MessageEventType = 'toast'
) => {
  document.dispatchEvent(
    new CustomEvent(eventType, {
      bubbles: true,
      detail: {
        type: type || 'info',
        message,
        data,
      },
    })
  );
};

// only toasts are supported
export const removeToast = (toastId: string) => {
  document.dispatchEvent(
    new CustomEvent('removeToast', {
      bubbles: true,
      detail: {
        id: toastId,
      },
    })
  );
};

export const addError = (
  message: string,
  data?: MessageEventData,
  eventType: MessageEventType = 'toast'
) => message !== 'Bad token.' && addMessage('error', message, data, eventType);

export const addSuccess = (message: string, data?: MessageEventData) =>
  addMessage('success', message, data);

export const addInfo = (message: string, data?: MessageEventData) =>
  addMessage('info', message, data);

export const addWarning = (message: string, data?: MessageEventData) =>
  addMessage('warning', message, data);

export const parseResponseError = (error: ResponseError) => {
  return error?.response?.data?.message ?? error?.message ?? null;
};

export const addResponseError = (
  error: ResponseError,
  defaultMessage?: string | null,
  data?: MessageEventData,
  eventType: MessageEventType = 'toast'
) => {
  const message =
    parseResponseError(error) ||
    defaultMessage ||
    'An unknown error has occured :/';
  addError(message, data, eventType);
};

export default {
  addMessage,
  addError,
  addSuccess,
  addInfo,
  addResponseError,
};
