import createStore, { State, UseStore, StoreApi, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import environment from '../environment';

export default <TState extends State>(
  createState: StateCreator<TState>,
  prefix: string = '' // for devtools only
): [UseStore<TState>, StoreApi<TState>] =>
  environment.appEnvironment === 'development'
    ? createStore(devtools(createState, prefix))
    : createStore(createState);
