import { IonAlert } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IMember } from '../../../@types/chat.d';
import { ListActions, ListActionsItemLink } from '../../listActions';
import { CurrentUserRole } from '../hooks/useChannelRole';
import useChatUser from '../hooks/useChatUser';
import channelMemberRole from '../utilities/channelMemberRole';
import { ChatProfileAvatar } from './ChannelProfile';

type ListActionsMemberProps = {
  currentUserRole: CurrentUserRole;
  member: IMember;
  onRemoveMember: (identity: string) => void;
  onMakeAdmin: (identity: string, friendlyName: string) => void;
  onBlock: (userId: string) => void;
  onRemoveChannelModerator: (identity: string, friendlyName: string) => void;
  isChannelOwner: boolean;
};

/**
 * Renders a custom list item specific to a member in the context role in the channel
 *
 * @param param0.currentUserRole - object that contains the role of the current logged in user
 * @param param0.member - the current member to list
 * @param param0.onRemoveMember - callback for when the member is removed from the group
 * @param param0.onMakeAdmin - callback for when the member is made an admin
 * @param param0.onBlock - callback for blocking a participant
 * @param param0.onRemoveChannelModerator - callback for when the moderator/admin is reverted to participant
 */
const ListActionsMember = ({
  currentUserRole,
  member,
  onRemoveMember,
  onMakeAdmin,
  onBlock,
  onRemoveChannelModerator,
  isChannelOwner,
}: ListActionsMemberProps) => {
  const { identity, roleSid } = member;
  const { location } = useHistory();
  const { user, loaded } = useChatUser(identity);
  const { isChannelAdmin } = channelMemberRole(roleSid);

  if (!user || !loaded) return null;

  const {
    friendlyName,
    isOnline: online,
    attributes: { avatarURL },
  } = user;
  const linkTo = location.pathname.replace('settings', `member/${identity}`);

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveMember(identity);
  };

  const handleRemoveModerator = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (e.currentTarget.id === 'remove-admin') {
      onRemoveChannelModerator(identity, user.friendlyName);
    }
  };
  const handleMakeAdmin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (e.currentTarget.id === 'add-new-admin') {
      onMakeAdmin(identity, user.friendlyName);
    }
  };

  const handleBlockMember = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (e.currentTarget.id === 'block-member') {
      onBlock(identity);
    }
  };

  const ProfileAvatar = (
    <ChatProfileAvatar
      avatarSize="md"
      friendlyName={friendlyName}
      avatarUrl={avatarURL}
      online={online}
    />
  );

  const canBeRemovedAsModerator =
    !isChannelOwner &&
    (currentUserRole.isModerator || currentUserRole.isOwner) &&
    isChannelAdmin;

  const canBeBlocked =
    !isChannelOwner &&
    (currentUserRole.isModerator || currentUserRole.isOwner) &&
    !isChannelAdmin;

  const canBeMadeAModerator =
    !isChannelOwner && currentUserRole.isOwner && !isChannelAdmin;

  const ProfileEnd = (
    <>
      {canBeRemovedAsModerator && (
        <div className="flex flex-row items-center">
          <button
            type="button"
            id="remove-admin"
            className="btn btn--sm btn--secondary btn--outline"
            onClick={handleRemoveModerator}
          >
            Remove admin
          </button>
        </div>
      )}
      {isChannelOwner && (
        <span className="text-grey-600 text-base font-semibold">
          Channel Creator
        </span>
      )}
      {canBeMadeAModerator && (
        <div className="flex flex-row items-center">
          <button
            type="button"
            id="add-new-admin"
            className="btn btn--sm btn--primary btn--outline"
            onClick={handleMakeAdmin}
          >
            Make admin
          </button>
        </div>
      )}
      {canBeBlocked ? (
        <div className="ml-2 flex flex-row items-center">
          <button
            type="button"
            id="block-member"
            className="btn btn--sm btn--primary btn--filled"
            onClick={handleBlockMember}
          >
            Block
          </button>
          <button
            type="button"
            className="btn btn--circle"
            onClick={handleRemove}
          >
            <i className="i-alert-remove text-grey-600 text-lg" />
          </button>
        </div>
      ) : (
        <i className="i-caret-right text-grey-600 text-lg px-3" />
      )}
    </>
  );

  return (
    <ListActionsItemLink start={ProfileAvatar} end={ProfileEnd} linkTo={linkTo}>
      {friendlyName}
    </ListActionsItemLink>
  );
};

type ChannelMembersListProps = {
  currentUserRole: CurrentUserRole;
  members: IMember[];
  userIsAdmin: boolean;
  onRemoveMember: (identity: string) => void;
  onMakeAdmin: (identity: string, friendlyName: string) => void;
  onRemoveChannelModerator: (identity: string, friendlyName: string) => void;
  onBlock: (userId: string) => void;
  channelOwnerId: string;
  isCurrentUserOwner: boolean;
  children: React.ReactNode;
};

const ChannelMembersList = ({
  currentUserRole,
  members,
  onRemoveMember,
  onMakeAdmin,
  onRemoveChannelModerator,
  onBlock,
  channelOwnerId,
  children,
}: ChannelMembersListProps) => {
  const [showRemovePrompt, setShowRemovePrompt] = useState(false);
  const [showAdminPrompt, setShowAdminPrompt] = useState(false);
  const [showRemoveModPrompt, setShowRemoveModPrompt] = useState(false);
  const [showBlockPrompt, setShowBlockPrompt] = useState(false);
  const memberToBlock = useRef('');

  const memberToRemove = useRef('');
  const newAdmin = useRef<{
    identity: string;
    friendlyName: string;
  }>({
    identity: '',
    friendlyName: '',
  });
  const modToRemove = useRef<{
    identity: string;
    friendlyName: string;
  }>({
    identity: '',
    friendlyName: '',
  });

  const handleOnRemoveMember = (identity: string) => {
    memberToRemove.current = identity;
    setShowRemovePrompt(true);
  };

  const handleOnMakeAdmin = (identity: string, friendlyName: string) => {
    newAdmin.current = { identity, friendlyName };
    setShowAdminPrompt(true);
  };

  const handleOnRemoveChannelModerator = (
    identity: string,
    friendlyName: string
  ) => {
    modToRemove.current = { identity, friendlyName };
    setShowRemoveModPrompt(true);
  };

  const handleOnBlock = (identity: string) => {
    memberToBlock.current = identity;
    setShowBlockPrompt(true);
  };

  return (
    <>
      <div className="text-lg mb-2">Members</div>

      <ListActions>
        {members.map(member => (
          <ListActionsMember
            currentUserRole={currentUserRole}
            key={member.identity}
            member={member}
            onMakeAdmin={handleOnMakeAdmin}
            onBlock={handleOnBlock}
            onRemoveMember={handleOnRemoveMember}
            onRemoveChannelModerator={handleOnRemoveChannelModerator}
            isChannelOwner={member.identity === channelOwnerId}
          />
        ))}
        {children}
      </ListActions>
      <IonAlert
        isOpen={showBlockPrompt}
        onDidDismiss={() => {
          setShowBlockPrompt(false);
        }}
        header="Block participant?"
        message="Are you sure you want to block this member from the conversation?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Block',
            handler: () => onBlock(memberToBlock.current),
          },
        ]}
      />
      <IonAlert
        isOpen={showRemovePrompt}
        onDidDismiss={() => {
          setShowRemovePrompt(false);
        }}
        header="Remove participant?"
        message="Are you sure you want to remove this member from the conversation?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Remove',
            handler: () => onRemoveMember(memberToRemove.current),
          },
        ]}
      />
      <IonAlert
        isOpen={showRemoveModPrompt}
        onDidDismiss={() => {
          setShowRemoveModPrompt(false);
        }}
        header="Remove moderator?"
        message={`Are you sure you want to remove ${modToRemove.current.friendlyName} as an admin?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Remove',
            handler: () => {
              onRemoveChannelModerator(
                modToRemove.current.identity,
                modToRemove.current.friendlyName
              );
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAdminPrompt}
        onDidDismiss={() => {
          setShowAdminPrompt(false);
        }}
        header="Make member admin?"
        message={`Are you sure you want to make ${newAdmin.current.friendlyName} an admin?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Proceed',
            handler: () =>
              onMakeAdmin(
                newAdmin.current.identity,
                newAdmin.current.friendlyName
              ),
          },
        ]}
      />
    </>
  );
};

export default ChannelMembersList;
