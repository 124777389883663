import React from 'react';
import { Link } from '../core/router';
import ListActionsItemBase from './ListActionsItemBase';

type ListActionsItemLinkProps = ListActionsItemProps & {
  linkTo: string;
  disabled?: boolean;
};

const ListActionsItemLink = ({
  start,
  end,
  className,
  linkTo,
  disabled = false,
  children,
}: ListActionsItemLinkProps) => {
  const classes = ['list-actions__item'];

  if (className?.length) {
    classes.push(className);
  }

  return (
    <Link disabled={disabled} to={linkTo} className={classes.join(' ')}>
      <ListActionsItemBase start={start} end={end}>
        {children}
      </ListActionsItemBase>
    </Link>
  );
};

export default ListActionsItemLink;
