export default (url: string) => {
  return {
    id: 'social',
    label: 'Add your links',
    description:
      'Your website and social links will give people a better understanding of who you are.',
    icon: 'link',
    action: `#/${url}/links`,
    actionLabel: 'Add Links',
    complete: false,
  };
};

