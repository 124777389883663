import React, { useMemo, useContext, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Roles from '../../../membership/formElements/Roles';
import Messaging from '../../../../services/Messaging';
import { Api } from '../../../../Api';
import MembershipContext from '../../../membership/Context';
import useStep from '../../../stepper/hooks/useStep';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

const Form = () => {
  const { membership, refresh } = useContext(MembershipContext);
  const {
    goToNextStep,
    setOnNext,
    setCanAdvance,
    isLoading,
    setLoading,
  } = useStep();
  const membershipRoles = membership?.roles ? membership.roles : [];
  const defaultRoles = useMemo(() => {
    return membershipRoles.map(({ role }: MembershipRole) => ({
      value: role.id,
      label: role.name,
    }));
  }, [membershipRoles]);
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      roles: defaultRoles,
    },
  });
  const { handleSubmit, reset, formState } = form;
  const { isValid, isDirty } = formState;

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AgencyRoles Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (isLoading) return;

      setLoading(true);
      const roles =
        values.roles?.map(({ value }: { value: string }) => value) || [];
      Api.put(`/membership/${membership?.id}/role`, { items: roles })
        .then(() => {
          reset({
            roles,
          });
          refresh();
          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, isLoading, membership, refresh, reset, setLoading]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Roles
        useMembershipNames
        upgradePath="numMembershipRoles"
        form={form}
        defaultValues={defaultRoles}
        isRequired
        min={1}
        max={membership?.plan?.numMembershipRoles || 1}
      />
    </form>
  );
};

export default Form;
