import React from 'react';
import Page from '../components/core/Page';
import Error404 from '../components/error/Error404';

type Props = {
  title?: string;
  message?: string;
};

export default ({
  title = '404',
  message = "We couldn't find that page.",
}: Props) => {
  return (
    <Page layout="basic" pageTitle={title} className="pt-0">
      <Error404 message={message} />
    </Page>
  );
};
