import React, { useEffect, useState } from 'react';
import { Recommendation } from '@communo-corp/shared-types';
import cn from 'classnames';
import { useMeasure } from 'react-use';
import RichText from '../core/RichText';

type Props = {
  recommendation: Recommendation;
};
const RecommendationBody = ({ recommendation }: Props) => {
  const collapsedHeight = 120;

  const [ref, { height }]: any = useMeasure();
  const [isExpanded, setExpanded] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);

  useEffect(() => {
    if (height > collapsedHeight) {
      setShouldCollapse(true);
    }
  }, [height, isExpanded]);

  let maxHeight = 'none';
  if (shouldCollapse) {
    maxHeight = isExpanded ? '9999px' : `${collapsedHeight}px`;
  }

  return (
    <div>
      <div
        ref={ref}
        className="overflow-hidden "
        style={{
          maxHeight,
          transition: 'max-height 0.3s ease-in-out',
        }}
      >
        <RichText html={recommendation.body ?? ''} />
      </div>

      {shouldCollapse ? (
        <button
          type="button"
          onClick={() => setExpanded(!isExpanded)}
          className={cn(
            'block w-full text-center font-semibold gradient-y-white-transparent z-10 relative',
            {
              '-mt-8 pt-8': !isExpanded,
              'py-4': isExpanded,
            }
          )}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      ) : null}
    </div>
  );
};
export default RecommendationBody;
