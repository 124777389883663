import userCompletenessStore from '../../user/state/userCompletenessStore';
import membershipCompletenessStore from '../../membership/state/membershipCompletenessStore';
import {
  CompletionStage,
  employeeRequirements,
  freelancerRequirements,
  UserProfileRequirements,
} from '../../user/views/ProfileCompletion';
import {
  agencyRequirements,
  businessRequirements,
  MembershipProfileRequirements,
} from '../../membership/ProfileCompletion';

export function isFreelancerProfileHidden(): boolean {
  const [completenessStore] = userCompletenessStore;
  const [userCompletedAll] = completenessStore(state => [
    state.userCompletedAll,
  ]);

  // If user doesn't have at least all the requirements in stage one, they are hidden
  return !userCompletedAll(freelancerRequirements.stageOne);
}

export function isUserProfileComplete(requirements: UserProfileRequirements) {
  const [completenessStore] = userCompletenessStore;
  const [userCompletedAll] = completenessStore(state => [
    state.userCompletedAll,
  ]);
  const { stageOne, stageTwo } = requirements;

  // If completed everything but stageThree
  return userCompletedAll([...stageOne, ...stageTwo]);
}

export function getUserProfileRequirements(isIndividualPlan: boolean) {
  switch (true) {
    case isIndividualPlan:
      return freelancerRequirements;
    default:
      return employeeRequirements;
  }
}

export function getUserCompletionStage(
  requirements: UserProfileRequirements
): CompletionStage {
  const [completenessStore] = userCompletenessStore;
  const [userCompletedAll] = completenessStore(state => [
    state.userCompletedAll,
  ]);

  if (!userCompletedAll(requirements.stageOne)) {
    return 'stageOne';
  }

  if (!userCompletedAll(requirements.stageTwo)) {
    return 'stageTwo';
  }

  return 'stageThree';
}

export function isAgencyProfileHidden(): boolean {
  const [completenessStore] = membershipCompletenessStore;
  const [membershipCompletedAll] = completenessStore(state => [
    state.membershipCompletedAll,
  ]);

  // If agency doesn't have at least all the requirements in stage one, they are hidden
  return !membershipCompletedAll(agencyRequirements.stageOne);
}

export function isMembershipProfileComplete(
  requirements: MembershipProfileRequirements
) {
  const [completenessStore] = membershipCompletenessStore;
  const [membershipCompletedAll] = completenessStore(state => [
    state.membershipCompletedAll,
  ]);
  const { stageOne, stageTwo } = requirements;

  // If completed everything but stageThree
  return membershipCompletedAll([...stageOne, ...stageTwo]);
}

export function getMembershipProfileRequirements(membershipType?: PlanType) {
  switch (membershipType) {
    case 'agency':
      return agencyRequirements;
    default:
      return businessRequirements;
  }
}

export function getMembershipCompletionStage(
  requirements: MembershipProfileRequirements
): CompletionStage {
  const [completenessStore] = membershipCompletenessStore;
  const [membershipCompletedAll] = completenessStore(state => [
    state.membershipCompletedAll,
  ]);

  if (!membershipCompletedAll(requirements.stageOne)) {
    return 'stageOne';
  }

  if (!membershipCompletedAll(requirements.stageTwo)) {
    return 'stageTwo';
  }

  return 'stageThree';
}
