/* eslint-disable no-console */
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Avatar from '../../avatar/Avatar';
import { Input } from '../../core/form';
import { addSuccess, addError } from '../../../services/Messaging';
import useCurrentUser from '../../../hooks/useCurrentUser';
import NewGroupChatContext from '../context/ChatNewGroupContext';
import { ListActions, ListActionsItem } from '../../listActions';
import useChatServices from '../hooks/useChatServices';
import { LoadingBalls } from '../../core/Loading';
import AvatarUploader from '../components/AvatarUploader';
import {
  NewConversationUser,
  ImperativeHandleRef,
} from '../../../@types/conversation.d';
import channelApi from '../../channels/Api';

const ListActionsAdmin = ({
  name,
  avatarUrl,
}: {
  name: string;
  avatarUrl: string | null;
}) => {
  const MemberAvatar = (
    <Avatar size="md" avatarName={name} avatarUrl={avatarUrl} />
  );

  const End = (
    <span className="text-grey-600 text-base font-semibold">Admin</span>
  );

  return (
    <ListActionsItem start={MemberAvatar} end={End}>
      {name}
    </ListActionsItem>
  );
};

const ListActionsMember = ({
  member,
  onRemoveMember,
}: {
  member: NewConversationUser;
  onRemoveMember: (member: NewConversationUser) => void;
}) => {
  const { fullName, avatarURL } = member;

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveMember(member);
  };

  const MemberAvatar = (
    <Avatar size="md" avatarName={fullName} avatarUrl={avatarURL} />
  );

  const RemoveMember = (
    <button type="button" className="btn btn--circle" onClick={handleRemove}>
      <i className="i-alert-remove text-grey-600 text-lg" />
    </button>
  );

  return (
    <ListActionsItem start={MemberAvatar} end={RemoveMember}>
      {fullName}
    </ListActionsItem>
  );
};

const NewGroupConversationSettings = forwardRef(
  (props, ref: React.Ref<ImperativeHandleRef>) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {
      name: currentUserName,
      avatarURL: currentUserAvatarUrl,
    } = useCurrentUser();
    const { createChannel } = useChatServices();
    const { members, updateMembers, clearMembers } = useContext(
      NewGroupChatContext
    );
    // formState is required to make useImperativeHandle work
    // we read the formState before render to subscribe the form state through Proxy
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { handleSubmit, errors, register, formState } = useForm({
      mode: 'onBlur',
      shouldFocusError: true,
    });
    const [avatarURL, setAvatarURL] = useState('');

    const handleAvatarChange = async (url: string) => {
      setAvatarURL(url);
    };

    const onSubmit = async (values: any) => {
      const { groupName } = values;
      const identities = members.map(({ identity }) => identity);

      try {
        setLoading(true);

        const channel = await createChannel({
          identities,
          friendlyName: groupName,
          attributes: {
            type: 'group',
            avatarURL,
          },
        });

        identities.forEach(async member => {
          await channelApi.interact(channel.sid, 'offered', {
            memberId: member,
          });
        });

        clearMembers();

        history.push(`/chat/conversation/${channel.sid}`);
      } catch (error) {
        setLoading(false);
        addError('Cannot create conversation');
      }
    };

    const handleRemoveMember = (member: NewConversationUser) => {
      updateMembers(member);

      addSuccess('Member successfully removed.');
    };

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
    }));

    if (loading) return <LoadingBalls isActive fullscreen />;

    return (
      <>
        <div className="form-group flex items-start">
          <AvatarUploader onChange={handleAvatarChange} />
          <div className="w-full mt-4 ml-2">
            <Input
              name="groupName"
              type="text"
              placeholder="Group name..."
              ref={register({
                required: 'A group name is required',
              })}
            />
            <div className="form-error">
              <ErrorMessage name="groupName" errors={errors} />
            </div>
            <div className="help-text">
              Please provide a group name and optional group avatar
            </div>
          </div>
        </div>

        <div className="text-lg mb-2">Members</div>

        <ListActions>
          <ListActionsAdmin
            name={currentUserName}
            avatarUrl={currentUserAvatarUrl}
          />
          {members.map(member => (
            <ListActionsMember
              key={member.identity}
              member={member}
              onRemoveMember={handleRemoveMember}
            />
          ))}
        </ListActions>
      </>
    );
  }
);

export default NewGroupConversationSettings;
