type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';

const screens = {
  xs: '375px',
  sm: '540px',
  md: '768px',
  lg: '992px',
  xl: '1025px',
  '2xl': '1280px',
  '3xl': '1440px',
  '4xl': '1920px',
  '5xl': '2560px',
};

const matchMedia = (x: Size) => {
  return window.matchMedia(`(min-width: ${screens[x]})`).matches;
};

export const isDesktop = matchMedia('md');
export const isMobile = !isDesktop;

/**
 * Strips out media query class prefixes
 * from the given classNames if the `trim`
 * parameter is `true`. Will only trim classes
 * equal to or above the `size` parameter.
 * @param classNames
 * @param trim
 * @param size
 */
export function mediaClasses(
  classNames: string,
  trim: boolean = false,
  size: Size = 'xs'
): string {
  if (!trim) {
    return classNames;
  }

  const classes = classNames.split(' ');
  const sizes = Object.keys(screens);
  const sizesToTrim = sizes.slice(sizes.indexOf(size as any) ?? 0);

  return classes
    .filter(className => {
      const shouldTrim = sizesToTrim.some(sizePrefix => {
        return className.includes(`${sizePrefix}:`);
      });

      return !shouldTrim;
    })
    .join(' ');
}

export default matchMedia;
