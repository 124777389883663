import React, { useContext } from 'react';
import validator from 'validator';
import UserContext from '../Context';
import { FormInput } from '../../core/form';

export default ({
  errors,
  register,
  onChange,
  labelVisible = false,
  label = 'Email',
}: any) => {
  const { authDetails, handleChangeAuthDetails } = useContext(UserContext);
  return (
    <FormInput
      name="email"
      type="email"
      label={label}
      labelVisible={labelVisible}
      defaultValue={authDetails.email}
      placeholder="name@email.com"
      errors={errors}
      ref={register({
        required: 'Email is required',
        validate: (value: any) => validator.isEmail(value) || 'Invalid email',
      })}
      onChange={(e: any) => {
        // keep in sync with user context authEmail
        handleChangeAuthDetails({
          email: e.target.value,
        });
        if (onChange) onChange(e);
      }}
      // autoComplete="off"
    />
  );
};
