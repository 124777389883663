import { useContext, useCallback, useRef, useReducer } from 'react';
import { debounce, uniqueId } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { SearchOptions } from '@algolia/client-search';
import SearchContext from './Context';
import { addResponseError } from '../../services/Messaging';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'UPDATE_RESULTS':
      return {
        ...state,
        loading: false,
        results: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

const useSearch = (func: Function, debounceTime: number = 0) => {
  const { isReady } = useContext(SearchContext);
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    results: {
      hits: [],
      nbHits: 0,
      page: 0,
    },
  });
  const searchIdRef = useRef('');

  const handleSearch = useCallback(
    debounce((params: SearchOptions = {}, callback?: Function) => {
      if (!isReady) return;

      dispatch({ type: 'LOADING' });

      const currentSearchId = uniqueId();
      searchIdRef.current = currentSearchId;

      func(params)
        .then((res: any) => {
          if (searchIdRef.current === currentSearchId) {
            dispatch({
              type: 'UPDATE_RESULTS',
              payload: res,
            });

            if (callback && typeof callback === 'function') callback();
          }
        })
        .catch((error: any) => {
          addResponseError(error);
        });
    }, debounceTime),
    [func, isReady]
  );

  return [state.results, handleSearch, state.loading];
};

export default useSearch;
