import React from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import Page from '../components/core/Page';
import PlanFrequency from '../components/plan/Frequency';
import useNavigate from '../hooks/useNavigate';
import useCurrentUser from '../hooks/useCurrentUser';

export default ({ match }: any) => {
  const { authDetails, isAuthenticated } = useCurrentUser();
  const { slug } = match.params;
  const navigate = useNavigate();

  useIonViewWillEnter(() => {
    // Redirect to internal upgrade modal
    if (isAuthenticated) {
      navigate(`/feed#/upgrade/${slug}/`);
      return;
    }

    // redirect to login / sign up
    const { pathname } = window.location;
    if (!authDetails.email && !isAuthenticated) {
      navigate(`/signup?redirectUrl=${pathname}`, 'none', 'replace');
    }
  }, [authDetails.email]);

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Get Started"
      mobileHeader={() => null}
      hideHeader
    >
      {(authDetails.email || isAuthenticated) && <PlanFrequency slug={slug} />}
    </Page>
  );
};
