/* eslint-disable no-unused-vars */
import { isPlatform } from '@ionic/core';
import { useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import { addResponseError } from '../../services/Messaging';
import uuid from '../../utilities/uuid';
import Button from '../core/Button';
import UserContext from '../user/Context';
import Api from './Api';
import CallStats from './CallStats';
import VideoContext from './Context';

const VideoChat = () => {
  const { id } = useContext(UserContext);
  const { page } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const { callStats, getMagicLink, openBrowser } = useContext(VideoContext);
  const [loadingJoin, setLoadingJoin] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [hideControls, setHideControls] = useState(false);
  const [activeRooms, setActiveRooms] = useState(0);

  useIonViewDidEnter(() => {
    page('Video Chat', {
      id,
    });
  });

  const refreshRooms = () => {
    Api.getActiveRooms().then((res: any) => {
      const rooms = (res || []).length;

      if (rooms !== activeRooms) {
        setActiveRooms(rooms);
      }
    });
  };

  const navigateRoom = (roomId: string) => {
    if (isPlatform('ios') && isPlatform('capacitor')) {
      getMagicLink(roomId).then((url: string) => {
        setLoadingJoin(false);
        setLoadingCreate(false);
        openBrowser(url);
      });

      return;
    }

    setLoadingJoin(false);
    setLoadingCreate(false);
    history.push(`/video-chat/${roomId}`);
  };

  const joinRoom = () => {
    if (loadingJoin || loadingCreate || !id) return;
    setLoadingJoin(true);

    Api.getRandomRoom(id)
      .then((res: any) => {
        setLoadingJoin(false);
        navigateRoom(res.room);
      })
      .catch(addResponseError)
      .finally(() => setLoadingJoin(false));
  };

  const createPrivateRoom = () => {
    if (loadingJoin || loadingCreate) return;
    setLoadingCreate(true);
    navigateRoom(`private-${uuid()}`);
  };

  useEffect(() => {
    refreshRooms();
  });

  useEffect(() => {
    if (location.search === '?hideControls') {
      setHideControls(true);
    }
  }, [location, setHideControls]);

  return (
    <div className="my-8 flex flex-col content-center overflow-y-auto h-full">
      <Button
        color="primary"
        className="mt-2 mx-auto w-64"
        loading={loadingJoin}
        onClick={() => joinRoom()}
      >
        Join the Conversation
        <br />(
        {activeRooms > 0
          ? `${activeRooms} room${activeRooms > 1 ? 's' : ''} available`
          : 'Create a new room'}
        )
      </Button>
      <Button
        color="primary"
        className="mt-2 mx-auto w-64"
        loading={loadingCreate}
        onClick={() => createPrivateRoom()}
      >
        Create Private Room
      </Button>
      {!hideControls ? (
        <Button
          color="primary"
          className="mt-2 mx-auto w-64"
          onClick={() => history.push('/feed')}
        >
          Back
        </Button>
      ) : (
        <div className="mt-8">
          <p className="text-lg text-center text-white">
            Please close this browser to return to the app
          </p>
        </div>
      )}

      {callStats?.totalParticipants?.length > 0 && (
        <div className="mt-16">
          <CallStats stats={callStats} />
        </div>
      )}
    </div>
  );
};

export default VideoChat;
