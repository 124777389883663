import React, { useContext } from 'react';
import AddMembers from '../components/AddMembers';
import NewGroupChatContext from '../context/ChatNewGroupContext';
import SelectedMembers from '../components/SelectedMembers';

const NewGroupConversation = () => {
  const { members, updateMembers } = useContext(NewGroupChatContext);
  const membersCount = members.length;

  return (
    <AddMembers multiSelect members={members} onAddMember={updateMembers}>
      {membersCount > 0 ? (
        <div className="pb-2">
          <SelectedMembers members={members} onMemberClick={updateMembers} />
        </div>
      ) : null}
    </AddMembers>
  );
};

export default NewGroupConversation;
