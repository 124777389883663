import React from 'react';
import PortfolioItemCover from './PortfolioItemCover';

type Props = {
  item: PortfolioEntity;
};

const PortfolioCard = ({ item }: Props) => {
  return (
    <div className="card rounded-2xl">
      <div className="card__body">
        <div className="group relative aspect-21/9 bg-grey-500 rounded-lg+ overflow-hidden cursor-pointer">
          <PortfolioItemCover item={item} />
        </div>
        {item.title && <p className="font-medium mt-3">{item.title}</p>}
      </div>
    </div>
  );
};

export default PortfolioCard;
