import moment from 'moment';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import { addResponseError } from '../../../../services/Messaging';
import { LoadingRing } from '../../../core/Loading';
import Api from '../../../education/Api';
import GraduationDate from '../../../education/formElements/GraduationDate';
import Major from '../../../education/formElements/Major';
import Minor from '../../../education/formElements/Minor';
import School from '../../../education/formElements/School';
import useStep from '../../../stepper/hooks/useStep';
import UserContext from '../../../user/Context';
import EducationContext from '../../../education/Context';

interface Props {
  user: UserEntity;
}

interface SchoolValue {
  value: string;
  label: string;
}
interface Inputs {
  school: SchoolValue | null;
  major: string;
  minor: string | null;
  graduationDate: string | null;
}

const now = new Date();

const HbcuEducationStep = () => {
  const {
    setOnNext,
    goToNextStep,
    setCanAdvance,
    setLoading,
    isLoading,
  } = useStep();
  const { education, isFetching } = useContext(EducationContext);

  const isEdit = education && education?.length > 0;
  const firstEducationItem = education?.length ? education[0] : null;
  const educationId = firstEducationItem?.id;
  const defaultSchool = {
    label: firstEducationItem
      ? `${firstEducationItem?.school.name} - ${firstEducationItem?.school.country.name}`
      : '',
    value: firstEducationItem ? firstEducationItem?.school.id : '',
  };
  const form = useForm<Inputs>({
    mode: 'onChange',
  });
  const { handleSubmit, formState, errors, register, reset, watch } = form;
  const { isValid, isDirty } = formState;
  const school = watch('school');

  useEffect(() => {
    const item = education?.length ? education[0] : null;

    if (item) {
      reset({
        school: { label: item.school.name, value: item.school.id },
        major: item.major,
        minor: item.minor,
        graduationDate: item.graduationDate,
      });
    }
  }, [education, reset]);

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - HbcuEducation Page');
  }, [track]);

  const onSubmit = useCallback(
    async (values: Inputs) => {
      if (isLoading) return;
      setLoading(true);

      try {
        const data = {
          ...values,
          graduationDate: moment(values.graduationDate ?? now).format(
            'YYYY-MM-DD'
          ),
          schoolId: values.school?.value,
        };

        const apiCall =
          isEdit && educationId
            ? Api.editEducation(educationId, data)
            : Api.createEducation(data);

        await apiCall;
        setLoading(false);
        goToNextStep();
      } catch (error) {
        setLoading(false);
        addResponseError(error);
      }
    },
    [educationId, goToNextStep, isEdit, isLoading, setLoading]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [isValid, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
        return false;
      }

      return true;
    });
  }, [handleSubmit, isDirty, onSubmit, setOnNext]);

  return (
    <>
      {!isFetching ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <School form={form} onlyHbcuSchools defaultValue={defaultSchool} />

          {school && (
            <>
              <Major errors={errors} register={register} />
              <Minor errors={errors} register={register} />
              <GraduationDate
                form={form}
                defaultValue={firstEducationItem?.graduationDate}
              />
            </>
          )}
        </form>
      ) : null}
    </>
  );
};

const WrappedHbcuEducationStep = () => {
  const { user } = useContext(UserContext);

  if (user == null) {
    return <LoadingRing isActive />;
  }

  return <HbcuEducationStep />;
};

export default WrappedHbcuEducationStep;
