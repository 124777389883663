import React, { useCallback, useContext, useEffect } from 'react';
import {
  OAuthResponseError,
  OAuthResponseType,
} from '@communo-corp/shared-types';
import UserContext from '../../user/Context';
import LinkedInLogin from '../../auth/LinkedInLogin';
import useStep from '../../stepper/hooks/useStep';
import { addError, addSuccess } from '../../../services/Messaging';
import Api from '../../user/Api';
import Button from '../../core/Button';
import { track } from '../../../analytics';

const AlreadyConnected = () => {
  const { goToNextStep, goToPrevStep, setDescription } = useStep();

  useEffect(() => {
    setDescription('Your LinkedIn has been successfully connected.');
  }, [setDescription]);

  return (
    <div>
      <div className="flex flex-row -mx-2 mt-16">
        <div className="px-2 w-1/2">
          <Button
            onClick={() => {
              goToPrevStep();
            }}
            fill="outline"
            className="w-full"
          >
            Back
          </Button>
        </div>
        <div className="px-2 w-1/2">
          <Button
            onClick={() => {
              goToNextStep();
            }}
            color="primary"
            className="w-full"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

const ConnectLinkedin = () => {
  const { setDescription } = useStep();
  const { handleChangeAuthDetails } = useContext(UserContext);
  const { goToNextStep } = useStep();

  useEffect(() => {
    setDescription(
      'We use LinkedIn to verify those giving a reference. By continuing with LinkedIn we’ll connect your LinkedIn and Communo accounts.'
    );
  }, [setDescription]);

  const linkedInCallback = useCallback(
    async (data: any) => {
      if (data.type === OAuthResponseType.PROFILE) {
        const { profile, oauthProvider, userId } = data.payload;

        track('Connected LinkedIn to existing Communo account', {
          step: 'Connect',
        });

        // If found userId, continue on and assume backend has connected them
        if (userId !== null) {
          goToNextStep();
          return;
        }

        // if userId is null then they dont have a linkedIn connection yet. lets make one.
        try {
          // Connect the accounts
          await Api.associateAuthProvider(oauthProvider.id, {
            firstName: profile.firstName,
            LastName: profile.lastName,
            avatar: profile.profilePhotoUrl,
            email: profile.email,
          });

          handleChangeAuthDetails(
            {
              email: profile.email,
              firstName: profile.firstName,
              lastName: profile.lastName,
              avatarURL: profile.profilePhotoUrl,
              oauthProviderId: oauthProvider.id,
            },
            () => {
              addSuccess('Your LinkedIn account has been connected.');
              goToNextStep();
            }
          );
        } catch (error) {
          if (error.response.status === 422) {
            addError(
              'This LinkedIn account is already attached to another user account.',
              {},
              'alert'
            );
          }
        }
      }

      if (data.type === OAuthResponseType.ERROR) {
        const { error } = data.payload;

        if (error === OAuthResponseError.INACTIVE_USER) {
          const { email } = data.payload.profile;

          // Able to find an account but it is inactive
          addError(
            `We're unable to log you in because the account found (${email}) is inactive.`,
            {},
            'alert'
          );
        }
      }
    },
    [goToNextStep, handleChangeAuthDetails]
  );

  return (
    <div>
      <div className="max-w-72 mx-auto">
        <LinkedInLogin action="references" callback={linkedInCallback} />
      </div>
    </div>
  );
};

const WrappedConnectLinkedInStep = () => {
  const { user } = useContext(UserContext);
  const { setTitle } = useStep();

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Connect LinkedIn',
    });
  }, []);

  useEffect(() => {
    setTitle('Connect your LinkedIn');
  }, [setTitle]);

  const hasLinkedInProvider = user?.oauthProviders?.find(
    provider => provider.providerType === 'linkedin'
  );

  // Fallback if they navigate back and have already connected
  return hasLinkedInProvider ? <AlreadyConnected /> : <ConnectLinkedin />;
};

export default WrappedConnectLinkedInStep;
