import React from 'react';
import Link from '../core/router/Link';

const links = [
  {
    to: '/perks/manage',
    label: 'Manage Perks',
  },
  {
    to: '/perks/create',
    label: 'Add Perk',
  },
  {
    to: '/perks/bundles',
    label: 'Manage Bundles',
  },
  {
    to: '/perks/bundles/create',
    label: 'Add Bundle',
  },
];

const PerkMenu = () => {
  return (
    <>
      <div className="content">
        <h2>Perks</h2>

        {links.map(({ to, label }) => (
          <Link to={to} key={to} className="block py-2">
            {label}
          </Link>
        ))}
      </div>
    </>
  );
};
export default PerkMenu;
