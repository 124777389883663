import React from 'react';
import RichText from './RichText';
import markdown from '../../utilities/Markdown';

export default ({ children }: any) => {
  const text = markdown(children);
  return (
    <div className="markdown">
      <RichText html={text} />
    </div>
  );
};
