import React from 'react';

type Props = {
  title: string;
  icon?: string | null;
  svg?: JSX.Element | null;
};

const ProfileBadge = React.memo(({ title, icon, svg }: Props) => {
  return (
    <div className="text-center text-white bg-grey-1100 w-full px-4 py-3 mt-05 rounded-br-md rounded-bl-md flex items-center justify-center">
      {icon ? <i className={`i-${icon} text-lg pr-4`} /> : null}
      {svg || null}
      <span className="font-semibold uppercase text-base">{title}</span>
    </div>
  );
});
export default ProfileBadge;
