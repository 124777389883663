import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import FormImageBrowser from '../../core/form/FormImageBrowser';
import SortableImages from '../../core/sortable/SortableImages';
import { addResponseError, addSuccess } from '../../../services/Messaging';

interface Props {
  membership: MembershipEntity;
  update: (data: any) => Promise<MembershipEntity>;
}

const ImagesForm = ({ membership, update }: Props) => {
  const { id: membershipId, imageURLs: defaultImages } = membership;
  const [loading, setLoading] = useState(false);
  const [imageURLs, setImageURLs] = useState<string[]>(() =>
    defaultImages?.length ? defaultImages : []
  );
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {},
  });
  const { register, errors } = form;
  const firstUpdate = useRef(true);

  useEffect(() => {
    // Prevents from running when states initial value set
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setLoading(true);

    const data = {
      imageURLs,
    };

    update(data)
      .then(response => {
        addSuccess('Images Updated!');
        return response;
      })
      .catch(error => {
        addResponseError(error);
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [membershipId, imageURLs, update]);

  const handleAddImage = (url: string) => {
    // eslint-disable-next-line no-shadow
    setImageURLs(imageURLs => [...imageURLs, url]);
  };

  const onImagesChanged = (imageArray: string[]) => {
    setImageURLs(imageArray);
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      {membership && (
        <>
          <SortableImages urls={imageURLs} onImagesChanged={onImagesChanged} />

          <FormImageBrowser
            name="imageURLs"
            aspectRatio={null}
            onChange={handleAddImage}
            errors={errors}
            ref={register}
          />
        </>
      )}
    </>
  );
};

export default ImagesForm;
