import React from 'react';
import { Link } from '../core/router';
import cloudinary from '../../utilities/Cloudinary';

interface Props {
  id: string;
  backgroundImage: string;
  name: string;
  tagline: string;
  className?: string;
  lightTheme?: boolean;
}

const CollectionBundleCard = ({
  id,
  backgroundImage,
  name,
  tagline,
  className,
  lightTheme = true,
}: Props) => {
  return (
    <Link
      to={`/perks/bundles/${id}`}
      className={`bundle-card bundle-card--collection rounded-md bundle-card--${
        lightTheme ? 'light' : 'dark'
      } ${className}`}
    >
      <img
        src={cloudinary(backgroundImage, ['w_400', 'h_400'])}
        alt={name}
        className="absolute inset-0 w-full h-full object-cover rounded-md"
      />
      <div className="absolute inset-0 z-30">
        <div className={`p-6 ${lightTheme ? 'text-white' : 'text-black'}`}>
          <p className="text-lg font-semibold opacity-60">{tagline}</p>
          <h5>{name}</h5>
        </div>
      </div>
    </Link>
  );
};
export default CollectionBundleCard;
