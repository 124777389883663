import React from 'react';
import { IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import useAnalytics from '../hooks/useAnalytics';
import Page from '../components/core/Page';
import Create from '../components/work/Create';

const WorkExternalCreate = () => {
  useAnalytics('Post External Work');

  return (
    <Page
      authGuard
      pageTitle="Post External Work"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/work" />
          </IonButtons>
          <IonTitle slot="start">Post External Work</IonTitle>
        </IonToolbar>
      )}
    >
      <Create title="Post External Work" isExternal />
    </Page>
  );
};
export default WorkExternalCreate;
