import React, { useCallback, useState } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import UpgradeFlow from './UpgradeFlow';

type AdditionalFlows = 'directUpgrade';

export type UpgradeablePermissions = Exclude<
  Permission,
  'canBatchSendMessages'
>;

export type Flow = {
  name: UpgradeablePermissions | AdditionalFlows;
  qualifier?: number;
};

interface FeatureGateContext {
  needsUpgrade(permission: UpgradeablePermissions, qualifier?: number): boolean;
  openUpgradeFlowFor(
    permission: UpgradeablePermissions,
    qualifier?: number
  ): void;
  destinationPlan: PlanEntity | null;
  setDestinationPlan: React.Dispatch<React.SetStateAction<PlanEntity | null>>;
}

const defaultValue: FeatureGateContext = {
  needsUpgrade: () => true,
  openUpgradeFlowFor: () => true,
  destinationPlan: null,
  setDestinationPlan: () => {},
};

export const FeatureGateContext = React.createContext<FeatureGateContext>(
  defaultValue
);

const FeatureGateProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { checkAccess } = useCurrentMembership();
  const [upgradeFlow, setUpgradeFlow] = useState<Flow | null>(null);
  const [destinationPlan, setDestinationPlan] = useState<PlanEntity | null>(
    null
  );

  const needsUpgrade = useCallback(
    (permission: UpgradeablePermissions, qualifier?: number) => {
      const isAllowed = checkAccess(permission, qualifier);

      if (!isAllowed) {
        setUpgradeFlow({ name: permission, qualifier });
      }

      return !isAllowed;
    },
    [checkAccess]
  );

  const openUpgradeFlowFor = useCallback(
    (permission: UpgradeablePermissions, qualifier?: number) => {
      // Prevent opening multiple or trying to open same one more than once
      if (upgradeFlow === null) {
        setUpgradeFlow({ name: permission, qualifier });
      }
    },
    [upgradeFlow]
  );

  return (
    <FeatureGateContext.Provider
      value={{
        needsUpgrade,
        openUpgradeFlowFor,
        destinationPlan,
        setDestinationPlan,
      }}
    >
      {children}
      {upgradeFlow && (
        <UpgradeFlow
          flow={upgradeFlow}
          onClose={() => {
            setUpgradeFlow(null);
          }}
        />
      )}
    </FeatureGateContext.Provider>
  );
};
export default FeatureGateProvider;
