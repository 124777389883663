import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Autocomplete } from '../core/form';
import { SubmitButton } from '../core/Button';
import useMembershipUsers from '../../hooks/useMembershipUsers';
import { addResponseError, addSuccess } from '../../services/Messaging';

import Api from './Api';

type Props = {
  work: WorkEntity;
  membershipId: string;
  refreshWork: Function;
  onComplete: Function;
};

type DisplayedUsers = {
  value: string;
  label: string;
};

const formatUserResults = (membershipUsers: MembershipUserEntity[] | null) => {
  if (!membershipUsers || membershipUsers.length === 0) return [];
  const members = membershipUsers?.map(
    (membershipUser: MembershipUserEntity) => {
      const { user } = membershipUser;
      return {
        value: `${user.id}`,
        label: `${user.firstName} ${user.lastName}`,
      };
    }
  );
  return members;
};

const filterUserResults = (
  query: string,
  membershipUsers: MembershipUserEntity[]
) => {
  const filteredResults = membershipUsers?.filter(
    (membershipUser: MembershipUserEntity) => {
      const { user } = membershipUser;
      const name = `${user.firstName} ${user.lastName}`.toLowerCase();
      return name.includes(query.toLowerCase().trim());
    }
  );
  return filteredResults;
};

const AssignRecruiter = ({
  work,
  membershipId,
  refreshWork,
  onComplete,
}: Props) => {
  const { membershipUsers } = useMembershipUsers(membershipId);
  const [id, setId] = useState(Math.random());
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState<DisplayedUsers[]>([]);
  const form = useForm({});

  const { handleSubmit, reset } = form;

  const onSubmit = async (values: { recruiter: any }) => {
    try {
      setIsLoading(true);
      const { recruiter } = values;
      await Api.assignRecruiter(work.id, { recruiterId: recruiter.value });
      await refreshWork();
      reset();
      setId(Math.random());
      addSuccess('Recruiter assigned');
    } catch (err) {
      addResponseError(err);
    } finally {
      setIsLoading(false);
      onComplete();
    }
  };

  const getResults = async (query: string) => {
    if (!membershipUsers) return [];
    const filteredResults = filterUserResults(query, membershipUsers);
    return formatUserResults(filteredResults);
  };

  useEffect(() => {
    const members = formatUserResults(membershipUsers);
    return setDefaultOptions(members);
  }, [membershipUsers]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <p className="text-lg-font-semibold">Assign a recruiter</p>
      <div className="flex flex-row space-x-2 mt-2 mb-6">
        <div className="w-full">
          <Autocomplete
            key={id}
            name="recruiter"
            min={1}
            max={1}
            isMulti={false}
            defaultOptions={defaultOptions}
            form={form}
            callback={getResults}
            isRequired
            placeholder="Search recruiters..."
          />
        </div>
        <SubmitButton text="Go" loading={isLoading} />
      </div>
    </form>
  );
};

export default AssignRecruiter;
