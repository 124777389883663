import React from 'react';
import { IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import Page from '../components/core/Page';
import View from '../components/work/View';

export default ({ match }: any) => {
  const { workId } = match.params;

  return (
    <Page
      pageTitle="Work Details"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/work" />
          </IonButtons>
          <IonTitle slot="start">Work Details</IonTitle>
        </IonToolbar>
      )}
      unMountOnLeave
    >
      <View workId={workId} />
    </Page>
  );
};
