import React, { useEffect, useContext, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { ErrorMessage, FormActions } from '../core/form';
import {
  Description,
  Location,
  Title,
  Type,
  Remote,
  Budget,
  Role,
  CoverPhoto,
} from './formElements';

import MembershipContext from '../membership/Context';
import { addResponseError } from '../../services/Messaging';
import UserContext from '../user/Context';
import { DEFAULT_WORK_COVER_PHOTO_URL } from '../../asset-config';
import {
  ENABLE_PROJECT_TYPES,
  SUPPORT_COMPANY_ON_JOB_POST,
} from '../../config';
import Industries from '../core/formElements/Industries';
import Requirements from './formElements/Requirements';
import Preview from './Preview';
import { isMobile } from '../../utilities/MatchMedia';
import { stripTrailingSlash } from '../../utilities/Url';
import AppContext from '../../AppContext';
import Button from '../core/Button';
import ExternalCompanySection from './formElements/ExternalCompanySection';
import RedactionLevel from './formElements/RedactionLevel';
import useNavigate from '../../hooks/useNavigate';
import useFeatureGate from '../../hooks/useFeatureGate';
import useAccessibleCollections from '../collections/hooks/useAccessibleCollections';
import WorkAudience from './utils/workAudience';
import useMyWork from '../../hooks/useMyWork';
import Accordion from '../accordion/Accordion';
import ProjectTypes from '../core/formElements/ProjectTypes';
import Duration from './formElements/Duration';
import SkillsWithRecommended from '../core/formElements/SkillsWithRecommended';
import WorkStart from './formElements/WorkStart';
import WorkCompanies from './formElements/WorkCompanies';
import Campaign from './formElements/Campaign';

enum WorkType {
  NONE,
  FULL_TIME,
  FREELANCER,
}

type Props = {
  saveForm: Function;
  isExternal: boolean;
  title?: string | null;
  form: any;
  isEdit?: boolean;
  disableAudienceEditing?: boolean;
  defaultValues?: Partial<WorkEntity> | undefined;
};

const Form = ({
  saveForm,
  isExternal,
  title,
  form,
  defaultValues,
  isEdit = false,
  disableAudienceEditing = false,
}: Props) => {
  const isMounted = useRef(false);
  const { isRoot, user, membershipAccess } = useContext(UserContext);
  const { membership, checkAccess } = useContext(MembershipContext);
  const { refresh: refreshMyWork } = useMyWork();
  const { ionContentRef } = useContext(AppContext);
  const { push, location } = useHistory();
  const navigate = useNavigate();
  const { needsUpgrade } = useFeatureGate();
  const [loading, setLoading] = useState(isEdit);
  const { id: membershipId } = membership || {};
  const { handleSubmit, errors, register, watch, setValue, trigger } = form;
  const imageURL = watch('imageURL');
  const coverPhotoURL: any =
    imageURL ||
    defaultValues?.imageURL ||
    defaultValues?.membership?.coverPhotoURL ||
    (isRoot && isEdit ? undefined : membership?.coverPhotoURL) ||
    DEFAULT_WORK_COVER_PHOTO_URL;

  const { mode } = useParams<{ mode?: string }>();
  const isPreview = mode === 'preview';
  const previewIntro = membership?.plan.isIndividualPlan
    ? user?.summary
    : membership?.summary;
  const previewLogo =
    (membership?.plan?.type === 'individual' && user?.avatarURL) ||
    membership?.logoURL ||
    null;
  const previewSocialLinks = membership?.plan.isIndividualPlan
    ? user?.socialLinks
    : membership?.socialLinks;
  const previewTitle =
    membership?.plan?.type === 'individual' && user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : membership?.name;
  const previewIntroCanEdit =
    membershipId === membership?.id && membershipAccess === 'admin';
  const previewIntroEditLink = membership?.plan.isIndividualPlan
    ? '#/edit-profile/about'
    : '#/edit-page/about';

  const returnUrl =
    isEdit && !!defaultValues
      ? `/work/${defaultValues.id}/edit`
      : '/work/create';

  const type = watch('type');
  const [workType, setWorkType] = useState(WorkType.NONE);
  useEffect(() => {
    switch (type) {
      case 'Full-time':
        form.unregister('projectTypes');
        setWorkType(WorkType.FULL_TIME);
        break;
      case 'Project Hire':
      case 'Ongoing Need':
        setWorkType(WorkType.FREELANCER);
        break;
      default:
        form.unregister('projectTypes');
        form.unregister('roles');
        setWorkType(WorkType.NONE);
        break;
    }
  }, [type, form]);

  const projectTypes = watch('projectTypes');

  const isOneClickApply = watch('isOneClickApply') === '1';
  const editingStudentWork = isEdit && defaultValues?.type === 'Student';

  const { collections } = useAccessibleCollections();
  const benchCollection = collections.find(
    collection => collection.type === 'bench'
  );

  const canPostWorkToCommuno = checkAccess('canPostWorkToCommuno');

  // Checking the error object instead of formState because formState.isValid is always false for some reason
  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (ionContentRef.current) {
      ionContentRef.current.scrollToTop();
    }
  }, [ionContentRef, mode]);

  useEffect(() => {
    setValue('membershipId', membershipId, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [setValue, membershipId]);

  useEffect(() => {
    setLoading(isEdit && !defaultValues);
  }, [isEdit, defaultValues]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const getAudienceAndCollectionValues = (isForPreview = false) => ({
    audience: canPostWorkToCommuno
      ? WorkAudience.BOTH
      : WorkAudience.COLLECTION,
    collectionIds:
      canPostWorkToCommuno || !benchCollection
        ? []
        : [
            isForPreview
              ? { value: benchCollection.id, label: benchCollection.name }
              : benchCollection.id,
          ],
  });

  const onSubmit = (values: any) => {
    const skillIds = values?.skills?.map(
      (item: { label: string; value: string }) => {
        return item.value;
      }
    );

    const industry: { label: string; value: string } | null =
      values?.industries;
    const industryIds = industry ? [industry.value] : [];

    const data: any = {
      companyName: values.companyName,
      logoURL: values.logoURL || null,
      externalURL: values.externalURL || null,
      title: values.title,
      location: {
        latitude: values.location.latitude || null,
        longitude: values.location.longitude || null,
        friendlyName: values.location.friendlyName || null,
        rawJSON: JSON.parse(values.location.rawJSON) || null,
      },
      skillIds: skillIds || [],
      industryIds: industryIds || [],
      requirements: values.requirements || null,
      description: values.description?.html ?? values.description ?? null,
      isRemote: values.remote === '1',
      imageURL: values.imageURL || null,
      redactionLevel: parseInt(values.redactionLevel, 10) || 0,
      isExternal,
      isOneClickApply: values.isOneClickApply === '1',
      budgetType: values.budgetType,
      workStart: values.workStart,
      price: values.price ? parseFloat(values.price) : null,
      roleId: values.roles?.value ? values.roles?.value : null,
      type: values.type ? values.type : null,
      hourlyRate: values.hourlyRate ? values.hourlyRate : null,
      hoursPerWeek: values.hoursPerWeek ? values.hoursPerWeek : null,
      duration: values.duration ? values.duration : null,
      companyId: values.workCompanies?.value || null,
      isCampaign: values.isCampaign === '1',
      ...getAudienceAndCollectionValues(false),
    };

    if (values.projectTypes?.value && values.projectTypes?.projectType?.id) {
      data.projectTypeId = values.projectTypes.projectType.id;
      data.projectOptionId = values.projectTypes.value;
    }

    // Launch the upgrade flow as we switch pages
    needsUpgrade('canPostWorkToCommuno');

    if (loading) return;
    setLoading(true);

    saveForm(data)
      .then((response: WorkEntity) => {
        refreshMyWork();
        navigate(`/work/${response.id}`, 'none', 'replace');
        return response;
      })
      .catch((error: any) => {
        addResponseError(error);
        return error;
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const submitForm = () => {
    handleSubmit(onSubmit)();
  };

  const getPreviewValues = () => ({
    ...form.getValues({ nest: true }),
    ...getAudienceAndCollectionValues(true),
  });

  const showDuration = () => {
    if (workType === WorkType.FREELANCER) {
      if (isExternal) return type === 'Project Hire';
      return true;
    }
    return false;
  };

  return (
    <>
      {isPreview ? (
        <Preview
          isEdit={isEdit}
          work={getPreviewValues()}
          coverPhotoURL={coverPhotoURL}
          intro={previewIntro}
          logoUrl={previewLogo}
          socialLinks={previewSocialLinks}
          displayTitle={previewTitle}
          canEdit={previewIntroCanEdit}
          editUrl={previewIntroEditLink}
          returnUrl={returnUrl}
          onSubmit={submitForm}
        />
      ) : null}
      {((isEdit && defaultValues) || !isEdit) && (
        <div
          className={`max-w-screen-md mx-auto bg-white px-6 md:px-8 py-4 rounded-b-xl ${
            isPreview ? 'hidden' : 'block'
          }`}
        >
          {!isMobile && title ? (
            <h6 className="text-center my-3">{title}</h6>
          ) : null}
          <form
            className="form-lg flex flex-col min-h-full"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isExternal ? (
              <ExternalCompanySection
                form={form}
                defaultValues={defaultValues}
              />
            ) : null}

            {!editingStudentWork ? (
              <div className="form-section">
                <h4 className="form-section__header">Work Type</h4>
                <Type errors={errors} register={register} />
              </div>
            ) : null}

            {workType !== WorkType.NONE && (
              <div className="form-section">
                <h4 className="form-section__header">Work Requirements</h4>
                {ENABLE_PROJECT_TYPES && workType === WorkType.FREELANCER && (
                  <ProjectTypes
                    name="projectTypes"
                    form={form}
                    defaultValues={
                      (defaultValues?.projectOption || null) as any
                    }
                    isMulti={false}
                    isRequired
                  />
                )}

                {!editingStudentWork ? (
                  <Role
                    form={form}
                    defaultValues={(defaultValues?.role || null) as any}
                    isMulti={false}
                    isDisabled={disableAudienceEditing}
                    isRequired
                  />
                ) : null}

                <SkillsWithRecommended
                  form={form}
                  isMulti
                  defaultValues={(defaultValues?.skills || null) as any}
                  showRecommended={workType === WorkType.FREELANCER}
                  projectTypes={projectTypes}
                />
              </div>
            )}

            <div className="form-section">
              <h4 className="form-section__header">Work Details</h4>
              <Title errors={errors} register={register} />
              {SUPPORT_COMPANY_ON_JOB_POST && (
                <WorkCompanies
                  form={form}
                  defaultValues={(defaultValues?.company || null) as any}
                  isMulti={false}
                  isRequired
                />
              )}
              <Description
                errors={errors}
                register={register}
                defaultValue={defaultValues?.description}
                form={form}
                isExternal={isExternal}
                required={!isExternal || (isExternal && isOneClickApply)}
              />
            </div>

            <div className="form-section">
              <h4 className="form-section__header">Location</h4>
              <Location
                form={form}
                errors={errors}
                register={register}
                defaultValue={defaultValues?.location?.friendlyName}
              />
              <Remote
                errors={errors}
                register={register}
                defaultValue={!!defaultValues?.isRemote}
                form={form}
              />

              <Requirements errors={errors} register={register} />
            </div>

            <div className="form-section">
              <h4 className="form-section__header">Budget & Duration</h4>
              <WorkStart
                errors={errors}
                register={register}
                defaultValue={defaultValues?.workStart}
                isRequired={!isExternal}
              />
              {!editingStudentWork ? (
                <Budget
                  errors={errors}
                  register={register}
                  defaultValues={defaultValues}
                  defaultPrice={defaultValues?.price}
                  defaultHourlyRate={defaultValues?.hourlyRate}
                  defaultHoursPerWeek={defaultValues?.hoursPerWeek}
                  defaultBudgetType={defaultValues?.budgetType}
                  form={form}
                  useFixedAmount={workType === WorkType.FREELANCER}
                  isRequired={!isExternal}
                />
              ) : null}
              <Duration
                errors={errors}
                register={register}
                defaultValue={defaultValues?.duration}
                form={form}
                showDuration={showDuration()}
              />
            </div>

            <div className="form-section">
              <Accordion title="More options">
                <div className="form-section">
                  <h4 className="form-section__header">Sharing</h4>
                  <RedactionLevel errors={errors} register={register} />
                </div>

                {isRoot && (
                  <div className="form-section">
                    <h4 className="form-section__header">Campaign</h4>
                    <Campaign
                      form={form}
                      defaultValue={!!defaultValues?.isCampaign}
                      errors={errors}
                      register={register}
                    />
                  </div>
                )}

                <Industries
                  form={form}
                  defaultValues={(defaultValues?.industries || null) as any}
                  isMulti={false}
                  overrideLabel="Industry experience required"
                />

                {!isExternal ? (
                  <div className="form-section">
                    <h4 className="form-section__header">Cover Image</h4>
                    <CoverPhoto
                      errors={errors}
                      register={register}
                      form={form}
                      defaultValue={coverPhotoURL}
                    />
                  </div>
                ) : null}
              </Accordion>
              <div className="form-section">
                {hasErrors && (
                  <>
                    <ErrorMessage
                      name="formInvalid"
                      errors={{
                        formInvalid: {
                          message:
                            'The form has errors. Please fix any errors before you can continue.',
                        },
                      }}
                    />
                  </>
                )}
              </div>

              <FormActions className="mt-auto justify-center">
                <div className="md:ml-auto">
                  {!isExternal ? (
                    <Button
                      color="primary"
                      onClick={async () => {
                        const formValid = await trigger();

                        if (formValid) {
                          const currentPath = stripTrailingSlash(
                            location.pathname
                          );
                          push(`${currentPath}/preview`);
                        }
                      }}
                    >
                      Preview
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="ml-4"
                      onClick={submitForm}
                      loading={loading}
                    >
                      {isEdit ? 'Update Work' : 'Get Applicants'}
                    </Button>
                  )}
                </div>
              </FormActions>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Form;
