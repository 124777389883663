import React, { useMemo } from 'react';

import useCurrentUser from '../../hooks/useCurrentUser';
import Avatar from '../avatar/Avatar';
import ScoreMeter from '../candidateScore/ScoreMeter';
import userCompletenessStore, {
  UserProfileAttribute,
} from '../user/state/userCompletenessStore';
import {
  CompletionStage,
  UserProfileRequirements,
} from '../user/views/ProfileCompletion';
import { getUserCompletionStage } from './utils/profile';

interface Props {
  requirements: UserProfileRequirements;
}

export interface ProfileMessage {
  heading: JSX.Element;
  subHeading: JSX.Element;
  color: string;
  trackColor: string;
}

const messages: ProfileMessage[] = [
  {
    heading: (
      <h3>
        Your profile is currently <span className="text-red-900">hidden</span>.
      </h3>
    ),
    subHeading: (
      <div>
        Complete the minimum <span className="text-red-900">profile</span>{' '}
        requirements to be visible on the platform.
      </div>
    ),
    color: '#F05555',
    trackColor: '#4C4125',
  },
  {
    heading: (
      <h3>
        Your profile can be <span className="text-gold-900">improved</span>
      </h3>
    ),
    subHeading: (
      <div>
        Complete the remaining profile requirements to become
        <span className="text-gold-900"> verified</span>.
      </div>
    ),
    color: '#F4B831',
    trackColor: '#4C4125',
  },
  {
    heading: (
      <h3>
        Your profile is looking <span className="text-green-900">good...</span>
      </h3>
    ),
    subHeading: (
      <div>
        We know you can <span className="text-green-900">finish</span> it!
      </div>
    ),
    color: '#74BA74',
    trackColor: '#2A4133',
  },
  {
    heading: (
      <h3>
        Your profile is <span className="text-green-900">complete!</span>
      </h3>
    ),
    subHeading: (
      <div>
        It looks <span className="text-green-900">great</span>!
      </div>
    ),
    color: '#74BA74',
    trackColor: '#2A4133',
  },
];

function getMessaging(
  completionStage: CompletionStage,
  completionPercentage: number
) {
  switch (true) {
    case completionStage === 'stageThree':
      return messages[3];
    case completionStage === 'stageTwo' && completionPercentage >= 70:
      return messages[2];
    case completionStage === 'stageTwo' && completionPercentage >= 40:
      return messages[1];
    default:
      return messages[0];
  }
}

const CompletionAvatar = () => {
  const { name, avatarURL } = useCurrentUser();

  return (
    <Avatar
      avatarName={name}
      avatarUrl={avatarURL}
      size="full"
      borderWidth={2}
      borderColor="black"
    />
  );
};

function combinedRequirements(
  requirements: UserProfileRequirements
): UserProfileAttribute[] {
  return Object.values(requirements)
    .flat()
    .filter(requirement => {
      // Dont include stage three requirements because they are "nice to have"
      return !requirements.stageThree.includes(requirement);
    });
}

const ProfileCompletionHeader = ({ requirements }: Props) => {
  const [completenessStore] = userCompletenessStore;
  const [getStatusesFor] = completenessStore(state => [state.getStatusesFor]);
  const allRequirements = combinedRequirements(requirements);
  const values = getStatusesFor(allRequirements);
  const numRequirements = allRequirements.length;
  const completionStage = getUserCompletionStage(requirements);

  const { id, refresh } = useCurrentUser();
  const [, store] = userCompletenessStore;
  const storedId = store.getState().userId;

  let messaging: ProfileMessage | undefined;

  const percentage = useMemo(() => {
    const completed = values.filter(value => value === 'complete');

    return (completed.length / numRequirements) * 100;
  }, [numRequirements, values]);

  if (id !== undefined && id.length > 0 && id !== storedId) {
    refresh();
  } else {
    messaging = getMessaging(completionStage, percentage);
  }

  if (messaging !== undefined) {
    return (
      <div className="bg-black text-white py-6 px-5 rounded-md">
        <div className="flex">
          <div className="flex-auto pr-4">
            <div>{messaging.heading}</div>

            <div className="mt-2">{messaging.subHeading}</div>
          </div>
          {completionStage !== 'stageOne' ? (
            <div>
              <ScoreMeter
                percentage={percentage}
                size={20}
                selectedChart={{ ...messaging, trackWidth: 4.5 }}
              >
                <CompletionAvatar />
              </ScoreMeter>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};
export default ProfileCompletionHeader;
