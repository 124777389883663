import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useIsFormValid from '../../../hooks/useIsFormValid';
import { Checkbox, FormGroup } from '../../core/form';
import ExternalLink from '../../core/router/ExternalLink';
import useStep from '../../stepper/hooks/useStep';

interface Inputs {
  agree: boolean;
}

const TermsStep = () => {
  const { setCanAdvance, setOnNext, setLoading } = useStep();
  const form = useForm<Inputs>({
    mode: 'onChange',
  });
  const { register, errors, handleSubmit } = form;
  const isValidForm = useIsFormValid(form, ['agree']);

  useEffect(() => {
    setCanAdvance(isValidForm);
  }, [isValidForm, setCanAdvance]);

  useEffect(() => {
    setOnNext(() => {
      setLoading(true);
      return true;
    });
  }, [setLoading, setOnNext]);

  const onSubmit = () => {
    // TODO: Dont really need this as we dont send this data anywhere. Here for future though if we need to store when the user agreed to the terms.
    // console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup name="agree" errors={errors}>
        <label htmlFor="agree" className="checkbox-stylized flex">
          <Checkbox
            name="agree"
            value="accepted"
            ref={register({
              required: 'Terms and Privacy are required',
            })}
          />
          <span className="text-subdued text-xs leading-normal ml-4">
            I accept the{' '}
            <ExternalLink
              to="https://communo.com/privacy"
              className="text-link"
            >
              Privacy Policy
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink to="https://communo.com/terms" className="text-link">
              Terms and Conditions of Use
            </ExternalLink>
          </span>
        </label>
      </FormGroup>
    </form>
  );
};
export default TermsStep;
