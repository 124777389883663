import React from 'react';
import Avatar from '../../avatar/Avatar';
import { Link } from '../../core/router';

interface Props {
  showName?: boolean;
  heading: string;
  subHeading?: string;
  imageUrl: string;
  to: string;
  headingClassName?: string;
  subHeadingClassName?: string;
}

const AvatarBase = ({
  to,
  imageUrl,
  showName = false,
  heading,
  subHeading,
  headingClassName = 'text-black',
  subHeadingClassName = 'text-black',
}: Props) => {
  return (
    <Link to={to}>
      <Avatar
        size="fluid"
        square={false}
        avatarName={heading}
        borderWidth={2}
        className="mx-auto"
        avatarUrl={imageUrl}
      />
      {showName ? (
        <div className="text-center mt-4">
          {heading && (
            <h6 className={`leading-tight ${headingClassName}`}>{heading}</h6>
          )}
          {subHeading && (
            <div className={`text-base ${subHeadingClassName}`}>
              {subHeading}
            </div>
          )}
        </div>
      ) : null}
    </Link>
  );
};
export default AvatarBase;
