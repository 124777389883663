import React from 'react';
import ScoreMeter from './ScoreMeter';
import Avatar from '../avatar/Avatar';

type Props = {
  name: string | null;
  imageUrl: string | null;
  percentage: number | null;
  size?: number | string;
  className?: string;
  borderWidth?: number;
  colorChart?: {
    percent: number;
    color: string;
  }[];
};

const defaultColorChart = [
  {
    percent: 0,
    color: '#f05555',
  },
  {
    percent: 34,
    color: '#f69f09',
  },
  {
    percent: 71,
    color: '#74ba74',
  },
];

const ScoreAvatar = ({
  name,
  imageUrl,
  percentage,
  size = 40,
  className,
  borderWidth,
  colorChart = defaultColorChart,
}: Props) => {
  return (
    <ScoreMeter
      percentage={percentage || 0}
      size={size}
      colorChart={colorChart}
    >
      <Avatar
        avatarName={name}
        avatarUrl={imageUrl}
        size="full"
        className={className}
        borderWidth={borderWidth}
      />
    </ScoreMeter>
  );
};

export default React.memo(ScoreAvatar);
