import React from 'react';
import Button from '../core/Button';

type Props = {
  handleHireButton: () => void;
  hiredButtonText: string;
};
const ApplicationHireButton = ({
  handleHireButton,
  hiredButtonText,
}: Props) => {
  return (
    <Button
      fill="outline"
      type="button"
      onClick={handleHireButton}
      color="primary"
      className="w-full"
    >
      <span className="btn__text">{hiredButtonText}</span>
    </Button>
  );
};

export default ApplicationHireButton;
