import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import { useHistory } from 'react-router';
import { useCookie } from 'react-use';
import { useForm } from 'react-hook-form';
import {
  OAuthResponseError,
  OAuthResponseType,
} from '@communo-corp/shared-types';
import { isPlatform } from '@ionic/core';
import UserContext from '../Context';
import Api from '../Api';
import { addError, addResponseError } from '../../../services/Messaging';
import BackButton from '../../core/BackButton';
import { SubmitButton } from '../../core/Button';
import AuthEmail from '../formElements/AuthEmail';
import useNavigate from '../../../hooks/useNavigate';
import LinkedInLogin from '../../auth/LinkedInLogin';
import SocialAuthLoginForm from './SocialAuthLoginForm';
import ContentHeader from '../../core/ContentHeader';
import { Terms } from '../../plan/formElements';
import MembershipApi from '../../membership/Api';
import usePlanBySlug from '../../plan/hooks/usePlanBySlug';
import { SOURCE_COOKIE } from '../../cookie/SourceCookie';
import { Password } from '../formElements';

const isCapacitor = isPlatform('capacitor');

type Props = {
  action: 'login' | 'signup';
  redirectUrl?: string;
  type?: string;
};

const LoginSignupForm = ({ action, redirectUrl, type }: Props) => {
  const navigate = useNavigate();
  const {
    authDetails,
    isAuthenticated,
    register: registerUser,
    registerPartialProfile,
    refresh,
    handleChangeAuthDetails,
    setRedirectUrl,
    setShowOnboarding,
    authenticateJwt,
  } = useContext(UserContext);
  const { push } = useHistory();

  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [sourceCookie] = useCookie(SOURCE_COOKIE);
  const [oauthState, setOAuthState] = useState<any>(null);
  const { handleSubmit, errors, register, formState, trigger } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: authDetails.email,
    },
  });
  const { isValid, isSubmitted } = formState;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useIonViewWillEnter(() => {
    // if logged in, redirect to homepage
    if (isAuthenticated) {
      navigate(redirectUrl || '/feed', 'none', 'replace');
      return;
    }

    const { current }: any = inputRef;
    setTimeout(() => {
      if (current) current.focus();
    }, 100);
  });

  // Get the plan slug from the redirectUrl
  const redirectSplit = redirectUrl?.split('/') || [];
  const planSlug =
    redirectSplit.length > 0 ? redirectSplit[redirectSplit.length - 1] : '';
  const { plan } = usePlanBySlug(planSlug);

  const onSubmit = useCallback(
    (values: any) => {
      const createMembership = () => {
        MembershipApi.create({
          planId: plan?.id,
        })
          .then((response: MembershipEntity) => {
            setLoading(false);
            refresh().then(() => {
              push('/getting-to-know-you');
              setShowOnboarding(true);
            });
            return response;
          })
          .catch((error: ResponseError) => {
            setLoading(false);
            addResponseError(error);
          });
      };

      if (loading) return;
      setLoading(true);

      const {
        email,
        password,
        firstName,
        lastName,
        avatarURL,
        oauthProviderId,
      }: RegisterUser = values;

      Api.checkEmail({ email })
        .then(({ status, id }: CheckEmailStatuses) => {
          switch (status) {
            case 'invited':
              setLoading(false);
              navigate('/auth/invited', 'forward', 'push');
              break;
            case 'active':
              setLoading(false);
              handleChangeAuthDetails({ email }, () => {
                if (redirectUrl) {
                  setRedirectUrl(
                    // Existing users logging in via signup should not go to plans
                    action === 'signup' && redirectUrl.startsWith('/plans')
                      ? '/feed'
                      : redirectUrl
                  );
                }
                navigate(
                  action === 'signup' ? '/auth/welcome-back' : '/auth',
                  'forward',
                  'push'
                );
              });
              break;
            case 'no-password':
              setLoading(false);
              navigate('/auth/no-password', 'forward', 'push');
              break;
            case 'partial-profile':
              if (!redirectUrl) {
                setLoading(false);
                navigate(
                  action === 'login' ? '/auth/unknown' : '/plans',
                  'forward',
                  'push'
                );

                break;
              }
              handleChangeAuthDetails({ email }, () => {
                registerPartialProfile(
                  {
                    email,
                    password,
                    isPartialProfile: false,
                  },
                  id
                )
                  .then(() => {
                    createMembership();
                    setLoading(false);
                  })
                  .catch((error: ResponseError) => {
                    addResponseError(error);
                    setLoading(false);
                  });
              });
              break;
            default:
              // We must have a redirectUrl on this page for signup because otherwise we won't set
              // the user plan correctly on the server.
              if (!redirectUrl) {
                setLoading(false);
                navigate(
                  action === 'login' ? '/auth/unknown' : '/plans',
                  'forward',
                  'push'
                );

                break;
              }
              handleChangeAuthDetails({ email }, () => {
                registerUser({
                  email,
                  password,
                  firstName: firstName || authDetails.firstName,
                  lastName: lastName || authDetails.lastName,
                  avatarURL: avatarURL || authDetails.avatarURL,
                  oauthProviderId:
                    oauthProviderId || authDetails.oauthProviderId,
                  source: sourceCookie,
                })
                  .then(() => {
                    createMembership();
                    setLoading(false);
                  })
                  .catch((error: ResponseError) => {
                    addResponseError(error);
                    setLoading(false);
                  });
                // This path used to go to the redirectUrl (Frequency.tsx) page on signup.
                // Changed on 2022.02.03
                //
                // navigate(
                //   redirectUrl ||
                //     (action === 'login' ? '/auth/unknown' : '/plans'),
                //   'forward',
                //   'push'
                // );
              });
              break;
          }
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          addResponseError(error);
        });
    },
    [
      loading,
      plan,
      refresh,
      push,
      setShowOnboarding,
      navigate,
      handleChangeAuthDetails,
      redirectUrl,
      action,
      setRedirectUrl,
      registerPartialProfile,
      registerUser,
      authDetails.firstName,
      authDetails.lastName,
      authDetails.avatarURL,
      authDetails.oauthProviderId,
      sourceCookie,
    ]
  );

  const linkedInCallback = useCallback(
    async (data: any) => {
      if (data.type === OAuthResponseType.PROFILE) {
        const { profile, oauthProvider } = data.payload;

        const linkedinAuthDetails = {
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          avatarURL: profile.profilePhotoUrl,
          oauthProviderId: oauthProvider.id,
        };

        handleChangeAuthDetails(linkedinAuthDetails, () => {
          onSubmit(linkedinAuthDetails);
        });
      }

      if (data.type === OAuthResponseType.AUTH_RESPONSE) {
        const jwt = data.payload;
        await authenticateJwt(jwt);

        navigate('/feed', 'none', 'replace');
      }

      if (data.type === OAuthResponseType.ERROR) {
        const { error } = data.payload;

        if (error === OAuthResponseError.NO_COMMUNO_ACCOUNT) {
          setOAuthState({
            profile: data.payload.profile,
            oauthProvider: data.payload.oauthProvider,
          });
        }

        if (error === OAuthResponseError.INACTIVE_USER) {
          const { email } = data.payload.profile;
          addError(
            `We're unable to log you in because the account found (${email}) is inactive.`,
            {},
            'alert'
          );
        }
      }
    },
    [authenticateJwt, handleChangeAuthDetails, navigate, onSubmit]
  );

  if (isAuthenticated) return null;

  if (oauthState) {
    return (
      <SocialAuthLoginForm
        profile={oauthState.profile}
        oauthProvider={oauthState.oauthProvider}
      />
    );
  }

  const isSignup = action === 'signup';
  return (
    <>
      {isSignup ? (
        <>
          <ContentHeader heading="Account Setup" />
          <div className="text-center my-8">
            <h3 className="mb-2">Join Communo</h3>
            <div className="text-lg">
              Set up your account to connect with top talent
              {type === 'individual' ? ' and view work opportunities' : null}.
            </div>
          </div>
        </>
      ) : (
        <>
          <ContentHeader />
          <div className="text-center mb-8">
            <h3 className="mb-2">Sign in</h3>
            <div className="text-lg">
              {isCapacitor
                ? 'Enter your email address so we know who you are.'
                : 'Use social sign on or enter your email address so we know who you are.'}
            </div>
          </div>
        </>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="flex flex-col grow w-full max-w-xl mx-auto"
      >
        {!isCapacitor ? (
          <>
            <LinkedInLogin action={action} callback={linkedInCallback} />
            <div className="pb-4 text-center text-black">or</div>
          </>
        ) : null}

        <AuthEmail
          labelVisible
          label="Email address"
          errors={isSubmitted ? errors : {}}
          register={register}
        />

        {isSignup ? (
          <>
            <Password
              errors={isSubmitted ? errors : {}}
              register={register}
              label="Password"
              labelVisible
            />
            <div className="px-4">
              <Terms
                name="terms"
                errors={isSubmitted ? errors : {}}
                register={register}
              />
            </div>
          </>
        ) : null}

        <div className="mt-auto md:mt-4 xl:mt-10 text-center flex">
          <BackButton className="btn btn--sm btn--outline btn--full mr-4" />
          <SubmitButton
            id="sign-in-email-btn"
            text="Continue"
            loading={loading}
            disabled={!isValid}
            fullWidth
          />
        </div>
      </form>
    </>
  );
};

export default LoginSignupForm;
