import { intersection } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { planCanAccess } from '../../../utilities/permissionUtil';
import Api from '../Api';
import UserContext from '../Context';

interface ReturnValues {
  isLoading: boolean;
  allowList: string[];
  refetchAllowList: Function;
  usersInAllowList(userIds: string | string[]): boolean;
  invalidateAllowList(): void;
}

/**
 * Get the array of user IDs in the current users allow list
 */
export default function useUserAllowList(): ReturnValues {
  const { isPublic } = useContext(UserContext);
  const { id, membership } = useCurrentUser();
  const [allowList, setAllowList] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const currentUserPlan = membership?.plan;

  const { data, isLoading, refetch } = useQuery(
    ['allowList'],
    (): Promise<any> => {
      // Allow list is only needed for free members and all paid users canPostWorkToCommuno
      if (
        (currentUserPlan &&
          planCanAccess({
            plan: currentUserPlan,
            permission: 'canPostWorkToCommuno',
          })) ||
        isPublic
      ) {
        return Promise.resolve([]);
      }

      return Api.getAllowList();
    },
    {
      enabled: !!id,
      // refetchInterval: 1000 * 60 * 360, // refetch every 6hrs
      staleTime: 1000 * 60 * 360, // 6hrs
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setAllowList(data ?? []);
  }, [data]);

  /**
   * Will return true if any users are in the list.
   */
  const usersInAllowList = useCallback(
    (userIds: string | string[]) => {
      const ids = Array.isArray(userIds) ? userIds : [userIds];
      const usersInList = intersection(ids, allowList);
      return usersInList.length > 0;
    },
    [allowList]
  );

  const invalidateAllowList = useCallback(() => {
    queryClient.invalidateQueries(['allowList']);
  }, [queryClient]);

  return {
    allowList,
    isLoading,
    refetchAllowList: refetch,
    usersInAllowList,
    invalidateAllowList,
  };
}
