import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="title"
    label="Project title"
    placeholder="e.g. Creating the Brand"
    errors={errors}
    ref={register({
      required: 'Title is required',
    })}
  />
);
