import shallow from 'zustand/shallow';
import { useMemo, useCallback } from 'react';
// import useAsyncEffect from 'use-async-effect';
import chatChannelsStore from '../state/ChatChannelsState';
import { CHAT_CHANNELS_PER_PAGE } from '../../../config';
import sortChannels from '../utilities/sortChannels';
import loadChannelsToState from '../services/loadChannelsToState';
import useChatClient from './useChatClient';
import { select } from '../instances/ChannelInstances';

/**
 * useChatChannels
 *
 * Returns a list of Channels that the current user has access to.
 * Will return the number as given by `channelsToShow`.
 * The hook will load the Channels if they haven't been loaded yet.
 */
const useChatChannels = (channelsToShow: number = CHAT_CHANNELS_PER_PAGE) => {
  const { clientInstance } = useChatClient();
  const [channelsStore] = chatChannelsStore;
  const [channels, loading, loaded, reloadChannels] = channelsStore(
    state => [
      state.channels,
      state.loading,
      state.loaded,
      state.reloadChannels,
    ],
    shallow
  );

  const loadMoreChannels = useCallback(
    async (anchor: number) => {
      if (!clientInstance) return Promise.reject();

      const arr = Object.values(channels);
      const channelsToLoad = arr.slice(anchor, anchor + channelsToShow);
      const channelInstances = channelsToLoad.map(channel =>
        select(channel.sid)
      );

      await loadChannelsToState(channelInstances);

      return Promise.resolve();
    },
    [clientInstance, channels, channelsToShow]
  );

  const allChannels = useMemo(() => Object.values(channels), [channels]);

  const activeChannels = useMemo(() => {
    const sortedChannels = sortChannels(channels);
    return [...sortedChannels].splice(0, channelsToShow);
  }, [channels, channelsToShow]);

  const channelCount = useMemo(() => Object.keys(channels).length, [channels]);

  const hasMoreChannels = useMemo(() => channelCount > channelsToShow, [
    channelCount,
    channelsToShow,
  ]);

  return {
    channels: allChannels,
    activeChannels,
    channelCount,
    hasMoreChannels,
    loading,
    loaded,
    reloadChannels,
    loadMoreChannels,
  };
};

export default useChatChannels;
