import React, { useContext } from 'react';
import { HBCU_PLAN_ID } from '../../config';
import EducationSummary from '../education/EducationSummary';
import EducationContext from '../education/Context';

type Props = {
  user: UserEntity;
};

const ProfileEducation = ({ user }: Props) => {
  const { education } = useContext(EducationContext);

  const planId = user.primaryMembershipUser?.membership.plan.id;

  if (!education || education.length === 0) {
    return null;
  }

  return (
    <div className="mb-8">
      <p className="profile__section-title">Education</p>
      {education.map(edu => {
        return (
          <div key={edu.id} className="mb-6">
            <EducationSummary
              education={edu}
              isStudentEducation={planId === HBCU_PLAN_ID}
            />
          </div>
        );
      })}
    </div>
  );
};
export default ProfileEducation;
