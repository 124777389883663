/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import { addSuccess, addError } from '../../../services/Messaging';
import Avatar from '../../avatar/Avatar';
import { LoadingRing } from '../../core/Loading';
import ImageUploader from '../../image/ImageUploader';

const filePondConfig = {
  imageResizeTargetWidth: 400,
  imageResizeTargetHeight: 400,
};

type Props = {
  onChange: (imageUrl: string) => Promise<any>;
  defaultValue?: string | null;
  hasPermission?: boolean;
  config?: {};
};

const AvatarUploader = ({
  onChange,
  defaultValue,
  hasPermission = true,
  config = filePondConfig,
}: Props) => {
  const imageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(defaultValue || null);

  const browseFiles = (pondRef: any) => {
    pondRef.current.removeFile();
    pondRef.current.browse();
  };

  const handleChange = async (url: string) => {
    setImageUrl(url);
    await onChange(url)
      .then(() => addSuccess('Image successfully uploaded'))
      .catch((err: any) => {
        console.error('Error uploading image', err);

        addError('Image unsuccessfully uploaded');
      });
    setLoading(false);
  };

  return (
    <ImageUploader
      ref={imageRef}
      filePondConfig={config}
      onChange={(url: string) => handleChange(url)}
      setLoading={setLoading}
    >
      <div className="flex flex-col items-center">
        {hasPermission ? (
          <>
            <button
              type="button"
              className="relative"
              onClick={() => browseFiles(imageRef)}
            >
              {loading ? (
                <div className="absolute top-0 left-0 z-10 w-full h-full flex justify-center items-center">
                  <LoadingRing isActive />
                </div>
              ) : null}
              {imageUrl ? (
                <Avatar avatarUrl={imageUrl} size="xl" />
              ) : (
                <div className="avatar avatar--xl">
                  {loading ? null : <i className="i-camera" />}
                </div>
              )}
            </button>
            <button
              type="button"
              className="btn btn--sm btn--primary btn--rev"
              onClick={() => browseFiles(imageRef)}
            >
              Change
            </button>
          </>
        ) : (
          <Avatar avatarUrl={imageUrl} size="xl" />
        )}
      </div>
    </ImageUploader>
  );
};

export default AvatarUploader;
