import React from 'react';
import useFeatureGate from '../../../hooks/useFeatureGate';
import UpgradePlan from '../../plan/upgrade/UpgradePlan';
import { UpgradeStepProps } from '../flows';

const PaymentStep = ({ coupon, purchasableItem }: UpgradeStepProps) => {
  const { destinationPlan } = useFeatureGate();

  if (!destinationPlan?.slug) {
    return <p>We don&apos;t know what plan you&apos;re upgrading to!</p>;
  }

  return (
    <UpgradePlan
      planSlug={destinationPlan.slug}
      coupon={coupon}
      purchasableItem={purchasableItem}
    />
  );
};
export default PaymentStep;
