import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useCookie } from 'react-use';
import { useIonViewDidEnter } from '@ionic/react';
import { FormGroup, Radio } from '../core/form';
import UserContext from '../user/Context';
import Capitalize from '../../utilities/Capitalize';
import { currency, number, percent } from '../../utilities/Number';
import { Link } from '../core/router';
import MembershipApi from '../membership/Api';
import { Terms } from './formElements';
import useAnalytics from '../../hooks/useAnalytics';
import { addResponseError } from '../../services/Messaging';
import BackButton from '../core/BackButton';
import { SubmitButton } from '../core/Button';
import ContentHeader from '../core/ContentHeader';
import Breadcrumbs from '../core/Breadcrumb';
import { SOURCE_COOKIE } from '../cookie/SourceCookie';
import SignupLayout from '../user/signup/SignupLayout';
import usePlanBySlug from './hooks/usePlanBySlug';
import { LoadingRing } from '../core/Loading';

type Props = {
  slug: string;
};

const isYearly = (item: RecurlyPlanEntity) => item.intervalUnit === 'yearly';
const getMonthly = (item: RecurlyPlanEntity) =>
  number(item.unitAmount) / (isYearly(item) ? 12 : 1);

const Price = ({ item }: { item: RecurlyPlanEntity }) => {
  const monthly = `${currency(getMonthly(item))}/mo`;
  const yearly = isYearly(item) ? `${currency(item.unitAmount)}/yr` : null;

  return (
    <div className="ml-auto flex flex-col text-right">
      <span className="font-medium">{monthly}</span>
      {yearly && (
        <span className="text-xs text-grey-400 mt-1">Total: {yearly}</span>
      )}
    </div>
  );
};

const Savings = ({
  items,
  item,
}: {
  items: RecurlyPlanEntity[];
  item: RecurlyPlanEntity;
}) => {
  const max = Math.max(...items.map(i => getMonthly(i), 0));
  const monthly = getMonthly(item);
  const amountSavings = max - monthly;
  const percentSavings = 1 - monthly / max;
  const minimumSavingsToDisplay = 1;

  return amountSavings > minimumSavingsToDisplay ? (
    <div className="text-teal-900">
      <span className="mx-1">–</span>
      {`Save ${percent(percentSavings)}`}
    </div>
  ) : null;
};

const FreePlanSignup = ({ plan }: { plan: PlanEntity }) => {
  const {
    isAuthenticated,
    authDetails,
    register: registerUser,
    refresh,
  } = useContext(UserContext);
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [sourceCookie] = useCookie(SOURCE_COOKIE);
  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { isSubmitted, isValid } = formState;

  const createMembership = () => {
    MembershipApi.create({
      planId: plan.id,
    })
      .then((response: MembershipEntity) => {
        refresh().then(() => {
          push('/getting-to-know-you');
        });
        return response;
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error);
      });
  };

  const onSubmit = () => {
    if (loading) return;
    setLoading(true);

    if (isAuthenticated) createMembership();
    else {
      registerUser({
        email: authDetails.email,
        firstName: authDetails.firstName,
        lastName: authDetails.lastName,
        avatarURL: authDetails.avatarURL,
        oauthProviderId: authDetails.oauthProviderId,
        source: sourceCookie,
      })
        .then(() => {
          createMembership();
        })
        .catch((error: ResponseError) => {
          setLoading(false);
          addResponseError(error);
        });
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="flex flex-col grow"
      >
        <ContentHeader heading="Account setup" />
        <div className="text-center mt-4 mb-8">
          <h3 className="mb-2">House rules.</h3>
          <p className="text-lg">
            Making sure we all get along and respect one another.
          </p>
        </div>
        {/* {authEmail && (
          <div className="mb-8 text-center text-base">
            <p>You are signing up as {authEmail}</p>
            <p className="text-sm text-subdued">
              (
              <Link
                to={`/signup?redirectUrl=${pathname}`}
                className="text-link"
              >
                change email
              </Link>
              )
            </p>
          </div>
        )} */}
        <div className="px-4">
          <Terms
            name="terms"
            errors={isSubmitted ? errors : {}}
            register={register}
          />
        </div>
        <div className="mt-auto md:mt-4 xl:mt-10 text-center flex">
          <BackButton
            defaultHref="/plans"
            className="btn btn--outline btn--full mr-4"
          />
          <SubmitButton
            loading={loading}
            text="Sign up"
            disabled={!isValid}
            fullWidth
          />
        </div>
      </form>
    </>
  );
};

const Selection = ({
  plan,
  hasMembership,
}: {
  plan: PlanEntity;
  hasMembership: boolean;
}) => {
  const { push } = useHistory();
  const [recurlyId, setRecurlyId] = useState<string>();

  const navigateToPlan = useCallback(
    (recurlyPlanId: string) => {
      push(`/plans/${plan.id}/${recurlyPlanId}`);
    },
    [plan.id, push]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (recurlyId) {
      navigateToPlan(recurlyId);
    }
  };

  const handleSelect = (e: any) => {
    setRecurlyId(e.target.value);
  };

  const recurlyPlanId =
    plan.recurlyPlans.length === 1 ? plan.recurlyPlans[0].id : null;

  useIonViewDidEnter(() => {
    if (recurlyPlanId) {
      navigateToPlan(recurlyPlanId);
    }
  }, [recurlyPlanId, navigateToPlan]);

  if (recurlyPlanId) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} noValidate className="flex flex-col grow">
      <div className="mb-4">
        <ContentHeader heading="Plan information">
          <Breadcrumbs current={2} total={3} />
        </ContentHeader>
      </div>
      <div className="text-center mb-4">
        <h3 className="mb-4">
          {hasMembership ? 'Upgrade Plan.' : 'Your plan.'}
        </h3>
        <p className="text-lg">
          <span className="font-medium">{plan?.name}</span>{' '}
          <span className="text-subdued">{plan?.description}</span>
        </p>
      </div>
      <FormGroup name="recurlyId">
        <div className="border-t border-grey-300" />
        {plan.recurlyPlans.map((item: RecurlyPlanEntity) => (
          <label className="radio-stylized m-0 block" key={item.id}>
            <div className="py-4 flex items-center my-2">
              <Radio
                name="recurlyId"
                type="radio"
                value={item.id}
                checked={item.id === recurlyId}
                onChange={handleSelect}
              />
              <span className="font-medium flex">
                {Capitalize(item.intervalUnit)}
                <Savings items={plan.recurlyPlans} item={item} />
              </span>
              <Price item={item} />
            </div>
          </label>
        ))}
        <div className="border-t border-grey-300" />
      </FormGroup>
      <p className="text-xs text-center text-subdued mt-8 mb-4">
        All prices shown in USD
      </p>
      <div className="mt-auto md:mt-8 text-center">
        <div className="flex">
          <BackButton
            defaultHref="/plans"
            className="btn btn--outline btn--full mr-4"
          />
          <SubmitButton text="Continue" disabled={!recurlyId} fullWidth />
        </div>
      </div>
    </form>
  );
};

export default ({ slug }: Props) => {
  const { authDetails, hasMembership } = useContext(UserContext);

  const { pageIsReady } = useAnalytics();

  const { plan, isFetching } = usePlanBySlug(slug);

  useEffect(() => {
    if (plan) {
      pageIsReady({
        pageName: 'Payment Frequency',
        email: authDetails.email,
        planId: plan?.id,
        planName: plan?.name,
        planType: plan?.type,
      });
    }
  }, [plan, pageIsReady, authDetails]);

  return (
    <SignupLayout>
      {isFetching ? (
        <>
          <LoadingRing isActive color="primary" size="xl" />
        </>
      ) : (
        <>
          {plan?.isPaid && (
            <Selection plan={plan} hasMembership={hasMembership} />
          )}
          {plan?.isPaid === false && <FreePlanSignup plan={plan} />}
          {!plan && (
            <div className="text-center">
              <div className="mb-8">Hmm we couldn&apos;t find that plan :/</div>
              <Link to="/plans" className="text-subdued">
                See all plans <i className="i-caret-right text-xs" />
              </Link>
            </div>
          )}
        </>
      )}
    </SignupLayout>
  );
};
