import React from 'react';

import useBeatsWork from '../../hooks/useBeatsWork';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useMembership from '../../hooks/useMembership';
import WorkCard from '../card/WorkCard';
import { LoadingRing } from '../core/Loading';
import { Link } from '../core/router';
import ProfileProgress from '../profileCompleteness/ProfileProgress';
import cloudinary from '../../utilities/Cloudinary';
import ButtonLink from '../core/ButtonLink';
import { BEATS_MEMBERSHIP_ID } from '../../config';

const BeatsFeed = () => {
  const { membership } = useCurrentMembership();

  const { membership: beatsMembership, isFetching } = useMembership(
    BEATS_MEMBERSHIP_ID
  );

  const workResults = useBeatsWork({
    hitsPerPage: 2,
    page: 0,
  });
  const hits = workResults?.hits ?? [];

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  const coverPhoto = beatsMembership?.coverPhotoURL;

  return (
    <div className="px-4 md:px-0 mt-8 lg:flex lg:flex-row-reverse">
      <div className="lg:w-2/5 lg:ml-8">
        <div className="bg-trueBlack rounded-md overflow-hidden block sm:flex lg:block">
          <div className="flex-1">
            <img
              src={cloudinary(
                'https://res.cloudinary.com/communo/image/upload/v1610743949/brands/beats/HBCU_Beats-Slide-1_R2.png',
                ['w_600', 'q_60']
              )}
              alt="HBCU Beats"
              className="w-full h-full object-cover max-h-130 md:max-h-96"
            />
          </div>
          <div className="flex-1 p-8">
            <img
              src="https://res.cloudinary.com/communo/image/upload/v1611682532/brands/beats/black-creators-logo-white-svg.svg"
              alt="Black Creators"
              className="w-full"
            />

            <div className="mt-8 text-white">
              <p>
                Over the last ten years, Beats by Dre has become a leading
                storyteller in sport, music, and culture. As the brand continues
                to expand their breadth of content, they are looking to partner
                with Black creatives at HBCUs to give them a blank canvas to do
                what they do best, CREATE.
              </p>
              <p className="mt-4">
                These partnerships are a manifestation of the brand’s heritage
                and commitment to empowering Black talent. Beats by Dre is
                moving out of the way and giving young Black creatives a lane to
                create and be heard globally.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 lg:mt-0 lg:w-3/5">
        {membership ? <ProfileProgress membership={membership} /> : null}

        {beatsMembership && coverPhoto ? (
          <div className="relative rounded-md overflow-hidden mb-8">
            <div className="absolute bottom-0 left-0 ml-8 mb-8 z-20">
              <ButtonLink
                href={`/members/membership/${beatsMembership.id}`}
                fill="outline"
                color="primary"
                text="Beats by Dr. Dre"
                size="lg"
                reverse
              />
            </div>

            <div className="aspect-4/3 sm:aspect-21/9">
              <img
                src={cloudinary(coverPhoto, ['w_2680'])}
                alt={beatsMembership.name}
                className="absolute inset-0 w-full h-full object-cover z-10"
              />
            </div>
          </div>
        ) : null}

        {hits?.length ? (
          <div>
            <div className="flex">
              <h4 className="mb-4 flex-1">Program</h4>
              <Link className="text-link font-semibold" to="/work">
                View more
              </Link>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {hits?.map((work: WorkEntity) => (
                <WorkCard
                  key={work.objectID}
                  work={work}
                  cardStyle="full"
                  className="mb-6"
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default BeatsFeed;
