import React, { useContext } from 'react';
import UserContext from '../user/Context';
import OnboardingLayout from '../onboarding/OnboardingLayout';
import Button from '../core/Button';
import useClientBrandingStore from '../../hooks/stores/useClientBrandingStore';

const GetToKnowYourAgency = () => {
  const { setShowOnboarding } = useContext(UserContext);
  const brandColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );

  return (
    <OnboardingLayout organization="communo" theme="dark">
      <>
        <div className="content text-white text-center pt-24">
          <h1 className="h2">Getting to know your company</h1>
          <p>We need to get to know you better. Just the basics, we promise.</p>
        </div>
        <Button
          color="primary"
          reverse
          fullWidth
          text="Continue"
          className="mt-8"
          onClick={() => {
            setShowOnboarding(true);
          }}
          style={{
            color: brandColor ?? undefined,
          }}
        />
      </>
    </OnboardingLayout>
  );
};
export default GetToKnowYourAgency;
