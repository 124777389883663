import React, { useCallback, useState } from 'react';
import Button from '../core/Button';
import RequestRecommendationModal from './RequestRecommendationModal';

type Props = {
  fill?: 'outline' | 'solid';
  className?: string;
};
const RequestRecommendationButton = ({
  fill = 'outline',
  className = '',
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <Button
        type="button"
        color="primary"
        fill={fill}
        text="Request a Recommendation"
        onClick={handleClick}
        className={className}
      />

      {isModalOpen ? (
        <RequestRecommendationModal onClose={() => setModalOpen(false)} />
      ) : null}
    </>
  );
};
export default RequestRecommendationButton;
