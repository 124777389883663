/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import { LoadingRing } from './Loading';

export type ButtonFillType = 'solid' | 'outline';

type ButtonProps = {
  loading?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary';
  fill?: ButtonFillType;
  type?: 'button' | 'submit';
  text?: string;
  icon?: string;
  align?: 'start' | 'end';
  disabled?: boolean;
  fullWidth?: boolean;
  reverse?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

export type ButtonType = JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLButtonElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps;

export const SubmitButton = ({
  text,
  color = 'primary',
  ...rest
}: ButtonType) => <Button type="submit" text={text} color={color} {...rest} />;

export const Button = ({
  loading = false,
  children,
  text,
  type,
  color,
  fill,
  icon,
  align,
  disabled = false,
  reverse = false,
  fullWidth,
  size = 'sm',
  ...rest
}: ButtonType) => {
  let className = `btn ${rest.className || ''}`;
  if (color) className += ` btn--${color}`;
  if (fill === 'outline') className += ' btn--outline';
  if (align === 'start') className += ' mr-auto';
  if (align === 'end') className += ' ml-auto';
  if (fullWidth) className += ' btn--full';
  if (disabled) className += ' disabled';
  if (size) className += ` btn--${size}`;
  if (icon) className += ' btn--w-icon';
  if (reverse) className += ' btn--rev';
  if (icon) className += ' btn--w-icon';

  return (
    <button
      {...rest}
      className={`relative ${className}`}
      type={type || 'button'}
      disabled={disabled}
    >
      {loading ? (
        <div>
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <LoadingRing isActive />
          </div>
          &nbsp;
        </div>
      ) : (
        <>
          {icon && <i className={`i-${icon} btn__icon`} />}
          {text && <span className="btn__text">{text}</span>}
          {!text && children}
        </>
      )}
    </button>
  );
};

export default Button;
