import React from 'react';
import useChatChannelUnreadCount from '../hooks/useChatChannelUnreadCount';

type Props = {
  channelSid: string;
  className?: string;
};

const ChannelUnreadCountBadge = ({ channelSid, className }: Props) => {
  const unreadCount = useChatChannelUnreadCount(channelSid);
  const classes = ['badge'];

  if (!unreadCount) return null;
  if (className) classes.push(className);

  return <div className={classes.join(' ')}>{unreadCount}</div>;
};

export default ChannelUnreadCountBadge;
