// This API relies on the Segment script being added to window
// This can be found within <head> in public/index.html
// Refer to the Segment documentation for more details about its JS library
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/

declare global {
  interface Window {
    analytics: any;
    mixpanel?: { cookie?: { clear: Function } };
  }
}

// Load analytics
export const load = (key: string) => {
  if (window.analytics !== undefined) window.analytics.load(key);
};

// Resets the id, including anonymousId, and clears traits for the currently identified user and group.
export const reset = () => {
  if (window.analytics !== undefined) window.analytics.reset();
  if (window.mixpanel?.cookie !== undefined) window.mixpanel.cookie.clear();
};

// Set the users identity
export const identify = (id: string, ...rest: any) => {
  if (window.analytics !== undefined) window.analytics.identify(id, ...rest);
};

// Set the users identity
export const alias = (id: string, ...rest: any) => {
  if (window.analytics !== undefined) window.analytics.alias(id, ...rest);
};

// Track an action/event
export const track = (event: string, ...rest: any) => {
  if (window.analytics !== undefined) window.analytics.track(event, ...rest);
};

// Track a page hit
export const page = (name: string, ...rest: any) => {
  if (window.analytics !== undefined) window.analytics.page(name, ...rest);
};

const record = {
  load,
  identify,
  alias,
  track,
  page,
};

export default record;
