import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../core/Button';

type Props = { editMeetingUrl: string };

const MeetingSettingsButton = ({ editMeetingUrl }: Props) => {
  const history = useHistory();
  return (
    <Button
      fill="outline"
      id="application-meeting-settings"
      type="button"
      onClick={() => history.push(editMeetingUrl)}
      color="primary"
      className="flex-1"
    >
      <i className="i-settings btn__icon" />
    </Button>
  );
};

export default MeetingSettingsButton;
