import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { sortBy, uniq } from 'lodash';
import Button from '../../core/Button';
import useAccessibleCollections from '../hooks/useAccessibleCollections';
import {
  BenchCollectionItem,
  GroupCollectionItem,
  ListCollectionItem,
} from './AddToCollectionItem';
import Api from '../Api';
import CreateCollectionModal from './CreateCollectionModal';
import useUserCollectionIds from '../hooks/useUserCollectionIds';
import { CollectionsContext } from '../context/CollectionsContext';
import { LoadingBalls } from '../../core/Loading';
import { addResponseError } from '../../../services/Messaging';
import Modal from '../../core/modal/Modal';
import useUserAllowList from '../../user/hooks/useUserAllowList';

type Props = {
  userId: string;
  onClose: (collectionIdsUserIsIn: string[]) => void;
};
function CollectionItems(type: string) {
  switch (type) {
    case 'bench':
      return BenchCollectionItem;
    case 'group':
      return GroupCollectionItem;
    default:
      return ListCollectionItem;
  }
}
const Content = ({
  collections,
  isLoadingCollections,
  onCreateNewList,
  isUserInCollection,
  onToggleUser,
  userIds,
}: any): any => {
  if (!collections || isLoadingCollections) {
    return <LoadingBalls isActive />;
  }

  if (collections.length === 0) {
    return (
      <>
        <p className="text-lg">
          It looks like you haven&apos;t created a list yet!
        </p>
        <Button
          text="Create one now"
          fill="solid"
          color="primary"
          icon="add"
          fullWidth
          className="mt-4"
          onClick={onCreateNewList}
        />
      </>
    );
  }

  return sortBy(collections, ['type', 'name']).map((collection: any) => {
    const Component = CollectionItems(collection.type);

    return (
      <Component
        key={collection.id}
        id={collection.id}
        name={collection.name}
        description={collection.description}
        count={collection.userCount}
        isSelected={isUserInCollection(collection.id)}
        onToggle={onToggleUser}
        userIds={userIds}
      />
    );
  });
};

const Footer = ({ onCreateNewList, onClose }: any) => {
  return (
    <>
      <Button
        text="Create a new list"
        fill="outline"
        icon="add"
        size="sm"
        onClick={() => onCreateNewList()}
      />
      <Button
        text="Done"
        fill="solid"
        color="primary"
        size="sm"
        onClick={() => onClose()}
      />
    </>
  );
};

const useCollectionIdsUserIsIn = (userId: string) => {
  const [collectionIdsUserIsIn, setCollectionIdsUsersIn] = useState<string[]>(
    []
  );

  const isUserInCollection = useCallback(
    (collectionId: string) => {
      return collectionIdsUserIsIn.includes(collectionId);
    },
    [collectionIdsUserIsIn]
  );

  const {
    data: freshCollectionIdsUserIsIn,
    refetch: refetchCollectionIds,
  } = useUserCollectionIds(userId);

  useEffect(() => {
    if (freshCollectionIdsUserIsIn?.collectionIds) {
      setCollectionIdsUsersIn(freshCollectionIdsUserIsIn.collectionIds);
    }
  }, [freshCollectionIdsUserIsIn]);

  return {
    collectionIdsUserIsIn,
    setCollectionIdsUsersIn,
    refetchCollectionIds,
    isUserInCollection,
  };
};

const AddUserToCollectionModal = ({ userId, onClose }: Props) => {
  const { invalidateAllowList } = useUserAllowList();
  const {
    collections: accessibleCollections,
    isLoading: isLoadingCollections,
    refetch: refetchAccessibleCollections,
    incrementCount,
    decrementCount,
  } = useAccessibleCollections();

  const { updateUserIdsCollectionCache } = useContext(CollectionsContext);

  const {
    collectionIdsUserIsIn,
    setCollectionIdsUsersIn,
    refetchCollectionIds,
    isUserInCollection,
  } = useCollectionIdsUserIsIn(userId);

  const listAndBenchOnly = useMemo(() => {
    return accessibleCollections.filter(accessibleCollection => {
      return accessibleCollection.type;
    });
  }, [accessibleCollections]);

  const closeAddUserToCollectionModal = useCallback(() => {
    const collectionTypes: any = uniq(
      collectionIdsUserIsIn
        .map(collectionId => {
          const collection = listAndBenchOnly.find(
            col => col.id === collectionId
          );
          return collection?.type;
        })
        .filter(type => !!type)
    );

    updateUserIdsCollectionCache([userId], collectionTypes);

    onClose(collectionIdsUserIsIn);
  }, [
    collectionIdsUserIsIn,
    updateUserIdsCollectionCache,
    userId,
    onClose,
    listAndBenchOnly,
  ]);

  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const closeCreateCollectionModal = useCallback(() => {
    setCreateCollectionOpen(false);

    refetchAccessibleCollections().then(() => {
      refetchCollectionIds();
    });
  }, [refetchAccessibleCollections, refetchCollectionIds]);

  const addUserToCollection = useCallback(
    collectionId => {
      return new Promise(res => {
        setCollectionIdsUsersIn(prev => [...prev, collectionId]);
        incrementCount(collectionId);

        Api.addUsersToCollection(collectionId, [userId])
          .then(() => {
            res();
          })
          .catch(err => {
            setCollectionIdsUsersIn(prev =>
              prev.filter(id => id !== collectionId)
            );
            decrementCount(collectionId);
            addResponseError(err);
          });
      });
    },
    [decrementCount, incrementCount, setCollectionIdsUsersIn, userId]
  );

  const removeUserFromCollection = useCallback(
    collectionId => {
      return new Promise(res => {
        Api.removeUsersToCollection(collectionId, userId)
          .then(() => {
            setCollectionIdsUsersIn(prev =>
              prev.filter(id => id !== collectionId)
            );
            decrementCount(collectionId);
            res();
          })
          .catch(err => {
            setCollectionIdsUsersIn(prev => [...prev, collectionId]);
            incrementCount(collectionId);
            addResponseError(err);
          });
      });
    },
    [decrementCount, incrementCount, setCollectionIdsUsersIn, userId]
  );

  const onToggleUser = useCallback(
    (collectionId: string, shouldRemove: boolean, callback?: Function) => {
      const method = shouldRemove
        ? removeUserFromCollection
        : addUserToCollection;
      method(collectionId).then(() => {
        invalidateAllowList();

        if (callback) {
          callback();
        }
      });
    },
    [addUserToCollection, invalidateAllowList, removeUserFromCollection]
  );

  const content = useMemo(
    () => (
      <div className="min-h-screen50">
        <Content
          collections={listAndBenchOnly}
          isLoadingCollections={isLoadingCollections}
          isUserInCollection={isUserInCollection}
          onCreateNewList={() => setCreateCollectionOpen(true)}
          onToggleUser={onToggleUser}
          userIds={[userId]}
        />
      </div>
    ),
    [
      listAndBenchOnly,
      isLoadingCollections,
      isUserInCollection,
      onToggleUser,
      userId,
    ]
  );
  const footer = useMemo(
    () => (
      <Footer
        onCreateNewList={() => setCreateCollectionOpen(true)}
        onClose={closeAddUserToCollectionModal}
      />
    ),
    [closeAddUserToCollectionModal]
  );

  return (
    <>
      <Modal
        isOpen
        title="Add to a List"
        width="md"
        onClose={closeAddUserToCollectionModal}
        footer={footer}
      >
        {content}
      </Modal>

      <CreateCollectionModal
        isOpen={createCollectionOpen}
        userIds={[userId]}
        onClose={closeCreateCollectionModal}
      />
    </>
  );
};
export default AddUserToCollectionModal;
