import React from 'react';
import { useHistory } from 'react-router';
import AddMembers from '../components/AddMembers';
import { NewConversationUser } from '../../../@types/conversation.d';

const NewDirectConversation = () => {
  const history = useHistory();

  const handleAddMember = (user: NewConversationUser) =>
    history.replace(`/chat/new-direct-message/${user.identity}`);

  return <AddMembers onAddMember={handleAddMember} />;
};

export default NewDirectConversation;
