import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingBalls } from '../../core/Loading';
import { FormActions } from '../../core/form';
import { SocialLinks } from '../formElements';
import { SubmitButton } from '../../core/Button';
import UserContext from '../Context';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import { SocialLink } from '../formElements/SocialLinks';

const links: SocialLink[] = [
  { key: 'WebsiteURL', label: 'Website' },
  { key: 'InstagramURL', label: 'Instagram' },
  { key: 'TwitterURL', label: 'Twitter' },
  { key: 'FacebookURL', label: 'Facebook' },
  { key: 'LinkedInURL', label: 'LinkedIn' },
  { key: 'BehanceURL', label: 'Behance' },
  { key: 'VimeoURL', label: 'Vimeo' },
  { key: 'YouTubeURL', label: 'YouTube' },
];

interface Props {
  user: UserEntity;
}

const LinksForm = ({ user }: Props) => {
  const [loading, setLoading] = useState(false);
  const { update, refresh } = useContext(UserContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      WebsiteURL: user.socialLinks.WebsiteURL || '',
      InstagramURL: user.socialLinks.InstagramURL || '',
      TwitterURL: user.socialLinks.TwitterURL || '',
      FacebookURL: user.socialLinks.FacebookURL || '',
      LinkedInURL: user.socialLinks.LinkedInURL || '',
      BehanceURL: user.socialLinks.BehanceURL || '',
      VimeoURL: user.socialLinks.VimeoURL || '',
      YouTubeURL: user.socialLinks.YouTubeURL || '',
    },
  });
  const { handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = {
      socialLinks: {
        ...values,
      },
    };

    update(data)
      .then((response: UserEntity) => {
        addSuccess('Profile updated!');
        refresh();
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <SocialLinks errors={errors} register={register} links={links} />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default LinksForm;
