import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="name"
    label="Enter event title"
    placeholder="Event title"
    errors={errors}
    ref={register({
      required: 'Event title is required',
    })}
  />
);
