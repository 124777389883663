import React from 'react';
import { FormInput } from '../../core/form';

interface Props {
  errors: any;
  register: any;
}

const Major = ({ errors, register }: Props) => {
  return (
    <FormInput
      name="major"
      label="Major"
      placeholder="e.g. Computer Science"
      required
      errors={errors}
      ref={register({
        required: 'A major is required',
      })}
    />
  );
};
export default Major;
