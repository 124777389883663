import React, { useCallback } from 'react';
import { addResponseError, addSuccess } from '../../services/Messaging';
import Alert from '../alert/Alert';
import Api from './Api';

type Props = {
  recommendationId: string;
  isOpen: boolean;
  onClose: Function;
  onDelete: Function;
};
const DeleteRecommendation = ({
  recommendationId,
  isOpen,
  onClose,
  onDelete,
}: Props) => {
  const onConfirmDelete = useCallback(() => {
    Api.deleteRecommendation(recommendationId)
      .then(() => {
        addSuccess('The recommendation has been deleted.');
        onDelete();
      })
      .catch((err: any) => {
        addResponseError(err);
      });
  }, [recommendationId, onDelete]);

  return (
    <Alert
      isOpen={isOpen}
      header="Delete Recommendation?"
      message="<p>Are you sure? Once deleted, you cannot restore this recommendation, and will have to request a new one from the individual who submitted it.</p>"
      onDidDismiss={onClose}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-secondary-btn mr-auto',
          handler: onClose,
        },
        {
          text: 'Delete',
          cssClass: 'alert-primary-btn',
          handler: onConfirmDelete,
        },
      ]}
    />
  );
};
export default DeleteRecommendation;
