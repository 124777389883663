import { BaseAPI, Api } from '../../Api';

export const VideoApi = {
  getToken: (id: string, roomName: string): Promise<any> => {
    return Api.get(`/video/token`, {
      identity: id,
      roomName,
    });
  },

  getActiveRooms: (): Promise<any> => {
    return Api.get(`/video/rooms`);
  },

  getRandomRoom: (id: string): Promise<any> => {
    return Api.get(`/video/random`, {
      identity: id,
    });
  },
};

export default { ...BaseAPI('video'), ...VideoApi };
