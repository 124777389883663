import { Api } from '../../Api';

export default {
  getRecommendation: (recommendationId: string) =>
    Api.get(`/recommendation/${recommendationId}`),
  getRecommendationByToken: (submissionToken: string) =>
    Api.get(`/recommendation/token/${submissionToken}`),
  listRecommendationsForUser: (userId: string) =>
    Api.get(`/recommendation/user/${userId}`),
  requestRecommendation: (payload: any) => Api.post(`/recommendation`, payload),
  submitRecommendation: (
    recommendationId: string,
    payload: {
      firstName: string;
      lastName: string;
      email: string;
      organization: string;
      department?: string;
      title: string;
      body: string;
      submissionToken: string;
    }
  ) => Api.put(`/recommendation/submit/${recommendationId}`, payload),
  deleteRecommendation: (recommendationId: string) =>
    Api.delete(`/recommendation/${recommendationId}`),
};
