import { IonRange } from '@ionic/react';
import React, { useState } from 'react';

interface Props {
  defaultIndex: number;
  label: string;
  className?: string;
  onChange?(value: SteppedRangeValue): void;
  options: SteppedRangeValue[];
  required?: boolean;
}

export interface SteppedRangeValue {
  value: number;
  label: string;
}

const SteppedRangeSlider = ({
  defaultIndex,
  label,
  className,
  onChange,
  options,
  required = false,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="text-md font-semibold">
          {label}
          {required ? <span className="text-red-900 font-bold"> *</span> : null}
        </div>
        <div className="text-red-900 text-base font-semibold">
          {options[selectedIndex].label}
        </div>
      </div>
      <div>
        <IonRange
          className="stepped-range"
          // debounce={10}
          min={0}
          max={options.length - 1}
          snaps
          ticks
          color="communo-red"
          mode="ios"
          value={selectedIndex}
          onIonChange={event => {
            const index: number = event.detail.value as number;
            const newValue = options[index];
            setSelectedIndex(index);

            if (onChange) {
              onChange(newValue);
            }
          }}
        />
      </div>
    </div>
  );
};
export default SteppedRangeSlider;
