import React from 'react';
import ProgressBar, { ColorChart } from '../core/ProgressBar';

type Props = {
  percentage: number;
  size: number | string;
  // If your passing an array of available options that ProgressBar should get the best value
  colorChart?: ColorChart[];
  // If you already know the options you want to use
  selectedChart?: Omit<ColorChart, 'percent'>;
  children: JSX.Element;
};

// hack so purgecss wont purge 3 common widths
// purgecss: w-20 w-24 w-40
const ScoreMeter = ({
  percentage,
  size = 40,
  colorChart,
  children,
  selectedChart,
}: Props) => {
  return (
    <div className={`w-${size}`}>
      <div className="relative aspect-square">
        <div className="absolute top-0 left-0 w-full h-full z-0">
          <ProgressBar
            percent={percentage}
            colorChart={colorChart}
            selectedChart={selectedChart}
          />
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-1">
          <div
            className="w-full h-full rounded-full border border-transparent"
            style={{ padding: '8%' }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreMeter;
