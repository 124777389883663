/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormGroup } from '.';

interface Options {
  id: string | number;
  name: string;
}
interface Props {
  name: string;
  label: string;
  errors: any;
  placeholder?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  options?: Options[];
}

const FormSelect = React.forwardRef(
  (
    {
      name,
      label,
      errors,
      placeholder = '',
      className = '',
      children,
      options = [],
      ...rest
    }: Props,
    ref: any
  ) => {
    return (
      <FormGroup
        className={className}
        name={name}
        label={label}
        errors={errors}
      >
        <select
          {...rest}
          id={name}
          name={name}
          className="form-input"
          ref={ref}
        >
          <option value="">{placeholder || '-- Select --'}</option>
          {options?.map((item: Options) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
          {children}
        </select>
      </FormGroup>
    );
  }
);
export default FormSelect;
