import React from 'react';
import { IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation } from 'react-router';
import useAnalytics from '../hooks/useAnalytics';
import Page from '../components/core/Page';
import Create from '../components/work/Create';

const WorkInternalCreate = ({ match }: any) => {
  const { mode } = match.params;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const collectionId = params.has('cid') ? params.get('cid') : null;

  useAnalytics('Post Work');

  return (
    <Page
      authGuard
      pageTitle="Post New Work"
      mobileHeader={() => (
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/work" />
          </IonButtons>
          <IonTitle slot="start">
            {mode === 'preview' ? 'Preview' : 'Post New Work'}
          </IonTitle>
        </IonToolbar>
      )}
    >
      <Create
        title="Create new work post"
        isExternal={false}
        collectionId={collectionId}
      />
    </Page>
  );
};
export default WorkInternalCreate;
