import React from 'react';
import ListActionsItemBase from './ListActionsItemBase';

export const ListActionsItem = ({
  start,
  end,
  className,
  children,
}: ListActionsItemProps) => {
  const classes = ['list-actions__item'];

  if (className?.length) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <ListActionsItemBase start={start} end={end}>
        {children}
      </ListActionsItemBase>
    </div>
  );
};

export default ListActionsItem;
