import React, { useMemo } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';

import Avatar from '../avatar/Avatar';
import ScoreMeter from '../candidateScore/ScoreMeter';
import { MembershipProfileRequirements } from '../membership/ProfileCompletion';
import membershipCompletenessStore, {
  MembershipProfileAttribute,
} from '../membership/state/membershipCompletenessStore';
import { CompletionStage } from '../user/views/ProfileCompletion';
import { ProfileMessage } from './ProfileCompletionHeader';
import { getMembershipCompletionStage } from './utils/profile';

interface Props {
  requirements: MembershipProfileRequirements;
}

const messages: ProfileMessage[] = [
  {
    heading: (
      <h3>
        Your company page is currently{' '}
        <span className="text-red-900">hidden</span>
      </h3>
    ),
    subHeading: (
      <div>
        Add your company information to make your page{' '}
        <span className="text-red-900">visible</span>.
      </div>
    ),
    color: '#F05555',
    trackColor: '#4C4125',
  },
  {
    heading: (
      <h3>
        Your company page could be{' '}
        <span className="text-gold-900">better...</span>
      </h3>
    ),
    subHeading: (
      <div>
        But you can <span className="text-gold-900">improve</span> it.
      </div>
    ),
    color: '#F4B831',
    trackColor: '#4C4125',
  },
  {
    heading: (
      <h3>
        Your company page is looking{' '}
        <span className="text-green-900">good...</span>
      </h3>
    ),
    subHeading: (
      <div>
        We know you can <span className="text-green-900">finish</span> it!
      </div>
    ),
    color: '#74BA74',
    trackColor: '#2A4133',
  },
  {
    heading: (
      <h3>
        Your company page is <span className="text-green-900">complete!</span>
      </h3>
    ),
    subHeading: (
      <div>
        It looks <span className="text-green-900">great</span>!
      </div>
    ),
    color: '#74BA74',
    trackColor: '#2A4133',
  },
];

function getMessaging(
  completionStage: CompletionStage,
  completionPercentage: number
) {
  switch (true) {
    case completionStage === 'stageThree':
      return messages[3];
    case completionStage === 'stageTwo' && completionPercentage >= 75:
      return messages[2];
    case completionStage === 'stageTwo' && completionPercentage >= 50:
      return messages[1];
    default:
      return messages[0];
  }
}

const CompletionAvatar = () => {
  const { membership } = useCurrentMembership();

  return (
    <Avatar
      avatarName={membership?.name}
      avatarUrl={membership?.logoURL}
      size="full"
      borderWidth={2}
      borderColor="black"
    />
  );
};

function combinedRequirements(
  requirements: MembershipProfileRequirements
): MembershipProfileAttribute[] {
  return Object.values(requirements)
    .flat()
    .filter(requirement => {
      // Dont include stage three requirements because they are "nice to have"
      return !requirements.stageThree.includes(requirement);
    });
}

const MembershipProfileCompletionHeader = ({ requirements }: Props) => {
  const [completenessStore] = membershipCompletenessStore;
  const [getStatusesFor] = completenessStore(state => [state.getStatusesFor]);
  const allRequirements = combinedRequirements(requirements);
  const values = getStatusesFor(allRequirements);
  const numRequirements = allRequirements.length;
  const completionStage = getMembershipCompletionStage(requirements);

  const percentage = useMemo(() => {
    const completed = values.filter(value => value === 'complete');

    return (completed.length / numRequirements) * 100;
  }, [numRequirements, values]);

  const messaging = getMessaging(completionStage, percentage);

  return (
    <div className="bg-black text-white py-6 px-5 rounded-md">
      <div className="flex">
        <div className="flex-auto pr-4">
          <div>{messaging.heading}</div>

          <div className="mt-2">{messaging.subHeading}</div>
        </div>
        {completionStage !== 'stageOne' ? (
          <div>
            <ScoreMeter
              percentage={percentage}
              size={20}
              selectedChart={{ ...messaging, trackWidth: 4.5 }}
            >
              <CompletionAvatar />
            </ScoreMeter>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default MembershipProfileCompletionHeader;
