import React, { useContext } from 'react';
import Image from '../../core/Image';
import RichText from '../../core/RichText';
import { SubmitRecommendationContext } from '../context/SubmitRecommendationContext';

const RecommendationIntroStep = () => {
  const { recommendation } = useContext(SubmitRecommendationContext);

  const { user } = recommendation;

  const education = user.education.length > 0 ? user.education[0] : null;

  return (
    <div>
      <div className="flex">
        {user.avatarUrl ? (
          <div className="w-24">
            <Image
              src={user.avatarUrl}
              alt={`${user.firstName} ${user.lastName}`}
              className="rounded-t-md overflow-hidden"
            />
          </div>
        ) : null}

        <div className="ml-4">
          <h6>
            {user.firstName} {user.lastName}
          </h6>
          <p>{user.locationFriendlyName}</p>
          {education ? (
            <>
              <p className="mt-4">{education.school.name}</p>
              <p className="text-sm">{education.major}</p>
            </>
          ) : null}
        </div>
      </div>

      <div className="mt-8">
        <RichText html={recommendation.intro || null} />
      </div>
    </div>
  );
};
export default RecommendationIntroStep;
