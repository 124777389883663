/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import { useSpring, animated } from 'react-spring';

type Props = {
  collapsedHeight?: number;
  children: any;
};

type Direction = 'OPEN' | 'CLOSE';

type ToggleProp = {
  nextState: Direction;
  toggleExpanded: () => void;
};

const ToggleButton = ({ nextState, toggleExpanded }: ToggleProp) => {
  return (
    <>
      {nextState === 'CLOSE' ? (
        <button
          onClick={toggleExpanded}
          type="button"
          className="right-0 bottom-0 w-full h-6 text-right text-red-900"
        >
          ... Show Less
        </button>
      ) : (
        <div className="absolute right-0 bottom-0 w-full h-40">
          <button
            onClick={toggleExpanded}
            type="button"
            id="textButton"
            className="flex items-end justify-end h-full w-full text-red-900 bg-bottom-gradient--white"
          >
            ... Show More
          </button>{' '}
        </div>
      )}
    </>
  );
};

const ShowMore = ({ children, collapsedHeight = 120 }: Props) => {
  const [wrapper, { height: wrapperHeight }]: any = useMeasure();
  const [content, { height: contentHeight }]: any = useMeasure();
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [nextState, setNextState] = useState<Direction>('OPEN');

  const [styles, set] = useSpring(() => ({
    maxHeight: collapsedHeight,
  }));

  useEffect(() => {
    if (contentHeight > collapsedHeight) {
      setShouldCollapse(true);
    }
  }, [collapsedHeight, contentHeight]);

  const toggleExpanded = () => {
    const destinationHeight =
      wrapperHeight < contentHeight ? contentHeight : collapsedHeight;
    set({
      maxHeight: destinationHeight,
    });
    setNextState(destinationHeight > wrapperHeight ? 'CLOSE' : 'OPEN');
  };

  return (
    <div className="flex flex-col mt-4 relative">
      <animated.div className="overflow-hidden" ref={wrapper} style={styles}>
        <div ref={content}>{children}</div>
      </animated.div>
      {shouldCollapse ? (
        <ToggleButton nextState={nextState} toggleExpanded={toggleExpanded} />
      ) : null}
    </div>
  );
};

export default ShowMore;
