import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Toggle from './Toggle';

/* eslint-disable no-unused-vars */
import {
  AllFiltersOption,
  BaseFilterOption,
  URLFilterOption,
  RadioFilterOption,
  SelectFilterOption,
  LocationFilterOption,
  BooleanFilterOption,
} from './options';
import { FilterValues, ImperativeActions } from './index.d';
import FilterSearch from './FilterSearch';
import Modal from '../core/modal/Modal';

function getFilterToggleLabel(
  values: FilterValues,
  option: BaseFilterOption,
  actions: ImperativeActions
) {
  if (option instanceof LocationFilterOption) {
    const location = values[option.name];
    if (location) {
      return location?.location?.address ?? option.toggleLabel;
    }
  }

  const label = option.toggleLabel;
  const filterValue = actions.getFilterValue(option.name);

  if (option instanceof RadioFilterOption) {
    const selectedValue = option.options?.find(
      opt => opt.value === filterValue
    );
    if (selectedValue) {
      return selectedValue.activeLabel || selectedValue.label;
    }
  }

  if (option instanceof SelectFilterOption) {
    const selectedValue = values[option.name];

    if (selectedValue) {
      if (option.isMulti) {
        if (selectedValue.length === 0) {
          return label;
        }
        if (selectedValue.length === 1) {
          const val = selectedValue[0];
          if (typeof option.valueFormatter === 'function') {
            return option.valueFormatter(val).label;
          }
          return val;
        }
        return `${option.toggleLabel} (${selectedValue.length})`;
      }

      // TODO: This code uses the 'value' as the 'label' and that seems incorrect to me. This
      // change can fix that but it will need to be fixed other places as well.
      // const foundObject = option.options?.reduce(
      //   (previousValue, currentValue) =>
      //     currentValue.options.find((o2: any) => o2.value === selectedValue) ||
      //     previousValue,
      //   ''
      // );
      // return foundObject?.label || selectedValue;

      return selectedValue;
    }
  }

  if (option instanceof BooleanFilterOption) {
    const selectedValue = values[option.name];
    if (selectedValue) {
      return option.activeLabel || option.toggleLabel;
    }
  }

  return label;
}

type Props = {
  actions: ImperativeActions;
  options: URLFilterOption[];
  filteredOptions: URLFilterOption[];
  className?: string;
  values: FilterValues;
  loading?: boolean;
  hideReset?: boolean;
  hideSearch?: boolean;
  hideAllFilterButton?: boolean;
};

function Filter({
  actions,
  className = '',
  loading = false,
  filteredOptions,
  options,
  values,
  hideReset = false,
  hideSearch = false,
  hideAllFilterButton = false,
}: Props) {
  const searchFilterValue = actions.getFilterValue('search') || '';
  const allFiltersOption = new AllFiltersOption();
  const [search, setSearch] = useState(searchFilterValue);
  const [currentFilter, setCurrentFilter] = useState<BaseFilterOption | null>(
    null
  );
  const [modalText, setModalText] = useState('Close');

  useEffect(() => {
    filteredOptions.forEach((option: BaseFilterOption) => {
      if (option.name === currentFilter?.name) {
        setCurrentFilter(option);
      }
    });
  }, [filteredOptions, currentFilter]);

  useEffect(() => {
    if (currentFilter && values[currentFilter.name]) {
      return setModalText('Apply');
    }

    let isAValueDefined = false;
    const filterValues = Object.values(values);
    for (let i = 0; i < filterValues.length; i += 1) {
      if (filterValues[i]) {
        isAValueDefined = true;
        break;
      }
    }
    if (currentFilter?.name === 'all' && isAValueDefined) {
      return setModalText('Apply');
    }

    return setModalText('Close');
  }, [currentFilter, values]);

  const handleDrawerClose = () => {
    setCurrentFilter(null);
  };

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      actions.updateFilterValue('search', text);
    }, 300),
    []
  );

  const handleToggle = (option: BaseFilterOption) => {
    if (option.onToggle) {
      option.onToggle(actions);
    } else {
      setCurrentFilter(option);
    }
  };

  const handleClose = () => {
    handleDrawerClose();
    setCurrentFilter(null);
  };

  const isFilterActive = useCallback(
    (option: BaseFilterOption): boolean => {
      if (actions.getFilterValue(option.name)) {
        return true;
      }
      return currentFilter?.name === option.name;
    },
    [actions, currentFilter]
  );

  useEffect(() => {
    if (!values.search) {
      setSearch('');
    }
  }, [values.search]);

  useEffect(() => {
    setSearch(searchFilterValue);
  }, [searchFilterValue]);

  const allFiltersActive = isFilterActive(allFiltersOption);

  return (
    <section>
      <div className="overflow-x-auto disable-scrollbars px-screen5 md:mx-auto">
        <div className="flex flex-nowrap mb-4">
          {!hideSearch && (
            <FilterSearch
              search={search}
              setSearch={setSearch}
              debouncedSearch={debouncedSearch}
            />
          )}

          <div className={`mr-4 inline-flex flex-row ${className}`}>
            {!hideAllFilterButton && (
              <Toggle
                active={allFiltersActive}
                onToggle={() => handleToggle(allFiltersOption)}
              >
                {allFiltersOption.toggleLabel}
              </Toggle>
            )}

            {filteredOptions.map((option: BaseFilterOption) => {
              const toggleLabel = getFilterToggleLabel(values, option, actions);

              return (
                <Toggle
                  key={option.name}
                  active={isFilterActive(option)}
                  onToggle={() => handleToggle(option)}
                >
                  {toggleLabel}
                  {!option.onToggle && (
                    <i className="i-caret-down filter__icon" />
                  )}
                </Toggle>
              );
            })}
            {!hideReset && (
              <button
                type="button"
                className="text-link text-caption whitespace-nowrap font-medium pr-4"
                onClick={actions.clearFilters}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={!!currentFilter}
        height={currentFilter?.modalHeight ?? 'auto'}
        fullHeight={currentFilter?.fullHeight}
        title={currentFilter?.sectionTitle ?? null}
        onClose={handleDrawerClose}
        enableScroll={allFiltersActive}
        width="lg"
      >
        {currentFilter ? (
          <div>
            <currentFilter.component
              onChange={actions.updateFilterValue}
              filterOptions={options}
              value={values[currentFilter.name]}
              values={values}
              actions={actions}
              loading={loading}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...currentFilter}
            />

            <div className="pt-5">
              <button
                type="submit"
                onClick={handleClose}
                className="btn btn--primary btn--full"
              >
                {modalText}
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
    </section>
  );
}

export default Filter;
