import React from 'react';
import cn from 'classnames';
import { Link } from '../core/router';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

type Props = {
  work: WorkEntity;
};

const CandidateBreadcrumbs = ({ work }: Props) => {
  const breadcrumbs = useBreadcrumbs({
    titleForID: work.title,
  });
  const textClasses = 'text-base uppercase';

  return (
    <div className="flex flex-row gap-2 text-xl uppercase font-semibold">
      {breadcrumbs.map((crumb, i) => {
        if (breadcrumbs.length === i + 1) {
          return (
            <h2
              key={`${crumb.title}`}
              className={cn(textClasses, 'font-normal')}
            >
              <Link to={crumb.linkTo}>{crumb.title.toUpperCase()}</Link>
            </h2>
          );
        }
        return (
          <h2
            key={`${crumb.title}`}
            className={cn(
              textClasses,
              'gap-2 flex justify-between font-semibold'
            )}
          >
            <Link
              className="underline max-w-130 whitespace-nowrap overflow-hidden overflow-ellipsis"
              to={crumb.linkTo}
            >
              {crumb.title}
            </Link>
            &gt;
          </h2>
        );
      })}
    </div>
  );
};

export default CandidateBreadcrumbs;
