import React, { useEffect, useState } from 'react';
import matchMedia from '../../utilities/MatchMedia';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import { Link } from '../core/router';

declare type Props = {
  title: string;
  steps: ProfileStep[];
  avatarUrl: string;
  avatarAlt: string;
  actionUrl: string;
};

const avatarSize = matchMedia('lg') ? 40 : 24;

const ProfileMeterCircle = ({
  title,
  steps,
  avatarUrl,
  avatarAlt,
  actionUrl,
}: Props) => {
  const [remaining, setRemaining] = useState<ProfileStep[]>([]);
  const [complete, setComplete] = useState<ProfileStep[]>([]);
  const completion = complete.length / steps.length;
  const percentage = Math.round(completion * 100);

  useEffect(() => {
    const stepsRemaining = steps.filter(step => {
      return !step.complete;
    });

    setRemaining(stepsRemaining);
  }, [steps]);

  useEffect(() => {
    const stepsCompleted = steps.filter(step => {
      return step.complete;
    });

    setComplete(stepsCompleted);
  }, [steps]);

  return (
    <>
      <div className="flex lg:flex-col lg:items-center lg:justify-center lg:text-center">
        <ScoreAvatar
          name={avatarAlt}
          imageUrl={avatarUrl}
          percentage={percentage}
          size={avatarSize}
          borderWidth={2}
        />

        <div className="pl-4 flex-1 flex flex-col justify-center lg:p-0">
          {remaining.length > 0 ? (
            <>
              <div className="content">
                <h6 className="lg:mt-4">{title}</h6>
                <p className="lg:max-w-64">
                  Having a complete profile increases your chance to get work.
                </p>
              </div>
              <Link to={actionUrl} className="mt-4 btn btn--sm btn--primary">
                {remaining.length} Remaining Task
                {remaining.length > 1 ? 's' : null}
              </Link>
            </>
          ) : (
            <div className="content">
              <h6 className="lg:mt-4">Great job - profile complete!</h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProfileMeterCircle;
