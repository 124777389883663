import React, { useEffect } from 'react';

import Page from '../components/core/Page';
import { Link } from '../components/core/router';
import BeatsMacroLockup from '../components/icons/BeatsMacroLockup';
import useClientBrandingStore from '../hooks/stores/useClientBrandingStore';
import useAnalytics from '../hooks/useAnalytics';
import cloudinary from '../utilities/Cloudinary';

const LandingHBCU = () => {
  useAnalytics('HBCU Landing');
  const setClientBranding = useClientBrandingStore(
    state => state.setClientBranding
  );

  // These aren't super necessary but good as fallback
  useEffect(() => {
    setClientBranding({
      brandLogoURL:
        'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/logo-lockup.png',
      brandCoverPhotoURL:
        'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/naomi.jpg',
      brandColor: 'var(--com-color-true-black)',
    });
  }, [setClientBranding]);

  return (
    <Page
      layout="none"
      className="page--no-padding page-bg--trueBlack"
      hideHeader
    >
      <div className="relative xl:min-h-screen xl:grid xl:grid-cols-2">
        <div className="aspect-square relative xl:pb-0">
          <img
            src={cloudinary(
              'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/g1000.jpg',
              ['w_1024']
            )}
            alt="Beats"
            className="absolute inset-0 object-cover w-full h-full"
          />
        </div>
        <div className="px-8 py-4 pb-8 flex flex-col justify-center xl:px-16 xl:py-16 3xl:py-20 bg-trueBlack xl:order-1">
          <div className="content text-white xl:max-w-3xl">
            {/* <img
              src={cloudinary(
                'https://res.cloudinary.com/communo/image/upload/v1611682532/brands/beats/black-creators-logo-white-png.png',
                ['h_96']
              )}
              alt="Beats"
              className="max-w-full h-auto mb-8"
            /> */}

            <BeatsMacroLockup />

            <p className="text-xl xl:text-4xl mt-8 leading-snug font-semibold">
              Beats x MACRO Pitch Competition
            </p>
            <p className="text-large">
              Expanding on its dynamic HBCU program, Beats is now partnering
              with MACRO, the award-winning media brand driven by people of
              color, to host a pitch competition at MACRO’s 3rd annual HBCU
              Entertainment Summit in Atlanta, GA.
            </p>
            <p className="text-large">
              HBCU students/teams in the Atlanta area are encouraged to apply
              below. Three finalist teams will be chosen to present to our panel
              of judges in October. The winning team will then have the
              opportunity to attend the Beats Black Creators Summit as well as
              the MACRO Lodge at the Sundance Film Festival 2022, ultimately
              creating a pathway to turn their vision to reality.
            </p>
            {/* <div className="notice bg-white mt-8">
              <p className="text-xl font-semibold leading-snug">
                Applications are closed. If you already have an account, you may
                sign in below.
              </p>
            </div> */}
          </div>

          <div className="md:flex md:flex-row-reverse md:justify-end -mx-4">
            <div className="px-4">
              <Link
                to="/hbcu-signup"
                className="btn btn--tertiary btn--rev btn--full md:w-auto mt-8"
              >
                GET STARTED
              </Link>
            </div>
            <div className="px-4">
              <Link
                to="/login"
                className="btn btn--tertiary btn--rev btn--outline btn--full md:w-auto mt-8"
              >
                I have an account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(LandingHBCU);
