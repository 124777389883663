import React from 'react';
import { FormTextarea } from '../../core/form';

const About = ({ errors, register, form, defaultValue }: any) => (
  <FormTextarea
    name="about"
    label="About"
    placeholder="Tell us about your professional self. e.g. I’m a freelance graphic designer specializing in…"
    errors={errors}
    ref={register({
      // required: 'About is required',
    })}
    defaultValue={defaultValue}
    richText
    form={form}
  />
);

export default About;
