/* eslint-disable no-console */
import React from 'react';
import useChatUser from '../hooks/useChatUser';
import Avatar from '../../avatar/Avatar';
import { TwilioUserAttributes } from '../../../@types/chat.d';

const ReadReceipt = ({ identity }: { identity: string }) => {
  const { user, loaded } = useChatUser(identity);

  if (!loaded || !user) return null;

  const { friendlyName, attributes } = user;
  const { avatarURL }: TwilioUserAttributes = attributes;

  return <Avatar size="xxs" avatarName={friendlyName} avatarUrl={avatarURL} />;
};

export default React.memo(ReadReceipt);
