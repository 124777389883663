import React, { useMemo, useState } from 'react';
import Button from '../../core/Button';
import useReferencesForUser from '../../reference/hooks/useReferencesForUser';
import ReferenceAvatar from '../../reference/ReferenceAvatar';
import RequestReferenceButton from '../../reference/RequestReferenceButton';
import ViewReferencesModal from '../../reference/ViewReferencesModal';

interface Props {
  user: UserEntity;
}

const ReferencesForm = ({ user }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { userReferenceRequest } = user;
  const { response } = useReferencesForUser(user.id);
  const completed = response?.references ?? [];

  const pending = useMemo(() => {
    if (!userReferenceRequest) {
      return [];
    }

    return userReferenceRequest.filter(request => {
      const { referenceRequest } = request;
      return referenceRequest.status === 'requested';
    });
  }, [userReferenceRequest]);

  return (
    <>
      <div>
        {completed.length ? (
          <>
            <div className="font-semibold mb-2">Completed</div>
            {completed.map(reference => {
              const avatarUrl = reference.avatarURL ?? reference.oAuthAvatarURL;
              const fullName = reference?.fullname || reference.oAuthFullname;
              const title = reference?.userTitle ?? reference?.oAuthTitle;
              const isCommunoUser = !!reference.userId;
              const isVisible = reference.visibility;

              return (
                <div className="mb-6" key={reference.referenceId}>
                  <ReferenceAvatar
                    title={title ?? ''}
                    name={fullName || ''}
                    avatarUrl={avatarUrl}
                    isCommunoUser={isCommunoUser}
                    className={isVisible ? 'opacity-100' : 'opacity-50'}
                    isHidden={!isVisible}
                  />
                </div>
              );
            })}
          </>
        ) : (
          'Add references to your profile to increase your chances of being shortlisted for work opportunities.'
        )}

        {pending.length ? (
          <>
            <div className="font-semibold mt-4">Pending</div>
            {pending.map(request => {
              const { referenceRequest } = request;

              return (
                <div className="my-2" key={request.id}>
                  {referenceRequest.contactEmail}
                </div>
              );
            })}
          </>
        ) : null}

        <div className="sm:flex sm:space-x-4">
          {completed.length ? (
            <div className="mt-4 w-full">
              <Button
                text="View references"
                className="w-full"
                color="primary"
                fill="outline"
                onClick={() => {
                  setModalOpen(true);
                }}
              />
            </div>
          ) : null}
          <div className="mt-4 w-full">
            <RequestReferenceButton fill="solid" />
          </div>
        </div>
      </div>

      <ViewReferencesModal
        user={user}
        references={completed}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default ReferencesForm;
