import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function usePlans(): {
  isLoading: boolean;
  plans: PlanEntity[];
} {
  const { data, isLoading } = useQuery(['plans'], () => Api.getPlans(), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    isLoading,
    plans: data,
  };
}
