import * as Doka from '../../vendor/browser-doka/doka.min';
import '../../vendor/browser-doka/doka.min.css';
import CropMasks from './CropMasks';

const defaultConfig: any = {
  cropAspectRatio: 1,
  cropMask: CropMasks.circle,
  utils: ['crop', 'filter', 'color'],
};

function createDoka(config: object = {}) {
  const mergedConfig = {
    ...defaultConfig,
    ...config,
  };
  const doka = Doka.create(mergedConfig);

  doka.on('load', (e: any) => {
    if (e.type === 'image/gif') {
      doka.setOptions({
        utils: ['resize'],
      });
    }

    // Force crop to center of image as a square when loading an image
    doka.setData({
      crop: {
        center: {
          x: 0.5,
          y: 0.5,
        },
        zoom: 1,
        rotation: 0,
        aspectRatio: mergedConfig.cropAspectRatio,
      },
    });
  });

  return doka;
}

export default {
  createDoka,
};
