import React, { useContext } from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import UserContext from '../components/user/Context';
import SetPassword from '../components/user/views/NoPassword';

const AuthNoPassword = () => {
  const { authDetails } = useContext(UserContext);
  useAnalytics('Setting password', { email: authDetails.email });

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="No Password"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      <SetPassword />
    </Page>
  );
};

export default AuthNoPassword;
