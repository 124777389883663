import React from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import { Link } from '../../core/router';

type Props = {
  workId: string;
  trackingEventName: string;
  width?: string;
};

const SuggestedCandidatesButton = ({
  workId,
  trackingEventName,
  width = 'md:w-auto',
}: Props) => {
  const { track } = useAnalytics();

  return (
    <div className="flex justify-between gap-2">
      <Link
        className={`flex-1 btn btn--sm btn--primary btn--outline ${width}`}
        onClick={async () => track(trackingEventName)}
        to={{
          pathname: `/work/${workId}/candidates/suggested`,
        }}
      >
        Suggested Candidates
      </Link>
    </div>
  );
};
export default SuggestedCandidatesButton;
