import React from 'react';
import { LoadingBalls } from '../../core/Loading';
import Divider from '../../core/Divider';
import WorkApplyNowCTA from './WorkApplyNowCTA';
import WorkScore from '../WorkScore';
import Application from '../Application';
import useCurrentUser from '../../../hooks/useCurrentUser';
import meetsWorkRequirements from '../utils/meetsWorkRequirements';
import useCurrentPlan from '../../../hooks/useCurrentPlan';

type Props = {
  work: WorkEntity;
  qualification: QualificationEntity | null;
  refreshQualification: Function;
  isClosed: boolean;
};

const Footer = ({
  work,
  qualification,
  refreshQualification,
  isClosed,
}: Props) => {
  if (!qualification) {
    return <LoadingBalls isActive />;
  }

  const meetsRequirements = qualification
    ? meetsWorkRequirements(qualification.requirements)
    : false;

  let showCandidateScore = !!qualification;
  if (qualification.application && work.isOneClickApply) {
    showCandidateScore = false;
  }

  const canApply = !!(qualification && meetsRequirements);

  return (
    <>
      {qualification && showCandidateScore ? (
        <WorkScore
          work={work}
          qualification={qualification}
          refresh={refreshQualification}
        />
      ) : null}

      {canApply && (
        <Application
          work={work}
          qualification={qualification}
          refreshQualification={refreshQualification}
          isClosed={isClosed}
        />
      )}
    </>
  );
};

const WrappedFooter = ({
  work,
  isClosed,
  qualification,
  refreshQualification,
}: Props) => {
  const { id: userId } = useCurrentUser();
  const plan = useCurrentPlan();

  // Hide for Communo Brands
  if (plan?.type === 'brand') {
    return null;
  }

  return (
    <div id="workFooter">
      <Divider className="mb-8" />
      {userId ? (
        <Footer
          work={work}
          qualification={qualification}
          refreshQualification={refreshQualification}
          isClosed={isClosed}
        />
      ) : (
        <div className="mt-12 text-right">
          <WorkApplyNowCTA work={work} />
        </div>
      )}
    </div>
  );
};

export default WrappedFooter;
