import React from 'react';
import classnames from 'classnames';

interface CircleProps {
  className?: string;
  children: React.ReactNode;
}

const Circle = ({ className, children }: CircleProps) => {
  return (
    <div className={classnames('w-5 h-5 rounded-full relative', className)}>
      <div
        className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
        style={{ lineHeight: 0 }}
      >
        {children}
      </div>
    </div>
  );
};

const Complete = () => (
  <Circle className="bg-green-900 text-white">
    <i
      className="i-check"
      style={{ fontSize: '0.7rem', fontWeight: 'bold', lineHeight: 0 }}
    />
  </Circle>
);

const Partial = () => (
  <Circle className="bg-gold-900 text-white">
    <i
      className="i-check"
      style={{ fontSize: '0.7rem', fontWeight: 'bold', lineHeight: 0 }}
    />
  </Circle>
);

const Incomplete = () => (
  <Circle className="bg-red-900 text-white">
    <i
      className="i-close"
      style={{ fontSize: '0.5rem', fontWeight: 'bold', lineHeight: 0 }}
    />
  </Circle>
);

const Default = () => (
  <Circle className="border-2 border-grey-500 text-grey-500">
    <i
      className="i-add"
      style={{ fontSize: '0.7rem', fontWeight: 'bold', lineHeight: 0 }}
    />
  </Circle>
);

export type MetricStatus = 'complete' | 'partial' | 'incomplete' | null;
type Props = { status?: MetricStatus | null };

const MetricStatusIcon = ({ status }: Props) => {
  if (status === 'complete') return <Complete />;
  if (status === 'partial') return <Partial />;
  if (status === 'incomplete') return <Incomplete />;

  return <Default />;
};

export default MetricStatusIcon;
