import { useQuery } from '@tanstack/react-query';
import { ChannelInteraction } from '../../../@types/chat.d';
import Api from '../Api';

const useLatestChannels = () => {
  const { data, isLoading, refetch, isFetching } = useQuery<
    ChannelInteraction[]
  >(['latestChannels'], () => Api.latest(), {
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
  });

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
export default useLatestChannels;
