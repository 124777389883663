import React, { useContext } from 'react';

import { LoadingRing } from '../../core/Loading';
import useCurrentUser from '../../../hooks/useCurrentUser';
import EducationSummary from '../EducationSummary';
import { HashLink } from '../../core/router';
import { HBCU_PLAN_ID } from '../../../config';
import EducationContext from '../Context';

const ListEducation = ({ user }: { user: UserEntity }) => {
  const { education, isFetching } = useContext(EducationContext);
  const planId = user.primaryMembershipUser?.membership.plan.id;

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  return (
    <>
      {education?.map(edu => (
        <div key={edu.id} className="mb-8">
          <EducationSummary
            education={edu}
            isStudentEducation={planId === HBCU_PLAN_ID}
            showEdit
          />
        </div>
      ))}

      {education && education.length === 0 ? (
        <div>
          <HashLink
            to="#/edit-profile/education/create"
            className="btn btn--outline w-full mt-4 mb-8"
          >
            <i className="i-add mr-2" /> <span>Add education</span>
          </HashLink>
        </div>
      ) : null}
    </>
  );
};

const WrappedListEducation = () => {
  const { user: authUser } = useCurrentUser();

  if (!authUser) {
    return null;
  }

  return <ListEducation user={authUser} />;
};

export default WrappedListEducation;
