import {
  Client as TwilioClient,
  Conversation as TwilioConversation,
  Participant as TwilioMember,
  Message as TwilioMessage,
  User as TwilioUser,
  RestPaginator as TwilioPaginator,
  ConversationUpdatedEventArgs,
  ParticipantUpdatedEventArgs,
  MessageUpdatedEventArgs,
  UserUpdatedEventArgs,
  ConnectionState,
} from '@twilio/conversations';

export type TwilioConversationChannelAttributes = {
  id: string;
  type: string;
  title: string;
  description: string;
  senderUserId: string;
  visibility: 'public' | 'private';
};

export type TwilioConversationWorkAttributes = {
  id: string;
  applicationId: string;
  applicantUserId: string;
  posterUserId: string;
};

export type TwilioConversationAttributes = {
  createdBy?: string;
  type?: 'direct' | 'group' | 'generosity' | 'work';
  avatarURL?: string | null;
  favor?: TwilioConversationChannelAttributes;
  work?: TwilioConversationWorkAttributes;
  moderators?: {
    primary: string;
  };
  channelState?: string;
};

export type TwilioMemberAttributes = {
  isNotifiable?: boolean;
};

export type TwilioMessageLinkDataAttributes =
  | {
      title: string;
      description: string;
      type: string;
      image: string;
      url: string;
      favicon: string;
      siteName: string;
      imageWidth?: string;
      imageHeight?: string;
      articlePublishedTime?: string;
      articleModifiedTime?: string;
      time?: number;
    }
  | undefined
  | null;

export type TwilioMessageReplyDataAttributes = {
  recipientId: string;
  messageSid: string;
  message: string;
};

export type TwilioMessageAttributes = {
  linkData?: TwilioMessageLinkDataAttributes;
  replyData?: TwilioMessageReplyDataAttributes;
};

export type TwilioUserAttributes = {
  avatarURL?: string | null;
  legacy?: boolean;
  isDeactivated?: boolean;
  isNotContactable?: boolean;
  isOblivious?: boolean;
  title?: string;
  location?: string;
};

// TODO: Find a better way of extending a namespace.
// e.g. IClientConnectionState should be IClient.ConnectionState

export interface IClient extends TwilioClient {}
export interface IClientConnectionState extends ConnectionState {}

export interface IChannel extends TwilioConversation {
  attributes: TwilioConversationAttributes;
  friendlyName: string;
}
export interface IChannelUpdatedEventArgs
  extends ConversationUpdatedEventArgs {}

export interface IMember extends TwilioMember {
  attributes?: TwilioMemberAttributes;
  identity: string;
}
export interface IMemberUpdatedEventArgs extends ParticipantUpdatedEventArgs {}

export interface IMessage extends TwilioMessage {
  attributes?: TwilioMessageAttributes;
  author: string;
  body: string;
}
export interface IMessageUpdatedEventArgs extends MessageUpdatedEventArgs {}

export interface IUser extends TwilioUser {
  attributes: TwilioUserAttributes;
  friendlyName: string;
  isOnline: boolean;
}
export interface IUserUpdatedEventArgs extends UserUpdatedEventArgs {}
export interface IPaginator extends TwilioPaginator {}

/**
 * This is the "channel_interaction" table data shape on the backend
 */
export type ChannelInteraction = {
  /**
   * The unique id assigned to the interaction
   */
  id: string;

  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;

  /**
   * The conversation id assigned by Twilio to a conversation
   *
   * This is also the channelSid
   */
  conversationId: string;

  /**
   * The amount of participants in a conversation
   * TODO: This should be changed on the backend to a better name
   */
  participantCount: number;

  status: string;
  title: string;
  type: string;
  user: UserEntity;
  vesion: number;
  description: string;
  avatars: Array;
};

/**
 * A type predicate that deduces the attributes as a TwilioUserAttributes type
 *
 * @param attributes - the attributes to be checked
 * @returns a boolean that dictates whether the attributes is a TwilioUserAttributes or JSONValue
 */
export function areUserAttributes(
  attributes: TwilioUserAttributes | JSONValue
): attributes is TwilioUserAttributes {
  const areValidAttributes = Object.entries(
    attributes as TwilioUserAttributes
  ).some(attribute => attribute !== undefined);

  return areValidAttributes;
}

/**
 * A type predicate that returns the message as a deduced type based on attributes
 *
 * @param member - the message to be checked
 * @returns a boolean that dictates whether the message is a Conversation or an IMember
 */
export function isMember(member: IMember | TwilioMember): member is IMember {
  return (member as IMember).attributes !== undefined;
}

/**
 * A type predicate that returns the message as a deduced type based on attributes
 *
 * @param message - the message to be checked
 * @returns a boolean that dictates whether the message is a Conversation or an IMessage
 */
export function isMessage(
  message: IMessage | TwilioMessage
): message is IMessage {
  return (message as IMessage).attributes !== undefined;
}

/**
 * A type predicate that returns the user as a deduced type based on attributes
 *
 * @param user - the user to be checked
 * @returns a boolean that dictates whether the user is an IUser or a User
 */
export function isUser(user: IUser | TwilioUser): user is IUser {
  return (
    (user as IUser).attributes !== undefined &&
    !!areUserAttributes((user as IUser).attributes)
  );
}
