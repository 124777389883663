const planTypes = {
  individual: 'Individual',
  agency: 'Agency',
  partner: 'Partner',
  unknown: 'Unknown',
};

declare type PlanType = 'individual' | 'agency' | 'partner' | 'unknown';

// eslint-disable-next-line import/prefer-default-export
export const formatPlanType = (planType: PlanType) => {
  return planTypes?.[planType] || '';
};

export function getCouponDiscount(
  recurlyPlan: RecurlyPlanEntity,
  coupon?: RecurlyCoupon
): number | undefined {
  if (!coupon) {
    return 0;
  }

  const { unitAmount } = recurlyPlan;

  if (coupon.discount.rate) {
    return Math.max(0, unitAmount * coupon.discount.rate);
  }

  return coupon.discount.amount?.USD ?? 0;
}
