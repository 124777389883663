import React from 'react';
import { FormInput } from '../../core/form';

export default ({ errors, register }: any) => (
  <FormInput
    name="price"
    label="Event price (enter 0 for free)"
    type="number"
    min="0"
    placeholder="Price"
    errors={errors}
    ref={register}
  />
);
