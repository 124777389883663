import React, { useCallback, useContext } from 'react';
import {
  Draggable,
  /* eslint-disable no-unused-vars */
  DroppableStateSnapshot,
  DroppableProvided,
  DraggableProvided,
  /* eslint-enable no-unused-vars */
} from 'react-beautiful-dnd';

import capitalize from '../../utilities/Capitalize';
import ColorUtil from '../../utilities/ColorUtil';
import {
  isStatusFrozen,
  statusColor,
  statusColumnPosition,
  publicStatusColumnPosition,
  clientShortlistedColumnWidth,
  statusLabel,
} from '../../utilities/ApplicationStatus';
import WorkCandidateCard from './WorkCandidateCard';
import ExportCandidatesToList from './ExportCandidatesToList';
import UserContext from '../user/Context';
import { ENABLE_INVITE_TO_APPLY } from '../../config';

type Props = {
  status: ApplicationStatus;
  applications: ApplicationEntity[];
  clientRejectedApplications: boolean;
  dropProvided: DroppableProvided;
  dropSnapshot: DroppableStateSnapshot;
};

const Applications = ({
  applications,
  status,
}: {
  applications: ApplicationEntity[];
  status: ApplicationStatus;
}) => {
  const { isPublic, publicMagicKey } = useContext(UserContext);
  const WorkCandidateCardCallback = useCallback(
    application => (
      <WorkCandidateCard
        application={application}
        to={`?=applicationId=${application.id}${
          isPublic && publicMagicKey ? `&=magicKey=${publicMagicKey}` : ''
        }`}
      />
    ),
    [isPublic, publicMagicKey]
  );

  const publicShortlistedColumn = isPublic && status === 'shortlisted';

  const gridPosition = (index: number): string => {
    const columnNumber = (index % clientShortlistedColumnWidth) + 1;
    // eslint-disable-next-line no-bitwise
    const rowNumber = (index / clientShortlistedColumnWidth + 1) >> 0;
    const colStart = `2xl:col-start-${columnNumber}`;
    const colSpan = '2xl:col-span-1';
    const rowStart = `2xl:row-start-${rowNumber}`;
    const rowSpan = '2xl:row-span-1';
    return `${colStart} ${colSpan} ${rowStart} ${rowSpan}`;
  };

  const compareScores = (a: ApplicationEntity, b: ApplicationEntity) => {
    return (b.score ?? 0) - (a.score ?? 0);
  };

  const sortedApplications: ApplicationEntity[] = isPublic
    ? applications.sort(compareScores)
    : applications;

  return (
    <div
      className={
        publicShortlistedColumn
          ? 'shortlisted-column grid grid-cols-1 2xl:grid-cols-3 gap-4'
          : ''
      }
    >
      {sortedApplications.map((application, index) => (
        // Make card non-draggable if application is 'awarded'
        <div className={gridPosition(index)}>
          {application.status === 'awarded' ? (
            <div
              key={application.id}
              className={`mb-4 ${
                status === 'client_rejected' || status === 'rejected'
                  ? 'grayscale'
                  : ''
              }`}
            >
              {WorkCandidateCardCallback(application)}
            </div>
          ) : (
            <Draggable
              key={application.id}
              draggableId={application.id}
              index={index}
              isDragDisabled={isStatusFrozen(application.status, isPublic)}
            >
              {(dragProvided: DraggableProvided) => {
                return (
                  <div
                    ref={dragProvided.innerRef}
                    className={`mb-4 ${
                      status === 'client_rejected' || status === 'rejected'
                        ? 'grayscale'
                        : ''
                    }`}
                    style={dragProvided.draggableProps.style}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...dragProvided.dragHandleProps}
                    {...dragProvided.draggableProps}
                    /* eslint-enable react/jsx-props-no-spreading */
                  >
                    {WorkCandidateCardCallback(application)}
                  </div>
                );
              }}
            </Draggable>
          )}
        </div>
      ))}
    </div>
  );
};

const WorkCandidatesColumn = ({
  status,
  applications,
  clientRejectedApplications,
  dropProvided,
  dropSnapshot,
}: Props) => {
  const gridPosition = ENABLE_INVITE_TO_APPLY
    ? statusColumnPosition(status, clientRejectedApplications)
    : '';

  const color = statusColor(status);
  const getStyle = useCallback(() => {
    if (!dropSnapshot.isDraggingOver) {
      return {};
    }

    return {
      backgroundColor: ColorUtil.hexToRGB(color, 0.4),
    };
  }, [dropSnapshot.isDraggingOver, color]);

  const publicGridPosition = publicStatusColumnPosition(status);
  const { isPublic } = useContext(UserContext);
  const userIds = applications.map(application => application.user.id);

  return (
    <div
      className={`min-h-[100px] bg-white p-0 overflow-y-auto flex flex-col disable-scrollbars ${
        isPublic ? publicGridPosition : gridPosition
      }`}
      style={getStyle()}
    >
      <div className="bg-white sticky top-0 z-10 p-4 pb-2 mb-2">
        <div className="flex flex-row flex-wrap items-center">
          <div className="font-bold flex-1 text-lg">
            {capitalize(statusLabel(status, isPublic))}
          </div>

          <div>
            <ExportCandidatesToList userIds={userIds} />
            <span
              className="w-8 h-8 inline-flex items-center justify-center rounded-lg text-white text-sm"
              style={{ background: color }}
            >
              {applications.length}
            </span>
          </div>
        </div>
      </div>

      <div ref={dropProvided.innerRef} className="px-4 grow">
        <Applications status={status} applications={applications} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
};

export default WorkCandidatesColumn;
