import React, { useState } from 'react';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { LoadingBalls } from '../../core/Loading';
import SuggestedCandidateSearchList from './SuggestedCandidateSearchList';
// import { OuterLocation } from './location';
import useWorkQuery from '../../../hooks/useWorkQuery';
import { addError } from '../../../services/Messaging';
import useOwnsWorkPost from '../../../hooks/useOwnsWorkPost';
import CandidatesHeader from '../../work/CandidatesHeader';
import workApi from '../../work/Api';

export interface ScoreMetrics {
  score: number;
  maxScore: number;
  percentage: number;
}

export interface ScoredCandidate extends UserEntity {
  candidateScore: ScoreMetrics;
}

type State = {
  page: number;
  workId: string;
  filters: {
    role: string;
    // location: OuterLocation;
    skills: string;
  };
  candidateListLoaded: boolean;
  workDetailsLoaded: boolean;
  userAllResults: ScoredCandidate[];
};

export default function SuggestedCandidatesProvider() {
  const { workId: parsedWorkId } = useParams<{ workId: string }>();

  const [state, setState] = useState<State>({
    page: 0,
    workId: parsedWorkId,
    filters: {
      role: '',
      // location: {} as OuterLocation,
      skills: '',
    },
    candidateListLoaded: false,
    workDetailsLoaded: false,
    userAllResults: [],
  });

  const buildQueryString = (obj: Record<string, string>) => {
    let queryString = '?';
    Object.keys(obj).forEach(key => {
      if (key in obj) {
        queryString += `${key}=${obj[key]}&`;
      }
    });
    return queryString.slice(0, -1);
  };

  const loadFetchedWorkParams = (
    role: string,
    skills: string
    // location: OuterLocation
  ) => {
    setState(s => ({
      ...s,
      filters: {
        role,
        // location,
        skills,
      },
      workDetailsLoaded: true,
    }));
  };

  const loadFetchedCandidates = (candidatesData: ScoredCandidate[]) => {
    setState(s => ({
      ...s,
      candidateListLoaded: true,
      userAllResults: candidatesData,
    }));
  };

  const handleSuccess = (data: WorkEntity) => {
    const role = data.role?.name ?? '';
    const skills = JSON.stringify(data.skills.map(skill => skill.name));
    // const { location } = data;

    loadFetchedWorkParams(
      role,
      skills
      // (location as unknown) as OuterLocation,
    );
  };

  const work = useWorkQuery(state.workId, {
    onSuccess: handleSuccess,
  });

  const { data: workData } = work;
  const ownsWorkPost = useOwnsWorkPost(workData);

  const queryString = buildQueryString(state.filters);

  const candidates = useQuery(
    ['suggestedCandidates', state.workId, state.workDetailsLoaded],
    () =>
      state.workDetailsLoaded
        ? workApi.getSuggestedCandidates(state.workId, queryString)
        : [],
    {
      onSuccess: loadFetchedCandidates,
    }
  );

  if (work.isError || candidates.isError) {
    Sentry.captureException(work.error);
    addError(`We were not able to fetch the work. Please try again.`);
    return null;
  }

  if (work.isFetched && !ownsWorkPost) {
    return (
      <div className="flex justify-center mx-auto w-9/10">
        <h3>Not Allowed</h3>
      </div>
    );
  }

  const dataIsLoading = work.isLoading || candidates.isLoading;
  const dataLoadedInState =
    state.candidateListLoaded && state.workDetailsLoaded;
  const dataIsReady = !dataIsLoading && dataLoadedInState;

  const SuggestedCandidatesBody = () => {
    if (!dataIsReady) {
      return <LoadingBalls isActive />;
    }
    return dataIsReady && state.userAllResults && work.data ? (
      <SuggestedCandidateSearchList
        suggestedCandidates={state.userAllResults}
        work={work.data}
      />
    ) : (
      <div />
    );
  };

  return (
    <div className="py-8 flex flex-col gap-4">
      <CandidatesHeader work={workData} pretext="Suggested Candidates" />
      <SuggestedCandidatesBody />
    </div>
  );
}
