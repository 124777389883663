import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import useNavigate from '../../../../hooks/useNavigate';
import UserContext from '../../../user/Context';

type Props = {
  user: UserEntity;
  setShowOnboarding: (bool: boolean) => void;
  // updateUser: (data: Partial<UserEntity>) => Promise<UserEntity>;
  // isActive: boolean;
  goTo: Function;
};

const AgencyContent = React.memo(() => {
  // const { state } = useStep();
  // const { goToNextStep } = state;

  return (
    <>
      <div className="text-center">
        <p className="text-lg font-light mb-4">
          Now we need to get some information about your company for your agency
          profile.
        </p>
      </div>

      {/* <div className="mt-auto md:mt-8">
        <button
          type="button"
          onClick={() => {
            goToNextStep();
          }}
          className="btn btn--outline btn--rev btn--full"
        >
          Continue
        </button>
      </div> */}
    </>
  );
});

const IndividualContent = React.memo(() => {
  const { user, setShowOnboarding } = useContext(UserContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleHideOnboarding = () => {
    setShowOnboarding(false);
    return true;
  };

  const handleRedirect = () => {
    if (pathname.startsWith('/onboarding'))
      navigate('/feed', 'none', 'replace');
    return true;
  };

  return (
    <>
      <div className="text-center">
        <p className="text-lg font-light mb-4">
          That&apos;s it – you&apos;re in.
        </p>
        <p
          className="text-lg font-light mb-4"
          // data-swiper-parallax="-200"
        >
          But adding more information to your profile greatly increases your
          chances of being noticed.
        </p>
      </div>

      <div className="mt-auto md:mt-8">
        <div
          role="none"
          onClick={(e: any) => {
            handleHideOnboarding();
            e.preventDefault();
            navigate('/feed', 'none', 'push');
            navigate(`/members/user/${user?.id}`, 'none', 'push');
          }}
          className="btn btn--sm btn--primary btn--full mb-4"
        >
          Complete your profile
        </div>
        <div
          role="none"
          onClick={() => {
            handleHideOnboarding();
            handleRedirect();
          }}
          className="btn btn--sm btn--outline btn--full"
        >
          Continue
        </div>
      </div>
    </>
  );
});

const Complete = () => {
  const { user } = useContext(UserContext);
  const planType = user?.primaryMembershipUser?.membership.plan.type;

  return (
    <>
      <div className="text-center">
        <h3 className="mb-4">Welcome to Communo, {user?.firstName}</h3>
      </div>

      {planType === 'agency' && <AgencyContent />}
      {planType !== 'agency' && user && <IndividualContent />}
    </>
  );
};

export default Complete;
