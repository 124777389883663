import useCurrentUser from '../../../hooks/useCurrentUser';
import channelMemberRole from '../utilities/channelMemberRole';
import { IChannel } from '../../../@types/chat.d';
import useChatChannelMembers from './useChatChannelMembers';

export type CurrentUserRole = {
  isModerator: boolean;
  isOwner: boolean;
  isParticipant: boolean;
};

/**
 * Returns the current user's role in the current channel
 *
 * @param channel - the channel in question
 */
export default function useChannelRole(channel: IChannel): CurrentUserRole {
  const { id } = useCurrentUser();
  const { channelMembers } = useChatChannelMembers(channel.sid);
  const loggedInMember = channelMembers[id];

  const { isChannelAdmin: isModerator } = channelMemberRole(
    loggedInMember?.roleSid
  );
  const isOwner = channel.attributes.createdBy === id;

  const isParticipant = !isOwner && !isModerator;

  return { isModerator, isOwner, isParticipant };
}
