import React from 'react';
import ReactStars from 'react-stars';
import useUser from '../../../hooks/useUser';
import { formatDateShortMonth } from '../../../utilities/dateTime';
import Avatar from '../../avatar/Avatar';
import truncateNoWordSplit from '../../../utilities/truncateNoWordSplit';

interface Props {
  applicationNote: ApplicationNoteEntity;
}

const MAX_TRUNCATED_LENGTH = 70;
const ApplicationNote = ({ applicationNote }: Props) => {
  const { createdAt, commenterUserId, name, rating, note } = applicationNote;
  const [expanded, setExpanded] = React.useState(false);

  const { truncatedNote, isTruncated } = truncateNoWordSplit(
    note,
    MAX_TRUNCATED_LENGTH
  );

  let commenter: UserEntity | null = null;

  try {
    const userData = useUser(commenterUserId || null);
    commenter = userData.user;
  } catch (e) {
    // Fail silently and use null value for commenter.
  }

  return (
    <div className="bg-white">
      <div className="flex py-2 gap-4 justify-center items-center">
        <Avatar
          className="flex-shrink-0 self-start mt-4"
          size="xl"
          avatarUrl={commenter?.avatarURL || ''}
          avatarName={name}
        />
        <div className="flex flex-col flex-grow gap-1">
          <ReactStars
            count={5}
            value={rating || 0}
            edit={false}
            size={18}
            color2="#E8A318"
          />
          <div className="-mt-2 flex flex-col lg:flex-row justify-between lg:items-center">
            <div className="font-bold text-md">{name}</div>
            <div className="text-sm">{formatDateShortMonth(createdAt)}</div>
          </div>
          <div
            className={`min-w-full w-32 lg:w-52 ${
              expanded ? '' : 'max-h-screen15'
            } break-words overflow-hidden text-md`}
          >
            {note && (expanded ? note : truncatedNote)}
            {isTruncated && (
              <span className="ml-2 underline w-fit">
                <button
                  className="inline-block underline"
                  type="button"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? 'Show Less' : 'Show More'}
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationNote;
