import React from 'react';
import { Error404 } from '../components/error';
import SubmitRecommendationForm from '../components/recommendations/submit/SubmitRecommendationForm';

const SubmitRecommendation = ({ match }: any) => {
  const { submissionToken } = match.params;

  return submissionToken ? (
    <SubmitRecommendationForm submissionToken={submissionToken} />
  ) : (
    <Error404 />
  );
};
export default SubmitRecommendation;
