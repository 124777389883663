import React, { useContext } from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import UserContext from '../components/user/Context';
import PlanLanding from '../components/plan/PlanLanding';

export default ({ match }: any) => {
  const { planSlug, coupon } = match.params;
  const { authDetails } = useContext(UserContext);
  useAnalytics('Plans', { email: authDetails.email });

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Plans"
      mobileHeader={() => null}
      hideHeader
    >
      <PlanLanding planSlug={planSlug} coupon={coupon} />
    </Page>
  );
};
