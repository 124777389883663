import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'querystringify';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import { isDesktop } from '../../utilities/MatchMedia';
import useNavigate from '../../hooks/useNavigate';
import ChannelUnreadCountBadge from '../chat/components/ChannelUnreadCountBadge';
import RibbonIcon from '../icons/RibbonIcon';

type Props = {
  to: string;
  application: ApplicationEntity;
};

// Requires a 'Relative' parent.
const HiredRibbon = () => {
  return (
    <div className="bg-black rounded-lg m-1 p-1 absolute top-0 right-0 z-1">
      <RibbonIcon className="text-white w-6 h-8 mx-auto inline-block" />
    </div>
  );
};

const WorkCandidateCard = ({ to, application }: Props) => {
  const navigate = useNavigate();
  const { user } = application;
  const { firstName, lastName, roles } = user;
  const location = useLocation();
  const { applicationId } = parse(location.search) as any;
  const activeClass = applicationId === application.id ? 'ring' : '';

  const planType = useMemo(
    () => user.primaryMembershipUser?.membership.plan.type,
    [user]
  );

  const membership = useMemo(() => user.primaryMembershipUser?.membership, [
    user,
  ]);

  const name = useMemo(
    () =>
      planType === 'agency' && membership
        ? membership.name
        : `${firstName} ${lastName}`,
    [planType, membership, firstName, lastName]
  );

  const role = useMemo(() => {
    return planType === 'agency' && membership?.roles
      ? membership.roles.map(r => r.role.nameForMembership).join(', ') ||
          'Unknown role'
      : roles?.map(r => r.role.name).join(', ') || 'Unknown role';
  }, [roles, planType, membership]);

  const imageUrl = useMemo(() => {
    return planType === 'agency' && membership
      ? membership.logoURL
      : user.avatarURL;
  }, [planType, user, membership]);

  const handleClick = () => {
    navigate(to, 'none', isDesktop ? 'replace' : 'push');
  };

  return (
    <div
      role="none"
      onClick={handleClick}
      className={`${activeClass} active:rotate-12 transition-transform origin-center`}
    >
      <div className="shadow-lg hover:shadow-xl bg-white relative">
        {application.status === 'awarded' && <HiredRibbon />}
        <div className="p-4">
          <div className="relative flex justify-center mb-2 w-full">
            {application.chatChannelSid ? (
              <ChannelUnreadCountBadge
                className="absolute top-0 right-0 z-10"
                channelSid={application.chatChannelSid}
              />
            ) : null}
            <ScoreAvatar
              name={name}
              imageUrl={imageUrl}
              percentage={application.score}
              className={
                application.status === 'client_rejected' ||
                application.status === 'rejected'
                  ? 'opacity-50'
                  : ''
              }
            />
          </div>
          <div className="truncate">
            <div className="font-medium truncate mb-1">{name}</div>
            <div className="text-sm font-light truncate">{role}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCandidateCard;
