import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../components/user/Api';
import UserContext from '../components/user/Context';

export default function(
  userId: string | null
): {
  user: UserEntity;
  isLoading: boolean;
  isFetching: boolean;
  refetch: Function;
} {
  if (!userId) {
    throw new Error('userId is required');
  }

  const { isPublic } = useContext(UserContext);
  const { data, isLoading, refetch, isFetching } = useQuery(
    ['user', userId],
    () => Api.getUser(userId, isPublic),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    user: data,
    isLoading,
    isFetching,
    refetch,
  };
}
