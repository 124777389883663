import React from 'react';
import { IonBackButton } from '@ionic/react';

type Props = {
  defaultHref?: string;
  className?: string;
  text?: any;
};

const BackButton = ({
  text = 'Back',
  defaultHref = '/',
  className = 'btn',
}: Props) => {
  return (
    <IonBackButton
      icon=""
      text={text}
      defaultHref={defaultHref}
      className={`${className} py-0`}
    />
  );
};

export default React.memo(BackButton);
