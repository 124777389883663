import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

const Campaign = ({ errors, register }: any) => {
  const ref = register({ required: 'Campaign attributes are required' });
  return (
    <FormGroup
      required
      name="isCampaign"
      label="Should this post apply button link to the login page when shared outside of the platform?"
      errors={errors}
    >
      <div className="radio-inline">
        <FormRadio name="isCampaign" label="Yes" value="1" ref={ref} />
        <FormRadio name="isCampaign" label="No" value="0" ref={ref} />
      </div>
    </FormGroup>
  );
};
export default Campaign;
