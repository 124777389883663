import React from 'react';
import OnboardingLayout from '../onboarding/OnboardingLayout';
import { SubmitReferenceContextProvider } from './context/SubmitReferenceContext';
import SubmitReferenceStepper from './SubmitReferenceStepper';

interface Props {
  leaveFor?: string;
}

const LeaveReferenceView = ({ leaveFor }: Props) => {
  return (
    <OnboardingLayout organization="communo">
      <>
        <SubmitReferenceContextProvider leaveFor={leaveFor}>
          <SubmitReferenceStepper />
        </SubmitReferenceContextProvider>
      </>
    </OnboardingLayout>
  );
};
export default LeaveReferenceView;
