import React from 'react';
import { currency } from '../../../utilities/Number';
import LineItem from './LineItem';

type Props = {
  item: RecurlyItem;
};

const UpgradePayToPostPreview = ({ item }: Props) => {
  const { name, currencies } = item;
  const [charge] = currencies;

  return (
    <div className="mt-4 pb-2 space-y-2">
      <div className="font-semibold">
        <LineItem label={name} value={`${currency(charge.unit_amount)}`} />
      </div>
    </div>
  );
};
export default UpgradePayToPostPreview;
