import React, { useEffect, useState, useMemo } from 'react';
import FormGroup from './FormGroup';
import Textarea from './Textarea';
import Editor from '../Editor/Editor';
import CharacterCounter from '../CharacterCounter';
import markdown from '../../../utilities/Markdown';

// If richText prop is set, form is required
type RichText =
  | { richText?: never; form?: any }
  | { richText: boolean; form: any };

// If characterCount prop is set, form is required
type CharacterCount = { characterCount?: number; form?: any };

type Props = Partial<Omit<HTMLTextAreaElement, 'form'>> &
  CharacterCount &
  RichText & {
    name: string;
    label?: string;
    labelSubtext?: string;
    errors: any;
    required?: boolean;
    rows?: number;
    maxLength?: number;
    placeholder: string;
    className?: string;
    inputClasses?: string;
    hint?: string;
    onChange?: Function;
  };

export default React.forwardRef(
  (
    {
      name,
      label,
      labelSubtext,
      errors,
      required = false,
      placeholder = '',
      rows = 10,
      className = '',
      inputClasses = '',
      hint = '',
      richText = false,
      maxLength,
      form,
      onChange,
      defaultValue = '',
      characterCount,
    }: Props,
    ref: any
  ) => {
    const { setValue, trigger, watch, register } = form || ({} as any);
    const [text, setText] = useState('');
    const isRichText = useMemo(() => richText, [richText]);
    const value = isRichText ? text : watch?.(name);

    const handleRichTextChange = (html: string, plainText: string) => {
      setValue(`${name}[html]`, html);
      setValue(`${name}[text]`, plainText);
      if (errors?.[name]) trigger(name);
      if (onChange) onChange(html, plainText);
      if (characterCount) setText(plainText);
    };

    useEffect(() => {
      if (setValue && defaultValue && !isRichText) setValue(name, defaultValue);
    }, [isRichText, name, defaultValue, setValue]);

    const richTextValue = useMemo(() => markdown(defaultValue), [defaultValue]);

    return (
      <FormGroup
        label={label}
        name={isRichText ? 'text' : name}
        required={required}
        errors={isRichText ? errors?.[name] : errors}
        className={className}
        hint={hint}
        labelSubtext={labelSubtext}
      >
        <div className="relative">
          <div className="relative z-0">
            {isRichText ? (
              <>
                <Editor
                  placeholder={placeholder}
                  defaultValue={richTextValue}
                  onChange={handleRichTextChange}
                />
                <Textarea
                  name={`${name}[html]`}
                  defaultValue={defaultValue}
                  placeholder="html"
                  rows={1}
                  ref={register}
                  className="hidden"
                />
                <Textarea
                  name={`${name}[text]`}
                  defaultValue={defaultValue}
                  placeholder="text"
                  rows={1}
                  ref={ref}
                  className="hidden"
                />
              </>
            ) : (
              <Textarea
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                ref={ref}
                rows={rows}
                maxLength={maxLength}
                className={inputClasses}
              />
            )}
          </div>
          {characterCount && (
            <div className="absolute right-0 bottom-0 mb-1 mr-2">
              {value !== undefined && value !== null ? (
                <CharacterCounter text={value} max={characterCount} />
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
);
