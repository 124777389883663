import React from 'react';
import { DiscoverListProps } from '..';
import { Category } from '../slides/index';

const DiscoverListCategory = ({ items }: DiscoverListProps) => {
  return (
    <div className="lg:w-9/10 mx-auto my-20">
      <div className="discover-category-container">
        {items.length
          ? items.map(item => <Category item={item} key={item.id} />)
          : null}
      </div>
    </div>
  );
};
export default DiscoverListCategory;
