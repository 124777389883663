import development from './development';
import production from './production';
import brandStage from './brandStage';
import brandProd from './brandProd';
import stage from './stage';
import local from './local';
import demo from './demo';

export type Environment = {
  appEnvironment: string;
  apiUrl: string;
  headerColor: string;
  algoliaAppId: string;
  algoliaPrefix: string;
  cloudinaryApiKey: string;
  cloudinaryCloudName: string;
  logRocketAppId: string;
  googleMapsApiKey: string;
  recurlyPublicKey: string;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseUrl: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
  segmentKey: string | null;
  sentryDsn: string;
  youtubeChannelId: string;
  feedVideoPlaylistId: string;
  chatMessagesGroupingInterval: number;
  chatMessagesPerPage: number;
  chatChannelsPerPage: number;
  chatRoles: { [key: string]: { type: string; label: string } };
  opengraphKey?: string;
  beatsMembershipId: string;
  doDeployUpdate: boolean;
  zendeskKey: string;
  featureFlags: {
    enableProjectTypes: boolean;
    supportCompanyOnJobPost: boolean;
    showCompanyOnJobPost: boolean;
    disableRateOnSignup: boolean;
    enableExperienceLevel: boolean;
    enablePublicCandidatePage: boolean;
    enableInviteToApply: boolean;
    enableRecruiterAssignment: boolean;
    showApplicantNotes: boolean;
    showScheduler: boolean;
    enableSuggestedCandidates: boolean;
  };
};

function getEnvironment(): Environment {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return production;
    case 'brand_stage':
      return brandStage;
    case 'brand_prod':
      return brandProd;
    case 'stage':
      return stage;
    case 'demo':
      return demo;
    case 'local':
      return local;
    default:
      return development;
  }
}

export default getEnvironment();
