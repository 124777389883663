import React from 'react';

type Props = {
  name: string;
  showing: boolean;
  title: string;
  entities: string[];
};

const ProfileRelations = ({ name, showing = true, title, entities }: Props) => {
  if (!showing) {
    return null;
  }

  return (
    <div>
      <p className="profile__section-title">{title}</p>

      {entities.length > 0 ? (
        <ul className="horz-bullets">
          {entities.map(entity => (
            <li key={entity}>
              <span>{entity}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>
          {name} hasn&apos;t added any {title.toLowerCase()} to their profile
          yet!
        </p>
      )}
    </div>
  );
};
export default ProfileRelations;
