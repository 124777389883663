/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Link, LinkProps } from 'react-router-dom';
import HashLink from './HashLink';

// Render a HashLink component if the path beings with #,
// which will remove ionic pages transitions when changing
// hash routes.
export default ({
  to,
  disabled = false,
  children,
  ...rest
}: LinkProps & { disabled?: boolean }) => {
  const { className } = rest;

  if (disabled)
    return <span className={`${className} disabled`}>{children}</span>;

  return typeof to === 'string' && to.startsWith('#') ? (
    <HashLink to={to} {...rest}>
      {children}
    </HashLink>
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};
