import React from 'react';
import { DiscoverListProps } from '..';
import cloudinary from '../../../utilities/Cloudinary';
import { Link } from '../../core/router';
import { Avatar } from '../slides/index';

const DiscoverListGrouped = ({
  items,
  heading,
  subHeading,
  imageUrl,
  actionUrl,
  actionText,
}: DiscoverListProps) => {
  return (
    <div className="discover-common-interest">
      {imageUrl ? (
        <img
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          alt="company"
          src={cloudinary(imageUrl, ['w_2000', 'h_560'])}
        />
      ) : null}
      <div className="w-9/10 mx-auto relative z-20">
        <div className="content text-white mb-16">
          {heading && <h2 className="text-white">{heading}</h2>}
          {subHeading && <p className="text-large">{subHeading}</p>}
        </div>
        <div className="2xl:w-9/12 4xl:w-7/12">
          <div className="2xl:pr-20 4xl:pr-40">
            <div className="row-flex">
              {items.map(item => {
                return (
                  <div
                    key={item.id}
                    className="col w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 2xl:w-1/5 3xl:w-1/6 5xl:w-1/6 mb-12"
                  >
                    <Avatar
                      item={item}
                      headingClassName="text-white"
                      subHeadingClassName="hidden"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {actionUrl ? (
            <div className="mt-4 text-center xl:text-right">
              <Link to={actionUrl} className="btn btn--tertiary btn--rev">
                {actionText ?? 'View more'}
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default DiscoverListGrouped;
