import React from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import { Link } from '../core/router';
import { srcSetFunction } from '../../utilities/Image';

type Props = {
  perk: PerkBundleSearchResponse;
};

const PerkFeatureSlideDesktop = ({ perk }: Props) => {
  const {
    objectID,
    type,
    name,
    descriptionSummary,
    offerSummary,
    horizontalHeaderImageUrl,
    featuredLogoImageUrl,
    usesLightTheme,
    tagline,
    company,
  } = perk;

  return (
    <div
      className={`w-full px-16 4xl:px-20 5xl:px-24 flex flex-col justify-center relative perk-slide perk-slide--${type} perk-slide--${
        usesLightTheme ? 'white' : 'black'
      }`}
    >
      {horizontalHeaderImageUrl && (
        <img
          srcSet={srcSetFunction(horizontalHeaderImageUrl, [
            '750',
            '1200',
            '1600',
          ])}
          alt={name}
          className="perk-slide__background"
        />
      )}
      <div className="max-w-screen-xs relative z-30">
        <div
          className={`text-left ${
            usesLightTheme ? 'text-white' : 'text-black'
          }`}
        >
          {featuredLogoImageUrl && (
            <img
              src={featuredLogoImageUrl}
              className="perk-slide__featured-logo"
              alt={name}
            />
          )}
          <div className="text-2xl mt-1 xl:mt-2">
            {type === 'bundle' ? name : company}
          </div>
          <h3 className="h4 2xl:h3 mt-3 2xl:mt-6">
            {type === 'bundle' ? tagline : name}
          </h3>
          {descriptionSummary && (
            <div className="text-base 2xl:text-large mt-2 xl:mt-4">
              {descriptionSummary}
            </div>
          )}
          {offerSummary && <h6 className="mt-2 xl:mt-4">{offerSummary}</h6>}
        </div>
        <div className="text-left mt-4 2xl:mt-8">
          <Link
            to={
              type === 'perk'
                ? `/perks/${objectID}`
                : `/perks/bundles/${objectID}`
            }
            className={`btn btn--outline btn--sm ${
              usesLightTheme ? 'btn--rev' : 'btn--tertiary'
            }`}
          >
            Discover more
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PerkFeatureSlideDesktop;
