import React from 'react';
import { PerkBundleSearchResponse } from '@communo-corp/shared-types';
import BasePerkCard from '../card/PerkCard';
import PartnerBundleCard from '../card/PartnerBundleCard';
import CollectionBundleCard from '../card/CollectionBundleCard';

type Props = {
  perkBundle: PerkBundleSearchResponse;
};

const PerkCard = ({ perk }: { perk: PerkBundleSearchResponse }) => {
  const {
    objectID,
    name,
    descriptionSummary,
    offerSummary,
    horizontalHeaderImageUrl,
    logoUrl,
    company,
    countries,
  } = perk;

  return (
    <BasePerkCard
      id={objectID}
      name={name}
      company={company || undefined}
      offer={offerSummary}
      logoURL={logoUrl}
      imageURL={horizontalHeaderImageUrl}
      description={descriptionSummary}
      country={countries && countries.length > 0 ? countries[0] : null}
    />
  );
};

const BundleCard = ({ bundle }: { bundle: PerkBundleSearchResponse }) => {
  const {
    objectID,
    name,
    squareImageUrl,
    descriptionSummary,
    usesLightTheme,
    featuredLogoImageUrl,
    offerSummary,
    bundleType,
    tagline,
  } = bundle;

  if (bundleType === 'collection') {
    return (
      <CollectionBundleCard
        id={objectID}
        backgroundImage={squareImageUrl}
        name={name}
        tagline={tagline}
        lightTheme={usesLightTheme}
      />
    );
  }

  return (
    <PartnerBundleCard
      id={objectID}
      backgroundImage={squareImageUrl}
      logoUrl={featuredLogoImageUrl}
      name={name}
      tagline={tagline}
      lightTheme={usesLightTheme}
      summary={descriptionSummary}
      offer={offerSummary}
    />
  );
};

const PerkOrBundleCard = ({ perkBundle }: Props) => {
  if (perkBundle.type === 'perk') return <PerkCard perk={perkBundle} />;
  if (perkBundle.type === 'bundle') return <BundleCard bundle={perkBundle} />;
  return null;
};

export default React.memo(PerkOrBundleCard);
