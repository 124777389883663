import shallow from 'zustand/shallow';
import chatUnreadMessagesState from '../state/ChatUnreadMessagesState';

type ChannelUnreadCount = {
  [sid: string]: number;
};

/**
 * useChatChannelsUnreadCount
 *
 * Returns the total unread messages count for a given channel.
 */
const useChatChannelsUnreadCount = (channelSids: (string | null)[]) => {
  const [unreadMessagesStore] = chatUnreadMessagesState;
  const [channels] = unreadMessagesStore(state => [state.channels], shallow);

  const unreads: ChannelUnreadCount = {};
  channelSids.forEach(sid => {
    if (!sid) return;
    Object.assign(unreads, { [sid]: channels[sid] || 0 });
  });

  const totalUnreadCount =
    Object.values(unreads).reduce(
      // eslint-disable-next-line no-return-assign, no-param-reassign
      (prev, curr) => (prev += curr),
      0
    ) || 0;

  return {
    unreads,
    totalUnreadCount,
  };
};

export default useChatChannelsUnreadCount;
