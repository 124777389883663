import React from 'react';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import EventSearchProvider from '../components/event/search/EventSearchProvider';

const Events = () => {
  useAnalytics('Events');

  return (
    <Page
      pullToRefresh
      authGuard
      pageTitle="Events"
      layout="none"
      className="page--no-padding"
    >
      <EventSearchProvider />
    </Page>
  );
};

export default React.memo(Events);
