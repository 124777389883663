import React from 'react';
import classnames from 'classnames';
import MetricStatusIcon, { MetricStatus } from './MetricStatus';

type Props = {
  label: string;
  labelSubtext?: string;
  status?: MetricStatus | null;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  description?: string;
};

const Metric = ({
  label,
  labelSubtext,
  status,
  onClick,
  className,
  description,
}: Props) => {
  const isButton = !!onClick;
  const Component = isButton ? 'button' : 'div';
  // const handleClick = (e: React.MouseEvent) => (onClick ? onClick(e) : null);

  return (
    <Component
      type={isButton ? 'button' : undefined}
      onClick={onClick}
      className={classnames('flex py-xxs leading-none w-full', {
        'items-center': !description,
      })}
    >
      <div>
        <MetricStatusIcon status={status} />
      </div>
      <div
        className={classnames(
          'flex-auto text-left text-sm pl-2',
          {
            'pt-1': !!description,
          },
          className
        )}
      >
        <div className="font-semibold">
          {label}{' '}
          {labelSubtext ? (
            <span className="font-normal">{labelSubtext}</span>
          ) : null}
        </div>
        {description ? <div className="pt-1">{description}</div> : null}
      </div>
    </Component>
  );
};

export default React.memo(Metric);
