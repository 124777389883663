import React from 'react';

type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
};

const VettedBadge = ({ size = 'sm' }: Props) => {
  const classes = ['vetted-badge', `vetted-badge--${size}`];
  const classNames = classes.join(' ');

  return (
    <div className={classNames}>
      <i className="i-badge vetted-badge__star" />
      <i className="i-check-bold vetted-badge__check" />
    </div>
  );
};
export default React.memo(VettedBadge);
