import React from 'react';
import { percent } from '../../utilities/Number';

declare type Props = {
  title: string;
  total: number;
  complete: number;
};

const ProgressMeter = ({ title, total, complete }: Props) => {
  const completion = complete / total;
  const steps = [];
  let percentageClasses = 'whitespace-nowrap';

  if (completion === 1) {
    percentageClasses += ' text-alert-positive';
  }

  const getStepColor = (stepNumber: number) => {
    const percentage = Math.round(completion * 100);

    if (stepNumber > complete) {
      return 'bg-transparent';
    }

    if (percentage > 0 && percentage <= 40) {
      return 'bg-alert-negative';
    }

    if (percentage > 40 && percentage <= 75) {
      return 'bg-alert-warning';
    }

    if (percentage > 75) {
      return 'bg-alert-positive';
    }

    return 'bg-transparent';
  };

  for (let i = 0; i < total; i += 1) {
    const status = getStepColor(i + 1);
    steps.push(<div key={i} className={`step ${status}`} />);
  }

  return (
    <>
      <div className="mb-4">
        <span className="font-semibold text-lg">{title}</span>{' '}
        <span className={percentageClasses}>
          {percent(completion)} complete
        </span>
      </div>
      <div className="progress-meter">{steps}</div>
    </>
  );
};
export default ProgressMeter;
