import React, { useContext } from 'react';

import { timeAgo } from '../../utilities/dateTime';
import Link from '../core/router/Link';
import UserContext from '../user/Context';
import Breadcrumbs from './CandidateBreadcrumbs';

type Props = {
  pageViews?: PageView | null;
  pretext?: string;
  work: WorkEntity | undefined;
};
type TitleProps = Pick<Props, 'pretext'> & {
  work: WorkEntity;
};
type DetailsProps = Pick<Props, 'pageViews'> & { work: WorkEntity };
type DetailProps = { label: string; content: string | number };

const Title = ({ pretext, work }: TitleProps) => {
  return (
    <h1 className="text-3xl max-w-[50ch] whitespace-nowrap overflow-hidden overflow-ellipsis">
      {pretext ? `${pretext} - ` : null}
      <Link to={`/work/${work.id}`}>{work.title}</Link>
    </h1>
  );
};

const Detail = ({ label, content }: DetailProps) => {
  return (
    <h2 className="flex text-base font-medium gap-2">
      <span className="uppercase font-extralight">{label}</span>
      {content}
    </h2>
  );
};

const Details = ({ work, pageViews }: DetailsProps) => {
  const { isRoot } = useContext(UserContext);

  return (
    <div className="flex gap-8">
      <Detail label="Posted" content={timeAgo(work.createdAt)} />
      {work.company ? <Detail label="For" content={work.company.name} /> : null}
      {pageViews ? (
        <>
          <Detail label="Views" content={pageViews?.total || 0} />
          {isRoot && (
            <Detail label="Unique Views" content={pageViews?.unique || 0} />
          )}
        </>
      ) : null}
    </div>
  );
};

const CandidatesHeader = ({ pageViews, pretext, work }: Props) => {
  const { isPublic } = useContext(UserContext);

  return work ? (
    <header
      className={"{isPublic ? '' : 'mx-auto'} w-9/10 flex flex-col gap-2"}
    >
      {!isPublic && <Breadcrumbs work={work} />}
      <Title work={work} pretext={pretext} />
      {!isPublic && <Details work={work} pageViews={pageViews} />}
    </header>
  ) : (
    <div />
  );
};

export default CandidatesHeader;
