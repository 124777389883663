import { Api } from '../../Api';

export const getNylasAuthUrl = async (redirectState: string) => {
  const { authUrl } = await Api.post(`/scheduler`, {
    redirectState,
  });
  return authUrl;
};

export const createSlug = (workId: string, applicantId: string) =>
  `${workId}__workapp__${applicantId}`;
