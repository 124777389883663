import { CHAT_ROLES } from '../../../config';

type ChatRole = {
  type: string;
  label: string;
};

/**
 * Returns the role for a member.
 */
const channelMemberRole = (roleSid: string) => {
  // @ts-ignore
  const role: ChatRole = CHAT_ROLES[roleSid];
  const isChannelAdmin = role?.type === 'channelAdmin';

  return {
    role,
    isChannelAdmin,
  };
};

export default channelMemberRole;
