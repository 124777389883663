import React, { useContext } from 'react';
import useAnalytics from '../hooks/useAnalytics';
import { Link } from '../components/core/router';
import NewOneToManyConversation from '../components/chat/views/NewOneToManyConversation';
import NewOneToManyChatContext from '../components/chat/context/ChatNewOneToManyContext';
import View, {
  ViewHeader,
  ViewContent,
  ViewHeaderTitle,
  ViewFooter,
} from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import BackButton from '../components/core/BackButton';

export default () => {
  useAnalytics('New One-to-Many Recipients');
  const { members } = useContext(NewOneToManyChatContext);

  if (isDesktop)
    return (
      <>
        <ViewContent layout="chat">
          <h6 className="text-center mb-8">New One To Many</h6>
          <NewOneToManyConversation />
        </ViewContent>
        <ViewFooter className="actions">
          <BackButton
            className="btn btn--primary btn--sm btn--outline"
            defaultHref="/chat"
          />
          <Link
            className="btn btn--sm btn--primary ml-4"
            to="/chat/new-one-to-many-message/settings"
            disabled={members.length === 0}
          >
            Next
          </Link>
        </ViewFooter>
      </>
    );

  const NextLink = () => (
    <Link
      className="btn btn--sm btn--primary btn--rev"
      to="/chat/new-one-to-many-message/settings"
      disabled={members.length === 0}
    >
      Next
    </Link>
  );

  return (
    <View
      authGuard
      permissions="canBatchSendMessages"
      pageTitle="New One to Many Message"
    >
      <ViewHeader defaultBackHref="/chat" end={<NextLink />}>
        <ViewHeaderTitle>New One To Many</ViewHeaderTitle>
      </ViewHeader>

      <ViewContent layout="chat">
        <NewOneToManyConversation />
      </ViewContent>
    </View>
  );
};
