import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';

/* eslint-disable-next-line no-unused-vars */
import { FilterOptionComponentProps, ImperativeActions } from '../index.d';
import URLFilterOption from './URLFilterOption';

type FilterValueType = boolean;

interface BooleanFilterOptionProps {
  options?: [string, string];
  activeLabel?: string;
}

type BooleanFilterComponentProps = BooleanFilterOptionProps &
  FilterOptionComponentProps<FilterValueType>;

const BooleanFilterComponent: React.FC<BooleanFilterComponentProps> = ({
  name,
  onChange,
  options = ['Default Selected Option', 'Selectable Option'],
  value,
}: BooleanFilterComponentProps) => {
  const [first, second] = options;
  const uuid = useMemo(() => uniqueId('boolean_'), []);
  const booleanValue = !!value;

  return (
    <section className="filter-group">
      <div className="filter-group__item justify-start">
        <div className="radio-filter mr-2">
          <input
            id={`${uuid}-1`}
            type="radio"
            name={`boolean-filter-${name}`}
            value={first}
            checked={!booleanValue}
            onChange={() => onChange(name, false)}
          />
          <label htmlFor={`${uuid}-1`}>{first}</label>
        </div>
        <div className="radio-filter mr-2">
          <input
            id={`${uuid}-2`}
            type="radio"
            name={`boolean-filter-${name}`}
            value={second}
            checked={booleanValue}
            onChange={() => onChange(name, true)}
          />
          <label htmlFor={`${uuid}-2`}>{second}</label>
        </div>
      </div>
    </section>
  );
};

class BooleanFilterOption extends URLFilterOption<FilterValueType>
  implements BooleanFilterOptionProps {
  options?: [string, string];

  activeLabel?: string;

  constructor({
    options = ['Default Selected', 'Selectable'],
    activeLabel = '',
    ...rest
  }: Omit<
    URLFilterOption<FilterValueType>,
    'component' | 'fromURL' | 'toURL' | 'onToggle'
  > &
    BooleanFilterOptionProps) {
    super({
      ...rest,
      component: BooleanFilterComponent,
    });

    this.options = options;
    this.initialValue = false;
    this.activeLabel = activeLabel;

    this.onToggle = this.handleToggle;
  }

  handleToggle(actions: ImperativeActions) {
    const value = actions.getFilterValue(this.name);
    actions.updateFilterValue(this.name, !value);
  }

  /**
   * Convert URL params to value
   * @param params URL params
   */
  fromURL(params: {
    [key: string]: any;
  }): { [key: string]: FilterValueType | undefined } {
    const value = params[this.name];
    return {
      [this.name]: value ? Boolean(value) : undefined,
    };
  }
}

export default BooleanFilterOption;
