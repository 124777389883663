import {
  RolePickerCategory,
  PickerRole,
} from '../core/formElements/RolePicker/RolePicker';

export function defaultRoleTransformer(role: any) {
  return {
    value: role.objectID,
    label: role.name,
  };
}

export function membershipRoleTransformer(role: any) {
  const label = role?.nameForMembership ?? role.name;

  return {
    value: role.objectID,
    label,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function groupRoles(
  roles: any[],
  roleTransformer = defaultRoleTransformer
) {
  if (!roles.length) {
    return [];
  }

  const rolesByCategory = roles.reduce((carry: any, role: any) => {
    // If no category, set to uncategorized
    // eslint-disable-next-line no-param-reassign
    if (!role.category) role.category = { name: 'uncategorized' };

    const category = carry[role.category.name] || [];
    category.push(roleTransformer(role));
    // eslint-disable-next-line no-param-reassign
    carry[role.category.name] = category;
    return carry;
  }, {});

  return Object.keys(rolesByCategory)
    .sort()
    .map(category => {
      return {
        label: category,
        options: rolesByCategory[category],
      };
    });
}

// TODO: Is this type too specific to the RolePicker? can make it more generic?
interface RoleCategory extends CategoryEntity {
  // type: 'category' | 'subCategory';
  roles: PickerRole[];
  parentName: string | null;
  subCategories?: RoleCategory[];
}

/**
 * Inspiration taken from - https://typeofnan.dev/an-easy-way-to-build-a-tree-with-object-references/
 */
export function buildRoleCategoryTree(
  roles: RoleEntity[],
  roleTransformer: (role: RoleEntity) => PickerRole
): RolePickerCategory[] {
  // Create a map for all the categories without duplicates
  const flatMap = new Map<string, RoleCategory>();

  // Flatten/map out all the categories assoiciated to our roles
  roles.forEach(role => {
    const { category } = role;
    const existingSubCategory = flatMap.get(category.name);

    if (existingSubCategory) {
      // Update it
      flatMap.set(category.name, {
        ...existingSubCategory,
        roles: [...existingSubCategory.roles, roleTransformer(role)],
      });
    } else {
      // Set it
      const parentName = category.parent?.name ?? 'unknown';

      flatMap.set(category.name, {
        ...category,
        // type: 'subCategory',
        roles: [roleTransformer(role)],
        parentName,
      });
    }

    if (category.parent) {
      flatMap.set(category.parent.name, {
        ...category.parent,
        // type: 'category',
        roles: [],
        parentName: null,
      });
    }
  });

  const data = Array.from(flatMap.values());
  const parentMap: any[] = [];
  const categoryNameMap = data.reduce((acc: any, category, index) => {
    acc[category.name] = index;
    return acc;
  }, {});

  data.forEach(category => {
    if (category.parentName === null) {
      parentMap.push(category);
      return;
    }

    const { parentName } = category;

    // Use our mapping to locate the parent element in our data array
    const parentEl = data[categoryNameMap[parentName]];

    // Add our current el to its parent's `children` array
    parentEl.subCategories = [...(parentEl.subCategories || []), category];
  });

  return parentMap;

  // Build an object with all the parent categories names
  // const parents = Array.from(flatMap.values()).reduce(
  //   (acc: any, category: RoleCategory) => {
  //     if (category.type === 'category') {
  //       acc[category.name] = { ...category, subCategories: [] };
  //     }

  //     return acc;
  //   },
  //   {}
  // );

  // // Assign all subCategories to their parents subCategory array
  // flatMap.forEach(category => {
  //   if (category.type === 'subCategory' && category.parent) {
  //     parents[category.parent.name].subCategories.push(category);
  //   }
  // });

  // return Object.values<ParentCategory>(parents);
}

export default {
  groupRoles,
  buildRoleCategoryTree,
};
