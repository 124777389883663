import React, { useContext, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormActions,
  FormTextarea,
  Autocomplete,
  FormGroup,
} from '../core/form';
import { SubmitButton } from '../core/Button';
import SearchContext from '../search/Context';
import Avatar from '../avatar/Avatar';
import { addSuccess } from '../../services/Messaging';
import useChatServices from '../chat/hooks/useChatServices';
import { appUrl } from '../../utilities/Url';

type LabelProps = {
  label: string;
  value: {
    identity: string;
    firstName: string;
    lastName: string;
    avatarURL: string;
  };
};

const Invite = ({
  roomId,
  onComplete,
}: {
  roomId: string;
  onComplete: Function;
}) => {
  const { checkForExistingChannel, createChannel } = useChatServices();
  const { getUsers } = useContext(SearchContext);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    defaultValues: {
      message: 'Join my video chat room.',
    },
  });

  const { handleSubmit, register, errors, reset } = form;

  const onSubmit = useCallback(
    async (values: any) => {
      setIsLoading(true);
      const { recipient, message } = values;
      const { identity } = recipient.value;

      const link = appUrl(`/video-chat/${roomId}`);
      const fullMessage = `${message}\n\n${link}`;
      const existingChannel = await checkForExistingChannel(identity);

      if (existingChannel) {
        await existingChannel.sendMessage(fullMessage);
        await existingChannel.setAllMessagesRead();
      } else {
        await createChannel({
          identities: [identity],
          message: fullMessage,
        });
      }

      reset();
      addSuccess('Sent invite link to user');
      setIsLoading(false);
      onComplete();
    },
    [roomId, checkForExistingChannel, createChannel, onComplete, reset]
  );

  const getResults = (q: string) =>
    getUsers({ query: q }).then(({ hits }: any) =>
      hits.map((hit: any) => {
        const { objectID: identity, firstName, lastName, avatarURL } = hit;
        return {
          value: { identity, firstName, lastName, avatarURL },
          label: `${firstName} ${lastName}`,
        };
      })
    );

  const formatLabel = ({ label, value }: LabelProps) => {
    const { firstName, lastName, avatarURL } = value;

    return (
      <div className="flex items-center">
        <Avatar avatarName={`${firstName} ${lastName}`} avatarUrl={avatarURL} />
        <span className="ml-2">{label}</span>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2 className="mb-8">Invite a user</h2>
      <FormGroup
        className="mb-8"
        name="recipient"
        label="Search members"
        errors={errors}
      >
        <Autocomplete
          name="recipient"
          form={form}
          callback={getResults}
          isRequired
          placeholder="Search users..."
          formatOptionLabel={formatLabel}
        />
      </FormGroup>
      <FormTextarea
        className="mb-8"
        name="message"
        label="Your message here"
        placeholder="Compose your message..."
        ref={register({
          required: {
            value: true,
            message: 'A message is required',
          },
        })}
        errors={errors}
        hint="We'll include the link as part of your message."
      />

      <FormActions className="mt-auto md:mt-8 justify-end">
        <SubmitButton text="Invite" loading={isLoading} />
      </FormActions>
    </form>
  );
};

export default Invite;
