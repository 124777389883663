import React from 'react';
import { FormInput } from '../../core/form';

const Requirements = ({ errors, register }: any) => (
  <FormInput
    name="requirements"
    label="Additional Requirements"
    placeholder="e.g. Must be +- 2 hours MST"
    errors={errors}
    ref={register}
  />
);
export default Requirements;
