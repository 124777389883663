import querystringify from 'querystringify';
import React, { useContext, useEffect } from 'react';
import Page from '../components/core/Page';
import UserContext from '../components/user/Context';
import MagicLogin from '../components/user/views/MagicLogin';
import useAnalytics from '../hooks/useAnalytics';

export default ({
  match: {
    params: { code },
  },
}: any) => {
  useAnalytics('Magic Link Login');
  const { redirectUrl: url, directRedirect } = querystringify.parse(
    window.location.search
  ) as any;
  const { redirectUrl, setRedirectUrl } = useContext(UserContext);

  useEffect(() => {
    if (url) setRedirectUrl(url);
  }, [url, setRedirectUrl]);

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Magic"
      mobileHeader={() => null}
      hideHeader
    >
      {((url && redirectUrl) || !url) && (
        <MagicLogin code={code} direct={directRedirect} />
      )}
    </Page>
  );
};
