import { CollectionSummary } from '@communo-corp/shared-types';
import React, { useCallback, useContext, useReducer } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useFeatureGate from '../../../hooks/useFeatureGate';
import useNavigate from '../../../hooks/useNavigate';
import { isMobile } from '../../../utilities/Device';
import Button from '../../core/Button';
import MembershipContext from '../../membership/Context';
import SideBar from '../../menu/SideBar';
import useCollection from '../hooks/useCollection';
import DeleteCollection from './DeleteCollection';
import EditCollectionModal from './EditCollectionModal';
import InviteToCollectionModal from './InviteToCollectionModal';

enum ACTIONS {
  SET_EDITING,
  SET_DELETING,
  DONE_EDITING,
  DONE_DELETING,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  OPEN_INVITE_TO_COLLECTION_MODAL,
  CLOSE_INVITE_TO_COLLECTION_MODAL,
  POST_WORK_TO_COLLECTION,
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case ACTIONS.SET_EDITING:
      return {
        ...state,
        isEditing: true,
        isMobileMenuOpen: false,
      };
    case ACTIONS.DONE_EDITING:
      return {
        ...state,
        isEditing: false,
        isMobileMenuOpen: false,
      };
    case ACTIONS.SET_DELETING:
      return {
        ...state,
        isDeleting: true,
        isMobileMenuOpen: false,
      };
    case ACTIONS.DONE_DELETING:
      return {
        ...state,
        isDeleting: false,
        isMobileMenuOpen: false,
      };
    case ACTIONS.OPEN_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: true,
      };
    case ACTIONS.CLOSE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: false,
      };
    case ACTIONS.OPEN_INVITE_TO_COLLECTION_MODAL:
      return {
        ...state,
        inviteModalOpen: true,
        isMobileMenuOpen: false,
      };
    case ACTIONS.CLOSE_INVITE_TO_COLLECTION_MODAL:
      return {
        ...state,
        inviteModalOpen: false,
      };
    default:
      return state;
  }
}

type Props = {
  collection: CollectionSummary;
  modal?: string;
};
const ViewCollectionActions = ({ collection, modal }: Props) => {
  const { id: userId, isAdmin } = useCurrentUser();
  const { checkAccess } = useContext(MembershipContext);
  const { needsUpgrade, openUpgradeFlowFor } = useFeatureGate();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    isEditing: false,
    isDeleting: false,
    inviteModalOpen: modal === 'invite',
    mobileMenuOpen: false,
  });

  const { refetch: refetchCollection } = useCollection(collection.id);

  const sharing = collection.membershipId ? 'Company' : 'Private';
  const isCollectionOwner = userId === collection.userId;

  const isBench = collection.type === 'bench';
  const isList = collection.type === 'list' || collection.type === 'group';
  const canSeeEdit =
    (isList && isCollectionOwner) || (sharing === 'Company' && isAdmin);

  const invitePermissions: Permission[] = isBench
    ? ['canInviteToBenchViaLink', 'canInviteToBenchViaEmail']
    : ['canInviteToListViaLink', 'canInviteToListViaEmail'];
  const canInvite = checkAccess(invitePermissions);

  const doneEditing = useCallback(() => {
    refetchCollection();
    dispatch({
      type: ACTIONS.DONE_EDITING,
    });
  }, [refetchCollection]);

  const closeMobileMenu = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_MOBILE_MENU,
    });
  }, []);

  const handlePostWorkToCollection = useCallback(() => {
    const postWorkPermission = isBench
      ? 'canPostWorkToBench'
      : 'canPostWorkToCommuno';

    if (needsUpgrade(postWorkPermission)) {
      return;
    }

    closeMobileMenu();
    navigate(`/work/create?cid=${collection.id}`);
  }, [closeMobileMenu, collection.id, isBench, navigate, needsUpgrade]);

  const handleInviteToCollection = useCallback(() => {
    if (canInvite) {
      dispatch({
        type: ACTIONS.OPEN_INVITE_TO_COLLECTION_MODAL,
      });
      return;
    }

    openUpgradeFlowFor('canAddToBench');
  }, [canInvite, openUpgradeFlowFor]);

  return (
    <>
      {!isMobile ? (
        <div className="hidden w-full mt-4 sm:flex sm:flex-wrap md:w-auto md:mt-0">
          {isList ? (
            <>
              <span className="rounded-full bg-grey-200 text-grey-700 px-3 flex items-center font-semibold text-sm mb-4 lg:ml-4 lg:mb-0">
                {sharing}
              </span>
              <Button
                text="Delete"
                fill="outline"
                size="sm"
                className="ml-2 mb-4 md:ml-4 lg:mb-0"
                disabled={!isCollectionOwner}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SET_DELETING,
                  });
                }}
              />
            </>
          ) : null}

          {canSeeEdit ? (
            <Button
              text="Edit"
              fill="outline"
              size="sm"
              className="ml-2 mb-4 md:ml-4 lg:mb-0"
              onClick={() => {
                dispatch({
                  type: ACTIONS.SET_EDITING,
                });
              }}
            />
          ) : null}

          <div className="w-full mt-2 flex flex-wrap ml-0 md:w-auto md:mt-0">
            <Button
              text="Invite"
              fill="solid"
              color="primary"
              icon="add"
              size="sm"
              className="ml-2 mb-4 md:ml-4 lg:mb-0"
              onClick={handleInviteToCollection}
            />

            <Button
              text="Create new work post"
              fill="solid"
              color="primary"
              icon="work"
              size="sm"
              className="ml-2 mb-4 md:ml-4 lg:mb-0"
              onClick={handlePostWorkToCollection}
            />
          </div>
        </div>
      ) : null}

      {isMobile ? (
        <button
          type="button"
          className="flex items-center"
          onClick={() => {
            dispatch({
              type: ACTIONS.OPEN_MOBILE_MENU,
            });
          }}
        >
          <div className="text-sm uppercase mr-1">Menu</div>
          <div className="text-lg">
            <i className="i-more-vert" />
          </div>
        </button>
      ) : null}

      {state.isEditing ? (
        <EditCollectionModal
          collection={collection}
          onSave={doneEditing}
          onClose={() => {
            dispatch({
              type: ACTIONS.DONE_EDITING,
            });
          }}
        />
      ) : null}

      {state.isDeleting ? (
        <DeleteCollection
          collection={collection}
          isOpen
          onClose={() => {
            dispatch({
              type: ACTIONS.DONE_DELETING,
            });
          }}
        />
      ) : null}

      {state.inviteModalOpen ? (
        <InviteToCollectionModal
          collection={collection}
          onClose={() => {
            dispatch({
              type: ACTIONS.CLOSE_INVITE_TO_COLLECTION_MODAL,
            });
          }}
        />
      ) : null}

      {isMobile ? (
        <SideBar open={state.isMobileMenuOpen} setOpen={closeMobileMenu}>
          <div className="py-3 sm:w-48">
            <div className="pt-8 pb-4 px-3 flex items-center sm:hidden">
              <h4>Menu</h4>
            </div>

            <button
              type="button"
              onClick={handleInviteToCollection}
              className="px-3 h-12 flex items-center md:text-base cursor-pointer"
            >
              <div className="w-10 h-10 flex items-center justify-center mr-4">
                <i className="i-add" />
              </div>
              Invite
            </button>

            <button
              type="button"
              className="px-3 h-12 flex items-center md:text-base cursor-pointer"
              onClick={handlePostWorkToCollection}
            >
              <div className="w-10 h-10 flex items-center justify-center mr-4">
                <i className="i-work" />
              </div>
              Post Work
            </button>

            {canSeeEdit ? (
              <button
                type="button"
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SET_EDITING,
                  });
                }}
                className="px-3 h-12 flex items-center md:text-base cursor-pointer"
              >
                <div className="w-10 h-10 flex items-center justify-center mr-4">
                  <i className="i-edit" />
                </div>
                Edit
              </button>
            ) : null}

            {isList ? (
              <>
                <button
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: ACTIONS.SET_DELETING,
                    });
                  }}
                  className="px-3 h-12 flex items-center md:text-base cursor-pointer"
                >
                  <div className="w-10 h-10 flex items-center justify-center mr-4">
                    <i className="i-trash" />
                  </div>
                  Delete
                </button>
              </>
            ) : null}
          </div>
        </SideBar>
      ) : null}
    </>
  );
};
export default ViewCollectionActions;
