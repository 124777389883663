import React, { useCallback, useContext, useEffect } from 'react';
import { useCookie } from 'react-use';
import Markdown from '../../core/Markdown';
import Avatar from '../../avatar/Avatar';
import { SubmitReferenceContext } from '../context/SubmitReferenceContext';
import { track } from '../../../analytics';
import { SOURCE_COOKIE } from '../../cookie/SourceCookie';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useStep from '../../stepper/hooks/useStep';

const InvitationStep = () => {
  const { user, referenceRequest } = useContext(SubmitReferenceContext);
  const { id: authUserId } = useCurrentUser();
  const { setOnNext, goToNextStep } = useStep();
  const [, setSourceCookie] = useCookie(SOURCE_COOKIE);

  const handleNext = useCallback(() => {
    // If not an existing user, and we have the requesting user (to make typescript happy)
    if (!authUserId && user) {
      setSourceCookie(`Reference:${user.id}`, {
        expires: 86400 * 30,
        path: '/',
      });
    }

    goToNextStep();
  }, [authUserId, goToNextStep, setSourceCookie, user]);

  useEffect(() => {
    setOnNext(() => {
      handleNext();
      return false;
    });
  }, [handleNext, setOnNext]);

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Invitation',
    });
  }, []);

  return (
    <div>
      <div className="flex">
        <div className="pr-4">
          <Avatar
            square
            size="xl"
            avatarName={user?.fullname}
            avatarUrl={user?.avatar}
          />
        </div>
        <div>
          {user?.fullname ? <h5>{user?.fullname}</h5> : null}
          {user?.title ? <div className="text-large">{user.title}</div> : null}
          {referenceRequest?.roleWorkedWith ? (
            <div className="text-base font-semibold mt-2">
              {referenceRequest.roleWorkedWith}
            </div>
          ) : null}
          {user?.location ? (
            <div className="text-small">{user.location}</div>
          ) : null}
        </div>
      </div>
      <div className="mt-8">
        <Markdown>{referenceRequest?.message}</Markdown>
      </div>
    </div>
  );
};
export default InvitationStep;
