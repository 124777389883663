import React from 'react';
import moment from 'moment';

import useWorkForMembership from '../../hooks/useWorkForMembership';
import WorkCard from '../card/WorkCard';
import Loading from '../core/Loading';

type Props = {
  membership: MembershipEntity;
};

const ProfileWork = ({ membership }: Props) => {
  const { data: workItems, loading: loadingWork } = useWorkForMembership(
    membership.id
  );

  if (loadingWork || !workItems) {
    return <Loading isActive />;
  }

  const now = moment().utc();
  const openWork = workItems.filter((workItem: WorkEntity) => {
    return now.isBefore(workItem.expiresAt);
  });

  if (openWork.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="mt-12 brand-spacing-m flex justify-between items-baseline">
        <h4>Open Jobs</h4>
      </div>

      {openWork.map((work: WorkEntity) => (
        <div key={work.id} className="mt-4">
          <WorkCard work={work} className="shadow-lg" />
        </div>
      ))}
    </div>
  );
};
export default ProfileWork;
