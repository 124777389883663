import { CollectionUser } from '@communo-corp/shared-types';
import React from 'react';
import UserCard from './UserCard';

interface Props {
  user: CollectionUser;
  onUserCollectionUpdate?: (collectionIdsUserIsIn: string[]) => void;
}

const CollectionUserCard = ({ user, onUserCollectionUpdate }: Props) => {
  const {
    id,
    avatarURL,
    firstName,
    role,
    isVetted,
    // locationFriendlyName,
    logoURL,
    membershipName,
    isIndividualPlan,
  } = user;

  const isEmployee = !isIndividualPlan;

  return (
    <UserCard
      userId={id}
      avatarUrl={avatarURL}
      name={firstName}
      userRole={role}
      // location={locationFriendlyName}
      badge={isVetted && !isEmployee ? 'vetted' : undefined}
      onUserCollectionUpdate={onUserCollectionUpdate}
      worksAt={isEmployee ? membershipName : undefined}
      logoUrl={isEmployee ? logoURL : undefined}
    />
  );
};
export default CollectionUserCard;
