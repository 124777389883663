import { IonRange } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { currency } from '../../../utilities/Number';
import { FormGroup } from '../../core/form';
import BudgetType, { BudgetTypeEnum } from './BudgetType';

const range: number[] = [
  5000,
  10000,
  15000,
  20000,
  30000,
  40000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000,
  125000,
  150000,
  175000,
  200000,
  225000,
  250000,
  275000,
  300000,
  325000,
  350000,
  375000,
  400000,
  425000,
  450000,
  475000,
  500000,
  600000,
  700000,
  800000,
  900000,
  1000000,
];

const HOURLY_RATE_MIN = 25;
const HOURLY_RATE_MAX = 500;
const HOURS_PER_WEEK_MIN = 5;
const HOURS_PER_WEEK_MAX = 40;

export default ({
  errors,
  register,
  defaultPrice,
  defaultHourlyRate,
  defaultHoursPerWeek,
  defaultBudgetType,
  form,
  useFixedAmount = true,
  isRequired = true,
}: any) => {
  const { setValue, watch, unregister } = form;
  const budgetType = watch('budgetType');

  // Fixed price
  const getIndex = (value: any) => Math.max(range.indexOf(value), 0);
  const [index, setIndex] = useState(getIndex(defaultPrice));
  const budget = currency(watch('price'));
  const handlePriceChange = useCallback(
    (value: number) => {
      setIndex(value);
      setValue('price', range[value]);
    },
    [setValue]
  );

  // Hourly Rate
  const [hourlyIndex, setHourlyIndex] = useState(HOURLY_RATE_MIN);
  const hourlyRate = currency(watch('hourlyRate'));
  const handleHourlyRateChange = useCallback(
    (value: number) => {
      setHourlyIndex(value);
      setValue('hourlyRate', value);
    },
    [setValue]
  );

  // Hours per Week
  const [hoursPerWeekIndex, setHoursPerWeekIndex] = useState(
    HOURS_PER_WEEK_MIN
  );
  const hoursPerWeek = watch('hoursPerWeek');
  const handleHoursPerWeekChange = useCallback(
    (value: number) => {
      setHoursPerWeekIndex(value);
      setValue('hoursPerWeek', value);
    },
    [setValue]
  );

  useEffect(() => {
    setValue('budgetType', defaultBudgetType || BudgetTypeEnum.Fixed);
  }, [defaultBudgetType, setValue]);

  useEffect(() => {
    if (budgetType === BudgetTypeEnum.Fixed) {
      handlePriceChange(getIndex(defaultPrice));
      unregister('hourlyRate');
      unregister('hoursPerWeek');
    } else {
      handleHourlyRateChange(defaultHourlyRate || HOURLY_RATE_MIN);
      handleHoursPerWeekChange(defaultHoursPerWeek || HOURS_PER_WEEK_MIN);
      unregister('price');
    }
  }, [
    budgetType,
    unregister,
    setValue,
    defaultPrice,
    defaultHourlyRate,
    defaultHoursPerWeek,
    handlePriceChange,
    handleHourlyRateChange,
    handleHoursPerWeekChange,
  ]);

  return (
    <>
      <BudgetType
        name="budgetType"
        errors={errors}
        register={register}
        useFixedAmount={useFixedAmount}
        form={form}
        isRequired={isRequired}
      />
      {budgetType === BudgetTypeEnum.Fixed && (
        <FormGroup
          required
          name="price"
          label="Estimated budget"
          errors={errors}
        >
          <div>{budget}</div>
          <IonRange
            min={0}
            max={range.length - 1}
            step={1}
            snaps
            ticks={false}
            pin={false}
            value={index}
            color="communo-red"
            mode="ios"
            onIonChange={(e: any) => handlePriceChange(e?.target?.value || 0)}
          />
          <input
            type="hidden"
            name="price"
            ref={register({
              required: 'Budget is required',
            })}
          />
        </FormGroup>
      )}
      {budgetType === BudgetTypeEnum.Hourly && (
        <>
          <FormGroup
            required
            name="hourlyRate"
            label="Hourly rate"
            errors={errors}
          >
            <div>{hourlyRate}</div>
            <IonRange
              min={HOURLY_RATE_MIN}
              max={HOURLY_RATE_MAX}
              step={1}
              snaps
              ticks={false}
              pin={false}
              value={hourlyIndex}
              color="communo-red"
              mode="ios"
              onIonChange={(e: any) => {
                return handleHourlyRateChange(
                  e?.target?.value || HOURLY_RATE_MIN
                );
              }}
            />
            <input
              type="hidden"
              name="hourlyRate"
              ref={register({
                required: 'Hourly rate is required',
              })}
            />
          </FormGroup>

          <FormGroup
            required
            name="hoursPerWeek"
            label="Hours per week"
            errors={errors}
          >
            <div>{hoursPerWeek} hours</div>
            <IonRange
              min={HOURS_PER_WEEK_MIN}
              max={HOURS_PER_WEEK_MAX}
              step={1}
              snaps
              ticks={false}
              pin={false}
              value={hoursPerWeekIndex}
              color="communo-red"
              mode="ios"
              onIonChange={(e: any) => {
                return handleHoursPerWeekChange(
                  e?.target?.value || HOURS_PER_WEEK_MIN
                );
              }}
            />
            <input
              type="hidden"
              name="hoursPerWeek"
              ref={register({
                required: 'Hours per week rate is required',
              })}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};
