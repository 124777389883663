import React from 'react';
import { addSuccess } from '../../services/Messaging';
import Button from '../core/Button';

type Props = {
  isPublicUrlExpired: boolean;
  doCreatePublicUrl: () => void;
};

const ShortlistCreateButton = ({
  isPublicUrlExpired,
  doCreatePublicUrl,
}: Props) => {
  const createPublicUrl = () => {
    doCreatePublicUrl();
    addSuccess('Public URL generated!');
  };

  return (
    <Button
      color="secondary"
      className="m-1 w-56"
      onClick={() => createPublicUrl()}
    >
      <span className="btn__text">
        {isPublicUrlExpired ? 'Regenerate' : 'Generate'} Shortlist
      </span>
    </Button>
  );
};

export default ShortlistCreateButton;
