import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { utilApi } from './Api';
import { addResponseError } from './services/Messaging';

const { pathname, href } = window.location;

interface PageMeta {
  title: string;
  description: string;
  image: string;
}

const defaultMeta: PageMeta = {
  title: 'Communo makes good work happen',
  description:
    'Communities of compatible marketing pros collaborating on the work they love, with the people the love, for the brands they love.',
  image:
    'https://res.cloudinary.com/communo/image/upload/assets/meta/communo-image.png',
};

const AppMeta = () => {
  const [meta, setMeta] = useState<PageMeta>(defaultMeta);

  useEffect(() => {
    utilApi
      .meta(pathname)
      .then((result: PageMeta) => {
        setMeta(result);
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      });
  }, []);

  return meta ? (
    <Helmet>
      <meta property="og:site_name" content="Communo" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:url" content={href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@communohq" />
    </Helmet>
  ) : null;
};

export default React.memo(AppMeta);
