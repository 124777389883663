// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';
import { PlanType } from '@communo-corp/shared-types';
import {
  LocationFilterOption,
  RadioFilterOption,
  SelectFilterOption,
} from '../../filter/options';
import getSkillOptions from './getSkillOptions';
import getIndustryOptions from './getIndustryOptions';
import getRoleOptions from './getRoleOptions';
import getPlanTypeOptions from './getPlanTypeOptions';
import { isMobile } from '../../../utilities/Device';

function getMemberFilterOptions(
  plan: PlanEntity | null,
  results: { [key: string]: SearchResponse },
  queryFilters: { [key: string]: {} | string },
  roles: RoleEntity[]
) {
  const memberFilters = [];

  const categoryOptions = [
    {
      label: `Person`,
      activeLabel: 'Person',
      value: 'people',
    },
  ];

  if (plan?.type !== PlanType.STUDENT) {
    categoryOptions.push(
      {
        label: `Agency`,
        activeLabel: 'Agency',
        value: 'agency',
      },
      {
        label: `Business`,
        activeLabel: 'Business',
        value: 'brand',
      }
    );

    memberFilters.push(
      new RadioFilterOption({
        multiple: false,
        name: 'type',
        toggleLabel: 'Category',
        sectionTitle: 'Category of Member',
        modalHeight: 'auto',
        options: categoryOptions,
      })
    );

    memberFilters.push(
      new RadioFilterOption({
        dependsOn: {
          targetName: 'type',
          operator: 'EQUALS',
          value: 'people',
        },
        name: 'isIndividualPlan',
        toggleLabel: 'Type',
        sectionTitle: 'Type',
        fullHeight: isMobile,
        options: getPlanTypeOptions(results),
        toFacetFilter: (filters: any): string[] => {
          return filters.isIndividualPlan
            ? [
                `primaryMembershipUser.membership.plan.isIndividualPlan:${filters.isIndividualPlan}`,
              ]
            : [];
        },
      })
    );
  }

  memberFilters.push(
    new SelectFilterOption({
      dependsOn: {
        targetName: 'type',
        operator: 'EXCLUDES',
        value: ['brand'],
      },
      name: 'role',
      toggleLabel: 'Role',
      sectionTitle: 'Role',
      placeholder: 'Select a Role or type to search...',
      fullHeight: isMobile,
      options: getRoleOptions(results, queryFilters, roles),
      toFacetFilter: (filters: any): string[] => {
        return filters.role ? [`roles.role.name:${filters.role}`] : [];
      },
      isClearable: true,
    })
  );

  const membershipCount = results['membership-search']?.nbHits || 0;
  const userCount = results['user-search']?.nbHits || 0;
  let totalCount = 0;

  if (queryFilters.type === 'people') {
    totalCount = userCount;
  } else if (queryFilters.type === 'agency') {
    totalCount = membershipCount;
  } else {
    totalCount = membershipCount + userCount;
  }

  memberFilters.push(
    new LocationFilterOption({
      dependsOn: {
        targetName: 'type',
        operator: 'EXCLUDES',
        value: ['brand'],
      },
      name: 'location',
      toggleLabel: 'Location',
      sectionTitle: 'Location',
      fullHeight: isMobile,
      resultsCount: totalCount,
      addAlgoliaParam: (algoliaParams: any, filters: any) => {
        if (filters.location) {
          const { location, distance = 20 } = filters.location;

          if (location) {
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundLatLng = `${location.coordinates.lat},${location.coordinates.lng}`;
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundRadius = parseInt(distance, 10) * 1000;
          }
        }
      },
    })
  );

  memberFilters.push(
    new SelectFilterOption({
      isMulti: true,
      dependsOn: {
        targetName: 'type',
        operator: 'EXCLUDES',
        value: ['brand'],
      },
      name: 'skills',
      toggleLabel: 'Skills',
      sectionTitle: 'Skills',
      placeholder: 'Type to search for a Skill...',
      isSearchable: true,
      fullHeight: isMobile,
      options: getSkillOptions(results, queryFilters),
      toFacetFilter: (filters: any): string[] => {
        const { skills } = filters;
        if (!skills || skills.length === 0) {
          return [];
        }

        return skills.map((skillName: any) => {
          return [`skills.skill.name:${skillName}`];
        });
      },
      isClearable: true,
    })
  );

  memberFilters.push(
    new SelectFilterOption({
      dependsOn: {
        targetName: 'type',
        operator: 'EXCLUDES',
        value: ['brand'],
      },
      name: 'industry',
      toggleLabel: 'Industry',
      sectionTitle: 'Industry',
      placeholder: 'Select an Industry or type to search...',
      fullHeight: isMobile,
      options: getIndustryOptions(results, queryFilters),
      toFacetFilter: (filters: any): string[] => {
        return filters.industry
          ? [`industries.industry.name:${filters.industry}`]
          : [];
      },
      isClearable: true,
    })
  );

  return memberFilters;
}

export default getMemberFilterOptions;
