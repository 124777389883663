import React, { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Card from './Card';
import CardImage from './CardImage';
import CardLogo from './CardLogo';
import Alert from '../alert/Alert';
import { currency } from '../../utilities/Number';
import cloudinary from '../../utilities/Cloudinary';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import { DEFAULT_WORK_COVER_PHOTO_URL } from '../../asset-config';
import { SHOW_COMPANY_ON_JOB_POST } from '../../config';
import workApi from '../work/Api';
import useAnalytics from '../../hooks/useAnalytics';
import { addResponseError } from '../../services/Messaging';
import UserContext from '../user/Context';
import Image from '../core/Image';
import useForceMobile from '../../hooks/useForceMobile';
import { srcSetFunction } from '../../utilities/Image';
import { getWorkTypeLabel } from '../work/search/getTypeOptions';

type Props = {
  work: WorkEntity;
  cardStyle?: 'limited' | 'full' | 'external';
  className?: string;
};

type WorkCardProps = {
  work: WorkEntity;
  actionsComponent: JSX.Element | null;
  dateText?: string | null;
};

function getLocation(work: WorkEntity) {
  if (work.isRemote) {
    return 'Remote OK';
  }
  return work.location?.friendlyName ?? null;
}

function getCoverPhotoURL(work: WorkEntity) {
  return (
    work.imageURL ||
    work.membership?.coverPhotoURL ||
    DEFAULT_WORK_COVER_PHOTO_URL
  );
}

function getLogoURL(work: WorkEntity) {
  return (
    (SHOW_COMPANY_ON_JOB_POST && work.company?.logoURL) ||
    work.logoURL ||
    (work.membership?.plan?.type === 'individual' && work.user?.avatarURL) ||
    work.membership?.logoURL ||
    null
  );
}

function getDisplayTitle(work: WorkEntity) {
  if (work.companyName) return work.companyName;
  if (work.membership?.plan?.type === 'individual' && work.user?.firstName)
    return `${work.user?.firstName} ${work.user?.lastName}`;
  return work.membership?.name || '';
}

function getLogoAltText(work: WorkEntity) {
  return getDisplayTitle(work);
}

function ExternalCard({ work, actionsComponent, dateText }: WorkCardProps) {
  const location = getLocation(work);
  const logoURL = getLogoURL(work);
  const logoAltText = getLogoAltText(work);
  const price = work.price ? currency(work.price) : null;

  return (
    <>
      <div className="z-50 absolute top-0 right-0">{actionsComponent}</div>
      <div className="card__body relative flex">
        <div className="pr-6">
          <CardLogo
            imageUrl={logoURL}
            altText={logoAltText}
            logoSize="lg"
            shape="square"
          />
        </div>
        <div className="flex-1 flex flex-col justify-between overflow-hidden">
          <div>
            <p className="text-large leading-snug mb-4">
              {getDisplayTitle(work)}
            </p>
            {work.type !== 'Student' ? (
              <p className="font-semibold text-grey-500">
                {work.role?.name || 'Unknown role'}
              </p>
            ) : null}
            <h5 className="mt-1 break-words">{work.title}</h5>
            {location ? (
              <p className="text-base mt-1 mb-3">{location}</p>
            ) : null}
            <p className="text-grey-600 mb-1">{dateText}</p>
          </div>
          <div className="flex items-baseline justify-between">
            <div className="text-sm">
              {getWorkTypeLabel(work.type) || 'Other'}
            </div>
            {price ? <p className="text-right text-large">{price}</p> : null}
          </div>
        </div>
      </div>
    </>
  );
}

function LimitedCard({ work, actionsComponent }: WorkCardProps) {
  const forceMobile = useForceMobile();

  const location = getLocation(work);
  const coverPhotoURL = getCoverPhotoURL(work);
  const logoAltText = getLogoAltText(work);
  const price = work.price ? currency(work.price) : null;

  return (
    <>
      <div className="z-50 absolute top-0 right-0">{actionsComponent}</div>
      <div className="bg-white rounded-md overflow-hidden">
        <div className={mc('md:flex', forceMobile)}>
          <div className={mc('w-full md:w-1/3 md:shrink-0', forceMobile)}>
            <div className={mc('h-full w-full', forceMobile)}>
              <Image
                alt={logoAltText}
                src={cloudinary(coverPhotoURL, ['w_640', 'q_70'])}
                lazy
                className="aspect-21/9 md:aspect-square"
              />
            </div>
          </div>
          <div className="p-6 w-full">
            <div>
              <p className="text-lg">{work.role?.name || 'Unknown role'}</p>
              <h5 className="mt-1 break-words leading-snug">{work.title}</h5>
              {location ? <p className="mt-1">{location}</p> : null}
            </div>
            <div className="flex mt-4 items-baseline">
              <div className="flex-1 flex flex-col justify-between overflow-hidden">
                <div>
                  <p className="font-semibold">
                    {getWorkTypeLabel(work.type) || 'Other'}
                  </p>
                </div>
              </div>
              <div className="pl-6">
                <div className="overflow-hidden">
                  <p className="text-right text-lg">{price}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FullCard({ work, actionsComponent }: WorkCardProps) {
  const location = getLocation(work);
  const logoURL = getLogoURL(work);
  const logoAltText = getLogoAltText(work);
  const price = work.price ? currency(work.price) : null;

  return (
    <div className="card__body relative flex flex-col justify-between">
      <div className="z-50 absolute top-0 left-0 -mt-8">{actionsComponent}</div>
      <div>
        <div className="absolute top-0 right-0 transform -translate-y-1/2 mr-6">
          <CardLogo
            imageUrl={logoURL}
            altText={logoAltText}
            logoSize="xl"
            borderSize="md"
          />
        </div>
        <p className="font-semibold text-grey-500 mt-1 pr-20">
          {work.role?.name || 'Unknown role'}
        </p>
        <h5 className="mt-1 leading-tight">{work.title}</h5>
        {location ? <p className="text-base mt-1">{location}</p> : null}
      </div>
      <div className="flex items-baseline justify-between">
        <div>{getWorkTypeLabel(work.type) || 'Other'}</div>
        {price ? (
          <p className="text-right text-large mt-4 tracking-tight">{price}</p>
        ) : null}
      </div>
    </div>
  );
}

const WorkActions = ({
  id,
  handleDeleteClick,
}: {
  id: string | null;
  handleDeleteClick: any;
}) => {
  const { push } = useHistory();
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    push(`/work/${id}/edit`);
  };

  if (!id) return null;

  return (
    <div className="px-6 py-2 text-base text-link bg-white opacity-80 rounded-tr-md">
      <div className="flex items-center opacity-100">
        <div role="none" onClick={handleDeleteClick}>
          <i className="i-alert-error" />{' '}
          <span className="text-xs font-semibold">Close</span>
        </div>
        <div className="ml-4" onClick={handleClick} role="none">
          <i className="i-edit" />{' '}
          <span className="text-xs font-semibold">Edit</span>
        </div>
      </div>
    </div>
  );
};

const WorkCard = ({ work, cardStyle = 'limited', className = '' }: Props) => {
  const { createdAt, title } = work;

  const { isRoot } = useContext(UserContext);
  const canEdit: boolean = useMemo(() => isRoot, [isRoot]);
  const dateText: string | null = useMemo(() => {
    const dateDiff = Math.abs(
      moment(new Date()).diff(moment(createdAt), 'hours')
    );
    return dateDiff <= 24
      ? moment(createdAt)
          .utc()
          .fromNow()
      : null;
  }, [createdAt]);
  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { push } = useHistory();
  const { track } = useAnalytics();

  // const user = useContext(UserContext);
  // TODO: Pull from UserContext
  const canApplyToCommunoWork = true;
  const coverPhotoURL = getCoverPhotoURL(work);

  const image = useMemo(
    () => (
      <CardImage
        srcSet={srcSetFunction(coverPhotoURL, ['600', '1000', '1300'])}
        imageUrl={cloudinary(coverPhotoURL || '', ['w_826', 'h_414'])}
        altText={title || ''}
        aspectRatio="2/1"
      >
        {dateText && (
          <p className="absolute top-0 right-0 px-6 py-2 text-sm bg-black text-white">
            {dateText}
          </p>
        )}
      </CardImage>
    ),
    [coverPhotoURL, title, dateText]
  );

  const cardStyles = {
    limited: LimitedCard,
    full: FullCard,
    external: ExternalCard,
  };
  const CardComponent = cardStyles[cardStyle];

  const handleCardClick = useCallback(() => {
    if (work.isExternal) {
      const {
        id: workId,
        title: workTitle,
        type,
        isRemote,
        isOneClickApply,
        price,
        externalURL,
        companyName,
      } = work;
      const { friendlyName: location } = work.location;

      if (isOneClickApply) {
        push(`/work/${work.id || work.objectID}`);
        return;
      }

      track('External Work Clicked', {
        workId,
        workTitle,
        type,
        isRemote,
        price,
        externalURL,
        companyName,
        location,
        role: work.role?.name,
        roleId: work.role?.id,
        membershipName: work?.membership?.name,
        membershipType: work?.membership?.plan.type,
      });
      push(`/work/${work.id || work.objectID}`);
      return;
    }

    if (canApplyToCommunoWork) {
      push(`/work/${work.id || work.objectID}`);
      return;
    }
    setShowUpgradeAlert(true);
  }, [push, work, canApplyToCommunoWork, track]);

  // purgecss: card--work--limited card--work--full

  const openDeleteAlert = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteAlert(true);
  };

  const actionsComponent = canEdit ? (
    <WorkActions
      id={work.id || work.objectID || null}
      handleDeleteClick={openDeleteAlert}
    />
  ) : null;

  return (
    <>
      <Card
        onClick={handleCardClick}
        imageComponent={cardStyle === 'full' ? image : undefined}
        className={`card--work card--work--${cardStyle} relative ${className}`}
      >
        <CardComponent
          work={work}
          dateText={dateText}
          actionsComponent={actionsComponent}
        />
      </Card>
      <Alert
        isOpen={showUpgradeAlert}
        message="Access to this post is not included with your plan. To view these opportunities upgrade your membership plan."
        header="Exclusive content"
        onDidDismiss={() => {
          setShowUpgradeAlert(false);
        }}
        buttons={[
          {
            text: 'Request upgrade',
            cssClass: 'alert-primary-btn',
            handler: () => {
              // console.log('Redirect the user to upgrade!');
            },
          },
          {
            text: 'No thanks, I’m fine at this level.',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
        ]}
      />
      <Alert
        isOpen={showDeleteAlert}
        onDidDismiss={() => {
          setShowDeleteAlert(false);
        }}
        header="Are you sure!"
        message="Closing work can’t be undone."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Close',
            cssClass: 'alert-primary-btn',
            handler: () => {
              // Workaround for now, set work expiresAt to now until we have a proper delete route
              const now = moment()
                .utc()
                .format();

              const workId = work.id || work.objectID || null;

              if (workId) {
                workApi
                  .update(workId, { expiresAt: now })
                  // eslint-disable-next-line
                  .then((response: WorkEntity) => {
                    // TODO: How to remove from the feed? Better way than a full reload?
                    window.location.reload();
                  })
                  .catch(addResponseError);
              }
            },
          },
        ]}
      />
    </>
  );
};
export default React.memo(WorkCard);
