// eslint-disable-next-line no-unused-vars
import { SearchOptions } from '@algolia/client-search';

import {
  /* eslint-disable-next-line no-unused-vars */
  ImperativeActions,
  /* eslint-disable-next-line no-unused-vars */
  FilterOptionComponentProps,
  /* eslint-disable-next-line no-unused-vars */
  FilterOptionDependencyOperator,
} from '../index.d';

type Props<T> = {
  dependsOn?: {
    targetName: string;
    operator: FilterOptionDependencyOperator;
    value?: any;
  };
  name: string;
  toggleLabel: React.ReactNode;
  component: React.ReactType<FilterOptionComponentProps<T>>;
  onToggle?: (actions: ImperativeActions) => void;
  initialValue?: T;
  sectionTitle?: string;
  showTitle?: boolean;
  size?: 'small' | 'large';
  modalHeight?: string;
  fullHeight?: boolean;
  resultsCount?: number;
  toFacetFilter?: (filters: any) => string[];
  toAlgoliaFilter?: (filters: any) => string | null;
  addAlgoliaParam?: (algoliaParams: SearchOptions, filters: any) => void;
};

class BaseFilterOption<T = any> {
  dependsOn?: {
    targetName: string;
    operator: FilterOptionDependencyOperator;
    value?: any;
  };

  name: string;

  toggleLabel: React.ReactNode;

  component: React.ReactType<FilterOptionComponentProps<T>>;

  onToggle?: (actions: ImperativeActions) => void;

  initialValue?: T;

  sectionTitle?: string;

  showTitle?: boolean;

  size?: 'small' | 'large';

  modalHeight?: string;

  fullHeight?: boolean;

  resultsCount?: number;

  /**
   * Should return an array of strings to be added
   * to the algolia query in the `facetFilters` key.
   */
  toFacetFilter?: (filters: any) => string[];

  /**
   * Should return an algolia query string that will
   * be added to the `filters` key.
   */
  toAlgoliaFilter?: (filters: any) => string | null;

  /**
   * Optional method for a filter to add parameters
   * directly to the aloglia query.
   */
  addAlgoliaParam?: (algoliaParams: SearchOptions, filters: any) => void;

  constructor({
    dependsOn,
    name,
    toggleLabel,
    component,
    onToggle,
    initialValue,
    sectionTitle,
    showTitle = false,
    size = 'small',
    modalHeight,
    fullHeight = false,
    resultsCount,
    toFacetFilter,
    toAlgoliaFilter,
    addAlgoliaParam,
  }: Props<T>) {
    this.dependsOn = dependsOn;
    this.name = name;
    this.toggleLabel = toggleLabel;
    this.component = component;
    this.onToggle = onToggle;
    this.initialValue = initialValue;
    this.sectionTitle = sectionTitle;
    this.showTitle = showTitle;
    this.size = size;
    this.modalHeight = modalHeight;
    this.fullHeight = fullHeight;
    this.resultsCount = resultsCount;
    this.toFacetFilter = toFacetFilter;
    this.toAlgoliaFilter = toAlgoliaFilter;
    this.addAlgoliaParam = addAlgoliaParam;
  }
}

export default BaseFilterOption;
