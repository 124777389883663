import React from 'react';
import ForgotPasswordSent from '../components/user/views/ForgotPasswordSent';
import Page from '../components/core/Page';

export default () => {
  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Forgot Password Sent"
      mobileHeader={() => null}
      hideHeader
    >
      <ForgotPasswordSent />
    </Page>
  );
};
