// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';

import {
  LocationFilterOption,
  RadioFilterOption,
  BooleanFilterOption,
  SelectFilterOption,
} from '../../filter/options';
import getRoleOptions from './getRoleOptions';
import { isMobile } from '../../../utilities/Device';
import getProjectOptionOptions from './getProjectOptionOptions';
import { ENABLE_PROJECT_TYPES } from '../../../config';
import getTypeOptions from './getTypeOptions';

function getWorkFilterOptions(
  results: { [key: string]: SearchResponse },
  membership: MembershipEntity | null,
  roles: RoleEntity[],
  projectOptions: ProjectOptionEntity[]
) {
  const workFilters = [];

  if (ENABLE_PROJECT_TYPES) {
    workFilters.push(
      new SelectFilterOption({
        name: 'projectType',
        toggleLabel: 'Project',
        sectionTitle: 'Project',
        placeholder: 'Select a Project Type or type to search...',
        options: getProjectOptionOptions(results, projectOptions),
        toFacetFilter: (filters: any): string[] =>
          filters.projectType
            ? [`projectOption.name:${filters.projectType}`]
            : [],
        isClearable: true,
      })
    );
  }

  workFilters.push(
    new SelectFilterOption({
      name: 'role',
      toggleLabel: 'Role',
      sectionTitle: 'Role',
      placeholder: 'Select a Role or type to search...',
      options: getRoleOptions(results, roles),
      toFacetFilter: (filters: any): string[] => {
        return filters.role ? [`role.name:${filters.role}`] : [];
      },
      isClearable: true,
    })
  );

  workFilters.push(
    new RadioFilterOption({
      multiple: false,
      name: 'type',
      toggleLabel: 'Type',
      sectionTitle: 'Type of Work',
      modalHeight: 'auto',
      options: getTypeOptions(results),
      toFacetFilter: (filters: any): string[] => {
        return filters.type ? [`type:${filters.type}`] : [];
      },
    })
  );

  workFilters.push(
    new LocationFilterOption({
      name: 'location',
      toggleLabel: 'Location',
      sectionTitle: 'Location',
      fullHeight: isMobile,
      resultsCount: results?.search?.nbHits,
      addAlgoliaParam: (algoliaParams: any, filters: any) => {
        if (filters.location) {
          const { location, distance = 20 } = filters.location;

          if (location) {
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundLatLng = `${location.coordinates.lat},${location.coordinates.lng}`;
            // eslint-disable-next-line no-param-reassign
            algoliaParams.aroundRadius = parseInt(distance, 10) * 1000;
          }
        }
      },
    })
  );

  const remoteLabel = `Remote`;
  workFilters.push(
    new BooleanFilterOption({
      name: 'isRemote',
      toggleLabel: remoteLabel,
      activeLabel: 'Remote',
      options: ['All Work', remoteLabel],
      toFacetFilter: (filters: any): string[] => {
        return filters.isRemote ? [`isRemote:${true}`] : [];
      },
    })
  );

  return workFilters;
}

export default getWorkFilterOptions;
