/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { Controller } from 'react-hook-form';
import FormGroup from './FormGroup';

export default ({
  name,
  leftValue,
  leftLabel,
  rightValue,
  rightLabel,
  hint = null,
  label,
  labelVisible = true,
  required = false,
  errors,
  control = null,
  className = '',
}: any) => {
  return (
    <FormGroup
      className={`${className} form-toggle`}
      name={name}
      hint={hint}
      label={label}
      labelVisible={labelVisible}
      required={required}
      errors={errors}
    >
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <IonSegment
            mode="ios"
            onIonChange={e => onChange(e?.detail.value)}
            value={value}
          >
            <IonSegmentButton value={leftValue}>
              <IonLabel>{leftLabel}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={rightValue}>
              <IonLabel>{rightLabel}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        )}
      />
    </FormGroup>
  );
};
