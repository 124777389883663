import React, { useContext, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '../core/form';
import { SubmitButton } from '../core/Button';
import SearchContext from '../search/Context';
import Avatar from '../avatar/Avatar';
import useChatServices from '../chat/hooks/useChatServices';
import Api from './Api';
import { ENABLE_INVITE_TO_APPLY } from '../../config';
import { WorkContext } from './View';

type LabelProps = {
  label: string;
  value: {
    identity: string;
    firstName: string;
    lastName: string;
    avatarURL: string;
  };
};

type Props = {
  shareUrl: TCardId;
  onComplete: Function;
  work: WorkEntity;
  user: string | undefined;
  onlyInvite: boolean;
  formLabel: string;
};

const InAppShareable = ({
  shareUrl,
  onComplete,
  work,
  user,
  onlyInvite,
  formLabel,
}: Props) => {
  const { checkForExistingChannel, createChannel } = useChatServices();
  const { setUpdateApplications } = useContext(WorkContext);
  const { getUsers } = useContext(SearchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(Math.random());
  const form = useForm({});

  const { handleSubmit, reset } = form;

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        setIsLoading(true);
        const { recipient } = values;
        const { identity } = recipient.value;

        let message = 'Thought you might be perfect for this position.';
        const existingChannel = await checkForExistingChannel(identity);

        // If the current user owns this work then track this invite as a work application.
        if (ENABLE_INVITE_TO_APPLY && user === work.user.id && onlyInvite) {
          await Api.invite(work.id, { userId: identity }); // setting invite here
          message = 'I would like to invite you to apply for our position.';
        }

        const fullMessage = `${message}\n\n${shareUrl}`;

        if (existingChannel) {
          await existingChannel.sendMessage(fullMessage);
          await existingChannel.setAllMessagesRead();
        } else {
          await createChannel({
            identities: [identity],
            message: fullMessage,
          });
        }

        reset();
        onComplete();
        setId(Math.random());
        setUpdateApplications(true);
      } catch (err) {
        onComplete(err);
      } finally {
        setIsLoading(false);
      }
    },
    [
      shareUrl,
      checkForExistingChannel,
      createChannel,
      onComplete,
      reset,
      user,
      work,
      onlyInvite,
      setUpdateApplications,
    ]
  );

  const getResults = (q: string) =>
    getUsers({ query: q }).then(({ hits }: any) =>
      hits.map((hit: any) => {
        const { objectID: identity, firstName, lastName, avatarURL } = hit;
        return {
          value: { identity, firstName, lastName, avatarURL },
          label: `${firstName} ${lastName}`,
        };
      })
    );

  const formatLabel = ({ label, value }: LabelProps) => {
    const { firstName, lastName, avatarURL } = value;

    return (
      <div className="flex items-center">
        <Avatar avatarName={`${firstName} ${lastName}`} avatarUrl={avatarURL} />
        <span className="ml-2">{label}</span>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <p className="text-lg-font-semibold">{formLabel}</p>
      <div className="flex flex-row space-x-2 mt-2 mb-6">
        <div className="w-full">
          <Autocomplete
            key={id}
            name="recipient"
            form={form}
            callback={getResults}
            isRequired
            placeholder="Search users..."
            formatOptionLabel={formatLabel}
          />
        </div>
        <SubmitButton text="Go" loading={isLoading} />
      </div>
    </form>
  );
};

export default InAppShareable;
