import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import { Input, DropdownIndicator, Menu } from './Components';

const Select = forwardRef((props: SelectComponentsProps, ref: any) => {
  let components = {
    Input,
    DropdownIndicator,
    Menu,
  };

  if (props.components) {
    components = {
      ...components,
      ...props.components,
    };
  }

  return (
    <ReactSelect
      ref={ref}
      className="multiselect"
      classNamePrefix="multi"
      menuShouldScrollIntoView
      menuPosition="absolute"
      openMenuOnFocus
      components={components}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});
export default Select;
