import React, { useContext } from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import { Link } from '../core/router';
import { WorkContext } from './View';

const ViewApplicantsButton = ({
  workId,
  trackingEventName,
  applicantCount,
  isExternalJob,
  width = 'md:w-auto',
}: {
  workId: string;
  trackingEventName: string;
  applicantCount: number;
  isExternalJob: boolean;
  width?: string;
}) => {
  const { track } = useAnalytics();
  const { updateApplications } = useContext(WorkContext);

  if (isExternalJob) {
    return <div />;
  }
  return (
    <div className="flex flex-col text-center md:ml-auto whitespace-nowrap">
      <Link
        className={`btn btn--sm btn--primary btn--outline ${width}`}
        onClick={async () => track(trackingEventName)}
        to={{
          pathname: `/work/${workId}/candidates`,
          state: updateApplications,
        }}
      >
        {`${`${applicantCount}`} ${
          applicantCount === 1 ? 'Applicant' : 'Applicants'
        }`}
      </Link>
    </div>
  );
};

export default ViewApplicantsButton;
