import React from 'react';
import { HashLink } from '../core/router';

declare type Props = {
  step: ProfileStep;
};

const ProgressStep = ({ step }: Props) => {
  return (
    <div className="profile-completeness-slider__slide">
      <div>
        <div className="rounded-lg+ bg-teal-900 text-white p-2 w-10 h-10 flex items-center justify-center">
          <i className={`i-${step.icon} text-xl`} />
        </div>
      </div>
      <div className="py-2 pr-2 xs:pt-0 md:py-0 md:pr-0 xs:pl-4 flex-1 flex flex-wrap">
        <div className="md:flex-1 md:pr-4 text-left">
          <p className="font-medium mb-1">{step.label}</p>
          <p className="text-subdued text-sm">{step.description}</p>
        </div>
        <div className="mt-4 mb-0 w-full md:w-auto md:mt-0">
          <HashLink
            to={step.action}
            className="btn btn--outline btn--full md:w-auto whitespace-nowrap"
          >
            {step.actionLabel}
          </HashLink>
        </div>
      </div>
    </div>
  );
};
export default ProgressStep;
