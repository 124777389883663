import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import ChannelCard from '../channels/ChannelCard';
import ChannelQueries from '../channels/queries';
import { ChannelInteraction } from '../../@types/chat.d';
import useNavigate from '../../hooks/useNavigate';
import useLatestChannels from '../channels/hooks/useLatestChannels';

const LatestChannels = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: latestChannels } = useLatestChannels();

  const interactWithChannel = ChannelQueries.useInteractWithChannel();
  const { data: getUnjoinedChannels } = ChannelQueries.useGetUnjoinedChannels();

  const handleChannelView = (id: TCardId) => {
    interactWithChannel.mutate(
      { cardId: id.toString() },
      {
        onSuccess: d => {
          queryClient.invalidateQueries(['channels']);
          navigate(`/chat/conversation/${d.conversationId}`);
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <h4 className="flex-1">Latest channels</h4>
        <Link className="text-link font-semibold" to="/channels">
          View more
        </Link>
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-8">
        {(getUnjoinedChannels?.length === 0
          ? latestChannels
          : getUnjoinedChannels
        )
          ?.slice(0, 2)
          .map((channel: ChannelInteraction) => (
            <ChannelCard
              currentTab="all"
              details={channel}
              key={channel.id}
              onViewChannel={() => handleChannelView(channel.id)}
            />
          ))}
      </div>
    </div>
  );
};

export default React.memo(LatestChannels);
