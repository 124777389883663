import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useReferencesForUser(
  userId: string,
  includeRatings: boolean = true
): {
  isFetching: boolean;
  response?: ReferencesResponse | null;
  refetch: Function;
} {
  const { data, isFetching, refetch } = useQuery(
    ['references-for-user', userId, includeRatings],
    () => {
      if (!userId) {
        return null;
      }

      return Api.getUsersReferences(userId, includeRatings);
    },
    {
      refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isFetching,
    response: data,
    refetch,
  };
}
