import React, { useRef } from 'react';
import { useIntersection } from 'react-use';
import { LoadingRing } from './Loading';

const IntersectionLoader = ({
  showLoadingIndicator = true,
  threshold = 0,
  onIntersect,
}: {
  showLoadingIndicator?: boolean;
  threshold?: number;
  onIntersect: () => Promise<any>;
}) => {
  const loading = useRef(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold,
  });

  const load = async () => {
    if (loading.current) return;

    loading.current = true;

    await onIntersect();

    loading.current = false;
  };

  // Workaround to call a function within JSX
  const callLoad = () => {
    load();
    return showLoadingIndicator ? (
      <LoadingRing type="primary" isActive />
    ) : null;
  };

  return (
    <div
      className={`intersection-loader${
        showLoadingIndicator ? ' intersection-loader--has-indicator' : ''
      }`}
      ref={intersectionRef}
    >
      {intersection &&
      (intersection.isIntersecting || intersection.intersectionRatio > 0)
        ? callLoad()
        : null}
    </div>
  );
};

export default React.memo(IntersectionLoader);
