import React from 'react';
import { FormTextarea } from '../../core/form';

const Intro = ({ errors, register, form }: any) => (
  <FormTextarea
    name="summary"
    label="Intro"
    placeholder="Describe yourself in one sentence. e.g. Advertising veteran specializing in branding & marketing"
    errors={errors}
    rows={5}
    ref={register({
      maxLength: {
        value: 250,
        message: 'Max length of 250 characters',
      },
    })}
    characterCount={250}
    form={form}
  />
);

export default Intro;
