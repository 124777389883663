import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Messaging from '../../../../services/Messaging';
import { Name, Location } from '../../../membership/formElements';
import MembershipContext from '../../../membership/Context';
import useStep from '../../../stepper/hooks/useStep';
import CompanySize from '../../../membership/formElements/CompanySize';
import useIsFormValid from '../../../../hooks/useIsFormValid';
import SocialLinks, {
  SocialLink,
} from '../../../user/formElements/SocialLinks';
import useAnalytics from '../../../../hooks/useAnalytics';

const { addResponseError } = Messaging;

const links: SocialLink[] = [
  { key: 'WebsiteURL', label: 'Company website', optional: true },
];

const AgencyAbout = () => {
  const { membership, update: updateMembership } = useContext(
    MembershipContext
  );
  const { goToNextStep, setCanAdvance, setOnNext } = useStep();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: membership?.name,
      WebsiteURL: membership?.socialLinks?.WebsiteURL,
      location: {
        latitude: membership?.location?.latitude || '',
        longitude: membership?.location?.longitude || '',
        friendlyName: membership?.location?.friendlyName || '',
      },
      size: {
        value: membership?.size,
      },
    },
  });
  const { handleSubmit, register, errors, reset, formState } = form;
  const { isSubmitted } = formState;
  const isValid = useIsFormValid(form, ['name', 'location.friendlyName']);

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AgencyAbout Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (loading) return;

      setLoading(true);
      const { size, WebsiteURL, ...rest } = values;

      updateMembership({
        ...rest,
        socialLinks: { WebsiteURL },
        size: size.value,
      })
        .then(() => {
          // reset the form so it will not be 'dirty'
          reset({
            name: values.name,
            location: values.location,
            size: values.size,
            WebsiteURL: values.WebsiteURL,
          });
          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, loading, reset, updateMembership]
  );

  useEffect(() => {
    setCanAdvance(isValid);
  }, [setCanAdvance, isValid]);

  useEffect(() => {
    setOnNext(() => {
      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Name errors={isSubmitted ? errors : {}} register={register} required />
      <Location
        errors={errors}
        register={register}
        defaultValue={membership?.location?.friendlyName || ''}
        form={form}
        label="Company location"
        required
      />
      <SocialLinks errors={errors} register={register} links={links} />
      <CompanySize form={form} defaultValue={membership?.size || 4} />
    </form>
  );
};

export default AgencyAbout;
