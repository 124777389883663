import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import cn from 'classnames';
import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { SHOW_APPLICANT_NOTES } from '../../config';
import UserContext from '../user/Context';
import CandidateStatusForm from './forms/CandidateStatusForm';
import UnreadIndicator from './UnreadIndicator';
import CandidatesNotesTab from './CandidatesNotesTab';
import CandidatesChatTab from './CandidatesChatTab';
import CandidatesProfileTab from './CandidatesProfileTab';
import CandidatesApplicationTab from './CandidatesApplicationTab';
import { isMobile } from '../../utilities/Device';

type Props = {
  work: WorkEntity;
  application: ApplicationEntity;
  patchApplications: Function;
  hire: (applicationId: string, sendEmail: boolean) => void;
  reject: (applicationId: string) => void;
};

const NavButton = ({
  value,
  text,
  className,
  children,
}: {
  value: string;
  text: string;
  className?: string;
  children?: React.ReactNode;
}) => (
  <IonSegmentButton
    value={value}
    className={cn('min-w-0 w-full flex flex-col items-center', className)}
  >
    {children}
    <IonLabel className="w-full min-w-fit">{text}</IonLabel>
  </IonSegmentButton>
);

const ApplicationPreview = ({
  work,
  application,
  patchApplications,
  hire,
  reject,
}: Props) => {
  const initialAnchor = window.location.hash.slice(1);
  const history = useHistory();
  const { isPublic } = useContext(UserContext);
  const [segment, setSegment] = useState(initialAnchor || 'application');
  const [messageTemplate, setMessageTemplate] = useState('');
  const { user, chatChannelSid } = application;
  const { roles: userRoles } = user;

  const setLocationAnchor = useCallback(
    (anchor: string) => {
      const newLocation = history.location;
      newLocation.hash = anchor;
      history.replace(newLocation);
    },
    [history]
  );

  const hasStartedConversation = Boolean(application.chatChannelSid);

  useEffect(() => {
    setLocationAnchor(segment);
  }, [segment, setLocationAnchor]);

  useEffect(() => {
    setMessageTemplate('');
  }, [application.id, application.user.id, work.id]);

  const showChat = !isPublic;

  const planType = useMemo(() => {
    return application.user.primaryMembershipUser?.membership.plan.type;
  }, [application]);

  const membership = useMemo(() => {
    return application.user.primaryMembershipUser?.membership;
  }, [application]);

  // if it's an agency, we want to show the roles of the agency, if not, we want the user's roles
  const role =
    planType === 'agency'
      ? membership?.roles[0]?.role.nameForMembership
      : userRoles.map(userRole => userRole.role.name)[0];

  const showProfile = () => {
    setSegment('profile');
  };

  const sendMeetingLink = (link: string) => {
    const updateMessageTemplate = () => {
      const boilerplateMessage = hasStartedConversation ? link : '';
      setMessageTemplate(boilerplateMessage);
    };

    const meetingLink = updateMessageTemplate();
    setSegment('chat');
    return meetingLink;
  };

  return (
    <div className="h-full flex flex-col md:pb-0">
      <div className="bg-white border-b border-grey-100 fixed md:sticky top-auto md:top-0 bottom-0 md:bottom-auto left-0 md:left-auto w-full md:w-auto md:h-auto z-10 flex shadow-top md:shadow-none pb-safe">
        <IonSegment
          mode="md"
          value={segment}
          className="application-preview h-16 md:h-8 w-full"
          onIonChange={(e: any) => {
            setSegment(e.detail.value);
          }}
        >
          <NavButton
            value="application"
            text="Application"
            className="md:px-4"
          />
          <NavButton value="profile" text="Profile" />
          {showChat && (
            <NavButton value="chat" text="Chat">
              <UnreadIndicator
                className="mt-1"
                channelSids={[chatChannelSid]}
              />
            </NavButton>
          )}
          {SHOW_APPLICANT_NOTES && <NavButton value="notes" text="Notes" />}
          <NavButton value="status" text="Status" className="md:hidden" />
        </IonSegment>
      </div>

      <div className="h-full bg-white grow overflow-y-auto disable-scrollbars">
        <div className={`${segment === 'application' ? '' : 'hidden'}`}>
          <div className="p-4 pb-48 md:pb-4 flex flex-col gap-4">
            <CandidatesApplicationTab
              application={application}
              hasStartedConversation={hasStartedConversation}
              hire={hire}
              reject={reject}
              membership={membership}
              patchApplications={patchApplications}
              planType={planType}
              role={role}
              sendMeetingLink={sendMeetingLink}
              showProfile={showProfile}
            />
          </div>
        </div>
        <div className={`${segment === 'profile' ? '' : 'hidden'}`}>
          <CandidatesProfileTab
            application={application}
            membership={membership}
            planType={planType}
            role={role}
            work={work}
          />
        </div>
        {showChat && (
          <div className={`${segment === 'chat' ? 'h-full' : 'hidden'}`}>
            <CandidatesChatTab
              application={application}
              messageTemplate={messageTemplate}
              patchApplications={patchApplications}
              segment={segment}
              work={work}
            />
          </div>
        )}
        {SHOW_APPLICANT_NOTES && (
          <div className={`${segment === 'notes' ? '' : 'hidden'}`}>
            <CandidatesNotesTab
              setSegment={setSegment}
              application={application}
              work={work}
              role={role}
            />
          </div>
        )}
        {segment === 'status' && (
          <CandidateStatusForm
            application={application}
            isPublic={isPublic}
            patchApplications={patchApplications}
            onComplete={() => isMobile && setSegment('application')}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationPreview;
