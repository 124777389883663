import Axios from 'axios';
import { BaseAPI, Api } from '../../Api';

const customApi = {
  selfServe: () => Api.get(`/billing/portal`),
  getAccount: () => Api.get(`/billing/account`),
  getBillingInfo: () => Api.get(`/billing/billing-info`),
  getPlanBySlug: (slug: string) => Api.get(`/plan/${slug}`),
  getPlans: () => Api.get(`/plan`),
  getCoupon: ({
    recurlyPublicKey,
    recurlyPlanCode,
    coupon,
  }: {
    recurlyPublicKey: string;
    coupon: string;
    recurlyPlanCode: string;
  }) =>
    Axios.get(
      `https://api.recurly.com/js/v1/coupons/${coupon}?plan_codes[0]=${recurlyPlanCode}&key=${recurlyPublicKey}`
    ),
  getItem: ({
    recurlyPublicKey,
    recurlyPlanCode,
    item,
  }: {
    recurlyPublicKey: string;
    item: string;
    recurlyPlanCode: string;
  }) =>
    Axios.get(
      `https://api.recurly.com/js/v1/items/${item}?plan_codes[0]=${recurlyPlanCode}&key=${recurlyPublicKey}`
    ),
  previewUpgradePlan: (payload: { planCode: string | null; coupon?: string }) =>
    Api.post(`/billing/upgrade/preview`, payload),
  upgradePlan: (payload: {
    planCode: string;
    paymentTokenId?: string;
    coupon?: string;
    items?: string[];
  }) => Api.post(`/billing/upgrade`, payload),
  changePlan: (payload: { userId: string; planId: string }) =>
    Api.patch('/change-plan', payload),
};

export default { ...BaseAPI('plan'), ...customApi };
