import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormGroup } from '../../core/form';
import UserContext from '../Context';
import Button from '../../core/Button';
import {
  addError,
  addResponseError,
  addSuccess,
} from '../../../services/Messaging';
import { Api } from '../../../Api';
import MembershipContext from '../../membership/Context';

const Form = () => {
  const { user } = useContext(UserContext);
  const { plan: currentPlan, membership } = useContext(MembershipContext);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [membershipWarning, setMembershipWarning] = useState(false);
  const history = useHistory();

  const deactivate = () => {
    if (deactivating) return;

    if (!membership?.id) {
      addError('Something went wrong');
      return;
    }

    Api.patch(`/root/membership/${membership.id}/deactivate`, {
      membershipId: membership.id,
    })
      .then(() => {
        addSuccess('Deactivated account successfully');

        setTimeout(() => {
          history.push('/logout');
        }, 1500);
      })
      .catch((err: any) => addResponseError(err))
      .finally(() => setDeactivating(false));
  };

  const checkForMembership = () => {
    if (currentPlan?.canCancelPlan) {
      setShowDeactivate(true);
    } else {
      setMembershipWarning(true);
    }
  };

  return (
    <>
      <div>
        <FormGroup
          name="account-status"
          label="Account Status"
          className="flex flex-col"
        >
          <p>{!user?.isDeactivated ? 'Active' : 'Inactive'}</p>
        </FormGroup>
        {!membershipWarning ? (
          <div className="mt-8 pt-8 border-t border-grey-100">
            {!showDeactivate ? (
              <Button
                fill="outline"
                color="tertiary"
                onClick={() => checkForMembership()}
                fullWidth
                size="sm"
              >
                Deactivate account
              </Button>
            ) : (
              <div>
                <p className="mb-4">
                  Are you sure you would like to deactivate your account?
                </p>
                <Button
                  fill="outline"
                  className="mr-2"
                  onClick={() => {
                    if (deactivating) return;
                    setShowDeactivate(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="mr-4"
                  color="primary"
                  onClick={() => deactivate()}
                  loading={deactivating}
                >
                  Deactivate account
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-8 pt-8 border-t border-grey-100">
            <p className="mb-4">
              Contact{' '}
              <a
                href="mailto:support@communo.com?subject=Deactivate Subscription"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold text-red-900"
              >
                support@communo.com
              </a>{' '}
              to cancel your monthly Communo subscription before deactivating
              your account
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
