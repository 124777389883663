import React from 'react';
import { LoadingBalls } from '../core/Loading';
import ChannelCard from './ChannelCard';
import ChannelQueries from './queries';
import CardEmpty from './CardEmpty';
import { addError } from '../../services/Messaging';

type UnjoinedChannelsCardsProps = {
  onShare?: (id: TCardId) => void;
  onView: (id: TCardId) => void;
  currentTab: 'all' | 'mine';
};

const UnjoinedChannelsCards = ({
  onView,
  currentTab,
}: UnjoinedChannelsCardsProps) => {
  const getUnjoinedChannels = ChannelQueries.useGetUnjoinedChannels();

  if (getUnjoinedChannels.status === 'loading') {
    return <LoadingBalls isActive />;
  }

  if (getUnjoinedChannels.status === 'error') {
    addError('There was an error while fetching available channels');
    return null;
  }

  return (
    <div className="w-fit items-center">
      {getUnjoinedChannels.data.length > 0 ? (
        <div
          className={`${
            currentTab === 'all'
              ? 'grid sm:grid-col-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 justify-items-center place-content-center'
              : 'hidden'
          } `}
        >
          {getUnjoinedChannels.data.map((channel, i) => (
            <ChannelCard
              currentTab={currentTab}
              details={channel}
              onViewChannel={() => onView(channel.id)}
              key={`${channel.id}-${i.toString()}`}
            />
          ))}
        </div>
      ) : (
        <div className="card-empty">
          <CardEmpty isLoading={getUnjoinedChannels.isFetching} />
        </div>
      )}
    </div>
  );
};

export default UnjoinedChannelsCards;
