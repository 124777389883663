import React from 'react';
import ScoreMetrics from './ScoreMetrics';
import { ScoreCardProps } from './CandidateScore';
import ProfileMetrics from './metrics/Profile';
import ReputationMetrics from './ReputationMetrics';

const InternalCandidateScoreCard = ({
  isUser,
  work,
  scoreMetrics,
  isHidden,
}: ScoreCardProps) => {
  return (
    <>
      <div>
        <h6 className="mb-2">Profile Completion</h6>
        <ProfileMetrics
          isUser={isUser}
          scoreMetrics={scoreMetrics}
          isHidden={isHidden}
        />
      </div>

      {isHidden ? (
        <div className="sm:col-span-2 md:col-span-1 lg:col-span-2">
          <div className="flex flex-col flex-1 min-h-full bg-red-900 rounded-md p-6 text-center">
            <h3 className="text-white">Incomplete Profile</h3>
            <p className="mt-1 text-white text-lg leading-snug">
              In order to utilize your profile to apply for this work you just
              need to add some basic information to your account.
            </p>
            <p className="mt-4 text-black font-semibold text-sm">
              Click on the (+) icon to complete the required fields.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div>
            <h6 className="mb-2">Job Match</h6>
            <ScoreMetrics
              isUser={isUser}
              work={work}
              scoreMetrics={scoreMetrics}
            />
          </div>
          {isUser ? (
            <div>
              <h6 className="mb-2">Reputation</h6>
              <ReputationMetrics
                scoreMetrics={scoreMetrics}
                showReferenceMetric={isUser}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default InternalCandidateScoreCard;
