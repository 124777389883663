import React from 'react';
import { FormImage } from '../../core/form';

export default React.forwardRef(
  ({ errors, form, defaultValue = '', onChange }: any, ref: any) => {
    const handleChangeImageUrl = (url: string) => {
      return onChange(url);
    };

    return (
      <div className="relative">
        <FormImage
          name="headerPhotoURL"
          label="Cover image"
          aspectRatio={[2, 1]}
          // eslint-disable-next-line no-unused-vars
          onChange={handleChangeImageUrl}
          errors={errors}
          form={form}
          ref={ref}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
);
