import React from 'react';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import ContentHeader from '../../core/ContentHeader';
import useAnalytics from '../../../hooks/useAnalytics';

interface Props {
  resetCode: string;
}

export default ({ resetCode }: Props) => {
  useAnalytics('Reset Password');

  return (
    <>
      <ContentHeader />
      <div className="text-center">
        <h3 className="mb-4">Enter a new password.</h3>
        <div className="text-lg font-light mb-8">
          Something secure, but memorable. And you can always reset it again.
        </div>
      </div>

      <ResetPasswordForm resetCode={resetCode} />
    </>
  );
};
