import React, { useEffect, useCallback, useState } from 'react';
import querystringify from 'querystringify';
import { useParams, useLocation } from 'react-router';
import { Perk, PerkBundle } from '@communo-corp/shared-types';
import { Link } from 'react-router-dom';
import Filters from './Filters';
import Panels from '../core/panels/panels';
import { isMobile } from '../../utilities/Device';
import PageHeaderMobile from '../core/PageHeaderMobile';
import Avatar from '../avatar/Avatar';
import perksApi from './Api';
import { addResponseError } from '../../services/Messaging';
import Markdown from '../core/Markdown';
import PerkCard from '../card/PerkCard';
import CollectionsSidebar from './CollectionsSidebar';
import cloudinary from '../../utilities/Cloudinary';
import useAnalytics from '../../hooks/useAnalytics';
import PageView from '../../analytics/PageView';

const PerksSidebar = ({
  bundleId,
  currentPerkId,
}: {
  bundleId: string;
  currentPerkId?: string;
}) => {
  const [bundle, setBundle] = useState<PerkBundle | null>(null);

  const getBundle = useCallback((id: string) => {
    perksApi
      .retrieveBundle(id)
      .then((response: PerkBundle) => {
        setBundle(response);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    if (bundleId) {
      getBundle(bundleId);
    }
  }, [getBundle, bundleId]);

  return (
    <>
      <div className="content">
        <h4>Other Perks in this bundle</h4>
      </div>
      {bundle?.perks &&
        bundle.perks.map((perk: Perk) => {
          const {
            id,
            name,
            membership,
            offerSummary,
            horizontalHeaderImageUrl,
            descriptionSummary,
          } = perk;

          if (id === currentPerkId) {
            return null;
          }

          return (
            <PerkCard
              className="my-8"
              key={id}
              id={`${id}?bundleId=${bundleId}`}
              name={name}
              company={membership?.name}
              offer={offerSummary}
              logoURL={membership?.logoURL}
              imageURL={horizontalHeaderImageUrl}
              description={descriptionSummary}
            />
          );
        })}
    </>
  );
};

const PerksDetail = () => {
  const [perk, setPerk] = useState<Perk | null>(null);
  const { id: perkId } = useParams() as any;
  const { search } = useLocation();
  const { bundleId } = querystringify.parse(search) as any;

  const contactUser = perk?.contactUser || null;
  const { track, pageIsReady } = useAnalytics();

  const getPerk = useCallback((id: string) => {
    perksApi
      .retrieve(id)
      .then((response: Perk) => {
        setPerk(response);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    if (perkId) {
      getPerk(perkId);
    }
  }, [getPerk, perkId]);

  useEffect(() => {
    if (perk) {
      pageIsReady({
        pageType: PageView.PERK,
        pageId: perk?.id,
        pageName: 'Perk Detail',
        perkId: perk?.id,
        tag: perk?.name,
        title: perk?.descriptionSummary,
        details: perk?.description,
        offerShort: perk?.offer,
        offerLong: perk?.offer,
        about: perk?.about,
        visibleToAgencies: perk?.visibleToAgencies,
        visibleToFreelancers: perk?.visibleToFreelancers,
        isPremium: perk?.isPremium,
        isFeatured: perk?.isFeatured,
        isNew: perk?.isNew,
        isActive: perk?.isActive,
        isBundleOnly: perk?.isBundleOnly,
      });
    }
  }, [pageIsReady, perk]);

  return (
    <Panels
      leftPanel={<Filters backToSearch />}
      contextPanel={
        bundleId ? (
          <PerksSidebar bundleId={bundleId} currentPerkId={perkId} />
        ) : (
          <CollectionsSidebar />
        )
      }
      leftPanelIcon="i-filters"
      contextClassName="bg-grey-100"
      mainClassName="bg-white"
    >
      {toggleLeftPanel => (
        <>
          {perk && (
            <div>
              {isMobile && (
                <PageHeaderMobile
                  title="Perks"
                  action={
                    // eslint-disable-next-line
                    <button
                      type="button"
                      onClick={() => toggleLeftPanel()}
                      className="btn btn--sm"
                    >
                      Filters <i className="i-filters text-lg ml-xs" />
                    </button>
                  }
                />
              )}

              <div className="relative aspect-8/5 md:aspect-3/1 bg-grey-300">
                <img
                  src={cloudinary(perk?.horizontalHeaderImageUrl, [
                    'w_1300',
                    'h_430',
                    'g_auto',
                  ])}
                  alt={perk?.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="px-4 py-6 md:px-12 md:py-8 4xl:px-16 4xl:py-12 5xl:px-24 5xl:py-16 border-l-2 border-grey-100">
                <div className="3xl:flex">
                  <div className="content 3xl:flex-1">
                    <h3 className="text-grey-600">{perk.name}</h3>
                    <h2>{perk.descriptionSummary}</h2>
                    {perk?.description && (
                      <Markdown>{perk?.description}</Markdown>
                    )}

                    {perk?.assetImageUrls.map((url: string) => {
                      if (url.length) {
                        return (
                          <img
                            src={url}
                            alt="asset"
                            className="w-full h-auto my-4"
                            key={url}
                          />
                        );
                      }
                      return null;
                    })}

                    {/* {perk?.assetVideoUrls.map((url: string) => (
                  <video width="320" height="240" controls key={url}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))} */}

                    {perk?.offerDetails && (
                      <>
                        <div className="mt-8 pt-8 border-t border-grey-300">
                          <h4>Terms & Conditions</h4>
                          <Markdown>{perk.offerDetails}</Markdown>
                        </div>
                      </>
                    )}

                    <div className="mt-8 pt-8 border-t border-grey-300">
                      <h4>About {perk?.membership?.name}</h4>
                      <Markdown>{perk?.about}</Markdown>
                    </div>
                  </div>
                  <div className="mt-8 3xl:mt-0 3xl:w-screen-xs 3xl:pl-8">
                    <div className="content">
                      {contactUser && perk?.membership?.id ? (
                        <Link
                          to={`/members/membership/${perk?.membership?.id}`}
                        >
                          <img
                            src={perk?.membership?.logoURL}
                            alt={perk?.membership?.name}
                            className="w-full h-auto max-w-32"
                          />
                        </Link>
                      ) : (
                        <img
                          src={perk?.membership?.logoURL}
                          alt={perk?.membership?.name}
                          className="w-full h-auto max-w-32"
                        />
                      )}

                      <div className="text-large mt-2">
                        {perk?.membership?.name}
                      </div>
                      <h4>{perk?.offer}</h4>
                    </div>
                    {perk?.redeemUrl && (
                      <a
                        href={perk.redeemUrl}
                        className="btn btn--primary w-full"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => {
                          return track('Perk Redeem Clicked', {
                            url: perk.redeemUrl,
                            name: `${perk?.membership?.name} - ${perk?.offer}`,
                          });
                        }}
                      >
                        Redeem
                      </a>
                    )}
                    {contactUser ? (
                      <div className="flex mt-8 pt-8 border-t border-grey-300">
                        <div>
                          <Avatar
                            size="xl"
                            avatarName={`${contactUser.firstName} ${contactUser.lastName}`}
                            avatarUrl={contactUser?.avatarURL}
                          />
                        </div>
                        <div className="pl-10">
                          <h5>{`${contactUser.firstName} ${contactUser.lastName}`}</h5>
                          <p>{contactUser.title}</p>
                          {contactUser?.id ? (
                            <Link
                              to={`/chat/new-direct-message/${contactUser.id}`}
                              className="btn btn--sm btn--outline btn--primary btn--w-icon w-full mt-4"
                              onClick={() => {
                                track('Perk Chat Clicked', {
                                  name: `${perk?.membership?.name} - ${perk?.offer}`,
                                });
                              }}
                            >
                              <i className="i-chat btn__icon" />
                              <span className="btn__text">Chat</span>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Panels>
  );
};
export default PerksDetail;
