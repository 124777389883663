import React from 'react';
import VideoPlayer from '../video/VideoPlayer';

type Props = {
  videoUrl: string | null;
};

const ProfileVideo = ({ videoUrl = null }: Props) => {
  return (
    <div className="mt-8">
      <VideoPlayer videoUrl={videoUrl} />
    </div>
  );
};
export default ProfileVideo;
