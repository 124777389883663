import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import moment from 'moment';

import { Education } from '@communo-corp/shared-types';
import { SubmitButton } from '../../core/Button';
import { FormActions, FormGroup } from '../../core/form';
import { LoadingRing } from '../../core/Loading';
import School from '../formElements/School';
import Major from '../formElements/Major';
import Minor from '../formElements/Minor';
import GraduationDate from '../formElements/GraduationDate';
import EducationApi from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import useNavigate from '../../../hooks/useNavigate';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import { HBCU_PLAN_ID } from '../../../config';
import { Country } from '../../plan/formElements';
import EducationContext from '../Context';
import UserContext from '../../user/Context';

interface SchoolValue {
  value: string;
  label: string;
}

interface Inputs {
  school: SchoolValue | null;
  country?: string | null;
  graduationDate: string | null;
  major: string | null;
  minor: string | null;
}

const now = new Date();

const EditEducation = ({ education }: { education: Education }) => {
  const { editEducation } = useContext(EducationContext);
  const { refresh } = useContext(UserContext);

  const mounted = useRef(false);

  const plan = useCurrentPlan();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const defaultSchool = {
    label: `${education.school.name} - ${education.school.country.name}` || '',
    value: education.school.id || '',
  };
  const form = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      school: defaultSchool,
      country: null,
      major: education.major,
      minor: education.minor,
      graduationDate: education.graduationDate,
    },
  });
  const { errors, register, handleSubmit, watch } = form;
  const isHbcu = plan?.id === HBCU_PLAN_ID;
  const school = watch('school');
  const isNewSchool = school?.value === 'create';

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onSubmit = useCallback(
    async (values: Inputs) => {
      if (loading) return;
      setLoading(true);

      try {
        let schoolId = values.school?.value;

        if (values.school?.value === 'create' && values.country) {
          const newSchool = await EducationApi.createSchool({
            name: values.school.label,
            countryIsoCode: values.country,
          });

          schoolId = newSchool.id;
        }

        // graduationDate should never be null because we have validation to prevent it. 'now' is just to make typescript happy.
        const data = {
          ...values,
          graduationDate: moment(values.graduationDate ?? now).format(
            'YYYY-MM-DD'
          ),
          schoolId,
        };

        await editEducation(education.id, data);
        await refresh();
        if (mounted.current) {
          setLoading(false);
        }
        addSuccess('Education Updated!');
        navigate('#/edit-profile/education');
      } catch (error) {
        if (mounted.current) {
          setLoading(false);
        }
        addResponseError(error);
      }
    },
    [education.id, loading, navigate, editEducation, refresh]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <School
        form={form}
        onlyHbcuSchools={isHbcu}
        defaultValue={defaultSchool}
      />
      {isNewSchool && (
        <FormGroup name="country" label="Country" errors={errors}>
          <Country
            name="country"
            // errors={isSubmitted ? errors : {}}
            errors={errors}
            register={register}
          />
        </FormGroup>
      )}

      {school && (
        <>
          <Major errors={errors} register={register} />
          {isHbcu ? <Minor errors={errors} register={register} /> : null}
          <GraduationDate
            label={isHbcu ? 'Graduation date' : 'Year of graduation'}
            format={isHbcu ? 'Month' : 'Year'}
            form={form}
            defaultValue={education.graduationDate}
          />
        </>
      )}

      <FormActions className="mt-auto justify-center">
        <div className="flex justify-end w-full">
          <SubmitButton text="Save" loading={loading} />
        </div>
      </FormActions>
    </form>
  );
};

const WrappedEditEducation = ({
  educationId,
}: {
  userId: string;
  educationId: string;
}) => {
  const { education, isFetching } = useContext(EducationContext);

  if (isFetching) {
    return <LoadingRing isActive />;
  }

  const educationToEdit = education
    ? education.find(edu => edu.id === educationId)
    : null;

  if (!educationToEdit) {
    return <p>Cannot find education record to edit.</p>;
  }

  return <EditEducation education={educationToEdit} />;
};

export default WrappedEditEducation;
