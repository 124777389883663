/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default React.forwardRef(
  ({ name, placeholder = '', type = 'text', ...rest }: any, ref: any) => (
    <input
      {...rest}
      type={type}
      id={name}
      name={name}
      placeholder={placeholder}
      className="form-input"
      ref={ref}
    />
  )
);
