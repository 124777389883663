const communoUrl = /(http(s?):\/\/)?(www\.)?((app|stage).communo\.com|.*communo-v3.netlify(.com|.app))\/(?<path>.+)/m;
const communoPath = (url: string): string | null => {
  if (communoUrl.test(url)) {
    const regex = communoUrl.exec(url);

    if (regex) {
      return `/${regex.groups?.path}`;
    }
  }

  return null;
};

export default communoPath;
