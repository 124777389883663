export default (url: string) => {
  return {
    id: 'title',
    label: 'What’s the title on your business card?',
    description: 'Your title will help people identify what you do.',
    icon: 'work',
    action: `#/${url}/basic`,
    actionLabel: 'Add Title',
    complete: false,
  };
};
