import React, { useCallback } from 'react';
import cn from 'classnames';
import moment from 'moment';
import useFeatureGate from '../../hooks/useFeatureGate';
import Button from '../core/Button';
import useCurrentPlan from '../../hooks/useCurrentPlan';

type Props = {
  qualification: QualificationEntity;
};

const ApplicationLimitNotice = ({ qualification }: Props) => {
  const plan = useCurrentPlan();
  const { openUpgradeFlowFor } = useFeatureGate();
  const onClick = useCallback(() => {
    openUpgradeFlowFor('numCommunoWorkApplicationsPerMonth');
  }, [openUpgradeFlowFor]);

  if (!qualification.applicationCreditResetTimestamp) {
    return null;
  }

  const totalCredits = plan?.numCommunoWorkApplicationsPerMonth ?? 0;
  const creditBalance = qualification.applicationCreditBalance ?? 0;
  const creditsUsed = qualification.applicationCreditsUsed ?? 0;
  const resetDate = moment(
    qualification.applicationCreditResetTimestamp
  ).fromNow(true);

  return (
    <div
      className={cn(
        'mb-16 notice flex flex-col md:flex-row items-start md:items-center md:space-x-4',
        {
          'notice--danger': creditBalance === 0,
        }
      )}
    >
      <div
        className={cn('hidden md:block p-3 text-white rounded-md', {
          'bg-red-1100': creditBalance === 0,
          'bg-grey-700': creditBalance > 0,
        })}
      >
        <svg
          className="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 22H20V18.5C20 15.8 18.6 13.3 16.2 12C18.5 10.7 20 8.2 20 5.5V2H21C21.6 2 22 1.6 22 1C22 0.4 21.6 0 21 0H4C3.4 0 3 0.4 3 1C3 1.6 3.4 2 4 2H5V5.5C5 8.2 6.4 10.7 8.8 12C6.4 13.3 5 15.8 5 18.5V22H4C3.4 22 3 22.4 3 23C3 23.6 3.4 24 4 24H21C21.6 24 22 23.6 22 23C22 22.4 21.6 22 21 22ZM7 18.5C7 15.5 9.5 13 12.5 13C12.7 13 12.9 13 13 13C15.9 13.4 18 15.8 17.9 18.7V21.5C17.9 21.8 17.7 22 17.4 22H7.4C7.2 22 7 21.8 7 21.5V18.5ZM7.5 2H17.5C17.8 2 18 2.2 18 2.5V5.3C18 8.2 15.9 10.7 13.1 11C10 11.3 7.3 9.1 7 6C7 5.8 7 5.6 7 5.5V2.5C7 2.2 7.2 2 7.5 2Z"
            fill="white"
          />
          <path
            d="M12.5 9.59998C13.9 9.49998 15.3 8.69998 15.9 7.39998C16 7.19998 16 6.89998 15.7 6.69998C15.6 6.69998 15.5 6.59998 15.4 6.59998H9.4C9.2 6.69998 9 6.89998 9 7.19998C9 7.29998 9 7.39998 9.1 7.49998C9.7 8.69998 11.1 9.59998 12.5 9.59998Z"
            fill="white"
          />
          <path
            d="M13.2001 15.6C12.8001 15.2 12.2001 15.2 11.7001 15.6L8.40015 19.3C8.00015 19.7 8.10015 20.3 8.50015 20.7C8.70015 20.9 8.90015 21 9.20015 21H15.9001C16.5001 21 16.9001 20.6 16.9001 20C16.9001 19.8 16.8001 19.5 16.6001 19.3L13.2001 15.6Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="flex-1">
        <p>
          You have used <strong>{creditsUsed}</strong> of your{' '}
          <strong>{totalCredits}</strong> monthly applications.
        </p>
        <p className="mt-2 text-sm">
          Your limit will reset in <strong>{resetDate}</strong>. Or upgrade to
          remove the limit!
        </p>
      </div>

      <div className="mt-4">
        <Button fill="solid" color="primary" type="button" onClick={onClick}>
          Upgrade
        </Button>
      </div>
    </div>
  );
};

export default ApplicationLimitNotice;
