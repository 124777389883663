import { SearchResponse } from '@algolia/client-search';
import orderBy from 'lodash/orderBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';
import { thousands } from '../../../utilities/Number';
import { groupRoles } from '../../roles/Util';

export default function getRoleOptions(
  results: {
    [key: string]: SearchResponse;
  },
  roles: RoleEntity[]
) {
  const roleFacetOptions = getFacetValuesFromResponse(
    results?.role || results?.search,
    'role.name'
  );

  const filteredRoles = roles.filter(role => {
    return !!roleFacetOptions[role.name];
  });

  const roleOptions = groupRoles(filteredRoles, role => {
    const count = roleFacetOptions[role.name] ?? 0;

    return {
      value: role.name,
      activeLabel: role.name,
      label: `${role.name} (${thousands(count)})`,
      count,
    };
  });

  return orderBy(roleOptions, ['count'], ['desc']);
}
