/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';

interface Props {
  columns: any;
  data: any;
  onClick(row: any): any;
}

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}) => {
  const count = preGlobalFilteredRows.length;

  return (
    <div className="form-group">
      <label htmlFor="memberSearch" className="sr-only">
        Search
      </label>
      <div className="input-group">
        <input
          id="memberSearch"
          className="form-input"
          type="search"
          placeholder={`Search ${count} records...`}
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
        />
        <div className="input-group__icon">
          <i className="i-search text-xs" />
        </div>
      </div>
    </div>
  );
};

const PerkBundleTable = ({ columns, data, onClick }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-white bg-grey-500 p-3 text-left"
                >
                  <div className="flex items-center justify-between">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="i-caret-up" />
                        ) : (
                          <i className="i-caret-down" />
                        )
                      ) : (
                        <>
                          <i className="i-caret-up" />
                          <i className="i-caret-down" />
                        </>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => onClick(row)}
                className="hover:cursor-pointer group"
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-3 bg-white border-b border-grey-200 cursor-pointer group-hover:bg-grey-100"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default PerkBundleTable;
