import React, { useEffect } from 'react';
import { App } from '@capacitor/app';
import { withRouter } from 'react-router';

const AppUrlOpener = React.memo(({ push }: any) => {
  useEffect(() => {
    App.addListener('appUrlOpen', (data: any) => {
      const location = data.url
        .replace('https://app.communo.com/', '')
        .replace('communo://', '');

      push(`/${location}`);
    });
  }, [push]);

  return null;
});

export default withRouter(({ history }) => (
  <AppUrlOpener push={history.push} />
));
