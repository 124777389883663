import React from 'react';
import useNavigate from '../../../hooks/useNavigate';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

export const getVettingMetricStatus = (details: ScoreDetail): MetricStatus => {
  if (!details) return null;

  // Tiers/count = Not vetted: 0, pending: 1, vetted: 2, futureValue: 3
  const { count } = details;

  switch (count) {
    case 2:
      return 'complete';
    case 1:
      return 'complete'; // Was "partial" but we are band-aiding people who were pending to now be verified and consider that complete
    default:
      return null;
  }
};

// export const getVettingMetricStatus = (
//   criteria: ScoreMetricsCriteria
// ): MetricStatus => {
//   if (criteria.vetted) return 'complete';
//   if (criteria.pendingVetting || criteria.eligibleForVetting) return 'partial';
//   return 'incomplete';
// };

// export const getLabel = (criteria: ScoreMetricsCriteria) => {
//   if (criteria.vetted) return 'Vetted';
//   if (criteria.pendingVetting) return 'Pending';
//   if (criteria.eligibleForVetting) return 'Eligible';
//   return 'Not Vetted';
// };

export const getLabel = (details: ScoreDetail) => {
  // Tiers/count = Not Verified: 0, pending: 1, Pro: 2, futureValue: 3
  const count = details?.count;

  switch (count) {
    case 2:
      return 'Expert-Vetted';
    case 1:
      return 'Verified';
    default:
      return 'Not Verified';
  }
};

type Props = {
  scoreMetrics: ScoreMetrics;
};

const VettingStatus = ({ scoreMetrics }: Props) => {
  const navigate = useNavigate();
  const {
    detail: { vetting },
  } = scoreMetrics;
  const status = getVettingMetricStatus(vetting);
  const label = getLabel(vetting);

  // console.log('scoreMetrics', scoreMetrics);
  // console.log('detail', vetting);

  const handleClick = () => {
    if (status !== 'complete') {
      navigate('#/edit-profile/completion', 'none', 'push');
    }
  };

  return (
    <Metric
      label={label}
      status={status}
      onClick={handleClick}
      className={vetting.count === 0 ? 'text-red-900' : ''}
    />
  );
};

export default VettingStatus;
