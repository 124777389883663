import React from 'react';

const StackIcon = React.memo(({ className = '' }: any) => {
  return (
    <svg
      className={className}
      viewBox="15 15 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <rect width="80" height="80" rx="40" fill="#1F2326" /> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.006 15.79a2.222 2.222 0 0 1 1.988 0L63.216 26.9a2.222 2.222 0 0 1 0 3.976L40.994 41.987a2.222 2.222 0 0 1-1.988 0l-22.222-11.11a2.222 2.222 0 0 1 0-3.976L39.006 15.79Zm-16.26 13.099L40 37.514l17.253-8.627L40 20.263l-17.253 8.627ZM15.79 50.118a2.222 2.222 0 0 1 2.982-.994L40 59.738l21.228-10.614a2.222 2.222 0 1 1 1.988 3.975L40.994 64.21a2.222 2.222 0 0 1-1.988 0L16.784 53.1a2.222 2.222 0 0 1-.994-2.981Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.79 39.006a2.222 2.222 0 0 1 2.982-.994L40 48.627l21.228-10.615a2.222 2.222 0 0 1 1.988 3.976l-22.222 11.11a2.222 2.222 0 0 1-1.988 0l-22.222-11.11a2.222 2.222 0 0 1-.994-2.982Z"
      />
    </svg>
  );
});
export default StackIcon;
