import React from 'react';
import Page from '../components/core/Page';
import DirectUpgradeFlow from '../components/featureGate/DirectUpgradeFlow';

const Upgrade = ({ match }: any) => {
  const { slug, coupon } = match.params;

  return (
    <Page authGuard pageTitle="Upgrade">
      <DirectUpgradeFlow slug={slug} coupon={coupon} />
    </Page>
  );
};

export default React.memo(Upgrade);
