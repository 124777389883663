import React, { useEffect } from 'react';
import { track } from '../../analytics';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Link } from '../core/router';

const ThankYouMessage = () => {
  const { isAuthenticated } = useCurrentUser();
  // const { leaveFor } = useContext(SubmitReferenceContext);
  // const navigate = useNavigate();

  useEffect(() => {
    track('Reference: Viewed Step', {
      step: 'Thank You',
    });
  }, []);

  return (
    <div className="content">
      <p>
        Your reference is being processed, and the member will be notified when
        it is posted on their profile.
      </p>
      {isAuthenticated ? (
        <p>
          View the{' '}
          <Link className="text-link font-semibold" to="/work">
            latest work.
          </Link>
        </p>
      ) : (
        <p>
          If you would like to find out more about Communo visit{' '}
          <a href="https://communo.com">communo.com</a>.
        </p>
      )}

      {/* Leaving a reference for someone */}
      {/* {leaveFor && isAuthenticated ? (
        <p>
          Return to{' '}
          <button
            type="button"
            onClick={() => {
              navigate(`/members/user/${leaveFor}`, 'forward', 'replace');
            }}
          >
            Back button
          </button>
          <Link
            className="text-link font-semibold"
            to={`/members/user/${leaveFor}`}
          >
            their profile.
          </Link>
        </p>
      ) : null} */}
    </div>
  );
};
export default ThankYouMessage;
