import React, { useCallback, useContext, useState } from 'react';
import Alert from '../../alert/Alert';
import MembershipApi from '../../membership/Api';
import UserApi from '../../user/Api';
import UserContext from '../../user/Context';
import SkillMetric from './Skill';

const maxWorkSkills = 5;

type Props = {
  isUser: boolean;
  skills: SkillEntity[];
  scoreMetrics: ScoreMetrics;
};

const SkillsMetric = ({ isUser, skills, scoreMetrics }: Props) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { id: userId, membershipId, refresh, refreshMembership } = useContext(
    UserContext
  );

  const addSkill = useCallback(
    (skillId: string) => {
      if (isUser) {
        return UserApi.associateSkill(userId || '', skillId).then(() =>
          refresh()
        );
      }

      return MembershipApi.associateSkill(membershipId || '', skillId)
        .then(() => refreshMembership())
        .catch(err => {
          const response = err?.response;

          if (response?.status === 401) {
            setShowErrorModal(true);
          }
        });
    },
    [membershipId, isUser, refresh, refreshMembership, userId]
  );

  return (
    <>
      {skills?.slice(0, maxWorkSkills).map(skill => (
        <SkillMetric
          key={skill.id}
          skill={skill}
          addSkill={addSkill}
          scoreMetrics={scoreMetrics}
        />
      ))}

      {showErrorModal ? (
        <Alert
          isOpen={showErrorModal}
          header="Error saving skill"
          message="You must be an admin of your team to add a skill."
          onDidDismiss={() => setShowErrorModal(false)}
          buttons={[
            {
              text: 'OK',
              role: 'cancel',
              cssClass: 'alert-primary-btn',
              handler: () => {},
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default SkillsMetric;
