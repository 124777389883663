import React, { useCallback, useEffect } from 'react';
import { isFunction } from 'lodash';
import { API_DOMAIN } from '../../config';
import LinkedInButton from '../button/LinkedInButton';

type Props = {
  action: 'login' | 'signup' | 'references';
  callback: Function;
};

const LinkedInLogin = ({ action, callback }: Props) => {
  let actionText = 'Continue';
  if (action === 'login') {
    actionText = 'Log in';
  }

  const onClick = () => {
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `${API_DOMAIN}/sso/linkedin/${action}`,
      'LinkedIn',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  const handlePostMessage = useCallback(
    async (event: any) => {
      if (isFunction(callback)) {
        callback(event.data);
      }
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [handlePostMessage]);

  return (
    <div className="mb-6">
      <LinkedInButton onClick={onClick}>
        {actionText} with LinkedIn
      </LinkedInButton>
    </div>
  );
};
export default LinkedInLogin;
