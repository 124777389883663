import React from 'react';

interface Props {
  rateLevel: number | null;
}

interface DollarProps {
  isActive: boolean;
}

const DollarSign = ({ isActive }: DollarProps) => {
  return <div className={isActive ? 'text-black' : 'text-grey-200'}>$</div>;
};

const DisplayRate = ({ rateLevel }: Props) => {
  const dollarSigns = [];

  if (rateLevel === null) {
    return <div className="text-center text-3xl font-semibold">&mdash;</div>;
  }

  for (let index = 1; index < 6; index += 1) {
    dollarSigns.push(<DollarSign isActive={rateLevel >= index} key={index} />);
  }

  return (
    <div className="flex items-center justify-center text-lg">
      {dollarSigns}
    </div>
  );
};
export default DisplayRate;
