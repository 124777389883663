import React from 'react';
import ForgotPassword from '../components/user/views/ForgotPassword';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';
import { DEFAULT_FORGOT_COVER_PHOTO_URL } from '../asset-config';

export default () => {
  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Forgot Password"
      mobileHeader={() => null}
      hideHeader
    >
      <SignupLayout headerImg={DEFAULT_FORGOT_COVER_PHOTO_URL}>
        <div>
          <ForgotPassword />
        </div>
      </SignupLayout>
    </Page>
  );
};
