import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

export default ({ errors, register }: any) => {
  const ref = register({ required: 'Please select a remote option' });

  return (
    <FormGroup
      required
      name="remote"
      label="Can the candidate work remotely?"
      errors={errors}
    >
      <FormRadio
        name="remote"
        value="1"
        label="Yes - e.g. the successful candidate can work from anywhere"
        ref={ref}
      />
      <FormRadio
        name="remote"
        value="0"
        label="No - e.g. in-person meetings will be required"
        ref={ref}
      />
    </FormGroup>
  );
};
