import React from 'react';

type ChatIconProps = {
  className?: string;
};

const ChatIcon = ({ className = '' }: ChatIconProps) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M35.956 6.444h-13.29V3.29a3.092 3.092 0 0 0-3.11-3.111H3.11A3.092 3.092 0 0 0 0 3.288v15.334c0 .4.222.756.533.978.178.089.356.133.578.133.178 0 .4-.044.533-.133l3.334-1.911H7.91v9.289a4.06 4.06 0 0 0 4.045 4.044h21.422l4.978 2.845c.177.089.355.133.533.133.178 0 .4-.044.578-.133.355-.178.533-.578.533-.978V10.444c0-2.177-1.822-4-4.044-4Zm-28.045 4v5.023h-3.2c-.178 0-.4.044-.533.133l-1.956 1.111V3.29c0-.489.4-.889.89-.889h16.444c.488 0 .888.4.888.889v3.155h-8.533c-2.222 0-4 1.823-4 4Z" />
    </svg>
  );
};

export default ChatIcon;
