import React, { useCallback, useMemo, useState } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useReferencesForUser from '../../reference/hooks/useReferencesForUser';
import ViewReferencesModal from '../../reference/ViewReferencesModal';
import Metric from '../Metric';
import { MetricStatus } from '../MetricStatus';

const getReferencesStatus = (count: number, steps: number): MetricStatus => {
  if (count >= steps) {
    return 'complete';
  }

  return null;
};

interface ModalProps {
  isOpen: boolean;
  user: UserEntity;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReferencesModal = ({ isOpen, user, onClose }: ModalProps) => {
  const { response } = useReferencesForUser(user.id);
  const references = response?.references ?? [];

  return (
    <>
      {isOpen ? (
        <ViewReferencesModal
          user={user}
          references={references}
          isOpen={isOpen}
          onClose={() => {
            onClose(false);
          }}
        />
      ) : null}
    </>
  );
};

type Props = {
  scoreMetrics: ScoreMetrics;
};

const ReferencesMetric = ({
  scoreMetrics,
  user,
}: Props & { user: UserEntity }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    detail: { references },
  } = scoreMetrics;
  // steps: is the number that's considered complete, count: is the current number of complete references for the user
  const { steps, count } = references;

  // This was just a Bandaid until the backend could include it in the score - leaving for reference (pun intended)
  // const { response } = useReferencesForUser(user.id);
  // const references = response?.references ?? [];
  // const visibleReferences = useMemo(() => {
  //   return references.filter(reference => reference.visibility);
  // }, [references]);
  // const numReferences = visibleReferences.length;
  // =======

  const labelSubtext = useMemo(() => {
    if (count >= steps) {
      return `(${steps}/${steps})`;
    }

    return `(${count}/${steps})`;
  }, [count, steps]);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <Metric
        label="References"
        labelSubtext={labelSubtext}
        status={getReferencesStatus(count, steps)}
        onClick={handleClick}
      />
      {user ? (
        <ReferencesModal
          isOpen={isModalOpen}
          user={user}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

const WrappedReferencesMetric = ({ scoreMetrics }: Props) => {
  const { user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return <ReferencesMetric user={user} scoreMetrics={scoreMetrics} />;
};

export default WrappedReferencesMetric;
