import React, { useCallback, useContext, useEffect } from 'react';
import useIsFormValid from '../../../hooks/useIsFormValid';
import Editor from '../../core/Editor/Editor';
import useStep from '../../stepper/hooks/useStep';
import { SubmitRecommendationContext } from '../context/SubmitRecommendationContext';

const RecommendationBodyStep = () => {
  const { setCanAdvance } = useStep();
  const { form } = useContext(SubmitRecommendationContext);
  const isValidForm = useIsFormValid(form, ['body']);

  useEffect(() => {
    setCanAdvance(isValidForm);
  }, [setCanAdvance, isValidForm]);

  useEffect(() => {
    form.register(
      { name: 'body', type: 'custom' },
      {
        required: 'A recommendation message is required',
      }
    );
  }, [form]);

  const handleRichTextChange = useCallback(
    (html: string) => {
      form.setValue('body', html);
    },
    [form]
  );

  const body = form.getValues('body');

  return (
    <div>
      <Editor
        placeholder="You can copy and paste from other applications."
        defaultValue={body}
        onChange={handleRichTextChange}
      />
    </div>
  );
};
export default RecommendationBodyStep;
