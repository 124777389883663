/* eslint-disable no-param-reassign */
import { isPlatform } from '@ionic/core';
import { KeyboardInfo, KeyboardResize, Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

const {
  show,
  hide,
  setAccessoryBarVisible,
  removeAllListeners,
  setResizeMode,
} = Keyboard;
const isNative = isPlatform('capacitor');

const init = async (ref: HTMLElement) => {
  if (isNative) {
    if (
      Capacitor.getPlatform() === 'ios' &&
      Capacitor.isPluginAvailable('Keyboard')
    ) {
      // TODO: Remove setResizeMode once all users have upgraded app version to >= 4.7.2
      await setResizeMode({ mode: KeyboardResize.None });
      await setAccessoryBarVisible({ isVisible: false });
    }

    Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
      const { keyboardHeight } = info;
      const { activeElement } = document;

      if (ref && activeElement) {
        const yOffset =
          activeElement.getBoundingClientRect().top + window.pageYOffset;

        if (yOffset < keyboardHeight) {
          return;
        }

        ref.style.transition = 'transform 0.3s';
        ref.style.transform = `translateY(${-keyboardHeight}px)`;

        const htmlEl = document.getElementsByTagName('html')[0];
        if (htmlEl) {
          htmlEl.classList.add('mobile-keyboard-open');
        }
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (ref) {
        ref.style.transform = `translateY(0)`;
      }

      const htmlEl = document.getElementsByTagName('html')[0];
      if (htmlEl) {
        htmlEl.classList.remove('mobile-keyboard-open');
      }
    });
  }
};

export default {
  init,
  show,
  hide,
  setAccessoryBarVisible,
  removeAllListeners,
};
