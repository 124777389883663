/**
 * useCanRecipientChat
 *
 * Hook to return a Twilio User attributes for the given recipient identity.
 * This hook will load the user's attributes if they haven't been loaded yet.
 */

import useChatRecipientIdentity from './useChatRecipientIdentity';
import useChatUser from './useChatUser';

const useCanRecipientChat = (recipientIdentity: string) => {
  // If channel already exists, extract member info from conversation

  const { user } = useChatUser(useChatRecipientIdentity(recipientIdentity));

  if (!user) return false;

  const {
    attributes: {
      isDeactivated = false,
      isOblivious = false,
      isNotContactable = false,
    },
  } = user;

  return !(isDeactivated || (isNotContactable && !isOblivious));
};

export default useCanRecipientChat;
