import React from 'react';
import { IMember } from '../../../@types/chat.d';
import { ListActionsItemButton } from '../../listActions';
import MemberInstances from '../instances/MemberInstances';

const MuteIcon = () => (
  <i className="i-mute-notification text-grey-600 text-lg" />
);
const UnmuteIcon = () => <i className="i-notification text-grey-600 text-lg" />;

const MemberMuteChannel = ({ member }: { member: IMember }) => {
  if (!member) return null;

  const isNotifiable = member.attributes?.isNotifiable ?? true;

  const handleMute = async () => {
    const MemberInstance = MemberInstances.select(member.sid);
    await MemberInstance.updateAttributes({ isNotifiable: !isNotifiable });
  };

  return (
    <ListActionsItemButton
      end={isNotifiable ? <UnmuteIcon /> : <MuteIcon />}
      onClick={handleMute}
    >
      {isNotifiable ? 'Mute' : 'Unmute'} Conversation
    </ListActionsItemButton>
  );
};

export default MemberMuteChannel;
