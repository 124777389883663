import React from 'react';
import { Capacitor } from '@capacitor/core';
import { Badge as AppBadge } from '@capawesome/capacitor-badge';
import useAsyncEffect from 'use-async-effect';
import Badge from '../../badge/Badge';
import useChatTotalUnreadCount from '../hooks/useChatTotalUnreadCount';

const Notifications = () => {
  const unreadCount = useChatTotalUnreadCount();

  useAsyncEffect(async () => {
    if (Capacitor.isNative) {
      const badgeSupport = await AppBadge.isSupported();
      if (badgeSupport) {
        AppBadge.set({ count: unreadCount });
      }
    }
  }, [unreadCount]);

  return (
    <div className="relative">
      <i className="i-chat block" />
      {unreadCount > 0 && (
        <Badge className="absolute top-0 right-0 -mt-2 -mr-3 w-4 h-4">
          {unreadCount}
        </Badge>
      )}
    </div>
  );
};

export default React.memo(Notifications);
