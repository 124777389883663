import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import Messaging from '../../../../services/Messaging';
import { Autocomplete, FormGroup } from '../../../core/form';
import useStep from '../../../stepper/hooks/useStep';
import UserContext from '../../../user/Context';
import chapters from './Chapters';

const { addResponseError } = Messaging;

const defaultOptions = Object.keys(chapters).map(district => {
  return {
    label: district,
    options: chapters[district].map((chapter: string) => {
      return { value: chapter, label: chapter };
    }),
  };
});

const Form = () => {
  const { user, update: updateUser } = useContext(UserContext);
  const jsonAttributes = user ? user.jsonAttributes : undefined;
  const { goToNextStep, setOnNext } = useStep();
  const [loading, setLoading] = useState(false);
  const defaultChapter = useMemo(() => {
    if (jsonAttributes?.aafChapter) {
      return [
        {
          label: jsonAttributes?.aafChapter ?? null,
          value: jsonAttributes?.aafChapter ?? null,
        },
      ];
    }

    return undefined;
  }, [jsonAttributes]);
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      aafChapter: defaultChapter,
    },
  });
  const { handleSubmit, reset, errors } = form;
  // const { isValid } = formState;

  const filterOptions = async (query: string) => {
    const q = query.toLowerCase();
    return defaultOptions
      .map(district => {
        return district.label.toLowerCase().includes(q)
          ? district
          : {
              label: district.label,
              options: district.options.filter(chapter =>
                chapter.label.toLowerCase().includes(q)
              ),
            };
      })
      .filter(district => district.options.length > 0);
  };

  const { track } = useAnalytics();
  useEffect(() => {
    track('Signup - AafChapter Page');
  }, [track]);

  const onSubmit = useCallback(
    (values: any) => {
      if (loading) return;

      // if (!isDirty) {
      //   goTo('next');
      //   return;
      // }

      setLoading(true);
      updateUser({ jsonAttributes: { aafChapter: values.aafChapter.value } })
        .then(() => {
          reset({
            aafChapter: values.aafChapter,
          });
          goToNextStep();
        })
        .catch(addResponseError)
        .finally(() => {
          setLoading(false);
        });
    },
    [goToNextStep, loading, reset, updateUser]
  );

  useEffect(() => {
    setOnNext(() => {
      handleSubmit(onSubmit)();
      return false;
    });
  }, [handleSubmit, onSubmit, setOnNext]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormGroup name="aafChapter" label="AAF Chapter" errors={errors}>
        <Autocomplete
          name="aafChapter"
          form={form}
          defaultValues={defaultChapter}
          defaultOptions={defaultOptions}
          callback={filterOptions}
          isRequired
          isCreatable={false}
          placeholder="Select a chapter"
        />
      </FormGroup>
    </form>
  );
};

export default Form;
