import PaymentStepper from '../PaymentStepper';
import { StepType } from '../../stepper/Step';
import PlanStep from '../steps/PlanStep';
import PaymentStep from '../steps/PaymentStep';
import NumSeatsStep from '../steps/NumSeatsStep';

export type ACTIONS = 'NEXT' | 'PREV' | 'CLOSE' | 'OPEN';

// Needed because the 'use-state-with-callback lib doesn't export the DispatchWithCallback type
export type SetFlowScreensType = (
  value: React.SetStateAction<Screen[] | null> | null,
  callback: (state: Screen[] | null) => void
) => void;

export interface ScreenProps {
  goToNext(): void;
  goToPrev(): void;
  flowName?: string;
  slug?: string;
  coupon?: string;
  contextualFlow?: string;
  availablePlans: PlanEntity[];
  setFlowScreens: SetFlowScreensType;
  stepperSteps: UpgradeStep[] | null;
  setStepperSteps: React.Dispatch<React.SetStateAction<UpgradeStep[] | null>>;
  purchasableItem?: string;
  setPurchasableItem: React.Dispatch<React.SetStateAction<string | undefined>>;
  eventTrackingLabel: string;
}

export interface Screen {
  title?: string;
  icon?: string;
  btnLabel?: string;
  btnAction?: ACTIONS;
  bgColor: string;
  Component: (props: ScreenProps) => JSX.Element;
  eventTrackingLabel: string;
  shouldTrackScreenEvent: boolean;
}

export interface UpgradeStepProps {
  currentPlan: PlanEntity | null;
  availablePlans: PlanEntity[] | null;
  goToPrev(): void;
  coupon?: string;
  purchasableItem?: string;
}

export type UpgradeStep = Omit<StepType, 'component'> & {
  StepComponent: (props: UpgradeStepProps) => JSX.Element;
  name: string;
};

export const beginningDefault: Omit<Screen, 'title' | 'Component'> = {
  icon: 'i-alert-add',
  btnAction: 'NEXT',
  btnLabel: 'Continue',
  bgColor: '#f05555',
  eventTrackingLabel: 'Opened upgrade flow',
  shouldTrackScreenEvent: true,
};

export const paymentDefault: Pick<
  Screen,
  'bgColor' | 'Component' | 'eventTrackingLabel' | 'shouldTrackScreenEvent'
> = {
  bgColor: '#ffffff',
  Component: PaymentStepper,
  eventTrackingLabel: 'Upgrade Flow',
  shouldTrackScreenEvent: false,
};

export const endDefault: Omit<Screen, 'Component'> = {
  icon: 'i-alert-positive',
  btnAction: 'CLOSE',
  btnLabel: 'Close',
  bgColor: '#f05555',
  title: 'Success!',
  eventTrackingLabel: 'Upgrade success page',
  shouldTrackScreenEvent: true,
};

export const paymentFormStep: UpgradeStep = {
  title: 'Payment Info',
  name: 'Payment Info',
  StepComponent: PaymentStep,
};

export const defaultStepper: UpgradeStep[] = [
  {
    StepComponent: PlanStep,
    name: 'Plan Details',
    canAdvance: true,
  },
  paymentFormStep,
];

export const membershipStepper: UpgradeStep[] = [
  {
    StepComponent: NumSeatsStep,
    name: 'Seat Selector',
  },
  ...defaultStepper,
];
