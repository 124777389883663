/* eslint-disable no-console */
import channelMembersStoreCreator from '../state/ChatChannelMembersState';
import { IChannel, IMember } from '../../../@types/chat.d';
import MemberInstances from '../instances/MemberInstances';

const loadChannelMembersToState = async (channelInstance: IChannel) => {
  const { sid } = channelInstance;
  const [, channelMembersApi] = channelMembersStoreCreator(sid);
  const { members: existingMembers, addMembers } = channelMembersApi.getState();

  const existingMembersArr = Object.values(existingMembers);

  if (existingMembersArr.length > 0) {
    const sids = existingMembersArr.map(el => el.sid);
    return MemberInstances.selectMany(sids);
  }

  try {
    const members = await channelInstance.getParticipants();

    const mappedMembers = members.map(member => member as IMember);
    addMembers(mappedMembers);
    return mappedMembers;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default loadChannelMembersToState;
