import { useQuery } from '@tanstack/react-query';
import Api from '../Api';

export default function useBillingInfo(): {
  isFetching: boolean;
  data: any;
} {
  const { data, isFetching } = useQuery(
    ['billing-info'],
    () => Api.getBillingInfo().catch(() => null), // Allow react-query to cache non-200 responses
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 20 * 1000,
      staleTime: 20 * 1000,
    }
  );

  return {
    isFetching,
    data,
  };
}
