import { useEffect, useState, useCallback, useRef } from 'react';
import Api from '../components/skills/Api';

export type ProjectTypeType = {
  data: ProjectTypeEntity[] | null;
  projectOptions: ProjectOptionEntity[] | null;
  loading: boolean;
};

export default function(): {
  data: ProjectTypeEntity[] | null;
  projectOptions: ProjectOptionEntity[] | null;
  loading: boolean;
  refresh: Function;
} {
  const isMounted = useRef(false);
  const [state, setState] = useState({
    data: null,
    projectOptions: null,
    loading: true,
  } as ProjectTypeType);

  const updateState = useCallback(obj => {
    if (!isMounted.current) {
      return;
    }

    setState((state: any) => ({
      ...state,
      ...obj,
    }));
  }, []);

  const refresh = useCallback(async () => {
    isMounted.current = true;

    setState({
      loading: true,
      data: null,
      projectOptions: null,
    });

    try {
      const projectTypeResponse = await Api.projectType();
      const projectOptionResponse = await Api.projectOption();
      await updateState({
        data: projectTypeResponse,
        projectOptions: projectOptionResponse,
      });
    } catch (e) {
      await updateState({ data: null, projectOptions: null });
    }
    await updateState({ loading: false });

    return () => {
      isMounted.current = false;
    };
  }, [updateState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data: state.data,
    projectOptions: state.projectOptions,
    loading: state.loading,
    refresh,
  };
}
