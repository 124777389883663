import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import deepEqual from 'fast-deep-equal';
import { LoadingBalls } from '../../core/Loading';
import { Api } from '../../../Api';
import { FormActions } from '../../core/form';
import { Role, Skills, Industries } from '../formElements';
import { SubmitButton } from '../../core/Button';
import UserContext from '../Context';
import MembershipContext from '../../membership/Context';
import { addSuccess } from '../../../services/Messaging';

interface Props {
  user: UserEntity;
}

const RolesForm = ({ user }: Props) => {
  const [loading, setLoading] = useState(false);
  const { refresh, isRoot } = useContext(UserContext);
  const { plan } = useContext(MembershipContext);
  const { roles, skills, industries } = user;
  const form = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {},
  });
  const { handleSubmit, trigger } = form;

  useEffect(() => {
    trigger();
  }, [plan, trigger]);

  const numUserRoles = plan?.numUserRoles ?? 1;
  const numUserIndustries = plan ? plan.numUserIndustries : 0;

  const defaultRoles = useMemo(() => {
    return roles.map(({ role }: UserRole) => ({
      value: role.id,
      label: role.name,
    }));
  }, [roles]);

  const defaultSkills = useMemo(() => {
    return skills.map(({ skill }: UserSkill) => ({
      value: skill.id,
      label: skill.name,
    }));
  }, [skills]);

  const defaultIndustries = useMemo(() => {
    return industries.map(({ industry }: UserIndustry) => ({
      value: industry.id,
      label: industry.name,
    }));
  }, [industries]);

  const onSubmit = async (values: any) => {
    if (loading) return;
    setLoading(true);

    const relations: {
      relationship: 'roles' | 'skills' | 'industries';
      entity: any;
    }[] = [
      {
        relationship: 'roles',
        entity: 'role',
      },
      {
        relationship: 'skills',
        entity: 'skill',
      },
      {
        relationship: 'industries',
        entity: 'industry',
      },
    ];

    const requests: any[] = [];
    relations.forEach(relation => {
      const { relationship, entity } = relation;
      const entities: any[] = user[relationship] ?? [];

      const existingIds = entities.map((assoc: any) => assoc[entity].id);
      const ids = Array.isArray(values[relationship])
        ? values[relationship].map(({ value }: { value: string }) => value)
        : [values[relationship]?.value || []];

      if (!deepEqual(existingIds, ids)) {
        requests.push(Api.put(`/user/${user.id}/${entity}`, { items: ids }));
      }
    });
    await Promise.all(requests);

    refresh();
    addSuccess('Profile updated!');
    setLoading(false);
  };

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Role
          upgradePath="numUserRoles"
          form={form}
          defaultValues={defaultRoles}
          min={1}
          max={numUserRoles}
        />
        <Skills form={form} defaultValues={defaultSkills} />
        <Industries
          upgradePath="numUserIndustries"
          form={form}
          defaultValues={defaultIndustries}
          max={isRoot ? undefined : numUserIndustries}
        />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default RolesForm;
