import React, { useState, useRef } from 'react';
import { IonAlert } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import ChannelQueries from '../channels/queries';
import { ListActions } from '../listActions';
import { addSuccess, addError } from '../../services/Messaging';
import MemberListItem from './components/MemberListItem';

type BlockedMember = Omit<UserEntity, 'id'> & { id: string };

type BlockedMembersProps = {
  channelId: string;
};

/**
 * Renders a list of blocked members for the passed channel
 *
 * @param param0.channelid - the channel that has blocked members
 */
export default function BlockedMembers({
  channelId,
}: BlockedMembersProps): JSX.Element {
  const [members, setMembers] = useState<BlockedMember[]>([]);
  const [isShowingUnblockPrompt, setIsShowingUnblockPrompt] = useState(false);
  const memberToUnblock = useRef('');

  const queryClient = useQueryClient();

  ChannelQueries.useGetBlockedMembers<BlockedMember[]>(channelId, {
    onSuccess: data => {
      setMembers(data);
    },
  });

  const unblockMember = ChannelQueries.useUnblockMember();

  function handleUnblockMember(
    e: React.MouseEvent<HTMLButtonElement>,
    identity: string
  ) {
    setIsShowingUnblockPrompt(true);
    e.preventDefault();

    if (e.currentTarget.id === 'unblock-member') {
      memberToUnblock.current = identity;
    }
  }

  return (
    <div className="my-8">
      <>
        <IonAlert
          isOpen={isShowingUnblockPrompt}
          onDidDismiss={() => setIsShowingUnblockPrompt(false)}
          header="Unblock Participant?"
          message="Are you sure you want to unblock this participant from the conversation?"
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {},
            },
            {
              text: 'Unblock',
              handler: () => {
                unblockMember.mutate(
                  {
                    channelId,
                    userId: memberToUnblock.current,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries([
                        'members',
                        { filter: 'blocked' },
                      ]);
                      queryClient.invalidateQueries([
                        'channels',
                        { filter: 'all' },
                      ]);
                      addSuccess(`Successfully unblocked member`);
                    },
                    onError: () => {
                      addError(`An error occurred while unblocking a member`);
                    },
                  }
                );
              },
            },
          ]}
        />
        <div className="text-lg mb-2">Blocked Members</div>
        {members.length < 1 ? (
          <p className="text-sm">No blocked members</p>
        ) : (
          <ListActions>
            {members.map((member, i) => (
              <MemberListItem
                key={`${i.toString()}-${member.id}`}
                avatarUrl={member.avatarURL ?? ''}
                friendlyName={`${member.firstName} ${member.lastName}`}
                id={member.id}
                disabled
                end={
                  <button
                    type="button"
                    id="unblock-member"
                    className="btn btn--sm btn--secondary mx-2"
                    onClick={e => handleUnblockMember(e, member.id)}
                  >
                    Unblock
                  </button>
                }
              />
            ))}
          </ListActions>
        )}
      </>
    </div>
  );
}
